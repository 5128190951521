import React from 'react';
import chatService from '../../../../../Services/ChatService';

class ChatUser extends React.Component { 
    state = {
        userActiveStatus: false
    }
    
    componentDidMount() {
        chatService.geActivationStatus(this.props.user.userId).on('value', activationStatus => {
            this.setState({ userActiveStatus: activationStatus.val()})
        })
    }

    render() {
        const { user } = this.props;
        const { userActiveStatus } = this.state;
        return <>
            <div
                className="chat-user chat-contacts"
                onClick={() => this.props.openChatWindowFromContactList(user)}
              >
                <div className="chat-user-img">
                  <img
                    src={(user.profileImageUrl == null || user.profileImageUrl == '') ? "/Images/default-profile.png" : user.profileImageUrl}
                    onError={(e)=>{e.target.onerror = null; e.target.src="/Images/default-profile.png"}}
                    alt={user.displayName}
                  />
                </div>
                <div className="chat-username">
                  <span>{user.displayName}</span>
                </div>
                {(userActiveStatus) ? <div>online</div> : <div>offline</div>}
              </div>
            </>
    }
}

export default ChatUser;
import * as React from "react";
import Viewer from "react-viewer";
import FileViewer from "./FilesViewer";
const ImageViewer = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [imgUrl, setImgUrl] = React.useState("");
  const [downloadImgUrl, setDownloadImgUrl] = React.useState("");
  const [fileType, setFileType] = React.useState("");
  const [filePath, setFilePath] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [isCommentsAvailable, setCommentsAvailable] = React.useState(false);
  const [attachmentComment, setAttachmentComment] = React.useState("");
  return (  
    <>
      {props.attachments && props.attachments.length > 0 ? (
        <div className="fileslider float-left w-100">
          {(props.isExternal) ? '' : props.isEsignature ? (
            <div className="pb-3 p-0 text-14 text-light-brown" title="Completed result">
              e-Signature
            </div>
          ) : props.isInvoice ? (
            // AN-61716 : d-none applied according to the ticket
            <div className="pb-3 p-0 text-14 text-light-brown d-none" title="Completed result">
              Invoice Attachments
            </div>
          ) : props.isAfter ? (
            <div className="pb-3 p-0 text-14 text-light-brown" title="Completed result">
              Result Attachments
            </div>
          )  : props.isFromAsset ? (
            <div className="pb-3 p-0 text-14 text-light-brown text-left" title="Completed result">
            </div>
          ) : props.isFromAsset_ff_cp ? (
            <div className="pb-3 p-0 text-14 text-light-brown text-left" title="Completed result">
              Front Facing Photo and Compliance Plate Photo
            </div>
          ): props.isVariation ? <> 
            <div className="pb-3 p-0 text-14 text-light-brown" title="Completed result">
                Variation Attachments
              </div>
          </> : props.isInspectionReport? '' : (
            <div className="pb-3 p-0 text-14 text-light-brown" title="Job requirement">
                {props.isFromBot ? 'Job attachments' : props.isFromTenantManageJobPost ? 'Job attachments': props.attachments[0].type == 3 ? 'Job attachments':'Quote attachments'}
            </div>
          )}

          {props.attachments.map((uploadedFile) => {
            var defaultFileSrc = "/Images/attachment.png";

            if (uploadedFile.extension === ".csv") {
              defaultFileSrc = "/Images/attached-file-csv.svg";
            } else if (uploadedFile.extension === ".pdf") {
              defaultFileSrc = "/Images/attached-file-pdf.svg";
            } else if (uploadedFile.extension === ".mov") {
              defaultFileSrc = "/Images/attached-file-mov.svg";
            } else if (uploadedFile.extension === ".mp4") {
              defaultFileSrc = "/Images/attached-file-mp4.svg";
            } else if (uploadedFile.extension === ".tiff") {
              defaultFileSrc = "/Images/attached-file-tiff.svg";
            } else if (uploadedFile.extension === ".msg") {
              defaultFileSrc = "/Images/attached-file-msg.svg";
            } else if (uploadedFile.extension === ".eml") {
              defaultFileSrc = "/Images/attached-file-eml.svg";
            } else{
              defaultFileSrc = "/Images/attachment.png";
            }
            
            return (
              
                <div className="float-left relative" key={uploadedFile.fileId}>
                  {props.isFromAsset && 
                  <div className="delete-icon cursor-pointer close-icon-position" onClick={()=>{ props.RemoveFromList(uploadedFile.fileId)}}><i className="ba-icn ba-icn-close image-close-icon"></i></div>}
                  <div className="slider-item-wrapper">
                    <div className="fileslider-item">
                      <div className="img-holder">
                        <img
                        src={uploadedFile.fileUrl}
                        alt={uploadedFile.fileName}
                        downloadurl={uploadedFile.fileUrl}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultFileSrc;
                        }}
                        onClick={(e) => {
                          if(uploadedFile.fileType==1 && !uploadedFile.isCommentsAvailable && uploadedFile.extension != '.tiff'){
                            setDownloadImgUrl(uploadedFile.fileUrl);
                            setImgUrl(e.target.src);
                            setVisible(true);
                          } else {
                            
                              if (uploadedFile.isCommentsAvailable) {
                                setFileType(uploadedFile.extension.split('.')[1]);
                                setFilePath(uploadedFile.fileUrl);
                                setCommentsAvailable(true);
                                setAttachmentComment(uploadedFile.attachmentComments);
                                setModalShow(true);
                            }
                            else {
                                setCommentsAvailable(false);
                                setAttachmentComment("");
                                window.open(uploadedFile.fileUrl);
                            }
                          }
                        }}
                        />
                      </div>
                      <div>
                        {uploadedFile.isCommentsAvailable ? <div className="commented"  title="Comments available"><i class="ba-icn ba-icn-notes"></i></div>
                        : ''}
                      </div>
                    </div>
                    <div>
                      {props.isInspectionReport? <div className="img-datetime">{uploadedFile.lastModified}</div>
                      :''}
                    </div>
                  </div>
                </div>
            );
          })}

          <Viewer
            visible={visible}
            downloadable={true}
            changeable={false}
            downloadInNewWindow={true}
            onClose={() => {
              setVisible(false);
            }}
            images={[{ src: imgUrl, alt: "", downloadUrl: downloadImgUrl }]}
          />
          <FileViewer
            show={modalShow}
            onHide={() => setModalShow(false)} 
            fileType = {fileType}
            filePath={filePath}
            isCommentsAvailable={isCommentsAvailable}
            attachmentComment={attachmentComment}
            />
        </div>
      ) : (
        ""
      )}
    </>
  );
};


export default ImageViewer;

import React from "react";
import Drawer from "../../Common/Drawer/Drawer";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import { AppConfiguration } from "read-appsettings-json";
import './OwnerApproveRejectDrawer.scss';

class OwnerApproveRejectDrawer extends React.Component {

    state = {
        jobDetails: {},
        description: "",
        userId: "",
        isLoading: false
    };
    constructor(props) {
        super(props);

        this.JobAttend = this.JobAttend.bind(this);
        this.description = React.createRef();
    }

    componentDidMount() {
        this.setState({ jobDetails: this.props.jobDetails, userId: this.props.userId });
    }

    render() {
        const header = this.createHeader();
        const body = this.createBody();
        const footer = this.createFooter();
        return (
            <Drawer
                size={"lg"}
                open={this.props.showOwnerApproveRejectDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    }


    createHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">Attend Job</h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={this.props.toggleOwnerApproveRejectDrawer.bind(this)}>
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };


    createBody = () => {
        return (<>
            <div className="panel-body">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="w-100 text-left">Note</label>
                            <textarea
                                className="form-control"
                                id="txtFeedback"
                                rows="3"
                                ref={this.description}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>

        </>);
    }

    createFooter = () => {
        return (
            <>
                <div class="card-footer text-right edit-job-actions">
                    <button class="btn btn-approve" onClick={this.JobAttend}>
                        {this.state.isLoading ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : <>I will attend</>}
                    </button>
                    <button class="btn btn-primary" onClick={this.props.toggleOwnerApproveRejectDrawer.bind(this)}> Cancel</button>
                </div>
            </>
        );
    };

    JobAttend = () => {
        if (this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
            toast.error("Please enter job attend reason.", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return;
        }

        if (this.state.userId == null || this.state.userId == 'undefind') {
            this.setState({ userId: localStorage.getItem("exernalUserId") });
        }
        this.setState({
            isLoading: true
        });
        var callbackUrl = AppConfiguration.Setting().BAndAApiEndPoint;
        try {
            AXIOS.GET(`tenant-api/owner-attending-job?ownerId=${this.state.userId}&jobId=${this.props.jobDetails.id}&callbackUrl=${callbackUrl}&note=${this.description.current.value}`).then(response => {
                if (response) {
                    this.setState({
                        isLoading: false
                    });
                    toast.success('Saved as Owner will attend to the job', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.props.onJobApproveOrReject();
                    //this.props.history.push(`/external/overview?code=${this.props.jobDetails.id}&userId=${this.state.userId}`);
                }
                else {

                    this.setState({
                        isLoading: false
                    });
                    //this.props.history.push(`/external/overview?code=${this.props.jobDetails.id}&userId=${this.state.userId}`);
                    toast.error('Job�s status has been changed. Please contact the property manager for more details', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
    };


}


export default OwnerApproveRejectDrawer;
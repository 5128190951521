import React, { SetState } from "react";
import PropTypes from "prop-types";

function TextInput(props) {

    // const [textValue, setTextValue] = SetState();
    let wrapperClass = "form-group";
    if (props.error.length > 0) {
        wrapperClass += " has-error";
    }

    // if (props.value != undefined || props.value != null) {
    //     setTextValue(props.value);
    // }

    return (
        <div className={wrapperClass}>
            <label className="col-md-10 control-label p-0 m-0" htmlFor={props.id}>{props.label}</label>
            <div className="col-md-10 p-0">
                <input
                    id={props.id}
                    type="text"
                    //onChange={event => { setTextValue(event.target.value); props.value = textValue; }}
                    onChange={props.onChange}
                    name={props.name}
                    className="form-control"
                    value={props.value}
                /><span className="highlight"></span>
                <span className="bar"></span>
            </div>
            {props.error && <div className="alert alert-danger">{props.error}</div>}
        </div>
    );
}

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    error: PropTypes.string
};

TextInput.defaultProps = {
    error: ""
};

export default TextInput;

import React from 'react';
import Loader from "react-loader-spinner";
import { Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import externalService from '../../../Services/ExternalService';
import { connect } from 'react-redux';
import AssetCommanView from './AssetCommanView';
import AssetForm from './AssetForm';
import './Assets.scss';
import PropertyAssets from './PropertyAssets';

class ExternalAssets extends React.Component {

    state = {
        assetId: this.props.assetId,
        invitationId: this.props.invitationId,
        isLoading: false,
        assets:{
            id: this.props.assetId,
            assetName: '',
            brand: '',
            model: '',
            barcode: '',
            dateOfPurchase: '',
            note: '',
            attachments: [],
            frontFacingAttachments: [],
            compliancePlateAttachments: []
        },
        assetsReviewInfo:{
            propertyAddress :'',
            recipientEmail :'',
            recipientTeantName :'',
            relatedOrganizationLogo :'',
            requestedPropertyMangerId :'',
            relatedOrganizationId : '',
            requestedPropertyMangerEmail :'',
            requestedPropertyMangerName :'',
            isLinkExpired : false,
            submittedUserId:''
        },
        assetList: [],
        currentStep: 1
    }

    componentDidMount() {
        const { assetId, invitationId, encriptedUserId } = this.props;
        this.getAssetsReviewInfo(assetId,encriptedUserId,invitationId);
    }


    getAssetsReviewInfo = (assetId,encriptedUserId,invitationId) => {
        this.setState({ isLoading: true });
        try {
            externalService.getPropertyAssetReviewInfo(assetId, encriptedUserId, invitationId).then(res => {
                this.setState({
                    assetsReviewInfo: res,
                    isLoading: false
                });

                if (!res.IsLinkExpired && assetId != null && assetId != undefined && assetId != '') {
                    this.getAssets(assetId);
                }
            });
        } catch (error) {
            this.setState({
                currentStep: 10
            });
        }
    }

    
    getAssets = (assetId) => {
        this.setState({ isLoading: true });
        try {
            externalService.getAssetById(assetId).then(res => {
                this.setState({
                    assets: res,
                    isLoading: false
                });
            });
        } catch (error) {
            this.setState({
                currentStep: 10
            });
        }
        
    }

    goToNextStep = (step) => {
        console.log("Received data from child:", step);
        this.setState({ currentStep: step })
    }

    goToLastStep = (_state,step) => {
        console.log("Received data from Last child:", _state);
        this.setState({ currentStep: step });
        this.setState({ assets:{
            id: this.state.assetId,
            assetName: _state.assetName,
            brand: _state.brand,
            model: _state.model,
            barcode: _state.barcode,
            dateOfPurchase: _state.dateOfPurchase,
            note: _state.note,
            attachments: _state.attachments,
            userId: this.props.encriptedUserId
        }   
         })
    }

    submitAssetList = (_state, step) => {
        this.setState({
            currentStep: step,
            assetList: _state.assetList,
            invitationId: _state.invitationId
        })

    }

    render() {
       
        const { isLoading, assets, assetsReviewInfo } = this.state;
        return (
            <div className='asset-page'>
                <div>
                    <Navbar className="top-nav-bar shadow-lg bg-white rounded" expand="xl">
                            <div className='row'>
                                <a className="navbar-brand d-flex align-items-center justify-content-center" href="#"><img src={(assetsReviewInfo.relatedOrganizationLogo) ? assetsReviewInfo.relatedOrganizationLogo : "/images/app-logo.svg"} alt="Bricks + Agent" className="logo thumbnail" /></a>
                            </div>
                    </Navbar>
                </div>
                {assetsReviewInfo.isLinkExpired &&  
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <div id="notfound">
                                        <div className="notfound">
                                            <div className="notfound-404">
                                                <h2 className="sub-title">Url has been expired!</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>  
            }
                {(!assetsReviewInfo.isLinkExpired && this.state.currentStep == 1 || this.state.currentStep == 2 || this.state.currentStep == 4 || this.state.currentStep == 6 || this.state.currentStep == 7 || this.state.currentStep == 10) &&
                <div >
                    <AssetCommanView assetsReviewInfo={assetsReviewInfo} 
                    currentStep={this.state.currentStep} 
                    asset={this.state.assets} 
                    assetList={this.state.assetList}
                    invitationId={this.state.invitationId}
                    sendDataToParent={this.goToNextStep} ></AssetCommanView>
                </div>
                } 
                
                {(!assetsReviewInfo.isLinkExpired && this.state.assetId != null && this.state.assetId != undefined && this.state.assetId != '' && this.state.currentStep == 3) &&
                    <AssetForm currentStep={this.state.currentStep} 
                    assets={this.state.assets} submittedUserId ={assetsReviewInfo.submittedUserId} encriptedUserId={this.props.encriptedUserId}
                    goToLastStep={this.goToLastStep}></AssetForm>
                }

                {(!assetsReviewInfo.isLinkExpired && this.state.invitationId != null && this.state.invitationId != undefined && this.state.invitationId != '' && this.state.currentStep == 3) &&
                    <PropertyAssets currentStep={this.state.currentStep} invitationId={this.state.invitationId} encriptedUserId={this.props.encriptedUserId} assetList={this.state.assetList} goToLastStep={this.submitAssetList} sendDataToParent={this.goToNextStep}></PropertyAssets>
                }
             </div>
        )
    }



}

export default connect()( ExternalAssets );
import React, { useState } from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import BootstrapTable from "../../Common/Node/bootstrapTable";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import Drawer from "../../Common/Drawer/Drawer";
import { AppConfiguration } from "read-appsettings-json";
import { Dropdown } from 'semantic-ui-react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Plugins
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";

class LockRequestsDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            page: 1,
            sizePerPage: 10,
            loading: true,
            lockList: [],
            total: 10,
            isLoadingLockRequests: false,
            approvalDrawerShow: false,
            accessibleHourOption: [{ key: 1, text: "1 hours", value: 1 }, { key: 2, text: "2 hours", value: 2 }, { key: 3, text: "3 hours", value: 3 }, { key: 4, text: "Custom", value: 4 }],
            showCustomAccessibleHour: false,
            customHour: 0,
            selectedProperyLock: { id: '', jobId: '', lockId: '', requestedByUserId: '' }
        };
    }

    approveOrReject = (isApprove, row) => {

        if (!isApprove) {
            this.setState({ isLoadingLockRequests: true });
            try {
                trackPromise(
                    AXIOS.GET(
                        `tenant-api/access/property/tenant/reject-access-request?requestId=${row.id}&requestHandledBy=${localStorage.getItem('userId')}`
                    ).then(dataJson => {
                        this.setState({
                            isLoadingLockRequests: false
                        });
                        toast.success('Request Rejected', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.getPropertyLockRequests(false);
                    })
                );
            } catch (error) {
                console.log(error);
                this.setState({ isLoadingLockRequests: false });
            }
        } else {
            let selcted = {
                id: row.propertyLock.id,
                jobId: (row.jobId) ? row.jobId : '00000000-0000-0000-0000-000000000000',
                lockId: row.propertyLockId,
                requestedByUserId: row.requestedByUserId
            }
            this.setState({ approvalDrawerShow: true, selectedProperyLock: selcted });
        }
    }

    render() {
        let drawer = this.createDrawer();
        let approveDrawer = <></>;
        if (this.state.approvalDrawerShow) {
            approveDrawer = this.createApprovalDrawer();
        }
        return <div>
            {drawer}
            {(this.state.approvalDrawerShow) ?
                approveDrawer
                : ''}
        </div>;
    }

    componentDidMount() {
        this.getPropertyLockRequests(false);
    }

    getPropertyLockRequests = fromPagination => {
        this.setState({ isLoadingLockRequests: true });
        try {
            trackPromise(
                AXIOS.GET(
                    `tenant-api/access/property/tenant/lock-requests?userId=${localStorage.getItem("userId")}`
                ).then(dataJson => {
                    this.setState({
                        lockList: dataJson.lockList,
                        sizePerPage: 10,
                        total: fromPagination ? this.state.total : dataJson.total,
                        isLoadingLockRequests: false
                    });
                })
            );
        } catch (error) {
            console.log(error);
            this.setState({ isLoadingLockRequests: false })
        }
    };

    createBody = () => {
        let table = this.createDocumentTable();

        return (
            <div className="panel-body">
                <div className="my-job">
                    <div className="card-wrapper">
                        <div className="grid-container custom-responsive-grid document-list-grid">
                            <div></div>
                            {table}
                        </div>
                    </div>
                </div>
            </div>

        );
    };

    createDrawer = () => {
        if (this.props.drawerOpen) {
            let header = this.createHeader();
            let body = this.createBody();
            return (
                <Drawer
                    size={"lg"}
                    open={this.props.drawerOpen}
                    header={header}
                    body={body}
                ></Drawer>
            );
        }
    };

    createHeader = () => {
        const { isLoadingLockRequests } = this.state;
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Smart lock requests
                    {(isLoadingLockRequests) ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : ''}
                </h2>
                <div className="panel-header-actions">
                    {/* <button className="action-btn"><i className="ba-icn ba-icn-edit"></i><span className="button-label">Edit</span></button> */}
                    {/* <ChatDrawer></ChatDrawer> */}
                    <button className="action-btn panel-close" onClick={() => this.props.toggleDrawer()}>
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    handleInit = () => { };

    createDocumentTable() {

        const actionsFormatter = (cell, row, rowIndex) => {
            return (
                <div>
                    <span>
                        {/* <a href="#" onClick={() => this.approveOrReject(row)}>Approve</a> */}
                        <button className="button button-primary button-sm mr-2" type="button" onClick={() => this.approveOrReject(true, row)}>
                            Approve
                        </button>
                    </span>
                    <span>
                        {/* <a href="#" onClick={() => this.approveOrReject(row)}>Approve</a> */}
                        <button className="button button-reject button-sm" type="button" onClick={() => this.approveOrReject(false, row)}>
                            Reject
                        </button>
                    </span>
                </div>
            );
        }

        const columns = [
            { text: "ID", dataField: "propertyLock.lockId" },
            { text: "Lock Name", dataField: "propertyLock.lockName" },
            { text: "Property", dataField: "propertyLock.propertyName" },
            { text: "Job Number", dataField: "jobNumber" },
            { text: "Requested By", dataField: "requestedByUser.name" },
            {
                text: "Action",
                dataField: "extention",
                formatter: actionsFormatter
            }
        ];


        const rowEvents = {
        };

        return (
            <React.Fragment>
                <BootstrapTable
                    keyField="id"
                    data={this.state.lockList}
                    columns={columns}
                    rowEvents={rowEvents}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={10}
                    page={this.state.page}
                    //options={this.options}
                    onTableChange={this.handleTableChange}
                />
            </React.Fragment>
        );
    }

    handleTableChange = (
        type,
        { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
    ) => {
        console.log("this.state.page: ", this.state.page);
        console.log("this.state.loading: ", this.state.loading);
        this.setState(
            {
                page,
                sizePerPage,
                loading: true
            },
            () => {
                console.log("this.state.page: ", this.state.page);
                console.log("this.state.loading: ", this.state.loading);
                this.getPropertyLockRequests();
            }
        );
    };

    createApprovalDrawer = () => {
        if (this.state.approvalDrawerShow) {
            let header = this.createApprovalHeader();
            let body = this.createApprovalBody();
            let footer = this.createApprovalFooter();
            return (
                <Drawer
                    size={"lg"}
                    open={this.state.approvalDrawerShow}
                    header={header}
                    body={body}
                    footer={footer}
                ></Drawer>
            );
        }

    }

    createApprovalHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Requests Approval
                </h2>
                <div className="panel-header-actions">
                    <button className="action-btn panel-close" onClick={() => this.hideApprovalDrawer()}>
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    }

    hideApprovalDrawer = () => {
        this.setState({ approvalDrawerShow: false });
    }
    createApprovalBody = () => {

        return <>
            <div className="panel-body">
                <div className="my-job">
                    <div className="card-wrapper">
                        <div className="form-group">
                            <label htmlFor="property">Accessible Hours</label>
                            <div>
                                <Dropdown
                                    selectOnNavigation={false}
                                    selection
                                    options={this.state.accessibleHourOption}
                                    placeholder='Choose Accessible Hours'
                                    onChange={this.handleAccessibleHoursChange}
                                />
                            </div>
                        </div>
                        {(this.state.showCustomAccessibleHour) ?
                            <div className="form-group">
                                <label htmlFor="property">Custom Hours</label>
                                <div>
                                    <input type="number" className="form-control" value={this.state.customHour} min="0" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        onChange={event => this.customHourOnChange(event.target.value)} />
                                </div>
                            </div>
                            : ''}


                    </div>
                </div>
            </div>
        </>
    }

    customHourOnChange = (val) => {
        this.setState({ customHour: val, permissionPeriod: val });
    }

    handleAccessibleHoursChange = (event, props) => {
        let option = props.value;
        if (option == 4) {
            this.setState({ showCustomAccessibleHour: true });
        } else {
            this.setState({ showCustomAccessibleHour: false, permissionPeriod: option });
        }
    }

    createApprovalFooter = () => {
        return (
            <>
                <div className="card-actions">
                    <button onClick={this.hideApprovalDrawer}>Cancel</button>
                    <button onClick={this.approveLockRequest} type="button">Approve</button>
                </div>
            </>
        )
    }

    approveLockRequest = () => {
        this.hideApprovalDrawer();
        let selectedProperyLock = this.state.selectedProperyLock;
        this.setState({ isLoadingLockRequests: true });
        const jobLockViewModel = {};
        jobLockViewModel.jobId = selectedProperyLock.jobId;
        jobLockViewModel.permissions = [1];
        jobLockViewModel.propertyLock = { id: selectedProperyLock.lockId }
        jobLockViewModel.grantedToUserId = selectedProperyLock.requestedByUserId;
        jobLockViewModel.grantedByUserId = localStorage.getItem('userId');
        jobLockViewModel.permissionPeriod = 3;
        jobLockViewModel.permissionPeriodForTrade = this.state.permissionPeriod;
        try {
            trackPromise(
                AXIOS.POST(`tenant-api/access/job/grantpropertylock`, jobLockViewModel, {
                    'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret }
                })
                    .then(dataJson => {
                        this.setState({
                            isLoadingLockRequests: false
                        });
                        toast.success('Request Granted', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.getPropertyLockRequests(false);
                    })
            )
        } catch (error) {
            console.log(error);
            this.setState({ isLoadingLockRequests: false });
            toast.success('Failed to Grant Access', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            this.getPropertyLockRequests(false);
        }
    }
}

export default LockRequestsDrawer;
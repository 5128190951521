import React, { Component } from "react";
import SideNav from "./SideNav";
import ProfileDropDown from "./ProfileDropDown";

//#region [IMPORT FONT AWESOME COMPONENTS]
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faCommentAlt, faSignOutAlt, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
//#endregion
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { Navbar } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { AppConfiguration } from "read-appsettings-json";
import { Guid } from "guid-typescript";


import ChatDrawer from "../Drawers/ChatDrawer/ChatDrawer";
import ChatBoxContainer from "../Common/ChatBoxContainer/ChatBoxContainer";
import googleAnalyticsService from "../../Services/GoogleAnalyticsService"
import { connect } from "react-redux";
import { UserType } from '../../Constants/Enums';
import chatService from "../../Services/ChatService";
import JobPostDrawer from "../Drawers/JobPostDrawer/JobPostDrawer";
import notificationService from "../../Services/NotificationService";
import propertyService from "../../Services/PropertyService";
import TopNavigationBar from "./TopNavigationBar/TopNavigationBar"
//import log from '../External/LogMaintenanceBot/BricksAndAgentMaintenanceBot.js';
class Layout extends Component {
  state = {
    mainMarginLeft: 0,
    showJobPostDrawer: false,
    showChatDrawer: false,
    showChatHelper: false,
    jobId: '',
    navExpanded: false,
    styleCode:''
  };

  host = '';

  componentDidMount() {
    this.GetTenantPropertiesByUserId();
    let endPoint = AppConfiguration.Setting().BAndAApiEndPoint;
    endPoint = endPoint.charAt(endPoint.length - 1) == '/' ? endPoint : `${endPoint}/`;
    this.host = endPoint;
  }
  componentWillMount() {
    if(localStorage.getItem('styleCode') === "JLL" || localStorage.getItem('styleCode') === "jll") {
      import('./JLL/Layout.scss');
    } else {
      import('./Layout.scss');
    }
  }
  trackGoogleAnalytics = location => {
    if (location != null) {
      googleAnalyticsService.sendPageStatsViaStringToGoogle(location.pathname, "", "");
    }

  };

  setNavExpanded = (value) => {
    this.setState({ navExpanded: value });
  }

   closeNav = () => {
    this.setState({ navExpanded: false });
  }

  render() {
    this.trackGoogleAnalytics(this.props.location);
    const LoadingIndicator = props => {
      const { promiseInProgress } = usePromiseTracker();
      return (
        promiseInProgress && (
          <div className="loading-wrapper">
            <Loader type="ThreeDots" color="#DB3D44" height={30} width={100} />
          </div>
        )
      );
    };

    let logoUrl="";
    if(localStorage.getItem('styleCode')==="JLL"){
      //  logoUrl = this.host + "Images/Jll/app-logo-new.svg";       
       logoUrl = "Images/Jll/app-logo-new.svg";
    } else{
       logoUrl = "Images/app-logo.svg";
    }
    

    // const postJob = this.openPostJobDrawer(false);

    return (
      <div>
        <div id="wrapper" className="web-wrapr">
          <div id="page-content-wrapper" className="main-page-wrapper">
            <div className="main-menu fixed-top">
              <div className="container">
              <Navbar onToggle={this.setNavExpanded} expanded={this.state.navExpanded} className="top-nav-bar p-0"  expand="xl">
                <a className="navbar-brand" href="#"><img src={logoUrl} alt="Bricks + Agent" className="logo" style={{width:localStorage.getItem('styleCode')==="JLL"?"85px":""}} /></a>
                <Navbar.Toggle aria-controls="mainNavCollapsible" />
                <Navbar.Collapse id="mainNavCollapsible" className="main-nav-items">
                  <TopNavigationBar
                    host={window.location.origin}
                    pathname={window.location.pathname} 
                    closeNav={this.closeNav}/>
                  <ul className="navbar-nav ml-auto align-items-center">
                    <li className="nav-item">
                      <Button onClick={this.onClickSupport} className="support-btn" >
                        Support
                      </Button>
                    </li>
                    <ProfileDropDown  closeNav={this.closeNav}></ProfileDropDown>
                  </ul>
                </Navbar.Collapse>
              </Navbar>
              </div>
            </div>
            <div className="container mt-5 pt-5">
              
              <LoadingIndicator />
              {this.props.children}
            
            </div>
          </div>
          {(this.state.showJobPostDrawer) ?
            <JobPostDrawer
              drawerOpen={this.state.showJobPostDrawer}
              closeJobPostDrawer={this.hideJobPost}
              jobId={this.state.jobId}
            ></JobPostDrawer> : ''}
          <ChatDrawer
            show={this.state.showChatDrawer}
            toggleDrawer={this.toggleChatDrawer}
            toggleNotificationDrawer={this.toggleNotificationDrawer}
          ></ChatDrawer>
          <ChatBoxContainer></ChatBoxContainer>
        </div>
      </div>
    );
  }

  GetTenantPropertiesByUserId = () => {
    this.setState({ isPropertiesLoading: true })
    propertyService.GetTenantPropertiesByUserId()
      .then((res) => {
        this.props.updateProperties(res.properties);
      })
  }

  onClickLogout = () => {
    chatService.updateUserActiveStatus(localStorage.getItem("userIdDecripted"), false);
    localStorage.setItem('userId', null);
    localStorage.setItem('userType', null);
    let endPoint = localStorage.getItem('styleCode').toUpperCase()==="JLL" ?AppConfiguration.Setting().JllBaseUrl : AppConfiguration.Setting().BAndAApiEndPoint;
    endPoint = endPoint.charAt(endPoint.length - 1) == '/' ? endPoint : `${endPoint}/`;
    window.location.href = endPoint + 'tenant-api/log-off';
    window.location.href = endPoint + 'Account/UserLogin';
  }

  onClickSupport = () => {
    this.closeNav();
    if (this.state.showChatHelper) {
      window.Intercom('hide');
    } else {
      window.Intercom('show');
    }
    this.setState({ showChatHelper: !this.state.showChatHelper })
  }

  openPostJobDrawer = () => {
    this.setState({ showJobPostDrawer: true });
    this.setState({
      jobId: Guid.create().toString(),
    });
    googleAnalyticsService.sendEventStatsToGoogle("View", "Button click", "Post a Job");

  };

  toggleNotificationDrawer = () => {
    let chatDrawerStatus = this.state.showChatDrawer == false ? "Open" : "Close";
    this.setState({ showChatDrawer: !this.state.showChatDrawer });
    googleAnalyticsService.sendEventStatsToGoogle("View", "Button click", `${chatDrawerStatus} Chat Drawer`);

    if (this.state.showChatDrawer) {
      notificationService.ClearUserUnreadNotifications();

    }
  }

  toggleChatDrawer = () => {

    console.log()
    let chatDrawerStatus = this.state.showChatDrawer == false ? "Open" : "Close";
    this.setState({ showChatDrawer: !this.state.showChatDrawer });
    googleAnalyticsService.sendEventStatsToGoogle("View", "Button click", `${chatDrawerStatus} Chat Drawer`);
  };

  hideJobPost = () => {
    setTimeout(() => {
      this.setState({ showJobPostDrawer: false });
    }, 500);
    googleAnalyticsService.sendEventStatsToGoogle("Cancel", "Button click", "Post a Job");
  };

  // onSideNaveExpand = isExpanded => {
  //   var marginCalculated = isExpanded ? 240 : 55;
  //   this.setState({ mainMarginLeft: marginCalculated });
  // };
}

const mapStateToProps = (state, ownProps) => {
  try {
    let userDetails = state.User
    return {
      isCurrentTenant: state.Tenant.isCurrentTenant,
      userDetails: userDetails
    };
  } catch (error) {
    console.log(error);
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateProperties: properties => {
      dispatch({ type: "SET_PROPERTIES", properties: properties });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import React, { Component } from "react";
import _ from "lodash";
import { Search, Grid, Header } from "semantic-ui-react";
import jobService from "../../../Services/JobService";

var source = document.createElement("div");
// var service = new window.google.maps.places.AutocompleteService();
// var geocoder = new window.google.maps.Geocoder();

export default class GoogleMapLocationsAutoComplete extends Component {

    state = {
        isLoading: false,
        latitude: 0,
        longitude: 0,
        placeId: '',
        value: '',
        results: []
    }

    componentWillMount() {
        this.resetComponent();
    }

    resetComponent = () => {
        if(this.props.formData){
            this.setState({ isLoading: false, results: [], value: this.props.formData.address });
        }else if(this.props.location){
            this.setState({ isLoading: false, results: [], value: this.props.location });
        } 
    }

    handleResultSelect = (e, response) => {
        this.setState({ value: response.result.description });
        new window.google.maps.Geocoder().geocode({ 'address': response.result.description }, (results, status) => {
            var place = results[0];
            this.setState({ 
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng(),
                placeId: results[0].place_id
             });

             var suburbName = place.address_components.find(function (element) {
                return element.types[0] == 'locality';
            });

            var postalCode = place.address_components.find(function (element) {
                return element.types[0] == 'postal_code';
            });

            if (suburbName && postalCode) {
                jobService.GetSuburb(suburbName.long_name, postalCode.long_name).then(response => {
                    this.props.setLocationDetails({
                        latitude: this.state.latitude,
                        longitude: this.state.longitude,
                        placeId: this.state.placeId,
                        suburbId: (response == null) ? null : response.id,
                        location: (response == null) ? null : response.label,
                        address: this.state.value
                    });
                });
            } else {
                this.props.setLocationDetails({
                    latitude: this.state.latitude,
                    longitude: this.state.longitude,
                    placeId: this.state.placeId,
                    suburbId: null,
                    location: null,
                    address: this.state.value
                });
            }
            
        });
        
        //service.
        // this.props.location = response.result;
    }

    handleSearchChange = (e, { value }) => {
        var vm = this;
        this.setState({ isLoading: true, value: value });
        var countryArray =  "au";

        var countryCode = localStorage.getItem("countryCode");

        if(countryCode != "null" && countryCode != "AUS"){
            countryArray = "nz";
        }      

        var request = { input: value, types: ['geocode'], componentRestrictions: { country: [countryArray] }  }; 
        
        if (!value.length > 0) {
            vm.setState({ isLoading: false, results: [] });
            return;
        }
        // if (this.state.isLoading) {
        //     return;
        // }
        new window.google.maps.places.AutocompleteService().getPlacePredictions(request, function (results, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                vm.setState({ isLoading: false, results: results });
            }
        });
    };

    geolocate = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                var circle = new window.google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });
                window.google.maps.LatLngBounds(circle.getBounds())
                // autocomplete.setBounds(circle.getBounds());
            });
        }
    }

    render() {
        const { isLoading, value, results } = this.state;

        return (
            <Search
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                    leading: true
                })}
                results={results}
                value={value}
                {...this.props}
            />
        )
    }

}

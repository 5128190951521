import React from "react";
import Drawer from "../../Common/Drawer/Drawer";
import JobNotes from "../JobDetailsDrawer/JobNotes/JobNotes";
import ImageViewer from "../../Common/ImageViewer/ImageViewer";
import './JobNotesDrawer.scss';

class JobNotesDrawer extends React.Component {


    render() {
        const header = this.createHeader();
        const body = this.createBody();
        const footer = this.createFooter();
        return (
            <Drawer
                size={"lg"}
                open={this.props.showJobNotesDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    }


    createHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">Notes</h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={this.props.toggleJobNotesDrawer.bind(this)}>
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };


    createBody = () => {
        const jobNotes = this.props.jobNotes;
        return (<>
            <div className="panel-body">
                <div className="job-notes-drawer-container">
                    {(jobNotes.length === 0 || jobNotes == null) ? <p className="row justify-content-center w-100">Notes not available</p> :
                        jobNotes.map((note, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="job-note ng-star-inserted">
                                        <div className="row justify-content-start mb-3">
                                            <div className="col-3 text-left">
                                                <p className="user-name">{note.createdUserName}</p>
                                            </div>
                                            <div className="col text-left">
                                                <div className="row justify-content-end">
                                                    <p className="posted-date mr-3 mb-0">Posted on {note.createdOn}</p>
                                                    <p className="user-type mr-3 mb-0">{note.createdUserType}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-start mb-3">
                                            <div className="col-12 text-left">
                                                <p className="note-type">{note.type}</p>
                                                <div className="job-note-email" dangerouslySetInnerHTML={{ __html: note.note }}>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {note && note.attachments && note.attachments.length > 0 ?
                                                <div className="pt-2">
                                                    <div className="external-gallery w-100 d-inline-block">
                                                        <div className="float-left">
                                                            <ImageViewer
                                                                attachments={note.attachments}
                                                                isAfter={false}
                                                                isExternal={true}
                                                            ></ImageViewer>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })
                    }

                </div>
            </div>

        </>);
    }

    createFooter = () => {
        return (
            <>
                <div className="card-actions">
                    <button className="cancel-action" onClick={this.props.toggleJobNotesDrawer.bind(this)}>
                        Close
                    </button>
                </div>
            </>
        );
    };


}


export default JobNotesDrawer;
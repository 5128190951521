import { Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import Loader from "react-loader-spinner";
import jobService from '../../../Services/JobService';
import React from 'react';
import translateService from '../../../Services/TranslateService';
import './JobIssueTag.scss';

class JobIssueTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingJobTypeTags: false,
            jobTypeTags: [],
            jobAssignTags: [],
            isJobTypeTagsNotFound: false

        };
        this.state.jobAssignTags = this.props.data != undefined ? this.props.data.jobAssignTags:[];
    };



    componentWillMount() {
        this.getJobTypeTagsData();
    }

    render() {
        const { jobTypeTags, isLoadingJobTypeTags, isJobTypeTagsNotFound } = this.state;
        const { t } = this.props.data;
        return (
            <>
             {!isJobTypeTagsNotFound ? <div className="wizard-inner wzrd-post-a-job">
                    <div className="wizard-body">
                        {!isLoadingJobTypeTags && <div className="wizard-header mb-4">
                            <h2>{t != undefined ? t('lbl_header_of_tags') : "Choose tags that best describe your issue."}</h2>
                            <p className="usertip">({t != undefined ? t('lbl_chooseoption') : "choose an option"})</p>
                        </div>}
                        {!isLoadingJobTypeTags && <div className="form-group for-tgl-btn">
                            <ButtonToolbar className="radio-btn-group" id="jobUrgencyGroup" key="jobUrgencyGroup">

                                <ToggleButtonGroup type="checkbox" name="options" ref="jobTag" defaultValue={this.props.data.jobAssignTags} onChange={(value) => this.handleJobTypeTagOnChange(value)}
                                >
                                    {jobTypeTags.map((a, index) => {
                                        return <ToggleButton key={index} value={a.issueTagId}><span className="ba-icn ba-icn-ok"></span> {a.issueTagName}</ToggleButton>
                                    })
                                    }
                                </ToggleButtonGroup>

                            </ButtonToolbar>
                        </div>}
                        {isLoadingJobTypeTags && <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />}
                        {isJobTypeTagsNotFound && <div> <h2>{t != undefined ? t('lbl_tags_not_found') : "Tags not found for selected job type"}</h2></div>}
                    </div>
                    {!isLoadingJobTypeTags && <div className="wizard-actions">
                        <button type="button" className="btn btn-secondary" onClick={() => this.props.data.previousStep(this.props.data.index)} style={this.props.data.secondaryButtonStyle}>{t != undefined ? t('btn_back') : "BACK"}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.props.data.nextStep({ data: this.state.jobAssignTags, index: this.props.data.index, t: t })} style={this.props.data.primaryButtonStyle}>{t != undefined ? t('btn_next') : "NEXT"}</button>
                    </div>
                    }
                </div>
             :''}
            </>);
    }

    getJobTypeTagsData() {
        this.setState({
            isLoadingJobTypeTags: true
        });
        const { t, isFromBot, i18n } = this.props.data;
        let trgLng = window.lng == undefined ? 'en' : window.lng;
        if (isFromBot) {
            trgLng = (i18n.language == "en-US" || i18n.language == "en-GB") ? "en" : i18n.language;
        }
        jobService.GetJobTypeTags(this.props.data.childJobTypeId).then(result => {           
                var tagList =[];
            
                if (result.length > 0) {
                    tagList = result.map(function(value) { return value['issueTagName']; });
                
                this.applyTranslation(tagList, "en", trgLng).then(trans => {
                    if(trans.length > 0){
                        result.map(function(value,index) { return value.issueTagName = trans[index]});                       
                    }
                    if(result.length >0){
                        this.setState({
                            jobTypeTags: result,
                            isLoadingJobTypeTags: false,
                            isJobTypeTagsNotFound: false
                        });
                    }                
            });
        }else {
            if(this.props.data.previousIndex > this.props.data.index ){
                this.props.data.previousStep(this.props.data.index);
            }else{
                this.props.data.nextStep({ data: this.state.jobAssignTags, index: this.props.data.index, t: t});
            }                
            
        }
        });
    }

    handleJobTypeTagOnChange = (value) => {
        this.setState({ jobAssignTags: value });
    }

    applyTranslation(texts, sourceLng, targetLng) {
        const requestModel = {
            text: texts,
            targetCode: targetLng,
            sourceCode: sourceLng
        };
        return translateService.translate(requestModel);
    }
}

export default JobIssueTag;
import * as React from "react";
import StarRatingComponent from "react-star-rating-component";



const FeedbackViewer = (props) => {


    return (
        <>
            {(props.bidFeedbackList && props.bidFeedbackList.bidFeedbackProviderViewModel && props.bidFeedbackList.bidFeedbackProviderViewModel.length > 0) ? <div className="" >
                <div className="col-md-12 p-0">
                    <div className="row">
                        <div className="col-md-12 pt-2"><h6>Overall Rating</h6></div>
                        <div className="col-md-12">
                            <div className="row">
                                {props.bidFeedbackList.bidFeedbackProviderViewModel.map((feedBackUser, index) => {
                                    return <>
                                        <div className="col-md-12" >
                                            {(feedBackUser.feedBackUserType == 3 && props.showFeedbackOverView) ? <div className="mt-2">
                                                <strong>Property Manger</strong>
                                            </div> : ''}
                                            {(feedBackUser.feedBackUserType == 4 && props.showFeedbackOverView) ? <div >
                                                <strong>Tenant</strong>
                                            </div> : ''}
                                            {(feedBackUser.feedBackUserType == 2 && props.showFeedbackOverView) ? <div >
                                                <strong>Consumer</strong>
                                            </div> : ''}

                                            <div className="row">
                                                {(props.showFeedbackOverView) ? <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-2 mb-2">{feedBackUser.user}</div>
                                                        <div className="col-md-12 feedbackNote mb-2">{feedBackUser.feedback}</div>
                                                    </div>
                                                </div> : ''}
                                            </div>
                                            <div className="dashboard-grid">
                                                <table className="received-feedBack col-md-12">
                                                    {props.bidFeedbackList.bidRatings.map((bidRating, index) => {
                                                        return <>
                                                            <tr >
                                                                {(bidRating.ratedUser == feedBackUser.userId) ? <td className="pl-2">
                                                                    <span>{bidRating.ratingsCriteria}</span>
                                                                </td> : ''}
                                                                {(bidRating.ratedUser == feedBackUser.userId) ? <td className="pr-2 text-right">
                                                                    <StarRatingComponent
                                                                        name="feedbacStarkRating"
                                                                        starCount={5}
                                                                        value={bidRating.rating}

                                                                    />
                                                                </td> : ''}
                                                            </tr >
                                                        </>
                                                    })}
                                                </table >
                                            </div >
                                        </div >
                                    </>
                                })}
                            </div >
                        </div >
                    </div >
                </div >
            </div > : ''}
            {(props.bidFeedbackList && props.bidFeedbackList.bidFeedbackProviderViewModel && props.bidFeedbackList.bidFeedbackProviderViewModel.length == 0) ? <div>
                No feedback to show
  </div> : ''}


        </>
    )
};

export default FeedbackViewer;
import { AppConfiguration } from "read-appsettings-json";
import Firebase from "firebase";
const appsettings = AppConfiguration.Setting();

export const firebaseConfig = {
  apiKey: appsettings.PushSettings.RealtimeApiKey,
  authDomain: appsettings.PushSettings.RealtimeAuthDomain,
  databaseURL: appsettings.PushSettings.RealtimeDatabaseURL,
  projectId: appsettings.PushSettings.RealtimeProjectId,
  storageBucket: appsettings.PushSettings.RealtimeStorageBucket,
  messagingSenderId: appsettings.PushSettings.RealtimeMessagingSenderId
}
export const initFirebaseApp = () => {
    Firebase.initializeApp(firebaseConfig);
}


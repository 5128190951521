import React from 'react';
import {
    Form, Container, Row, Button, Accordion, Card,
    Col
} from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import * as _ from 'lodash';
import { Label } from 'semantic-ui-react';
import AssetForm from './AssetForm';
import { v4 as uuidv4 } from 'uuid';

class PropertyAssets extends React.Component {
    state = {
            invitationId: this.props.invitationId,
            assetList: this.props.assetList,
            assetCount: 0,
            assetComponentCount: 1,
            addComponentDisabled: false,
            asset: {
                id: uuidv4(),
                assetName: '',
                brand: '',
                model: '',
                barcode: '',
                dateOfPurchase: new Date(),
                note: '',
                attachments: [],
                frontFacingAttachments: [],
                compliancePlateAttachments: [],
                openRemoveConfirmation: false,
                isUpdated: false,
                isOpen: true                
            }, 
            
            isValidationError: false,
            validationErrorMessage: "",        
            currentStep:  this.props.currentStep
        }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.state.assetList.length == 0) {
            this.setState({
                assetList: [...this.state.assetList, {
                    id: uuidv4(),
                    assetName: '',
                    brand: '',
                    model: '',
                    barcode: '',
                    dateOfPurchase: new Date(),
                    note: '',
                    attachments: [],
                    frontFacingAttachments: [],
                    compliancePlateAttachments: [],
                    openRemoveConfirmation: false,
                    isUpdated: false,
                    isOpen: true                  
                }]
            });
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleDateChange = (date) => {
        this.setState({ dateOfPurchase: date });
    }

    handleFileChange = (e) => {
        this.setState({ attachment: e.target.files[0] });
    }

    handleClick = (page) => {
        this.props.sendDataToParent(page);
    };

    addAssetComponent = (count) => {
        if (this.state.assetComponentCount >= 49) {
            this.setState({ addComponentDisabled: true });
        }

        this.setState({ assetComponentCount: count + 1 });
        var asset = {
            id: uuidv4(),
            assetName: '',
            brand: '',
            model: '',
            barcode: '',
            dateOfPurchase: new Date(),
            note: '',
            attachments: [],
            frontFacingAttachments: [],
            compliancePlateAttachments: [],
            openRemoveConfirmation: false,
            isUpdated: false,
            isOpen: true
        };
        this.state.assetList.push(asset);
        
    }

    addToList = (_state) => {

        var array = [...this.state.assetList];
        array[_state.assetIndex] = {
            ...array[_state.assetIndex],
            assetName: _state.assetName,
            brand: _state.brand,
            model: _state.model,
            barcode: _state.barcode,
            dateOfPurchase: _state.dateOfPurchase,
            note: _state.note,
            attachments: [..._state.frontFacingAttachments, ..._state.compliancePlateAttachments],
            frontFacingAttachments: _state.frontFacingAttachments,
            compliancePlateAttachments: _state.compliancePlateAttachments,
            isUpdated: true
        }

        this.setState({
            assetList: array,
            assetCount: this.state.assetCount + 1
        });
    }

    editAsset = (asset, index) => {
        var array = [...this.state.assetList];
        array[index] = {
            ...array[index],
            isUpdated: false,
        }

        this.setState({
            assetList: array,
            assetCount: this.state.assetCount - 1
        });
    }

    removeAssetConfirmation = (index) => {
        var array = [...this.state.assetList];
        array[index] = {
            ...array[index],
            openRemoveConfirmation: true,
        }

        this.setState({
            assetList: array
        });
    }

    removeAsset = (index) => {
        var array = [...this.state.assetList];

        array.splice(index, 1);
        this.setState({
            assetList: array,
            assetCount: this.state.assetCount - 1,
            assetComponentCount: this.state.assetComponentCount - 1,
            addComponentDisabled: false
        });
    }

    cancelAssetRemove = (index) => {
        var array = [...this.state.assetList];
        array[index] = {
            ...array[index],
            openRemoveConfirmation: false,
        }

        this.setState({
            assetList: array
        });
    }

    removeFromList(fileId, index) {
        var attachments = this.state.assetList[index].attachments.filter(function (el) {
            return el.fileId !== fileId;
        });

    }

    handleSubmit = () => {
        var updatedArray = _.filter(this.state.assetList, f => f.isUpdated == true);
   
        if (updatedArray.length > 0) {
            this.setState({ assetList: updatedArray, isValidationError: false });
            this.props.goToLastStep(this.state, 7);
        } else {
            this.setState({
                isValidationError: true,
                validationErrorMessage: "Add atleast one asset to continue"
            });
        }
    }  

    toggleAccordion = (index, itemIsOpen) => {
        //this.setState({ isOpen: !this.state.isOpen });

        var array = [...this.state.assetList];
        array[index] = {
            ...array[index],
            isOpen: !itemIsOpen,
        }

        this.setState({
            assetList: array,
        });
    };
   

    render() {
        return (
            <>
                <div className="justify-content-center w-100 d-flex align-items-center flex-column align-items-center bg-light-blue m-0 px-0">
                    <Col md={3} className='mx-0 px-0'>
                        <div className='asset-count text-center form-color'>{this.state.assetCount}/50 Assets</div>
                        <div className='d-flex'>
                            <button className='add-asset new-asset v-align' onClick={() => this.addAssetComponent(this.state.assetComponentCount)} disabled={this.state.addComponentDisabled}><img src="/Images/add_icon.svg" /> New Asset</button>
                        </div>
                        {(this.state.assetList && this.state.assetList.length > 0) &&
                            this.state.assetList.map((item, index) => (
                                <Accordion defaultActiveKey={index+1} key={item.id}>
                                    <Card>
                                        <Card.Header className='custom-card-header form-color'>
                                            <div className='d-flex justify-content-between border-bottom py-4'>
                                                <div className='d-flex'>
                                                    <img className='asset-img pr-2' src="/Images/asset_icon.svg" />
                                                    <div className='asset-label'>Asset {index + 1}</div>
                                                </div>
                                                <div className='d-flex'>
                                                    {item.isUpdated && <div className='pr-4'><img src="/Images/tick.svg" /></div>}
                                                    <div>
                                                        <Accordion.Toggle
                                                            eventKey={index+1}
                                                            onClick={() => this.toggleAccordion(index, item.isOpen)}
                                                            aria-expanded={item.isOpen ? 'true' : 'false'} 
                                                            className={item.isOpen ? "rotate" : ""}
                                                            as={Button} 
                                                            variant="link" 
                                                            style={{ padding: 0, border: 'none', background: 'none' }}
                                                        >
                                                            <img className='v-align' src="/Images/down.svg" alt="Arrow" />
                                                        </Accordion.Toggle>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Accordion.Collapse className='form-color' eventKey={index+1}>
                                            {!item.isUpdated ?
                                                <AssetForm assets={item} invitationId={this.state.invitationId} encriptedUserId={this.props.encriptedUserId} currentStep={this.state.currentStep} addToList={this.addToList} assetIndex={index}></AssetForm>
                                                :
                                                <Container className='container container bg-white py-3'>
                                                    <div className='d-flex'>
                                                        <div className='col-md-6 pb-3 p-0 asset-type'>Asset Name</div>
                                                        <div className='col-md-6 text-right pb-3 p-0 asset-detail'>{item.assetName}</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='col-md-6 pb-3 p-0 asset-type'>Brand</div>
                                                        <div className='col-md-6 text-right pb-3 p-0 asset-detail'>{item.brand}</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='col-md-6 pb-3 p-0 asset-type'>Model</div>
                                                        <div className='col-md-6 text-right pb-3 p-0 asset-detail'>{item.model}</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='col-md-6 pb-3 p-0 asset-type'>Barcode</div>
                                                        <div className='col-md-6 text-right pb-3 p-0 asset-detail'>{item.barcode}</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='col-md-6 pb-3 p-0 asset-type'>Date of Purchase</div>
                                                        <div className='col-md-6 text-right pb-3 p-0 asset-detail'>{item.dateOfPurchase.toString()}</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='col-md-6 pb-3 p-0 asset-type'>Note</div>
                                                        <div className='col-md-6 text-right pb-3 p-0 asset-detail'>{item.note}</div>
                                                    </div>
                                                    <div className='col-md-6 pb-3 p-0 asset-type'>Attachments</div>
                                                    <div className='pb-3'>
                                                        {item.attachments ? (
                                                            <ImageViewer
                                                                RemoveFromList={(fileid) => {
                                                                    this.removeFromList(fileid, index);
                                                                }}
                                                                attachments={item.attachments}
                                                                isFromBot={false}
                                                                isFromTenantManageJobPost={false}
                                                                isFromAsset_ff_cp={true}
                                                            ></ImageViewer>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    
                                                    <div className='d-flex justify-content-end pt-100'>
                                                        <div className='d-flex text-end'>
                                                            <img className='v-align' src="/Images/edit.svg" />
                                                            <button className='edit-asset v-align' onClick={() => this.editAsset(item, index)}>Edit</button>
                                                        </div>
                                                        <div className='d-flex delete-asset text-end ml-3'>
                                                            <img className='v-align' src="/Images/delete.svg" />
                                                            <button className='delete-asset v-align' onClick={() => this.removeAssetConfirmation(index)}>Delete</button>
                                                        </div>
                                                    </div>
    
                                                    {item.openRemoveConfirmation &&
                                                        <div className="align-items-center justify-content-center gap-2 pt-5">
                                                            <h4 className='text-center'>Are you sure you want to delete the asset "{item.assetName}"?</h4>
                                                            <div className='justify-content-around text-center'>
                                                            <button className='btn bg-dark text-white border-radius-22-5 mr-3 fs-12 btn-size' onClick={() => this.cancelAssetRemove(index)}>No</button>
                                                            <button className="btn bg-red text-white border-radius-22-5 fs-12 btn-size" onClick={() => this.removeAsset(index)}>Yes</button>
                                                            </div>
                                                        </div>
                                                    }
                                                </Container>
                                                }
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            ))}
                    </Col>
                </div>
                <div className='shadow-lg bg-white w-100 py-5 text-center'>
                    <button className="btn bg-dark text-white border-radius-22-5 mr-3 fs-12 btn-size" onClick={() => this.handleClick(2)}>
                        GO BACK
                    </button>{" "}
                    <button variant="primary" type="submit" className="btn bg-purple text-white btn-size border-radius-22-5" onClick={() => this.handleSubmit()}>
                        I'M DONE
                    </button>
                </div>
            </>
        );
    }
    
}


export default PropertyAssets;

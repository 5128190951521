import React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import chatService from '../../../../Services/ChatService';
import MessagePost from './MessagePost/MessagePost';

class ChatBox extends React.Component {

    state = {
        conversationList: [],
        receiversId: [],
        chatMessageCount: 50,
        isInitialChatChunk: true,
        unreadChat: [],
        isUnread: false,
        isJobSpecificChat: false,
        workflowStatus: 0,
        conversation: {
            conversationId: '',
            conversationName: '',
            isPrivate: true,
            isRead: false,
            lastMessage: '',
            receiver: '',
            receiverFirstName: '',
            receiverImageUrl: '',
            receiverLastName: '',
            receiverName: '',
            sender: '',
            senderFirstName: '',
            senderImageUrl: '',
            senderName: '',
            unReadMessageCount: 0
        },
        isMinimized: false,
        conversationTread: {},
        conversationId: '',
        conversationCommon: {},
        profileImageThumbnailCdn: 'https://ancillary-dev-profile-thumbnails.azureedge.net/'
    }

    render() {
        const chatHeading = this.getConversation();
        const messeges = this.getMesseges();
        let jobChatCompletedMessage = '';
        if (this.state.isJobSpecificChat && this.state.workflowStatus == 7) {
            jobChatCompletedMessage = <div className="ch-global-message">
                <span>This chat thread is no longer active due to the job being completed.</span>
            </div>
        }

        return (
            <div className="chat-locator">
                <div className="chat-container">
                    {chatHeading}
                    <div className="ch-body">
                        <div ref={this.chatBoxBody}
                            className={this.state.isMinimized ? 'ch-body-inner d-none' : 'ch-body-inner'}>
                            {messeges}
                            {jobChatCompletedMessage}
                        </div>
                    </div>
                    <MessagePost 
                        conversationTread={this.props.conversation} 
                        options={{ isJobSpecificChat: this.state.isJobSpecificChat, isJobCompleted: this.state.workflowStatus == 7 }}></MessagePost>
                </div>
            </div>
        );
    }

    constructor(props) {
        super(props); 
        this.chatBoxBody = React.createRef();
    }

    componentDidMount() {
        this.getConversationInput();
        this.getConversationReadStatus();

    }

    componentDidUpdate() {
        let chatBoxBody = this.chatBoxBody.current;
        chatBoxBody.scrollTop = chatBoxBody.scrollHeight;
    }

    getConversationReadStatus = () => {
        chatService.getConversationReadStatus(localStorage.userIdDecripted, this.props.conversation.conversationId)
            .on('value', snapshot => {
                let isRead = snapshot.val();
                this.setState(state => ({ isUnread: !isRead }));
            });
    }

    getMesseges = () => {
        const msgItems = this.state.conversationList;
        return msgItems.map((item, index) => {
            const senderMsg = this.getSenderMessageInfo(item)
            const receivermsg = this.getReceiverMsgInfo(item);
            if (localStorage.getItem('userIdDecripted') == item.senderId) {
                return (
                    <div key={index}>
                        {senderMsg}
                    </div>
                )
            } else {
                return (
                    <div key={index}>
                        {receivermsg}
                    </div>
                )
            }

        });
    }

    getReceiverMsgInfo(item) {
        let globalText = '';
        if (this.state.unreadChat && item.timestamp == this.state.unreadChat.timestamp) {
            globalText = <div className="ch-global-message">
                <span className="unread-chats">_____________________ Unread Chat _____________________</span>
            </div>
        }

        if (item.isEmoteText) {
            return <>
                {globalText}
                <div className="ch-global-message ">
                    <span>{item.message}</span>
                </div>
            </>
        } else {
            return <>
                {globalText}
                <div className="ch-set ch-left ">
                    <div className="ch-pic">
                        <img src={this.state.profileImageThumbnailCdn + item.senderId + '.jpg'} title={item.senderName}
                        onError={(e)=>{e.target.onerror = null; e.target.src="/Images/default-profile.png"}} />
                    </div>
                    <div>
                        <span className="ch-right">{item.message}</span>
                        <div className="ch-dateTime">{item.senderFirstName}, {item.sentDate}</div>
                        {/* {(item.senderBusinessName && item.senderBusinessName != null && item.senderBusinessName != 'null') ? (
                            <div className="ch-datetime">
                                {item.senderBusinessName}
                            </div>
                        ) : ('')} */}
                    </div>
                </div>
            </>
        }
    }

    getSenderMessageInfo(item) {
        if (item.isEmoteText) {
            return <>
                <div className="ch-global-message ">
                    <span>{item.message}</span>
                </div>
            </>
        } else {
            const seen = this.getSeenByList(item)
            return <>
                <div className="ch-set ch-right ">
                    <span >{item.message}</span>
                    <div className="ch-dateTime">{item.sentDate}</div>
                    {seen}
                </div>
            </>
        }
    }

    getSeenByList = (conMessage) => {
        if(conMessage.seenBy !== undefined) {
            let messageSeenByList = Object.keys(conMessage.seenBy).map(key => ({type: key, value: conMessage.seenBy[key]}));

            if(messageSeenByList.length > 0) {
                return <div>
                    {messageSeenByList.map((seenMsg, index) =>
                        <div className="ch-dateTime" key={index}>
                            Seen by<b> {seenMsg.value.firstName} </b>, {seenMsg.value.seenTime}, 
                        </div>
                    )}
                </div>
            } else {
                return 
            }
        } else {
            return 
        }
    }

    getConversation = () => {
        const conversationData = this.props.conversation;
        const convType = this.getTitleByConversationType(conversationData);
        return (
            <>
                <div className="ch-heading" className={this.state.isUnread ? 'ch-heading unread-chats' : 'ch-heading'}>
                    {convType}
                    <div className="ch-font-up ch-addition">
                        <i className="ba-icn ba-icn-font-up" data-toggle="tooltip" data-placement="right" title="Font Size" ></i>
                    </div>
                    {!(this.state.isJobSpecificChat && this.state.workflowStatus == 7) ? 
                        <> 
                            <div className="ch-webcam ch-addition d-none" >
                                <i className="ba-icn ba-icn-webcam"></i>
                            </div>
                            <div className="ch-user-plus ch-addition">
                                <i className="ba-icn ba-icn-user-plus"></i>
                            </div>
                        </> : ''
                    }
                    <div onClick={() => { this.setState({ isMinimized: true }) }} className={this.state.isMinimized ? 'ch-minimize d-none' : 'ch-minimize'}>
                        <i className="ba-icn ba-icn-minimize"></i>
                    </div>
                    <div onClick={() => { this.setState({ isMinimized: false }) }} className={this.state.isMinimized ? 'ch-minimize' : 'ch-minimize d-none'}>
                        <i className="ba-icn ba-icn-maximize"></i>
                    </div>
                    <div className="ch-close" onClick={() => {
                        let selectedChat = conversationData;
                        let chats = (this.props.chatBoxes == '') ? [] : JSON.parse(this.props.chatBoxes);
                        
                        const filteredChats = chats.filter((chat) => {
                            return chat.conversationId !== selectedChat.conversationId;
                        });
                        const chatsStr = (filteredChats.length > 0) ? JSON.stringify(filteredChats) : '';
                        this.props.updateChatboxes(chatsStr);
                    }}>
                        <i className="ba-icn ba-icn-close"></i>
                    </div>
                </div>
            </>
        ) 
    }

    getTitleByConversationType = (conversation) => {
        if (conversation.isPrivate) {
            return (
                <>
                    <div className="ch-profile-img">
                        <img src={this.props.profileThumbnailsCdnEndpoint + conversation.receiver + ".jpg"} alt=""
                        onError={(e)=>{e.target.onerror = null; e.target.src="/Images/default-profile.png"}} />
                    </div>
                    <div className="ch-title">
                        <input type="text" disabled="disabled" value={conversation.conversationName}/>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="ch-profile-img">
                        <img src="/images/default-group-profile.png" alt="" />
                    </div>
                    <div className="ch-title">
                        <input type="text" value={conversation.conversationName} onChange={() => { }} 
                        data-toggle="tooltip" title={conversation.conversationName}/>
                    </div>
                </>
            )
        }
    }

    getConversationInput() {
        let tread = this.props.conversation;
        if (tread.conversationId !== undefined) {
            chatService.resetConversationMessages(tread);
        }
        if (tread.conversationId === undefined && tread.receiver === undefined) {
            return;
        }
        this.setState({ conversation: tread });
        this.getConversationId(tread);
    }

    getConversationId(tread) {
        this.state.receiversId.push(tread.receiver);
        let stateTread = tread;
        if (tread.conversationId === '' || tread.conversationId === undefined) {
            stateTread.isPrivate = true;
            this.setState({ conversation: stateTread });
            chatService.getConversationIdByUser(stateTread.sender)
                .on('value', snapshot => {
                    let dataList = [];
                    snapshot.forEach(childSnapshot => {
                        const childKey = childSnapshot.key;
                        let childData = childSnapshot.val();
                        childData.key = childKey;
                        dataList.push(childData);
                    });

                    let findx = dataList.findIndex(data => {
                        return data.receiver == stateTread.receiver;
                    });

                    if (findx >= 0) {
                        if (!stateTread || !stateTread.conversationId || !stateTread.conversationId == '' || !stateTread.conversationId == undefined) {
                            stateTread.conversationId = dataList[findx].key;
                            let chatsObj = JSON.parse(this.props.chatBoxes);
                            let chatObjIndex = chatsObj.findIndex(a => {
                                return a.conversationId === undefined && a.receiver === stateTread.receiver && a.sender === stateTread.sender;
                            });
                            if(chatObjIndex >= 0) {
                                chatsObj[chatObjIndex] = stateTread;
                                this.props.updateChatboxes(JSON.stringify(chatsObj));
                            }
                            this.setState({ conversation: stateTread });
                            this.getConversationDetails(stateTread);
                        }
                        if (stateTread && stateTread.conversationId && stateTread.conversationId !== '' && stateTread.conversationId !== undefined) {
                            this.getConversationDetails(stateTread);
                        }
                    } else {
                        let newConversationId = chatService.createPrivateConversation(stateTread);
                        if (this.isConversationIdNull(stateTread)) {
                            this.resetConversationData(stateTread.conversationId)
                        }

                        this.props.conversation.conversationId = newConversationId;
                        stateTread.conversationId = newConversationId;
                        stateTread.isPrivate = true;
                        let chatsObj = JSON.parse(this.props.chatBoxes);
                        let chatObjIndex = chatsObj.findIndex(a => {
                            return a.conversationId === undefined && a.receiver === stateTread.receiver && a.sender === stateTread.sender;
                        });
                        if(chatObjIndex >= 0) {
                            chatsObj[chatObjIndex] = stateTread;
                            this.props.updateChatboxes(JSON.stringify(chatsObj));
                        }
                        this.setState({ conversation: stateTread });
                        this.getConversationDetails(stateTread);
                    }
                });
        } else {
            this.getConversationDetails(stateTread);
        }
    }

    isConversationIdNull = (conversation) => {
        if (conversation.ConversationId === null || conversation.ConversationId === undefined) {
            return true;
        }
        else {
            return false;
        }
    }

    resetConversationData = (conversationId) => {
        chatService.resetConversationData(conversationId);
    }

    getConversationDetails = (conversation) => {
        if (conversation.conversationId !== undefined) {
            this.setState({ conversationId: conversation.conversationId });
            chatService.getConversationByIdChunk(conversation.conversationId, this.state.chatMessageCount)
                .on('value', (snapshot) => {
                    let conList = [];
                    if (this.props.conversation.conversationId === conversation.conversationId) {
                        snapshot.forEach((childSnapshot) => {
                            const childKey = childSnapshot.key;
                            let childData = childSnapshot.val();
                            childData.key = childKey;
                            conList.push(childData);
                        });
                        let unreadChats = this.getFirstUnreadChat(conList);
                        this.setState({
                            isInitialChatChunk: true,
                            conversationList: conList,
                            unreadChat: unreadChats
                        });

                        if(unreadChats) {
                            conversation.jobId = unreadChats.subTypeId;
                            this.setState({ conversation: conversation });
                        }
                        if(conList.length > 0) {
                            let statusId = 0;
                            if (conList[0].subType == 1) {
                                if (conversation.jobId == undefined) {
                                    conversation.jobId = conList[0].subTypeId;
                                }
                                chatService.getConversationSubTypeStatus(conversation.jobId)
                                .then(data => {
                                    statusId = data;
                                    this.setState(state => ({ isJobSpecificChat: true, workflowStatus: statusId }));
                                });
                            }
                            this.setState(state => ({ isJobSpecificChat: conList[0].subType == 1, workflowStatus: statusId }));
                        } 
                    } else {
                        chatService.resetConversationMessages(conversation);
                    }

                    this.getConversationChanges(conversation);
                    // this.checkConversationType(conversation);
                    // this.checkIfConversationDisabled();
                });
        }
    }

    getConversationChanges = (conversation) => {
        if (conversation.conversationId !== undefined) {
            chatService.getConversationChanges(conversation)
                .on('value', (snapshot) => {
                    const data = snapshot.val();
                    if (data && data["conversationId"] == this.props.conversation.conversationId) {
                        this.setState({
                            conversationCommon: {
                                isRead: data["isRead"]
                            },
                            conversation: {
                                conversationName: data["conversationName"],
                                IsPrivate: data["isPrivate"]
                            }
                        });
                        // if (this.state.isInitialChatChunk) {
                        //     this.scrollToBottom();
                        // }
                    }
                });
        } else {
            this.setState({
                conversationCommon: {
                    isRead: true
                }
            });
        }

    }

    getFirstUnreadChat = (items) => {
        let allUnreadMsgs = items.filter((item) => {
            const seenBy = (item.seenBy) ? Object.values(item.seenBy) : [];
            return localStorage.getItem('userIdDecripted') !== item.senderId && (!item.seenBy || (item.seenBy && !_.some(seenBy, ['userId', localStorage.getItem('userIdDecripted')])))
        })
        return _.minBy(allUnreadMsgs, data => {
            return new Date(data.timestamp);
        });
    }
}


const mapStateToProps = (state, ownProps) => {

    try {
        let chats = state.Conversation.conversationList

        return {
            chatBoxes: chats,
            imageThumbnailsCdnEndpoint: state.AppSettings.imageThumbnailsCdnEndpoint,
            imageCdnEndpoint: state.AppSettings.imageCdnEndpoint,
            profileThumbnailsCdnEndpoint: state.AppSettings.profileThumbnailsCdnEndpoint,
        };
    } catch (error) {
        console.log(error)
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        updateChatboxes: (chatList) => { dispatch({ type: 'SHOW_CONVERSATION', chatList: chatList }) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);
import React from "react";
import Loader from "react-loader-spinner";
import { Badge } from "react-bootstrap";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import settingsService from "../../../../Services/settingsService";
import { StripeProvider, Elements, ReactStrpeElements } from 'react-stripe-elements';
import CardSection from "./CardSection"
class Payment extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        cardDetails: [],
        stripe: null,
        loading: false
    };

    componentDidMount() {
        this.getCardDetailsByUserId(this.props.userId);
        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(localStorage.getItem('spk')) });

        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({ stripe: window.Stripe(localStorage.getItem('spk')) });
            });
        }
    }

    refresh = () => {
        this.getCardDetailsByUserId(this.props.userId);
    }
    getCardDetailsByUserId(userId) {
        settingsService
            .getCardDetailsByUserId(userId)
            .then((res) => {
                this.setState({
                    cardDetails: res
                });
                if (this.props.isFromTenantManageJob) {
                    this.props.checkCardAvailability();
                }
            });
    }


    render() {
        let { cardDetails } = this.state;
        let cardDetailsView = this.loadCardDetails(cardDetails);
        return (<>
            {cardDetailsView}
        </>
        )
    }

    addCardDetails = () => {
        return (
            <StripeProvider stripe={this.state.stripe} >
                <Elements >
                    <CardSection stripe={this.state.stripe} refresh={() => this.refresh()} isFromTenantManageJob={this.props.isFromTenantManageJob}
                      nextStep={this.props.nextStep} />
                </Elements>
            </StripeProvider>
        );
    }

    loadCardDetails = (cardDetails) => {

        let defaultCard = (cardDetails) ? cardDetails.filter(m => m.isDefault === true) : null;
        let otherCards = (cardDetails) ? cardDetails.filter(m => m.isDefault === false) : null;
        return (
            <div className="d-flex flex-column align-items-center">
                <div className="d-flex mt-2">
                    <h4 className='text-center font-regular text-dark'>Add new card</h4>
                </div>
                <div className="">
                    {this.addCardDetails()}
                </div>
                <div className="d-flex mt-2">
                    {(defaultCard.length !== 0 || otherCards.length !== 0) ? <h4 className='text-center font-regular text-dark'>Card Details</h4> : ""}
                </div>
                <div className="d-flex mt-2">
                    <Loader visible={this.state.loading} type="ThreeDots" color={"#DB3D44"} height={10} width={100} />
                </div>
                <div className="d-flex flex-column flex-lg-row flex-wrap w-100 justify-content-center">
                    {(defaultCard && defaultCard !== null) ?
                        defaultCard.map((item) => (
                            <div className="col-12 col-lg-4 float-left" key={item.id}>
                                <div className="credit-card-default">
                                    <div className="p-4">
                                        <div className="card-type">{item.brand}</div>
                                        <div className="d-flex justify-content-between">
                                            <div className="card-data">
                                                Card number
                                                <strong>{item.cardMask}</strong>
                                            </div>
                                            <div className="card-data text-right">
                                                Exp.
                                                <strong>{item.exp_month}/{item.exp_year}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)) : ""}
                    {(otherCards && otherCards !== null) ?

                        otherCards.map((item) => (
                            <div className="col-12 col-lg-4 float-left" key={item.id}>
                                <div className="credit-card">
                                    <div className="p-4">
                                        <div className="card-type text-dark">{item.brand}</div>
                                        <div className="d-flex justify-content-between">
                                            <div className="card-data  text-dark">
                                                Card number
                                                <strong>{item.cardMask}</strong>
                                            </div>
                                            <div className="card-data  text-dark text-right">
                                                Exp.
                                                <strong>{item.exp_month} / {item.exp_year}</strong>
                                            </div>
                                        </div>
                                        <div className="card-data">
                                            <button className="button btn light-blue-button py-2 button-sm font-12 float-right ml-1" type="button" onClick={() => this.remove(item.id)}>
                                                Remove
                                            </button>
                                            <button className="button btn next-btn py-2 button-sm font-12 float-right" type="button" onClick={() => this.setDefault(item.id)}>
                                                Set Default
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : ""}
                </div>
            </div>)
    }

    remove = (id) => {
        var model = {
            'userId': localStorage.getItem('userId'),
            'cardToken': id
        }
        this.setState({ loading: true });
        settingsService.deleteCardDetails(model)
            .then((res) => {
                this.setState({ loading: false });
                if (res.status === 18) {
                    toast.success("Card Removed Successful.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error('Card Removed Unsuccessful.', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                this.refresh();
            });
    }

    setDefault = (id) => {
        var model = {
            'userId': localStorage.getItem('userId'),
            'cardToken': id
        }
        this.setState({ loading: true });
        settingsService.setDefaultCardDetails(model)
            .then((res) => {
                this.setState({ loading: false });
                if (res.status === 18) {
                    toast.success("Set Default Card Successful.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Set Default Card Unsuccessful.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                this.refresh();
            });

    }

}


export default Payment;

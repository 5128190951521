//import Grid from '../../Components/Grid';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from "react-loader-spinner";

import Drawer from '../../Common/Drawer/Drawer';
import './JobPostDrawer.scss';
import AutoComplete from '../../Common/AutoComplete/AutoComplete';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton, Form, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Search, Label, Dropdown } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import * as _ from 'lodash';
import 'semantic-ui-css/semantic.min.css'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AXIOS } from '../../../Utilities/HttpRequest/Axios';
import { registerPlugin, FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Guid } from "guid-typescript";
import { AppConfiguration } from "read-appsettings-json";
import AttachmentsDrawer from '../../Drawers/AttachmentsDrawer/AttachmentsDrawer';
import JobLocksDrawer from '../../Drawers/JobLocksDrawer/JobLocksDrawer';
import propertyService from '../../../Services/PropertyService';
import settingsService from '../../../Services/settingsService';
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import jobService from '../../../Services/JobService';
import FrequentlyUsedServices from './FrequentlyUsedServices/FrequentlyUsedServices';
import { trackPromise } from 'react-promise-tracker';
import GoogleMapLocationsAutoComplete from '../../Common/GoogleMapLocationsAutoComplete/GoogleMapLocationsAutoComplete';
import InviteTradee from './InviteTradee/InviteTradee';
import Disclaimer from './Disclaimer/Disclaimer'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const corsProxy = 'https://cors-anywhere.herokuapp.com/';

class JobPostDrawer extends Component {
    notifyError = (msg) => toast.error(msg,{
        position: toast.POSITION.BOTTOM_RIGHT
    });
    state = {
        propertySearchData: [],
        formData: {
            address: '',
            location: '',
            propertyId: '',
            propertyName: '',
            suburbId: '',
            propertyType: '',
            jobBudget: 0
        },
        availableServices: [],
        availableServicesDetails: [],
        selectedService: { childJobTypes: [] },
        selectedServiceType: {
            parentJobType: { id: ''}
        },
        selectedSubServiceType: {},
        jobDetails: { attachments: [] },
        attachments: [],
        jobId: '',
        dueDate: '',
        formattedDueDate: '',
        jobUrgency: '',
        propertyList: [],
        value:'',
        isLoading: false,
        filteredResults: [],
        propertyId: '',
        isPropertiesLoading: false,
        parentJobTypes: [],
        primaryJobTypes: [],
        secondaryJobTypes: [],
        frequentlyUsedServices: [],
        isShowMoreServices: false,
        locationDetails: {
            latitude: '',
            longitude: '',
            placeId: '',
            suburbId: '',
            location: ''
        },
        isMandatoryToUploadFiles: true,
        isJobForMe: 1,
        jobBudget: 0,
        jobForMe: {},
        selectedTradees: [],
        isSelectedOnDemand: false,
        selectedQuoteLimit: 0,
        inviteToProviders: false,
        isJobPosting: false,
        isRestrictKeyCollectionForResidentialProperties: false,
        showDisclaimerDrawer: false,
        jobPostWorkflowStatus: 1,
        propertyLocks: [],
        isJobForMeText: '',
        isSubServiceTypeSelected:false,
    } 

    constructor(props) {
        super(props);
        this.title = React.createRef();
        this.jobDescription = React.createRef();
        this.budgetRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.GetTenantPropertiesByUserId();
        }, 1000);
        this.getAvailableServices();
        this.GetFrequentlyUsedJobTypes();
        this.setState({
            jobId: Guid.create().toString(),
            jobDetails: {
                id: this.state.jobId
            }
         });
        this.getAllAvailableJobTypes();
        if(localStorage.userType == 5) {
            this.setState({
                isJobForMe: 0
            })
        }

      
    }



    getOrganizationSettings(organizationId) {
        settingsService.getOrganizationSettingsByOrgId(organizationId)
            .then((res) => {
                this.setState({ isMandatoryToUploadFiles: res.organizationSettingsModel.allowTenantsToImageAttachmentFeatureForTheJobposting, 
                    isRestrictKeyCollectionForResidentialProperties: res.organizationSettingsModel.isRestrictKeyCollectionForResidentialProperties
                })

            })

    }

    //TODO: This lifecycle event is deprecated and must be updated before updating to React v17
    componentWillReceiveProps() {
        this.setState({
            jobId: Guid.create().toString(),
            jobDetails: {
                id: this.state.jobId
            }
         });
        this.getAllAvailableJobTypes();
    }

    GetTenantPropertiesByUserId = () => {
        let none = [{ propertyId: 0, propertyName: '--None--', suburbId: '', location: '', address: '', propertyType: 'Residential' }];
        if (this.props.properties.length > 0) {

            let properties = this.props.properties;

            this.setState({
                propertyList: properties,
                propertyId: properties[0].propertyId
            });
            this.getPropertyDetailsByPropertyId(properties[0].propertyId);
        } else {
            this.setState({
                propertyList: none,
                propertyId: none[0].propertyId
            });
        }
    }

    onAttachTradeesToTheJob = ({ selectedTradees, selectedQuoteLimit, isSelectedOnDemand, inviteToProviders }) => {
        this.setState({
            selectedTradees: selectedTradees,
            selectedQuoteLimit: selectedQuoteLimit,
            isSelectedOnDemand: isSelectedOnDemand,
            inviteToProviders: inviteToProviders
        })
    }

    render() {
        let drawer = this.createDrawer();
        return (
            <div>
                {drawer}
            </div>
        );
    }

    createDrawer = () => {
        let header = this.createHeader();
        let body = this.createBody();
        let footer = this.createFooter();
        return (
            <Drawer
                size={'xl'}
                open={this.props.drawerOpen}
                header={header}
                body={body}
                footer={footer}>
            </Drawer>
        )
    }

    createHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    {(localStorage.getItem("userType") == 4) ? 'Post a job' : 'Post a job for me'}
                </h2>
                <div className="panel-header-actions" >
                    <button className="action-btn panel-close" onClick={this.props.closeJobPostDrawer}>
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        )
    }

    validateJob = (workflow) => {
        let v = true;
        if(this.state.isJobForMe == 0) {

            const { address, budget, latitude, location, longitude, placeId, propertyType, suburbId} = this.state.jobForMe;
            const { selectedService, selectedServiceType, formData, jobBudget, selectedTradees, isSelectedOnDemand, inviteToProviders } = this.state;
            if(address == '') {this.notifyError('Please enter your address.'); v = v && false; };
            if(suburbId == '') {this.notifyError('Please enter a valid address.'); v = v && false; };
            if(propertyType == '') {this.notifyError('Please select your property type.'); v = v && false; };

            if (selectedService && selectedService.parentJobType) {
                if (selectedServiceType && selectedServiceType.parentJobType && selectedServiceType.parentJobType.id != '') {
                    if (selectedServiceType.childJobTypes.length > 0 && (!formData.childJobType || formData.childJobType === '')) {
                        this.notifyError(`Please select ${selectedServiceType.parentJobType.description.replace('?', '')} that you need.`); v = v && false;
                    }
                } else {
                    this.notifyError('Please select a type of service.'); v = v && false;
                }
            } else {
                this.notifyError('Please select a service.'); v = v && false;
            }

            if(jobBudget == '') {this.notifyError('Please select your estimate.'); v = v && false; };
            if(jobBudget == '-999' && (this.budgetRef.current.value == '' || this.budgetRef.current.value == 0)) {this.notifyError('Please enter your estimate.'); v = v && false; };

            if(isSelectedOnDemand) {
                if(selectedTradees.length == 0) {this.notifyError('Please select one or more trades to progress.'); v = v && false;}
            } else {
                if(!inviteToProviders) {this.notifyError('Please select one or more trades to progress.'); v = v && false;}
            }
        } else {
            if(this.state.formData.propertyId == 0 || this.state.formData.propertyId == undefined){
                if(this.state.locationDetails.address == '' || this.state.locationDetails.address == undefined) {this.notifyError('Please enter your address.'); v = v && false; }
                if(this.refs.jobPropertyTypes.props.value == undefined) {this.notifyError('Please select your property type.'); v = v && false; }
            } 
            if (this.state.formData.propertyName === '') { this.notifyError('Please select a property.'); v = v && false; };
            if (this.state.selectedService && this.state.selectedService.parentJobType) {
                if (!this.state.isSubServiceTypeSelected) {
                    this.notifyError(`Please select type of sub service.`); v = v && false;
                 }
                if (this.state.selectedServiceType && this.state.selectedServiceType.parentJobType) {
                    if (this.state.selectedServiceType.childJobTypes && this.state.selectedServiceType.childJobTypes.length > 0 && (!this.state.formData.childJobType || this.state.formData.childJobType === '')) {
                        this.notifyError(`Please select ${this.state.selectedServiceType.parentJobType.description.replace('?', '')} that you need.`); v = v && false;
                    }
                } else {
                    this.notifyError('Please select a type of service.'); v = v && false;
                }
            } else {
                this.notifyError('Please select a service.'); v = v && false;
            }
            if (document.querySelector('input[name=propertyKey]:checked') == null) { this.notifyError('Please select property key collection method.'); v = v && false; }
            if (workflow != 1 && localStorage.userType != 5 && this.state.isMandatoryToUploadFiles && this.state.attachments.length < 1) { this.notifyError('Attachments are required.'); v = v && false; }
            
        }
       
        if (this.title.current.value === '') { this.notifyError('Please enter a job title.'); v = v && false; }
        if (this.jobDescription.current.value === '') { this.notifyError('Please enter a job description.'); v = v && false; }
        if (this.refs.jobUrgency.props.value == undefined) { this.notifyError('Please select job urgency.'); v = v && false; }
        if (this.refs.jobUrgency.props.value == -999 && this.state.dueDate == '') { this.notifyError('Please select a due date.'); v = v && false; }
        if (this.state.formData.propertyType == '') { this.notifyError('Please select property type.'); v = v && false; }
        
        return v;
    }

    onSubmitJob = (workflow) => {  
        if (this.validateJob(workflow)) {
            if(this.state.isJobForMe == 0 || workflow == 1) {
                this.postJob(workflow);
            } else {
                this.loadDisclaimer(workflow);
            }
        }
    }

    loadDisclaimer = (workflow) => {
        this.setState({
            isLoading : true
        });
        propertyService.GetDisclaimerByPropertyId(this.state.formData.propertyId).then(res => {
            this.setState({
                isLoading : false
            });
            if(res !== null && res !== '') {
                this.setState({ 
                    showDisclaimerDrawer: true,
                    disclamimerText: res,
                    jobPostWorkflowStatus: workflow
                });
            } else {
                this.postJob(workflow);
            }
        });
    }

    closeDisclaimer = () => {
        this.setState({ 
            showDisclaimerDrawer: false,
        });
    }

    postJob = (workflow) => {
        const workflowId = workflow;
        let jobDetails = {};
        if(this.state.isJobForMe == 0) { 

            const { address, budget, latitude, location, longitude, placeId, propertyType, suburbId} = this.state.jobForMe;
            const { selectedService, selectedServiceType, formData, jobBudget, selectedTradees, isSelectedOnDemand, selectedQuoteLimit } = this.state; 

            jobDetails.propertyId = null;
            jobDetails.suburbId = suburbId;
            jobDetails.address = address;
            jobDetails.location = location;
            jobDetails.jobPropertyTypes = propertyType;
            if (jobDetails.jobPropertyTypes === "Residential") {
                jobDetails.propertyTypeId = 0;
            } else {
                jobDetails.propertyTypeId = 1;
            }
            jobDetails.budgetId = parseInt(jobBudget);
            if(jobBudget == '-999') {
                jobDetails.budgetAmount = this.budgetRef.current.value;
            }
            if(isSelectedOnDemand) {
                jobDetails.targetedTradeeOption = 4;
                jobDetails.sendInvitationtoAll = false;
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                let tradees =  [];
                selectedTradees.forEach(element => {
                    tradees.push({
                        isAssigned: true,
                        userId: element.userId,
                        name: element.name,
                        email: element.email,
                        firstName: element.name,
                        lastName: element.name,
                    });
                });
                jobDetails.tradees = tradees;
            } else {
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                jobDetails.targetedTradeeOption = 0;
                jobDetails.sendInvitationtoAll = true;
                jobDetails.tradees = [];
            }
        } else {
            if(this.state.formData.propertyId == 0 || this.state.formData.propertyId == undefined){
                jobDetails.propertyId = null;
                jobDetails.suburbId = this.state.locationDetails.suburbId;
                jobDetails.address = this.state.locationDetails.address;
                jobDetails.jobPropertyTypes =  this.refs.jobPropertyTypes.props.value;
                if (jobDetails.jobPropertyTypes === "Residential") {
                    jobDetails.propertyTypeId = 0;
                } else {
                    jobDetails.propertyTypeId = 1;
                }
            } else {
                jobDetails.propertyId = this.state.formData.propertyId;
                jobDetails.address = this.state.formData.address;
                jobDetails.location = this.state.formData.location;
                jobDetails.propertyName = this.state.formData.propertyName;
                jobDetails.suburbId = this.state.formData.suburbId;
                if (this.state.formData.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                } else {
                    jobDetails.propertyTypeId = 1;
                }
                jobDetails.jobPropertyTypes =  this.state.formData.propertyType;
            }
            jobDetails.propertyKeyCollectType =  parseInt(document.querySelector('input[name=propertyKey]:checked').value);
        }
        jobDetails.id = this.props.jobId;
        jobDetails.secondaryJobType =  this.state.selectedServiceType.parentJobType.id;
        jobDetails.secondaryJobTypeDisplay = this.state.selectedServiceType.parentJobType.title;
        jobDetails.primaryJobType = this.state.selectedService.parentJobType.id;
        jobDetails.primaryJobTypeDisplay = this.state.selectedService.parentJobType.title;
        jobDetails.jobPropertyLocks = this.state.propertyLocks.map((item, index) => {
            const jobLock = {};
            jobLock.jobId = null;
            jobLock.propertyLock = {};
            jobLock.propertyLock.id = item.id;
            jobLock.propertyLock.lockId = item.lockId;
            jobLock.propertyLock.propertyId = jobDetails.propertyId;
            return jobLock;
        });

        if (this.state.formData.childJobTypeId.trim() != "") {
            jobDetails.jobType = this.state.formData.childJobTypeId;
        }
        else{
            jobDetails.jobType = this.state.selectedServiceType.parentJobType.id;;
        }
       
        jobDetails.jobTypeDisplay = this.state.formData.childJobType;
        jobDetails.title = this.title.current.value;
        jobDetails.description = this.jobDescription.current.value;
        jobDetails.jobUrgencyId =  parseInt(this.refs.jobUrgency.props.value);
        
        jobDetails.dueDate = (!this.state.dueDate) ? null : this.state.dueDate;
        // jobDetails.JobUrgencyDisplay =  this.refs.jobUrgency.props.value;
        jobDetails.jobValidityPeriod =  1;
        jobDetails.serviceConsumerId =  localStorage.getItem('userId');
        
        jobDetails.workflowStatusId =  workflow === 1 ? 1 : 2;
        jobDetails.attachments =  this.state.attachments;
        jobDetails.isActive = true;
        this.setState({isJobPosting: true})
        try {
            trackPromise(
                AXIOS.POST(`tenant-api/job-post`, jobDetails, {
                    'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret }})
                .then(dataJson => {
                    this.setState({isJobPosting: false})
                    const message = workflowId === 1 ? 'drafted' : 'posted'
                    if(dataJson == 'Request Unauthorized' || dataJson == 'Job creation failed'){
                        toast.error(`Job is not ${message} successfully. Please try again.`,{
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    } else {
                        toast.success(`Job is ${message} successfully.`,{
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        let { history } = this.props;
                        history.push({ pathname: '/'});
                        this.props.closeJobPostDrawer();
                        this.setState({
                            attachments: [], 
                            value:'', 
                            isShowMoreServices: false,
                            showDisclaimerDrawer: false,
                            jobPostWorkflowStatus: 1
                        })
                    }
                },  error => {
                    console.log(error);
                    this.setState({isJobPosting: false});
                    toast.error(`Fail to post the job. Please try again.`,{
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
            )
            
          } catch (error) {
            console.log(error);
          }
    }

    getPropertyDetailsByPropertyId = (propertyId) => {
        const selectedProperty = this.state.propertyList.filter(a => a.propertyId == propertyId);
        if(selectedProperty && selectedProperty.length > 0 && selectedProperty[0].propertyId != null) {
            this.setState({
                propertyId: propertyId,
                locationDetails: {},
                formData: {
                    address: selectedProperty[0].address,
                    location: selectedProperty[0].location,
                    propertyId: selectedProperty[0].propertyId,
                    propertyName: selectedProperty[0].propertyName,
                    suburbId: selectedProperty[0].suburbId,
                    propertyType: selectedProperty[0].propertyType
                }
             });
             this.getOrganizationSettings(selectedProperty[0].orgnizationId);
        } else {
            this.setState({
                propertyId: null,
                locationDetails: {},
                formData: {
                    address: '',
                    location: '',
                    propertyId: null,
                    propertyName: '',
                    suburbId: null,
                    propertyType: ''
                }
             });
        }
        setTimeout(() => {
            if(this.state.isJobForMe == 1) {
                document.getElementById('check1').checked = true;
                this.setState({keyCollectType: 1});
            }         
        }, 500);
    }

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.label })
        this.getChildJobTypes(result.id);
    }
    handleSearchChange = (e, { value }) => {
        this.setState({filteredResults: [], value });
        if(value.length > 2) {
            this.setState({ isLoading: true })
            setTimeout(() => {
            document.querySelector('.jobpost-main-details .auto-complete').classList.remove("hide-results");
              this.setState({
                isLoading: false,
                filteredResults: this.state.parentJobTypes.filter(suggestion => suggestion.label.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1)
              })
            }, 300)
        } else {
            document.querySelector('.jobpost-main-details .auto-complete').classList.add("hide-results");
        }
    }

    resultRenderer = (event) => {
        return  <Label key={event.label} content={event.label}></Label>
    }

    handlePropertyChange = (event, props) => {
        this.setState({ property: props.value });
        this.getPropertyDetailsByPropertyId(props.value);
    }

    setLocationDetails = (event) => {
        this.setState({ 
            locationDetails: event, 
            jobForMe: {
                propertyType: '',
                address: event.address,
                latitude: event.latitude,
                location: event.location,
                longitude: event.longitude,
                placeId: event.placeId,
                suburbId: event.suburbId,
            },
        });
    }

    onChangeJobType = (value) => {
        if(value === 1){
            this.setState({isJobForMeText : <p>You (tenant) will be paying for this job and are responsible <br></br> for all costs involved.<br></br>This job is not being sent to your property manager.</p> });
        } else {
            this.setState({isJobForMeText : ''});
        }
        
        this.setState({
            isJobForMe: this.refs.jobType.props.value,
            jobForMe: {
                propertyType: '',
                address: '',
                latitude: '',
                location: '',
                longitude: '',
                placeId: '',
                suburbId: '',
                budget: ''
            },
        });
    }

    onChangePropertyTypeForMe = (event) => {
        let jobforMe = this.state.jobForMe;
        jobforMe.propertyType = event;
        this.setState({ jobForMe: jobforMe });

        let formdata = this.state.formData;
        formdata.propertyType = event;
        this.setState({ formdata: formdata });
    }

    onKeyCollectionChange = (event) => {
        this.setState({
            keyCollectType: event.currentTarget.value
            });
    }

    createBody = () => {
        const { isLoading, value, propertyList, filteredResults, propertyId, isPropertiesLoading, isJobForMe, jobForMe } = this.state
        if(this.props.drawerOpen){
            let serviceTypes = this.createTypeOfServicesButtons();
            let serviceTypeChild = this.createTypeOfServicesChildButtons();
            // const attachmentlist = this.getUploadedAttachments();
            const dueDate = this.getDueDate();
            const forMeText = this.state.isJobForMeText
            return (
                <>
                    <div className="panel-body">
                    <Form>
                        <div className="jobpost-main-details">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className={localStorage.userType == 4 ? "form-group" : 'd-none'}>
                                        <label>Job posting for</label>
                                        <ButtonToolbar className="radio-btn-group">
                                            <ToggleButtonGroup type="radio" name="options" ref="jobType" defaultValue={localStorage.userType == 4 ? 0 : 1}
                                                onChange={(value) => { this.onChangeJobType(value) }}>
                                                <ToggleButton value={0}>Property Manager</ToggleButton>
                                                <ToggleButton value={1}>Job I will Pay For</ToggleButton>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>
                                        <div className="mb-2">
                                            <span className="mr-2 common-tag small badge-danger text-left w-100">{forMeText}</span>
                                            </div>
                                    </div>
                                    {(isJobForMe == 1) ? 
                                    <div className="form-group">
                                        <label htmlFor="property">Link to a Property</label>
                                        <div>
                                            <Dropdown
                                                selectOnNavigation={false}
                                                className='test test'
                                                selection
                                                loading={isPropertiesLoading}
                                                value={propertyId}
                                                name='propertyId'
                                                options={propertyList.map((a, index) => { return { key: index, text: a.propertyName, value: a.propertyId }; })}
                                                placeholder='Choose a Property'
                                                onChange={this.handlePropertyChange}
                                            />
                                        </div>
                                    </div>: ''}
                                    <div className="form-group">
                                        <label>What is your Address<span className="required-icon">*</span></label>
                                        {(propertyId == 0 || isJobForMe == 0) ? 
                                            <GoogleMapLocationsAutoComplete setLocationDetails={(event) => this.setLocationDetails(event)}></GoogleMapLocationsAutoComplete>: 
                                            <input type="text" className="form-control" id="formGroupAddress" placeholder="Address" readOnly  value={this.state.formData.address} ></input>}
                                    </div>
    
                                    <div className="form-group">
                                        <label>What is your Location<span className="required-icon">*</span></label>
                                        {propertyId == 0 || isJobForMe == 0 ? <input type="text" className="form-control" id="formGroupLocation" placeholder="Location" readOnly value={jobForMe.location}></input>
                                        : <input type="text" className="form-control" id="formGroupLocation" placeholder="Location" readOnly={true} value={this.state.formData.location}></input>}
                                        
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Property Type<span className="required-icon">*</span></label>
                                        {(isJobForMe == 0) ?
                                            <ButtonToolbar className="radio-btn-group">
                                            <ToggleButtonGroup type="radio" name="options" ref="jobPropertyTypes" value={jobForMe.propertyType}
                                             onChange={this.onChangePropertyTypeForMe}>
                                                <ToggleButton disabled={false} value="Residential">Residential</ToggleButton>
                                                <ToggleButton disabled={false} value="Commercial">Commercial</ToggleButton>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar> :
                                        <ButtonToolbar className="radio-btn-group">
                                            <ToggleButtonGroup type="radio" name="options" ref="jobPropertyTypes" value={this.state.formData.propertyType} defaultValue={this.state.formData.propertyType} onChange={() =>{}}>
                                                <ToggleButton disabled={true} value="Residential">Residential</ToggleButton>
                                                <ToggleButton disabled={true} value="Commercial">Commercial</ToggleButton>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>}
                                    </div>
    
                                    <div className="form-group">
                                        <label>What is your job title?<span className="required-icon">*</span></label>
                                        <input type="text" className="form-control" 
                                        placeholder="e.g :install a new hot water system" aria-label="test" 
                                        value={this.state.formData.title} ref={this.title}></input>
                                    </div>
                                    <div className="form-group">
                                        <label>Describe the job that you need to be completed<span className="required-icon">*</span></label>
                                        <textarea className="form-control" placeholder="Please provide as much detail about your job so
                                                that the quotes received are as accurate as possible" aria-label="test" 
                                            value={this.state.formData.jobDescription} ref={this.jobDescription}>
                                        </textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div>
                                        {(this.state.isShowMoreServices) ? 
                                                <div className="form-group auto-complete">
                                                    <label>Which service do you need?<span className="required-icon">*</span></label>
                                                    <Search
                                                        placeholder="e.g. Electrician, Plumber, Cleaner"
                                                        loading={isLoading}
                                                        onResultSelect={this.handleResultSelect}
                                                        onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                                            leading: true,
                                                        })}
                                                        value={value}
                                                        results={filteredResults}
                                                        resultRenderer={this.resultRenderer}
                                                        {...this.props}
                                                    />
                                                </div> : 
                                            <FrequentlyUsedServices
                                                onSelect={this.onSelectFrequentlyUsedJobTypes}
                                                services={this.state.frequentlyUsedServices}
                                                onClickMore={this.showHideMoreService}>
                                            </FrequentlyUsedServices>
                                        }
                                        {serviceTypes}
                                        {serviceTypeChild}
                                    </div>
                                    <div className="form-group">
                                        <label>When do you need the job done?</label>
                                        <ButtonToolbar className="radio-btn-group">
                                            <ToggleButtonGroup type="radio" name="options" ref="jobUrgency" onChange={this.handleJobUrgencyOnChange}> 
                                                <ToggleButton value="1">Urgently</ToggleButton>
                                                <ToggleButton value="2">Within a few days</ToggleButton>
                                                <ToggleButton value="3">Within a week</ToggleButton>
                                                <ToggleButton value="-999">By this date</ToggleButton>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>
                                    </div>
                                    {dueDate}
                                    {(isJobForMe == 0) ? 
                                    <div className="form-group">
                                        <label>Do you have an estimate in mind?<span className="required-icon">*</span></label>
                                        <ButtonToolbar className="radio-btn-group">
                                            <ToggleButtonGroup type="radio" name="options" ref="jobBudget" onChange={this.onChangeJobBudget}> 
                                                <ToggleButton value="1">Under $500</ToggleButton>
                                                <ToggleButton value="2">$500 - $1000</ToggleButton>
                                                <ToggleButton value="3">$1000 - $2000</ToggleButton>
                                                <ToggleButton value="4">$2000 - $5000</ToggleButton>
                                                <ToggleButton value="5">$5000+</ToggleButton>
                                                <ToggleButton value="0">Unsure</ToggleButton>
                                                <ToggleButton value="-999">Custom</ToggleButton>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>
                                    </div> : ''}
                                    <div className={(this.state.jobBudget == -999) ? 'form-group' : 'form-group d-none'}>
                                        <input type="number" className="form-control" 
                                            placeholder="Enter your estimate." aria-label="test" 
                                            value={this.state.formData.jobBudget} ref={this.budgetRef} onBlur={e => this.onBlurBudgetAmount(e)}>
                                        </input>
                                    </div>
                                    { (isJobForMe == 0) ? '' : 
                                    <div className="form-group">
                                        <label>Property key collection<span className="required-icon">*</span></label>
                                            {(this.state.isRestrictKeyCollectionForResidentialProperties && this.state.formData.propertyType == 'Residential') ? 
                                                <div className="custom-control custom-radio">
                                                    <input name="propertyKey" type="radio" id="check3" className="custom-control-input" value="3" checked />
                                                    <label title="" type="checkbox" htmlFor="check3" className="custom-control-label">Contact tenant to arrange access and time</label>
                                                </div> :
                                                <div>
                                                    <div className="custom-control custom-radio">
                                                        <input name="propertyKey" type="radio" onChange={this.onKeyCollectionChange} id="check1" className="custom-control-input" value="1"  />
                                                        <label title="" type="checkbox" htmlFor="check1" className="custom-control-label">Please collect keys from the office to enter the property</label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input name="propertyKey" type="radio" onChange={this.onKeyCollectionChange} id="check2" className="custom-control-input" value="2"  />
                                                        <label title="" type="checkbox" htmlFor="check2" className="custom-control-label">Please collect the keys from the office but let the tenant know what time you will be on site</label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input name="propertyKey" type="radio" onChange={this.onKeyCollectionChange} id="check3" className="custom-control-input" value="3"  />
                                                        <label title="" type="checkbox" htmlFor="check3" className="custom-control-label">Please book a time with the tenant to meet at the property</label>
                                                    </div>
                                                </div>
                                        }                                        
                                    </div>
                                    }

                                    {
                                        (isJobForMe != 0 
                                            && this.state.keyCollectType != null 
                                            && (this.state.keyCollectType == 1 || this.state.keyCollectType == 2)) ?
                                        <JobLocksDrawer propertyId={this.state.propertyId} isSelectable={true} onPropertyKeySelected={(event) => this.onPropertyKeySelected(event)}></JobLocksDrawer>
                                        : ''
                                    }
                                    
                                    <AttachmentsDrawer 
                                        jobDetails={ { id: this.props.jobId, attachments: this.state.attachments }} 
                                        onSubmitAttachments={(event) => { this.setState( { attachments: event } ) }} 
                                        isJobPost={true}>                                        
                                    </AttachmentsDrawer>

                                    {(isJobForMe == 0) ? 
                                    <InviteTradee 
                                        location={this.state.jobForMe} 
                                        jobTypeId={this.state.selectedServiceType.parentJobType.id}
                                        validateBeforeInviteTradee={this.validateBeforeInviteTradee}
                                        onAttachTradeesToTheJob={(event) => this.onAttachTradeesToTheJob(event)}
                                        selectedTradees={this.state.selectedTradees}> 
                                    </InviteTradee>: ''}
                                    {(this.state.showDisclaimerDrawer==true) ?
                                        <Disclaimer
                                        showDrawer = {this.state.showDisclaimerDrawer} 
                                        disclaimer = {this.state.disclamimerText}
                                        jobPostWorkflowStatus = {this.state.jobPostWorkflowStatus}
                                        onAgreedForDisclaimer = {(event) => this.postJob(event)}
                                        onCloseDisclaimer = {() => this.closeDisclaimer()}
                                        isFromBot={false}
                                        ></Disclaimer>: ''
                                    }

                                    <div className="form-group d-none">
                                        <label>See macthing trades around my suburb</label>
                                    </div>
                                    {(this.state.attachments) ? 
                                        <ImageViewer attachments={this.state.attachments} ></ImageViewer>: ''
                                    }
                                </div>
                            </div>
                        </div>
                    </Form>
                    </div>
                </>
            )
        } else {
            return
        }
    }

    onPropertyKeySelected = (event) => {
        this.setState({ propertyLocks: event });
        console.log(event);
    }

    onBlurBudgetAmount = (event) => {
        if(event.target.value < 1) {
            this.budgetRef.current.value = 1;
        }
    }

    validateBeforeInviteTradee = () => {
        var v = true;
        if(this.state.jobForMe.address == '') { this.notifyError('Please enter your address.'); v = v && false; }
        if (this.state.selectedService && this.state.selectedService.parentJobType) {
            if (this.state.selectedServiceType && this.state.selectedServiceType.parentJobType && this.state.selectedServiceType.parentJobType.id != '') {
                if (this.state.selectedServiceType.childJobTypes.length > 0 && (!this.state.formData.childJobType || this.state.formData.childJobType === '')) {
                    this.notifyError(`Please select ${this.state.selectedServiceType.parentJobType.description.replace('?', '')} that you need.`); v = v && false;
                }
            } else {
                this.notifyError('Please select a type of service.'); v = v && false;
            }
        } else {
            this.notifyError('Please select a service.'); v = v && false;
        }

        return v;
    }

    onChangeJobBudget = (value) => {
        this.setState({ jobBudget: value });     
    }

    handleJobUrgencyOnChange = (value) => {
        this.setState({ jobUrgency: value });     
    }

    getDueDate = () =>{
       if (this.state.jobUrgency == -999)  {
           return (
               <div className="form-group">
                    <label>Due Date</label>
                    <div>
                        <DatePicker dateFormat={this.props.dateFormat} minDate={new Date()} className="form-control" selected={this.state.dueDate} onChange={this.handleDueDateChange} />
                    </div>                    
                </div>
           )
       }
    }

    handleDueDateChange = (date) => {
        this.setState({
            dueDate: date, // ISO String, ex: "2016-11-19T12:00:00.000Z"
        });
    }

    getUploadedAttachments = () => {
        if (this.state.attachments) {
          const uploadedFiles = this.state.attachments.map(uploadedFile => {
            return (
              <div className="fileslider-item">
                <img src={uploadedFile.fileUrl} alt={uploadedFile.fileName} onError={(e)=>{e.target.onerror = null; e.target.src="/Images/attachment.png"}} />
              </div>
            )
          })
          return (
            <div className="fileslider">
              {uploadedFiles}
            </div>
          );
        }
      }

    handleInit = () => {}

    createFooter = () => {
        return (
            <>
                <div className="card-actions">

                    <button  onClick={() => this.onSubmitJob(2)} className="d-block" disabled={this.state.isJobPosting}>
                        <i className="ba-icn ba-icn-ok"></i> 
                        Post this job now 
                    </button>
                     <button onClick={() => this.onSubmitJob(1)} className="d-block" disabled={this.state.isJobPosting}>
                         <i className="ba-icn ba-icn-close"></i> 
                         Save + Post later 
                    </button>
                    {/*<button><i className="ba-icn ba-icn-send"></i> Summary </button> */}
                    <button className="more-actions" onClick={this.props.closeJobPostDrawer}> Cancel </button>

                    {/* {this.props.footerButtons} */}

                </div>
            </>
        )
    }

    getAllAvailableJobTypes() {
        let jobTypes = this.props.jobTypes;
        let parents = _.map(jobTypes ,m => m.parentJobType );
        const parentJobTypes =  _.filter(parents, f => f.parentRef === null);
        this.setState({ parentJobTypes: parentJobTypes});
    }

    getChildJobTypes(parentId) {
        let jobTypes = this.props.jobTypes;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === parentId);
        if (this.state.selectedServiceType && this.state.selectedServiceType.parentJobType && _.find(selectedNode.childJobTypes, f => f.id === this.state.selectedServiceType.parentJobType.id)) {
            this.setState({
                primaryJobTypes: selectedNode.childJobTypes,
                selectedService: selectedNode
            });
        } else {
            let formData2 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: '',

            }
            this.setState({
                primaryJobTypes: selectedNode.childJobTypes,
                selectedService: selectedNode,
                secondaryJobTypes: [],
                selectedServiceType: {
                    parentJobType: { id: '' }
                },
                formData: formData2
            });
        }
    }

    getSubChildJobTypes(parentId) {
        this.setState({isSubServiceTypeSelected:true});
        let jobTypes = this.props.jobTypes;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === parentId);
        if(selectedNode == undefined) {
            let selectedServiceType = {
                childJobTypes: [],
                parentJobType: _.find(this.state.primaryJobTypes, f => f.id === parentId)
            }
            let formData1 = {...this.state.formData, childJobType: '',
            childJobTypeId: ''};
            this.setState({ 
                secondaryJobTypes:  [],
                selectedServiceType: selectedServiceType,
                formData: formData1
            });
            
        } else {
            let formData2 = {...this.state.formData, childJobType: '',
            childJobTypeId: ''};
            this.setState({ 
                secondaryJobTypes: selectedNode.childJobTypes,
                selectedServiceType: selectedNode,
                formData: formData2
            });
        }
        
    }

    //#region  Available service types Childs Methods

    createTypeOfServicesChildButtons = (props) => {
        if (this.state.secondaryJobTypes.length > 0) {
            return (
                <>
                    <div className="form-group">
                        <label>{this.state.selectedServiceType.parentJobType.description}<span className="required-icon">*</span></label>
                        <ButtonToolbar className="radio-btn-group">
                            <ToggleButtonGroup 
                                type="radio" 
                                name="options" 
                                onChange={this.onChangeChildTypeOfServices}>
                                {this.state.secondaryJobTypes.map((jbType, index) => {
                                    return <ToggleButton value={jbType.id}>{jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            return
        }

    }

    onChangeChildTypeOfServices = (input) => {
        const childJobType = this.state.selectedServiceType.childJobTypes.filter(a => a.id === input)[0];
        let formData = this.state.formData;
        formData.childJobTypeId = childJobType.id;
        formData.childJobType = childJobType.title;
        this.setState({formData: formData});
    }

    //#endregion Available service types Childs Methods

    //#region  Available service types Methods

    createTypeOfServicesButtons = (props) => {
        let isServiceTypeSelected = this.state.isServiceTypeSelected;
        if (this.state.selectedService.childJobTypes) {
            return (
                <>
                    <div className="form-group">
                    {(isServiceTypeSelected) ? 
                             <label>Please select the type of subservice you require.<span className="required-icon">*</span></label>       : ''}
                       
                        <ButtonToolbar className="radio-btn-group">
                            <ToggleButtonGroup 
                                type="radio" 
                                name="options" 
                                onChange={(event) => { this.getSubChildJobTypes(event); }}>
                                {this.state.selectedService.childJobTypes.map((jbType, index) => {
                                    return <ToggleButton key={jbType.id} value={jbType.id}>{jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            return
        }
    }

    onChangeTypeOfServices = (event) => {
        try {
            this.setState({ isLoading: true })
            AXIOS.GET(`tenant-api/job-sub-types?parentRef=${event}`)
                .then(dataJson => {
                    this.setState({ isLoading: false })
                    this.setState({ selectedServiceType: dataJson });
                    let formData = this.state.formData;
                    formData.childJobTypeId = '';
                    formData.childJobType = '';
                    this.setState({formData: formData});
                });
          } catch (error) {
            console.log(error);
          }
    }


    //#endregion Available service types Methods

    //#region  Frequently UsedJob Types

    GetFrequentlyUsedJobTypes = () => {
        try {
            jobService.GetFrequentlyUsedJobTypes()
                .then(data => {
                    this.setState({ frequentlyUsedServices: data});
                })
            
        } catch (error) {
            console.log(error);
        }
    }

    onSelectFrequentlyUsedJobTypes = (event) => { 
        try {
            this.setState({ isServiceTypeSelected: true });
            this.getChildJobTypes(event);
        } catch (error) {
            console.log(error);
        }
    }

    showHideMoreService = () => {
        this.setState({
            isShowMoreServices: !this.state.isShowMoreServices,
            selectedService: {},
            isServiceTypeSelected: true
        })
    }

    //#endregion Frequently UsedJob Types

    //#region  Available services AutoComplete Methods

    getAvailableServices = () => {
        try {
            this.setState({ isLoading: true })
            AXIOS.GET(`tenant-api/available-job-types?userId=${localStorage.getItem('userId')}`)
                .then(dataJson => {
                    this.setState({ isLoading: false })
                    this.setState({ availableServicesDetails: dataJson });
                    this.setState({ availableServices: dataJson });
                    let formData = this.state.formData;
                    formData.childJobTypeId = '';
                    formData.childJobType = '';
                    this.setState({formData: formData});
                })
          } catch (error) {
            console.log(error);
          }
    }

    onChangeServiceAutoComplete = (userInput) => {
        const filteredJobTypes = this.state.availableServicesDetails.filter(a => a.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1);
        this.setState({ availableServices: filteredJobTypes});
    }

    onSelectServiceAutoComplete = (data) => {
        if(data != null){
            this.setState({ isLoading: true })
            try {
                AXIOS.GET(`tenant-api/job-sub-types?parentRef=${data.value}`)
                    .then(dataJson => {
                        this.setState({ isLoading: false })
                        this.setState({ selectedService: dataJson , selectedServiceType : {}});
                    })
            } catch (error) {
                console.log(error);
            }
        }
    }

     //#endregion Available services AutoComplete Methods

     //#region  Available Properties AutoComplete Methods

     onChangePropertyAutoCompelete = (userInput) => {
        try {
            fetch( corsProxy + `https://bnaancillarytest.azurewebsites.net/general/search?userId=a0ae4466-9188-4907-a864-73b6afeca5d9&type=1&searchKey=${userInput}`)
              .then(res => res.json())
              .then(dataJson => {
                this.setState({ 
                    propertySearchData: dataJson.properties,
                    formData: {
                        address: this.state.formData.address,
                        location: this.state.formData.location,
                        propertyId: this.state.formData.id,
                        propertyName: this.state.formData.propertyName,
                        suburbId: this.state.formData.suburbId,
                    }
                });
              })
          } catch (error) {
            console.log(error);
          }
    }

    onSelectPropertyAutoComplete = (data) => {
        if(data == null) {
            this.setState({
                formData: {
                    address: '',
                    location: '',
                    propertyId: '',
                    propertyName: '',
                    suburbId: ''
                }
            })
        } else {
            this.setState({
                propertySearchData: this.setState.propertySearchData,
                formData: {
                    address: data.address,
                    location: data.location,
                    propertyId: data.id,
                    propertyName: data.propertyName,
                    suburbId: data.suburbId
                }
            })
        }
        
    }
     //#endregion  Available Properties AutoComplete Methods
}

const mapStateToProps = (state, ownProps) => {
    try {
      return {
        jobTypes: state.Jobs.jobTypes,
        dateFormat: state.User.dateFormat,
        userRoles: state.User.userRoles,
        properties: state.Tenant.tenantProperties
      };
    } catch (error) {
      console.log(error);
    }
  };
  
const mapDispatchToProps = dispatch => {
    return {
      updateProperties: properties => {
        dispatch({ type: "SET_PROPERTIES", properties: properties });
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobPostDrawer));
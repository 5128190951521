import { AXIOS } from '../Utilities/HttpRequest/Axios';
import * as firebase from 'firebase';
import * as _ from 'lodash';

const GetNotifications = (isShowMore) => {
    return AXIOS.POST(`tenant-api/notification/load-notification-min?chatNotifications=false&userId=${localStorage.getItem('userId')}&orgId=${localStorage.orgId}&isShowAll=${isShowMore}`);
}

const GetUnreadNotifications = () => {
    const databseRef = firebase.database();
    return databseRef.ref(`users/${localStorage.getItem('userIdDecripted')}/unreadNotificationCount`);
}

const UpdateNotificationAsRead = (notificationId) => {
    return AXIOS.PUT(`tenant-api/notification/updateNotificationAsRead?notificationId=${notificationId}`)
}

const ClearUserUnreadNotifications = () => {
    const databaseRef = firebase.database();
    const Updates = { };
    Updates[`/users/${localStorage.getItem('userIdDecripted')}/unreadNotificationCount`] = 0;
    databaseRef.ref().update(Updates);
  }

const notificationService = {
    GetNotifications,
    GetUnreadNotifications,
    UpdateNotificationAsRead,
    ClearUserUnreadNotifications
}

export default notificationService;
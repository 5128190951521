import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './ToolTipLayout.scss';

class ToolTipLayout extends React.Component {

    constructor() {
        super();
    }

    state = {
        placement: "top",
        ToolTipMessage: "Click here to peform this action",
        styleType: "tooltip-default"
    }

    componentDidMount() {
        this.setState({
            placement: this.props.placement ? this.props.placement : this.state.placement,
            ToolTipMessage: this.props.ToolTipMessage ? this.props.ToolTipMessage : this.state.ToolTipMessage,
            styleType: this.props.styleType ? this.props.styleType : this.state.styleType,
        });
    }

    render() {
        return (
            <>
                <OverlayTrigger
                    key={this.state.placement}
                    placement={this.state.placement}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className={this.state.styleType} >
                            {this.state.ToolTipMessage}
                        </Tooltip>
                    }
                >
                    {this.props.children}
                </OverlayTrigger>
            </>
        );
    }
}


export default ToolTipLayout;
import React from 'react';
import {Link} from 'react-router-dom'
import { UserType } from '../../../Constants/Enums';
class TopNavigationBar extends React.Component {
    constructor(props) {
        super(props);    
        this.closeNavigation = this.closeNavigation.bind(this);
      }

      closeNavigation = () => {
        this.props.closeNav();
      }
      
    render() {
        const isTenantManage = localStorage.getItem('styleCode')=="JLL";
        const { pathname,host } = this.props;
        const userType =  localStorage.getItem("userType") == UserType.Owner ? "/owner" : "/tenant";
        return (
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                <li onClick={this.closeNavigation} className={`nav-item ${pathname.indexOf('home') > -1 ? 'active' : ''}`}>
                    <Link  to={`${userType}/home`} className={`nav-link ${pathname.indexOf('home') > -1 ? 'active' : ''}`}>Home</Link>
                </li>
                <li onClick={this.closeNavigation} className={`nav-item ${ pathname.indexOf('jobs-for-review') > -1 ? 'active' : ''}`}>
                    <Link to={`${userType}/jobs-for-review`} className={`nav-link ${pathname.indexOf('items-for-review') > -1 ? 'active' : ''}`}>Items for Review</Link>
                </li>
                <li onClick={this.closeNavigation} className={`nav-item ${ pathname.indexOf('my-jobs') > -1 ? 'active' : ''}`}>
                    <Link to={`${userType}/my-jobs`} className={`nav-link ${pathname.indexOf('maintenance-requests') > -1 ? 'active' : ''}`}>Maintenance Requests</Link>
                </li>
                {(userType =="/owner")?
                <li onClick={this.closeNavigation} className={`nav-item ${ pathname.indexOf('all-jobs') > -1 ? 'active' : ''}`}>
                    <Link to={`${userType}/all-jobs`} className={`nav-link ${pathname.indexOf('maintenance-requests') > -1 ? 'active' : ''}`}>All Maintenance Requests</Link>
                </li>:''
                }
                {(userType=="/tenant" && !isTenantManage)?
                <li onClick={this.closeNavigation} className={`nav-item ${ pathname.indexOf('property-access-controls') > -1 ? 'active' : ''}`}>
                <Link to={`${userType}/property-access-controls`} className={`nav-link ${pathname.indexOf('maintenance-requests') > -1 ? 'active' : ''}`}>Property Access Controls</Link>
                </li>:''
                }
                {!isTenantManage?
                <li onClick={this.closeNavigation} className={`nav-item ${pathname.indexOf('property-inspection') > -1 ? 'active' : ''}`}>
                <Link to={`${userType}/property-inspection`} className={`nav-link ${pathname.indexOf('inspections') > -1 ? 'active' : ''}`}>Inspections</Link>
                </li>:""}
                
                {!isTenantManage?
                <li onClick={this.closeNavigation} className={`nav-item ${pathname.indexOf('document-list') > -1 ? 'active' : ''}`}>
                <Link to={`${userType}/document-list`} className={`nav-link ${pathname.indexOf('documents') > -1 ? 'active' : ''}`}>Documents</Link>
                </li>:""}
                
                <li onClick={this.closeNavigation} className={`nav-item ${pathname.indexOf('settings') > -1 ? 'active' : ''}`}>
                    <Link to={`${userType}/settings`} className={`nav-link ${pathname.indexOf('settings') > -1 ? 'active' : ''}`}>Settings</Link>
                </li>
            </ul>
        )
    }
}

export default TopNavigationBar;
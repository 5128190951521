import { AppConfiguration } from "read-appsettings-json";

const apiendpoint = AppConfiguration.Setting().apiendpoint;
const imageCdnEndpoint = AppConfiguration.Setting().azureStorage.ImageCdnEndpoint;
const imageThumbnailsCdnEndpoint = AppConfiguration.Setting().azureStorage.ImageThumbnailsCdnEndpoint;
const clientSecret = AppConfiguration.Setting().ClientSecret;
const profileThumbnailsCdnEndpoint = AppConfiguration.Setting().azureStorage.ProfileThumbnailsCdnEndpoint;
const videoCdnEndpoint = AppConfiguration.Setting().azureStorage.VideoCdnEndpoint;
const generalFilesCdnEndpoint = AppConfiguration.Setting().azureStorage.GeneralFilesCdnEndpoint;
const BAndAEndPoint = AppConfiguration.Setting().BAndAApiEndPoint;
const intercomApiKey = AppConfiguration.Setting().Intercom.ApiKey;

const initState = {
  apiEndPoint: apiendpoint,
  imageCdnEndpoint: imageCdnEndpoint,
  imageThumbnailsCdnEndpoint: imageThumbnailsCdnEndpoint,
  profileThumbnailsCdnEndpoint: profileThumbnailsCdnEndpoint,
  videoCdnEndpoint: videoCdnEndpoint,
  generalFilesCdnEndpoint: generalFilesCdnEndpoint,
  clientSecret: clientSecret,
  BAndAEndPoint: BAndAEndPoint,
  intercomApiKey: intercomApiKey
};

const appSettingsReducers = (state = initState, action) => {
  switch (action.type) {
    case "LOAD_APP_SETTINGS":
      return {
        ...state,
        appSettings: action.appSettings
      };
    default:
      return state;
  }
};
export default appSettingsReducers;

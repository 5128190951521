const initState = {
    ownerCount: 0
}

const OwnerReducer = (state = initState, action) =>{
    if(action.type === "ADD_Owner"){
         
        return{
            ...state,
            ownerCount: action.ownerCount
        }
    }
    return state;
}

export default OwnerReducer;
import { Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import Loader from "react-loader-spinner";
import jobService from '../../../Services/JobService';
import React, { useRef } from 'react';
import { Guid } from "guid-typescript";
import './GoogleJobImage.scss';
import { AppConfiguration } from "read-appsettings-json";

class GoogleJobImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingGoogleImage: true,
            googleImages: [],
            selectedGoogleImage: [],
            attachmentUrl: "",
            uploadedSelectedFile: []
        };

    };



    componentWillMount() {
        this.getGoogleImages();
    }

    render() {
        const { isLoadingGoogleImage, googleImages } = this.state;
        const { t } = this.props.data;
        return (
            <>
                <div className="wizard-inner googl-img-job">
                    <div className="wizard-body">
                        {isLoadingGoogleImage ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : ''}
                        {!isLoadingGoogleImage ? <h2> {t != undefined ? t('lbl_google_image') : "Select images that best describes your issue"}</h2> :''}
                        
                    </div>
                    {!isLoadingGoogleImage && <div className="form-group">
                        <ButtonToolbar className="radio-btn-group" id="googleimage" key="googleimageGroup">

                            <ToggleButtonGroup type="checkbox" name="options" ref="jobimages" defaultValue={this.props.data.selectedGoogleImage} onChange={(value) => this.handleImageOnChange(value)} >
                                {googleImages.map((a, index) => {
                                    return <ToggleButton key={index} value={a.link}><img src={a.image.thumbnailLink} width="200" height="200" /></ToggleButton>
                                })
                                }
                            </ToggleButtonGroup>

                        </ButtonToolbar>
                    </div>}
                    {!isLoadingGoogleImage && <div className="wizard-actions">
                    <button type="button" className="btn btn-secondary" onClick={() => this.props.data.previousStep(this.props.data.index)} style={this.props.data.secondaryButtonStyle}>{t != undefined ? t('btn_back') : "BACK"}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.uploadToBlob()} style={this.props.data.primaryButtonStyle}>{t != undefined ? t('btn_next') : "NEXT"}</button>
                    </div>
                    }

                </div>
            </>);

    }

    getGoogleImages() {
        this.setState({
            isLoadingGoogleImage: true
        });

        jobService.GetGoogleImages(this.props.data.jobDescription, 3, this.props.data.attachmentUrl).then(res => {
            if (res.items.length > 0) {
                this.setState({
                    googleImages: res.items,
                    isLoadingGoogleImage: false,
                    isJobTypeTagsNotFound: false
                });
            } else {
                this.setState({
                    isLoadingGoogleImage: false,
                    isJobTypeTagsNotFound: true
                });
            }
        })
    }

    handleImageOnChange = (value) => {
        this.setState({ selectedGoogleImage: value });
    }

    uploadToBlob() {
        this.setState({
            isLoadingGoogleImage: true
        });
        if (this.state.selectedGoogleImage && this.state.selectedGoogleImage.length > 0) {

            this.state.selectedGoogleImage.forEach(ele => {
                this.createFile(ele);
            });

        } else {
            this.props.data.nextStep({
                index: this.props.data.index,
                selectedGoogleImage: [],
                attachments: undefined,
                t: this.props.data
            })
        }

    }

    uploadFile = (file) => {
        const formData = new FormData();
        formData.append("file", file);

        const request = new XMLHttpRequest();
        const attComponent = this;
        request.open(
            "POST",
            `/tenant-api/file-upload?id=${Guid.EMPTY
            }&persist=false&userId=${localStorage.getItem("userId")}`)
        {
        };
        var that = this;
        request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
                var resp = JSON.parse(request.response);
                that.setAttachments(resp);
            }
        };

        request.send(formData);
    }


    createFile = (url) => {
        
        fetch(AppConfiguration.Setting().CorsAnywhereUrl + url)
            .then(res => res.blob())
            .then(blob => {
                let metadata = {
                    type: 'image/jpeg'
                };
                var id = Guid.create().toString();
                let file = new File([blob], id + ".jpg", metadata);
                this.uploadFile(file);
            });
    }

    setAttachments = (resp) => {
        var files = this.state.uploadedSelectedFile;
        files.push(resp);
        this.setState({
            uploadedSelectedFile: files
        });        

        if (files.length === this.state.selectedGoogleImage.length) {
            this.props.data.nextStep({
                index: this.props.data.index,
                selectedGoogleImage: this.state.selectedGoogleImage,
                attachments: files,
                t: this.props.data
            })
        }

    }

}

export default GoogleJobImage;
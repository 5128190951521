import React from 'react';
import Loader from "react-loader-spinner";
import { Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import externalService from '../../../Services/ExternalService';
import { connect } from 'react-redux';

class CompletedJobAttachmentsPage extends React.Component {

    state = {
        jobId: this.props.jobId,
        isLoading: false,
        attachments: []
    }

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { jobId } = this.props;
        this.getAttachments(jobId);
    }

    getAttachments = (jobId) => {
        this.setState({ isLoading: true });
        externalService.getAttachmentsByJobId(jobId).then(res => {
                
                let imageArray = [];
               
                    for (var i = 0; i < res.length; i++) {
                       
                            let image = res[i];
                            imageArray.push(image);
                    
                }

                this.setState({
                    attachments: imageArray,
                    isLoading: false
                });
            
        });
    }
    render() {

        const { isLoading, attachments } = this.state;
        return (
            <div className="App">
                <div className="home-wrapper container">
                    <h1>Attachments</h1>
                    <div className="row row job-list-card">
                        {isLoading ? <h2 className="align-items-center d-block">
                            <Loader type="ThreeDots" color={"#DB3D44"} height={10} width={100} />
                        </h2> :
                            <div style={{ lineHeight: "25px", width: "100%" }}>
                                <div className="p-5 innerHtmlContainer job-list-card-body">
                                   
                                    {attachments && attachments.length>0?
                                    <div>
                                        <h6>Job Attachment(s)</h6>
                                        <div className="mb-5">
                                            <ImageViewer
                                                attachments={attachments}
                                                isAfter={false}
                                                isExternal={true}
                                            ></ImageViewer>
                                        </div>
                                    </div>:<div>
                                    Attachment(s) Not Found
                                        </div>
                                    }
                                </div>

                            </div>}
                    </div>
                </div>
            </div>
        )
    }

}

export default connect()( CompletedJobAttachmentsPage );
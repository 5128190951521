import React from "react";
import moment from 'moment';
import Drawer from "../../../Common/Drawer/Drawer";
import { trackPromise } from "react-promise-tracker";
import { AXIOS } from "../../../../Utilities/HttpRequest/Axios";
import "./Quotes.scss";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Moment from "react-moment";
import ImageViewer from '../../../Common/ImageViewer/ImageViewer';
import StarRatingComponent from "react-star-rating-component";
import FeedbackViewer from "../../../Common/FeedbackViewer/FeedbackViewer";
import userService from "../../../../Services/UserService";
import bidService from "../../../../Services/BidService";
import { toast } from 'react-toastify';

class Quotes extends React.Component {
    state = {
        showProfileDrawer: false,
        profileDetails: {},
        isPmView: false,
        isSpView: false,
        isUserView: false,
        isDataLoaded: false,
        profileImageUrl: "/images/default-profile.png",
        skillsLoad: false,
        isShowNearbySuburbs: false,
        showLicensesTradee: false,
        jobTypeArray: [],
        showInsurancesTradee: false,
        itemSelected: 0,
        feedBackUserTypeCount: 0,
        feedbackResult: [],
        dateFormat: "dd/MM/yyyy",
        ActivateIndex: [],
        ExpandedIndex:[],
        isShowMore: false,
        show: 5,
        bidFeedbackList:[]
    };
    constructor(props) {
        super(props);
    }

    render() {        
        const quotesList = this.getQuotesList();
        return <div className="quotes-container">
            {(this.props.jobDetails.workflowStatusId == 6) ? <div className="alert alert-warning">Note: Please select the best quote for the job, if not decline all to request better quotes.</div>: ''}
            {quotesList}
        </div>;
    }

    increaseShow() {
        if (this.state.show < this.state.profileDetails.providerProfile.feedbacks.length) {
            this.setState({
                show: this.state.show + 5
            });
        }
        else {
            this.setState({
                show:5
            });
        }
       
    }

    getSPNotes = notes => {
        if (notes != null && notes.trim() != "") {
            return <div className="quote-description">Note: {notes}</div>;
        }
    };

    getProfile = userId => {
        try {
            let filters = {
                userId: userId,
                includeAttachments: true,
                includeBidRatings: true,
                includeNearbySuburbs: true,
                includeTradeeAttachments: true,
                isDetailedView: true,
                includeBadges: true,
                isTimeline: true,
                inCludePrefferedJobTypes: true,
                numberOfSuburbsNeeded: 10,
                ischat: false
            };
            trackPromise(
                AXIOS.GET(
                    `tenant-api/profile?filters=${btoa(JSON.stringify(filters))}`
                ).then(dataJson => {
                    this.setState({
                        profileDetails: dataJson,
                        isDataLoaded: true
                    });

                    if (dataJson.providerProfile.userId !== null) {
                        this.setState({
                            isPmView: false,
                            isSpView: true,
                            isUserView: false
                        });
                        if (dataJson.providerProfile.profileImageUrl != null &&
                            dataJson.providerProfile.profileImageUrl.trim() != ""
                        ) {
                            this.setState({
                                profileImageUrl: dataJson.providerProfile.profileImageUrl
                            });
                        }
                        if (dataJson.providerProfile.feedbacks != null
                            && dataJson.providerProfile.feedbacks.length > 0) {
                            this.setState({
                                feedbackResult: dataJson.providerProfile.feedbacks
                            });
                        }
                        if (dataJson.providerProfile.providerLicenses != null
                            && dataJson.providerProfile.providerLicenses.length > 0) {
                            this.convertLicensedTrades(dataJson.providerProfile.providerLicenses);
                        }

                    } else if (dataJson.propertyManagerProfile.userId !== null) {
                        this.setState({
                            isPmView: true,
                            isSpView: false,
                            isUserView: false
                        });

                        if (
                            dataJson.propertyManagerProfile.profileImageUrl !== null ||
                            dataJson.propertyManagerProfile.profileImageUrl.trim() != ""
                        ) {
                            this.setState({
                                profileImageUrl: dataJson.propertyManagerProfile.profileImageUrl
                            });
                        }
                    } else if (dataJson.userProfile.userId !== null) {
                        this.setState({
                            isPmView: false,
                            isSpView: false,
                            isUserView: true
                        });
                        if (
                            dataJson.userProfile.profileImageUrl !== null ||
                            dataJson.userProfile.profileImageUrl.trim() != ""
                        ) {
                            this.setState({
                                profileImageUrl: dataJson.userProfile.profileImageUrl
                            });
                        }


                    } else {
                        this.setState({
                            isDataLoaded: false
                        });
                    }
                })
            );
        } catch (error) {
            console.log(error);
        }
    };

    getFeedbackData = (bidId) => {
        try {
           
            trackPromise(
                userService.getFeedbackListByBidId(bidId)
                        .then(data => {
                            this.setState({
                                bidFeedbackList: data
                            });
                        })
                );
        } catch (error) {
            console.log(error);
        }
    };

    convertLicensedTrades(data) {
        if (!data)
            return;
        this.setState({
            jobTypeArray: new Array()
        });

        for (var j = 0; j < data.length; j++) {
            for (var i = 0; i < data[j].selectedMainJobTypes.length; i++) {
                var jobType = data[j].selectedMainJobTypes[i];
                var index = this.state.jobTypeArray.findIndex((item) => item.selectedJobTypeId === jobType.selectedJobTypeId);
                if ((index) < 0) {
                    jobType.licenceNumber = data[j].licenceNumber;

                    this.setState(prevState => ({
                        jobTypeArray: [...prevState.jobTypeArray, jobType]
                    }))


                }
                else {
                    var currentJobType = this.state.jobTypeArray.splice(index, 1);
                    var licenseNos = currentJobType[0].licenceNumber + ", " + data[j].licenceNumber;
                    jobType.licenceNumber = licenseNos;
                    this.setState(prevState => ({
                        jobTypeArray: [...prevState.jobTypeArray, jobType]
                    }))
                }
            }
        }
    }

    toggleProfileDrawer = userId => {
        if (!this.state.showProfileDrawer && userId != null) {
            this.getProfile(userId);
        }
        this.setState(state => ({
            showProfileDrawer: !this.state.showProfileDrawer
        }));
    };

    createDrawer = () => {
        const header = this.createProfileHeader();
        const body = this.createProfileBody();
        const footer = this.createProfileFooter();

        return (
            <Drawer
                size={"xxl"}
                open={this.state.showProfileDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    };

    createProfileHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">Profile</h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={() => this.toggleProfileDrawer(null)}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createProfileBody = () => {
        if (this.state.isDataLoaded) {
            return (
                <>
                    <div className="panel-body dashboardN pt-0">
                        <div className="provider-profile row">
                            <div className="col-md-12">
                                <div className="row profile-view-bg pt-4">
                                    <div className="col-md-3">
                                        <div className="profile-img-wrapper">
                                            <div className="profile-img">
                                                <img
                                                    src={this.state.profileImageUrl}
                                                    alt=""
                                                    className="rounded-circle img-150"
                                                />
                                            </div>
                                        </div>

                                        <div className="text-center mt-4">
                                            <div className="font-18 mb-1">
                                                <strong className="mt-0 mb-1">
                                                    {" "}
                                                    {
                                                        this.state.profileDetails.providerProfile
                                                            .displayName
                                                    }{" "}
                                                </strong>
                                            </div>
                                            <div className="font-14">
                                                <strong className="mt-0 mb-1"></strong>{" "}
                                                {this.state.profileDetails.providerProfile.email}
                                            </div>
                                            <div className="font-14">
                                                <strong className="mt-0 mb-1"></strong>{" "}
                                                {this.state.profileDetails.providerProfile.mobileNumber}
                                            </div>
                                        </div>
                                    </div>
                                    {this.getSPDetails()}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-5 mt-4">
                                        {(this.state.profileDetails) ? <div >
                                            <span className="mt-0 mb-1">Country in Operation:</span><strong>
                                                {this.state.profileDetails.providerProfile.workingCountry}</strong>
                                        </div> : ''}

                                        {(this.state.profileDetails) ? <div className="mt-2">
                                            <h4 className="mb-2 mt-3">Area Covered</h4>
                                            <div className="d-flex justify-content-between align-items-start">
                                                <div>
                                                    {this.state.profileDetails.providerProfile.preferredStates.map((val, index) => {
                                                        return <><label className="label label-md label-brand-blue">{val.label}
                                                        </label>
                                                        </>
                                                    })}

                                                </div>

                                                {(this.state.isShowNearbySuburbs) ? <div className="subtoggle">
                                                    <div>
                                                        {this.state.profileDetails.profileDetails.nearbySuburbs.map((val, index) => {
                                                            return <> <span className="label label-default">{val.label}</span>
                                                            </>
                                                        })}

                                                    </div>
                                                </div> : ''}
                                                {(this.state.profileDetails.nearbySuburbs && this.state.profileDetails.nearbySuburbs.length > 0) ? <div className="mt-1 ml-2" >
                                                    <a className="ellipsis clearfix pointer font-700" onClick={() => this.showNearbySuburbs()}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </a>
                                                </div> : ''}
                                            </div>
                                        </div> : ''}

                                        {(this.state.profileDetails) ? <div className="right-collaps mt-4">
                                            {(this.state.profileDetails.providerProfile.providerLicenses && this.state.profileDetails.providerProfile.providerLicenses.length > 0) ?
                                                <h3 className="profile-view-collapse mb-0" onClick={() => this.showLicenses()}>Licensed Trades
                                                    {(this.state.showLicensesTradee) ? <i className="ba-icn ba-icn-minus"></i> : <i className="ba-icn ba-icn-plus2"></i>}
                                                </h3> : ''}
                                            {(this.state.showLicensesTradee) ? <div >
                                                <table className="w-100 table table-sm">
                                                    {this.state.jobTypeArray.map((value, index) => {
                                                        return <>
                                                            <tr>
                                                                <td className="font-600 text-left">{value.selectedJobType}</td>
                                                                <td className="text-left">{value.licenceNumber}</td>
                                                            </tr>
                                                        </>
                                                    })}
                                                </table>
                                            </div> : ''}

                                            {(this.state.profileDetails.providerProfile.providerInsurances && this.state.profileDetails.providerProfile.providerInsurances.length > 0) ?
                                                <h3 className="profile-view-collapse mt-1 mb-0" onClick={() => this.showInsurances()}> Insurance
                                                    {(this.state.showInsurancesTradee) ? <i className="ba-icn ba-icn-minus"></i> : <i className="ba-icn ba-icn-plus2"></i>}
                                                    
                                                </h3> : ''}
                                            {(this.state.showInsurancesTradee) ? <div className="mt-2">
                                                {this.state.profileDetails.providerProfile.providerInsurances.map((value, index) => {
                                                    return <>
                                                        <div >
                                                            {(value.insuranceExpiredStatus == 3) ? <div>
                                                                <div className="row">
                                                                    {(value.isApplicableToAllTradeType) ? <div className="col-md-6">
                                                                        <h3 className="font-600 font-14 my-2">Applicable to All job Types</h3>
                                                                    </div> : ''}
                                                                    {(!value.isApplicableToAllTradeType) ? <div >
                                                                        {value.selectedMainJobTypes.map((val, index) => {
                                                                            return <>
                                                                                <div className="col-md-6" >
                                                                                    <h3 className="font-600 font-14">{val.selectedJobType}</h3>
                                                                                </div>
                                                                            </>
                                                                        })}
                                                                    </div> : ''}

                                                                </div >
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div>Public Liability</div>
                                                                        <h3 className="font-600 font-14 m-1">$ {value.publicLiabilityDisplay} </h3>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div>
                                                                            <div>Professional indemnity</div>
                                                                            <h3 className="font-600 font-14 m-1">${value.professionalIndemnityDisplay}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div > : ''}
                                                        </div >
                                                    </>
                                                })}
                                            </div > : ''}
                                        </div > : ''
                                        }
                                    </div>
                                    <div class="col-md-7 mt-4">
                                        {(this.state.profileDetails.providerProfile && this.state.profileDetails.providerProfile.feedbacks) ? <div>
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <h3 className="font-500">Feedback Received <span
                                                    className="font-300">({this.state.profileDetails.providerProfile.feedbacks.length})</span></h3>
                                                <div className="form-inline">
                                                    <div className="form-group">
                                                        <label>Filter By User Type</label>
                                                        <select onChange={(event) => this.onOptionsSelected(event.target.value)} className="ml-2 form-control">
                                                            <option value="0" selected>All</option>
                                                            <option value="3">Property Managers</option>
                                                            <option value="4">Property Tenants</option>
                                                            <option value="2">Consumers</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-grid received-feedBack-scroller">
                                                <table className="received-feedBack-main table">
                                                    {this.state.feedbackResult.slice(0, this.state.show).map((value, index) => {
                                                        return <>
                                                            <tbody >
                                                                <tr id={"feedback-item-" + { index }}>
                                                                    <td>
                                                                        <div className="">
                                                                            <span>{moment(value.dateTime).format(this.state.dateFormat.toUpperCase())}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-light">
                                                                            {(value.feedBackUserType == 3) ? <span >(Property Manager)</span> : ''}
                                                                            {(value.feedBackUserType == 4) ? <span >(Tenant)</span> : ''}
                                                                            {(value.feedBackUserType == 2) ? <span >(Consumer)</span> : ''}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="font-600">{value.user}</div>
                                                                    </td>
                                                                    <td className="feedback-stars">
                                                                        <StarRatingComponent
                                                                            name="feedbackRating"
                                                                            starCount={5}
                                                                            value={value.avarageRating}

                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <div className="mr-4">{(Math.round(value.avarageRating * 100) / 100).toFixed(1)}</div>
                                                                    </td>
                                                                    <td>
                                                                        <a className="ellipsis align-items-center px-2 clearfix pointer font-700"
                                                                            onClick={() => this.toogleItems(index)}
                                                                            id="bt">
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                        </a>

                                                                    </td>
                                                                </tr>
                                                                {(this.state.ActivateIndex.includes(index)) ?
                                                                    <tr className="view-feedback">
                                                                        <td colspan="12">
                                                                            {(this.state.ActivateIndex.includes(index) || value.feedback.length <= 60) ? <div className="mb-3">
                                                                                <span>{value.feedback}</span>
                                                                            </div> : ''}
                                                                            {(!this.state.ActivateIndex.includes(index) && value.feedback.length > 60) ?<div >
                                                                                {(!this.state.ExpandedIndex.includes(index)) ?<div >
                                                                                    <span>{value.feedback}</span>
                                                                                    <button className="button button-default button-sm float-right font-12"
                                                                                        onClick={() => this.toogleExpandItems(index)}><i className="ba-icn ba-icn-angle-down"></i> Read More</button>
                                                                                </div> : ''}
                                                                                {(this.state.ExpandedIndex.includes(index))?<div  >
                                                                                    <span></span>
                                                                                    <button className="button button-default button-sm float-right font-12"
                                                                                        onClick={() => this.toogleExpandItems(index)}> Show Less</button >
                                                                                </div >: ''}
                                                                            </div >: ''}
                                                                            {(this.state.ActivateIndex.includes(index))? <div >
                                                                                    <div colspan="4">
                                                                                    <FeedbackViewer
                                                                                        bidId={value.bidId}
                                                                                        showFeedbackOverView={this.state.isShowMore}
                                                                                        bidFeedbackList={this.state.bidFeedbackList}
                                                                                       ></FeedbackViewer>
                                                                                </div>
                                                                            </div>: ''}
                                                                        </td >
                                                                    </tr > : ''}
                                                            </tbody >
                                                        </>
                                                    })}
                                                </table >
                                            </div >
                                        </div > : ''}
                                        <div className="clearfix mt-2 text-center">
                                            <button className="button button-default button-sm float-right font-12"
                                                onClick={() => this.increaseShow()} ><i
                                                className="ba-icn ba-icn-angle-down"></i> Load More</button>
                                        </div >
                                    </div >
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            );
        }
    };

    getSPDetails = () => {
        if (this.state.isSpView) {
            return (
                <>
                    <div className="col-md-9">
                        <div className="d-flex justify-content-between">
                            <div className="col-md-9">
                                <div className="">
                                    <table className="font-14 profile-view-table">
                                        <tbody className="profile-view-table">
                                            <tr>
                                                <td>ABN </td>
                                                <td className="font-600">
                                                    {" "}
                                                    : {this.state.profileDetails.providerProfile.abn}
                                                </td>
                                            </tr>
                                            {this.getSPBusinessName()}
                                            <tr>
                                                <td>Office No </td>
                                                <td className="font-600">
                                                    {" "}
                                                    :{" "}
                                                    {
                                                        this.state.profileDetails.providerProfile
                                                            .officePhone
                                                    }
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <hr />
                                <div>
                                    <h3>Skills</h3>
                                    {this.getSPSkillsSummary()}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="user-contact text-right">
                                    <div className="mb-2">
                                        <label className="ba-switch ready-to-work-profile transform-origin-right">
                                            <input className="ba-switch-input" type="checkbox" defaultChecked={this.state.profileDetails.providerProfile.readyToWork}
                                                name="readyForWork" disabled />
                                            <span className="ba-switch-label">
                                                <span className="on-label">Ready to Work</span>
                                                <span className="off-label">Ready to Work</span>
                                            </span>
                                            <span className="ba-switch-text"></span>
                                        </label>

                                    </div>
                                    <div className="">
                                        <div className="font-30">
                                            <strong>{(Math.round(this.state.profileDetails.providerProfile.overallRating * 100) / 100).toFixed(1)}</strong></div>

                                        <StarRatingComponent
                                            name="overallRating"
                                            starCount={5}
                                            value={this.state.profileDetails.providerProfile.overallRating}

                                        />
                                    </div>
                                </div >
                            </div >
                        </div>
                        <div className="profile-stats pt-3 pb-3" >
                            {(this.state.profileDetails.providerProfile.hourlyRate) ? <div className="stat" >
                                <div className="stat-count">
                                    {this.state.profileDetails.providerProfile.hourlyRate}
                                </div>
                                <div className="stat-details">
                                    <span>Per hour</span>
                                    <span>rate</span>
                                </div>
                            </div> : ''}
                            <div className="stat">
                                <div className="stat-count">
                                    {this.state.profileDetails.providerProfile.completedJobCount}
                                </div>
                                <div className="stat-details">
                                    <span>Completed</span>
                                    <span>jobs</span>
                                </div>
                            </div>
                            <div className="stat">
                                <div className="stat-count">
                                    {this.state.profileDetails.providerProfile.calloutFee}
                                </div>
                                <div className="stat-details">
                                    <span>Callout</span>
                                    <span>fee</span>
                                </div>
                            </div>
                        </div>
                        <div className="profile-badges">
                            {(this.state.profileDetails.providerProfile.hasLicenseBadge) ?
                                <div className="profile-badge">
                                    <div className="license-badge"></div>
                                    <div className="badge-name">Licence</div>
                                </div> : ''}
                            {(this.state.profileDetails.providerProfile.hasInsuranceBadge) ?
                                <div className="profile-badge">
                                    <div className="insurance-badge"></div>
                                    <div className="badge-name">Insurance</div>
                                </div> : ''}
                            {(this.state.profileDetails.providerProfile.hasABNBadge) ?
                                <div className="profile-badge" >
                                    <div className="abn-badge"></div>
                                    <div className="badge-name">ABN<br />Registered</div>
                                </div> : ''}
                            {(this.state.profileDetails.providerProfile.hasJobFiftyPlusBadge) ?
                                <div className="profile-badge" >
                                    <div className="topRated-badge"></div>
                                    <div className="badge-name">You are<br />Top rated</div>
                                </div> : ''}
                            {(this.state.profileDetails.providerProfile.hasCompletedMoreThanFiftyJobs) ?
                                <div className="profile-badge" >
                                    <div className="plus50Jobs-badge"></div>
                                    <div className="badge-name">Job 50+<br />Completed</div>
                                </div> : ''}
                            {(this.state.profileDetails.providerProfile.hasCm3CertifiedBadge) ?
                                <div className="profile-badge" >
                                    <div className="cm3Certified-badge"></div>
                                    <div className="badge-name">CM3<br />Certified</div>
                                </div> : ''}
                        </div >
                    </div>
                </>
            );
        }
    };

    toogleExpandItems = (index) =>  {
        if (this.state.ExpandedIndex.includes(index)) {
            this.setState(state => ({
                ExpandedIndex: this.state.ExpandedIndex.filter(item => item !== index),
               
            }));
        }
        else {
            this.setState(prevState => ({
                ExpandedIndex: [...prevState.ExpandedIndex, index]
               
            }));
           
        }
    }

    toogleItems = (index) => {
        this.getFeedbackData(this.state.feedbackResult[index].bidId);
        if (this.state.ActivateIndex.includes(index)) {
            this.setState(state => ({
                ActivateIndex: this.state.ActivateIndex.filter(item => item !== index),
                isShowMore: true
            }));
          
        }
        else {

            this.setState(prevState => ({
                ActivateIndex: [...prevState.ActivateIndex, index],
                isShowMore: false
            }));

        }
    }

    onOptionsSelected = (event) => {
        var resultArrey = new Array();
        if (this.state.profileDetails.providerProfile.feedbacks != null) {
            for (var j = 0; j < this.state.profileDetails.providerProfile.feedbacks.length; j++) {
                if (this.state.profileDetails.providerProfile.feedbacks[j].feedBackUserType == event || event == 0) {

                    resultArrey.push(this.state.profileDetails.providerProfile.feedbacks[j]);
                }
            }
            this.setState(state => ({
                feedBackUserTypeCount: resultArrey.length,
                feedbackResult: resultArrey
            }));

        }
    }
    getSPBusinessName = () => {
        if (this.state.profileDetails.providerProfile.businessName.trim() != "") {
            return (
                <>
                    <tr>
                        <td>Business Name </td>
                        <td className="font-600">
                            {" "}
                            : {this.state.profileDetails.providerProfile.businessName}
                        </td>
                    </tr>
                </>
            );
        }
    };

    getSPAllSkillsSummary = () => {
        const skills = this.state.profileDetails.providerProfile.primaryServices.map(
            (service, index) => {
                return (
                    <>
                        <label className="label label-md label-brand-blue">
                            {service.title}
                        </label>
                    </>
                );
            }
        );
        return skills;
    };

    getSPSkillsSummary = () => {
        if (this.state.profileDetails.providerProfile.primaryServices !== null) {
            return (
                <>
                    <div className="mb-4">{this.getSPAllSkillsSummary()}</div>
                </>
            );
        }
        else {
            return (
                <>
                    {(this.state.profileDetails.providerProfile.preferredJobTypesGrouped !== null) ? <div className="d-flex align-items-start align-items-center">
                        <div className="d-inline mr-4">
                            {this.state.profileDetails.providerProfile.preferredJobTypesGrouped.map((value, index) => {
                                return <>
                                    <div className="d-inline">
                                        <label className="label label-md label-brand-blue">{value[0].parentLabel}</label>
                                        {(this.state.skillsLoad) ?
                                            <div className="d-inline">
                                                {value.map((val, index) => {
                                                    return <><label className="label label-md label-brand-blue">{val.label}
                                                    </label>
                                                    </>
                                                })}

                                            </div> : ''}
                                    </div>
                                </>
                            })}
                        </div >
                        <a className="ellipsis clearfix pointer font-700" onClick={() => this.toogleItemsInSkills()} id="bt" >
                            <span></span>
                            <span></span>
                            <span></span>
                        </a >
                    </div > : ''}

                </>
            );

        }
    };

    toogleItemsInSkills() {
        this.setState(state => ({
            skillsLoad: !this.state.skillsLoad
        }));

    }

    showNearbySuburbs() {
        this.setState(state => ({
            isShowNearbySuburbs: !this.state.isShowNearbySuburbs
        }));

    }

    showLicenses() {
        this.setState(state => ({
            showLicensesTradee: !this.state.showLicensesTradee
        }));
    }

    showInsurances() {
        this.setState(state => ({
            showInsurancesTradee: !this.state.showInsurancesTradee
        }));
    }

    createProfileFooter = () => {
        return (
            <>
                <div className="card-actions">
                    <button
                        className="more-actions"
                        onClick={() => this.toggleProfileDrawer(null)}
                    >
                        {" "}
                        Cancel{" "}
                    </button>
                </div>
            </>
        );
    };

    getQuotesList = () => {
        const quotes = this.props.bids.map((bid, index) => {
            let drawer = this.createDrawer();
            return (
                <>
                    <div className="quote-card" key={index}>
                        <div className="quote-card-header">
                            <div className="trade-personal">
                                <div className="trade-pic">
                                    <img
                                        onClick={() => this.toggleProfileDrawer(bid.providerId)}
                                        src={bid.providerProfile.profileImageUrl}
                                        alt="bid.providerProfile.email"
                                        className="profile-pic"
                                        onError={e => {
                                            e.target.onerror = null;
                                            e.target.src = "/Images/default-profile.png";
                                        }}
                                    />
                                </div>
                                <div className="trade-contact">
                                    <h3>{bid.name}</h3>
                                    <p>email: {bid.email}</p>
                                    <p>tel: {bid.phone}</p>
                                </div>
                            </div>
                            <div className="trade-info">
                                {this.getQuoteAmount(bid)}
                            </div>
                        </div>

                        <div>
                            <div className="quote-card-body">
                                {this.getSPNotes(bid.notes)}
                                <div className="quote-description">{bid.feedback}</div>
                                <div className="quote-details-table">
                                    {(bid.availabilityStart && bid.availabilityEnd) ?
                                        <div className="job-details-table-row">
                                            <div class="table-label">Schedule</div>
                                            <div class="table-data"><Moment format="DD/MM/YYYY">{bid.availabilityStart}</Moment> to <Moment format="DD/MM/YYYY">{bid.availabilityEnd}</Moment></div>
                                        </div>
                                         : ''}
                                    {(bid.estimatedTimeDisplay) ?
                                    <div className="job-details-table-row">
                                        <div class="table-label">Estimated Time</div>
                                        <div class="table-data">{bid.estimatedTimeDisplay}</div>
                                    </div>
                                     : ''}
                                </div>                           
                            </div>
                            
                            <div>
                                {(bid.hasOwnProperty("attachments")) ?
                                    <ImageViewer attachments={bid.attachments} ></ImageViewer> : ''
                                }
                                {/* {this.getInvoiceAttachments(bid)} */}
                            </div>
                        </div>
                        {/* {(!this.isApprovedOrDeclined()) ? <>
                            <div className="quote-actions">
                                <button title="Accept" onClick={() => {this.onClickApproveBid(bid)}}>Accept</button>
                            </div>
                        </> : ''} */}
                        
                    </div>
                    {drawer}
                </>
            );
        });
        return quotes;
    };

    isApprovedOrDeclined = () => {
        return this.props.bids.filter(a => a.quoteApprovalStatusId == 1 || a.quoteApprovalStatusId == 2).length > 0
    }

    onClickApproveBid = (bid) => {
        bidService.approveBidByOwner(this.props.jobDetails.id, bid.bidId, "").then(res => {
            toast.success('Bid accepted by owner', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
            this.props.onApproveBid(bid);
        })
    }

    getInvoiceAttachments = (bid) => {
        if(bid.job != null && bid.job.attachments != null) {
            let invoiceAttachments = bid.job.attachments.filter(a => a.type == 6 && a.userId == bid.providerId)
            return (
                <div className="">
                    <ImageViewer attachments={invoiceAttachments} isInvoice={true}></ImageViewer>
                </div>
            );
        }
    }

    getQuoteAmount(bid) {
        if (bid.placeBidType) {
            if (bid.placeBidType === 1 || bid.placeBidType === 3) {
                return (
                    <div className="quoted-amount">
                        <div>Quoted Amount</div>
                        <div className="quote-value">${bid.amount.toFixed(2)}</div>
                    </div>
                );
            } else if (bid.placeBidType === 2) {
                return (
                    <div className="quoted-amount">
                        <div className="quote-type-min-max">
                            <table>
                                <tr>
                                    <td className="text-center"><label className="form-label mb-0">Min</label></td>
                                    <td className="text-center pl-2"><label className="form-label mb-0">Max</label></td>
                                </tr>
                                <tr>
                                    <td className="text-center"><div className="quote-value">${bid.amount}</div></td>
                                    <td className="text-center pl-2"><div className="quote-value">${bid.amountMax}</div></td>
                                </tr>
                            </table>
                        </div>
                    </div>                    
                );
            } else if (bid.placeBidType === 4) {
                return (
                    <div className="quoted-amount">
                        <div className="quote-type-min-max">
                            <table>
                                <tr>
                                    <td className="text-center"><label className="form-label mb-0">Call out fee</label></td>
                                    <td className="text-center pl-2"><label className="form-label mb-0">Hourly rate</label></td>
                                </tr>
                                <tr>
                                    <td className="text-center"><div className="quote-value">${bid.callOutFee}</div></td>
                                    <td className="text-center pl-2"><div className="quote-value">${bid.hourlyRate}</div></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default Quotes;

import React, { useState } from "react";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";
import { Dropdown } from 'semantic-ui-react';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loader-spinner";
// Styles
 //import "../OwnerApproval/JobApproveDrawer.scss";

class ExternalJobReject extends React.Component {
    notifyError = (msg) => toast.error(msg,{
      position: toast.POSITION.BOTTOM_RIGHT
    });
  state = {
    jobDetails:{},
    description:"",
    userId:"",
    isLoading: false,
    disableRejctButton:false
  };
  constructor(props) {
    super(props);

    this.JobReject = this.JobReject.bind(this);
    this.description = React.createRef();
  }


  componentDidMount() {
    this.setState({jobDetails:this.props.jobDetails,userId:this.props.userId});
  } 

  render() {
    return (
      <>
        {this.createJobRejectBody()}
      </>
    );
  }
 
  createJobRejectBody = () => {
    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="w-100 text-left">Note</label>
              <textarea
                className="form-control"
                id="txtFeedback"
                rows="3"
                ref={this.description}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row d-none">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  name="markAsCompleted"
                  type="checkbox"
                  id="1"
                  className="custom-control-input"
                />
                <label
                  title=""
                  type="checkbox"
                  htmlFor="1"
                  className="custom-control-label"
                >
                  {" "}
                  Mark as Completed
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-right edit-job-actions">
          <button class="btn btn-reject" onClick={this.JobReject} disabled={this.state.disableRejctButton}>
            {this.state.isLoading ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : <>Reject</>}
          </button>
          <button class="btn btn-primary" onClick={()=>this.props.history.goBack()}> Cancel</button>
          </div>
      </div>
    );
  };

  JobReject = () => {
    if (this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
      toast.error("Please enter job rejecting reason.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      return;
    }

    this.setState({
      isLoading: true,
      disableRejctButton:true
    });

    if (this.state.userId == null || this.state.userId == 'undefind') {
      this.setState({ userId: localStorage.getItem("exernalUserId") });
    }

    AXIOS.GET(`tenant-api/job-reject-owner?userId=${this.state.userId}&jobId=${this.props.jobDetails.id}&note=${this.description.current.value}`).then(response => {
      if (response && response.success) {
        this.setState({
          isLoading: false
        });
        toast.success("Job Rejected.", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        this.props.onJobApproveOrReject();
        this.props.history.push(`/external/overview?code=${this.props.jobDetails.id}&userId=${this.state.userId}`);
        window.location.reload();
      } else {
        this.setState({
          isLoading: false
        });
        this.props.history.push(`/external/overview?code=${this.props.jobDetails.id}&userId=${this.state.userId}`);
                toast.error('Job’s status has been changed. Please contact the property manager for more details', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
      }
    });
  };
}


export default connect(
)(ExternalJobReject);

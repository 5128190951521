import React, { Component } from 'react';
import '../../../Layout/LogMaintenanceBotLayout.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import botService from '../../../../Services/BotService.js';
import * as _ from 'lodash';
import { Accordion, ButtonToolbar, Card, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import Axios from 'axios';
import moment from 'moment';
class RentalDetailsWizard extends React.Component {

    constructor(props) {
        super(props);
        this.refProblem = React.createRef();
        this.state = {
            isCustomThemeEnable: this.props.isCustomThemeEnable,
            theme: this.props.theme,
            style: this.props.style,
            tenantId: this.props.tenantId,
            rentalDetails: {},
            arrearsDetails: {},
            currentPropertyName: this.props.propertyName,
            currentPropertyId: this.props.propertyId,
            leaseStartDate: '',
            leaseEndDate: '',
            leasePeriod: '',
            rentalAmount: 0,
            rentalDueDate: '',
            isRentalDetailFound: false,
            paidToDate: 0,
            arrearsAmount: 0,
            arrearsDays: 0,
            isArreasDetailFound: false,
        }
    }

    componentDidMount() {
        this.LoadRentalDetails();
        this.LoadArrearsDetails();
        this.setTheme();
    }

    componentDidUpdate() {
        if (this.props.isCustomThemeEnable) {
            this.createToggleBtnDynamicStyle();
            this.createOkBtnDynamicStyle();
            this.createActiveBtnDynamicStyle();
        }
    }

    createToggleBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.color;
        const border = this.props.style.optionsStyle.border;
        let labelInsideToggleGroup = document.querySelectorAll('label.btn');

        for (var i = 0; i < labelInsideToggleGroup.length; i++) {
            labelInsideToggleGroup[i].style.color = color;
            labelInsideToggleGroup[i].style.border = border;
            labelInsideToggleGroup[i].style.background = '';
        }
    }
    createOkBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.color;
        let okIcons = document.querySelectorAll('span.ba-icn-ok');

        for (var i = 0; i < okIcons.length; i++) {
            okIcons[i].style.background = color;
        }
    }
    createActiveBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.activeBackground;
        let active = document.querySelectorAll('label.btn.active');
        for (var i = 0; i < active.length; i++) {
            active[i].style.background = color;
        }
    }

    LoadRentalDetails = () => {
        const { rentalDetails, currentPropertyName } = this.state;
        var tenantId = this.props.tenantId;
        botService.getRentalDetails(tenantId).then(res => {
            if (res) {
                this.setState({ rentalDetails: res, });
                if (res && res.tenancyRentalDetailList && res.tenancyRentalDetailList.length > 0) {
                    res.tenancyRentalDetailList.map((a, index) => {
                        if (a.propertyName == currentPropertyName && a.status == 12) {
                            this.setState({
                                leaseStartDate: moment(a.leaseStartDate).format('DD/MM/YYYY'),
                                leaseEndDate: moment(a.leaseEndDate).format('DD/MM/YYYY'),
                                leasePeriod: a.leasePeriod,
                                rentalAmount: a.rentalAmount,
                                rentalDueDate: a.rentalDueDate,
                                isRentalDetailFound: true
                            });
                        }
                    });
                }
            }
        }, error => {
        });
    }

    LoadArrearsDetails = () => {
        const { arrearsDetails, currentPropertyName } = this.state;
        var tenantId = this.props.tenantId;
        botService.getArrearsDetails(tenantId).then(res => {
            if (res) {
                this.setState({ arrearsDetails: res });
                if (res && res.tenancyArrearsDetailList && res.tenancyArrearsDetailList.length > 0) {
                    res.tenancyArrearsDetailList.map((a, index) => {
                        if (a.propertyName == currentPropertyName && a.status == 12) {
                            a.arrears.map((b, index) => {
                                this.setState({
                                    arrearsAmount: b.arrearsAmount ? b.arrearsAmount : 'N/A',
                                    arrearsDays: b.arrearsDays ? b.arrearsDays : 'N/A',
                                    paidToDate: b.paidToDate ? moment(b.paidToDate).format('DD/MM/YYYY') : 'N/A',
                                    isArreasDetailFound: true
                                });

                            });
                        }
                    });
                }
            }
        }, error => {
        });
    }

    addBodyStyle = () => {
        var element = document.body;
        element.style.cssText = 'overflow:hidden !important;';
        element.style.cssText = 'padding-top:0 !important; overflow:hidden !important; background: transparent !important;';
        document.documentElement.style.cssText = "background: transparent !important;";
    }

    setTheme = () => {
        const { style, theme, isCustomThemeEnable } = this.props;
        this.setState({
            style: style,
            theme: theme,
            isCustomThemeEnable: isCustomThemeEnable
        });
    }

    render() {
        let body = this.createBody();
        return <>
            <div>
                {body}
            </div>
        </>
    }

    createBody = () => {
        const { theme } = this.state;
        const { t } = this.props;
        let { rentalDetails, currentPropertyName, isRentalDetailFound, leasePeriod, leaseStartDate,
            leaseEndDate, rentalAmount, rentalDueDate, arrearsAmount, arrearsDays, paidToDate, isArreasDetailFound } = this.state;

        return <div>
            {(isRentalDetailFound) ?
                <div className="wizard-body wzrd-post-a-job tenant-chat-bot" >
                    <div className="wizard-header">
                        <h2>{t('new_ui_lbl_rental_header')}{currentPropertyName} </h2>                        
                    </div>

                    <div className="mb-2 tracking-timeline no-stepping">
                        {(isRentalDetailFound) ?
                            <div className="mt-3 mb-5 d-inline-block w-100">
                                <div className='d-flex flex-row align-items-center pt-0 step'>
                                    <div className='mr-3'><span className='icon-ico-home-cock status-icon'></span></div>
                                    <div className='d-flex flex-column'>
                                        <p className='mb-0 status-title d-flex align-items-center'>{t('rd_lbl_rental_lease_period')}</p>
                                        <p className='mb-0 status-text text-light-brown'>{leasePeriod}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center pt-4 step'>
                                    <div className='mr-3'><span className='icon icon-ico-caleandar-start status-icon'></span></div>
                                    <div className='d-flex flex-column'>
                                        <p className='mb-0 status-title d-flex align-items-center'>{t('new_ui_lbl_rental_lease_st_date')}</p>
                                        <p className='mb-0 status-text text-light-brown'>{leaseStartDate}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center pt-4 step'>
                                    <div className='mr-3'><span className='icon icon-ico-caleandar-stop status-icon'></span></div>
                                    <div className='d-flex flex-column'>
                                        <p className='mb-0 status-title d-flex align-items-center'>{t('new_ui_lbl_rental_lease_end_date')}</p>
                                        <p className='mb-0 status-text text-light-brown'>{leaseEndDate}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center pt-4 step inactive'>
                                    <div className='mr-3'><span className='icon icon-ico-doller-mark status-icon'></span></div>
                                    <div className='d-flex flex-column'>
                                        <p className='mb-0 status-title d-flex align-items-center'>{t('new_ui_lbl_rental_amount')}</p>
                                        <p className='mb-0 status-text text-light-brown'>{rentalAmount}</p>
                                    </div>
                                </div>                                
                            </div>                            
                            
                            : <div className="d-flex justify-content-center align-items-center flex-column mt-5"><img src='images/no-tenant-jobs-found.svg'/><p className="font-400 mt-3 font-18">No rental detail found.</p></div>
                        }

                        {(isArreasDetailFound) ?
                            <div className='d-flex align-items-between bg-light rounded py-3'>
                                <div className='d-flex flex-column align-items-center col-4 px-3'>
                                    <p className="userTip mb-0 text-light-brown font-12">{t('new_ui_lbl_arrears_amount')} </p>
                                    <p className="font-500 text-dark font-12">${arrearsAmount}</p>
                                </div>
                                <div className='d-flex flex-column align-items-center col-4 px-3'>
                                    <p className="userTip mb-0 text-light-brown font-12">{t('rd_lbl_arrears_days')} </p>
                                    <p className="font-500 text-dark font-12">{arrearsDays}</p>
                                </div>
                                <div className='d-flex flex-column align-items-center col-4 px-3'>
                                    <p className="userTip mb-0 text-light-brown font-12">{t('rd_lbl_arrears_paid_to_date')} </p>
                                    <p className="font-500 text-dark font-12">{paidToDate}</p>
                                </div>                                
                            </div>
                            :                            
                            <div className="d-flex justify-content-center align-items-center flex-column mt-5"><img src='images/no-tenant-jobs-found.svg'/><p className="font-400 mt-3 font-18">{t('rd_lbl_no_arrears_found')}</p></div>
                        }
                    </div>

                    <div className="wizard-actions">
                        <button type="button" className="btn btn-primary mx-0" onClick={() => this.props.backToMainMenu()} style={this.state.style.secondaryButtonStyle}>{t('new_ui_pj_btn_back_to_menu')}</button>
                    </div>

                </div>
                :<div className='d-flex justify-content-center'>No Data Found.</div>
            }
        </div>
    }
}


export default connect()(RentalDetailsWizard);
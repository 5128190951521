import React, { useState } from "react";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import Loader from "react-loader-spinner";


class ExternalInvoiceOwnerFeedBack extends React.Component {

  state = {
    agencyLogoUrl:'',
    invoiceId:'',
    commentCode: '',
    userId:'',
    isLoading: false
  };
  constructor(props) {
    super(props);
    this.setState({ invoiceId:this.props.invoiceId, commentCode: this.props.commentCode, isLoading: true });
  }


  componentDidMount() {
    this.getOrgnizationUrl();
    this.updateOwnerComment();
  }

  render() {

    return (
      <>
        {this.createExternalInvoiceOwnerFeedBackBody()}
      </>
    );
  }

  createExternalInvoiceOwnerFeedBackBody = () => {
    const { isLoading } = this.state;
    return (
        <div id="wrapper" className="container">
          <div className="navbar navbar-light px-0 pt-0 pb-4">
          {!this.state.loading?
            <a className="navbar-brand">
            { this.state.agencyLogoUrl ?
              <img src={this.state.agencyLogoUrl} alt="AgencyLogo" className="logo" />:
              <a>Invoice Automate</a>
            }
                <h5 className="mt-1 font-400">Invoice Processor</h5>
            </a>: <Loader type="ThreeDots" color={"#DB3D44"} height={10} width={100} /> }
          </div>
          <div className="card external-card">
            <div className="card-header menu-bar p-2">
                Owner Comment
            </div>
                <div className="card-body comment-wrapper">
            { this.state.isLoading ?
            <Loader type="ThreeDots" color={"#DB3D44"} height={10} width={100} /> : ''
            }
              <p>
                You have marked the option "<strong>{this.commentString(this.props.commentCode)}</strong>" successfully for the invoice.
              </p>
            </div>
          </div>
        </div>
    );
  };

  updateOwnerComment = () => {
      try {
        AXIOS.POST(
          `tenant-api/invoice-process?invoiceId=${this.props.invoiceId}&userId=${this.props.userId}&commentCode=${this.props.commentCode}`
        ).then(data => {
          if(data)
          this.setState({isLoading : false});
        })
      } catch (error) {
        console.log(error);
      }
  };

  commentString = (commentCode) => {
    switch (commentCode) {
      case "1":
          return "I have made the payment to the trust account";
      case "2":
          return "I have made the payment directly";
      case "3":
          return "Please wait until funds are received prior to paying this bill";
      default:
          return "none";
  }
}

getOrgnizationUrl = () => {
  this.setState({isLoading : true});
    try {
      AXIOS.GET(
        `tenant-api/invoice-process/logoUrl?invoiceId=${this.props.invoiceId}`
      ).then(res => {
        if(res)
        this.setState({agencyLogoUrl : res.url, isLoading : false});
      })
    } catch (error) {
      console.log(error);
    }
  };

};

export default connect(
)(ExternalInvoiceOwnerFeedBack);

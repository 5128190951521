import React, { Component } from 'react';
import { connect } from "react-redux";

export class Paginator extends Component {

  state = {
    page: 1,
    sizePerPage: 10,
    loading: true,
    total: 0
  };

  constructor(props){
    super(props)
  }


  render(){
    const { itemCount,listSize,curenntPage} = this.props;
      let total = parseInt(itemCount);
      let pageSize = parseInt(listSize);
      let pageNumber = parseInt(curenntPage);
      let pageCount = Math.ceil((total/pageSize));
      let pageArray = this.getPageArray(pageCount,pageNumber,5);
      if(total==0 || pageSize>=total){
        return '';
      }
      if(pageCount>5 && pageNumber>=5){
        pageArray.pop();
      }
    return  <ul className="pagination">
              {(pageNumber >2)?<li className="page-item" title="first page">
                <button className="page-link" onClick={()=>this.pageNavigetor(1)}>&lt;&lt;</button>
              </li>:''}
              {(pageNumber >1)?<li className="page-item" title="previous page">
                <button className="page-link" onClick={()=>this.pageNavigetor(pageNumber-1)}>&lt;</button>
              </li>:''}
              {pageArray.map((val, index) => (
                <li className={`page-item ${(pageNumber==val)? 'active' : ''}`} title={val} key={index}>
                  <button  className="page-link" onClick={()=>this.pageNavigetor(val)}>{val}</button>
                </li>
              ))}
              {(total > pageSize && (pageCount-pageNumber)>0)?<li className="page-item" title="next page">
                <button className="page-link" onClick={()=>this.pageNavigetor(pageNumber+1)}>&gt;</button>
              </li>:''}
              {(total > pageSize && (pageCount-pageNumber)>1)?<li className="page-item" title="last page">
                <button className="page-link" onClick={()=>this.pageNavigetor(pageCount)}>&gt;&gt;</button>
              </li>:''}
            </ul>
  }

  getPageArray=(count,pageNumber,size)=>{

    if(count<=5 && count>0){
      return Array.from({length: count}, (_, i) => i + 1);
    }else if(count>0 && pageNumber>3 && (count-pageNumber)>2){
      return Array.from({length: size}, (_, i) => (pageNumber-3)+i);
    }else if(count>0 && (count-pageNumber)<=2){
      return Array.from({length: size}, (_, i) => (count-3)+i);
    }else if(count>0){
        return Array.from({length: size}, (_, i) => i+1);
    }else{
        return [];
    }
  }

  pageNavigetor=(pageNumber)=>{
    this.setState({page:pageNumber}, function() {
        this.updateList();
    });    
  }

  updateList=()=>{
    this.props.updateJobList(true,this.state.page);
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
        pageNumber : this.state.page
  };
};

export default connect(mapStateToProps)(Paginator);
import React from 'react';
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
//import "./Styles/style.scss";
import { ToastContainer } from 'react-toastify';
import ExternalUserLayout from "./Components/Layout/ExternalUserLayout";
import { createBrowserHistory } from 'history';
import { AppConfiguration } from "read-appsettings-json";
import jobService from './Services/JobService';
import PouchDbService from './Services/PouchDbService';
import { MasterDataType, WorkflowStates } from '../src/Constants/Enums';
import userService from './Services/UserService';
import ExternalDownloadPage from './Components/External/DownloadPage/DownloadPage';
import settingsService from './Services/settingsService';
import translateService from './Services/TranslateService';
var history = createBrowserHistory();

class ExternalUserApp extends React.Component {

    state = {
        jobDetails: null,
        isJobDetailsLoading: false,
        isCustomThemeEnable: false,
        theme: {
            logoUrl: '',
            brandColor: '',
            textColor: '',
            primaryButtonBackground: '',
            primaryButtonTextColor: ''
        }
    }

    constructor(props) {
        super(props);
        var userid = '';
        if (window.location.search.indexOf("userId") > -1) {
            userid = window.location.search.split("userId=")[1];
        }
        localStorage.setItem("userId", userid);

        var isSendEmail = '';
        if (window.location.search.indexOf("sendEmailForTenatToGetMoreInfo") > -1) {
            isSendEmail = window.location.search.split("sendEmailForTenatToGetMoreInfo=")[1];

            localStorage.setItem("sendemailfortenattogetmoreinfo", isSendEmail);
        }
        else {
            localStorage.setItem("sendemailfortenattogetmoreinfo", isSendEmail)
        }
    }

    componentDidMount() {
        this.getJobDetailsByJobId();
    }
    componentWillMount() {
        if (localStorage.getItem('styleCode') === "JLL" || localStorage.getItem('styleCode') === "jll") {
            import('./Styles/JLL/style.scss');
        } else {
            import('./Styles/style.scss');
        }
    }
    getActiveMasterDataFileVersion = () => {
        userService.GetActiveMasterDataFileVersion(MasterDataType.JobTypes)
            .then(response => {
                this.readJsonFile(response);
            });
    }

    readJsonFile(fileName) {
        let filePath = `${AppConfiguration.Setting().azureStorage.BlobStorageCdnEndPoint}${AppConfiguration.Setting().azureStorage.JobTypesContainerName}/${fileName}`;
        fetch(filePath)
            .then(response => response.json())
            .then(result => {
                this.addJobTypesToTheState(result);
            })
    }

    getJobDetailsByJobId =  () => {
        const jobId = window.jobId;
        const trgLng = window.lng;
        const userId = localStorage.getItem("userId");
        this.props.setJobDetailsLoadingStatus(true);
        this.setState({ isJobDetailsLoading: true })
        if (jobId) {
            try {
                let details;
                jobService.GetJobDetailsForExternalUsers(jobId, userId).then((result) => {
                    details = result;
                    return this.applyTranslation([(details.description) ? details.description : " ",
                    (details.title) ? details.title : " ",
                    (details.address) ? details.address : " ",
                    (details.primaryJobTypeDisplay) ? details.primaryJobTypeDisplay : " ",
                    (details.statusDisplay) ? details.statusDisplay : " ",
                    (details.secondaryJobTypeDisplay) ? details.secondaryJobTypeDisplay : " ",
                    (details.propertyName) ? details.propertyName : " ",
                    (details.issueAreaInProperty != null) ? details.issueAreaInProperty : " ",
                    (details.tradees != null && details.tradees.length > 0 && details.tradees[0].firstName) ? details.tradees[0].firstName : " ",
                    (details.tradees != null && details.tradees.length > 0 && details.tradees[0].lastName) ? details.tradees[0].lastName : " ",
                    (details.tradees != null && details.tradees.length > 0 && details.tradees[0].businessName) ? details.tradees[0].businessName : " ",
                    (details.tradees != null && details.tradees.length > 0 && details.tradees[0].mobileNumber) ? details.tradees[0].mobileNumber : " ",
                    (details.tradees != null && details.tradees.length > 0 && details.tradees[0].email) ? details.tradees[0].email : " ",
                    (details.customKeyCollectionValue) ? details.customKeyCollectionValue : " ",
                    (details.issueTags != null && details.issueTags.length > 0) ? details.issueTags.join(',') : " ",

                    ], "en", trgLng);
                }).then(async trans => {
                    if (trans.length > 0) {
                        details.description = trans[0];
                        details.title = trans[1];
                        details.address = trans[2];
                        details.primaryJobTypeDisplay = trans[3];
                        details.statusDisplay = trans[4];
                        details.secondaryJobTypeDisplay = trans[5];
                        details.propertyName = trans[6];
                        details.issueAreaInProperty = trans[7];

                        if (details.tradees != null && details.tradees.length > 0 && details.tradees[0].firstName) {
                            details.tradees[0].firstName = trans[8];
                        };
                        if (details.tradees != null && details.tradees.length > 0 && details.tradees[0].lastName) {
                            details.tradees[0].lastName = trans[9];
                        };
                        if (details.tradees != null && details.tradees.length > 0 && details.tradees[0].businessName) {
                            details.tradees[0].businessName = trans[10];
                        };
                        if (details.tradees != null && details.tradees.length > 0 && details.tradees[0].mobileNumber) {
                            details.tradees[0].mobileNumber = trans[11];
                        };
                        if (details.tradees != null && details.tradees.length > 0 && details.tradees[0].email) {
                            details.tradees[0].email = trans[12];
                        };
                        details.customKeyCollectionValue = trans[13];
                        if (details.issueTags != null && details.issueTags.length > 0) {
                            let num = 14;
                            for (let i = 0; i < details.issueTags.length; i++) {
                                details.issueTags[i] = trans[num];
                                num++;
                            }
                        }
                    }

                    const jobNotes = await this.getJobNotesByJobId(jobId, userId);
                    details.jobNotes = jobNotes;

                    this.setState({
                        jobDetails: details,
                        isJobDetailsLoading: false
                    });
                    
                    this.props.updateIsTenantField(details.isCurrentTenant);
                    this.getUserDetails(userId ? userId : details.serviceConsumerId);

                    this.props.setJobDetails(details);
                    this.props.setJobDetailsLoadingStatus(false);
                });
            } catch (error) {
                console.log(error);
                this.props.setJobDetailsLoadingStatus(false);
            }
        }
    };

    getJobNotesByJobId = async (jobId, userId) => {
        if (!jobId || !userId)
            return;
        try {
            return await jobService.GetJobNotesByJobId(jobId, userId);
        } catch {
            throw new Error();
        }
    };

    applyTranslation(texts, sourceLng, targetLng) {
        const requestModel = {
            text: texts,
            targetCode: targetLng,
            sourceCode: sourceLng
        };
        return translateService.translate(requestModel);
    }

    getUserDetails = (userId) => {
        this.getOrgOmniThemeSettings(userId);
        try {
            userService.getExternalUserDetails(userId).then(res => {
                let details = {};
                details.firstName = res.firstName;
                details.lastName = res.lastName;
                details.email = res.email;
                details.location = res.location;
                details.mobileNumber = res.mobileNumber;
                details.homePhoneNumber = res.homePhoneNumber;
                details.officePhoneNumber = res.officePhoneNumber;
                details.countryId = res.countryId;
                details.profileImageUrl = this.props.profileThumbnailsCdnEndpoint + res.profileImageUrl;
                details.userId = res.userId;
                details.userType = res.userRole;
                details.userIdEncripted = res.encryptedUserId;
                details.dateFormat = res.dateFormat;
                details.dateTimeFormat = res.dateTimeFormat + ":ss";
                details.dateTimeFormatMin = res.dateTimeFormat;
                this.props.updateUserDetails(details);
                this.getJobTypesByCountryId();
                localStorage.setItem("DateFormat", details.dateFormat);
                localStorage.setItem("DateTimeFormat", details.dateTimeFormat);
                localStorage.setItem("dateTimeFormatMin", details.dateTimeFormatMin);
                localStorage.setItem("userId", details.userIdEncripted);
                localStorage.setItem("userType", details.userType);
                this.getActiveMasterDataFileVersion();

            });
        } catch (error) {
            console.log(error);
        }
    };


    getJobTypesByCountryId = () => {
        PouchDbService.GetAllDocument(MasterDataType[MasterDataType.JobTypes]).then(response => {
            if (response.rows.length > 0) {
                this.addJobTypesToTheState(response);
            }
        })
    }
    getOrgOmniThemeSettings = (userId) => {
        if (userId) {

            settingsService.getOmniOrgThemeSettings(userId).then(result => {
                if (result.organizationThemeSettingsModel.isCustomThemeEnable) {
                    let newTheme = {
                        logoUrl: result.organizationThemeSettingsModel.logoUrl,
                        brandColor: result.organizationThemeSettingsModel.brandColor,
                        textColor: result.organizationThemeSettingsModel.textColor,
                        primaryButtonBackground: result.organizationThemeSettingsModel.primaryButtonBackground,
                        primaryButtonTextColor: result.organizationThemeSettingsModel.primaryButtonTextColor
                    }

                    this.setState({
                        isCustomThemeEnable: result.organizationThemeSettingsModel.isCustomThemeEnable,
                        theme: newTheme
                    });
                } else {
                    this.setState({
                        isCustomThemeEnable: false,
                    });
                }

            }, error => {
                console.log(error);
            })
        }
    }


    addJobTypesToTheState = (jobTypesList) => {
        let trgLng = window.lng.charAt(0).toUpperCase() + window.lng.slice(1);
        if (trgLng === 'Zh-tw') {
            trgLng = 'ZhTw';
        }
        let services = [];
        let allJobTypes = [];
        let jobTypes = [];
        if (jobTypesList.length) {
            jobTypes = jobTypesList.filter(element => element.CountryId == this.props.userDetails.countryId);
        } else {
            jobTypes = jobTypesList.rows.filter(element => element.doc.CountryId == this.props.userDetails.countryId);
        }

        jobTypes.forEach(element => {
            let subTypes = [];
            if (jobTypesList.length) {
                subTypes = jobTypes.filter(p => p.ParentJobTypeId == element.Id);
                allJobTypes.push({
                    id: element.Id,
                    title: (trgLng === 'En') ? element.Name : element[`Name${trgLng}`],
                    label: (trgLng === 'En') ? element.Name : element[`Name${trgLng}`],
                    description: element.Description,
                    tooltip: element.Tooltip,
                    jobCategory: element.JobGroupId,
                    parentRef: element.ParentJobTypeId,
                    countryId: element.CountryId,
                    hasChildRef: subTypes.length > 0
                });
            } else {
                subTypes = jobTypes.filter(p => p.doc.ParentJobTypeId == element.doc.Id);
                allJobTypes.push({
                    id: element.doc.Id,
                    title: (trgLng === 'En') ? element.Name : element[`Name${trgLng}`],
                    label: (trgLng === 'En') ? element.Name : element[`Name${trgLng}`],
                    description: element.doc.Description,
                    tooltip: element.doc.Tooltip,
                    jobCategory: element.doc.JobGroupId,
                    parentRef: element.doc.ParentJobTypeId,
                    countryId: element.doc.CountryId,
                    hasChildRef: subTypes.length > 0
                });
            }
        });

        allJobTypes.forEach(element => {

            let subTypes = allJobTypes.filter(a => a.parentRef == element.id);

            if (element.hasChildRef) {
                services.push({
                    parentJobType: element,
                    childJobTypes: subTypes
                });
            }
        });
        this.props.addJobTypes(services);
    }
    reloadPage = () => {
        this.getJobDetailsByJobId();
    }

    render() {
        var userId = localStorage.getItem("userId");
        return (
            <>
                {/*(!this.state.isCustomThemeEnable) ?*/
                    <div className="App">
                        <ToastContainer autoClose={3000} hideProgressBar />
                        <Router history={history}>
                            <Route
                                path={"/external"}
                                render={routerProps => <ExternalUserLayout {...routerProps} theme={this.state.theme} isCustomThemeEnable={this.state.isCustomThemeEnable}  reloadPage={()=>this.reloadPage()} />}
                            />
                            <Route
                                path={`/download`}
                                render={routerProps => <ExternalDownloadPage {...routerProps} />}
                            />
                            <Route
                                exact
                                path="/"
                                render={() => <Redirect to={userId != null ? `/external/overview?code=${window.jobId}&userId=${userId}` : `/external/overview?code=${window.jobId}`} />}
                            />

                            <Route
                                exact
                                path="/"
                                render={() => <Redirect to={`/external/edit-job?code=${window.jobId}`} />}
                            />
                        </Router>
                    </div>
                    //:
                    //<div className="App">
                    //    <ToastContainer autoClose={3000} hideProgressBar />
                    //    <Router history={history}>
                    //        <Route
                    //            path={"/external"}
                    //            render={routerProps => <ExternalUserLayout {...routerProps} theme={this.state.theme} isCustomThemeEnable={this.state.isCustomThemeEnable} />}
                    //        />
                    //        <Route
                    //            path={`/download`}
                    //            render={routerProps => <ExternalDownloadPage {...routerProps} />}
                    //        />
                    //        <Route
                    //            exact
                    //            path="/"
                    //            render={() => <Redirect to={`/external/overview?code=${window.jobId}`} />}
                    //        />
                    //        <Route
                    //            exact
                    //            path="/"
                    //            render={() => <Redirect to={`/external/edit-job?code=${window.jobId}`} />}
                    //        />
                    //    </Router>
                    //</div>
                }
            </>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    try {
        let userDetails = state.User;

        return {
            userDetails: userDetails,
            profileThumbnailsCdnEndpoint: state.AppSettings.imageCdnEndpoint,
            jobTypes: state.Jobs.jobTypes,
            theme: state.theme,
            isCustomThemeEnable: state.isCustomThemeEnable
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateUserDetails: details => { dispatch({ type: "SET_USER_DETAILS", userDetails: details }) },
        updateIsTenantField: isCurrentTenant => { dispatch({ type: "CHECK_CURRENT_TENANT", isCurrentTenant: isCurrentTenant }) },
        setJobDetails: jobDetails => { dispatch({ type: 'SET_JOB_DETAILS', jobDetails: jobDetails }) },
        setJobDetailsLoadingStatus: status => { dispatch({ type: 'SET_JOB_DETAILS_LOADING_STATUS', status: status }) },
        addJobTypes: jobTypes => { dispatch({ type: 'SET_JOB_TYPES', jobTypes: jobTypes }) }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalUserApp);
import React from 'react';
import { connect } from "react-redux";
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import GoogleMapLocationsAutoComplete from '../GoogleMapLocationsAutoComplete/GoogleMapLocationsAutoComplete';
import settingsService from '../../../Services/settingsService';
import jobService from '../../../Services/JobService';
import { Guid } from 'guid-typescript';
import DatePicker from "react-datepicker";
import { Search, Label, Dropdown } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import * as _ from 'lodash';
import 'semantic-ui-css/semantic.min.css'
import './JobPostWizard.scss';
import propertyService from '../../../Services/PropertyService';
import FrequentlyUsedServices from '../../Drawers/JobPostDrawer/FrequentlyUsedServices/FrequentlyUsedServices';
import AttachmentsDrawer from '../../Drawers/AttachmentsDrawer/AttachmentsDrawer';
import Disclaimer from '../../Drawers/JobPostDrawer/Disclaimer/Disclaimer';
import { AppConfiguration } from 'read-appsettings-json';
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import JobIssueTag from '../JobIssueTag/JobIssueTag';
import PropertyComponent from '../PropertyComponent/PropertyComponent';
import { withTranslation } from 'react-i18next';
import translateService from '../../../Services/TranslateService';
import TroubleshootGuidModal from "../../Drawers/TroubleshootGuidModal/TroubleshootGuidModal";
class JobPostWizard extends React.Component {

    
    constructor(props) {
        super(props);
        this.title = React.createRef();
        this.jobDescription = React.createRef();
        this.budgetRef = React.createRef();
        this.issueAreaInProperty = React.createRef();
        this.isUrgentIssue = React.createRef();

    }

    notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        if (this.props.isFromBot) {
            this.props.showChatbotError(msg);
        }
    }

    state = {
        userType: localStorage.getItem("userType"),
        userId: localStorage.getItem("userId"),
        stepFlow: [
            [0, "selectProperty"],
            [1, "getMaintenaceIssue"],            
            [2, "getAttachments"],
            [3, "jobPostSummary"],
            [4, "selectSubmitOption"],
            [5, "setResponse"],
            [6, "GotoHomePage"],
            [7, "GetResolvedResponseByTroubleshootGuide"],
        ],
        selectedServiceTypeName: "",

        isJobEdit: (this.props.isJobEdit) ? true : false,
        jobEditId: (this.props.jobEditId) ? this.props.jobEditId : "",
        currentStep: 0,
        isConsumerJobPost: false,
        propertySearchData: [],
        formData: {
            address: '',
            location: '',
            propertyId: '',
            propertyName: '',
            suburbId: '',
            propertyType: '',
            jobBudget: 0,
            jobDescription: '',
            jobDescriptionEng: '',
            issueAreaInProperty: '',
            issueAreaInPropertyEng: '',
        },
        availableServices: [],
        availableServicesDetails: [],
        selectedService: { childJobTypes: [] },
        selectedServiceType: {
            parentJobType: { id: '' }
        },
        selectedSubServiceType: {},
        jobDetails: { attachments: [] },
        attachments: [],
        jobId: '',
        dueDate: '',
        formattedDueDate: '',
        jobUrgencyList: [
            {
                value: "Emergency",
                jobUrgency: -1
            },
            {
                value: "Urgent",
                jobUrgency: 1
            },
            {
                value: "Non-Urgent",
                jobUrgency: 2
            }
        ],
        jobUrgency: 2,
        propertyList: [],
        value: '',
        isLoading: false,
        filteredResults: [],
        propertyId: '',
        isPropertiesLoading: false,
        parentJobTypes: [],
        primaryJobTypes: [],
        secondaryJobTypes: [],
        frequentlyUsedServices: [],
        isShowMoreServices: false,
        locationDetails: {
            latitude: '',
            longitude: '',
            placeId: '',
            suburbId: '',
            location: ''
        },
        isMandatoryToUploadFiles: false,
        isJobForMe: false,
        jobBudget: "",
        jobForMe: {},
        selectedTradees: [],
        isSelectedOnDemand: false,
        selectedQuoteLimit: 0,
        inviteToProviders: false,
        isJobPosting: false,
        isRestrictKeyCollectionForResidentialProperties: false,
        showDisclaimerDrawer: false,
        jobPostWorkflowStatus: 1,
        propertyLocks: [],
        isJobForMeText: '',
        isSubServiceTypeSelected: false,
        isShowAttchmentDrawer: false,
        parentId: '',
        childJobTypeId: '',
        subChildJobTypeId: '',
        jobNumber: '',
        isPropertyOwner: (localStorage.userType == 5) ? true : false,
        isShowInviteTradeDrawer: false,
        budgetAmount: 0,
        isPropertySelected: false,
        title: '',
        isLoadingTroubleshootingGuide: false,
        troubleShootingGuideDetails: {
            troubleshootGuideId: '',
            troubleshootGuide: '',
            primaryJobTypeId: '',
            secondaryJobTypeId: ''
        },
        primaryButtonStyle: (this.props.customStyle && this.props.customStyle.primaryButtonStyle) ? this.props.customStyle.primaryButtonStyle : {
            color: ''
        },
        secondaryButtonStyle: (this.props.customStyle && this.props.customStyle.secondaryButtonStyle) ? this.props.customStyle.secondaryButtonStyle : {
            color: ''
        },
        optionsStyle: (this.props.customStyle && this.props.customStyle.optionsStyle) ? this.props.customStyle.optionsStyle : {
            color: ''
        },
        isFromBot: (this.props.isFromBot) ? true : false,
        isMaintatanceIssueProvided: false,
        isJobPostOrTroubleshootGuideSelected: false,
        isDraftJob: false,
        tradeeCount: 0,
        jobTypeTags: [],
        isLoadingJobTypeTags: false,
        jobAssignTags: [],
        selectedComponent: '',
        selectedGoogleImage: [],
        selectedFeatureComponent: [],
        previousStep: 0,
        chatbotSetting: this.props.chatbotSetting ? this.props.chatbotSetting : {
            hideQandABot: false,
            hideCheckRentalStatus: false,
            hideKeyCollectionOptions: false,
            hideTroubleshootGuide: false,
            enableDraftJobFeature: false
        },
        maintenanceTroubleshootGuideId: null,
        propertyType: "Residential",
        actionValue: 0,
        primaryJobTypeId: '',
        secondaryJobTypeId: '',
        primaryJobTypeName: '',
        secondaryJobTypeName: '',
        isShowTroubleshootGuid: false,
        
    }

    componentDidMount() {
        setTimeout(() => {
            this.GetTenantPropertiesByUserId();
        }, 1000);

        this.setState({
            jobId: Guid.create().toString(),
            jobDetails: {
                id: this.state.jobId
            }
        });

    }

    componentDidUpdate() {

    }

    getOrganizationSettings(organizationId) {
        settingsService.getOrganizationSettingsByOrgId(organizationId)
            .then((res) => {
                this.setState({
                    isMandatoryToUploadFiles: res.organizationSettingsModel.allowTenantsToImageAttachmentFeatureForTheJobposting,
                    isRestrictKeyCollectionForResidentialProperties: res.organizationSettingsModel.isRestrictKeyCollectionForResidentialProperties,
                    allowTenantsToSeeMatchingTrades: res.organizationSettingsModel.allowTenantsToSeeMatchingTrades,
                    isEnableSuggestingImage: res.organizationSettingsModel.isEnableSuggestingImage,
                    isEnableIssueTag: res.organizationSettingsModel.isEnableIssueTag,
                })

            })

    }

    GetTenantPropertiesByUserId = () => {
        let none = [{ propertyId: 0, propertyName: '--None--', suburbId: '', location: '', address: '', propertyType: 'Residential' }];

        this.setState({ isPropertiesLoading: true });
        let properties;
        let propertyNames = [];
        const { i18n } = this.props;

        propertyService.GetTenantPropertiesByUserId()
            .then((res) => {
                properties = res.properties;
                if (res.properties.length > 0) {

                    properties.forEach(x => {
                        propertyNames.push(x.address ? x.address : " ");
                    });
                    
                        this.setState({
                            propertyList: properties,
                            propertyId: properties[0].propertyId,
                            isCurrentTenant: properties[0].isCurrentTenant
                        });
                        if (localStorage.userType != 5) {
                            this.getPropertyDetailsByPropertyId(properties[0].propertyId);
                        }
                        this.setState({ isPropertiesLoading: false });
                        this.handlePropertyChange(properties[0].propertyId);
                } else {
                    this.setState({
                        propertyList: [],
                        propertyId: 0
                    });
                    this.onChangeJobType(1);
                }
            });
    }


    getPropertyDetailsByPropertyId = (propertyId) => {
        const selectedProperty = this.state.propertyList.filter(a => a.propertyId == propertyId);
        if (selectedProperty && selectedProperty.length > 0 && selectedProperty[0].propertyId != null) {
            this.setState({
                propertyId: propertyId,
                //locationDetails: {},
                formData: {
                    address: selectedProperty[0].address,
                    location: selectedProperty[0].location,
                    propertyId: selectedProperty[0].propertyId,
                    propertyName: selectedProperty[0].propertyName,
                    suburbId: selectedProperty[0].suburbId,
                    propertyType: selectedProperty[0].propertyType
                },
                locationDetails: selectedProperty[0],
                jobForMe: {
                    propertyType: selectedProperty[0].propertyType,
                    address: selectedProperty[0].address,
                    latitude: selectedProperty[0].latitude,
                    location: selectedProperty[0].location,
                    longitude: selectedProperty[0].longitude,
                    placeId: selectedProperty[0].placeId,
                    suburbId: selectedProperty[0].suburbId,
                },
            });
            this.getOrganizationSettings(selectedProperty[0].orgnizationId);
        } else {
            this.setState({
                propertyId: null,
                locationDetails: {},
                formData: {
                    address: '',
                    location: '',
                    propertyId: null,
                    propertyName: '',
                    suburbId: null,
                    propertyType: ''
                }
            });
        }
        setTimeout(() => {
            if (this.state.isJobForMe == 1) {
                // document.getElementById('check1').checked = true;
                this.setState({ keyCollectType: 1 });
            }
        }, 500);
    }

    onChangeJobType = (value) => {
        if (value === 1 && localStorage.userType == 4) {
            this.setState({ isJobForMeText: <div>You (tenant) will be paying for this job and are responsible for all costs involved.<br></br>This job is not being sent to your property manager.</div> });
        } else {
            this.setState({ isJobForMeText: '' });
        }

        this.setState({
            isJobForMe: (value === 1) ? true : false,
            jobForMe: {
                propertyType: '',
                address: '',
                latitude: '',
                location: '',
                longitude: '',
                placeId: '',
                suburbId: '',
                budget: ''
            },
            formData: {
                propertyId: ""
            }
        });
    }

    onChangePropertyTypeForMe = (event) => {
        let formdata = {};
        if (!event) {
            formdata.propertyType = "Residential";
            this.setState({ formdata: formdata, propertyType: "Residential" });
        }
        let jobforMe = this.state.jobForMe;
        jobforMe.propertyType = event;
        this.setState({ jobForMe: jobforMe, propertyType: event });

        formdata = this.state.formData;
        formdata.propertyType = event;
        this.setState({ formdata: formdata, propertyType: event });
    }

    onKeyCollectionChange = (event) => {
        this.setState({
            keyCollectType: event
        });
    }

    GetKeyCollectionTypeDescription = () => {
        switch (this.state.keyCollectType) {
            case '1': return "Collect keys from office to enter the property"; break;
            case '2': return "Collect from office, inform tenant on date & time"; break;
            case '3': return "Contact tenant to arrange access & time"; break;
            default: return "Key collection Option not mentioned";
        }
    }

    handlePropertyChange = (props) => {
        this.setState({ property: props });
        this.getPropertyDetailsByPropertyId(props);
    }
    setLocationDetails = (event) => {
        this.setState({
            locationDetails: event,
            jobForMe: {
                propertyType: '',
                address: event.address,
                latitude: event.latitude,
                location: event.location,
                longitude: event.longitude,
                placeId: event.placeId,
                suburbId: event.suburbId,
            },
            formData: {
                address: event.address
            }
        });
    }


    handleOnChangeJobDescription = (value) => {

        let formdata = this.state.formData;
        const typedDescription = this.jobDescription.current.value;
        formdata.jobDescription = typedDescription;
        formdata.jobDescriptionEng = typedDescription;
        this.setState({
            formdata: formdata,
            isMaintatanceIssueProvided: true
        });

    }

    handleDueDateChange = (date) => {
        this.setState({
            dueDate: date,
        });
    }

    handleJobUrgencyOnChange = (event, props) => {
        this.setState({ jobUrgency: props.value });
    }

    GetJobUrgency = () => {
        return this.state.jobUrgency == -1 ? "Emergency" : this.state.jobUrgency == 1 ? "Urgent" : "not-Urgent";
    }

    closeDisclaimer = () => {
        this.setState({
            showDisclaimerDrawer: false,
        });
    }

    getTroubleShoutingGuide(index) {
        const { i18n } = this.props;
        this.setState({
            isLoadingTroubleshootingGuide: true
        });
        jobService.GetTroubleShootingGuide(this.state.formData.propertyId, this.state.formData.jobDescriptionEng).then(res => {
            if (res.responseCode == 12 && res.troubleshootGuide != null) {                
                    this.setState({
                        troubleShootingGuideDetails: res,
                        maintenanceTroubleshootGuideId: res.troubleshootGuideId,
                        isLoadingTroubleshootingGuide: false,
                        isShowTroubleshootGuid: true   
                     
                    });
                
                
            } else {
                this.setState({
                    troubleShootingGuideDetails: res,
                    isLoadingTroubleshootingGuide: false,
                    currentStep: (index + 1),
                    isShowTroubleshootGuid: false   
                });
                
            }
            this.SetSelectedServiceType();
        })
    }

    render() {
        const currentStepIndex = this.state.currentStep;
        let step = this.showCurrentStep(currentStepIndex);
        
        return (
            <Form>
                <div className="jobpost-wizard">
                    {step}
                </div>
                {(this.state.showDisclaimerDrawer == true) ?
                    <Disclaimer
                        showDrawer={this.state.showDisclaimerDrawer}
                        disclaimer={this.state.disclamimerText}
                        jobPostWorkflowStatus={this.state.jobPostWorkflowStatus}
                        onAgreedForDisclaimer={(event) => this.postJob(event)}
                        onCloseDisclaimer={() => this.closeDisclaimer()}
                        isFromBot={this.state.isFromBot}
                    ></Disclaimer> : ''
                }
                {(this.state.isShowTroubleshootGuid == true) ?
                                <div>                                     
                                <TroubleshootGuidModal
                                   drawerOpen={true}
                                   continueJobPosting={this.continueJobPosting}
                                   saveTroubleShootResult={this.saveTroubleShootResult}              
                                   index={currentStepIndex}              
                                   troubleShootingGuideDetails={this.state.troubleShootingGuideDetails}
                                   formData={this.state.formData}
                                   closeModal = {this.closeTroubleshootGuid}
                               ></TroubleshootGuidModal> 
                               </div>:''
                }
                
            </Form>);
    }

    closeTroubleshootGuid= (index) =>{
        this.setState({
            isShowTroubleshootGuid: false   
        });
    }

    showCurrentStep = (index) => {
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "selectProperty":
                return this.selectPropertyStep(index);
            case "getMaintenaceIssue":
                return this.getMaintenaceIssue(index);
            case "selectKeyCollectionType":
                if (this.state.chatbotSetting.hideKeyCollectionOptions && this.state.isFromBot) {
                    this.setState({
                        keyCollectType: this.state.chatbotSetting.defaultKeyCollectionOption,
                        currentStep: index + 1
                    }, () => {
                        return this.nextStep(index);
                    })
                }
                else {
                    return this.selectKeyCollectionType(index);
                }
            case "getAttachments":
                return this.getAttachments(index);

            case "selectSubmitOption":
                return this.selectSubmitOption(index);
            case "setResponse":
                return this.setResponse(index);
            case "setTroubleShooteResponse":
                return this.setTroubleShooteResponse();
            case "jobPostSummary":
                return this.jobPostSummary(index);
            case "GotoHomePage":
                return this.GoToHomePage(index);
            case "GetResolvedResponseByTroubleshootGuide":
                return this.GetResolvedResponseByTroubleshootGuide(index);
            default:
                return this.nextStep(index);
        }
    }

    selectPropertyStep = (index) => {
        const { propertyList, propertyId, isJobForMe, jobForMe, formData, isPropertyOwner } = this.state;
        const forMeText = this.state.isJobForMeText;
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className='wizard-body'>
                    <div className={(localStorage.userType == 4 || localStorage.userType == 5) ? "d-block" : 'd-none'}>
                        <div className="wizard-header">
                            <h2>Job posting for</h2>
                        </div>
                        <ButtonToolbar className="radio-btn-group w-100 d-inline-block for-tgl-btn">
                            <ToggleButtonGroup className=" d-flex flex-column flex-md-row" type="radio" name="options" ref="jobType" defaultValue={isJobForMe ? 1 : 0} value={isJobForMe ? 1 : 0}
                                onChange={(value) => { this.onChangeJobType(value) }}>
                                {this.state.isCurrentTenant && localStorage.userType == 4 ?
                                    <ToggleButton className="px-2 mb-3 col-12 col-md-6" value={0}><div className='d-flex align-items-center btn-inner posting-for'><span className="ba-icn ba-icn-user"></span> Property Manager</div></ToggleButton> : ''}
                                {/* {<ToggleButton  className="px-2 mb-3 col-12 col-md-6" value={1}><div className='d-flex align-items-center btn-inner posting-for'><span className="ba-icn ba-icn-user-external"></span> Job I will Pay For</div></ToggleButton> } */}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                        <div className="my-2">
                            <div className="note-danger">{forMeText}</div>
                        </div>
                    </div>

                    {(!isJobForMe && propertyList && propertyList.length > 0) ?
                        <div>
                            <div className="wizard-header">
                                <h2 htmlFor="property">{this.state.isFromBot ? t('pj_lbl_prop_selection_q') : 'Please Select One of Your Properties'}</h2>
                            </div>

                            <div>
                                <ButtonToolbar className="radio-btn-group w-100 d-inline-block">
                                    <ToggleButtonGroup className={this.props.isFromBot ? "btn-group-bot" : " d-flex flex-column flex-md-row"} type="radio" name="options" ref="jobType" defaultValue={(formData && formData.propertyId != "") ? formData.propertyId : propertyList[0].propertyId}
                                        onChange={(value) => { this.handlePropertyChange(value) }}>
                                        {propertyList.map((a, index) => {
                                            return <ToggleButton className="px-2 mb-3 col-12" key={index} value={a.propertyId}><div className='d-flex align-items-center btn-inner'><span className="ba-icn icon-ico-location"></span> {a.address}</div></ToggleButton>
                                        })
                                        }
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </div>

                        </div> : ''}
                    <div className="form-group" hidden={(!isJobForMe && !isPropertyOwner) ? "hidden" : ""}>
                        <label className="form-label">What is your Address<span className="required-icon">*</span></label>
                        {(propertyId == 0 || isJobForMe || isPropertyOwner) ?
                            <GoogleMapLocationsAutoComplete setLocationDetails={(event) => this.setLocationDetails(event)} formData={this.state.formData}></GoogleMapLocationsAutoComplete> :
                            <input type="text" className="form-control" id="formGroupAddress" placeholder="Address" readOnly value={this.state.formData.address} ></input>}
                    </div>

                    <div className="form-group" hidden={(!isJobForMe && !isPropertyOwner) ? "hidden" : ""}>
                        <label className="form-label">What is your Location<span className="required-icon">*</span></label>
                        {propertyId == 0 || isJobForMe || isPropertyOwner ? <input type="text" className="form-control" id="formGroupLocation" placeholder="Location" readOnly value={jobForMe.location}></input>
                            : <input type="text" className="form-control" id="formGroupLocation" placeholder="Location" readOnly={true} value={this.state.formData.location}></input>}

                    </div>
                    {(isJobForMe || isPropertyOwner) ?
                        <div>
                            <div className="wizard-header">
                                <h2>Property Type</h2>
                                <p className="userTip">(Choose an option)<span className="required-icon">*</span></p>
                            </div>
                            <ButtonToolbar className="radio-btn-group">
                                <ToggleButtonGroup className={this.props.isFromBot ? "btn-group-bot" : ""} type="radio" name="options" ref="jobPropertyTypes" defaultValue="Residential"
                                    onChange={(value) => this.onChangePropertyTypeForMe(value)}>
                                    <ToggleButton value="Residential"><span className="ba-icn ba-icn-ok"></span> Residential</ToggleButton>
                                    <ToggleButton value="Commercial"><span className="ba-icn ba-icn-ok"></span> Commercial</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div> : ''}
                </div>
                <div className="wizard-actions d-flex justify-content-end">
                    <button type="button" className="btn next-btn px-4 mb-0 mx-0 mx-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" className='ml-2'/></button>
                </div>

            </div>);
    }
    getMaintenaceIssue = (index) => {
        const { jobUrgency, jobUrgencyList } = this.state;
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className={(this.state.isFromBot) ? 'job-post-type' : 'wizard-body job-post-type'}>
                    <div className="wizard-header">
                        <h2>What is the issue ?</h2>
                        <p>Describe your issue with more details</p>
                    </div>

                    <div className="form-group">
                        <lable className="form-label">Job Description</lable>
                        <textarea className='w-100 form-control form-textarea' type="text" placeholder='e.g.Sink in the bathroom is blocked'
                            value={this.state.formData.jobDescription} ref={this.jobDescription} onChange={(value) => this.handleOnChangeJobDescription(value)}></textarea>

                    </div>
                    <div className="form-group">
                        <label className="form-label">Priority</label>
                        <Dropdown className='form-control'
                                selectOnNavigation={false}
                                className='test test dropdown-priority'
                                selection
                                loading={false}
                                value={jobUrgency}
                                name='jobUrgency'
                                options={jobUrgencyList.map((a, index) => { return { key: index, text: a.value, value: a.jobUrgency }; })}
                                placeholder='Choose job Priority'
                                onChange={this.handleJobUrgencyOnChange}
                            />
                    </div>
                    {(this.jobUrgency == 1) ?
                        <div className="d-flex align-items-center mb-4">
                            <span className="ba-icn ba-icn-warn mr-2 text-orange font-14"></span>
                            <lable className="text-orange font-12">
                                This considered as an urgent, your prpoperty manager will verify this before they create the job.
                            </lable>
                        </div>
                        : ''}
                </div>

                <div className='d-flex flex-column flex-md-row justify-content-between mt-5'>
                    <div className='col-12 col-md-6 px-0 pr-md-3 mb-4 mb-md-0'>
                            <label>Step 1 of 3</label>
                            <div className='col-12 px-0'>
                                <div class="progress">
                                    <div class="progress-bar bg-success rounded w-33" role="progressbar"></div>
                                </div>
                            </div>
                    </div>
                    <div className='col-12 col-md-6 px-0 pl-md-3'>
                        <div className="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-0">
                            <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>BACK</button>
                            <button type="button" className="btn next-btn px-4 mb-0 mx-0 mx-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" className='ml-2'/></button>
                        </div>
                    </div>
                </div>
            </div>
            );
    }


    selectKeyCollectionType = (index) => {
        const { isJobForMe, isPropertyOwner } = this.state;
        const { t } = this.props;
        return (
            <>
                <div className="wizard-inner">
                    <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                        {(isJobForMe) ? '' :
                            <div>
                                <div className="wizard-header">
                                    <h3 className='font-regular font-15'>Key collection option for trade<span className="required-icon"></span></h3>
                                </div>
                                <div className="form-group mt-4">

                                    {(this.state.isRestrictKeyCollectionForResidentialProperties && this.state.formData.propertyType == 'Residential') ?
                                        <ButtonToolbar className="radio-btn-group key-collect-options">
                                            <ToggleButtonGroup className="" type="radio" name="propertyKey" id="check3" onChange={(value) => this.onKeyCollectionChange(value)} defaultValue={3}>
                                                <ToggleButton value="3"><span className="text-wrap d-flex align-items-center"><i className="ba-icn ba-icn-key pr-3"></i> Don\'t take the key, I want to be home so let’s arrange a time</span></ToggleButton>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>
                                        :
                                        <div>
                                            <ButtonToolbar className="radio-btn-group key-collect-options">
                                                <ToggleButtonGroup className={this.state.isFromBot ? "btn-group-bot" : ""} type="radio" name="propertyKey" id="check3" onChange={(value) => this.onKeyCollectionChange(value)} defaultValue={this.state.keyCollectType}>
                                                    <ToggleButton id="check1" key={1} value="1"><span className="text-wrap d-flex align-items-center"><i className="ba-icn ba-icn-briefcase mr-3"></i> Collect keys from office to enter the property</span></ToggleButton>
                                                    <ToggleButton id="check2" key={2} value="2"><span className="text-wrap d-flex align-items-center"><i className="ba-icn ba-icn-briefcase mr-3"></i> Collect from office, inform tenant on date & time</span></ToggleButton>
                                                    <ToggleButton id="check3" key={3} value="3"><span className="text-wrap d-flex align-items-center"><i className="ba-icn ba-icn-key mr-3"></i> Contact tenant to arrange access & time</span></ToggleButton>
                                                </ToggleButtonGroup>
                                            </ButtonToolbar>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>);
    }

    getAttachments = (index) => {
        const { isShowAttchmentDrawer, isJobForMe } = this.state;
        const { t } = this.props;
        const styleflex = {
            flexDirection: "column !important",
        }
        return (
            <>
                <div>
                    <div className="wizard-inner">
                        <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                            <div className="wizard-header">
                                <h2>Upload Photos & Videos</h2>
                                <p>Please attach photos & videos that describe your issue</p>
                            </div>
                            <div className="form-group">
                                <div className="img-upld-cont">
                                    <AttachmentsDrawer
                                        jobDetails={{ id: this.state.jobId, attachments: this.state.attachments }}
                                        onSubmitAttachments={(event) => { this.setState({ attachments: event }) }}
                                        isJobPost={true}
                                        isFromBot={this.state.isFromBot}
                                        t={t}>
                                    </AttachmentsDrawer>
                                    {(this.state.attachments) ?
                                        <ImageViewer attachments={this.state.attachments} isFromBot={this.state.isFromBot} isFromTenantManageJobPost = {localStorage.userType == 4 ? true : false} t={t}></ImageViewer> : ''
                                    }
                                </div>
                            </div>
                            <div>
                                {this.selectKeyCollectionType(index)}
                            </div>
                            <div className='d-flex flex-column flex-md-row justify-content-between mt-4'>
                                <div className='col-12 col-md-6 px-0 pr-md-3 mb-4 mb-md-0'>
                                        <label>step 2 of 3</label>
                                        <div className='col-12 px-0'>
                                            <div class="progress">
                                                <div class="progress-bar bg-success rounded w-66" role="progressbar"></div>
                                            </div>
                                        </div>
                                </div>
                                <div className='col-12 col-md-6 px-0 pl-md-3'>
                                    <div className="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-0">
                                        <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>BACK</button>
                                        <button type="button" className="btn next-btn px-4 mb-0 mx-0 mx-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" className='ml-2'/></button>                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>);
    }

    selectSubmitOption = (index) => {
        const { t } = this.props;
        return (
            <div className="wizard-inner">
                <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                    <div className="wizard-header text-center mb-5">
                        <h2>{this.state.isFromBot ? t('pj_lbl_select_submit_options') : 'Please select submit option.'}</h2>
                        {/* <p className="usertip">({this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'})</p> */}
                    </div>
                    <ButtonToolbar className="radio-btn-group key-collect-options submit-options justify-content-center">
                        <ToggleButtonGroup type="radio" name="options" className={this.props.isFromBot ? "btn-group-bot" : ""}
                            onChange={(value) => { this.onSubmitJob(value) }} value={this.state.actionValue}>
                            <ToggleButton value={2}>{this.state.isFromBot ? t('pj_button_job_post') : 'Job Post'}</ToggleButton>
                            {this.state.isFromBot ? !this.state.chatbotSetting.enableDraftJobFeature &&
                                <ToggleButton value={1}>{t('pj_button_job_draft')}</ToggleButton>
                                :
                                <ToggleButton value={1}>{this.state.isFromBot ? t('pj_button_job_draft') : 'Job Draft'}</ToggleButton>
                            }
                        </ToggleButtonGroup>
                    </ButtonToolbar>
                    <div className="wizard-actions mt-3">
                        <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                    </div>
                </div>
            </div>)
    }

    setResponse = (index) => {
        const { t } = this.props;
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <div className="job-posted-icon text-center">
                            <span className="ba-icn ba-icn-ok"></span>
                        </div>
                        <div className="job-posted-msg">
                            {(this.state.isDraftJob) ?
                                <h3 className="text-center mb-4"> {t('pj_lbl_job_draft_response')} </h3>
                                : <div  className='text-center mb-4'>
                                    <h3 className="text-center mb-4">{t('lbl_jobpost_success')}</h3>
                                    <p className='mb-4 font-green font-italics font-14'>Reference Number : {this.state.jobNumber}</p>
                                </div>
                            }
                        </div>
                        <div className="wizard-actions">
                            {(this.state.isFromBot) ? <button onClick={() => this.props.resetChat()} className="btn light-blue-button" style={this.state.primaryButtonStyle} ><span>{t('btn_done')}</span></button> : <Link to={`./home`} className="btn light-blue-button"><span>Back to home</span></Link>}
                        </div>
                    </div>
                </div>
            </>);
    }

    nextStep = (index) => {
        const { i18n } = this.props;
        var currentStep = index;
        if (this.validate(index)) {
            if (index == 1) {
                if (this.state.isJobForMe) {
                    currentStep = (index + 2);
                }
                else {

                    let formdata = this.state.formData;
                    const typedDescription = this.jobDescription.current.value;
                    if (typedDescription.length > 0) { 
                            this.getTroubleShoutingGuide(index);                        
                    }                    
                }
            } else if (this.state.isJobForMe && index == 4) {
                currentStep = (index + 5);
            }

            else if (this.state.isJobForMe && index == 9) {
                currentStep = (index - 4);
            }
            else if (this.state.isJobForMe && index == 5) {
                currentStep = (index + 5);
            }
            else if (!this.state.isJobForMe && !this.state.allowTenantsToSeeMatchingTrades && index == 8) { // 7
                currentStep = (index + 3);
            }
            else if (!this.state.isJobForMe && this.state.allowTenantsToSeeMatchingTrades && index == 8) {
                currentStep = (index + 2);
            }
            else if (!this.state.isJobForMe && !this.state.allowTenantsToSeeMatchingTrades && index == 10) { //7
                currentStep = (index + 2);
            }
            else {
                currentStep = (index + 1);
            }


        } else {
            return false;
        }      
        currentStep = this.permissionCheckNextStep(currentStep);
        this.setState({ currentStep: currentStep, previousStep: index });
    }

    previousStep = (index) => {
        var currentStep = index;
        if (this.state.isJobForMe && index == 9) { // 7
            currentStep = (index - 6);
        }
        else if (this.state.isJobForMe && index == 8) { //6
            currentStep = (index - 5);
        }
        else if (this.state.isJobForMe && index == 10) { //6
            currentStep = (index - 6);
        }
        else if (this.state.isJobForMe && index == 5) { //6
            currentStep = (index + 5);
        }
        else if (!this.state.isJobForMe && index == 9) { //7
            currentStep = (index - 2);
        }
        else if (!this.state.isJobForMe && this.state.allowTenantsToSeeMatchingTrades && index == 11) {
            currentStep = (index - 1);
        }
        else if (!this.state.isJobForMe && index == 11) { //7
            currentStep = (index - 3);
        }
        else if (!this.state.isJobForMe && this.state.allowTenantsToSeeMatchingTrades && index == 10) {
            currentStep = (index - 2);
        }
        else {
            currentStep = (index - 1);
        }
        
        currentStep = this.permissionCheckBackStep(currentStep);
        this.setState({ currentStep: currentStep, previousStep: index });
    }

    permissionCheckNextStep = (nextIndex) => {
        if (!this.state.isJobForMe && nextIndex == 6 && !this.state.isEnableSuggestingImage) {
            nextIndex = (nextIndex + 1);
        }
        if (!this.state.isJobForMe && nextIndex == 7 && !this.state.isEnableIssueTag) {
            nextIndex = (nextIndex + 1);
        }
        return nextIndex;
    }

    permissionCheckBackStep = (nextIndex) => {
        if (!this.state.isJobForMe && nextIndex == 6 && !this.state.isEnableSuggestingImage) {
            nextIndex = (nextIndex - 1);
        }
        if (!this.state.isJobForMe && nextIndex == 7 && !this.state.isEnableIssueTag) {
            nextIndex = (nextIndex - 1);
        }
        return nextIndex;
    }

    showAttachmentDrawer = () => {
        this.setState({ isShowAttchmentDrawer: true });
    }

    checkIfFilesMandatory = (index) => {
        const { t } = this.props;
        if (!this.state.isJobForMe) {
            if (this.state.isMandatoryToUploadFiles) {
                this.notifyError(this.state.isFromBot ? t('pj_error_attachments_empty') : 'Attachments are required.');
                return false;
            } else {
                this.nextStep(index);
            }
        } {
            this.nextStep(index);
        }
    }

    onSubmitJob = (workflow) => {
        const { t } = this.props;
        if (this.state.isJobForMe || workflow == 1) {
            if (workflow == 1) {
                this.setState({ isDraftJob: true });
            }
            this.postJob(workflow);
        } else {
            if (!this.state.isJobForMe && this.state.isMandatoryToUploadFiles && (this.state.attachments == [] || this.state.attachments.length == 0)) {
                this.notifyError(this.state.isFromBot ? t('pj_error_attachments_empty') : 'Attachments are required.');
                return false;
            } else {
                this.loadDisclaimer(workflow);
            }
        }
        
    }

    loadDisclaimer = (workflow) => {
        this.setState({
            isLoading: true
        });
        propertyService.GetDisclaimerByPropertyId(this.state.formData.propertyId).then(res => {
            this.setState({
                isLoading: false
            });
            if (res !== null && res !== '') {
                this.setState({
                    showDisclaimerDrawer: true,
                    disclamimerText: res,
                    jobPostWorkflowStatus: workflow,
                    actionValue: 0
                });
            } else {
                this.postJob(workflow);
            }
        });
    }

    validate = (index) => {
        const { tradeeCount, jobBudget, budgetAmount, suburbId, isSelectedOnDemand,
            inviteToProviders, isJobForMe, selectedTradees, selectedService,
            selectedServiceType, formData } = this.state
        let currentStep = this.state.stepFlow[index][1];
        const { t } = this.props;

        switch (currentStep) {
            case "selectProperty":
                if (isJobForMe && (this.state.jobForMe.suburbId == '' || this.state.jobForMe.suburbId == null)) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_invalid_address') : 'Please enter a valid address.');
                    return false;
                }
                if (this.state.jobForMe.address == "" && isJobForMe) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_no_address') : 'Please enter the address.');
                    return false;
                } else if (!isJobForMe && !this.state.property) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_no_property_selected') : 'Please select a property.');
                    return false;
                }
                else {
                    return true;
                }
            case "getMaintenaceIssue":
                if ((this.state.formData.jobDescription == '' || this.state.formData.jobDescription == undefined) && (!this.jobDescription.current.value || this.jobDescription.current.value == '')) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_no_jd') : 'Please enter job description.');
                    return false;
                } else {
                    return true;
                }

            case "getAttachments":
                if (!this.state.isJobForMe) {
                    if (this.state.isMandatoryToUploadFiles && this.state.attachments.length == 0) {
                        this.notifyError(this.state.isFromBot ? t('pj_error_attachments_empty') : 'Attachments are required.');
                        return false;
                    } 
                    else  if (this.state.keyCollectType == undefined || this.state.keyCollectType == '') {
                        this.notifyError(this.state.isFromBot ? t('pj_error_key_coll_method_not_selected') : 'Please select property key collection method.');
                        return false;
                    }
                    else {
                        return true;
                    }
                } else {
                    return true;
                }
            case "setResponse":
                return this.setResponse(index);

            default:
                return true;

        }
    }

    postJob = (workflow) => {
        const workflowId = workflow;
        let jobDetails = {};
        const { address, budget, latitude, location, longitude, placeId, propertyType, suburbId } = this.state.jobForMe;
        const { selectedService, selectedServiceType, formData, jobBudget, selectedTradees, isSelectedOnDemand, selectedQuoteLimit, budgetAmount, jobAssignTags, selectedComponent, selectedFeatureComponent } = this.state;
        if (this.state.isJobForMe == true) {

            jobDetails.propertyId = null;
            jobDetails.suburbId = suburbId;
            jobDetails.address = address;
            jobDetails.location = location;
            jobDetails.jobPropertyTypes = propertyType;
            if (this.state.jobForMe && this.state.jobForMe.propertyType && this.state.jobForMe.propertyType === "Residential") {
                jobDetails.propertyTypeId = 0;
            }
            else if (this.state.jobForMe && this.state.jobForMe.propertyType && this.state.jobForMe.propertyType === "Commercial") {
                jobDetails.propertyTypeId = 1;
            } else {
                if (this.state.propertyType && this.state.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                } else {
                    jobDetails.propertyTypeId = 1;
                }
            }
            jobDetails.budgetId = parseInt(jobBudget);
            if (jobBudget == '-999') {
                jobDetails.budgetAmount = parseFloat(budgetAmount);
            }
            if (isSelectedOnDemand) {
                jobDetails.targetedTradeeOption = 4;
                jobDetails.sendInvitationtoAll = false;
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                let tradees = [];
                selectedTradees.forEach(element => {
                    tradees.push({
                        isAssigned: true,
                        userId: element.userId,
                        name: element.name,
                        email: element.email,
                        firstName: element.name,
                        lastName: element.name,
                    });
                });
                jobDetails.tradees = tradees;
            } else {
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                jobDetails.targetedTradeeOption = 0;
                jobDetails.sendInvitationtoAll = true;
                jobDetails.tradees = [];
            }
        } else {
            if (this.state.formData.propertyId == 0 || this.state.formData.propertyId == undefined) {
                jobDetails.propertyId = null;
                jobDetails.suburbId = this.state.locationDetails.suburbId;
                jobDetails.address = this.state.locationDetails.address;
                jobDetails.jobPropertyTypes = this.refs.jobPropertyTypes.props.value;
                if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                } else if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Commercial") {
                    jobDetails.propertyTypeId = 1;
                }
                else {
                    jobDetails.propertyTypeId = 0;
                }
            } else {
                jobDetails.propertyId = this.state.formData.propertyId;
                jobDetails.address = this.state.formData.address;
                jobDetails.location = this.state.formData.location;
                jobDetails.propertyName = this.state.formData.propertyName;
                jobDetails.suburbId = this.state.formData.suburbId;
                if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                }
                else if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Commercial") {
                    jobDetails.propertyTypeId = 1;
                } else {
                    jobDetails.propertyTypeId = 0;
                }
                jobDetails.jobPropertyTypes = this.state.formData.propertyType;
            }
            jobDetails.propertyKeyCollectType = parseInt(this.state.keyCollectType);

            if (isSelectedOnDemand) {
                jobDetails.targetedTradeeOption = 4;
                jobDetails.sendInvitationtoAll = false;
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                let tradees = [];
                selectedTradees.forEach(element => {
                    tradees.push({
                        isAssigned: true,
                        userId: element.userId,
                        name: element.name,
                        email: element.email,
                        firstName: element.name,
                        lastName: element.name,
                    });
                });
                jobDetails.tradees = tradees;
            } else {
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                jobDetails.targetedTradeeOption = 0;
                jobDetails.sendInvitationtoAll = true;
                jobDetails.tradees = [];
            }
        }
        jobDetails.id = this.state.jobId;
        jobDetails.secondaryJobType = this.state.secondaryJobTypeId;
        jobDetails.secondaryJobTypeDisplay = this.state.secondaryJobTypeName;
        jobDetails.primaryJobType = this.state.primaryJobTypeId;
        jobDetails.primaryJobTypeDisplay = this.state.primaryJobTypeName;
        jobDetails.jobPropertyLocks = this.state.propertyLocks.map((item, index) => {
            const jobLock = {};
            jobLock.jobId = null;
            jobLock.propertyLock = {};
            jobLock.propertyLock.id = item.id;
            jobLock.propertyLock.lockId = item.lockId;
            jobLock.propertyLock.propertyId = jobDetails.propertyId;
            return jobLock;
        });

        jobDetails.jobType = this.state.secondaryJobTypeId;

        jobDetails.jobTypeDisplay = this.state.formData.childJobType;
        jobDetails.title = this.state.formData.jobDescriptionEng;
        jobDetails.description = this.state.formData.jobDescriptionEng;
        jobDetails.jobUrgencyId = this.state.jobUrgency;

        jobDetails.dueDate = (!this.state.dueDate) ? null : this.state.dueDate;
        jobDetails.JobUrgencyDisplay = this.state.jobUrgency == -1 ? "Emergency" : this.state.jobUrgency == 1 ? "Urgent" : "not-Urgent";
        jobDetails.jobValidityPeriod = 1;
        jobDetails.serviceConsumerId = localStorage.getItem('userId');

        jobDetails.workflowStatusId = workflow === 1 ? 1 : 2;
        jobDetails.attachments = this.state.attachments;
        if (workflowId == 2) {
            jobDetails.isActive = true;
        }
        if (selectedComponent != '') {
            jobDetails.JobPropertyComponentId = selectedComponent;
        }

        if (jobAssignTags != undefined && jobAssignTags.length > 0) {
            jobDetails.JobAssignTagIds = jobAssignTags;
        }

        if (selectedFeatureComponent != undefined && selectedFeatureComponent.length > 0) {
            jobDetails.JobPropertyFeatureComponentIds = selectedFeatureComponent;
        }

        jobDetails.issueAreaInProperty = (this.state.formData.issueAreaInPropertyEng) ? this.state.formData.issueAreaInPropertyEng : this.state.formData.issueAreaInProperty;

        if (this.state.maintenanceTroubleshootGuideId && this.state.maintenanceTroubleshootGuideId != '') {
            jobDetails.maintenanceTroubleshootGuideId = this.state.maintenanceTroubleshootGuideId;
        }
        if (this.state.isFromBot) {
            jobDetails.channelId = 12;
        } else {
            jobDetails.channelId = 1;
        }
        this.setState({ isJobPosting: true })
        try {
            trackPromise(
                AXIOS.POST(`tenant-api/job-post`, jobDetails, {
                    'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret, 'ChannelId': jobDetails.channelId }
                })
                    .then(dataJson => {
                        this.setState({ isJobPosting: false })
                        const message = workflowId === 1 ? 'drafted' : 'posted'
                        if (dataJson == 'Request Unauthorized' || dataJson == 'Job creation failed') {
                            toast.error(`Job is not ${message} successfully. Please try again.`, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        } else {
                            toast.success(`Job is ${message} successfully.`, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            this.setState({ jobNumber: dataJson.jobNumber });
                            this.setState({ currentStep: 5 });
                            this.setState({
                                attachments: [],
                                value: '',
                                isShowMoreServices: false,
                                showDisclaimerDrawer: false,
                                jobPostWorkflowStatus: 1
                            });
                            if (this.state.troubleShootingGuideDetails.troubleshootGuideId != '') {
                                this.saveTroubleShootResult(false, dataJson.id);
                            }
                        }
                    }, error => {
                        console.log(error);
                        this.setState({ isJobPosting: false });
                        const message = workflowId === 1 ? 'draf' : 'post';
                        toast.error(`Fail to ${message} the job. Please try again.`, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
            )

        } catch (error) {
            console.log(error);
        }
    }

    saveTroubleShootResult = (isResolved = false, jobId = "") => {
        let troubleShootResult = {};
        troubleShootResult.isResolved = isResolved;
        troubleShootResult.jobId = jobId;
        troubleShootResult.userId = this.state.userId;
        troubleShootResult.maintenanceTroubleshootGuideId = this.state.troubleShootingGuideDetails.troubleshootGuideId;
        troubleShootResult.description = this.state.formData.jobDescriptionEng;
        troubleShootResult.propertyId = this.state.propertyId;
        jobService.saveTroubleShootingResult(troubleShootResult).then(response => {
            if (response) {
                if (isResolved) {
                    this.setState({ currentStep: 7,isShowTroubleshootGuid:false });
                    this.showCurrentStep(7);
                }
            }
        });
    }

    continueJobPosting = (index) => {
        let jobTypes = this.props.jobTypes;
        this.state.selectedService = jobTypes.find(x => x.parentJobType.id == this.state.troubleShootingGuideDetails.primaryJobTypeId);

        this.setState({
            currentStep: (index + 1),
            parentId: this.state.troubleShootingGuideDetails.primaryJobTypeId,
            childJobTypeId: this.state.troubleShootingGuideDetails.secondaryJobTypeId,
            isShowTroubleshootGuid:false
        });
    }

    GetResolvedResponseByTroubleshootGuide = () => {
        const { t } = this.props;
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <div className="job-posted-icon text-center">
                            <span className="ba-icn ba-icn-briefcase" style={{ color: (this.state.isFromBot && this.props.customStyle.brand) ? this.props.customStyle.brand : '' }}>
                                <span className="ba-icn ba-icn-ok"></span>
                            </span>
                        </div>
                        <div className="job-posted-msg">
                            <h3 className="text-center"> You have successfully resolved the issue</h3>
                        </div>
                        <div className="wizard-actions">
                            {(this.state.isFromBot) ? <button onClick={() => this.props.resetChat()} className="btn light-blue-button" style={this.state.primaryButtonStyle} ><span>{t('btn_done')}</span></button> : <Link to={`./home`} className="btn light-blue-button"><span>Back to home</span></Link>}
                        </div>
                    </div>
                </div>
            </>);
    }

    t() {
        return false;
    }


    SetSelectedServiceType = () => {
        let jobTypes = this.props.jobTypes;

        let selectedService = jobTypes.find(x => x.parentJobType.id == this.state.troubleShootingGuideDetails.primaryJobTypeId);
        this.setState({
            title: selectedService.parentJobType.title,
            primaryJobTypeId: selectedService.parentJobType.id,
            secondaryJobTypeId: this.state.troubleShootingGuideDetails.secondaryJobTypeId,
            primaryJobTypeName: selectedService.parentJobType.title,
            secondaryJobTypeName: selectedService.childJobTypes.find(x => x.id == this.state.troubleShootingGuideDetails.secondaryJobTypeId).title,
        });
    }

    jobPostSummary = (index) => {
        const { address, title, jobUrgency, description, keyCollectType, attachments } = this.state;
        const { t } = this.props;
        return (
            <>
                <div>
                    <div className="post-summery flex-column-section">
                        <div>
                            <h2>Please check all the information below is correct</h2>
                        </div>
                        <div className='flex-column-section'>
                        <div className="mb-4">
                            <h4 className="mb-1">Property</h4>
                            <p className="mb-0 text-left">{this.state.formData.address}</p>
                        </div>
                        <div className="mb-4">
                            <h4 className="mb-1">Service</h4>
                            <p className="mb-0 text-left">{this.state.secondaryJobTypeName}</p>
                        </div>
                        <div className="mb-4">
                            <h4 className="mb-1">Job Title</h4>
                            <p className="mb-0 text-left">{this.state.primaryJobTypeName}</p>
                        </div>
                        <div className="mb-4">
                            <h4 className="mb-1">Urgency</h4>
                            <p className="mb-0 text-left">{this.GetJobUrgency()}</p>
                        </div>
                        <div className="mb-4">
                            <h4 className="mb-1">Description</h4>
                            <p className="mb-0 text-left">{this.state.formData.jobDescriptionEng}</p>
                        </div>
                        <div className="mb-4">
                            <h4 className="mb-1">Key Collection</h4>
                            <p className="mb-0 text-left">{this.GetKeyCollectionTypeDescription()}</p>
                        </div>
                        <div className='d-inline-block w-100'>
                            <h4 className="mb-1">Attachments</h4>
                            {(this.state.attachments) ?
                                <ImageViewer attachments={this.state.attachments} isFromBot={this.state.isFromBot} isFromTenantManageJobPost = {localStorage.userType == 4 ? true : false} t={t}></ImageViewer> : ''
                            }
                        </div>
                        </div>
                        <div className='d-flex flex-column flex-md-row justify-content-between mt-4'>
                                <div className='col-12 col-md-6 px-0 pr-md-3 mb-4 mb-md-0'>
                                        <label>Step 3 of 3</label>
                                        <div className='col-12 px-0'>
                                            <div class="progress">
                                                <div class="progress-bar bg-success rounded w-100" role="progressbar"></div>
                                            </div>
                                        </div>
                                </div>
                                <div className='col-12 col-md-6 px-0 pl-md-3'>
                                    <div className="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-0">
                                        <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>BACK</button>
                                        <button type="button" className="btn next-btn px-4 mb-0 mx-0 mx-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" className='ml-2'/></button>                                        
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </>);
    }

    GoToHomePage = (index) => {
        let { history } = this.props;
        history.push("/home");
    }
}



const mapStateToProps = (state, ownProps) => {
    try {
        return {
            jobTypes: state.Jobs.jobTypes,
            dateFormat: state.User.dateFormat,
            userRoles: state.User.userRoles,
            properties: state.Tenant.tenantProperties,
            isCurrentTenant: state.Tenant.isCurrentTenant,
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateProperties: properties => {
            dispatch({ type: "SET_PROPERTIES", properties: properties });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobPostWizard));
import React from "react";
import { connect } from "react-redux";
import userService from "../../../../Services/UserService";
import chatService from "../../../../Services/ChatService";
import ChatUser from "./ChatUser/ChatUser";
class ChatContacts extends React.Component {
    state = {
        chatUsers: [],
        chatUserLists: []
    };

    componentDidMount() {
        this.getChatUsersList();
    }

    getChatUsersList = () => {
        userService.getChatUsersList().then(res => {
            this.setState({
                chatUsers: res.chatUsers,
                chatUserLists: res.chatUsers
            });
        });
    };

  

    render() {
        return (
            <React.Fragment>
                <div className="chat-user-search" >
                    <input type="text" placeholder="Search..." onChange={event => this.searchUserByUserName(event.target.value)} />
                </div>
                <div className="chat-users panel-body">
                    <div className="chat-user chat-contacts" onClick={() => { window.Intercom('show'); }}>
                        <div className="chat-user-img bot-chat">
                            <img src="/images/bot-chat.svg" alt="" />
                        </div>
                        <div className="chat-username">
                            <span>
                                <b>Help</b>
                            </span>
                        </div>
                    </div>

                    {
                        (this.state.chatUsers && this.state.chatUsers.length > 0) ?
                            this.state.chatUsers.map((user, index) => {
                                return <ChatUser
                                    key={index}
                                    user={user}
                                    openChatWindowFromContactList={(user) => this.openChatWindowFromContactList(user)}>
                                </ChatUser>
                            }) : ''
                    }

                </div>
            </React.Fragment>
        );
    }

    searchUserByUserName($event) {
        this.setState({
            chatUsers: this.state.chatUserLists
        });
        if ($event.length > 2) {
            var filterdList = new Array();
            this.state.chatUsers.forEach(option => {
                if (option.displayName != null || option.displayName !== undefined) {
                    if (option.displayName.toLowerCase().includes($event.toLowerCase())) {
                        filterdList.push(option);
                    }
                }
            });
            this.setState({
                chatUsers: filterdList
            });
        }
        return;
    }



    openChatWindowFromContactList(user) {
        let conversationThread = {}
        conversationThread.sender = this.props.loggedUserId;
        conversationThread.senderFirstName = this.props.loggedUserFirstName;
        conversationThread.senderLastName = this.props.loggedUserLastName;
        conversationThread.senderImage = this.props.loggedUserProfileImage;
        conversationThread.receiver = user.userId;
        conversationThread.receiverFirstName = user.firstName;
        conversationThread.receiverLastName = user.lastName;
        conversationThread.receiverImage = user.profileImageUrl;
        conversationThread.conversationName = user.firstName + ' ' + user.lastName;

        let chatList = (this.props.chatBoxes == '') ? [] : JSON.parse(this.props.chatBoxes);
        chatList.push(conversationThread)
        this.props.updateChatboxes(JSON.stringify(chatList));
    }
}

const mapStateToProps = (state, ownProps) => {
    try {
        let chats = state.Conversation.conversationList;

        return {
            chatBoxes: chats,
            imageThumbnailsCdnEndpoint: state.AppSettings.imageThumbnailsCdnEndpoint,
            imageCdnEndpoint: state.AppSettings.imageCdnEndpoint,
            profileThumbnailsCdnEndpoint:
                state.AppSettings.profileThumbnailsCdnEndpoint,
            loggedUserProfileImage: state.User.profileImageUrl,
            loggedUserFirstName: state.User.firstName,
            loggedUserLastName: state.User.lastName,
            loggedUserId: state.User.userId
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateChatboxes: chatList => {
            dispatch({ type: "SHOW_CONVERSATION", chatList: chatList });
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatContacts);

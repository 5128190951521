import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import Drawer from "../../Common/Drawer/Drawer";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";
import ImageViewer from '../../Common/ImageViewer/ImageViewer';


class JobVariationDrawer extends React.Component {
    state = {
        showJobVariationDrawer: false
    };

    variationAmout = '';

    constructor(props) {
        super(props);
        this.description = React.createRef();
    }

    render() {
        let drawer = this.createDrawer();

        return (
            <>
                <button className="action-btn" onClick={this.toggleJobVariationDrawer}>
                    <span className="button-label">Variation</span>
                </button>
                {drawer}
            </>
        );
    }

    createDrawer = () => {
        const header = this.createJobVariationHeader();
        const body = this.createJobVariationBody();
        const footer = this.createJobVariationFooter();

        return (
            <Drawer
                size={"md"}
                open={this.state.showJobVariationDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    };

    createJobVariationHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">Job Variation</h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={this.toggleJobVariationDrawer}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createJobVariationBody = () => {
        if (this.props.jobDetails.jobVariations && this.props.jobDetails.jobVariations.length > 0) {
            this.variationAmout = this.props.jobDetails.jobVariations[0].amount;
        }

        return (
            <div className="panel-body">
                <div className="job-detail-card">
                    <div className="job-details-table">
                        <div className="job-details-table-row">
                            <div className="table-label text-left">Variation Amount{" "}</div>
                            <label className="table-data"><strong>{" "}${this.variationAmout.toFixed(2)}</strong></label>
                        </div>
                    </div>
                </div>
                <div className="job-detail-card">
                    {(this.props.jobDetails.jobVariations && this.props.jobDetails.jobVariations.length > 0 && this.props.jobDetails.jobVariations[0].jobVariationAttachments) ?
                        <>
                            <ImageViewer attachments={this.props.jobDetails.jobVariations[0].jobVariationAttachments} isVariation={true}></ImageViewer>
                        </> : ''
                    }
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="w-100 text-left">Note</label>
                            <textarea
                                className="form-control"
                                id="txtFeedback"
                                rows="3"
                                ref={this.description}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    createJobVariationFooter = () => {
        return (
            <>
                <div className="card-actions">
                    <button onClick={this.onClickJobVariationApprove}>
                        <i className="ba-icn ba-icn-ok"></i> Approve{" "}
                    </button>
                    <button onClick={this.onClickJobVariationReject}>
                        <i className="ba-icn ba-icn-ok"></i> Reject{" "}
                    </button>
                    <button className="more-actions" onClick={this.toggleJobVariationDrawer}>
                        {" "}
                        Cancel{" "}
                    </button>
                </div>
            </>
        );
    };

    onClickJobVariationApprove = () => {
        if (this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
            toast.error("Please enter job approving note.", { position: toast.POSITION.BOTTOM_RIGHT });
        }
        else {
            let jobVariationApproveRequest = {};
            jobVariationApproveRequest.userId = localStorage.getItem("userId");
            jobVariationApproveRequest.jobId = this.props.jobDetails.id;
            jobVariationApproveRequest.note = this.description.current.value;

            trackPromise(
                AXIOS.POST(`tenant-api/job-variation-approve-owner`, jobVariationApproveRequest).
                    then(response => {
                        if (response) {
                            toast.success("Job Variation Approved.", {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            this.toggleJobVariationDrawer();
                            this.props.toggleDrawer(true);
                        } else {
                            toast.error("Error occured. Please try again.", {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        }
                    }));
        }
    };

    onClickJobVariationReject = () => {
        if (this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
            toast.error("Please enter job rejection note.", { position: toast.POSITION.BOTTOM_RIGHT });
        }
        else {
            let jobVariationRejectRequest = {};
            jobVariationRejectRequest.userId = localStorage.getItem("userId");
            jobVariationRejectRequest.jobId = this.props.jobDetails.id;
            jobVariationRejectRequest.note = this.description.current.value;

            trackPromise(
                AXIOS.POST(`tenant-api/job-variation-reject-owner`, jobVariationRejectRequest).
                    then(response => {
                        if (response) {
                            toast.success("Job Variation Rejected.", {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            this.toggleJobVariationDrawer();
                            this.props.toggleDrawer(true);
                        } else {
                            toast.error("Error occured. Please try again.", {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        }
                    }));
        }
    };

    toggleJobVariationDrawer = () => {
        this.setState(state => ({
            showJobVariationDrawer: !this.state.showJobVariationDrawer
        }));
    };
}

const mapStateToProps = (state, ownProps) => {
    return {
        isRefresh: state.MyJobs.isRefreshGrid
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refreshGrid: isRefresh => {
            dispatch({ type: "REFRESH_GRID", isRefresh: isRefresh });
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobVariationDrawer);

import React, { useState } from "react";
import Drawer from "../../Common/Drawer/Drawer";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";
import jobService from "../../../Services/JobService";
import { trackPromise } from "react-promise-tracker";
import { UserType } from "../../../Constants/Enums";

class JobCancelDrawer extends React.Component {
  state = {
      showJobCancelDrawer: false
  };
  constructor(props) {
    super(props);
  }

  render() {
    let drawer = this.createDrawer();

    return (
      <>
        <button className="action-btn" onClick={this.toggleJobCancelDrawer}>
          <span className="button-label">Cancel</span>
        </button>
        {drawer}
      </>
    );
  }

  createDrawer = () => {
    const header = this.createJobCancelHeader();
    const body = this.createJobCancelBody();
    const footer = this.createJobCancelFooter();

    return (
      <Drawer
        size={"md"}
        open={this.state.showJobCancelDrawer}
        header={header}
        body={body}
        footer={footer}
      ></Drawer>
    );
  };

  createJobCancelHeader = () => {
    return (
      <>
        <h2 className="d-flex align-items-center">Cancel Job</h2>
        <div className="panel-header-actions">
          <button
            className="action-btn panel-close"
            onClick={this.toggleJobCancelDrawer}
          >
            <i className="ba-icn ba-icn-close"></i>
          </button>
        </div>
      </>
    );
  };

  createJobCancelBody = () => {
    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="d-flex">Are you sure you want to cancel the job?</label>
            </div>
          </div>
        </div>
        <div className="row d-none">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  name="markAsCompleted"
                  type="checkbox"
                  id="1"
                  className="custom-control-input"
                />
                <label
                  title=""
                  type="checkbox"
                  htmlFor="1"
                  className="custom-control-label"
                >
                  {" "}
                  Mark as Completed
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  createJobCancelFooter = () => {
    return (
      <>
        <div className="card-actions">
          <button onClick={this.onClickJobCancel}>
            <i className="ba-icn ba-icn-ok"></i> Yes{" "}
          </button>
          <button className="more-actions" onClick={this.toggleJobCancelDrawer}>
            {" "}
            No{" "}
          </button>
        </div>
      </>
    );
  };

  onClickJobCancel = () => {
      trackPromise(
        jobService.CancelJob(this.props.jobDetails.id).then(response => {
            if(response) {
                toast.success("Job has been cancelled.",{
                  position: toast.POSITION.BOTTOM_RIGHT
                });
                this.toggleJobCancelDrawer();
                this.props.toggleDrawer(true);
            } else {
                toast.error("Error occured. Please try again.",{
                  position: toast.POSITION.BOTTOM_RIGHT
                });
            }
          }).catch(error => {
            toast.error("Error occured. Please try again.",{
              position: toast.POSITION.BOTTOM_RIGHT
            });
          })
      )
  };

  toggleJobCancelDrawer = () => {
    if (this.isAwarded() || (this.props.jobDetails.tradees.length > 0 && !this.isDraftedJob())) {

        const tradeOption = this.props.jobDetails.targetedTradeeOption === 2
            ? "awarded"
            : "sent for quotes";

        const message = localStorage.getItem("userType") == UserType.Tenant
            ? "please contact your property manager to cancel the job."
            : "hence it cannot be canceled.";

        toast.info(`The job has been ${tradeOption}, ${message}`, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }
    else {
        this.setState(state => ({
            showJobCancelDrawer: !this.state.showJobCancelDrawer
        }));
    }
  };

  isAwarded() {
    return this.props.jobDetails.workflowStatusId == 4;
  }

  isDraftedJob() {
    return this.props.jobDetails.workflowStatusId == 1;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isRefresh: state.MyJobs.isRefreshGrid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshGrid: isRefresh => {
      dispatch({ type: "REFRESH_GRID", isRefresh: isRefresh });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobCancelDrawer);

import React, { useState } from "react";

// Plugins
import {  toast } from "react-toastify";
import { registerPlugin, FilePond } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { withTranslation, Trans } from 'react-i18next';

// Components
import Drawer from "../../Common/Drawer/Drawer";

// Styles
import "./AttachmentsDrawer.scss";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
class AttachmentsDrawer extends React.Component {
  state = {
    attachments: [],
    showAttachmentsDrawer: false,
    isFromBot:this.props.isFromBot?true:false,
    isFromAsset:this.props.isFromAsset?true:false,
  };

  constructor(props) {
    super(props);
  }

  render() {
    let drawer = this.createDrawer();
    return <>{drawer}</>;
  }

  createDrawer = () => {
    let header = this.createHeader();
    let body = this.createBody();
    let footer = this.createFooter();
    const { t , isFromBot , isFromVariation} = this.props;
    return (
      <>
        {(this.props.isJobPosted) ? <button className="d-block" onClick={this.toggleAttachmentsDrawer}> Upload Attachments </button> : <>
          <div className="form-group">
            <div
              className="form-control rich-control"
              onClick={this.toggleAttachmentsDrawer}
            ><div className="d-flex align-items-center"
            >
                       
                <span className="icon icon-ico-upload upload-icon"></span>
            
                  <label className="pl-2">{isFromBot ? t('btn_addimages_videos') :  isFromVariation ? 'Upload images/ videos' : 'Upload Attachments'}</label>
              {isFromVariation ?
                <></> : <div className="plus-icon">
                  <i className="ba-icn ba-icn-close"></i>
                </div>
              }</div>
            </div>
          </div>
        </>}
        <Drawer
          size={"sm"}
          open={this.state.showAttachmentsDrawer}
          header={header}
          body={body}
          footer={footer}
        ></Drawer>
      </>
    );
  };

  createHeader = () => {
    const { t, isFromBot } = this.props;
    return (
      <>
        <h2 className="d-flex align-items-center">
          {/* {this.props.title} */}
          {isFromBot ? t('hdr_attachment_drawer') : 'Add Attachments'}
        </h2>
        <div className="panel-header-actions">
          <button
            className="action-btn panel-close"
            type="button"
            onClick={this.toggleAttachmentsDrawer}
          >
            <i className="ba-icn ba-icn-close"></i>
          </button>
        </div>
      </>
    );
  };

  createBody = () => {
    const files = this.getUploadedImages();
    const { t, isFromBot, isFromAsset} = this.props;
    return (
      <div className={this.state.isFromBot?"panel-body-bot":"panel-body"}>
        <div className="form-group">
                <label>
                  {(!isFromAsset)? <Trans i18nKey="lbl_attachment_drawer_body">
                        Please upload some <strong>{{ name: t('lbl_attachment_drawer_highlited') }}</strong> to
                        provide further information for service providers to more accurately
                        quote.
                    </Trans>:
                    'Please upload some Pictures, Documents to provide further information for property managers to more accurately review'}
                </label>
          <div className="form-control rich-control d-none">
            <span>
              <img src="/Images/upload-icon.png" alt="upload-icon" />
            </span>
            <label>{isFromBot ? t('btn_addimages_videos') : 'Add images/ videos'}</label>
            <div className="plus-icon">
              <i className="ba-icn ba-icn-close"></i>
            </div>
          </div>
          <label labelfor="test123" className="file-upload d-none">
            <span>Add Attachments(s)</span>
            <span className="font-weight-bold">
              (.jpg,.jpeg,.png,.png,.mp4,.csv,.pdf)
            </span>
            <br></br>
            <input
              name="test123"
              type="file"
              placeholder="Select a file"
              className="invicible"
            />
          </label>
          <FilePond
                    ref={ref => (this.pond = ref)}
                    name={"filepond--browser"}
                    type={"remote"}
                    files={this.state.attachments}
                    allowMultiple={true}
                    server={{
                        process: (
                            fieldName,
                            file,
                            metadata,
                            load,
                            error,
                            progress,
                            abort,
                            transfer,
                            options
                        ) => {

                            if (file.name.includes('.msg') || file.name.includes('.eml') || file.type === 'message/rfc822' || file.name.includes('.mp3')) {
                                toast.error('Please Select valid types of file.', {
                                    position: toast.POSITION.BOTTOM_RIGHT
                                });
                                abort();
                                return;
                            }

                            if (isFromAsset && (file.name.includes('.mp4') || file.name.includes('.mov') || file.name.includes('.csv') || file.name.includes('.mp3'))) {
                              toast.error('Please Select valid types of file.', {
                                  position: toast.POSITION.BOTTOM_RIGHT
                              });
                              abort();
                              return;
                            }
                            const formData = new FormData();
                            formData.append("file", file);
                            const request = new XMLHttpRequest();
                            const attComponent = this;
                            if(this.props.isFromVariation){
                              request.open(
                                "POST",
                                `/tenant-api/file-upload?id=${
                                this.props.jobDetails.id
                                }&persist=false&userId=${localStorage.getItem("userId")}`
                            );
                            }
                            else if(this.props.isExternal){
                              request.open(
                                "POST",
                                `/tenant-api/file-upload?id=${
                                this.props.jobDetails.id
                                }&persist=${!this.props
                                    .isJobPost}&attachmentType=${this.props.isJobPost ? 3 : 0}&userId=${this.props.jobDetails.serviceConsumerId}`);
                            }
                            else if(this.props.isFromAsset){
                              request.open(
                                "POST",
                                `/tenant-api/file-upload?id=${
                                this.props.assetId
                                }&persist=${false}&attachmentType=0&userId=${this.props.assetSubmittedUserId}`);
                            }
                            else{
                            request.open(
                                "POST",
                                `/tenant-api/file-upload?id=${
                                this.props.jobDetails.id
                                }&persist=${!this.props
                                    .isJobPost}&attachmentType=${this.props.isJobPost ? 3 : 0}&userId=${localStorage.getItem("userId")}`
                            );
                            }


                            // Should call the progress method to update the progress to 100% before calling load
                            // Setting computable to false switches the loading indicator to infinite mode
                            request.upload.onprogress = e => {
                                progress(e.lengthComputable, e.loaded, e.total);
                            };

                            // Should call the load method when done and pass the returned server file id
                            // this server file id is then used later on when reverting or restoring a file
                            // so your server knows which file to return without exposing that info to the client
                            request.onload = function () {
                                if (request.status >= 200 && request.status < 300) {                                 
                                  let result = JSON.parse(request.responseText);
                                  if (result && result.status==10){
                                    toast.error(result.message,{
                                        position: toast.POSITION.BOTTOM_RIGHT
                                      });
                                      error();
                                      abort();
                                      return;
                                  }
                                    // the load method accepts either a string (id) or an object
                                    load(request.responseText);
                                    attComponent.state.attachments.push(
                                        JSON.parse(request.response)
                                    );
                                } else {
                                    // Can call the error method if something is wrong, should exit after
                                    error("oh no");
                                }
                            };

                            request.send(formData);

                            // Should expose an abort method so the request can be cancelled
                            return {
                                abort: () => {
                                    // This function is entered if the user has tapped the cancel button
                                    request.abort();

                                    // Let FilePond know the request has been cancelled
                                    abort();
                                }
                            };
                        },
                        revert: (uniqueFileId, load, error) => {
                            let attComponent = this;
                            const request = new XMLHttpRequest();
                            let fileObj = JSON.parse(uniqueFileId);
                            request.open(
                                "GET",
                                `/tenant-api/delete-file?fileId=${fileObj.fileId}`
                            );

                            request.onload = function () {
                                if (request.status >= 200 && request.status < 300) {
                                    // the load method accepts either a string (id) or an object
                                    load();
                                    let att = attComponent.state.attachments.filter(a => {
                                        return a.fileId != fileObj.fileId;
                                    });
                                    attComponent.state.attachments = att;
                                } else {
                                    // Can call the error method if something is wrong, should exit after
                                    error("oh no");
                                }
                            };

                            request.send();

                            return {
                                abort: () => {
                                    // This function is entered if the user has tapped the cancel button
                                    request.abort();
                                }
                            };
                            // Can call the error method if something is wrong, should exit after
                            //{/* error("oh my goodness"); */ }
                            // Should call the load method when done, no parameters required
                        }
                    }}
                    oninitfile={() => this.handleInit()}
                    labelIdle={isFromBot ? t('hdr_lbl_drop_attchmnt_here') + " (.jpg, .jpeg, .png, .gif, .mp4, .csv, .pdf, .mov, .tiff)" : (isFromAsset)?'Drop attachment(s) here to upload or browse (.jpg, .jpeg, .png, .pdf)': 'Drop attachment(s) here to upload or browse' + " (.jpg, .jpeg, .png, .gif, .mp4, .csv, .pdf, .mov, .tiff)"}
          ></FilePond>
        </div>
        {files}
      </div>
    );
  };

  getUploadedImages = () => {    
    if (!this.state.isFromAsset && this.props.jobDetails.hasOwnProperty("attachments")) {
      const uploadedFiles = this.state.attachments.map(file => {
        return <div className="fileslider-item">
          <img src={file.fileUrl} alt="" className="uploaded-img" />
          </div>;
      });
    }
  };

    createFooter = () => {
        const { t, isFromBot } = this.props;
    return (
      <>
        <div className="card-actions">
          <button  onClick={this.onAttachToPost} type="button">
            <i className="ba-icn ba-icn-ok"></i>
                    {isFromBot ? t('btn_done') : 'Done'}
          </button>
          <button
            className="more-actions"
            type="button"
            onClick={this.toggleAttachmentsDrawer}
          >
            {" "}
            {isFromBot ? t('btn_cancel') : 'Cancel'}{" "}
          </button>
        </div>
      </>
    );
  };

  onAttachToPost = () => { 
    
    if(this.props.isFromAsset){
      this.setAsstJobAttachments();
      return;
    }

    this.state.attachments.forEach(element => {
      this.props.jobDetails.attachments.push(element);
    });
    this.props.onSubmitAttachments( this.props.jobDetails.attachments);
    this.toggleAttachmentsDrawer();

  };

  setAsstJobAttachments=()=>{
    this.state.attachments.forEach(element => {
      this.props.assetAttachments.push(element);
    });
    this.props.onSubmitAttachments( this.props.assetAttachments );
    this.toggleAttachmentsDrawer();
  }
  
  handleInit = (file) => {
  };

  toggleAttachmentsDrawer = () => {
    this.setState({ attachments: [] });
    this.setState({
      showAttachmentsDrawer: !this.state.showAttachmentsDrawer
    });
  };
}

export default withTranslation()(AttachmentsDrawer);

import React from 'react';
import { Link } from 'react-router-dom'
import { UserType } from '../../../Constants/Enums';
import { Guid } from "guid-typescript";
import googleAnalyticsService from '../../../Services/GoogleAnalyticsService';
import OwnerJobPostWizard from '../../Common/JobPostWizard/OwnerJobPost/OwnerJobPostWizard';
import TenantManageJobPostWizard from '../../Common/JobPostWizard/TenantManage/TenantManageJobPostWizard';
import JobPostWizard from '../../Common/JobPostWizard/JobPostWizard';
class Home extends React.Component {

    state = {
        showJobPostDrawer: false,
        jobId: '',
        firstName: localStorage.getItem("firstName")
    };

    render() {
        const { pathname, host } = this.props;
        const isTenant = localStorage.getItem("userType") == UserType.Tenant ? true : false ;
        const userType = localStorage.getItem("userType") == UserType.Owner ? "/owner" : "/tenant";
        const jllPageReq = localStorage.getItem("styleCode") ? localStorage.getItem("styleCode") : "";
        const isTenantManage = localStorage.getItem('isTenantManageJob');

        return (
            <>
                <div className="home-wrapper container-fluid-wrapper">
                    {(!this.state.showJobPostDrawer) ?
                        <>
                            <h1>Welcome back, {this.state.firstName}!</h1>
                            <p>Please see all the activities that require your attention.</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <Link className="home-btn" to={`${userType}/jobs-for-review`}>
                                        <span className="plus-icon d-flex align-items-center justify-content-center"><i className="ba-icn ba-icn-job-review"></i></span><span className="btn-label">Items for Review </span>
                                        {/* <span className="counter">3</span> */}
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <Link className="home-btn" to={`${userType}/my-jobs`}>
                                        <span className="plus-icon d-flex align-items-center justify-content-center"><i className="ba-icn ba-icn-my-jobs"></i></span><span className="btn-label">Maintenance Request</span>
                                    </Link>
                                </div>
                                {!isTenantManage &&
                                    <>
                                        <div className="col-md-6">
                                            <Link className="home-btn" to={`${userType}/property-inspection`}>
                                                <span className="plus-icon d-flex align-items-center justify-content-center"><i className="ba-icn ba-icn-inspections"></i></span><span className="btn-label">Inspection</span>
                                            </Link>
                                        </div>
                                        <div className="col-md-6">
                                            <Link className="home-btn" to={`${userType}/document-list`}>
                                                <span className="plus-icon d-flex align-items-center justify-content-center"><i className="ba-icn ba-icn-checklist"></i></span><span className="btn-label">Document</span>
                                                {/* <span className="counter">3</span> */}
                                            </Link>
                                        </div>
                                    </>
                                }

                                <div className="col-md-6" >
                                    <button className="home-btn maintenance" onClick={() => this.openPostJobDrawer()}>

                                        <span className="plus-icon d-flex align-items-center justify-content-center"><i className="ba-icn ba-icn-plus"></i></span><span className="btn-label">Add Maintenance Request</span>
                                    </button>
                                </div>

                                {(userType == "/owner") ?
                                    <div className="col-md-6">
                                        <Link className="home-btn" to={`${userType}/all-jobs`}>
                                            <span className="plus-icon d-flex align-items-center justify-content-center"><i className="ba-icn ba-icn-briefcase"></i></span><span className="btn-label">All Maintenance Request</span>
                                        </Link>
                                    </div>
                                    : ''}
                            </div>
                        </> : ""}
                    {(this.state.showJobPostDrawer) ?
                        jllPageReq === "JLL" ?
                            <TenantManageJobPostWizard backtoList={(event) => this.backToList(event)}
                                isJobEdit={false}
                                jobEditid=""
                                isFromBot={false}
                                userData={this.props.userData}>
                            </TenantManageJobPostWizard> :
                        isTenant ? <JobPostWizard backtoList={(event) => this.backToList(event)}
                                isJobEdit={false}
                                jobEditid=""
                                isFromBot={false}>
                            </JobPostWizard>:
                            <OwnerJobPostWizard backtoList={(event) => this.backToList(event)}
                            isJobEdit={false}
                            jobEditid=""
                            isFromBot={false}>
                            </OwnerJobPostWizard>: ''}
                </div>


            </>

        )

    }
    openPostJobDrawer = () => {
        this.setState({ showJobPostDrawer: true });
        this.setState({
            jobId: Guid.create().toString(),
        });
        googleAnalyticsService.sendEventStatsToGoogle("View", "Button click", "Post a Job");

    };

    hideJobPost = () => {
        setTimeout(() => {
            this.setState({ showJobPostDrawer: false });
        }, 500);
        googleAnalyticsService.sendEventStatsToGoogle("Cancel", "Button click", "Post a Job");
    };

    backToList = (status) => {
        this.setState({
            showJobPostDrawer: false,
        });
    }

}

export default Home;
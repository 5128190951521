import React, { useState } from 'react';
import { connect } from 'react-redux';
// Plugins
import { AXIOS } from '../../../Utilities/HttpRequest/Axios';
import { registerPlugin, FilePond } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Components 
import Drawer from '../../Common/Drawer/Drawer';

// App Settings
import { AppConfiguration } from "read-appsettings-json";

// Styles
import './ProfilePicUploadDrawer.scss';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
class ProfilePicUploadDrawer extends React.Component {
    state = {
        profileImageUrl: '',
        showAttachmentsDrawer: false,

    }

    constructor(props) {
        super(props);
    }

    render() {
        let drawer = this.createDrawer();
        return (
            <div>
                {drawer}
            </div>
        );
    }

    createDrawer = () => {
        let header = this.createHeader();
        let body = this.createBody();
        let footer = this.createFooter();
        return (
            <>
                <div className="form-group">
                    <div className="form-control rich-control" onClick={this.toggleAttachmentsDrawer}>
                        <span><img src="/Images/upload-icon.png" alt="upload-icon" /></span>
                        <label>Add an image</label>
                        <div className="plus-icon">
                            <i className="ba-icn ba-icn-close"></i>
                        </div>
                    </div>
                </div>
                <Drawer
                    size={'sm'}
                    open={this.state.showAttachmentsDrawer}
                    header={header}
                    body={body}
                    footer={footer}>
                </Drawer>
            </>

        )
    }

    createHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    {/* {this.props.title} */}
                    Add Attachments
                </h2>
                <div className="panel-header-actions" >
                    <button className="action-btn panel-close" type="button" onClick={this.toggleAttachmentsDrawer}>
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        )
    }

    createBody = () => {
        return (
            <div className="panel-body">
                <div className="form-group">
                    <label>Please upload a <strong>Picture</strong></label>
                    <div className="form-control rich-control d-none">
                        <span><img src="/Images/upload-icon.png" alt="upload-icon" /></span>
                        <label>Add images/ videos</label>
                        <div className="plus-icon">
                            <i className="ba-icn ba-icn-close"></i>
                        </div>
                    </div>
                    <label labelfor="test123" className="file-upload d-none">
                        <span>Add Attachments(s)</span>
                        <span className="font-weight-bold">(.jpg,.jpeg,.png,.png)</span>
                        <br></br>
                        <input name="test123" type="file" placeholder="Select a file" className="invicible" />
                    </label>
                    <FilePond ref={ref => this.pond = ref}
                        name={'filepond--browser'}
                        type={'remote'}
                        file={this.state.profileImageUrl}
                        allowMultiple={false}
                        server={{

                            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                // fieldName is the name of the input field
                                // file is the actual file object to send
                                const formData = new FormData();
                                formData.append('file', file);
                                console.log("insideFilePond");
                                const request = new XMLHttpRequest();
                                let attComponent = this;
                                request.open('POST', `/tenant-api/profilePic-upload?userId=${localStorage.getItem('userId')}`);
                                {/* request.setRequestHeader("Content-Type", "multipart/form-data"); */}

                                // Should call the progress method to update the progress to 100% before calling load
                                // Setting computable to false switches the loading indicator to infinite mode
                                request.upload.onprogress = (e) => {
                                    progress(e.lengthComputable, e.loaded, e.total);
                                };

                                // Should call the load method when done and pass the returned server file id
                                // this server file id is then used later on when reverting or restoring a file
                                // so your server knows which file to return without exposing that info to the client
                                request.onload = function () {
                                    if (request.status >= 200 && request.status < 300) {
                                        // the load method accepts either a string (id) or an object
                                        load(request.responseText);
                                        attComponent.setState({ profileImageUrl: JSON.parse(request.response).fileUrl });

                                    }
                                    else {
                                        // Can call the error method if something is wrong, should exit after
                                        error('oh no');
                                    }
                                };

                                request.send(formData);

                                // Should expose an abort method so the request can be cancelled
                                return {
                                    abort: () => {
                                        // This function is entered if the user has tapped the cancel button
                                        request.abort();

                                        // Let FilePond know the request has been cancelled
                                        abort();
                                    }
                                };
                            }
                        }}
                        oninit={() => this.handleInit()}
                        labelIdle='Drop attachment(s) here to upload or browse (.jpg, .jpeg, .png, .gif)'
                    >
                    </FilePond>
                </div>
            </div>
        );
    }

    createFooter = () => {
        return (
            <>
                <div className="card-actions">

                    <button onClick={() => this.onAttachToPost()} type="button">
                        <i className="ba-icn ba-icn-ok"></i>
                        Done
                        </button>
                    <button className="more-actions" type="button" onClick={this.onCancel}> Cancel </button>

                </div>
            </>
        )
    }

    onAttachToPost = () => {
        if(this.state.profileImageUrl == '') {
            this.toggleAttachmentsDrawer();
        } else {
            let url = this.props.profileThumbnailsCdnEndpoint + this.state.profileImageUrl
            this.props.onSubmitAttachment(this.state.profileImageUrl, url);
            this.toggleAttachmentsDrawer();
        }
    }

    onCancel = () => {
        let url = this.props.profileThumbnailsCdnEndpoint + this.props.userDetails.profileImageId
        this.props.onSubmitAttachment(this.props.userDetails.profileImageId, url);
        this.toggleAttachmentsDrawer();
    }

    handleInit = () => { }

    toggleAttachmentsDrawer = () => {
        this.setState({ profileImageUrl: '' });
        this.setState({
            showAttachmentsDrawer: !this.state.showAttachmentsDrawer
        });
    }

}
const mapStateToProps = (state, ownProps) => {

    try {
        let userDetails = state.User

        return {
            profileThumbnailsCdnEndpoint: state.AppSettings.imageCdnEndpoint,
            userDetails: userDetails
        };
    } catch (error) {
        console.log(error)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchUserRoles: userRoles => {
            dispatch({ type: "SET_USER_ROLES", roles: userRoles });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicUploadDrawer);
import React, { Component } from 'react';
import '../../../Layout/LogMaintenanceBotLayout.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import botService from '../../../../Services/BotService.js';
import * as _ from 'lodash';
import { Accordion, ButtonToolbar, Card, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Loader } from 'semantic-ui-react';
import translateService from '../../../../Services/TranslateService';
import { Tab, Tabs } from "react-bootstrap";
class QandA_Wizard extends React.Component {

    constructor(props) {
        super(props);
        this.refProblem = React.createRef();
        this.state = {
            isCustomThemeEnable: this.props.isCustomThemeEnable,
            theme: this.props.theme,
            style: this.props.style,
            tenantId: this.props.tenantId,
            stepFlow: [[0, "QandAList"], [1, "ProblemBox"], [2, "FinalSend"]],
            currentStep: 0,
            previousStep: 0,
            selectedQandAType: '',
            selectedQandATypeDisplay: '',
            selectedQandATypeNumber: 0,
            qandaList: [],
            pageSize: 5,
            pageNumber: 0,
            problem: '',
            problemEng: '',
            isNotificationSended:false,
            assignedPM:{name:'',email:'',contact:''},
            sendingNotification:false,
        }
    }


    componentDidMount() {
        this.LoadQandAForTenant();
        this.setTheme();
        if(this.props.fromQandAMessege){
            this.setState({currentStep :1});           
        }
    }


    componentDidUpdate() {
        if (this.props.isCustomThemeEnable) {
            this.createToggleBtnDynamicStyle();
            this.createOkBtnDynamicStyle();
            this.createActiveBtnDynamicStyle();
        }
    }


    applyTranslation(texts, sourceLng, targetLng) {
        const requestModel = {
            text: texts,
            targetCode:  (targetLng == "en-US" || targetLng =="en-GB")  ? "en" : targetLng,
            sourceCode: sourceLng
        };
        
        return translateService.translate(requestModel);
    }


    createToggleBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.color;
        const border = this.props.style.optionsStyle.border;
        let labelInsideToggleGroup = document.querySelectorAll('label.btn');

        for (var i = 0; i < labelInsideToggleGroup.length; i++) {
            labelInsideToggleGroup[i].style.color = color;
            labelInsideToggleGroup[i].style.border = border;
            labelInsideToggleGroup[i].style.background = '';
        }
    }


    createOkBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.color;
        let okIcons = document.querySelectorAll('span.ba-icn-ok');

        for (var i = 0; i < okIcons.length; i++) {
            okIcons[i].style.background = color;
        }
    }


    createActiveBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.activeBackground;
        let active = document.querySelectorAll('label.btn.active');
        for (var i = 0; i < active.length; i++) {
            active[i].style.background = color;
        }
    }


    LoadQandAForTenant = () => {
        var organizationId = this.props.organizationId;
        const { i18n } = this.props;
        botService.getQandAForTenant(organizationId.trim()).then(result => {
            if (result && result.qandAListModel.length > 0) {
                var questionList = [];
                var answerList = [];
                questionList = result.qandAListModel.map(function (value) { return value['question']; });
                answerList = result.qandAListModel.map(function (value) { return value['answer']; });

                this.applyTranslation(questionList, "en", i18n.language).then(trans => {
                    if (trans.length > 0) {
                        result.qandAListModel.map(function (value, index) { return value.question = trans[index] });
                    }
                    this.applyTranslation(answerList, "en", i18n.language).then(transAns => {
                        if (transAns.length > 0) {
                            result.qandAListModel.map(function (value, index) { return value.answer = transAns[index] });
                        }
                        this.setState({ qandaList: result.qandAListModel });
                    });
                });
                this.handleQandATypeOnChange('General'); 
            }
        }, error => {
        });
    }


    addBodyStyle = () => {
        var element = document.body;
        element.style.cssText = 'overflow:hidden !important;';
        element.style.cssText = 'padding-top:0 !important; overflow:hidden !important; background: transparent !important;';
        document.documentElement.style.cssText = "background: transparent !important;";
    }


    setTheme = () => {
        const { style, theme, isCustomThemeEnable } = this.props;
        this.setState({
            style: style,
            theme: theme,
            isCustomThemeEnable: isCustomThemeEnable
        });
    }


    render() {
        const { t, i18n, theme } = this.props;
        const currentStepIndex = this.state.currentStep;
        let step = this.showCurrentStep(currentStepIndex);
        return <>
            <div className="">
                {step}
            </div>
        </>
    }


    showCurrentStep = (index) => {
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "QandAList":
                return this.getQandAList(index);
            case "ProblemBox":
                if(localStorage.getItem("is_bna_bot_session") == "TRUE" && (localStorage.getItem("userType") == 4)){
                    return this.addProblemBoxtWizard();
                }else{
                    return this.props.authenticate();
                }
                
            case "FinalSend":
                return this.addFinalSend();
        }
    }


    getQandAList = () => {
        let qandaList = this.createQandAList();
        const { t } = this.props;
        let value = 1;

        return <div className="wizard-inner  tenant-chat-bot">
            <div className="wizard-body">
            
                <div className='d-flex justify-content-start mb-4'>
                    <button type="button" className="btn btn-primary" onClick={() => this.props.backToMainMenu()} style={this.state.style.secondaryButtonStyle}>{t('new_ui_button_back')}</button>
                </div>
                <div className="wizard-header">
                    <h2>{t('new_ui_qna_lbl_header')} </h2>
                    
                        <div>
                          <div>
                            <div className="form-group common-chatbot-tabs">
                                <Tabs className="justify-content-center" activeKey={this.state.selectedQandAType} onSelect={(k) => this.handleQandATypeOnChange(k)}>
                                    <Tab eventKey="General" title={t('qna_lbl_opt_general')} style={{ overflowX: 'hidden' }}>
                                        <div>
                                            {qandaList}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Property" title={t('qna_lbl_opt_prop')} style={{ overflowX: 'hidden' }}>
                                        <div>
                                            {qandaList}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Maintainance" title={t('qna_lbl_opt_maintenance')} style={{ overflowX: 'hidden' }}>
                                        <div>
                                            {qandaList}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                          </div>
                    </div>

                </div>
            </div>
            
        </div>
    }


    handleQandATypeOnChange = (value) => {
        const { t } = this.props;
        let type = 0;
        let translationTag = '';
        if (value == 'General') {
            translationTag = 'qna_lbl_opt_general';
            type = 3;
        } else if (value == 'Property') {
            translationTag = 'qna_lbl_opt_prop';
            type = 2;
        } else if (value == 'Maintainance') {
            translationTag = 'qna_lbl_opt_maintenance';
            type = 1;
        }
        this.setState({ selectedQandAType: value, selectedQandATypeNumber: type, selectedQandATypeDisplay: t(translationTag)});
    }


    createQandAList = () => {
        let { qandaList, pageNumber, pageSize, selectedQandATypeNumber,theme } = this.state;
        let filterdList = _.filter(qandaList, function (o) { return o.qandAType == selectedQandATypeNumber });
        let count = filterdList.length - ((pageNumber + 1) * pageSize);
        let list = this.pageWiseList(filterdList, pageNumber, pageSize);
        let problemEventKey = 999;
        const { t } = this.props;
        return <><Accordion>
            {list.map((a, index) => {
                return <Card className="mb-2">
                    <Accordion.Toggle as={Card.Header} eventKey={index}>
                        {a.question}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index}>
                        <Card.Body>{a.answer}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            })
            }
        </Accordion>
            <div className="wizard-actions">
                {(count > 0) ? <button type="button" className="btn btn-primary mx-0" onClick={() => this.more()} style={this.state.style.primaryButtonStyle}>{t('qna_lbl_more_qna')}..</button> :
                    ''
                    //<div>
                    //   <div className='qna-link cursor-pointer' onClick={() => this.showQuestionView()}>{t('new_ui_qna_lbl_did_find_answer_q')}</div>
                    //</div>
                }
            </div>
        </>
    }


    addProblemBoxtWizard = () => {
        let { qandaList, pageNumber, pageSize, selectedQandATypeNumber,theme } = this.state;
        let filterdList = _.filter(qandaList, function (o) { return o.qandAType == selectedQandATypeNumber });
        let problemEventKey = 999;
        const { t } = this.props;

        return <div className="wizard-inner tenant-chat-bot">
            <div className="wizard-body">
                <div className='d-flex justify-content-between mb-4 mt-1'>
                    <button type="button" className="btn btn-primary" onClick={() => this.backToQandAList()} style={this.state.style.primaryButtonStyle}>{t('new_ui_button_back')}</button>
                </div>
                <div className="wizard-header">         
                    <h2>{t('new_ui_qna_lbl_type_prob')}</h2>
                </div>
                {(!this.state.isNotificationSended)?
                                     <>
                                     <div className="form-group">
                                        <textarea className="form-control form-textarea" placeholder={t('new_ui_qna_tt_type_prob')} aria-label="test"
                                        ref={this.refProblem} value={this.state.problem} onChange={(value) => this.handleOnChangeProblem(value)}>
                                        </textarea>
                                        <div className="wizard-actions d-flex align-items-center">
                                            <button type="button" className="btn btn-secondary w-50" onClick={() => this.backToQandAList()} style={this.state.style.primaryButtonStyle}>{t('new_ui_qna_button_cancle')}</button>
                                            <button type="button" className="btn btn-primary w-50" onClick={() => this.sendToPropertyManager()} style={this.state.style.primaryButtonStyle}>{t('new_ui_qna_button_send')}</button>
                                        </div>
                                     </div>
                                     {(this.state.sendingNotification) ? <div className='d-flex justify-content-center'><Loader type="ThreeDots" color={(theme.primaryColor != "") ? theme.primaryColor : "#DB3D44"} height={10} width={100} /></div> : ''}
                                     </>:
                                     <>
                                                <p className="userTip"><span className="font-500">{t('qna_lbl_pm_details')}</span></p>
                                                <p className="userTip">{t('qna_lbl_assigned_pm_name')} - <span className="font-500">{this.state.assignedPM.name}</span></p>
                                                <p className="userTip">{t('qna_lbl_assigned_pm_email')} - <span className="font-500">{this.state.assignedPM.email}</span></p>
                                                <p className="userTip">{t('qna_lbl_assigned_pm_contact_no')} - <span className="font-500">{this.state.assignedPM.contact}</span></p>
                                     </>}
            </div>
        </div>
    }


    addFinalSend = () => {
        const { t } = this.props;
        return <div className="wizard-inner tenant-chat-bot">
            <div className="wizard-body">
                <div className="wizard-header">
                    <div className="popup-contents d-flex flex-column align-items-center mt-3">
                        <span className='ba-icn ba-icn-ok popup-icon success'></span>
                        <h1 className='mt-4'>Sent!</h1>
                    </div>
                    <h2>{t('new_ui_qna_lbl_type_prob_answer')}</h2>
                </div>
            </div>
            <div className="wizard-actions d-flex align-items-center">
                <button type="button" className="btn btn-primary mx-0" onClick={() => this.props.backToMainMenu()} style={this.state.style.secondaryButtonStyle}>{t('new_ui_qna_lbl_back_to_main_chat')}</button>
            </div>
        </div>
    }


    showQuestionView = () => {
        this.setState({ currentStep: 1 });
    }
    

    more = () => {
        let number = this.state.pageNumber + 1;
        this.setState({ pageNumber: number });
    }


    backToQandAList = () => {
        this.setState({ currentStep: 0 });
    }

    pageWiseList = (filterdList, pageNumber, pageSize) => {
        let total = filterdList.length;
        if (total <= pageSize) {
            return filterdList;
        } else {
            let rest = total - (pageNumber * pageSize);
            if (rest > 0 && rest >= pageSize) {
                return _.take(_.drop(filterdList, (pageNumber * pageSize)), pageSize);
            } else if (rest > 0 && rest < pageSize) {
                return _.take(_.drop(filterdList, (pageNumber * pageSize)), rest);
            }
        }
    }


    handleOnChangeProblem = (value) => {       
        const typedProblem = this.refProblem.current.value;
        this.setState({problem : typedProblem});

    }


    sendToPropertyManager = () => {
        this.setState({sendingNotification:true});
        var tenantId = this.props.tenantId;
        let { problem } = this.state;
        const { i18n } = this.props;
        
        if(i18n.language !== 'en'){
        this.applyTranslation([problem], i18n.language, 'en').then((trans) => {
            this.setState({  problemEng: trans[0],currentStep: 2});
            this.sendTenantPostNewQandANotification(tenantId,trans[0]);
        });
    }else{
        this.setState({  problemEng: problem,currentStep: 2});
        this.sendTenantPostNewQandANotification(tenantId,problem);
    }
    
     
    }


    sendTenantPostNewQandANotification=(tenantId,question)=>{
        botService.postNewQandA(tenantId,question).then(res => { 
                             setTimeout(() => {
                     this.setState({isNotificationSended:true,sendingNotification:false});
                }, 1000);
        }, error => {
        });
    }
}

export default connect()(QandA_Wizard);


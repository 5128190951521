import React from 'react';
import { AppConfiguration } from "read-appsettings-json";
import { Link } from 'react-router-dom';

export default class ExternalDownloadPage extends React.Component {
    render() {
        return <div className="app-download-wrapper">
            <Link className="clearfix app-download-close" to={`/external/overview?code=${window.jobId}`}>
                <i className="ba-icn ba-icn-close float-right"></i>
            </Link>

            <h1>Simplified property
                <span className="font-600">maintenance + inspections</span>
                today</h1>
            <p>Post a maintenance request in less than 60 seconds + hold a remote inspection all from your smartphone.</p>
            <p className="font-600">Get started for free today.</p>
            <div className="download-app-links">
                <a href={AppConfiguration.Setting().AppStoreUrl}>
                    <img src="/images/app-store-badge.png" />
                </a>
                <a href={AppConfiguration.Setting().PlayStoreUrl}>
                    <img src="/images/google-play-badge.png" />
                </a>
            </div>
            <div className="bg-fixer">
                <img src="/images/app-download-bg.jpg" />            
            </div>
        </div>
    }
}
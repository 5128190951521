import React, { Component } from "react";
import { NavDropdown } from "react-bootstrap";
import { AppConfiguration } from "read-appsettings-json";
import { connect } from 'react-redux';
import { UserType } from "../../Constants/Enums";
import chatService from "../../Services/ChatService";
import SwitchRoleDrawer from "../Drawers/SwitchRoleDrawer/SwitchRoleDrawer";
import userService from "../../Services/UserService";
import * as _ from 'lodash';

 
class ProfileDropDown extends Component {

    state = {
        showSwitchRoleDrawer: false,
        roles: [],
        selectedUserRole: {},
        groupedRoles: []
    }

    toggleSwitchRoleDrawer = () => {
        if(this.state.showSwitchRoleDrawer){
            this.props.closeNav();
        }

        this.setState(state => ({
            showSwitchRoleDrawer: !this.state.showSwitchRoleDrawer
        }));
    };

    componentDidMount() {
        this.getUserRoles();
    }

    getUserRoles = () => {
        userService.getUserRoles().then(response => {
            
            let selectedRole = (response.length == 1) ? response[0] : response.find(a => a.isSelected);
            if (selectedRole == undefined) {
                selectedRole = response[0]
            }
            localStorage.setItem('orgId', selectedRole.organizationId);
            this.props.dispatchUserRoles(response);
            localStorage.setItem('userRoles', response);
            let roles = _.groupBy(response, (e) => {
                return e.organizationId
            })
            console.log(roles);

            this.setState({
                selectedUserRole: selectedRole,
                roles: response,
                groupedRoles: roles,

            });
        })
       
    }

    closeNavigation = (e) => {
        e.preventDefault();
        this.props.closeNav();
        window.location.href = e.target.href;
    }

    render() {

        let item = <img
            src="/Images/default-profile.png"
            alt="user-image"
            className="user-image"
            onError={(e) => { e.target.onerror = null; e.target.src = "/Images/default-profile.png" }}
        ></img>
        let subsString = this.props.userDetails.profileImageUrl.search("/null");

        if (this.props.userDetails.profileImageUrl != null && subsString == -1 && this.props.userDetails.profileImageUrl.trim() != "") {

            item = <img
                src={this.props.userDetails.profileImageUrl}
                alt="user-image"
                className="user-image"
                onError={(e) => { e.target.onerror = null; e.target.src = "/Images/default-profile.png" }}
            ></img>
        }

        return (
            <React.Fragment>
                <NavDropdown title={item} id="basic-nav-dropdown">
                    {(this.props.userDetails.userType == UserType.Tenant) ?
                        <NavDropdown.Item onClick={this.closeNavigation} href="/tenant/tenant-profile">Profile</NavDropdown.Item>
                        : <NavDropdown.Item onClick={this.closeNavigation} href="/owner/tenant-profile">Profile</NavDropdown.Item>
                    }
                    {(this.state.roles.length > 1) ? <NavDropdown.Item onClick={this.toggleSwitchRoleDrawer}>Switch Role</NavDropdown.Item> : ''}
                    {(localStorage.getItem('isSuperAdminAsOtherUser') === "true") ? <NavDropdown.Item onClick={this.onClickSwitchBackToSuperAdmin}>Switch back User</NavDropdown.Item> : ''}
                    {(localStorage.getItem('isSuperAdminAsOtherUser') == null || localStorage.getItem('isSuperAdminAsOtherUser') === "false") ? <NavDropdown.Item onClick={this.onClickLogout}>Logout</NavDropdown.Item> : ''}
                </NavDropdown>
                {(this.state.roles.length > 1) ?
                    <SwitchRoleDrawer
                        toggleSwitchRoleDrawer={this.toggleSwitchRoleDrawer}
                        selectedUserRole={this.state.selectedUserRole}
                        groupedRoles={this.state.groupedRoles}
                        showSwitchRoleDrawer={this.state.showSwitchRoleDrawer}
                        roles={this.state.roles}>
                    </SwitchRoleDrawer> : ''
                }

            </React.Fragment>
        );
    }

    onClickLogout = () => {
        this.props.closeNav();
        chatService.updateUserActiveStatus(localStorage.getItem("userIdDecripted"), false);
        let endPoint = AppConfiguration.Setting().BAndAApiEndPoint;
        var returnUrl = "";
        if(localStorage.getItem('styleCode').toUpperCase()==="JLL"){
            returnUrl =  AppConfiguration.Setting().JllBaseUrl;
            endPoint = AppConfiguration.Setting().JllBaseUrl;
        }
        localStorage.setItem('userId', null);
        localStorage.setItem('styleCode',null);
        endPoint = endPoint.charAt(endPoint.length - 1) == '/' ? endPoint : `${endPoint}/`;
        let redirectUrl = (returnUrl!==null)?'?returnUrl='+returnUrl:"";
        window.location.href = endPoint+'tenant-api/log-out'+redirectUrl;
    }

    onClickSwitchBackToSuperAdmin = () => {
        this.props.closeNav();
        localStorage.removeItem("isSuperAdminAsOtherUser");
        let adminId = localStorage.getItem("ImpersonatingUserId");
        localStorage.removeItem("ImpersonatingUserId");

        let endPoint = AppConfiguration.Setting().SuperAdminEndpoint;
        endPoint = endPoint.charAt(endPoint.length - 1) == '/' ? endPoint : `${endPoint}/`;

        window.location.href = endPoint + 'Admin/User/SwitchBackToSuperAdmin?id=' + adminId;
    }


}

const mapStateToProps = (state, ownProps) => {

    try {
        let userDetails = state.User

        return {
            profileThumbnailsCdnEndpoint: state.AppSettings.imageCdnEndpoint,
            userDetails: userDetails
        };
    } catch (error) {
        console.log(error)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchUserRoles: userRoles => {
            dispatch({ type: "SET_USER_ROLES", roles: userRoles });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropDown);

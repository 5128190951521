import { AXIOS } from '../Utilities/HttpRequest/Axios';
import { AppConfiguration } from "read-appsettings-json";

const saveScheduleJob = (scheduleData) => {
    return AXIOS.POST(`tenant-api/save-schedule-job?userId=${localStorage.getItem('userId')}`, scheduleData);
}

const saveExternalScheduleJob = (scheduleData) => {
    return AXIOS.POST(`tenant-api/save-external-schedule-job`, scheduleData);
}

const scheduleService = {
    saveScheduleJob,
    saveExternalScheduleJob
}

export default scheduleService;
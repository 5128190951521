import React from 'react';
import Loader from "react-loader-spinner";
import { Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import externalService from '../../../Services/ExternalService';
import { connect } from 'react-redux';
import { registerPlugin, FilePond } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { withTranslation, Trans } from 'react-i18next';
import { Navbar } from "react-bootstrap";
import AttachmentsDrawer from '../../Drawers/AttachmentsDrawer/AttachmentsDrawer';
import utilityService from '../../../Services/UtilityService';
import jobService from '../../../Services/JobService';
import propertyService from '../../../Services/PropertyService';
import Disclaimer from '../../Drawers/JobPostDrawer/Disclaimer/Disclaimer';
import { set } from 'react-ga';
import './AddJobAttachments.scss';





registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
class AddJobAttachments extends React.Component {

    state = {
      
        isLoading: false,
        attachments: [],
        showChatHelper: false,
        navExpanded: false,
        showSuccessMsg:false,
        jobTitle:"",
        jobState: 0,
        isLoading:false,
        jobDisclaimer:null,
        enableJobPostDisclaimer:false,
        isDrawerOpen :false,
        isEnableSave:false,
        postedOn:"",
        jobNumber:"",
        jobId:"",
        serviceConsumerId:"",

    }
    

    constructor(props) {
        super(props);
    }
    componentDidMount(){
        const { jobId} = this.props;
        //this.getJobDetails(jobId)
        document.addEventListener('load', () => {
            // Create Stripe instance once Stripe.js loads
            
        });
       
            this.getJobDetails(jobId)
        
           
        }
       
    
    componentDidUpdate() {
        const { jobId} = this.props;
    }
   
    getJobDetails = (jobId) =>{

        jobService.GetJobDetailsForExternalUsers(jobId,"").then((response) => {
            if(response)
            {
               
                this.setState({
                    jobTitle:response.title,
                    jobState:response.workflowStatusId,
                    isLoading:true,
                    enableJobPostDisclaimer:response.isEnableTenantJobPostDisclaimer,
                    jobDisclaimer:response.tenantJobPostDisclaimer,
                    serviceConsumerId:response.encryptedServiceConsumerId,
                    postedOn:response.postedOn,
                    jobNumber:response.jobNumber,

                })

            }
            else{
                    
            }
        })

       
        
    }

    
    
    render() {
        let uplorder = this.getAttachments();
        let loding = this.state.isLoading
       
        const { isLoading, attachments } = this.state;
        return (<div>
            { this.state.isLoading  ? (<div className="panel-body">
            <div className="main-menu fixed-top">
          <div className="container">
          <Navbar  className="top-nav-bar p-0"  expand="xl">
            <a className="navbar-brand" href="#"><img src="/images/app-logo.svg" alt="Bricks + Agent" className="logo" /></a>           
          </Navbar>
          </div>
         
        </div>  
        <br></br>    
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {
            this.state.jobState != 1 ?(<div className="success-wizard justify-content-center">
                <img src="/Images/expired-icon.svg" className="exp-img" alt="expired-icon" />
                <h2>Sorry, the link is expired. Please contact the PM.</h2>
                </div>) :(<div>
                
        {!this.state.showSuccessMsg ? (
            <div>
            <div className="job-title-header">
                <h3 className='d-flex justify-content-center'>This job drafted via SMS  {this.timeAgo(this.state.postedOn)}</h3>
                <h6 className='d-flex justify-content-center'>{this.state.jobTitle}</h6>
        </div>
      
<div className="jobpost-wizard" >
{uplorder}
 </div></div>
        ):
        (
            <div className="success-wizard justify-content-center" >
                    <img src="/Images/success-icon.svg" alt="success-icon" />
                     <h2>Successfully logged your maintenance issue!</h2>
                    <h6>Reference Number: {this.state.jobNumber}</h6>
                    <p>This maintenance request was posted to the agency {this.timeAgo(this.state.postedOn)}.<br></br>You will receive an email/SMS with a link to track your maintenance issue. </p>
 </div>
        )} 
            </div>)
        }
        
       
        
       
    </div>):(<div className="d-flex justify-content-center"><Loader type="ThreeDots" color="#DB3D44" height={10} width={100}  /></div>)

            }</div>
            
        )
    }

    getAttachments = () => {
        const { isShowAttchmentDrawer, isJobForMe } = this.state;
        const { t } = this.props;
        const styleflex = {
            flexDirection: "column !important",
        }
        return (
            <>
                <div>
                <div className="form-group">
                    <div className="wizard-inner">
                        <div className='wizard-body'>
                            <div className="wizard-header">
                            <h2>Upload Photos & Videos {!this.state.isEnableSave && <span>(required)</span>} </h2>
                                {this.state.isEnableSave ? <p>It is optional but uploading images or videos helps understand the issue better.</p> : <p>You can attach photos or videos related to the job here.</p>}
                            </div>
                            <div className="form-group d-flex">
                                <div className="img-upld-cont w-100">
                                    <AttachmentsDrawer
                                        jobDetails={{ id: this.props.jobId, attachments: this.state.attachments ,serviceConsumerId: this.state.serviceConsumerId}}
                                        onSubmitAttachments={(event) => { if(event.length >0)this.setState({ attachments: event ,isEnableSave:true})}}
                                        isJobPost={true}
                                        
                                        isExternal={true}
                                        t={t}>
                                    </AttachmentsDrawer>
                                    {(this.state.attachments) ?
                                        <ImageViewer attachments={this.state.attachments}  t={t}></ImageViewer> : ''
                                    }
                                </div>
                            </div>
                            
                            <div className='d-flex flex-column flex-md-row justify-content-end'>
                                    <div className="wizard-actions col-12 col-md-6 px-0 justify-content-end">
                                      <button type="button" className="btn mb-0 mx-0 d-flex align-items-center justify-content-center" disabled={!this.state.isEnableSave} onClick={ ()=> this.checkSettings()}>Save</button>                                        
                                </div>
                                {this.state.isDrawerOpen && (<Disclaimer
                                        showDrawer = {true} 
                                        disclaimer = {this.state.jobDisclaimer}
                                        jobPostWorkflowStatus = {this.state.jobPostWorkflowStatus}
                                        onAgreedForDisclaimer = {() => this.save()}
                                        onCloseDisclaimer = {() => this.closeDisclaimer()}
                                        isFromBot={false}
                                        ></Disclaimer>

                               )} 
                            </div>
                        </div>
    
                    </div>
                </div>
                </div>
            </>);
    }
    checkSettings = () =>{
        if(this.state.enableJobPostDisclaimer == true)
        {
            this.setState({
                isDrawerOpen :true
               });
        }
       else{
        this.save();
       }
    }

closeDisclaimer = () => {
    this.setState({ 
        isDrawerOpen: false,
    });
}

    save = () =>{
        
        
    try {
        // const model = {
        //     userId: this.props.serviceConsumerId,
        //     jobId: this.props.jobId,
        //     attachments: this.state.attachments
        // }
  
        utilityService.saveAttachments(this.state.attachments).then(response => {

                if (response) {
                    this.setState({ showSuccessMsg:true }) 
                } else {
                    // toast.warn('', {
                    //     position: toast.POSITION.BOTTOM_RIGHT
                    // });
                }
            });
    } catch (error) {
        console.log(error);
    }

        
    }
    timeAgo(value) {
        const { t } = this.props;
        if (!value) return;
        let d = new Date(value);
        let now = new Date();
        let seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));

        let minutes = Math.round(Math.abs(seconds / 60));
        let hours = Math.round(Math.abs(minutes / 60));
        let days = Math.round(Math.abs(hours / 24));
        let months = Math.round(Math.abs(days / 30.416));
        let years = Math.round(Math.abs(days / 365));
        if (Number.isNaN(seconds)) {
            return '';
        } else if (seconds <= 45) {
            return t('new_ui_lbl_fewSeconds');
        } else if (seconds <= 90) {
            return t('new_ui_lbl_minute');
        } else if (minutes <= 45) {
            return minutes + t('new_ui_lbl_minutes');
        } else if (minutes <= 90) {
            return t('new_ui_lbl_hour');
        } else if (hours <= 22) {
            return hours + t('new_ui_lbl_hours');
        } else if (hours <= 36) {
            return t('new_ui_lbl_day');
        } else if (days <= 25) {
            return days + t('new_ui_lbl_days');
        } else if (days <= 45) {
            return t('new_ui_lbl_month');
        } else if (days <= 345) {
            return months + t('new_ui_lbl_months');
        } else if (days <= 545) {
            return t('new_ui_lbl_year');
        } else {
            // (days > 545)
            return years + t('new_ui_lbl_years');
        }
    }
    
}





export default connect()(withTranslation() (AddJobAttachments));
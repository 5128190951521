import React, { useState } from "react";
import { connect } from "react-redux";
import bidService from "../../../Services/BidService";
import Moment from "react-moment";
import ImageViewer from "../../Common/ImageViewer/ImageViewer";
import { toast } from 'react-toastify';
import "./quotesDetail.scss";
import StarRatingComponent from "react-star-rating-component";
import ProviderMiniProfileDrawer from '../../Drawers/JobPostDrawer/ProviderProfileDrawer/ProviderMiniProfileDrawer';
import QuoteAcceptRejectDrawer from "./QuoteAcceptRejectDrawer";
import DeclineQuotesDrawer from "../../Drawers/DeclineAllQuotesDrawer/DeclineAllQuotesDrawer";
import QuoteAcceptDrawer from "../../Drawers/QuoteAcceptDrawer/QuoteAcceptDrawer";
import Loader from "react-loader-spinner";
// Styles
//import "../OwnerApproval/JobApproveDrawer.scss";

class QuotesDetails extends React.Component {

    state = {
        jobDetails: {},
        quotes: [],
        userId: "",
        isTenantManageJob:false,
        isShowProviderDrawer:false,
        providerViewId:'',
        showAcceptRejectDrawer:false,
        isAcceptQuote:false,
        selectedbidId:'',
        selectedbidAmount:0,
        isLoading:false,
        propertyId:'',
        ownerApprovalCount:0
    };
    constructor(props) {
        super(props);

        this.getQuoteAmount = this.getQuoteAmount.bind(this);
        this.getQoutesByJobId = this.getQoutesByJobId.bind(this);
    }

    componentDidMount() {
        this.setState({ jobDetails: this.props.jobDetails,
             userId: this.props.userId,
             isTenantManageJob : (this.props.jobDetails && this.props.jobDetails.isTenantManageJob)?true:false});
        this.getQoutesByJobId();
    }

    render() {

        return (
            <>
             {(this.state.isLoading) ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : ''}
                {this.createJobQuoteBody()}
                {(this.state.isShowProviderDrawer) ?
                <ProviderMiniProfileDrawer userId={this.state.providerViewId} isShowProviderDrawer={this.state.isShowProviderDrawer} 
                onCloseProviderDrawer={this.onCloseProviderDrawer} consumerTypePropertyId={this.state.propertyId}>
                </ProviderMiniProfileDrawer> : ''}
                {(this.state.showAcceptRejectDrawer) ?
                <QuoteAcceptRejectDrawer amount={this.state.selectedbidAmount} bidId={this.state.selectedbidId} showDrawer={this.state.showAcceptRejectDrawer} isAcceptQuote={this.state.isAcceptQuote}
                onCloseDrawer={(event)=>this.onCloseDrawer(event)}>
                </QuoteAcceptRejectDrawer> : ''}
            </>
        );
    }

    createJobQuoteBody = () => {
        const { jobDetails } = this.props;
        return (
            (!this.state.isTenantManageJob)?
            <div className="panel-body">
                <span className="common-tag small badge-primary" style={{float:'none'}}>Quote Limit : {(jobDetails && jobDetails.quoteLimit != null) ? jobDetails.quoteLimit : "0"}</span>
                {(this.props.jobDetails.workflowStatusId == 6) ? <div className="alert alert-warning mt-3">Note: Please select the best quote for the job, if not decline all to request better quotes.</div> : ''}
                {this.getQuotesList()}
                {(!this.state.quotes || this.state.quotes == null) ? <span>Quotes not available</span> : ""}
                {
                    this.bidDeclineButton()
                }
            </div>
            :
            <div className="panel-body">
                {this.getQuotesListForTenantManageJobs()} 
            </div>
        );
    };

    bidDeclineButton=()=>{  
        let isReplyed = this.state.quotes.filter(a => a.quoteApprovalStatusId === 1 || a.quoteApprovalStatusId === 2).length > 0
        if (isReplyed || this.state.quotes.filter(a => a.jobStatus === 107 || a.jobStatus === 7).length > 0 || (this.props.jobDetails.workflowStatusId != 6 && this.state.ownerApprovalCount != 0)){
            return '';
        } else if(this.state.quotes.length > 0 && this.props.jobDetails.propertyId !== null && !this.state.jobDetails.isOwnerApprovalPendingForVariation) {
            return <>
                <DeclineQuotesDrawer
                    toggleDrawer={(event) => this.onCloseDrawer(event)}
                    jobDetails={this.props.jobDetails}
                ></DeclineQuotesDrawer>
            </>
        }else{
            return '';
        }
    }

    onDeclineAllQuotes = () => {
        bidService.declineAllBidsByOwner(this.props.jobDetails.id, "").then(res => {
            toast.success('All bids decline by owner', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            this.props.reload();
        });
    }

    getQuotesList = () => {
        const quotes = this.state.quotes.map((bid, index) => {
            return (
                <>
                    <div className="quote-card" key={index}>
                        <div className="quote-card-header">
                            <div className="trade-personal">
                                <label className="float-right">created on  <Moment format={"dd/MM/YYYY".toUpperCase()}>
                                    {bid.createdOn}
                                </Moment> { }</label>
                                <div className="trade-contact">
                                    <h3>{bid.name}</h3>
                                    <p>email: {bid.email}</p>
                                    <p>tel: {bid.phone}</p>
                                </div>
                            </div>
                            <div className="trade-info">
                                {this.getQuoteAmount(bid)}
                            </div>
                        </div>

                        <div>
                            <div className="mt-2">
                                {this.getSPNotes(bid.notes)}
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    {(bid.availabilityStart && bid.availabilityEnd) ?
                                        <div className="job-details-table-row">
                                            <div className="table-label">Schedule</div>
                                            <div className="table-data"><Moment format="DD/MM/YYYY">{bid.availabilityStart}</Moment> to <Moment format="DD/MM/YYYY">{bid.availabilityEnd}</Moment></div>
                                        </div>
                                        : ''}
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    {(bid.estimatedTimeDisplay) ?
                                        <div className="job-details-table-row">
                                            <div className="table-label">Estimated Time</div>
                                            <div className="table-data">{bid.estimatedTimeDisplay}</div>
                                        </div>
                                        : ''}
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    {(!this.isApprovedOrDeclined() &&  this.props.jobDetails.propertyId != null 
                                    && this.props.jobDetails.workflowStatusId!==107 && this.props.jobDetails.workflowStatusId!==7 
                                        && !this.state.jobDetails.isOwnerApprovalPendingForVariation && (this.props.jobDetails.workflowStatusId == 6 || this.state.ownerApprovalCount == 0)) ? <>
                                            <div className="float-right"> 
                                            <QuoteAcceptDrawer
                                                toggleDrawer={(event) => this.onCloseDrawer(event)}
                                                jobDetails={this.props.jobDetails}
                                                bidDetails={bid}
                                            ></QuoteAcceptDrawer>
                                        </div>
                                    </> : ''}
                                </div>

                                <div className="col-md-12 mt-2">
                                    {(bid.hasOwnProperty("attachments")) ?
                                        <ImageViewer attachments={bid.attachments} ></ImageViewer> : ''
                                    }
                                </div>
                            </div>


                        </div>

                    </div>
                    <hr></hr>
                </>
            );
        });
        return quotes;
    };


    getQuotesListForTenantManageJobs =()=>{
        const quotes = this.state.quotes.filter(m=>m.status!==103).map((bid, index) => {
            return (
                <>
                    <div className="quote-card p-3 mb-3" key={index} style={{background:'#f4f4f5'}}>
                        <div className='row mb-2 font-500 ml-2'>
                            <div className='col-md-2' onClick={() => this.providerProfileView(bid.providerProfile.userId)} style={{ padding: '10px' }}>
                                <img style={{ borderRadius: '50%',cursor:"pointer" }} src={bid.providerProfile.profileImageUrl?bid.providerProfile.profileImageUrl:'/images/default-profile.png'} width="100" height="100" />
                            </div>
                            <div className='col-md-4'>
                                <div style={{ textAlign: 'left', marginTop: '15%' }}>
                                    <label><strong>{bid.providerProfile.name} {(bid.providerProfile.businessName) ? " | " + bid.providerProfile.businessName : ""}</strong></label>
                                    <div className="user-contact">
                                        <div>
                                            <StarRatingComponent
                                                name=""
                                                editing={false}
                                                starCount={5}
                                                value={bid.providerProfile.overallRating}
                                            />
                                        </div>
                                    </div>
                                    <label>Jobs Completed : <strong>{bid.providerProfile.completedJobsCount}</strong></label>
                                </div>
                            </div>
                            <div className='col-md-3'>

                            </div>
                            {(bid.amount && bid.amount > 0) ?
                                <div className='col-md-3'>
                                    <div style={{ textAlign: 'center', marginTop: '20%' }}>
                                        <label> Quote amount</label>
                                        <label style={{ display: 'block' }}> <h2>${bid.amount ? bid.amount.toFixed(2) : 0.00}</h2></label>
                                    </div>
                                </div>
                            :""}
                            {(bid.callOutFee>0 ||bid.hourlyRate>0) ?
                                <div className='col-md-3'>
                                    <div style={{ textAlign: 'center', marginTop: '20%' }}>
                                        <label> Call out fee</label>
                                        <label style={{ display: 'block' }}> <h2>${bid.callOutFee ? bid.callOutFee.toFixed(2) : 0.00}</h2></label>
                                    </div>
                                    <div style={{ textAlign: 'center', marginTop: '20%' }}>
                                        <label> Hourly rate</label>
                                        <label style={{ display: 'block' }}> <h2>${bid.hourlyRate ? bid.hourlyRate.toFixed(2) : 0.00}</h2></label>
                                    </div>
                                </div>
                            :""}
                            
                            

                            <div className="col-md-12 mt-2 mb-2">
                                <label>{bid.notes}</label>
                            </div>


                                <div className="row col-md-12">
                                <div className="col-md-3">
                                    <label> Esimated time</label>
                                    <label style={{ display: 'block' }}> <strong>{this.timeConvert(bid.estimatedHours)}</strong></label>
                                </div>
                                <div className="col-md-9">
                                    {(bid.hasOwnProperty("attachments")) ?
                                        <ImageViewer attachments={bid.attachments} ></ImageViewer> : ''
                                    }
                                </div>
                                </div>
                            

                            {bid.status === 101? <div className="col-md-12">
                                <div className="float-right">
                                    <button className="btn btn-outline-primary quote-action-accepted" style={{background:''}} title="Accept" onClick={() => { this.approveQuote(bid.bidId,bid.amount) }}>Accept</button>
                                </div>
                                <div className="float-right">
                                    <button className="btn btn-outline-danger mr-3 quote-action-reject" title="reject" onClick={() => { this.rejectQuote(bid.bidId,bid.amount) }}>Reject</button>
                                </div>
                            </div>:""}

                        </div>
                    </div>
                </>
            );
        });
        return quotes;
    }

    approveQuote =(id,amount)=>{
            this.setState({showAcceptRejectDrawer:true,selectedbidId:id,selectedbidAmount:amount,isAcceptQuote:true});
    }

    rejectQuote =(id,amount) =>{
        this.setState({showAcceptRejectDrawer:true,selectedbidId:id,selectedbidAmount:amount,isAcceptQuote:false});
    }


     timeConvert=(n)=> {
         if(n){
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            return  rhours + " h " + rminutes + " m";
         }else{
            return "N/A";
         }
        }

    getQuoteAmount(bid) {
        if (bid.placeBidType) {
            if (bid.placeBidType === 1 || bid.placeBidType === 3) {
                return (
                    <div className="quoted-amount">
                        <div>Quoted Amount</div>
                       <div className="quote-value">${(bid.priceRevised > 0) ? bid.priceRevised.toFixed(2) : bid.amount.toFixed(2)} </div>
                    </div>
                );
            } else if (bid.placeBidType === 2) {
                return (
                    <div className="quoted-amount">
                        <div className="quote-type-min-max">
                            <table>
                                <tr>
                                    <td className="text-center"><label className="form-label mb-0">Min</label></td>
                                    <td className="text-center pl-2"><label className="form-label mb-0">Max</label></td>
                                </tr>
                                <tr>
                                    <td className="text-center"><div className="quote-value">${bid.amount.toFixed(2)}</div></td>
                                    <td className="text-center pl-2"><div className="quote-value">${bid.amountMax.toFixed(2)}</div></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                );
            } else if (bid.placeBidType === 4) {
                return (
                    <div className="quoted-amount">
                        <div className="quote-type-min-max">
                            <table>
                                <tr>
                                    <td className="text-center"><label className="form-label mb-0">Call out fee</label></td>
                                    <td className="text-center pl-2"><label className="form-label mb-0">Hourly rate</label></td>
                                </tr>
                                <tr>
                                    <td className="text-center"><div className="quote-value">${bid.callOutFee}</div></td>
                                    <td className="text-center pl-2"><div className="quote-value">${bid.hourlyRate}</div></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                );
            }
        }
    }

    getQoutesByJobId = () => {
        const { userId, jobDetails } = this.props;
        var id = (userId != null) ? userId : localStorage.getItem("exernalUserId");
        if (jobDetails) {
            try {
                this.setState({isLoading:true});
                bidService.getQoutesByJobId(jobDetails.id, id)
                    .then(data => {
                        this.setState({
                            quotes: data.bidsList,
                            propertyId:data.propertyId,
                            isLoading:false,
                            ownerApprovalCount : data.ownerApprovalCount
                        });
                    })
            } catch (error) {
                console.log(error);
            }
        }
    };


    getSPNotes = notes => {
        if (notes != null && notes.trim() != "") {
            return <div className="quote-description">Note: {notes}</div>;
        }
    };

    getInvoiceAttachments = (bid) => {
        if (bid.job != null && bid.job.attachments != null) {
            let invoiceAttachments = bid.job.attachments.filter(a => a.type == 6 && a.userId == bid.providerId)
            return (
                <div className="">
                    <ImageViewer attachments={invoiceAttachments} isInvoice={true}></ImageViewer>
                </div>
            );
        }
    }

    isApprovedOrDeclined = () => {
        return this.state.quotes.filter(a => a.quoteApprovalStatusId == 1 || a.quoteApprovalStatusId == 2).length > 0
    };

    onClickApproveBid = (bid) => {
      
        bidService.approveBidByOwner(this.props.jobDetails.id, bid.bidId, "").then(res => {
            toast.success('Bid accepted by owner', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            this.props.reload();
        })
    };


    providerProfileView =(id)=> {
        this.setState({providerViewId: id,isShowProviderDrawer:true});
    }
    onCloseProviderDrawer = ()=>{
        this.setState({isShowProviderDrawer: false});
    }

  

    onCloseDrawer = (event)=>{
     
        if(event){
            this.getQoutesByJobId();
            this.props.reload();
        }
        this.setState({showAcceptRejectDrawer: false});
    }
}



export default connect(
)(QuotesDetails);

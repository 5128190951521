import React from 'react';
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ImageViewer from '../../../Common/ImageViewer/ImageViewer';
import { AXIOS } from "../../../../Utilities/HttpRequest/Axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import jobService from '../../../../Services/JobService';
import { Guid } from 'guid-typescript';
import DatePicker from "react-datepicker";
import { Search, Label } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import * as _ from 'lodash';
import 'semantic-ui-css/semantic.min.css'
import './TenantManageJobPostWizard.scss';
import propertyService from '../../../../Services/PropertyService';
import AttachmentsDrawer from '../../../Drawers/AttachmentsDrawer/AttachmentsDrawer';
import { AppConfiguration } from 'read-appsettings-json';
import { trackPromise } from 'react-promise-tracker';
import { Paginator } from '../../Paginator/Paginator';
import Payment from '../../../Tenant/Settings/Payment/Payment';
import settingsService from "../../../../Services/settingsService";
import { Dropdown } from 'semantic-ui-react';
import '../../../Tenant/Settings/Settings.scss';
import ProviderMiniProfileDrawer from '../../../Drawers/JobPostDrawer/ProviderProfileDrawer/ProviderMiniProfileDrawer';
import Drawer from '../../Drawer/Drawer';
import moment from 'moment';
import Pagination from 'rc-pagination';
import "rc-pagination/assets/index.css";

class TenantManageJobPostWizard extends React.Component {

    constructor(props) {
        super(props);
        this.title = React.createRef();
        this.jobDescription = React.createRef();
        this.budgetRef = React.createRef();
        this.issueAreaInProperty = React.createRef();
        this.contactNumber = React.createRef();
        this.contactPerson = React.createRef();
        this.placeToCome = React.createRef();
        let userId = localStorage.getItem("userId");
        this.getCardDetailsByUserId(userId);
        this.checkCardAvailability = this.checkCardAvailability.bind(this);

    }
    notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    state = {
        userType: localStorage.getItem("userType"),
        userId: localStorage.getItem("userId"),
        stepFlow: [[0, "checkCardDetailsAvailability"], [1, "selectProperty"], [2, "selectServiceType"], [3, "getMaintenaceIssue"], [4, "getContactDetails"], [5, "selectUgency"], [6, "selectBudget"], [7, "selectRecurringType"], [8, "getAttachments"], [9, "getTrades"]],
        currentStep: 0,
        formData: {
            address: '',
            location: '',
            propertyId: '',
            propertyName: '',
            suburbId: this.props.formData ? this.props.formData.suburbId : '',
            propertyType: '',
            jobBudget: 0,
            jobDescription: '',
            issueAreaInProperty: '',
            jobTitle: '',
            placeToCome: '',
            contactPerson: this.props.userData ? this.props.userData.name : '',
            contactNumber: this.props.userData ? this.props.userData.phone : '',
            latitude: '',
            longitude: ''
            
        },
        selectedService: { childJobTypes: [] },
        selectedServiceType: {
            parentJobType: { id: '' }
        },
        jobDetails: { attachments: [] },
        attachments: [],
        jobId: '',
        dueDate: '',
        jobUrgency: '',
        propertyList: [],
        value: '',// job service type
        isLoading: false,
        filteredResults: [],
        filteredTradeesResults: [],
        allTrades: [],
        propertyId: '',
        isPropertiesLoading: false,
        parentJobTypes: [],
        primaryJobTypes: [],
        isShowMoreServices: false,
        locationDetails: {
            latitude: '',
            longitude: '',
            placeId: '',
            suburbId: '',
            location: ''
        },
        jobBudget: "",
        selectedTradees: [],
        isSelectedOnDemand: false,
        isJobPosting: false,
        isSubServiceTypeSelected: false,
        parentId: '',
        childJobTypeId: '',
        budgetAmount: 0,
        isPropertySelected: false,
        title: '',
        primaryButtonStyle: (this.props.customStyle && this.props.customStyle.primaryButtonStyle) ? this.props.customStyle.primaryButtonStyle : {
            color: ''
        },
        secondaryButtonStyle: (this.props.customStyle && this.props.customStyle.secondaryButtonStyle) ? this.props.customStyle.secondaryButtonStyle : {
            color: ''
        },
        optionsStyle: (this.props.customStyle && this.props.customStyle.optionsStyle) ? this.props.customStyle.optionsStyle : {
            color: ''
        },
        tradeeCount: 0,
        previousStep: 0,
        propertyType: "Residential",
        organizationId: "",
        valueOfTradeSearch: '',
        isTradeLoading: false,
        total: 0,
        sizePerPage: 5,
        page: 1,
        tradeSearchtext:'',
        selectedProviderId: [],
        providerViewId: '',
        isShowProviderDrawer: false,
        displayTradesArray: [],
        recurringType: "0",
        fromDate: '',
        toDate: '',
        isCardAvailable: true,
        isCardLoading: true,
        minBudgetAmount: 0,
        maxBudgetAmount: 0,
        recurringCustomType: 0,
        tradeRequestType: '',
        quoteRequestLimit: 10,
        isHoldAmountFromTenant: false,
        holdAmountFromTenantPerJob: 0,
        selectedParent: {},
        secondaryJobTypes: [],
        subChildJobTypeId: ''
    }

    componentDidMount() {
        this.setState({
            jobId: Guid.create().toString(),
            jobDetails: {
                id: this.state.jobId
            }
        });

        this.getAvailableServices();
        this.getAllAvailableJobTypes();
        this.GetTenantPropertiesByUserId();
        this.getUserSettings();
    }

    componentDidUpdate() {
        if (this.props.isCustomThemeEnable) {
            this.createToggleBtnDynamicStyle();
            this.createOkBtnDynamicStyle();
            //this.createActiveBtnDynamicStyle();
            this.attchmentBarStyle();
        }
    }

    //Styles
    createToggleBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        const border = this.props.customStyle.optionsStyle.border;
        let labelInsideToggleGroup = document.querySelectorAll('label.btn');

        for (var i = 0; i < labelInsideToggleGroup.length; i++) {
            labelInsideToggleGroup[i].style.color = color;
            labelInsideToggleGroup[i].style.border = border;
            labelInsideToggleGroup[i].style.background = '';
        }
    }

    createOkBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        let okIcons = document.querySelectorAll('span.ba-icn-ok');

        for (var i = 0; i < okIcons.length; i++) {
            okIcons[i].style.background = color;
        }
    }

    createActiveBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.activeBackground;
        let active = document.querySelectorAll('label.btn.active');
        for (var i = 0; i < active.length; i++) {
            active[i].style.background = color;
        }
    }

    attchmentBarStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        const border = this.props.customStyle.optionsStyle.border;
        let label = document.querySelector('.rich-control');
        if (label) {
            label.querySelector('label').style.color = color;
        }
        let boader = document.querySelector('div.form-control.rich-control');
        if (boader) {
            boader.style.border = border;
        }
    }

    showCurrentStep = (index) => {
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "checkCardDetailsAvailability":
                return this.checkCardDetailsAvailabilityStep(index);
            case "selectProperty":
                return this.selectPropertyStep(index);
            case "getMaintenaceIssue":
                return this.getMaintenaceIssue(index);
            case "selectServiceType":
                return this.selectServiceType(index);
            case "getContactDetails":
                return this.getContactDetails(index);
            case "selectUgency":
                return this.selectUgency(index);
            case "selectBudget":
                return this.selectBudget(index);
            case "selectRecurringType":
                return this.selectRecurringType(index);
            case "getAttachments":
                return this.getAttachments(index);
            case "getTrades":
                return this.getTrades(index);
            default:
                return this.nextStep(index);
        }
    }

    nextStep = (index) => {
        var currentStep = index;
        if (this.validate(index)) {
            if (currentStep === 8) {
                this.getTradesList();
            }
            else if (currentStep === 9) {
                this.checkTradeRequestType();
                return true;
            }
            currentStep = (index + 1);
        }
        else {
            return false;
        }
        this.setState({ currentStep: currentStep, previousStep: index });
    }

    previousStep = (index) => {
        var currentStep = index;
        currentStep = (index - 1);

        this.setState({ currentStep: currentStep, previousStep: index });
    }

    //get data
    GetTenantPropertiesByUserId = () => {
        this.setState({ isPropertiesLoading: true,propertyList:[],property:'' });
        let properties;
        let propertyNames = [];
        propertyService.GetTenantPropertiesByUserId()
            .then((res) => {
                properties = res.properties;
                if (res.properties.length > 0) {
                    properties.forEach(x => {
                        propertyNames.push(x.address ? x.address : " ");
                    });

                    this.setState({
                        propertyList: properties,
                        isPropertiesLoading: false,
                        property: properties.length === 1 ? properties[0] : ''
                    });
                    if(properties.length === 1){
                        this.getPropertyDetailsByPropertyId(properties[0].propertyId);
                    }                    
                }
                else {
                    this.setState({
                        propertyList: [],
                        propertyId: 0
                    });
                }
            });
    }

    getAllAvailableJobTypes() {
        let jobTypes = this.props.jobTypes;
        let parents = _.map(jobTypes, m => m.parentJobType);
        const parentJobTypes = _.filter(parents, f => f.parentRef === null);
        this.setState({ parentJobTypes: parentJobTypes });
    }

    getPropertyDetailsByPropertyId = (propertyId) => {
        const selectedProperty = this.state.propertyList.filter(a => a.propertyId == propertyId);
        if (selectedProperty && selectedProperty.length > 0 && selectedProperty[0].propertyId != null) {

            let formData = this.state.formData;
            formData.address = selectedProperty[0].address;
            formData.location = selectedProperty[0].location;
            formData.propertyId = selectedProperty[0].propertyId;
            formData.propertyName = selectedProperty[0].propertyName;
            formData.suburbId = selectedProperty[0].suburbId;
            formData.propertyType = selectedProperty[0].propertyType;
            formData.latitude = selectedProperty[0].latitude;
            formData.longitude = selectedProperty[0].longitude;
            this.setState({
                propertyId: propertyId,
                //locationDetails: {},
                formData: formData,
                locationDetails: selectedProperty[0],
            });
           
        } else {
            this.setState({
                propertyId: null,
                locationDetails: {}
            });
        }
    }


    handlePropertyChange = (event) => {
        var property = event.currentTarget.querySelector("input").value;
        this.setState({ property: property });
        this.getPropertyDetailsByPropertyId(property);
    }

    setLocationDetails = (event) => {
        this.setState({
            locationDetails: event,
            formData: {
                address: event.address
            }
        });
    }

    handleOnChangeJobDescription = () => {
        let formdata = this.state.formData;
        const typedDescription = this.jobDescription.current.value;
        formdata.jobDescription = typedDescription;
        this.setState({
            formdata: formdata
        });
    }

    handleOnChangeJobIssueArea = () => {
        let formdata = this.state.formData;
        const typedArea = this.issueAreaInProperty.current.value;
        formdata.issueAreaInProperty = typedArea;
        this.setState({ formdata: formdata });
    }

    handleOnChangeJobTitle = () => {
        let formdata = this.state.formData;
        const title = this.title.current.value;
        formdata.jobTitle = title;
        this.setState({
            formdata: formdata
        });
    }

    handleOnChangeContactNumber = () => {
        let formdata = this.state.formData;
        const number = this.contactNumber.current.value;
        formdata.contactNumber = number;
        this.setState({
            formdata: formdata
        });
    }

    handleOnChangeContactPerson = () => {
        let formdata = this.state.formData;
        const person = this.contactPerson.current.value;
        formdata.contactPerson = person;
        this.setState({
            formdata: formdata
        });
    }

    handleOnChangePlaceTocome = () => {
        let formdata = this.state.formData;
        const placeToCome = this.placeToCome.current.value;
        formdata.placeToCome = placeToCome;
        this.setState({
            formdata: formdata
        });
    }

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.label })
        this.getChildJobTypes(result.id);
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ filteredResults: [], value });
        if (value.length > 2) {
            this.setState({ isLoading: true })
            setTimeout(() => {
                document.querySelector('.jobpost-wizard .auto-complete').classList.remove("hide-results");
                this.setState({
                    primaryJobTypes: [],
                    isLoading: false,
                    filteredResults: this.state.parentJobTypes.filter(suggestion => suggestion.label.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1)
                })
            }, 300)
        } else if (value.length === 0) {//Reset selectd sub types
            let formData = this.state.formData;
            formData.childJobType = '';
            formData.childJobTypeId = '';
            this.setState({
                isSubServiceTypeSelected:false,
                isServiceTypeSelected: false,
                selectedService: '',
                childJobTypeId: '',
                selectedServiceType: {
                    parentJobType: { id: '' },
                    childJobTypes: []
                }
            });
            this.createTypeOfServicesButtons();
        }
        else {
            document.querySelector('.jobpost-wizard .auto-complete').classList.add("hide-results");
        }
    }

    resultRenderer = (event) => {
        return <Label key={event.label} content={event.label}></Label>
    }

    getChildJobTypes(parentId) {
        let jobTypes = this.props.jobTypes;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === parentId);

        if (this.state.selectedServiceType && this.state.selectedServiceType.parentJobType && _.find(selectedNode.childJobTypes, f => f.id === this.state.selectedServiceType.parentJobType.id)) {
            this.setState({
                primaryJobTypes: selectedNode.childJobTypes,
                selectedService: selectedNode
            });
            this.setState({ childJobTypeId: selectedNode });
        } else {
            let formData2 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: '',

            }
            this.setState({
                primaryJobTypes: selectedNode.childJobTypes,
                selectedService: selectedNode,
                secondaryJobTypes: [],
                selectedServiceType: {
                    parentJobType: { id: '' }
                },
                formData: formData2
            });
        }
    }

    getSubChildJobTypes(parentId) {
        this.setState({ childJobTypeId: parentId });
        this.setState({ isSubServiceTypeSelected: true });
        let jobTypes = this.props.jobTypes;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === parentId);

        if (selectedNode == undefined) {
            let selectedServiceType = {
                childJobTypes: [],
                parentJobType: _.find(this.state.primaryJobTypes, f => f.id === parentId)
            }
            let formData1 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: ''
            };
            this.setState({
                secondaryJobTypes: [],
                selectedServiceType: selectedServiceType,
                formData: formData1
            });

        } else {
            let formData2 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: ''
            };
            this.setState({
                secondaryJobTypes: selectedNode.childJobTypes,
                selectedServiceType: selectedNode,
                formData: formData2
            });
        }

    }

    onChangeChildTypeOfServices = (input) => {
        this.setState({ subChildJobTypeId: input });
        const childJobType = this.state.selectedServiceType.childJobTypes.filter(a => a.id === input)[0];
        let formData = this.state.formData;
        formData.childJobTypeId = childJobType.id;
        formData.childJobType = childJobType.title;
        this.setState({ formData: formData });
    }

    handleDueDateChange = (date) => {
        this.setState({
            dueDate: date,
        });
    }

    getRecurringDate = (date, recurringType) => {
        var toDate = new Date(date);
        if (recurringType === "1") {
            toDate.setDate(toDate.getDate() + 1);
        }
        else if (recurringType === "7") {
            toDate.setDate(toDate.getDate() + 7);
        }
        else if (recurringType === "14") {
            toDate.setDate(toDate.getDate() + 14);
        }
        else if (recurringType === "30") {
            toDate.setMonth(toDate.getMonth() + 1);
        }
        else if (recurringType === "90") {
            toDate.setMonth(toDate.getMonth() + 3);
        }
        else if (recurringType === "180") {
            toDate.setMonth(toDate.getMonth() + 6);
        }
        else if (recurringType === "365") {
            toDate.setMonth(toDate.getMonth() + 12);
        }

        return toDate;
    }

    handleFromDateChange = (date) => {
        var toDate = this.getRecurringDate(date, this.state.recurringType);
        var fromDate = date;

        this.setState({
            fromDate: fromDate,
            toDate: toDate
        });
    }

    handleCustomRecurring = (event,data) => {
        var toDate = this.getCustomRecurringDate(this.state.fromDate, data.value);
        var fromDate = this.state.fromDate;

        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            recurringCustomType: data.value
        });

    }

    getCustomRecurringDate = (date, numberOfWeek) => {
        var toDate = new Date(date);
        toDate.setDate(toDate.getDate() + (7 * numberOfWeek));
        return toDate;
    }

    handleToDateChange = (date) => {
        this.setState({
            toDate: date
        });
    }

    handleJobUrgencyOnChange = (value) => {
        if(value != -999){
            this.setState({ dueDate: '' });
        }
        this.setState({ jobUrgency: value });
    }

    getDueDate = () => {
        if (this.state.jobUrgency == -999) {
            return (
                <div className="form-group">
                    <label className="form-label">{'Due date for the maintenance request'}</label>
                    <div>
                        <DatePicker dateFormat={(this.props.dateFormat && this.props.dateFormat != "") ? this.props.dateFormat : "dd/MM/yyyy"} minDate={new Date()} className="form-control" selected={this.state.dueDate} onChange={this.handleDueDateChange} />
                    </div>
                </div>
            )
        }
    }

    onChangeJobBudget = (value) => {
        this.setState({ jobBudget: value });
        if (value !== "7") {
            this.setState({ minBudgetAmount: 0, maxBudgetAmount: 0 });
        }
        if (value !== "-999") {
            this.setState({ budgetAmount: 0 });
        }
    }

    toFixed(num, fixed) {
        var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");    
        return num.toString().match(re)[0];    
    }

    onChangeAmount = (event) => {
        let amount = event.target.value;
        amount = amount ? this.toFixed(amount, 2) : amount;
        this.setState({ budgetAmount: amount });
    }

    onChangeMinAmount = (event) => {
        let amount = event.target.value;
        amount = amount ? this.toFixed(amount, 2) : amount;
        this.setState({ minBudgetAmount: amount });
    }

    onChangeMaxAmount = (event) => {
        let amount = event.target.value;
        amount = amount ? this.toFixed(amount, 2) : amount;
        this.setState({ maxBudgetAmount: amount });
    }

    handleJobRecurringTypeOnChange = (value) => {
        if (value === "0") {
            this.setState({ recurringType: value, fromDate: '', toDate: '' });
        }else if(value==="999"){
            var fromDate = new Date();
            var toDate = this.getRecurringDate(fromDate, "7");
            this.setState({ recurringType: value, fromDate: fromDate, toDate: toDate, recurringCustomType:1 });
        }
        else {
            var fromDate = new Date();
            var toDate = this.getRecurringDate(fromDate, value);
            this.setState({ recurringType: value, fromDate: fromDate, toDate: toDate });
        }
    }

    getCardDetailsByUserId = (userId) => {
        settingsService
            .getCardDetailsByUserId(userId)
            .then((res) => {
                if (res.length > 0) {
                    this.setState({
                        currentStep: 1,
                        isCardAvailable: true

                    });
                }
                else {
                    this.setState({
                        isCardAvailable: false
                    });
                }
            });
    }

    checkCardAvailability = () => {
        this.setState({
            isCardLoading: false
        });
    }

    getUserSettings = () => {
        trackPromise(
            settingsService.getUserSettings()
                .then(response => {
                    this.setState({
                        quoteRequestLimit: response.quoteSettings.quoteLimit > 0 ? response.quoteSettings.quoteLimit:10,
                    })
                })
        );
    }
    
    //Steps
    checkCardDetailsAvailabilityStep = (index) => {
        return (
            <>
                {!this.state.isCardAvailable ?
                    <div className="wizard-inner wzrd-post-a-job">
                        <div className='wizard-body'>
                            <div className="wizard-header">
                                <h2>Card Details</h2>
                            </div>
                            <div className="red-card my-2"> 
                                To proceed with the job posting, please enter a valid credit card.
                            </div>
                            <br />
                            <div>
                                <Payment userId={localStorage.getItem("userId")} isFromTenantManageJob={true}
                                    checkCardAvailability={this.checkCardAvailability} nextStep={this.nextStep}></Payment>
                            </div>

                            {this.state.isCardLoading &&
                                <div className='d-flex justify-content-center'><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>
                            }
                        </div>
                    </div>
                    :
                    <div className='d-flex justify-content-center'><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>
                }
            </>
        );
    }

    selectPropertyStep = (index) => {
        const { propertyList, formData, property } = this.state;

        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className='wizard-body'>
                    <div>
                        <div className="wizard-header">
                            <h2 htmlFor="property" className='mb-0 text-left'>Which property do you wish to log a maintenance request for?</h2>
                            <div className="userTip text-left font-14 text-dark font-medium mb-4">Choose an option</div>
                        </div>
                        <div className="mb-4">
                            {(this.state.isPropertiesLoading) ? <div className='d-flex justify-content-center'><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div> : ''}
                            <ButtonToolbar className="radio-btn-group">
                                <ToggleButtonGroup type="radio" name="options" className="w-100" ref="jobType" defaultValue={(formData && formData.propertyId != "") ? formData.propertyId : ""}
                                    onClick={(value) => { this.handlePropertyChange(value) }}>
                                    {propertyList.map((a, index) => {
                                        return <ToggleButton className="px-2 mb-3 col-12 col-lg-6" key={index} value={a.propertyId}><div className='d-flex align-items-center btn-inner'><span className="icon icon-ico-location"></span> {a.address}</div></ToggleButton>
                                    })
                                    }
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div>
                        <div className="bg-white text-orange d-flex align-items-center warning-section font-14 px-2">
                        <div className='d-flex align-items-center mr-3'><span className="icon icon-ico-exclamation color-white"></span></div>By selecting this option, you are responsible to manage the job and setting any required payments
                        </div>
                    </div>
                </div>
                <div className="wizard-actions d-flex justify-content-end">
                    <button type="button" className="btn next-btn px-4 mb-0 mx-0 mx-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" class="ml-2"></img></button>
                </div>

            </div>);
    }

    selectServiceType = (index) => {
        const { isLoading, value, filteredResults } = this.state
        let serviceTypes = this.createTypeOfServicesButtons();
        let serviceTypeChild = this.createTypeOfServicesChildButtons();
        return (
            <>
                <div className="wizard-inner">
                    <div className={'wizard-body'}>
                        <div>
                            <div className="wizard-header">
                                <h2 className='mb-0 text-left'>Choose the service type you need.<span className="required-icon">*</span></h2>
                                <div className="userTip text-left font-14 text-dark font-medium mb-4">Choose an option</div>
                            </div>
                            <div className="form-group auto-complete">
                                <label className="form-label">Service </label>
                                <Search className='common-search'
                                    placeholder="Please type the service you need"
                                    loading={isLoading}
                                    onResultSelect={this.handleResultSelect}
                                    onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                        leading: true,
                                    })}
                                    value={value}
                                    results={filteredResults}
                                    resultRenderer={this.resultRenderer}
                                    {...this.props}
                                />
                            </div>
                        </div>
                        {serviceTypes}
                        {serviceTypeChild} 
                    </div>
                    <div class="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4">
                        <button type="button" class="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>PREVIOUS</button>
                        <button type="button" class="btn next-btn px-4 mx-0 ml-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" class="ml-2"/></button>
                    </div>
                </div>
            </>);
    }

    createTypeOfServicesButtons = () => {
        const { t } = this.props;
        let isServiceTypeSelected = this.state.isServiceTypeSelected;
        if (this.state.selectedService.childJobTypes) {
            return (
                <>
                    <div className="mt-4 wzrd-post-a-job">
                        {(isServiceTypeSelected) ?
                            <div className="wizard-header">
                                <h2>{this.state.isFromBot ? t('pj_lbl_select_sub_type') : 'Please select the type of subservice you require.'}<span className="required-icon">*</span></h2>
                                <p>{this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'}</p>
                            </div>

                            : ''}

                        <ButtonToolbar className="radio-btn-group sub-srvs-typ-tgl key-collect-options mb-3 position-relative z-0">
                            <ToggleButtonGroup
                                className={this.props.isFromBot?"btn-group-bot":""}
                                defaultValue={(this.state.childJobTypeId) ? this.state.childJobTypeId : ''}
                                type="radio"
                                name="options"
                                onChange={(event) => { this.getSubChildJobTypes(event); }}>
                                {this.state.selectedService.childJobTypes.map((jbType, index) => {
                                    return <ToggleButton className="mr-10px" key={jbType.id} value={jbType.id}><span className="ba-icn ba-icn-ok mr-3 font-16"></span> {jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            return
        }
    }

    createTypeOfServicesChildButtons = (props) => {
    
        if (this.state.secondaryJobTypes.length > 0) {
            return (
                <>
                    <div className="form-group mt-5 wzrd-post-a-job thrd-lvl-btns">
                        <h2 className="mb-3">{this.state.selectedServiceType.parentJobType.description}<span className="required-icon">*</span></h2>
                        <ButtonToolbar className="radio-btn-group sub-srvs-typ-tgl key-collect-options mb-3 position-relative z-0">
                            <ToggleButtonGroup
                                className={this.props.isFromBot?"btn-group-bot":""}
                                defaultValue={(this.state.subChildJobTypeId) ? this.state.subChildJobTypeId : ''}
                                type="radio"
                                name="options"
                                onChange={this.onChangeChildTypeOfServices}>
                                {this.state.secondaryJobTypes.map((jbType, index) => {                                    
                                    return <ToggleButton className="mr-10px" key={jbType.id} value={jbType.id}><span className="ba-icn ba-icn-ok mr-3 font-16"></span> {jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            return
        }

    }

    getMaintenaceIssue = (index) => {
        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className={'wizard-body job-post-type'}>
                    <div className="wizard-header">
                        <h2 className='mb-0 text-left'>{'Please describe your maintenance issue'}<span className="required-icon">*</span></h2>
                        {(this.state.formData.address) ?
                            <div className="userTip text-left font-14 text-dark font-medium">{'You are logging this maintenance issue for'} <span className="font-500">{this.state.formData.address}</span></div> : ''}
                    </div>

                    <div className="form-group">
                        <label style={{marginRight : '90%' }}>Job title</label>
                        <input type="text" className="form-control"
                            value={this.state.formData.jobTitle} ref={this.title} onChange={(value) => this.handleOnChangeJobTitle()} >
                        </input>
                    </div>
                    <div className="form-group">
                        <label style={{marginRight : '77%' }}>Detailed description</label>
                        <textarea className="form-control form-textarea no-min-height"
                            value={this.state.formData.jobDescription} ref={this.jobDescription} onChange={(value) => this.handleOnChangeJobDescription()}>
                        </textarea>
                    </div>
                </div>
                <div class="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4">
                    <button type="button" class="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>PREVIOUS</button>
                    <button type="button" class="btn next-btn px-4 mb-0 mx-0 ml-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" class="ml-2"/></button>
                </div>
            </div>);
    }

    getContactDetails = (index) => {
        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className={'wizard-body job-post-type'}>
                    <div className="wizard-header">
                        <h2 className='mb-0 text-left'>{'Add location & contact details'}</h2>
                        <div className="userTip text-left font-14 text-dark font-medium">{'This will help trade to find the location when attending to fix the issue'}</div>
                    </div>

                    <div className="form-group">
                        <label style={{marginRight : '70%' }}>Where the job is located?</label>
                        <input type="text" className="form-control"
                            value={this.state.formData.issueAreaInProperty} ref={this.issueAreaInProperty} onChange={(value) => this.handleOnChangeJobIssueArea()} >
                        </input>
                    </div>
                    <div className="form-group">
                        <label style={{marginRight : '77%' }}>Where to come to?</label>
                        <input type="text" className="form-control"
                            value={this.state.formData.placeToCome} ref={this.placeToCome} onChange={(value) => this.handleOnChangePlaceTocome()} >
                        </input>
                    </div>
                    <div className="form-group">
                        <label style={{marginRight : '82%' }}>Contact person</label>
                        <input type="text" className="form-control"
                            value={this.state.formData.contactPerson} ref={this.contactPerson} onChange={(value) => this.handleOnChangeContactPerson()} >
                        </input>
                    </div>
                    <div className="form-group">
                        <label style={{marginRight : '82%' }}>Contact number</label>
                        <input type="text" className="form-control"
                            value={this.state.formData.contactNumber} ref={this.contactNumber} onChange={(value) => this.handleOnChangeContactNumber()} >
                        </input>
                    </div>
                </div>

                <div class="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4">
                    <button type="button" class="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>PREVIOUS</button>
                    <button type="button" class="btn next-btn px-4 mx-0 ml-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" class="ml-2"/></button>
                </div>
            </div>);
    }

    selectUgency = (index) => {
        const dueDate = this.getDueDate();
        return (
            <>
                <div className="wizard-inner wzrd-post-a-job">
                    <div className={'wizard-body'}>
                        <div className="wizard-header">
                            <h2 className='mb-0 text-left'>{'When do you want this issue to be fixed?'}<span className="required-icon">*</span></h2>
                            <div className="userTip text-left font-14 text-dark font-medium mb-4">{'choose an option'}</div>
                        </div>
                        <div className="form-group">
                            <ButtonToolbar className="radio-btn-group sub-srvs-typ-tgl key-collect-options" id="jobUrgencyGroup" key="jobUrgencyGroup">
                                <ToggleButtonGroup type="radio" name="options" ref="jobUrgency" onChange={(value) => this.handleJobUrgencyOnChange(value)} defaultValue={this.state.jobUrgency}>
                                    <ToggleButton key="jobUrgency1" value="1" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Urgently'}</ToggleButton>
                                    <ToggleButton key="jobUrgency2" value="2" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Within a few days'}</ToggleButton>
                                    <ToggleButton key="jobUrgency3" value="3" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Within a week'}</ToggleButton>
                                    <ToggleButton key="jobUrgency4" value="-999" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' By this date'}</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div>
                        {dueDate}
                    </div>
                    <div className="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4">
                        <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{'PREVIOUS'}</button>
                        <button type="button" className="btn next-btn px-4 mx-0 ml-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle} >{'NEXT'} <img src="Images/next-icon.svg" width="12" class="ml-2"/></button>
                    </div>
                </div>
            </>);
    }

    selectBudget = (index) => {
        const preventMinus = (e) => {
            if (e.charCode === 45 || e.charCode === 101) {
                e.preventDefault();
            }
        };
        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className="wizard-body2">
                    <div className="wizard-header">
                        <h2 className='mb-0 text-left'>Do you have an estimate in mind?<span className="required-icon">*</span></h2>
                        <div className="userTip text-left font-14 text-dark font-medium mb-4"> Choose an option</div>
                    </div>
                    <div className="sub-srvs-typ-tgl">
                        <ButtonToolbar className="radio-btn-group key-collect-options" id="budgetSelectGroup" key="budgetSelectGroup">
                            <ToggleButtonGroup className="budgetSelect" id="budgetSelect" type="radio" name="budget" onChange={(value) => this.onChangeJobBudget(value)} defaultValue={this.state.jobBudget}>
                                <ToggleButton id="budget1" value="1" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>Under $500</ToggleButton>
                                <ToggleButton id="budget2" value="2" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>$500 - $1000</ToggleButton>
                                <ToggleButton id="budget3" value="3" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>$1000 - $2000</ToggleButton>
                                <ToggleButton id="budget4" value="4" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>$2000 - $5000</ToggleButton>
                                <ToggleButton id="budget5" value="5" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>$5000+</ToggleButton>
                                <ToggleButton id="budget0" value="0" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>Unsure</ToggleButton>
                                <ToggleButton id="budget6" value="-999" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>Custom</ToggleButton>
                                <ToggleButton id="budget6" value="7" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>Estimate</ToggleButton>
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                    {this.state.jobBudget == -999 ?
                        <div className='form-group mt-3'>
                            <input type="number" className="form-control"
                                placeholder="Enter your estimate." aria-label="test"
                                value={this.state.budgetAmount} ref={this.budgetRef} min="0.00"
                                step="0.01" presicion={2} onKeyPress={e=> preventMinus(e)} onChange={e => this.onChangeAmount(e)}>
                            </input>
                        </div>
                        : (this.state.jobBudget == 7) &&
                        <div className="row">
                            <div className='col-md-6 form-group mt-3'>
                                <label className="form-label">{'Minimum Estimate Amount'}</label>
                                <input type="number" className="form-control"
                                    placeholder="Enter your min estimate." aria-label="test"
                                    value={this.state.minBudgetAmount} ref={this.budgetRef} min="0.00"
                                    step="0.01" presicion={2} onKeyPress={e=> preventMinus(e)}  onChange={e => this.onChangeMinAmount(e)}>
                                </input>
                            </div>
                            <div className='col-md-6 form-group mt-3'>
                                <label className="form-label">{'Maximum Estimate Amount'}</label>
                                <input type="number" className="form-control"
                                    placeholder="Enter your max estimate." aria-label="test"
                                    value={this.state.maxBudgetAmount} ref={this.budgetRef} min="0.00"
                                    step="0.01" presicion={2} onKeyPress={e=> preventMinus(e)} onChange={e => this.onChangeMaxAmount(e)}>
                                </input>
                            </div>
                        </div>
                    }

                </div>
                <div className="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4">
                    <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>PREVIOUS</button>
                    <button type="button" className="btn next-btn px-4 mx-0 ml-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT <img src="Images/next-icon.svg" width="12" class="ml-2"/></button>
                </div>
            </div>
        )
    }

    selectRecurringType = (index) => {
        const customRecurringOptions = [
            { key: 1, text: '1 Week', value: 1 },
            { key: 2, text: '2 Weeks', value: 2 },
            { key: 3, text: '3 Weeks', value: 3 },
            { key: 4, text: '4 Weeks', value: 4 },
            { key: 5, text: '5 Weeks', value: 5 },
            { key: 6, text: '6 Weeks', value: 6 },
            { key: 7, text: '7 Weeks', value: 7 },
            { key: 8, text: '8 Weeks', value: 8 },
            { key: 9, text: '9 Weeks', value: 9 },
            { key: 10, text: '10 Weeks', value: 10 }
        ];
        return (
            <>
                <div className="wizard-inner wzrd-post-a-job">
                    <div className={'wizard-body'}>
                        <div className="wizard-header">
                            <h2 className='mb-0 text-left'>{'Select the job recurring type'}<span className="required-icon">*</span></h2>
                            <div className="userTip text-left font-14 text-dark font-medium mb-4">{'choose an option'}</div>
                        </div>
                        <div className="form-group w-100">
                            <ButtonToolbar className="radio-btn-group sub-srvs-typ-tgl key-collect-options" id="recurringType" key="recurringType">
                                <ToggleButtonGroup type="radio" name="options" ref="recurringType" onChange={(value) => this.handleJobRecurringTypeOnChange(value)} defaultValue={this.state.recurringType}>
                                    <ToggleButton key="recurringType1" value="0" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16 "></span>{' No recurrence'}</ToggleButton>
                                    <ToggleButton key="recurringType2" value="1" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Daily'}</ToggleButton>
                                    <ToggleButton key="recurringType3" value="7" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Weekly'}</ToggleButton>
                                    <ToggleButton key="recurringType4" value="14" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Fortnightly'}</ToggleButton>
                                    <ToggleButton key="recurringType5" value="30" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Monthly'}</ToggleButton>
                                    <ToggleButton key="recurringType6" value="90" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Quartely'}</ToggleButton>
                                    <ToggleButton key="recurringType7" value="180" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Semi annually'}</ToggleButton>
                                    <ToggleButton key="recurringType8" value="365" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Annually'}</ToggleButton>
                                    <ToggleButton key="recurringType9" value="999" className="mr-10px"><span className="ba-icn ba-icn-ok mr-3 font-16"></span>{' Custom'}</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div>
                        {this.state.recurringType === "999" &&
                            <div className="row">
                                <div className="col-md-6 form-group dropDown-style">
                                    <label className="form-label">Recurring every</label>
                                    <Dropdown className="form-control"
                                        selectOnNavigation={false}
                                        selection
                                        options={customRecurringOptions}
                                        onChange={this.handleCustomRecurring}
                                        value={this.state.recurringCustomType}
                                    />
                                </div>
                            </div>
                        }
                        <div className={(this.state.recurringType !== "0" && this.state.recurringType !== "") ? 'row form-group mt-3' : 'form-group d-none'}>
                            <div className="col-md-6 form-group">
                                <label className="form-label">{'From Date'}</label>
                                <div>
                                    <DatePicker dateFormat={(this.props.dateFormat && this.props.dateFormat != "") ? this.props.dateFormat : "dd/MM/yyyy HH:mm:ss"} minDate={new Date()} className="form-control" selected={this.state.fromDate} onChange={this.handleFromDateChange} />
                                </div>
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="form-label">{'To Date'}</label>
                                <div>
                                    <DatePicker dateFormat={(this.props.dateFormat && this.props.dateFormat != "") ? this.props.dateFormat : "dd/MM/yyyy HH:mm:ss"} minDate={new Date()} className="form-control" selected={this.state.toDate} onChange={this.handleToDateChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4">
                        <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{'PREVIOUS'}</button>
                        <button type="button" className="btn next-btn px-4 mx-0 ml-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle} >{'NEXT'} <img src="Images/next-icon.svg" width="12" class="ml-2"/></button>
                    </div>
                </div>
            </>);
    }

    getAttachments = (index) => {
        return (
            <>
                <div>
                    <div className="wizard-inner wzrd-post-a-job">
                        <div className={'wizard-body'}>
                            <div className="wizard-header">
                                <h2 className='mb-0 text-left'>{'Attachments'}</h2>
                                <div className="userTip text-left font-14 text-dark font-medium">{'Attach images or videos that describe your issue properly.'}</div>
                            </div>
                            <div className="form-group w-100 d-inline-block">
                                <div className="img-upld-cont">
                                    <AttachmentsDrawer
                                        jobDetails={{ id: this.state.jobId, attachments: this.state.attachments }}
                                        onSubmitAttachments={(event) => { this.setState({ attachments: event }) }}
                                        isJobPost={true}>
                                    </AttachmentsDrawer>
                                    {(this.state.attachments) ?
                                        <ImageViewer attachments={this.state.attachments} isFromTenantManageJobPost={true}></ImageViewer> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={this.state.attachments ? "wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4" : "wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4"}>
                            <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{'PREVIOUS'}</button>
                            <button type="button" className="btn next-btn px-4 mx-0 ml-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{'NEXT'} <img src="Images/next-icon.svg" width="12" class="ml-2"/></button>                            </div>
                    </div>
                </div>
            </>);
    }

    getTrades = (index) => {
        const { isTradeLoading, displayTradesArray } = this.state

        return (
            <>
                <div>
                    <div className="wizard-inner">
                        <div className='wizard-body'>
                            <div className="wizard-header">
                                <h2 className='mb-0 text-left'>Assign Trade(s).</h2>
                                <div className="userTip text-left font-14 text-dark font-medium mb-4">Choose an option</div>
                            </div>
                            <div className="form-group w-100 mt-5 mb-3">
                                <ButtonToolbar className="radio-btn-group sub-srvs-typ-tgl assign-trade-options" id="inviteTrade" key="inviteTrade">
                                    <ToggleButtonGroup type="radio" name="options" ref="inviteTrade" onChange={(value) => this.handleTradeRequestChange(value)} defaultValue={this.state.tradeRequestType}>
                                        <ToggleButton key="inviteTrade1" value="2" className="mr-10px d-flex align-items-center justify-content-between"><div className="d-flex align-items-center"><span className="ba-icn icon-Default mr-3"></span><span className='text'>I want a work order</span></div><span className="ba-icn ba-icn-ok ml-3 font-16 tick-icon"></span></ToggleButton>
                                        <ToggleButton key="inviteTrade2" value="1" className="mr-10px d-flex align-items-center justify-content-between"><div className="d-flex align-items-center"><span className="ba-icn icon-ConditionReports mr-3"></span><span className='text'>I want a quote</span></div><span className="ba-icn ba-icn-ok ml-3 font-16 tick-icon"></span></ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </div>
                            {this.state.tradeRequestType == 1 ? <div className="form-group">
                                <label>Quote Limit</label>
                                <select value={this.state.quoteRequestLimit} className="form-control" onChange={(value) => this.handleQuoteLimitChange(value)}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="1000">Unlimited</option>
                                </select>
                            </div> : ''}
                            <div className="form-group auto-complete common-search">
                                <div className="ui icon input">
                                    <input placeholder="Search by trade name or company" value={this.state.valueOfTradeSearch} onChange={this.handleTradeSearchChange} className="prompt" />
                                    <i aria-hidden="true" class="search icon"></i>
                                </div>
                            </div>

                            <div>
                             {displayTradesArray.length == 0 && <div>Trades are not available for the selected service type / sub type.</div>}
                                {displayTradesArray.map((item, index) => {
                                    return <div key={item.id} className={(this.state.selectedProviderId.includes(item.id)) ? 'trade-row d-flex align-items-center mb-2 is-trade-selected' : 'trade-row d-flex align-items-center mb-2'} onClick={() => this.selectTrade(item, index)}>
                                        <div className='trade-img col-1 pl-0' onClick={(event) => this.providerProfileView(event,item.id)}>
                                            <img style={{ borderRadius: '50%',cursor:"pointer" }} src={item.profileImageUrl?item.profileImageUrl:'/images/default-profile.png'} />
                                        </div>
                                        <div className='d-flex flex-column justify-content-start col-6'>
                                            <label className='trade-name mb-0'>{item.name} {(item.companyName) ? " | " + item.companyName : ""}</label>
                                            <label className='trade-email mb-0'>{item.email}</label>
                                        </div>
                                        <div className='d-flex flex-column justify-content-start col-2'>
                                            <label className='info-label mb-0'> Call out fee</label>
                                            <lable className="fee mb-0" style={{ display: 'block' }}>${item.calloutFee}</lable>
                                        </div>
                                        <div className='d-flex flex-column justify-content-start col-2'>
                                            <label className='info-label mb-0'> Per hour</label>
                                            <label className="fee mb-0" style={{ display: 'block' }}> ${item.hourlyRate}</label>
                                        </div>
                                        <div className='col-1 pr-0 d-flex justify-content-end'>
                                            <span className="ba-icn ba-icn-ok tick-icon"></span>
                                        </div>
                                    </div>
                                })}

                            </div>
                            <div className='d-flex justify-content-center mt-5'>
                                {displayTradesArray.length > 0 && <Pagination className='pagination'
                                    pageSize={this.state.sizePerPage}
                                    onChange={this.onChange}
                                    current={this.state.page}
                                    total={this.state.total}
                                />}
                            </div>

                        </div>
                        <div className="wizard-actions d-flex flex-column flex-md-row justify-content-end mt-4">
                            <button type="button" className="btn light-blue-button px-4 mb-0 mx-0 mx-md-2" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle} >PREVIOUS</button>
                            <button type="button" className="btn next-btn px-4 mx-0 ml-md-2 d-flex align-items-center justify-content-center" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle} >POST</button>

                        </div>
                    </div>
                </div>
            </>);
    }

    selectTrade = (item, index) => {
        let { selectedProviderId,tradeRequestType } = this.state;

        if(tradeRequestType==="2"){
            selectedProviderId=[];
            selectedProviderId.push(item.id);
        }else if(tradeRequestType==="1"){
            if (selectedProviderId.includes(item.id)) {
                selectedProviderId.pop(item.id)
            } else {
                selectedProviderId.push(item.id);
            }
        }
        

        this.setState({ selectedProviderId: selectedProviderId });
    }

    providerProfileView = (e,id) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ providerViewId: id, isShowProviderDrawer: true });
    }

    handleTradeRequestChange = (value) => {
        this.setState({ tradeRequestType: value,selectedProviderId:[] });
    }
    handleQuoteLimitChange = (event) => {
        this.setState({ quoteRequestLimit: event.target.value });
    }

     onChange = pagenumber => {
        this.setState({page:pagenumber}, function() {
            this.getTradesList();
          }); 
      };

    updateJobList = (status, pagenumber) => {
        if(status){
            this.setState({page:pagenumber}, function() {
              this.getTradesList();
            });  
          }
    }

    onCloseProviderDrawer = () => {
        this.setState({ isShowProviderDrawer: false });
    }

    handleTradeSearchChange = (e) => {
        const { allTrades } = this.state;
        let value = e.target.value;
        this.setState({valueOfTradeSearch:value}, function() {
            this.getTradesList();
          });
       
    }

    validate = (index) => {
        const { tradeeCount, jobBudget, budgetAmount, selectedService,
            selectedServiceType, formData, minBudgetAmount, maxBudgetAmount, recurringType, selectedProviderId, tradeRequestType } = this.state
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "checkCardDetailsAvailability":
                return true;
            case "selectProperty":
                if (!this.state.property) {
                    this.notifyError('Please select a property.');
                    return false;
                }
                else {
                    return true;
                }
            case "getMaintenaceIssue":
                if ((this.state.formData.jobDescription ? this.state.formData.jobDescription.trim() == '' : true || this.state.formData.jobDescription == undefined) && (!this.jobDescription.current.value || this.jobDescription.current.value == '')) {
                    this.notifyError('Please enter job description.');
                    return false;
                }
                else if ((this.state.formData.jobTitle ? this.state.formData.jobTitle.trim() == '' : true || this.state.formData.jobTitle == undefined) && (!this.title.current.value || this.title.current.value == '')) {
                    this.notifyError('Please enter job title.');
                    return false;
                } else {
                    return true;
                }
            case "selectServiceType":
                if (selectedService && selectedServiceType.parentJobType) {
                    if (selectedServiceType.parentJobType.id != '') {
                        if (selectedServiceType.childJobTypes.length > 0 && (!formData.childJobType || formData.childJobType === '')) {
                            var errorMessage = 'Please select sub type that you need.';
                            this.notifyError(errorMessage);
                            return false;
                        }
                    } else {
                        this.notifyError('Please select a type of service.');
                        return false;
                    }
                } else if (!selectedService.parentJobType) {
                    this.notifyError('Please select a service.');
                    return false;
                }
                return true;
            case "getContactDetails"://Optional
                return true;
            case "selectUgency":
                if (this.state.jobUrgency == undefined || this.state.jobUrgency == '') {
                    this.notifyError('Please select job urgency.');
                    return false;
                }
                if (this.state.jobUrgency == -999 && this.state.dueDate == '') {
                    this.notifyError('Please select a due date.');
                    return false;
                }
                return true;
            case "selectBudget":
                if (jobBudget === '') {
                    this.notifyError('Please select your estimate.');
                    return false;
                }
                else if (jobBudget === "-999" && (budgetAmount == '' || budgetAmount == "0" ||  budgetAmount === 0 )) {
                    this.notifyError('Please enter your estimate.');
                    return false;
                }
                else if (jobBudget === "7" && (minBudgetAmount == '' || minBudgetAmount == "0" || minBudgetAmount === 0)) {
                    this.notifyError('Please enter your minimum estimate amount.');
                    return false;
                }
                else if (jobBudget === "7" && (maxBudgetAmount == '' || maxBudgetAmount == "0" || maxBudgetAmount === 0)) {
                    this.notifyError('Please enter your maximum estimate amount.');
                    return false;
                }
                else if (jobBudget === "7" && (maxBudgetAmount < minBudgetAmount)) {
                    this.notifyError('Minimum estimate cannot exceed the maximum amount');
                    return false;
                };
                return true;
            case "selectRecurringType":
                if (recurringType === '') {
                    this.notifyError('Please select From date and To date.');
                    return false;
                }
                else if (this.state.recurringType !== "0" && (this.state.fromDate === '' || this.state.toDate === '')) {
                    this.notifyError('Please select From date and To date.');
                    return false;
                }
                else if (this.state.fromDate > this.state.toDate) {
                    this.notifyError('Please check From date and To date.');
                    return false;
                }
                return true;
            case "getAttachments":
                return true;
            case "getTrades":
                if (selectedProviderId.length === 0) {
                    this.notifyError('Please select trade to progress.');
                    return false;
                }
                if (tradeRequestType == '') {
                    this.notifyError('Please select work order or quote to progress.');
                    return false;
                }
                return true;
        }
    }

    postJob = () => {

        let jobDetails = postModel();

        jobDetails.jobCategoryDisplay = this.state.value;
        jobDetails.longitude = this.state.formdata.longitude;
        jobDetails.latitude = this.state.formdata.latitude;

        jobDetails.attachments = this.state.attachments;
        jobDetails.isTradePostedJob = false;
        jobDetails.userId = localStorage.getItem("userIdDecripted");
        jobDetails.isScheduled = false;
        jobDetails.propertyKeyCollectType = 0;
        jobDetails.description = this.state.formdata.jobDescription;
        jobDetails.title = this.state.formdata.jobTitle;
        jobDetails.workflowStatusId = 2;
        jobDetails.isPropertyRelatedJob = false;
        jobDetails.id = "00000000-0000-0000-0000-000000000000";
        jobDetails.address = this.state.formdata.address;
        jobDetails.awardedTradeeId = this.state.tradeRequestType == '2' ? this.state.selectedProviderId[0] : '';//TODO multiple trade

        jobDetails.endTime = 0;
        jobDetails.taskStatus = 0;
        jobDetails.whereJobLocated = this.state.formdata.issueAreaInProperty;
        jobDetails.whereToCome = this.state.formdata.placeToCome;
        jobDetails.contactPersonName = this.state.formdata.contactPerson;
        jobDetails.contactPersonNumber = this.state.formdata.contactNumber;
        jobDetails.countryId = this.props.userData ? this.props.userData.countryID : '';
        jobDetails.jobUrgencyId = parseInt(this.state.jobUrgency);
        if(parseInt(this.state.jobUrgency)==-999){
            jobDetails.dueDate = this.state.dueDate;
        }
        jobDetails.primaryJobType = this.state.selectedServiceType.parentJobType.id;
        jobDetails.jobCategoryId = "00000000-0000-0000-0000-000000000000";

        jobDetails.isHideTenantDetailsToOwner = false;
        jobDetails.isCloneFromJobPost = false;
        jobDetails.hasBidsForOwnerApproval = false;
        jobDetails.placeBidType = 1;
        jobDetails.isDeclined = false;
        jobDetails.suburbId = this.state.formdata.suburbId;

        jobDetails.budgetId = parseInt(this.state.jobBudget);

        jobDetails.quoteLimit = this.state.quoteRequestLimit ? this.state.quoteRequestLimit : 0;

        jobDetails.jobInformation.hasRecuringJobs = this.state.recurringType != 0 ? true : false;
        jobDetails.isOwnerAttending = false;
        jobDetails.secondaryJobType = this.state.selectedServiceType.parentJobType.id;
        jobDetails.secondaryJobTypeDisplay = this.state.selectedServiceType.parentJobType.title;
        jobDetails.primaryJobType = this.state.selectedService.parentJobType.id;
        jobDetails.primaryJobTypeDisplay = this.state.selectedService.parentJobType.title;

        jobDetails.serviceConsumerId = localStorage.getItem("userIdDecripted");
        jobDetails.targetedTradeeOption = parseInt(this.state.tradeRequestType);

        jobDetails.consumerTypePropertyId = this.state.formdata.propertyId;
        //jobDetails.propertyId = null;
        jobDetails.budgetAmount = parseFloat(this.state.budgetAmount);
        jobDetails.payerId = localStorage.getItem("userIdDecripted");
        jobDetails.secondJobTypeLevel = this.state.childJobTypeId;
        jobDetails.isRecurred = this.state.recurringType != 0 ? true : false;



        jobDetails.postedUserId = localStorage.getItem("userIdDecripted");
        jobDetails.jobCountryId = this.props.userData.countryID;

        jobDetails.userType = 8;
        jobDetails.budgetMax = parseFloat(this.state.maxBudgetAmount);
        jobDetails.jobType = this.state.selectedServiceType.parentJobType.id;
        jobDetails.awardedAmount = this.state.jobBudget == -999?this.state.budgetAmount:0;

        jobDetails.isTenantPost = true;
        if(this.state.recurringType != 0){
            jobDetails.recurSettings = {
                recurTypeIdString: this.state.recurringType,
                recurCustomTypeId: parseInt(this.state.recurringCustomType),
                recurTypeId: parseInt(this.state.recurringType),
                startDate :this.state.fromDate ? this.state.fromDate :null,
                endDate : this.state.toDate ? this.state.toDate : null
            };
        }


        jobDetails.tradees = this.state.selectedProviderId.map(x => ({ id: x, userId: x,isAssigned : true }));

        this.setState({ isJobPosting: true })
        console.log(JSON.stringify(jobDetails));
        try {
            trackPromise(
                AXIOS.POST(`tenant-api/createjob`, jobDetails, {
                    'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret }
                })
                    .then(dataJson => {
                        this.setState({ isJobPosting: false })
                        if(dataJson.status === 13){
                            toast.error(dataJson.message, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        }
                        else{
                        toast.success(`${dataJson.jobNumber} Job is posted successfully.`, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });                    
                        this.discardDetails();
                    }

                    }, error => {
                        console.log(error);
                        this.setState({ isJobPosting: false });
                        toast.error(`Fail to post the job. Please try again.`, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
            )

        } catch (error) {
            console.log(error);
        }
    }


    getTradesList =()=> {
        try {
            let { sizePerPage, page, valueOfTradeSearch } = this.state;  
            this.setState({isTradeLoading : true});
            trackPromise(
                jobService.GetAllTrades(false, true, Guid.EMPTY.toString(), this.state.childJobTypeId, this.state.formData.propertyId, 
                false, this.state.formData.suburbId,valueOfTradeSearch,sizePerPage,page)
                .then(res => {
                    let data = res.tradesList;
                    this.setState({
                        filteredTradeesResults: data,
                        allTrades: data,
                        isTradeLoading: false,
                        total: data.length > 0 ? res.pageTotal : 0,
                        displayTradesArray: data
                    });                    
                })
            );
        } catch (error) {
            console.log(error);
        }
    };


    checkTradeRequestType() {
        let { tradeRequestType } = this.state;
        if (tradeRequestType == '2') {
            this.preAuthorizedAmount();
        } else {
            this.postJob();
        }
    }

    preAuthorizedAmount() {
        let { tradeRequestType, maxBudgetAmount, jobBudget, budgetAmount } = this.state;
        return jobService.GetPropertyDetailsWithHoldAmount()
            .then((res) => {
                if (res.properties.length > 0) {
                    let data = res.properties.filter(x => x.propertyId == this.state.propertyId)[0];
                    let amount = data.holdAmountFromTenantPerJob;
                    if (jobBudget == '7') {
                        amount = maxBudgetAmount;
                    }
                    if (jobBudget == '-999') {
                        amount = budgetAmount;
                    }

                    this.setState({ isHoldAmountFromTenant: data.isEnableHoldAmountFromTenantJob, holdAmountFromTenantPerJob: amount })
                    if (tradeRequestType == '2' && data.isEnableHoldAmountFromTenantJob) {
                        this.setState({ showPreAuthorizedDrawer: true });
                    }
                    if(!data.isEnableHoldAmountFromTenantJob){
                        this.postJob();
                    }
                }

            })
    }

    createDrawer = () => {
        const header = this.createPreAuthorizedHeader();
        const body = this.createPreAuthorizedBody();
        const footer = this.createPreAuthorizedFooter();

        return (
            <Drawer
                size={"m"}
                open={this.state.showPreAuthorizedDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    };

    createPreAuthorizedHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Confirmation
                </h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={() => this.toggleConfirmationeDrawer(true)}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>

            </>
        );
    };

    toggleConfirmationeDrawer = isClose => {
        this.setState(state => ({
            showPreAuthorizedDrawer: !this.state.showPreAuthorizedDrawer
        }));
    };

    createPreAuthorizedBody = () => {
        let { holdAmountFromTenantPerJob } = this.state;
        return (
                <div className="panel-body dashboardN pt-0">
                    <div className="provider-profile row">
                        <div className="col-md-12">
                            <div className="mt-4">
                                <span> <lable style={{ display: 'block' }}><strong> A temporary hold of ${holdAmountFromTenantPerJob} will be deducted from your nominated credit card and it will be released once the job is marked as completed.</strong></lable></span>
                            </div>
                        </div>
                    </div>
                </div>
        );
    };

    createPreAuthorizedFooter = () => {
        return (
            <>
                <div class="panel-footer mb-3">
                    <div class="card-actions">
                        <button type="button" onClick={() => this.confirmPreAuthorized()}><i class="ba-icn ba-icn-ok"></i>Yes</button>
                        <button class="more-actions" type="button" onClick={() => this.discardDetails()}> No </button>
                    </div>
                </div>

            </>
        );
    };

    confirmPreAuthorized = () => {
        this.postJob();

    }

    discardDetails = () => {
        this.props.backtoList();
    }

    render() {
        const currentStepIndex = this.state.currentStep;
        let step = this.showCurrentStep(currentStepIndex);
        let drawer = this.createDrawer();
        return (
            <Form>
                <div className="jobpost-wizard">
                    {step}
                </div>
                {(this.state.isShowProviderDrawer) ?
                    <ProviderMiniProfileDrawer userId={this.state.providerViewId} isShowProviderDrawer={this.state.isShowProviderDrawer}
                        onCloseProviderDrawer={this.onCloseProviderDrawer} consumerTypePropertyId={this.state.propertyId}>
                    </ProviderMiniProfileDrawer> : ''}

                {(this.state.showPreAuthorizedDrawer) ?
                    <div>{drawer}</div>
                    : ''}
            </Form>);
    }

    getAvailableServices = () => {
        try {
            this.setState({ isLoading: true })
            AXIOS.GET(`tenant-api/available-job-types?userId=${localStorage.getItem('userId')}`)
                .then(dataJson => {
                    this.setState({ isLoading: false })
                    this.setState({ availableServicesDetails: dataJson });
                    this.setState({ availableServices: dataJson });
                    let formData = this.state.formData;
                    formData.childJobTypeId = '';
                    formData.childJobType = '';
                    this.setState({ formData: formData });
                })
        } catch (error) {
            console.log(error);
        }
    }
}



function postModel() {
    return {
        "jobCategoryDisplay": "",
        "longitude": 0,
        "bidAmountMax": 0,
        "attachments": [],
        "distance": 0,
        "isTradePostedJob": false,
        "userId": "",
        "isScheduled": false,
        "propertyKeyCollectType": 0,
        "description": "Hehbe",
        "workflowStatusId": 2,
        "acceptedBid": {
            "isBookmarked": false,
            "invoice": {},
            "providerViewModel": {
                "isLicense": false,
                "isAssigned": false,
                "isInsurance": false,
                "profileCreated": false
            },
            "attachments": []
        },
        "isPropertyRelatedJob": false,
        "id": "00000000-0000-0000-0000-000000000000",
        "address": "",
        "awardedTradeeId": "",
        "contactPersonName": "",
        "endTime": 0,
        "serviceConsumer": {
            "pricingPlan": {
                "featuresList": {},
                "isMyPlan": false
            },
            "hasPickedAPlan": false,
            "userSetting": {
                "isInsuranceRequired": false,
                "isEnableSubTask": false,
                "isLicenceRequired": false,
                "isAbnRequired": false
            }
        },
        "taskStatus": 0,
        "whereJobLocated": "",
        "countryId": "",
        "jobUrgencyId": 1,
        "liteBidList": [],
        "primaryJobTypeDisplay": "",
        "primaryJobType": "",
        "jobCategoryId": "00000000-0000-0000-0000-000000000000",
        "taskStatuses": 0,
        "isHideTenantDetailsToOwner": false,
        "isCloneFromJobPost": false,
        "hasBidsForOwnerApproval": false,
        "contactPersonNumber": "",
        "initialPayment": 0,
        "hourlyRate": 0,
        "completedAttachments": [],
        "latitude": 0,
        "placeBidType": 1,
        "isDeclined": false,
        "suburbId": "",
        "jobValidityPeriodDisplay": "N/A",
        "startTime": 0,
        "bidStatus": 0,
        "suburbDisplay": "",
        "isPendingPayment": false,
        "secondaryJobTypeDisplay": "",
        "invoice": {},
        "budgetDisplay": "",
        "callOutFee": 0,
        "budgetId": 0,
        "jobValidityPeriod": 0,
        "quoteLimit": 6,
        "scheduledType": 0,
        "jobInformation": {
            "hasBidNotes": false,
            "hasInvoice": false,
            "requireReInvite": false,
            "requireApproveSchedule": false,
            "hasJobNotes": false,
            "hasRecuringJobs": false,
            "requireOwnerApproval": false,
            "requireSchedule": false,
            "hasVideoCalls": false,
            "hasGroupedJobs": false
        },
        "isOwnerAttending": false,
        "secondaryJobType": "",
        "bidCount": 0,
        "serviceConsumerId": "",
        "targetedTradeeOption": 1,
        "invoiceAmount": 0,
        "consumerTypePropertyId": "",
        "budgetAmount": 0,
        "payerId": "",
        "secondJobTypeLevel": "",
        "isRecurred": false,
        "bidAmount": 0,
        "tenantName": "",
        "IsOverrideOwner": false,
        "jobAsignees": [],
        "postedUserId": "",
        "jobVariations": [],
        "bidRevisedPriceAvailable": false,
        "whereToCome": "",
        "jobGroupSequence": 0,
        "isShowTenantOnQuote": false,
        "isJobCompletedByTenant": false,
        "jobUrgencyDisplay": "Urgently",
        "bidPlaced": false,
        "title": "",
        "bidRevisionRequested": false,
        "status": 0,
        "maintenanceType": 0,
        "awardedAmountMax": 0,
        "propertyTypeId": 0,
        "awardedAmount": 0,
        "awardedNotes": "",
        "jobCountryId": "",
        "isCloning": false,
        "jobPropertyLocks": [],
        "userType": 8,
        "budgetMax": 0,
        "jobType": "",
        "tradees": [
            {
                "lastName": "",
                "averageRating": '',
                "jobTypeId": "",
                "branchId": "",
                "status": 0,
                "profileCreated": false,
                "hourlyRate": 0,
                "calloutFee": 0,
                "name": "",
                "id": "",
                "email": "",
                "companyName": "",
                "firstName": "",
                "isAssigned": true,
                "profileImageUrl": "",
                "branchTradeeStatus": 1,
                "branchName": "",
                "isLicense": false,
                "jobType": "",
                "isInsurance": false,
                "userId": ""
            }
        ],
        "bidRevisedPrice": 0,
        "isDirectWorkorder": false,
        "placeBidTypeDisplay": "N/A",
        "isTenantPost": true,
        "recurSettings": {}
    }

     
}

const mapStateToProps = (state, ownProps) => {
    try {
        return {
            jobTypes: state.Jobs.jobTypes,
            dateFormat: state.User.dateFormat,
            userRoles: state.User.userRoles,
            properties: state.Tenant.tenantProperties,
            isCurrentTenant: state.Tenant.isCurrentTenant,
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateProperties: properties => {
            dispatch({ type: "SET_PROPERTIES", properties: properties });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TenantManageJobPostWizard);
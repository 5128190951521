import React from 'react';
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { withRouter, Link } from 'react-router-dom';
import Viewer from "react-viewer";

// Plugins
import { toast } from "react-toastify";
import { registerPlugin, FilePond } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { withTranslation, Trans } from 'react-i18next';
// Components
import Drawer from "../../Common/Drawer/Drawer";

// Styles

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class ExternalAddAttachments extends React.Component {

    state = {
        isUploading: false,
        showImageViewer: false,
        imageUrl: '',
        downloadImageUrl: '',
        attachments: [],
        showAttachmentsDrawer: false
    }

    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        let drawer = this.createDrawer(t);
        return <div>{drawer}</div>;
    }

    createDrawer = (t) => {
        let header = this.createHeader(t);
        let body = this.createBody(t);
        let footer = this.createFooter(t);
        return (
            <>
                <div className="form-group">
                    <div
                        className="form-control rich-control"
                        onClick={this.toggleAttachmentsDrawer}
                    >
                        <span>
                            <img src="/Images/upload-icon.png" alt="upload-icon" />
                        </span>
                        <label>{t('btn_addimages_videos')}</label>
                        <div className="plus-icon">
                            <i className="ba-icn ba-icn-close"></i>
                        </div>
                    </div>
                </div>
                <Drawer
                    size={"sm"}
                    open={this.state.showAttachmentsDrawer}
                    header={header}
                    body={body}
                    footer={footer}
                ></Drawer>
            </>
        );
    };

    createHeader = (t) => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    {/* {this.props.title} */}
                    {t('hdr_attachment_drawer')}
                </h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        type="button"
                        onClick={this.toggleAttachmentsDrawer}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createBody = (t) => {
        const files = this.getUploadedImages();
        return (
            <div className="panel-body">
                <div className="form-group">
                    <label>
                        <Trans i18nKey="lbl_attachment_drawer_body">
                            Please upload some <strong>{{ name: t('lbl_attachment_drawer_highlited') }}</strong> to
                            provide further information for service providers to more accurately
                            quote.
                        </Trans>

                    </label>
                    <div className="form-control rich-control d-none">
                        <span>
                            <img src="/Images/upload-icon.png" alt="upload-icon" />
                        </span>
                        <label>{t('btn_addimages_videos')}</label>
                        <div className="plus-icon">
                            <i className="ba-icn ba-icn-close"></i>
                        </div>
                    </div>
                    <label labelfor="test123" className="file-upload d-none">
                        <span>Add Attachments(s)</span>
                        <span className="font-weight-bold">
                            (.jpg,.jpeg,.png,.png,.mp4,.csv,.pdf)
                        </span>
                        <br></br>
                        <input
                            name="test123"
                            type="file"
                            placeholder="Select a file"
                            className="invicible"
                        />
                    </label>
                    <FilePond
                        ref={ref => (this.pond = ref)}
                        name={"filepond--browser"}
                        type={"remote"}
                        files={this.state.attachments}
                        allowMultiple={true}
                        server={{
                            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                // fieldName is the name of the input field
                                // file is the actual file object to send
                                if (file.name.includes('.msg') || file.name.includes('.eml') || file.type === 'message/rfc822' || file.name.includes('.mp3')) {
                                    toast.error('Please Select valid types of file.', {
                                        position: toast.POSITION.BOTTOM_RIGHT
                                    });
                                    abort();
                                    return;
                                }

                                const formData = new FormData();
                                formData.append("file", file);
                                if (file.name.includes('.msg') || file.name.includes('.eml') || file.type === 'message/rfc822') {
                                    toast.error('Please Select valid types of file.', {
                                        position: toast.POSITION.BOTTOM_RIGHT
                                    });
                                    abort();
                                    return;
                                }

                                const request = new XMLHttpRequest();
                                const attComponent = this;
                                request.open("POST", `/tenant-api/external-file-upload?id=${this.props.jobDetails.id}&persist=true&userId=${this.props.jobDetails.serviceConsumerId}`);
                                {
                                    /* request.setRequestHeader("Content-Type", "multipart/form-data"); */
                                }

                                // Should call the progress method to update the progress to 100% before calling load
                                // Setting computable to false switches the loading indicator to infinite mode
                                request.upload.onprogress = e => {
                                    progress(e.lengthComputable, e.loaded, e.total);
                                };

                                // Should call the load method when done and pass the returned server file id
                                // this server file id is then used later on when reverting or restoring a file
                                // so your server knows which file to return without exposing that info to the client
                                request.onload = () => {
                                    if (request.status >= 200 && request.status < 300) {
                                        // the load method accepts either a string (id) or an object
                                        load(request.responseText);
                                        {/* attComponent.props.jobDetails.attachments.push(
                                            JSON.parse(request.response)
                                        ); */}

                                        {/* this.setState({ isUploading: false }); */ }
                                        let jobDetails = this.props.jobDetails;
                                        jobDetails.attachments.push(JSON.parse(request.response));
                                        this.props.setJobDetails(jobDetails);

                                        {/* setTimeout(() => {
                                            this.setState({
                                                downloadImageUrl: ' '
                                            })
                                        }, 1000); */}

                                    } else {
                                        // Can call the error method if something is wrong, should exit after
                                        error("oh no");
                                    }
                                };

                                request.send(formData);

                                // Should expose an abort method so the request can be cancelled
                                return {
                                    abort: () => {
                                        // This function is entered if the user has tapped the cancel button
                                        request.abort();

                                        // Let FilePond know the request has been cancelled
                                        abort();
                                    }
                                };
                            },
                            revert: (uniqueFileId, load, error) => {
                                let attComponent = this;
                                const request = new XMLHttpRequest();
                                let fileObj = JSON.parse(uniqueFileId);
                                request.open(
                                    "GET",
                                    `/tenant-api/delete-file?fileId=${fileObj.fileId}`
                                );

                                request.onload = function () {
                                    if (request.status >= 200 && request.status < 300) {
                                        // the load method accepts either a string (id) or an object
                                        load();
                                        let att = attComponent.props.jobDetails.attachments.filter(a => {
                                            return a.fileId != fileObj.fileId;
                                        });
                                        attComponent.props.jobDetails.attachments = att;
                                    } else {
                                        // Can call the error method if something is wrong, should exit after
                                        error("oh no");
                                    }

                                };

                                request.send();

                                return {
                                    abort: () => {
                                        // This function is entered if the user has tapped the cancel button
                                        request.abort();
                                    }
                                };
                                // Can call the error method if something is wrong, should exit after
                                // Should call the load method when done, no parameters required
                            }
                        }}
                        oninit={() => this.handleInit()}
                        labelIdle={t('lbl_attachments_drawer_imagetypes')}
                    ></FilePond>
                </div>
                {files}
            </div>
        );
    };

    getUploadedImages = () => {
        if (this.props.jobDetails.hasOwnProperty("attachments")) {
            const uploadedFiles = this.props.jobDetails.attachments.map(file => {
                return <div className="fileslider-item"><img src={file.fileUrl} alt="" className="uploaded-img" /></div>;
            });

        }

    };

    createFooter = (t) => {
        return (
            <>
                <div className="card-actions">
                    <button onClick={this.onAttachToPost} type="button">
                        <i className="ba-icn ba-icn-ok"></i>
                        {t('btn_done')}
                    </button>
                    <button
                        className="more-actions"
                        type="button"
                        onClick={this.toggleAttachmentsDrawer}
                    >
                        {" "}
                        {t('btn_cancel')}{" "}
                    </button>
                </div>
            </>
        );
    };

    onAttachToPost = () => {
        this.toggleAttachmentsDrawer();
    };

    handleInit = () => { };

    toggleAttachmentsDrawer = () => {
        this.setState({ attachments: [] });
        this.setState({
            showAttachmentsDrawer: !this.state.showAttachmentsDrawer
        });
        this.props.onSubmitAttachments(this.props.jobDetails.attachments);
    };

    rendersss() {
        const { jobDetails, isJobDetailsLoading } = this.props;
        const { isUploading, showImageViewer, imageUrl, downloadImageUrl } = this.state;
        return <>
            {(isJobDetailsLoading) ?
                <h2 className="align-items-center d-block">
                    <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />
                </h2>
                :
                <div className="row attachments-slide">
                    {(jobDetails == null) ? '' :
                        jobDetails.attachments.map(element => {
                            var defaultFileSrc = "/Images/attachment.png";
                            switch (element.extension) {
                                case '.csv':
                                    defaultFileSrc = "/Images/attached-file-csv.svg";
                                    break;
                                case '.pdf':
                                    defaultFileSrc = "/Images/attached-file-pdf.svg";
                                    break;
                                case '.mov':
                                case '.mp4':
                                    defaultFileSrc = "/Images/attached-file-mov.svg";
                                    break;
                                case '.png':
                                case '.jpg':
                                case '.jpeg':
                                    defaultFileSrc = element.fileUrl;
                                    break;
                                case '.tiff':
                                    defaultFileSrc = "/Images/attached-file-tiff.svg";;
                                    break;
                                default:
                                    break;
                            }
                            return <div className="col-md-2 pr-2 pl-2 pb-3">
                                <div className="card h-100">
                                    <img
                                        src={defaultFileSrc}
                                        className="img-thumbnail"
                                        alt={element.fileName}
                                        downloadUrl={element.fileUrl}
                                        onClick={(event) => {
                                            this.setState({
                                                showImageViewer: true,
                                                imageUrl: event.target.src,
                                                downloadImageUrl: element.fileUrl
                                            })
                                        }}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = defaultFileSrc;
                                        }} />

                                </div>
                            </div>
                        })
                    }
                    <div className="col-md-2 pr-2 pl-2 pb-3">
                        <div className="card text-center py-4 h-100">
                            {(isUploading) ?
                                <h2 className="align-items-center d-block mt-3">
                                    <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />
                                </h2> : <>
                                    <i className="ba-icn ba-icn-plus add-new-image" onClick={() => document.getElementById('upload-file').click()} ></i>
                                    <input type="file" name="upload-file" id="upload-file" onChange={(event) => this.onFileSelected(event)} className="d-none" />
                                </>
                            }
                        </div>
                    </div>
                    <Viewer
                        visible={showImageViewer}
                        downloadable={true}
                        changeable={false}
                        downloadInNewWindow={true}
                        onClose={() => {
                            this.setState({ showImageViewer: false })
                        }}
                        images={[{ src: imageUrl, alt: "", downloadUrl: downloadImageUrl }]}
                    />
                </div>
            }

        </>
    }

    onFileSelected = (event) => {
        const jobDetails = this.props.jobDetails;

        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        const request = new XMLHttpRequest();

        this.setState({ isUploading: true });

        request.open("POST", `/tenant-api/external-file-upload?id=${jobDetails.id}&persist=true&userId=${jobDetails.serviceConsumerId}`);

        request.onload = () => {
            this.setState({ isUploading: false });
            if (request.status >= 200 && request.status < 300) {
                jobDetails.attachments.push(JSON.parse(request.response));
                this.props.setJobDetails(jobDetails);
            } else {

            }
        };
        request.send(formData);
    }
}

const mapStateToProps = (state, ownProps) => {
    try {
        return {
            jobDetails: state.Jobs.jobDetails,
            isJobDetailsLoading: state.Jobs.isJobDetailsLoading,
            jobTypes: state.Jobs.jobTypes,
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setJobDetails: jobDetails => { dispatch({ type: 'SET_JOB_DETAILS', jobDetails: jobDetails }) },
        setJobDetailsLoadingStatus: status => { dispatch({ type: 'SET_JOB_DETAILS_LOADING_STATUS', status: status }) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExternalAddAttachments));
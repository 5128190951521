import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { Modal, Button, Tabs, Tab, Badge, Form } from "react-bootstrap";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";

import Drawer from "../../Common/Drawer/Drawer";
import "./jobdetailsdrawer.scss";
import JobDetail from "./JobDetail/JobDetail";
import FeedbackDrawer from "../../Drawers/FeedbackDrawer/FeedbackDrawer";
import JobApproveDrawer from "../../Drawers/JobApproveDrawer/JobApproveDrawer";
import RejectDrawer from "../../Drawers/JobRejectDrawer/JobRejectDrawer";
import { UserType, WorkflowStates, JobsListTypes } from "../../../Constants/Enums";
import bidService from "../../../Services/BidService";
import Quotes from "../../Drawers/JobDetailsDrawer/Quotes/Quotes";
import JobNotes from "./JobNotes/JobNotes";
import ScheduleJobDrawer from "../ScheduleJobDrawer/ScheduleJobDrawer";
import { AppConfiguration } from "read-appsettings-json";
import JobCancelDrawer from "../JobCancelDrawer/JobCancelDrawer";
import JobVariationDrawer from "../JobVariationDrawer/JobVariationDrawer";
import EventScheduleDrawer from "../EventScheduleDrawer/EventScheduleDrawer";
import settingsService from "../../../Services/settingsService";

class JobDetailsDrawer extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        jobDetails: {
            workflowStatusId: 0            
        },
        jobNotes: [],
        attachments: [],
        quotes: [],
        jobVariation: {},
        showScheduleJob: false,
        isJobDetailsLoading: false,
        isJobQuotesLoading: false,
        isJobNotesLoading: false,
        isPostingData: false,
        isJobQuotesOwnerApprovalPending: false,
        showAverageCostWhenOwnerApproval: false,
        isOrganizationSettingsLoaded: false,
        isTenantManageJob:this.props.isTenantManageJob?true:false
    }

    render() {
        let drawer = this.createDrawer();
        return <div>{drawer}</div>;
    }

    componentDidMount() {
        this.getJobDetailsByJobId(this.props.jobId);
        this.getQoutesByJobId(this.props.jobId);
        this.getJobNotesByJobId(this.props.jobId);
        this.getOrganizationSettings(localStorage.getItem('orgId'));
    }

    getOrganizationSettings(organizationId) {
        settingsService.getOrganizationSettingsByOrgId(organizationId)
            .then((res) => {
                this.setState({
                    showAverageCostWhenOwnerApproval: res.organizationSettingsModel.showAverageCostWhenOwnerApproval,
                    isOrganizationSettingsLoaded: true
                })
            })
    }

    createDrawer = () => {
        if (this.props.drawerOpen) {
            let header = this.createHeader();
            let body = this.createBody();
            let footer = this.state.isTenantManageJob?<></>:this.createFooter();
            return (
                <Drawer
                    size={"lg"}
                    open={this.props.drawerOpen}
                    header={header}
                    body={body}
                    footer={footer}
                ></Drawer>
            );
        }
    };

    createHeader = () => {
        const { isJobDetailsLoading, isJobNotesLoading, isJobQuotesLoading } = this.state;
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Job Details 
                    {(isJobDetailsLoading || isJobNotesLoading || isJobQuotesLoading) ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : ''}
                </h2>
                <div className="panel-header-actions">
                    {/* <button className="action-btn"><i className="ba-icn ba-icn-edit"></i><span className="button-label">Edit</span></button> */}
                    {/* <ChatDrawer></ChatDrawer> */}
                    <button className="action-btn panel-close" onClick={() => this.props.toggleDrawer(false)}>
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };


    createBody = () => {
        const { jobDetails, jobNotes, isJobDetailsLoading, isJobQuotesLoading, isJobNotesLoading, isJobQuotesOwnerApprovalPending } = this.state;
        return (            
            <>
                <div className={(isJobDetailsLoading || isJobQuotesLoading || isJobNotesLoading) ? 'd-none' : 'panel-tabs'}>
                    {(!isJobDetailsLoading && this.state.isOrganizationSettingsLoaded) ?
                    <Tabs
                        defaultActiveKey={isJobQuotesOwnerApprovalPending ? "quotes" : "details"}
                        id="uncontrolled-tab-example">
                        <Tab eventKey="details" title="Details">
                            <JobDetail
                                    jobDetails={jobDetails}
                                    isMyJobs={this.props.isMyJobs}
                                    getJobDetails={(event) => { this.getJobDetailsByJobId(event) }}
                                    showAverageCostWhenOwnerApproval={this.state.showAverageCostWhenOwnerApproval}
                                    isTenantManageJob={this.state.isTenantManageJob}>
                            </JobDetail>
                        </Tab>
                        {(this.state.quotes && this.state.quotes.length > 0 && (localStorage.getItem('userType') == '5' || (jobDetails && jobDetails.propertyId == null))) ?
                            <Tab eventKey="quotes" title="Quotes">
                                <div className="panel-body tabbed-panel">
                                    <div className="quotes-container">
                                        <Quotes bids={this.state.quotes} jobDetails={jobDetails} onApproveBid={ (event) => this.onApproveBid(event)}></Quotes>
                                    </div>
                                </div>
                            </Tab> : ''
                        }
                        <Tab eventKey="jobNotes" title="Job Notes">
                            <JobNotes jobNotes={jobNotes}></JobNotes>
                        </Tab>
                    </Tabs> : <></>}
                </div>
            </>            
        );
    };

    createFooter = () => {
        const buttons = this.GetButtonsByUserType();
        const feedbackButton = this.getFeedbackButton();
        const wordId = this.props.workFlowId
        const { isJobDetailsLoading, isJobQuotesLoading, isJobNotesLoading } = this.state;

            return (
                <>
                    <div className={(isJobDetailsLoading || isJobQuotesLoading || isJobNotesLoading) ? 'd-none' : 'card-actions'}>
                    {(this.state.jobDetails.acceptedBid != null &&  this.state.jobDetails.acceptedBid.isSharedSchedule && this.state.jobDetails.workflowStatusId === 4) ? 
                            <EventScheduleDrawer
                            jobDetails={this.state.jobDetails}></EventScheduleDrawer>: ''} 
                        {buttons}
                        {feedbackButton}
                        {(this.state.jobDetails.workflowStatusId === 4 && this.state.jobDetails.scheduleEventId != null && this.state.jobDetails.scheduleEventStatus !== 2 && this.state.jobDetails.scheduleEventStatus !== 4 && this.state.jobDetails.scheduleEventStatus !== 1) ?
                                <button onClick={() => this.onAcceptSchedule()}>
                                    <i className="ba-icn ba-icn-calendar-gray"></i> Accept
                                </button> : ''}
                            {(this.state.jobDetails.workflowStatusId === 4 && this.state.jobDetails.scheduleEventId != null && this.state.jobDetails.scheduleEventStatus !== 2 && this.state.jobDetails.scheduleEventStatus !== 4 && this.state.jobDetails.scheduleEventStatus !== 1) ?
                                <button onClick={() => this.onRejectSchedule()}>
                                    <i className="ba-icn ba-icn-calendar-gray"></i> Reject
                                </button> : ''}
                        
                        {(this.state.jobDetails.workflowStatusId === 4 && this.state.jobDetails.scheduleEventId != null && this.state.jobDetails.acceptedBid != null &&  !this.state.jobDetails.acceptedBid.isSharedSchedule) ?
                            <React.Fragment>
                                <button onClick={() => this.toggleSheduleJobDrawer()}>
                                    <i className="ba-icn ba-icn-calendar-gray"></i> Re-Schedule Job
                                </button>
                                {(this.state.showScheduleJob) ?
                                    <ScheduleJobDrawer
                                        showScheduleJobDrawer={this.state.showScheduleJob}
                                        jobDetails={this.state.jobDetails}
                                        toggleSheduleJobDrawer={() => this.toggleSheduleJobDrawer()}
                                        getJobDetails={(event) => { this.getJobDetailsByJobId(event) }}
                                        CloseJobDetailsDrawer={(event) => this.props.toggleDrawer(event)}>
                                    </ScheduleJobDrawer> : ''
                                }
                            </React.Fragment> : ''
                        }
                        {(wordId == 1) ?
                            <button onClick={() => this.onSubmitJob()} className="d-block"> 
                                <i className="ba-icn ba-icn-send"></i> Post{" "}
                            </button> : ''
                        }  
                        
                        {(this.state.jobDetails.isOwnerApprovalPendingForVariation &&
              this.state.jobDetails.jobVariations &&
              this.state.jobDetails.jobVariations.length > 0 &&
              this.state.jobDetails.jobVariations[0].serviceStatus == 0 ) ?
                            <JobVariationDrawer
                                toggleDrawer={(event) => this.props.toggleDrawer(event)}
                                jobDetails={this.state.jobDetails}
                                getJobDetails={(event) => this.getJobDetailsByJobId(event)}>
                            </JobVariationDrawer> : ''
                        }
    
                        {(this.state.jobDetails.workflowStatusId == 107 && localStorage.getItem('userType') != '5' && this.state.jobDetails.isTenantPendingReviewRejected == 0) ?
                            <RejectDrawer
                                toggleDrawer={(event) => this.props.toggleDrawer(event)}
                                jobDetails={this.state.jobDetails}
                                getJobDetails={(event) => this.getJobDetailsByJobId(event)}>
                            </RejectDrawer> : ''
                        }
                        
                    </div>
                </>
            );
    };

    onDeclineAllQuotes = () => {
        bidService.declineAllBidsByOwner(this.state.jobDetails.id, "").then(res => {
            toast.success('All bids decline by owner', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            this.getQoutesByJobId(this.state.jobDetails.id);
            this.getJobDetailsByJobId(this.state.jobDetails.id)
        });
    }

    onApproveBid = () => {
        this.getQoutesByJobId(this.state.jobDetails.id);
        this.getJobDetailsByJobId(this.state.jobDetails.id)
    }

    toggleSheduleJobDrawer = () => {
        this.setState({
            showScheduleJob: !this.state.showScheduleJob
        });
    }

    onSubmitJob = () => {
        const draftedJob = this.state.jobDetails;
        draftedJob.workflowStatusId = 2
        draftedJob.serviceConsumerId = localStorage.getItem('userId');
        draftedJob.isUpdatingJobStaus = true;
        draftedJob.tradees = draftedJob.tradees == null ? [] : draftedJob.tradees;
        this.setState({ isPostingData: true });
        try {
            trackPromise(

                AXIOS.POST(`tenant-api/job-post`, draftedJob, {
                    'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret }
                })
                    .then(dataJson => {
                        this.setState({ isPostingData: false });
                        if (dataJson == 'Request Unauthorized' || dataJson == 'Job creation failed') {
                            toast.error('Fail to post the job. Please try again.',{
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        } else {
                            toast.success('Job is posted successfully.',{
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            this.props.toggleDrawer(true);
                            let { history } = this.props;
                            history.push({ pathname: '/' });
                        }
                    })

            )

        } catch (error) {

        }
    }

    getJobDetailsByJobId = jobId => {
        const userId = localStorage.getItem("userId");
        this.setState({isJobDetailsLoading: true})
        if (jobId) {
            try {
                AXIOS.GET(`tenant-api/job?jobId=${jobId}&userId=${userId}`).then(
                    data => {
                        this.setState({
                            isJobDetailsLoading: false,
                            jobDetails: data,   
                            isJobQuotesOwnerApprovalPending: data.isJobQuotesOwnerApprovalPending,
                            attachments: (!data.attachments) ? [] : data.attachments.map(a => {
                                return {
                                    source:
                                        "https://ancillary-dev-images-compressed.azureedge.net/" +
                                        a.fileId +
                                        a.extension,
                                    options: {
                                        type: "local"
                                    }
                                };
                            })
                        });
                    }
                )
            } catch (error) {
                console.log(error);
            }
        }
    };

    //Api call to get Job-notes
    getJobNotesByJobId = jobId => {
        const userId = localStorage.getItem("userId");
        this.setState({isJobNotesLoading: true})
        if (jobId) {
            try {
                AXIOS.GET(
                    `tenant-api/job-notes?jobId=${jobId}&userId=${userId}`
                ).then(data => {
                    this.setState({
                        jobNotes: data,
                        isJobNotesLoading: false
                    });
                })
            } catch (error) {
                console.log(error);
            }
        }
    };

    //get Qoutes/Bids Details
    getQoutesByJobId = jobId => {
        const userId = localStorage.getItem("userId");
        this.setState({isJobQuotesLoading: true})
        if (jobId) {
            try {
                bidService.getQoutesByJobId(jobId, userId)
                        .then(data => {
                            this.setState({
                                quotes: data.bidsList,
                                isJobQuotesLoading: false
                            });
                        })
            } catch (error) {
                console.log(error);
            }
        }
    };

    getFeedbackButton = () => {
        if (
            localStorage.getItem('userType') == '4' && 
            this.state.jobDetails.acceptedBid != null &&
            (this.state.jobDetails.workflowStatusId ===
                WorkflowStates.JobPendingReview ||
                this.state.jobDetails.workflowStatusId ===
                WorkflowStates.JobCompleted ||
                this.state.jobDetails.workflowStatusId == 107) &&
            this.state.jobDetails.thirdPartyTradeeEmail == null &&
            this.state.jobDetails.isTenantPendingReviewRejected == 0 &&
            (this.state.jobDetails.propertyId == null ||
                this.state.jobDetails.workflowStatusId == 107
                && this.state.jobDetails.propertyId != null)
        ) {
            return (
                <FeedbackDrawer jobDetails={this.state.jobDetails}></FeedbackDrawer>
            );
        }
    };

    GetButtonsByUserType = () => {
        if (localStorage.getItem('userType') == '5') {
            if (
                this.state.jobDetails.workflowStatusId ==
                WorkflowStates.JobAwaitingOwnerApproval && !(this.state.quotes && this.state.quotes.length > 0 && this.state.isOrganizationSettingsLoaded && !this.state.isJobDetailsLoading)
            ) {
                return (
                    <>
                        <JobApproveDrawer
                            toggleDrawer={(event) => this.props.toggleDrawer(event)}
                            jobDetails={this.state.jobDetails}
                            getJobDetails={(event) => this.getJobDetailsByJobId(event)}
                            showAverageCostWhenOwnerApproval={this.state.showAverageCostWhenOwnerApproval}
                        ></JobApproveDrawer>
                        <RejectDrawer
                            toggleDrawer={(event) => this.props.toggleDrawer(event)}
                            jobDetails={this.state.jobDetails}
                            getJobDetails={(event) => this.getJobDetailsByJobId(event)}
                        ></RejectDrawer>
                    </>
                );
            } else {
                let isReplyed = this.state.quotes.filter(a => a.quoteApprovalStatusId == 1 || a.quoteApprovalStatusId == 2).length > 0
                if(isReplyed){
                    return '';
                } 
            }
        }
    };

    onAcceptSchedule = () => {
       
        let obj = {};

        obj.JobId = this.state.jobDetails.id;
       
        obj.id = this.state.jobDetails.scheduleEventId;
            
        obj.type = 0;
        obj.status = 2;
        obj.bidId = this.state.jobDetails.acceptedBid.bidId;
        obj.userId = localStorage.getItem('userIdDecripted');
       
            
        try {
            AXIOS.POST(`tenant-api/save-schedule-job?userId=${localStorage.getItem('userId')}`, obj).then(response => {
                if (response) {
                    toast.success('Job Schedule Accepted', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.getJobDetailsByJobId(this.state.jobDetails.id);
                }
                else {
                    toast.error('Job Schedule Acceptance Failed', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
       

    };

    onRejectSchedule = () => {

        let obj = {};

        obj.JobId = this.state.jobDetails.id;

        obj.id = this.state.jobDetails.scheduleEventId;

        obj.type = 0;
        obj.status = 3;
        obj.bidId = this.state.jobDetails.acceptedBid.bidId;
        obj.userId = localStorage.getItem('userIdDecripted');
        

        try {
            AXIOS.POST(`tenant-api/save-schedule-job?userId=${localStorage.getItem('userId')}`, obj).then(response => {
                if (response) {
                    toast.success('Job Schedule Rejected', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.getJobDetailsByJobId(this.state.jobDetails.id);
                }
                else {
                    toast.error('Job Schedule Rejection Failed', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }


    };

}

export default JobDetailsDrawer;

import React from 'react';
import Loader from "react-loader-spinner";
import { Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import externalService from '../../../Services/ExternalService';
import { connect } from 'react-redux';

class TroubleShootGuidePage extends React.Component {

    state = {
        troubleShootGuideId: this.props.troubleShootGuideId,
        troubleShootGuide: "Not Available.",
        isLoading: false,
        attachments: []
    }

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { troubleShootGuideId } = this.props;
        this.getTroubleShootGuide(troubleShootGuideId);
    }

    getTroubleShootGuide = (troubleShootGuideId) => {
        this.setState({ isLoading: true });
        externalService.getTroubleShootGuideById(troubleShootGuideId).then(res => {
            if (res.responseCode === 12) {
                let textArray = [];
                let imageArray = [];
                var rgx = /(https?:\/\/[^\s]+(?:png|jpe?g|gif))/g;
                var sections = res.troubleshootGuide.split(rgx);

                if (sections.length == 1) {
                    textArray.push(sections[0])
                } else if (sections.length > 1) {
                    for (var i = 0; i < sections.length; i++) {
                        if ((/\.(gif|jpg|jpeg|tiff|png)$/i).test(sections[i])) {

                            let url = sections[i];
                            var extentionIndex = url.lastIndexOf('.');
                            var nameIndex = url.lastIndexOf('/');
                            var extention = url.substr(extentionIndex);
                            var fileId = url.substr(nameIndex).split('.')[0]

                            var image = {
                                extension: extention,
                                fileId: fileId,
                                fileName: extention+fileId,
                                fileType: 1,
                                fileUrl: url
                            }
                            imageArray.push(image);
                        }
                        else {
                            if (sections[i] != "" && sections[i] != null && sections[i]) {
                                textArray.push(sections[i]);
                            }
                        }
                    }
                }

                this.setState({
                    troubleShootGuide: textArray.join('').replace(/<img[^>]*>/g,""),
                    attachments: imageArray,
                    isLoading: false
                });
            }
        });
    }
    render() {

        const { isLoading, troubleShootGuide, attachments } = this.state;
        return (
            <div className="App">
                <div className="home-wrapper container-fluid-wrapper">
                    <h1>Troubleshooting guidelines</h1>
                    <div className="row row job-list-card">
                        {isLoading ? <h2 className="align-items-center d-block">
                            <Loader type="ThreeDots" color={"#DB3D44"} height={10} width={100} />
                        </h2> :
                            <div style={{ lineHeight: "25px", width: "100%" }}>
                                <div className="mt-4 p-5 innerHtmlContainer job-list-card-body">'
                                    <div dangerouslySetInnerHTML={{ __html: `${troubleShootGuide}` }}>
                                    </div>
                                    {attachments && attachments.length>0?
                                    <div className="mt-3">
                                        <h6>Attachment(s)</h6>
                                        <div className="mb-5">
                                            <ImageViewer
                                                attachments={attachments}
                                                isAfter={false}
                                                isExternal={true}
                                            ></ImageViewer>
                                        </div>
                                    </div>:<></>
                                    }
                                </div>

                            </div>}
                    </div>
                </div>
            </div>
        )
    }

}

export default connect()(TroubleShootGuidePage);
import React from 'react';
import { connect } from 'react-redux';
import Conversation from './Conversation/Conversation';

class ConversationList extends React.Component {

    state = {
        conversationList: [],
        chatRecived: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const chatList = this.getChatList();
        return (
            <>
                <div className="chat-user-search">
                    <input type="text" placeholder="Search..." onChange={event => this.searchUserByUserName(event.target.value)} />
                </div>

                <div className="chat-users panel-body">
                    {chatList}
                </div>
            </>
        );
    }

    getChatList = () => {
        var conversationList = this.state.conversationList.length === 0 ? this.props.conversationList : this.state.conversationList;
        const listItems = conversationList.map((chatuser, index) => {
            return <Conversation key={index} conversation={chatuser}></Conversation>
        })
        return listItems;
    }

    searchUserByUserName($event) {
        this.setState({
            conversationList: this.props.conversationList
        });
        if ($event.length > 2) {
            var filterdList = new Array();
            this.state.conversationList.forEach(option => {
                if (option.conversationName != null || option.conversationName != undefined) {
                    if (option.conversationName.toLowerCase().includes($event.toLowerCase())) {
                        filterdList.push(option);
                    }
                }
            });
            this.setState({
                conversationList: filterdList
            });
        }
        return;
    }

}

const mapStateToProps = (state, ownProps) => {
    try {
        let userDetails = state.User
        return {
            userDetails: userDetails,
            chatBoxes: state.Conversation.conversationList,
        };
    } catch (error) {
        console.log(error)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserDetails: (details) => { dispatch({ type: 'SET_USER_DETAILS', userDetails: details }) },
        updateChatboxes: chatList => { dispatch({ type: "SHOW_CONVERSATION", chatList: chatList }) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);
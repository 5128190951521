import React, { useState } from "react";
import { ButtonToolbar, ToggleButtonGroup, ToggleButton, Form, Button } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import Drawer from "../../../Common/Drawer/Drawer";
import jobService from "../../../../Services/JobService";
import { toast } from 'react-toastify';
import { trackPromise } from "react-promise-tracker";
import ProviderProfileDrawer from "../ProviderProfileDrawer/ProviderProfileDrawer";
import StarRatingComponent from "react-star-rating-component";
import Loader from "react-loader-spinner";
import settingsService from "../../../../Services/settingsService";

class InviteTradee extends React.Component {

    state = {
        showDrawer: false,
        isSelectedOnDemand: true,
        map: null,
        quoteLimit: [
            { key: 1, text: '1', value: 1 },
            { key: 2, text: '2', value: 2 },
            { key: 3, text: '3', value: 3 },
            { key: 4, text: '4', value: 4 },
            { key: 5, text: '5', value: 5 },
            { key: 6, text: '6', value: 6 },
            { key: 7, text: '7', value: 7 },
            { key: 8, text: '8', value: 8 },
            { key: 9, text: '9', value: 9 },
            { key: 10, text: '10', value: 10 },
            { key: 11, text: 'Unlimited', value: 11 }
        ],
        selectedQuoteLimit: 1,
        tradeeCount: 0,
        nearBytradees: [],
        markers: [],
        selectedTradees: [],
        providerId: '',
        isShowProviderDrawer: false,
        isMarketPlaceLoading: false
    };

    notifyError = (msg) => toast.error(msg, {
        position: toast.POSITION.BOTTOM_RIGHT
    });

    constructor(props) {
        super(props);
        this.quoteLimitRef = React.createRef();
    }

    componentDidMount() {
        settingsService.getUserSettings().then(response => {
            if (response.quoteSettings !== null && response.quoteSettings.quoteLimit) {
                this.quoteLimitRef.current.value = response.quoteSettings.quoteLimit;
            } else {
                this.quoteLimitRef.current.value = 0;
            }

        });
    }

    render() {
        let drawer = this.createDrawer();
        return <div>{drawer}</div>;
    }

    toggleDrawer = () => {
        if (this.props.validateBeforeInviteTradee()) {
            this.setState({
                showDrawer: !this.state.showDrawer
            })
            if (!this.state.showDrawer) {
                this.setState({
                    selectedTradees: this.props.selectedTradees,
                    isLoadingData: true
                })
                jobService.GetJobTradeeCount(this.props.jobTypeId, this.props.location.suburbId).then(res => {
                    if (!isNaN(Number(res.toString()))) {
                        this.setState({
                            tradeeCount: res,
                            isLoadingData: false
                        });
                    } else {
                        this.setState({
                            tradeeCount: 0,
                            isLoadingData: false
                        });
                    }
                })
                this.addJobLocation(this.props.location.latitude, this.props.location.longitude, this.props.location.address);
                this.loadNearByTrade();
            }
        }
    }

    loadNearByTrade = () => {
        this.setState({
            isMarketPlaceLoading: true
        });
        
        jobService.GetMarketplace(this.props.location.suburbId, this.props.jobTypeId).then(res => {
            this.setState({
                nearBytradees: res.marketplace
            })
            let markersList = this.state.markers;
            res['marketplace'].map(
                tradie => {
                    if (tradie.latitude != 0 && tradie.longitude != 0) {
                        let random1 = this.getRandomNumber(0.0010, 0.0030);
                        let random2 = this.getRandomNumber(0.0010, 0.0030);

                        let tardieLat = tradie.latitude - random1;
                        let tardieLng = tradie.longitude - random2;
                        markersList.push({ id: tradie.userId, lat: tardieLat, lng: tardieLng, toolTip: tradie.name, isSelected: false });
                    } else {
                        markersList.push({ id: tradie.userId, lat: tradie.latitude, lng: tradie.longitude, toolTip: tradie.name, isSelected: false });
                    }
                }
            );

            if (this.state.selectedTradees.length > 0) {
                this.state.selectedTradees.forEach(tradee => {
                    markersList.forEach(marker => {
                        if (tradee.userId == marker.id) {
                            marker.isSelected = true;
                        }
                    });
                });
                // this.firstTimeLoadFlag = false;
            }

            this.setState({
                markers: markersList,
                isMarketPlaceLoading: false
            });
            this.addTradieLocations(markersList);
        });
    }

    addTradieLocations = (markers) => {
        if (markers != null && markers.length > 0) {
            markers.forEach(tradee => {
                let icon;
                if (tradee.isSelected == true) {
                    icon = "/images/dashboardN/trades-icon-on.png";
                } else {
                    icon = "/images/dashboardN/trades-icon.png";
                }
                let tradieMarker = new window.google.maps.Marker({
                    position: { lat: tradee.lat, lng: tradee.lng },
                    map: this.state.map,
                    icon: icon
                });

                let _vm = this;

                var infowindow = new window.google.maps.InfoWindow({
                    content: tradee.toolTip
                });

                tradieMarker.set("id", tradee.id);
                tradieMarker.addListener('mouseover', function () {
                    infowindow.open(_vm.state.map, tradieMarker);
                });
                tradieMarker.addListener('mouseout', () => infowindow.close())
                tradieMarker.addListener('click', function () {
                    _vm.onClickTradeeProfile(tradee.id);
                });
            });
        }
    }

    addJobLocation = (jobLat, jobLng, address) => {
        let jobLocation = { lat: jobLat, lng: jobLng };

        let map = new window.google.maps.Map(document.getElementById('gmap'), {
            zoom: 11,
            center: jobLocation
        });

        let property = new window.google.maps.Marker({
            position: jobLocation,
            map: map,
            icon: '/images/dashboardN/trades-icon-job.png'
        });

        var infowindow = new window.google.maps.InfoWindow({
            content: address
        });

        property.set("id", "property");
        property.addListener('click', function () {
            infowindow.open(map, property);
        });

        new window.google.maps.Circle({
            strokeColor: '#00D0A0',
            strokeOpacity: 0.0,
            strokeWeight: 0,
            fillColor: '#00D0A0',
            fillOpacity: 0.3,
            map: map,
            center: jobLocation,
            radius: 20000//Math.sqrt(20000) * 100
        });

        this.setState({ map: map });
    }

    selectTradee = (userId, isSelected, ratings) => {

        let tradees = this.state.nearBytradees;
        let selectedTradee = tradees.find(a => a.userId == userId);

        selectedTradee.overallRating = ratings;

        if (isSelected) {
            this.setState({
                selectedTradees: [...this.state.selectedTradees, selectedTradee]
            })
        } else {
            let filteredTradees = this.state.selectedTradees.filter(a => a.userId != userId);
            this.setState({
                selectedTradees: filteredTradees
            })
        }

    }

    onClickTradeeProfile = (userId) => {
        this.setState({
            providerId: userId,
            isShowProviderDrawer: true
        })
    }

    onCloseProviderDrawer = () => {
        this.setState({
            isShowProviderDrawer: false
        })
    }

    getRandomNumber(min, max) {
        return Math.random() * (max - min) + min;
    }

    handleQuoteLimitChange = (event, props) => {
        this.state.selectedQuoteLimit = props.value;
    }

    onAttachToPost = () => {
        const { selectedTradees, selectedQuoteLimit, isSelectedOnDemand, tradeeCount } = this.state;

        if (isSelectedOnDemand && selectedTradees.length == 0) {
            this.notifyError('There are no trades registered in the B+A available for the selected service type.');
        } else if (!isSelectedOnDemand && tradeeCount == 0) {
            this.notifyError('There are no trades registered in the B+A available for the selected service type.');
        } else {
            this.props.onAttachTradeesToTheJob({ selectedTradees, selectedQuoteLimit: this.quoteLimitRef.current.value, isSelectedOnDemand, inviteToProviders: true, tradeeCount: this.state.tradeeCount });
            this.toggleDrawer();
        }
    }

    createDrawer = () => {
        let header = this.createHeader();
        let body = this.createBody();
        let footer = this.createFooter();
        return (
            <>
                <div className="form-group">
                    <div
                        className="form-control rich-control"
                        onClick={this.toggleDrawer}
                    >
                        <span>
                            <img src="/Images/user-add-icon.png" alt="user-add-icon" />
                        </span>
                        <label>Invite Trade </label>
                        <div className="plus-icon">
                            <i className="ba-icn ba-icn-close"></i>
                        </div>
                    </div>
                </div>
                <Drawer
                    size={"lg"}
                    open={this.state.showDrawer}
                    header={header}
                    body={body}
                    footer={footer}
                ></Drawer>
            </>
        );
    };

    createHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Invite Trade
                    <Loader visible={this.state.isLoadingData || this.state.isMarketPlaceLoading} type="ThreeDots" color="#DB3D44" height={10} width={100} />
                </h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        type="button"
                        onClick={this.toggleDrawer}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createBody = () => {
        return (
            <div className={this.props.isFromBot ? "panel-body chatbot-invite-trade": "panel-body"}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group col-md-12 mb-0">
                            <ButtonToolbar className="radio-btn-group">
                                <ToggleButtonGroup type="radio" name="options" ref="inviteTradeeOption" defaultValue={this.state.isSelectedOnDemand}>
                                    <ToggleButton value={false}>Matching trades around my suburb</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div>
                    </div>
                </div>
                <hr />
                <div className={(this.state.isSelectedOnDemand) ? 'row d-none' : 'row'}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label">Number of trades job applies to : <strong>{this.state.tradeeCount}</strong></label>
                        </div>
                    </div>
                </div>
                <div className={(this.state.isSelectedOnDemand) ? 'row' : 'row d-none'}>
                    <div className="col-md-12">
                        <div id="gmap" className="w-100" style={{ height: "500px" }}></div>
                    </div>
                </div>
                <div className={(this.state.isSelectedOnDemand) ? 'row' : 'row d-none'}>
                    <div className="col-md-12 quotes-container">
                        <div className="form-group mt-3">
                            <label className="form-label">Selected Tradees: {this.state.selectedTradees.length} </label>
                        </div>
                        {this.state.selectedTradees.map((element, index) => {
                            let profileImg = (element.profileImageUrl == null || element.profileImageUrl == undefined) ? '/Images/default-profile.png' : element.profileImageUrl;
                            return (
                                <div className="quote-card" key={index} onClick={() => this.onClickTradeeProfile(element.userId)}>
                                    <div className="quote-card-header">
                                        <div className="trade-personal">
                                            <div className="trade-pic">
                                                <img
                                                    style={{ 'border-radius': '20px' }}
                                                    src={profileImg}
                                                    alt={element.email}
                                                    className="profile-pic"
                                                    onError={e => {
                                                        e.target.onerror = null;
                                                        e.target.src = "/Images/default-profile.png";
                                                    }}
                                                />
                                            </div>
                                            <div className="trade-contact">
                                                <h3>{element.name}</h3>
                                                <p>email: {element.email}</p>
                                                <p>tel: {element.phoneNumber}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <StarRatingComponent name="Overoll Feedback" editing={false} starCount={5} value={element.overallRating} />
                                            <div className="text-center"><strong>Rating: {element.overallRating}</strong></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Quote Limit</label>
                            <input type="number" className="form-control"
                                placeholder="Quote limit" aria-label="test"
                                defaultValue={5} ref={this.quoteLimitRef} min="1" onBlur={e => this.OnChangeQouteLimit(e)} onKeyUp={e => this.onKeyupQuoteLimit(e)}>
                            </input>
                        </div>
                    </div>
                </div>
                {(this.state.isShowProviderDrawer) ?
                    <ProviderProfileDrawer
                        isSelected={this.state.selectedTradees.findIndex(a => a.userId == this.state.providerId) > -1}
                        selectedTradees={this.state.selectedTradees}
                        selectTradee={this.selectTradee}
                        userId={this.state.providerId}
                        isShowProviderDrawer={this.state.isShowProviderDrawer}
                        onCloseProviderDrawer={this.onCloseProviderDrawer}
                        isFromBot={this.props.isFromBot} >
                    </ProviderProfileDrawer> : ''
                }

            </div>
        )
    }

    onKeyupQuoteLimit = (event) => {
        let value = event.target.value.replace('-', '').replace('#', '');
        this.quoteLimitRef.current.value = value == 0 ? '' : value;
    }

    OnChangeQouteLimit = (event) => {
        if (event.target.value < 1) {
            this.quoteLimitRef.current.value = 1;
        }
    }

    createFooter = () => {
        return (
            <>
                <div className="card-actions mt-3 mb-3">
                    <button onClick={this.onAttachToPost} type="button" disabled={this.state.isLoadingData}>
                        {this.state.isLoadingData ? <Loader visible={this.state.isLoadingData} type="ThreeDots" color="#DB3D44" height={10} width={100} /> : <>
                            <i className="ba-icn ba-icn-ok"></i> Done </>}
                    </button>
                    <button
                        className="more-actions border"
                        type="button"
                        onClick={this.toggleDrawer}
                    >
                        {" "}
                        Cancel{" "}
                    </button>
                </div>
            </>
        );
    };

}


export default InviteTradee;
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProfileDetails from './ProfileDetails/ProfileDetails';


class TenantProfile extends Component {

  createProfileInfoFields = () => {
    return (
      <ProfileDetails
        userData={this.props.userDetails}
        profileImageUrlEndpoint={this.props.profileThumbnailsCdnEndpoint}
      ></ProfileDetails >);
  }

  render() {

    let profileDataBody = this.createProfileInfoFields();
    return <React.Fragment>
      <div>{profileDataBody}</div>
    </React.Fragment>;
  }
}

const mapStateToProps = (state, ownProps) => {

  try {
    let userDetails = state.User

    return {
      profileThumbnailsCdnEndpoint: state.AppSettings.imageCdnEndpoint,
      userDetails: userDetails
    };
  } catch (error) {
    console.log(error)
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    updateUserDetails: (details) => { dispatch({ type: 'SET_USER_DETAILS', userDetails: details }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantProfile);

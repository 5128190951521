import React from "react";

// Plugins
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";

// Styles
import "./JobLocksDrawer.scss";

class JobLocksDrawer extends React.Component {
    state = {
        showJobLocksDrawer: false
    };

    constructor(props) {
        super(props);
    }

    render() {
        let drawer = this.createDrawer();
        return <div>{drawer}</div>;
    }

    createDrawer = () => {
        const propertyLocks = this.renderPropertyLocks();
        const { t, isFromBot } = this.props;
        return (
            <>
                <div className="form-group">
                    <div
                        className="form-control rich-control"
                        onClick={this.toggleJobLocksDrawer}
                    ><div className="d-flex align-items-center">
                        <span>
                            <img src="/Images/lock.png" alt="Lock-icon" />
                        </span>
                        <label>{isFromBot ? t('jld_lbl_smart_locks') : 'Smart Locks'}</label>
                        <div className="plus-icon">
                            <i className="ba-icn ba-icn-close"></i>
                        </div>
                    </div>
                    </div>
                </div>
                {
                    (this.state.showJobLocksDrawer) ?
                        <div className="form-group">{propertyLocks}</div>
                        : <div className="card red-card">{isFromBot ? t('jld_info_no_results') : 'No Results Found'}</div>
                }
            </>
        );
    };

    renderPropertyLocks = () => {
        if (this.state.propertyLocks == null) {
            return <div></div>;
        }

        if (this.props.isSelectable) {
            const listItems = this.state.propertyLocks.map((lock, index) => {
                return <div key={index} className="ckeck-button">
                    <label>
                        <input type="checkbox" value={lock.id} checked={lock.isAssigned} onChange={this.onKeySelectionChange}></input><span>{lock.lockName}</span>
                    </label>
                </div>;
            });

            return listItems;
        } else {
            const listItems = this.state.propertyLocks.map((lock, index) => {
                return <div key={index} className="separate-list">
                    {lock.lockName}
                </div>;
            });

            return listItems;
        }
    }

    onKeySelectionChange = (event) => {
        const changedList = this.state.propertyLocks.map((lock, index) => {
            if (event.currentTarget.value == lock.id) {
                lock.isAssigned = event.currentTarget.checked;
            }

            return lock;
        });

        this.setState({ propertyLocks: changedList });
        this.props.onPropertyKeySelected(changedList.filter(a => a.isAssigned));
    }

    handleInit = () => { };

    toggleJobLocksDrawer = () => {
        this.setState({
            showJobLocksDrawer: !this.state.showJobLocksDrawer
        });

        try {
            if (this.props.propertyId) {
                this.setState({ isLoading: true })
                AXIOS.GET(`tenant-api/access/property/locks?propertyId=${this.props.propertyId}&userId=${localStorage.getItem('userId')}`)
                    .then(dataJson => {
                        this.setState({ isLoading: false })
                        this.setState({ propertyLocks: dataJson.propertyLocks });
                    });
            } else if (this.props.jobId) {
                this.setState({ isLoading: true })
                AXIOS.GET(`tenant-api/access/job/locks?jobId=${this.props.jobId}&userId=${localStorage.getItem('userId')}`)
                    .then(dataJson => {
                        const assignedLocks = dataJson.propertyLocks.filter(a => a.isAssigned);
                        this.setState({ isLoading: false })
                        this.setState({ propertyLocks: assignedLocks });
                    });
            }
        } catch (error) {
            console.log(error);
        }
    };
}

export default JobLocksDrawer;


import React, { Component } from 'react';
import BootstrapTableNext from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, Comparator } from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';

import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;
class BootstrapTable extends Component {

    pagination = {
        page: this.props.page,
        sizePerPage: this.props.sizePerPage,
        totalSize: this.props.totalSize
    }

    render() {
        return (


            <React.Fragment>
                <ToolkitProvider
                    keyField="id"
                    data={this.props.data}
                    columns={this.props.columns}                    
                    search
                    

                >
                    {
                        props => (
                            <React.Fragment>
                                <BootstrapTableNext
                                    {...props.baseProps}
                                    remote={ {pagination:true, search: false, sort: true } }
                                   // remote
                                    keyField={this.props.keyField}
                                    filter={filterFactory()}
                                    caption={<this.CaptionElement />}
                                   
                                    defaultSorted={this.props.defaultSorted}
                                    pagination={paginationFactory({ ...this.getPagination() })}
                                    onTableChange={this.handleTableChange}
                                    loading={this.props.loading}
                                    hover={true}
                                    //overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } }) }
                                    overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
                                    rowEvents={this.props.rowEvents}
                                    rowStyle={ { cursor: 'pointer' } }
                                />
                            </React.Fragment>
                        )
                    }
                </ToolkitProvider>
       
            </React.Fragment>
        );
    }

    componentDidMount() {
    }

    CaptionElement = () => {
        if(this.props.caption){
            return (
                <h4
                    style={{
                        borderRadius: '0.25em',
                        textAlign: 'center',
                        color: '#db3d44',
                        border: '1px solid purple',
                        padding: '0.5em'
                    }}>
                    {this.props.caption}
                </h4>
            )
        }
        return <></>
    }

    getPagination() {
        return {
            page: this.props.page,
            sizePerPage: this.props.sizePerPage,
            totalSize: this.props.totalSize,
            filter:this.props.filter
        }
    }

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
        //this.setState({ loading: true });
        //console.log('this.state.loading: ', this.state.loading);
        this.props.onTableChange(type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit });
        //this.setState({ loading: false });
        //console.log('this.state.loading: ', this.state.loading);
    }

    getOverlay = () => (
        <LoadingOverlay active={true} spinner text='Loading your content...'>
            <p>Some content or children or something.</p>
        </LoadingOverlay>
    );
}

/// Set property types
BootstrapTable.propTypes = {
    data: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    
    //onTableChange: PropTypes.func.isRequired
};



// const NoDataIndication = () => (
//     <div className="spinner">
//         <div className="rect1" />
//         <div className="rect2" />
//         <div className="rect3" />
//         <div className="rect4" />
//         <div className="rect5" />
//     </div>
// );

export default BootstrapTable;

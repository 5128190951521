import { AXIOS } from '../Utilities/HttpRequest/Axios';
import { AppConfiguration } from "read-appsettings-json";

const GetPropertyByUserId = () => {
    return AXIOS.GET(`tenant-api/property/tenant-property?userId=${localStorage.getItem('userId')}`);
}

const GetTenantPropertiesByUserId = () => {
    return AXIOS.GET(`tenant-api/property/tenant-properties?userId=${localStorage.getItem('userId')}&isTenant=${(localStorage.getItem("userType") == 4)}`);
}

const GetAssignedPropertyManagersForProperty = (propertyId) => {
    return AXIOS.GET(`tenant-api/property-managers-for-property?userId=${localStorage.getItem('userIdDecripted')}&propertyId=${propertyId}`);
}

const GetDisclaimerByPropertyId = (propertyId) => {
    return AXIOS.GET(`tenant-api/property/job-post-disclaimer-by-propertyId?propertyId=${propertyId}`);
}


const propertyService = {
    GetPropertyByUserId,
    GetTenantPropertiesByUserId,
    GetAssignedPropertyManagersForProperty,
    GetDisclaimerByPropertyId
}

export default propertyService;
import TenantReducer from './TenantReducer';
import OwnerReducer from './OwnerReducer';
import AppSettingsReducer from './AppSettingsReducer';
import JobsReducer from './JobsReducer';
import {combineReducers} from 'redux'
import ConversationReducer from './ConversationReducer';
import UserReducer from './UserReducer';

const RootReducer = combineReducers({
    Tenant : TenantReducer,
    Owner : OwnerReducer,
    MyJobs : JobsReducer,
    Jobs : JobsReducer,
    AppSettings: AppSettingsReducer,
    Conversation: ConversationReducer,
    User: UserReducer
})

export default RootReducer
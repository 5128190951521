import React from 'react';
import { connect } from 'react-redux';
import Drawer from '../../Common/Drawer/Drawer';
import { Tabs, Tab} from 'react-bootstrap'
import ConversationList from './ConversationList/ConversationList';

import './ChatDrawer.scss';
import ChatContacts from './ChatContacts/ChatContacts';
import NotificationList from './Notifications/NotificationList';
import notificationService from '../../../Services/NotificationService';
import utilityService from "../../../Services/UtilityService";
import chatService from "../../../Services/ChatService";

class ChatDrawer extends React.Component {

    state = {
        chatDrawerOpen: false,
        title: '',
        notificationCount: 0,
        conversationList: []
    }

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        utilityService.getDescriptedUserId().then(res => {
            localStorage.setItem("userIdDecripted", res);
            this.getNotificationCount();
            this.getUserConversations();
            this.autoTriggerConversationWindow();
        });
        
    }

    getUserConversations = () => {
        chatService.getConversationIdByUserWithOrderByIsRead(localStorage.getItem('userIdDecripted'))
            .on('value', (snapshot) => {
                let dataList = [];
                snapshot.forEach(function (childSnapshot) {
                    var childKey = childSnapshot.key;
                    var childData = childSnapshot.val();
                    dataList.push(childData);
                });
                this.setState({
                    conversationList: dataList
                });
            });
    }

    autoTriggerConversationWindow = () => {
        chatService.getUpdatedChats(localStorage.getItem('userIdDecripted'))
        .on('child_changed', (snapshot) => {
            var conData = snapshot.toJSON();
            let conversationThread = {}
            conversationThread.sender = conData.sender;
            conversationThread.conversationId = conData.conversationId;
            conversationThread.senderFirstName =conData.senderFirstName;
            conversationThread.senderLastName = conData.senderLastName;
            conversationThread.senderImage = conData.senderImageUrl;
            conversationThread.senderImageUrl = conData.senderImageUrl;
            conversationThread.receiver = conData.receiver;
            conversationThread.receiverFirstName = conData.receiverFirstName;
            conversationThread.receiverLastName = conData.receiverLastName;
            conversationThread.receiverImage = conData.profileImageUrl;
            conversationThread.receiverImageUrl = conData.profileImageUrl;
            conversationThread.isPrivate = conData.profileImageUrl;
            conversationThread.isRead = conData.profileImageUrl;
            conversationThread.lastMessage = conData.profileImageUrl;
            conversationThread.conversationName = conData.conversationName;
            conversationThread.unReadMessageCount = conData.unReadMessageCount;
            conversationThread.isStart = conData.isStart;
            if(!conversationThread.isStart) {
                let chatList = (this.props.chatBoxes == '') ? [] : JSON.parse(this.props.chatBoxes);
                const index = chatList.findIndex(a => a.conversationId == conversationThread.conversationId);
                if(index == -1) {
                    chatList.push(conversationThread)
                    this.props.updateChatboxes(JSON.stringify(chatList));
                }
            }
            
        })
    }

    getNotificationCount() {
        notificationService.GetUnreadNotifications()
            .on('value', (snapshot) => {
                let count = snapshot.val();
                count = count > 0 ? count : 0;
                this.setState({ notificationCount: count});
            });
    }

    createChatDrawer = () => {
        // let title ='Job Details'
        let headerButtons = this.createHeaderButtons();
        let panelBody = this.createPanelBody();
        let drawerIcons = this.getDrawerIcons();
        return (
            <Drawer
                size={'md chat-users-container communication-drawer side-drwr'}
                drawerIcons={drawerIcons}
                open={this.props.show}
                header={headerButtons}
                body={panelBody}
                footer=""
            >
            </Drawer>
        )
    }

    getDrawerIcons = () => {
        return (
            <>
                <div className="com-drawer-icons">
                    <ul>
                        <li>
                            <button title="Notifications" className={(this.state.title === 'Notifications') ? 'selected' : ''} onClick={() => this.togglePanels(1)}>
                                <i className="ba-icn ba-icn-notification"></i>
                                <span className={(this.state.notificationCount <= 0 || this.props.show || this.state.notificationCount == null ) ? 'd-none' : ''}>{this.state.notificationCount}</span>
                            </button>
                        </li>
                        {/* <li>
                            <button title="Messages" className={(this.state.title === 'Chats') ? 'selected' : ''} onClick={() => this.togglePanels(2)}>
                                <i className="ba-icn ba-icn-chat"></i>
                            </button>
                        </li> */}
                        <li className={(this.state.title === 'Timeline') ? 'selected d-none' : 'd-none'}>
                            <button title="Timeline"  onClick={() => this.togglePanels(3)}>
                                <i className="ba-icn ba-icn-timeline"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </>

        )
    }

    togglePanels = (panelType) => {
        (!this.props.show) && this.props.toggleDrawer();
        switch(panelType) {
            case 1:
                this.setState({ title: 'Notifications'});
                break;
            case 2:
                this.setState({ title: 'Chats'});
                break;
            case 3:
                this.setState({ title: 'Timeline'});
                break;
            default:
                this.setState({ title: 'Notifications'});
                break;
        }
    }

    createPanelBody = () => {

        return (
            <>
                {(this.state.title === 'Notifications') ? 
                    <div className="panel-body">
                        <NotificationList unreadCount={this.state.notificationCount}
                        userReadNotificationEvent = {() => this.updateUnreadCount()}></NotificationList>
                    </div> : ''
                }
                <div className={this.state.title === 'Chats' ? 'panel-tabs' : 'panel-tabs d-none'}>
                    <Tabs defaultActiveKey="chats" id="chat-drawer-tabs">
                        <Tab eventKey="chats" title="Chats" >
                            <ConversationList conversationList={this.state.conversationList}></ConversationList>
                        </Tab>
                        <Tab eventKey="contacts" title="Contacts">
                            <ChatContacts></ChatContacts>
                        </Tab>
                    </Tabs>
                </div>
            </>

        )
    }

    createHeaderButtons = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    {this.state.title}
                </h2>
                {(this.state.title == 'Notifications') ? 
                    <div className="panel-header-actions" >
                        <button className="action-btn panel-close" onClick={this.props.toggleNotificationDrawer}><i className="ba-icn ba-icn-close"></i></button>
                    </div>:
                    <div className="panel-header-actions" >
                        <button className="action-btn panel-close" onClick={this.props.toggleDrawer}><i className="ba-icn ba-icn-close"></i></button>
                    </div>
                }
            </>
        )
    }

    render() {
        let chatDrawer = this.createChatDrawer();

        return (
            <>
                {chatDrawer}
            </>
        );
    }

    updateUnreadCount() {
        this.setState({notificationCount: this.state.notificationCount-1});
    }
}

const mapStateToProps = (state, ownProps) => {
    try {
        let userDetails = state.User
        return {
          userDetails: userDetails,
          chatBoxes: state.Conversation.conversationList,
        };
    } catch (error) {
        console.log(error)
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          updateUserDetails: (details) => { dispatch({ type: 'SET_USER_DETAILS', userDetails: details }) },
          updateChatboxes: chatList => { dispatch({ type: "SHOW_CONVERSATION", chatList: chatList }) }
      }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ChatDrawer);
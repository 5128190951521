import React from 'react';
import { NavDropdown } from "react-bootstrap";
import { AppConfiguration } from "read-appsettings-json";
import Drawer from '../../Common/Drawer/Drawer';
import { toast } from "react-toastify";
import userService from '../../../Services/UserService';

class SwitchRoleDrawer extends React.Component {

    state = {
        roles: [],
        organizations: [],
        selectedOrgniztion: ''
    }

    render() {
        const header = this.createHeader();
        const body = this.createBody();
        const footer = this.createFooter();

        return (
            <Drawer
                size={"sm"}
                open={this.props.showSwitchRoleDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    }

    componentDidMount() {
        var orgRoles = [];
        Object.keys(this.props.groupedRoles).forEach(element => {
            var role = {}
            if(element == 'null') {
                role = this.props.roles.find(a => a.organizationId == null);
                role.organizationId = 0;
                role.organizationName = 'Providers';
            } else {
                role = this.props.roles.find(a => a.organizationId == element);
            }
            orgRoles.push(role);
        })

        this.setState({
            organizations: orgRoles
        });

        setTimeout(() => {
            if( document.getElementById(this.props.selectedUserRole.organizationId) != null) {
                document.getElementById(this.props.selectedUserRole.organizationId).checked = true;
                this.onChangeOrganization();
                //document.getElementById(this.props.selectedUserRole.roleId).checked = true;
            }
        }, 500);

        for (var j = 0; j < this.props.roles.length; j++) {
            if (this.props.roles[j].roleName == 'PropertyManagers') {
                this.props.roles[j].roleName = 'Property Manager';
            } else if(this.props.roles[j].roleName == 'PropertyTenant') {
                this.props.roles[j].roleName = 'Property Tenant';
            }  else if(this.props.roles[j].roleName == 'OrganizationAdmins') {
                this.props.roles[j].roleName = 'Organisation Admin';
            }
        }
    }

    createBody = () => {
        return (
            <div className="panel-body">
                <div className="row">
                    <div className={(this.state.organizations.length == 1) ? 'd-none' : "col-sm-12"}>
                        <div className="form-group">
                        <h2 className="font-13 font-600 d-flex align-items-center">
                            <i className="ba-icn ba-icn-organisation mr-2 font-25"></i>
                            Select Organization
                        </h2>
                            {(this.state.organizations.map(a => {
                                return (
                                    <div className={"card p-2 mb-2 mx-n1"}>
                                        <div className="custom-control custom-radio">
                                            <input name="organization" type="radio" id={a.organizationId} 
                                            className="custom-control-input" value={a.organizationId} onChange={(event) => this.onChangeOrganization()}/>
                                            <label title="" type="checkbox" htmlFor={a.organizationId} className="custom-control-label font-13">{a.organizationName}</label>
                                        </div>
                                    </div>
                                    
                                )
                            }))}
                            <div className="d-none" id="urlLoader"></div>
                        </div>
                    </div>
                    <div className="col-sm-12" className={(this.state.roles.length > 0) ? 'col-sm-12' : 'col-sm-12 d-none' }>
                        <div className="form-group">
                        <h2 className="font-13 font-600 d-flex align-items-center">
                            <i className="ba-icn ba-icn-user-group mr-2 font-25"></i>
                            Select User Role
                        </h2>
                            {(this.state.roles.map(a => {
                                return (
                                    <>
                                        {(a.roleId == 4 && this.state.roles.filter(a => a.roleId == 2).length > 0) ? '' :
                                        <div className="card p-2 mb-2 mx-n1">
                                            <div className="custom-control custom-radio">
                                                <input name="roles" type="radio" id={a.id} className="custom-control-input" value={a.id}/>
                                                <label title="" type="checkbox" htmlFor={a.id} className="custom-control-label font-13">{a.roleName}</label>
                                            </div>
                                        </div>
                                        }
                                    </>
                                )
                            }))}
                            <div className="d-none" id="urlLoader"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChangeOrganization = () => {
        if((document.querySelector('input[name=organization]:checked')) != null) {
            if(document.querySelector('input[name=organization]:checked').value == 0) {
                this.setState({
                    roles: []
                })
            } else {
                let userRoles = this.props.roles.filter(a => a.organizationId == document.querySelector('input[name=organization]:checked').value);
                this.setState({
                    roles: userRoles
                })
            }
            
        } else {
            this.setState({
                roles: []
            })
        }
    }

    createHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Switch Role
              </h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={this.props.toggleSwitchRoleDrawer}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    }

    createFooter = () => {
        return (
            <>
                <div className="card-actions">
                    <button onClick={this.onSubmitSwitchedRole}> 
                        <i className="ba-icn ba-icn-ok"></i> OK{" "}
                    </button>
                    <button
                        className="more-actions"
                        onClick={this.props.toggleSwitchRoleDrawer}
                    >
                        {" "}
                        Cancel{" "}
                    </button>
                </div>
            </>
        );
    }

    getUserRoles = () => {
        userService.getUserRoles().then(response => {
            this.setState({
                roles: response
            });
        })
    }

    onSubmitSwitchedRole = () => {
        if(document.querySelector('input[name=organization]:checked') && document.querySelector('input[name=organization]:checked').value == 0) {
            var providerRole = this.props.roles.find(a => a.organizationId == 0);
            window.location.href =  AppConfiguration.Setting().BAndAApiEndPoint + `Account/SwitchRoleSelectionWithRedirection?selectedOrgRoleId=${providerRole.id}&roleId=${providerRole.roleId}&userId=${localStorage.getItem('userIdDecripted')}`;
        }
        else if((document.querySelector('input[name=roles]:checked') != null)) {
            const selectedRole = this.props.roles.find(a => a.id == document.querySelector('input[name=roles]:checked').value);
            window.location.href =  AppConfiguration.Setting().BAndAApiEndPoint + `Account/SwitchRoleSelectionWithRedirection?selectedOrgRoleId=${selectedRole.id}&roleId=${selectedRole.roleId}&userId=${localStorage.getItem('userIdDecripted')}`;
        } else {
            toast.error("Please select an user role.",{
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }



}

export default SwitchRoleDrawer;
import { AXIOS } from '../Utilities/HttpRequest/Axios';
import { AppConfiguration } from "read-appsettings-json";

export const getUserDetails = () => {
    return AXIOS.GET(`tenant-api/tenant-profile?userId=${localStorage.getItem('userId')}`);
}

export const getExternalUserDetails = (userId) => {
    return AXIOS.GET(`tenant-api/external-tenant-profile?userId=${userId}`);
}

export const saveTenantProfile = (formValues) => {
    return AXIOS.POST(
        `tenant-api/save-profile`, formValues, {
        'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret }
    });
}

export const getChatUsersList = () => {
    return AXIOS.GET(`chat/global?userId=${localStorage.getItem('userId')}&pageNumber=1&pageSize=100`);
}

export const getUserRoles = () => {
    return AXIOS.GET(`tenant-api/get-user-roles?userId=${localStorage.getItem('userId')}`);
}

export const getProviderProfile = (filter) => {
    return AXIOS.GET(`tenant-api/profile?filters=${filter}`);
}

export const GetProviderBidFeedbacks = (providerId) => {
    return AXIOS.GET(`tenant-api/get-provider-bid-feedback?userId=${providerId}&feedbackCount=0`);
}

const getFeedbackListByBidId = (bidId) => {
    return AXIOS.GET(`tenant-api/bid/feedbackRatingList?bidId=${bidId}`);
}

const GetActiveMasterDataFileVersion = (fileType) => {
    return AXIOS.GET(`tenant-api/get-active-masterdata-file-version?fileType=${fileType}`);
}

export const getCommonOrganizationSettings = (orgId) => {
    return AXIOS.GET(`tenant-api/GetCommonOrganizationSettings?orgId=${orgId}`);
}

const userService = {
    GetActiveMasterDataFileVersion,
    GetProviderBidFeedbacks,
    getProviderProfile,
    getUserDetails,
    getExternalUserDetails,
    saveTenantProfile,
    getChatUsersList,
    getUserRoles,
    getFeedbackListByBidId,
    getCommonOrganizationSettings

}

export default userService;
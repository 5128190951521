import React from 'react';
import ChatBox from './ChatBox/ChatBox';
import { connect } from 'react-redux';

class ChatBoxContainer extends React.Component {
    state = {
        conversations: []
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate() {

    }

    render() {

        const chatList = this.getConversationList();
        return (
            <div className="multi-chat-container">
                {chatList}
            </div>
        );
    }

    getConversationList = () => {
        let chats = this.props.chatBoxes == '' ? [] : JSON.parse(this.props.chatBoxes);
         chats = removeDuplicate(chats,it => it.conversationId);
        return chats.map((con, index) => {
            if(con.ConversationId != undefined) con.conversationId = con.ConversationId; 
            return <ChatBox key={index} conversation={con}></ChatBox>
        })
    }

}

function removeDuplicate(data,key){
    return [
        ...new Map(
            data.map(x=> [key(x),x])
        ).values()
    ]
}
const mapStateToProps = (state, ownProps) => {

    try {
        let chats = state.Conversation.conversationList

        return {
            chatBoxes: chats
        };
    } catch (error) {
        console.log(error)
    }
}

// const mapDispatchToProps = (dispatch) => {

//     return {
//         addToChatBoxList: (chatList) => { dispatch({ type: 'SHOW_CONVERSATION', chatList: chatList }) }
//     }
// }

export default connect(mapStateToProps, null)(ChatBoxContainer);
const initState = {
    conversationList: ''
}


const ConversationReducer = (state = initState, action) => {
    if (action.type == 'SHOW_CONVERSATION') {
        return {
            ...state,
            conversationList: action.chatList
        }
    }
    return state;
    // switch(action.type) {
    //     case 'SHOW_CONVERSATION': 
    //         console.log(action.chatList);
    //         return {
    //             ...state,
    //             conversationList: action.chatList
    //         }
    //         break;
    //     default:
    //         return state;
    // }
}

export default ConversationReducer;
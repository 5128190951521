const initState = {
    email: '',
    firstName: '',
    lastName: '',
    profileImageUrl: '',
    userType: '',
    userId: '',
    userIdEncripted: '',
    homePhoneNumber: '',
    location: '',
    mobileNumber: '',
    officePhoneNumber: '',
    countryId: '',
    dateFormat: '',
    dateTimeFormat: '',
    dateTimeFormatMin: '',
    userRoles: []
}

const UserReducer = (state = initState, action) => {

    if (action.type === "SET_USER_DETAILS") {

        return {
            ...state,
            email: action.userDetails.email,
            firstName: action.userDetails.firstName,
            lastName: action.userDetails.lastName,
            profileImageUrl: action.userDetails.profileImageUrl,
            profileImageId: action.userDetails.profileImageId,
            userType: action.userDetails.userType,
            userId: action.userDetails.userId,
            userIdEncripted: action.userDetails.userIdEncripted,
            homePhoneNumber: action.userDetails.homePhoneNumber,
            location: action.userDetails.location,
            countryId: action.userDetails.countryId,
            mobileNumber: action.userDetails.mobileNumber,
            officePhoneNumber: action.userDetails.officePhoneNumber,
            dateFormat: action.userDetails.dateFormat,
            dateTimeFormat: action.userDetails.dateTimeFormat,
            dateTimeFormatMin: action.userDetails.dateTimeFormatMin,
        }
    }
    else if(action.type === "SET_USER_ROLES") {
        return {
            ...state,
            userRoles: action.roles
        }
    }
    return state;
}

export default UserReducer;
import React, { useState } from "react";
import { connect } from "react-redux";
import Drawer from "../../Common/Drawer/Drawer";
import StarRatingComponent from "react-star-rating-component";

import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { ToastContainer, toast } from "react-toastify";
import { WorkflowStates, UserType } from "../../../Constants/Enums";
import { AppConfiguration } from "read-appsettings-json";
import "./FeedbackDrawer.scss";
import { trackPromise } from "react-promise-tracker";

class FeedbackDrawer extends React.Component {
  state = {
    showFeedbackDrawer: false,
    attendanceRating: 0,
    communicationRating: 0,
    cleanlinessRating: 0,
    qualityRating: 0
  };

  isFeedbackProvided = false;
  userFeedbackNote = '';

  constructor(props) {
    super(props);
    this.feedback = React.createRef();
  }

  componentDidMount() {
    this.isFeedbackProvided = false;
  }

  render() {
    let drawer = this.createDrawer();
    const feedbackButton = "Feedback";
    return (
      <>
        <button className="action-btn" onClick={this.toggleFeedbackDrawer}>
          <span className="button-label">{feedbackButton}</span>
        </button>
        {drawer}
      </>
    );
  }

  createDrawer = () => {
    const header = this.createFeedbackHeader();
    const body = this.createFeedbackBody();
    const footer = this.createFeedbackFooter();

    return (
      <Drawer
        size={"md"}
        open={this.state.showFeedbackDrawer}
        header={header}
        body={body}
        footer={footer}
      ></Drawer>
    );
  };

  createFeedbackHeader = () => {
    return (
      <>
        <h2 className="d-flex align-items-center">Provide Feedback</h2>
        <div className="panel-header-actions">
          <button
            className="action-btn panel-close"
            onClick={this.toggleFeedbackDrawer}
          >
            <i className="ba-icn ba-icn-close"></i>
          </button>
        </div>
      </>
    );
  };

  createFeedbackBody = () => {
    const isTenant =
    localStorage.getItem("userType") == UserType.Tenant  ;
    const jobData = this.props.jobDetails;

    let {
      attendanceRating,
      communicationRating,
      cleanlinessRating,
      qualityRating
    } = this.state;

    if (jobData.bidFeedBackList && jobData.bidFeedBackList.length > 0) {
      const userFeedback = jobData.bidFeedBackList.filter(
        feedback => feedback.userId === localStorage.getItem("userIdDecripted")
      );
      if (userFeedback && userFeedback.length > 0) {
        const feedbackGivenByUser = userFeedback[0];

        this.isFeedbackProvided = true;
        this.userFeedbackNote = feedbackGivenByUser.feedback;
        attendanceRating = feedbackGivenByUser.bidRatings.filter(
          rating => rating.ratingsCriteriaId === 1
        )[0].rating;

        communicationRating = feedbackGivenByUser.bidRatings.filter(
          rating => rating.ratingsCriteriaId === 2
        )[0].rating;

        cleanlinessRating = feedbackGivenByUser.bidRatings.filter(
          rating => rating.ratingsCriteriaId === 3
        )[0].rating;

        qualityRating = feedbackGivenByUser.bidRatings.filter(
          rating => rating.ratingsCriteriaId === 4
        )[0].rating;
      }
    }


    return (
      <div className="panel-body job-detail-card feed-bk-drwr">
        <h5 className="mb-0">{jobData.title}</h5>
        <div className="job-description">{jobData.description}</div>
        <div className="job-details-table">
        {(!isTenant ) ?
          <div className="job-details-table-row">
            <div className="table-label">Estimate</div>
            <div className="table-data mb-3">
              {jobData && jobData.acceptedBid && jobData.acceptedBid.amount && jobData.acceptedBid.amount > 0 ? `$ ${jobData.acceptedBid.amount}` : "Not Supplied"}
            </div>
          </div>
           : ''}
          <div className="job-details-table-row">
            <div className="table-label">Provider</div>
            <div className="table-data mb-3">
              {jobData.acceptedBid ? `${jobData.acceptedBid.name}` : ``}
            </div>
          </div>
          <div className="job-details-table-row">
            <div className="table-label">Provider Note</div>
            <div className="table-data mb-3" dangerouslySetInnerHTML={{ __html: jobData.serviceProviderNote }}>
            </div>
          </div>
        </div>

        <hr></hr>

        <h3 className="my-3">Overall Rating</h3>
        <div className="job-details-table">

          <div className="job-details-table-row valign-middle">
            <div className="table-label">Attendance time</div>
            <div className="table-data">
              <StarRatingComponent
                name="attendanceRating"
                starCount={5}
                value={attendanceRating}
                onStarClick={this.onRatingStarClick.bind(this)}
              />
            </div>
          </div>
          <div className="job-details-table-row valign-middle">
            <div className="table-label">Communications</div>
            <div className="table-data">
              <StarRatingComponent
                name="communicationRating"
                starCount={5}
                value={communicationRating}
                onStarClick={this.onRatingStarClick.bind(this)}
              />
            </div>
          </div>
          <div className="job-details-table-row valign-middle">
            <div className="table-label">Cleanliness</div>
            <div className="table-data">
              <StarRatingComponent
                name="cleanlinessRating"
                starCount={5}
                value={cleanlinessRating}
                onStarClick={this.onRatingStarClick.bind(this)}
              />
            </div>
          </div>
          <div className="job-details-table-row valign-middle">
            <div className="table-label">Quality of Workmanship</div>
            <div className="table-data">
              <StarRatingComponent
                name="qualityRating"
                starCount={5}
                value={qualityRating}
                onStarClick={this.onRatingStarClick.bind(this)}
              />
            </div>
          </div>
        </div>
        {!this.isFeedbackProvided &&
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="table-label">Feedback</label>
                <textarea
                  className="form-control"
                  id="txtFeedback"
                  rows="3"
                  ref={this.feedback}
                ></textarea>
              </div>
            </div>
          </div>}
        {this.isFeedbackProvided &&
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="table-label">Feedback</label>
                <textarea
                  className="form-control"
                  id="txtFeedback"
                  rows="3"
                  value={this.userFeedbackNote}
                  ref={this.feedback}

                ></textarea>
              </div>
            </div>
          </div>
        }
        {this.props.jobDetails.propertyManagerAgencyPlaceId != '' || this.props.jobDetails.providerAgencyPlaceId != '' ?
          <div className="row">
            <div className="col-sm-12 my-3">
              <div className="btn-group mb-3">
                <button className={this.props.jobDetails.providerAgencyPlaceId == '' ? "d-none" : "btn btn-outline-primary mr-1 bigger-btn mb-3"}
                  onClick={() => this.onClickAddReview(this.props.jobDetails.providerAgencyPlaceId)}>
                  <span>Add review to Service Provider's Agency</span>
                </button>
                <button className={this.props.jobDetails.propertyManagerAgencyPlaceId == '' ? "d-none" : "btn btn-outline-primary bigger-btn mb-3"}
                  onClick={() => this.onClickAddReview(this.props.jobDetails.propertyManagerAgencyPlaceId)}>
                  <span>Add review to Property Manager's Agency</span>
                </button>
              </div>
            </div>
          </div> : ''
        }
        <div className="row d-none">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  name="markAsCompleted"
                  type="checkbox"
                  id="1"
                  className="custom-control-input"
                />
                <label
                  title=""
                  type="checkbox"
                  htmlFor="1"
                  className="custom-control-label"
                >
                  {" "}
                  Mark as Completed
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickAddReview = (placeId) => {
    let url = AppConfiguration.Setting().googleMapReviewUrl + "?placeid=" + placeId;
    window.open(url, "_blank")
  }


  onRatingStarClick(nextValue, prevValue, name) {
    switch (name) {
      case "attendanceRating":
        this.setState({ attendanceRating: nextValue });
        break;
      case "communicationRating":
        this.setState({ communicationRating: nextValue });
        break;
      case "cleanlinessRating":
        this.setState({ cleanlinessRating: nextValue });
        break;
      case "qualityRating":
        this.setState({ qualityRating: nextValue });
        break;
    }
  }

  createFeedbackFooter = () => {
    const feedbackButton = "ADD FEEDBACK";
    return (
      <>
        <div className="card-actions mb-3">
          <button className="more-actions" onClick={this.toggleFeedbackDrawer}>
            {" "}
            CANCEL{" "}
          </button>

          {!this.isFeedbackProvided && <button onClick={this.onUpdateFeedback}>
            <i className="ba-icn ba-icn-ok"></i> {feedbackButton}{" "}
          </button>}
        </div>
      </>
    );
  };

  onUpdateFeedback = () => {
    try {
      if (!this.state.attendanceRating || !this.state.communicationRating || !this.state.cleanlinessRating || !this.state.qualityRating) {
        toast.error("Great if you could rate all options!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return;
      }
      this.props.showJobDetailsDrawer(!this.props.open, true);
      let bidFeedback = {
        userId: localStorage.getItem("userId"),
        jobId: this.props.jobDetails.id,
        feedback: this.feedback.current.value,
        bidRatings: [
          { rating: this.state.attendanceRating, ratingsCriteriaId: 1 },
          { rating: this.state.communicationRating, ratingsCriteriaId: 2 },
          { rating: this.state.cleanlinessRating, ratingsCriteriaId: 3 },
          { rating: this.state.qualityRating, ratingsCriteriaId: 4 }
        ],
        FeedBackUserType: UserType.Owner,
        FeedbackRatings: 0
      };
      trackPromise(
        AXIOS.POST(`tenant-api/save-feedback`, bidFeedback).then(response => {
          if (response.success === true) {
            this.isFeedbackProvided = true;
            this.userFeedbackNote = this.feedback.current.value;
            toast.success("Feedback Updated.", {
              position: toast.POSITION.BOTTOM_RIGHT
            });
            this.props.getJobDetails(this.props.jobDetails.id);
            this.toggleFeedbackDrawer();
          } else {
            toast.error("Error occured. Please try again.", {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          }
        }));
    } catch (error) {
      console.log(error);
    }
  };

  toggleFeedbackDrawer = () => {
    this.setState(state => ({
      showFeedbackDrawer: !this.state.showFeedbackDrawer
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.MyJobs.isOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showJobDetailsDrawer: (isShowDrawer, isRefresh) => {
      dispatch({
        type: "SHOW_JOB_DETAILS_DRAWER",
        isOpen: isShowDrawer,
        refreshMyJobs: isRefresh
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackDrawer);

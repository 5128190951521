
import React from "react";
import Drawer from "../../../Components/Common/Drawer/Drawer";

export default class ConfirmationDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.note = React.createRef();
    }
    state = {
        showDrawer: false,
        approve:'Yes',
        reject:'No'
    };

    componentDidMount() {
        const approve = this.props.approveButtonName ? this.props.approveButtonName : this.state.approve;
        const reject = this.props.rejectButtonName ? this.props.rejectButtonName : this.state.reject;
        this.setState({ showDrawer: this.props.showDrawer,approve:approve, reject:reject});
    }

    render() {

        let drawer = this.createDrawer();
        return <div>{drawer}</div>;

    }

    toggleDrawer = isClose => {
        this.setState(state => ({
            showDrawer: !this.state.showDrawer
        }));
        if (isClose) {
            this.props.onClose(false);
        }

    };

    createDrawer = () => {
        const header = this.createProfileHeader();
        const body = this.createProfileBody();
        const footer = this.createFooter();
        return (
            <Drawer
                size={"m"}
                open={this.state.showDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    };


    createProfileHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Confirmation
                </h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={() => this.toggleDrawer(true)}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createProfileBody = () => {
        return (
            <>
                <div className="panel-body dashboardN pt-0">
                    <div className="provider-profile row">
                        <div className="col-md-12">
                            <div className="mt-4">
                                <span> <lable style={{ display: 'block' }}><strong>{this.props.message}</strong></lable></span>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    };


    createFooter = () => {
        return (
            <>
                <div className="card-actions">
                        <button onClick={this.onAccept} className="action-accept" type="button"> <i className="ba-icn ba-icn-ok"></i>{this.state.approve}</button> 
                        <button onClick={this.onReject} className="action-reject" type="button">{this.state.reject}</button>
                </div>
            </>
        )
    }


    onAccept = () => {
        this.setState(state => ({
            showDrawer: false
        }));
        this.props.onClose(false);
        this.props.onConfirm(true);
    }

    onReject = () => {
        this.setState(state => ({
            showDrawer: false
        }));
        this.props.onClose(false);
        this.props.onConfirm(false);
        }

    }



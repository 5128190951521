import React, { Component } from "react";
import "./drawer.scss";

class Drawer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let drawer = this.createDrawer();

    return <div>{drawer}</div>;
  }

  createDrawer = () => {
    let drawerClassName = "ba-drawer drawer-" + this.props.size;
    let headerIcons = (this.props.drawerIcons) ? this.props.drawerIcons : ''
    return (
      <div
        className={
          this.props.open ? drawerClassName + " slideInLeft" : drawerClassName
        }
      >
        <div className="drawer-panel">
          <div className="overflow-auto panel-cont-side">
            {headerIcons}
            <div className="panel-title">{this.props.header}</div>
            <div className="panel-body-wrapper">{this.props.body}</div>
            <div className="panel-footer my-3">{this.props.footer}</div>
          </div>
        </div>
      </div>
    );
  };
}

export default Drawer;

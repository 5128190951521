import * as React from 'react';
import Viewer from 'react-viewer';

const SignatureViewer = (props) => {
    const [visible, setVisible] = React.useState(false);
    const [imgUrl, setImgUrl] = React.useState('');
    const [downloadImgUrl, setDownloadImgUrl] = React.useState('');


    return (
        <>
            <div className="img">
                <img
                    src={props.attachments.fileUrl}
                    alt={props.attachments.fileName}
                    downloadUrl={props.attachments.fileUrl}
                    onError={(e) => { e.target.onerror = null; e.target.src = "/Images/attachment.png" }}
                    onClick={(e) => { setDownloadImgUrl(props.attachments.fileUrl); setImgUrl(e.target.src); setVisible(true); }} />

                <Viewer
                    visible={visible}
                    downloadable={true}
                    changeable={false}
                    downloadInNewWindow={true}
                    onClose={() => { setVisible(false); }}
                    images={[{ src: imgUrl, alt: '', downloadUrl: downloadImgUrl }]}
                />
            </div>
        </>
    )
}

export default SignatureViewer;
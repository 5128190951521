import "bootstrap/dist/css/bootstrap.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ExternalUserApp from "./ExternalUserApp";
import registerServiceWorker from "./registerServiceWorker";
import { createStore } from "redux";
import RootReducer from "./ReduxStore/Reducers/RootReducer";
import { Provider } from "react-redux";
import { UserType } from "./Constants/Enums";
import { initFirebaseApp } from "./Configuration/LoadFirebaseConfig";
import utilityService from "./Services/UtilityService";
import chatService from "./Services/ChatService";
import LogMaintenanceBotApp from "./LogMaintenanceBotApp";
import './i18n';
import Loader from "react-loader-spinner";
import TroubleShootGuidePage from "./Components/External/TroubleShootGuidePage/TroubleShootGuidePage";
import ExternalInvoiceOwnerFeedBack from "./Components/External/InvoiceProcessor/ExternalInvoiceOwnerFeedBack";
import CompletedJobAttachmentsPage from "./Components/External/CompletedJobAttachmentsPage/CompletedJobAttachmentsPage";
import ViewJobAttachments from "./Components/External/ViewJobAttachments/ViewJobAttachments";
import ExternalAssets from "./Components/External/Assets/ExternalAssets";

import AddJobAttachments from "./Components/External/AddJobAttachments/AddJobAttachments"

const rootElement = document.getElementById("root");
initFirebaseApp();
const store = createStore(RootReducer);
let urlParams = new URLSearchParams(window.location.search);
const appSettings = store.getState().AppSettings;


//  localStorage.setItem("userId", 'zbAkvU7-B1CRjLI1p7BQzqjtioo1a8FdtTwZteYIW2JKYUlD5Hs8S_N3b3MR0xbtko5ObcGEEerg_b-yods6NQ');
//  localStorage.setItem("userType", 4);

// localStorage.setItem("userId", 'jvT-UY9TO0A4UVsu2ACCMMLEm3nRGbLsZ3Si_RzxUl7f-FGrURmEurHB9IN5pIJbbmsTHGUD8uJOGPyUP721Og');
// localStorage.setItem("userType", 4);

if (window.location.search.indexOf('code=') > -1) {
    const jobId = urlParams.get('code')
    let langCode = urlParams.get('lng')
    if (!langCode)
        langCode = 'en';
    else {
        langCode = langCode.substring(0, 5);
    }
    window.jobId = jobId;
    window.lng = langCode;
    if (urlParams.get("jobId") == null) {
        if (jobId === undefined || jobId === '' || jobId === 'null' || jobId === null) {
            window.location.href = appSettings.BAndAEndPoint + 'Account/UserLogin';
        } else {
            ReactDOM.render(
                <Provider store={store}>
                    <Suspense fallback={<div className="d-flex justify-content-center"><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>}>
                        <ExternalUserApp />
                    </Suspense>
                </Provider>,
                rootElement
            );
        }
    } else {
        ReactDOM.render(
            <Provider store={store}>
                <Suspense fallback={<div className="d-flex justify-content-center"><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>}>
                    <ExternalUserApp />
                </Suspense>
            </Provider>,
            rootElement
        );
    }
} else if (window.location.search.indexOf('maintenancebot=') > -1) {
    var color = urlParams.get("themeColor");
    var orgId = urlParams.get("orgId");
    var language = urlParams.get("lng");
    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<div className="d-flex justify-content-center"><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>}>
                <LogMaintenanceBotApp themeColor={color} orgId={orgId} lng={language} />
            </Suspense>
        </Provider>,
        rootElement
    );
} else if (window.location.search.indexOf('troubleshootguide') > -1) {
    var id = urlParams.get("troubleshootguideId");
    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<div className="d-flex justify-content-center"><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>}>
                <TroubleShootGuidePage troubleShootGuideId={id} />
            </Suspense>
        </Provider>,
        rootElement
    );}else if (window.location.search.indexOf('attachmentsOfCompletedMR') > -1) {
        var id = urlParams.get("attachmentsOfCompletedMRId");
        ReactDOM.render(
            <Provider store={store}>
                <Suspense fallback={<Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />}>
                    <CompletedJobAttachmentsPage jobId={id} />
                </Suspense>
            </Provider>,
            rootElement
        );
 } else if (window.location.search.indexOf('draftedJobId') > -1 && window.location.pathname.indexOf('add-job-attachments')) {
   
     var id = urlParams.get("draftedJobId");
     ReactDOM.render(
         <Provider store={store}>
             <Suspense fallback={<div className="d-flex justify-content-center"><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>}>
                 <AddJobAttachments  jobId={id} />
             </Suspense>
         </Provider>,
         rootElement
     );
}

else if (window.location.search.indexOf('invoiceprocessor') == true) {
    const externalInvoiceId = urlParams.get('invoiceId');
    const applicationUserId = urlParams.get('userId');
    const code = urlParams.get('commentCode');
    window.history.pushState('', null, 'invoiceprocessor');
    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<div className="d-flex justify-content-center"><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>}>
                <ExternalInvoiceOwnerFeedBack  invoiceId={externalInvoiceId} userId={applicationUserId} commentCode={code}/>
            </Suspense>
        </Provider>,
        rootElement
    );
    
}
else if (window.location.search.indexOf('jobId') > -1 && window.location.pathname.indexOf("view-job-attachments") > -1) {
    const jobId = urlParams.get('jobId');
    ReactDOM.render(
        <Provider store={store}>
                <Suspense fallback={<Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />}>
                    <ViewJobAttachments jobId={jobId} />
                </Suspense>
            </Provider>,
        rootElement
    );
    
}
else if (window.location.search.indexOf('asset') > -1 || window.location.search.indexOf('invitation') > -1) {
    const assetId = urlParams.get('assetId');
    const invitationId = urlParams.get('invitationId');
    const userId = urlParams.get('userId');

    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />}>
                <ExternalAssets assetId={assetId} encriptedUserId={userId} invitationId={invitationId} />
            </Suspense>
        </Provider>,
        rootElement
    );
}
else {
    const userId = localStorage.getItem('userId');
    if (urlParams.get('id') == null) {
        if (userId === undefined || userId === '' || userId === 'null' || userId === null || (localStorage.getItem("exernalUserId") != null && localStorage.getItem("exernalUserId") != "")) {
            window.location.href = appSettings.BAndAEndPoint + 'Account/UserLogin';
        } else {
            localStorage.setItem("userId", userId);
            localStorage.setItem("userType", localStorage.getItem("userType"));
            localStorage.setItem("exernalUserId", "");

        }
    } else {
        localStorage.setItem("userId", urlParams.get("id"));
        localStorage.setItem(
            "userType",
            urlParams.get("isTenant") === "True" ? UserType.Tenant : UserType.Owner
        );
        localStorage.setItem("exernalUserId", "");
    }

    const isSuper = urlParams.get('isSuperAdminAsOtherUser');
    if (urlParams.get('isSuperAdminAsOtherUser') !== null && urlParams.get('isSuperAdminAsOtherUser') === "true") {
        localStorage.setItem("isSuperAdminAsOtherUser", urlParams.get("isSuperAdminAsOtherUser"));
        if (urlParams.get('adminId') !== null) {
            localStorage.setItem("ImpersonatingUserId", urlParams.get("adminId"));
        }
    }

    utilityService.getDescriptedUserId().then(res => {
        localStorage.setItem("userIdDecripted", res);
        chatService.updateUserActiveStatus(res, true);
    });

    window.onbeforeunload = function () {
        chatService.updateUserActiveStatus(localStorage.getItem("userIdDecripted"), false);
    };


    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        rootElement
    );
}

registerServiceWorker();

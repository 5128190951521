import React from 'react';
import {CardElement,injectStripe} from 'react-stripe-elements';
import settingsService from '../../../../Services/settingsService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
class CardSection extends React.Component {
  constructor(props) {
    super(props);
    this._element = React.createRef();
  }

 state={
   error:'',
   card:'',
   elements:'',
   loading:false
 }

  render() {
 
    return (
      <div className='credit-card-block'>
      <form onSubmit={this.handleSubmit} className="d-flex flex-column mt-4 mb-5">
      <label className='credit-card-block-inner'>
        <CardElement hidePostalCode={true} style={{base: {
        color: '#000',
        lineHeight: '18px',
        fontFamily: '"Poppins", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        background:'#eee',
        '::placeholder': {
          color: '#aab7c4'
        }
      },invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      },hidePostalCode: true}} onChange={(error)=>this.handleChangeFor(error)}  onReady={(c) => this._element = c} />
       <button className="btn light-blue-button mt-4">Add new card</button>
      </label>
      <Loader visible={this.state.loading} type="ThreeDots" color={"#DB3D44"} height={10} width={100} />
      <label style={{color: '#fa755a'}}>{this.state.error}</label>
      </form>
      </div>
    );
  }

  handleChangeFor = (error) => { 
    if(error.error){
      this.setState({error:error.error.message});
    }else{
      this.setState({error:''})
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.stripe.createToken().then((token) => {
      if(token && token.token){
        this.addCardDetails(token.token.id);
      }
    });
  };

  addCardDetails=(token)=>{
    var model={
      'userId':localStorage.getItem('userId'),
      'cardToken':token
    }
    this.setState({loading:true});
    settingsService.addCardDetails(model)
    .then((res) => {
      this._element.clear();
      this.setState({loading:false});
      if(res.status===18){
        toast.success("Card added Successful.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        if (this.props.isFromTenantManageJob) {
          this.props.nextStep(0);
      }
      }else{
        toast.error(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      this.props.refresh();
  });
  }
}

export default injectStripe(CardSection);
import { AXIOS } from '../Utilities/HttpRequest/Axios';

export const getTroubleShootGuideById = (id) => {
    return AXIOS.GET(`tenant-api/troubleShootGuide-by-id?troubleShootGuideId=${id}`);
}
export const getAttachmentsByJobId = (id) => {
    return AXIOS.GET(`tenant-api/attachments-by-jobId?jobId=${id}`);
}
export const getAssetById = (id) => {
    return AXIOS.GET(`tenant-api/asset-by-id?assetId=${id}`);
}          

export const updateAssetDetails = (model) => {
    return AXIOS.PUT(`tenant-api/asset-by-id`, model);
}

export const submitInvitationAssetList = (model) => {
    return AXIOS.POST(`tenant-api/assets-by-invitation`, model);
}

export const getPropertyAssetReviewInfo = (assetId, userId, invitationId) => {
    if (assetId) {
        return AXIOS.GET(`tenant-api/asset-review-info?assetId=${assetId}&userId=${userId}&dateTime=${new Date().toUTCString()}`);
    } else if (invitationId) {
        return AXIOS.GET(`tenant-api/asset-review-info?userId=${userId}&invitationId=${invitationId}&dateTime=${new Date().toUTCString()}`);
    }
    
}

const externalService = {
    getTroubleShootGuideById,
    getAttachmentsByJobId,
    getAssetById,
    updateAssetDetails,
    submitInvitationAssetList,
    getPropertyAssetReviewInfo
}

export default externalService;
import React from 'react';
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { ButtonToolbar, ToggleButtonGroup, ToggleButton, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Label, Dropdown } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import * as _ from 'lodash';
import 'semantic-ui-css/semantic.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AXIOS } from '../../../Utilities/HttpRequest/Axios';
import jobService from '../../../Services/JobService';
import { AppConfiguration } from "read-appsettings-json";
import propertyService from '../../../Services/PropertyService';
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import ExternalAddAttachments from '../AddAttachments/ExternalAddAttachments';


// const history = useHistory();
class ExternalEditJob extends React.Component {

    notifyError = (msg) => toast.error(msg,{
        position: toast.POSITION.BOTTOM_RIGHT
    });
    state = {
        propertySearchData: [],
        formData: {
            address: '',
            location: '',
            propertyId: '',
            propertyName: '',
            suburbId: '',
            propertyType: '',
            jobBudget: 0
        },
        availableServices: [],
        availableServicesDetails: [],
        selectedService: { childJobTypes: [] },
        selectedServiceType: {
            parentJobType: { id: '' }
        },
        selectedSubServiceType: {},
        jobDetails: { attachments: [] },
        attachments: [],
        jobId: '',
        dueDate: new Date(),
        formattedDueDate: '',
        jobUrgency: '',
        propertyList: [],
        value: '',
        isLoading: false,
        filteredResults: [],
        propertyId: '',
        isPropertiesLoading: false,
        parentJobTypes: [],
        primaryJobTypes: [],
        secondaryJobTypes: [],
        frequentlyUsedServices: [],
        isShowMoreServices: false,
        locationDetails: {
            latitude: '',
            longitude: '',
            placeId: '',
            suburbId: '',
            location: ''
        },
        isMandatoryToUploadFiles: false,
        isJobForMe: 1,
        jobBudget: 0,
        jobForMe: {},
        selectedTradees: [],
        isSelectedOnDemand: false,
        selectedQuoteLimit: 0,
        inviteToProviders: false,
        isJobPosting: false,
        isRestrictKeyCollectionForResidentialProperties: false,
        isJobTypesLoaded: false,
        propertyAddress: '',
        dateFormat:"dd/MM/yyyy",
        isAttachmentsMandotary:false
    }

    componentDidMount() {
        this.GetTenantPropertiesByUserId();
        this.loadJobTypes();
    }

    componentWillReceiveProps() {
        this.loadJobTypes();
    }

    loadJobTypes = () => {
        if (this.props.jobDetails != null && !this.state.isJobTypesLoaded) {
            let selectedDate = this.props.jobDetails.dueDate.indexOf('0001') > -1 ? new Date() : new Date(this.props.jobDetails.dueDate);
            this.setState({ dueDate: selectedDate });
            jobService.GetSubJobTypes(this.props.jobDetails.primaryJobType).then(res => {
                let selectedNode = res;
                this.setState({ primaryJobTypes: selectedNode.childJobTypes, selectedService: selectedNode, selectedServiceType: { parentJobType: { id: '' } } });

                jobService.GetSubJobTypes(this.props.jobDetails.secondaryJobType).then(res => {
                    this.setState({
                        isJobTypesLoaded: true
                    })
                    let selectedNode = res;
                    if (selectedNode == undefined) {
                        let selectedServiceType = {
                            childJobTypes: [],
                            parentJobType: _.find(this.state.primaryJobTypes, f => f.id === this.props.jobDetails.secondaryJobType)
                        }
                        let formData1 = {
                            ...this.state.formData, childJobType: '',
                            childJobTypeId: ''
                        };
                        this.setState({
                            secondaryJobTypes: [],
                            selectedServiceType: selectedServiceType,
                            formData: formData1
                        });

                    } else {
                        let formData2 = {
                            ...this.state.formData, childJobType: '',
                            childJobTypeId: ''
                        };
                        setTimeout(() => {
                            this.setState({
                                secondaryJobTypes: selectedNode.childJobTypes,
                                selectedServiceType: selectedNode,
                                formData: formData2
                            });
                        }, 500)
                    }
                });
            });
        }
    }

    constructor(props) {
        super(props);
        this.title = React.createRef();
        this.jobDescription = React.createRef();
        this.budgetRef = React.createRef();
    }

    onChangeJobType = () => {
        this.setState({
            isJobForMe: this.refs.jobType.props.value,
            jobForMe: {
                propertyType: '',
                address: '',
                latitude: '',
                location: '',
                longitude: '',
                placeId: '',
                suburbId: '',
                budget: ''
            },
        });
    }

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.label })
        this.getChildJobTypes(result.id);
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ filteredResults: [], value });
        if (value.length > 2) {
            this.setState({ isLoading: true })
            let jobTypes = this.props.jobTypes;
            let parents = _.map(jobTypes, m => m.parentJobType);
            const parentJobTypes = _.filter(parents, f => f.parentRef === null);
            this.setState({ parentJobTypes: parentJobTypes });
            setTimeout(() => {
                document.querySelector('.jobpost-main-details .auto-complete').classList.remove("hide-results");
                this.setState({
                    isLoading: false,
                    filteredResults: parentJobTypes.filter(suggestion => suggestion.label.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1)
                })
            }, 300)
        } else {
            document.querySelector('.jobpost-main-details .auto-complete').classList.add("hide-results");
        }
    }

    getChildJobTypes(parentId) {
        jobService.GetSubJobTypes(parentId).then(res => {
            let selectedNode = res;
            this.setState({ primaryJobTypes: selectedNode.childJobTypes, selectedService: selectedNode, selectedServiceType: { parentJobType: { id: '' } } });
        });
    }

    GetFrequentlyUsedJobTypes = () => {
        try {
            jobService.GetFrequentlyUsedJobTypes()
                .then(data => {
                    this.setState({ frequentlyUsedServices: data });
                })

        } catch (error) {
            console.log(error);
        }
    }

    onSelectFrequentlyUsedJobTypes = (event) => {
        try {
            this.getChildJobTypes(event);
        } catch (error) {
            console.log(error);
        }
    }

    showHideMoreService = () => {
        this.setState({
            isShowMoreServices: !this.state.isShowMoreServices,
            selectedService: {}
        })
    }


    getAllAvailableJobTypes() {
        let jobTypes = this.props.jobTypes;
        let parents = _.map(jobTypes, m => m.parentJobType);
        const parentJobTypes = _.filter(parents, f => f.parentRef === null);
        this.setState({ parentJobTypes: parentJobTypes });
    }

    resultRenderer = (event) => {
        return <Label key={event.label} content={event.label}></Label>
    }

    createTypeOfServicesButtons = (props) => {
        if (this.state.selectedService.childJobTypes && this.state.selectedService.childJobTypes.length > 0) {
            return (
                <>
                    <div className="form-group">
                        <label>Please select the type of service you require.<span className="required-icon">*</span></label>
                        <ButtonToolbar className="radio-btn-group">
                            <ToggleButtonGroup
                                type="radio"
                                name="serviceType"
                                onChange={(event) => this.getSubChildJobTypes(event)}
                                defaultValue={this.props.jobDetails.secondaryJobType}>
                                {this.state.selectedService.childJobTypes.map((jbType, index) => {
                                    return <ToggleButton key={index} value={jbType.id}>{jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            if (this.props.jobDetails != null) {
                //this.onSelectFrequentlyUsedJobTypes(this.props.jobDetails.primaryJobType)
            }
        }
    }

    createTypeOfServicesChildButtons = (props) => {
        if (this.state.secondaryJobTypes.length > 0) {
            return (
                <>
                    <div className="form-group">
                        <label>{this.state.selectedServiceType.parentJobType.description}<span className="required-icon">*</span></label>
                        <ButtonToolbar className="radio-btn-group">
                            <ToggleButtonGroup
                                type="radio"
                                name="serviceTypeOption"
                                defaultValue={this.props.jobDetails.jobType}
                                onChange={this.onChangeChildTypeOfServices}>
                                {this.state.secondaryJobTypes.map((jbType, index) => {
                                    return <ToggleButton key={index} value={jbType.id} >{jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            if (this.props.jobDetails != null) {
                //this.getSubChildJobTypes(this.props.jobDetails.secondaryJobType)
            }
        }

    }

    getDueDate = () => {
        if (this.props.jobDetails && this.props.jobDetails.jobUrgencyId == -999) {
            let selectedDate = this.props.jobDetails.dueDate.toString().indexOf('0001') > -1 ? new Date() : new Date(this.props.jobDetails.dueDate);
            return (
                <div className="form-group">
                    <label>Due Date</label>
                    <div>
                        <DatePicker dateFormat={this.state.dateFormat} minDate={new Date()} className="form-control" selected={selectedDate} onChange={this.handleDueDateChange} />
                    </div>
                </div>
            )
        }
    }

    handleDueDateChange = (date) => {
        let jobDetails = this.props.jobDetails;
        jobDetails.dueDate = date;
        this.setState({
            dueDate: date, // ISO String, ex: "2016-11-19T12:00:00.000Z"
        });
    }

    getSubChildJobTypes(parentId) {
        let jobTypes = this.props.jobTypes;
        jobService.GetSubJobTypes(parentId).then(res => {
            this.setState({
                isJobTypesLoaded: true
            })
            let selectedNode = res;
            if (selectedNode == undefined) {
                let selectedServiceType = {
                    childJobTypes: [],
                    parentJobType: _.find(this.state.primaryJobTypes, f => f.id === parentId)
                }
                let formData1 = {
                    ...this.state.formData, childJobType: '',
                    childJobTypeId: ''
                };
                this.setState({
                    secondaryJobTypes: [],
                    selectedServiceType: selectedServiceType,
                    formData: formData1
                });

            } else {
                let formData2 = {
                    ...this.state.formData, childJobType: '',
                    childJobTypeId: ''
                };
                setTimeout(() => {
                    this.setState({
                        secondaryJobTypes: selectedNode.childJobTypes,
                        selectedServiceType: selectedNode,
                        formData: formData2
                    });
                }, 500)
            }
        });
    }

    handleJobUrgencyOnChange = (value) => {
        this.setState({ jobUrgency: value });
        let jobDetails = this.props.jobDetails;
        jobDetails.jobUrgencyId = value;
        this.props.setJobDetails(jobDetails);
    }

    validateExternalJobDetails = () => {
        const { selectedService, selectedServiceType, formData, jobBudget, selectedTradees, isSelectedOnDemand, inviteToProviders } = this.state;
        let v = true;
        if (this.title.current.value === '') { this.notifyError('Please enter a job title.'); v = v && false; }
        if (this.jobDescription.current.value === '') { this.notifyError('Please enter a job description.'); v = v && false; }
        if (document.querySelector('[name="serviceType"]') != null && document.querySelector('[name="serviceType"]:checked') == null) { this.notifyError('Please select a type of service.'); v = v && false; }
        if (document.querySelector('[name="serviceTypeOption"]') != null && document.querySelector('[name="serviceTypeOption"]:checked') == null) { this.notifyError(`Please select ${selectedServiceType.parentJobType.description.replace('?', '')} that you need.`); v = v && false; }
        if (document.querySelector('[name="jobUrgency"]:checked') == null) { this.notifyError('Please select the job urgency.'); v = v && false; }
        // else { if(document.querySelector('[name="jobUrgency"]:checked').value == '-999' && !(new Date(this.state.dueDate.toLocaleDateString()) > new Date(new Date().toLocaleDateString())) ) {  } }
        return v;
    }

    onSubmitJobDetails = () => {
        if (this.validateExternalJobDetails()) {
            let jobDetails = this.props.jobDetails;
            jobDetails.title = this.title.current.value;
            jobDetails.description = this.jobDescription.current.value;
            jobDetails.secondaryJobType = document.querySelector('[name="serviceType"]') == null ? jobDetails.secondaryJobType : document.querySelector('[name="serviceType"]:checked').value;
            jobDetails.jobType = document.querySelector('[name="serviceTypeOption"]') == null ? jobDetails.secondaryJobType :  document.querySelector('[name="serviceTypeOption"]:checked').value;
            jobDetails.jobUrgencyId = document.querySelector('[name="jobUrgency"]:checked').value;
            jobDetails.propertyKeyCollectType = document.querySelector('input[name=propertyKey]:checked').value;

            if (this.state.propertyId != jobDetails.propertyId) {
                jobDetails.propertyId = this.state.propertyId;
                jobDetails.address = this.state.propertyList.find(x => x.propertyId == this.state.propertyId).address;
            }

            if(jobDetails.attachments.length==0 && jobDetails.isAttachmentMandatory ){

                toast.error(`You are required to attach an image  related to the maintenance request.`,{
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                return;
            }

            this.setState({ isJobPosting: true })
            AXIOS.POST(`tenant-api/external-job-post`, jobDetails, { 'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret } })
                .then(response => {
                    this.setState({ isJobPosting: false })
                    if (response == 'Request Unauthorized' || response == 'Job post failed') {
                        toast.error(`Job is not successfully. Please refresh link and try again.`, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    } else {

                        this.getJobDetailsByJobId();

                        this.props.setJobDetails(jobDetails);
                        toast.success(`Job updated Successfully.`,{
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.props.history.push(`/external/overview${this.props.location.search}`);
                        // let { history } = this.props;
                        // history.push({ pathname: '/' });
                    }
                }, error => {
                    console.log(error);
                    this.setState({ isJobPosting: false });
                    toast.error(`Fail to post the job. Please try again.`,{
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
        }
    }

    getJobDetailsByJobId = () => {
        const jobId = window.jobId;
        this.props.setJobDetailsLoadingStatus(true);
        this.setState({ isJobDetailsLoading: true })
        if (jobId) {
            try {
                jobService.GetJobDetailsForExternalUsers(jobId,'').then((details) => {
                    this.setState({
                        jobDetails: details,
                        isJobDetailsLoading: false
                    });
                    this.props.setJobDetails(details);
                    this.props.setJobDetailsLoadingStatus(false);
                });
            } catch (error) {
                console.log(error);
                this.props.setJobDetailsLoadingStatus(false);
            }
        }
    };

    GetTenantPropertiesByUserId = () => {
        this.setState({isPropertiesLoading: true})
        propertyService.GetTenantPropertiesByUserId()
            .then((res) => {
                this.setState({isPropertiesLoading: false});
                let none = [{ propertyId: 0, propertyName: '--None--', suburbId: '', location: '', address: '', propertyType: 'Residential'}]
                //this.props.updateProperties(res.properties);
                if(res.properties.length > 0) {

                    let properties = res.properties;

                    this.setState({
                        propertyList: properties,
                        propertyId: properties[0].propertyId
                     });
                     //this.getPropertyDetailsByPropertyId(properties[0].propertyId);
                } else {
                    this.setState({
                        propertyList: none,
                        propertyId: none[0].propertyId
                     });
                }
            })
    }

    handlePropertyChange = (event, props) => {
        this.setState({
            propertyId : props.value
        });
    }

    render() {
        let serviceTypes = this.createTypeOfServicesButtons();
        let serviceTypeChild = this.createTypeOfServicesChildButtons();
        const dueDate = this.getDueDate();
        const { isLoading, value, propertyList, filteredResults, propertyId, isPropertiesLoading, isJobForMe, jobForMe, isJobPosting } = this.state;
        const { isJobDetailsLoading, jobDetails } = this.props;
       

        return <>
            {(isJobDetailsLoading) ?
                <h2 className="align-items-center d-block">
                    <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />
                </h2>
                :
                (jobDetails == null) ? '' :
                    <>
                        <Form>
                            <div className="jobpost-main-details">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="property">Property</label>
                                            <div>
                                                <Dropdown
                                                    selectOnNavigation={false}
                                                    className='test test'
                                                    selection
                                                    loading={isPropertiesLoading}
                                                    value={propertyId}
                                                    name='propertyId'
                                                    options={propertyList.map((a, index) => { return { key: index, text: a.address, value: a.propertyId }; })}
                                                    placeholder='Choose a Property'
                                                    onChange={this.handlePropertyChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Title<span className="required-icon">*</span></label>
                                            <input type="text" className="form-control"
                                                placeholder="e.g :install a new hot water system" aria-label="test"
                                                defaultValue={jobDetails.title} ref={this.title}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Description<span className="required-icon">*</span></label>
                                            <textarea className="form-control" placeholder="Please provide as much detail about your job so
                                                that the quotes received are as accurate as possible" aria-label="test"
                                                defaultValue={jobDetails.description} ref={this.jobDescription}>
                                            </textarea>
                                        </div>
                                        <div>
                                            <div className="form-group">
                                                <label>Select the service you need<span className="required-icon">*</span></label>
                                                <ButtonToolbar className="radio-btn-group">
                                                    <ToggleButtonGroup type="radio" name="options" defaultValue={-1}>
                                                        <ToggleButton value={-1} >{jobDetails.primaryJobTypeDisplay}</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </ButtonToolbar>
                                            </div>
                                            {serviceTypes}
                                            {serviceTypeChild}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>When do you need the job done?<span className="required-icon">*</span></label>
                                            <ButtonToolbar className="radio-btn-group">
                                                <ToggleButtonGroup type="radio" name="jobUrgency" ref="jobUrgency" defaultValue={this.props.jobDetails.jobUrgencyId.toString()} onChange={this.handleJobUrgencyOnChange}>
                                                    <ToggleButton value={'1'}>Within a day (Urgent)</ToggleButton>
                                                    <ToggleButton value={'2'}>Within a few days</ToggleButton>
                                                    <ToggleButton value={'3'}>Within a week</ToggleButton>
                                                    <ToggleButton value={'-999'}>Schedule a date</ToggleButton>
                                                </ToggleButtonGroup>
                                            </ButtonToolbar>
                                        </div>
                                        {dueDate}
                                        <div className="form-group">
                                            <label>Preferred key collection option<span className="required-icon">*</span></label>
                                            <div>
                                                <div className="custom-control custom-radio">
                                                    <input name="propertyKey" type="radio" id="check1" className="custom-control-input" value="1" defaultChecked={jobDetails.propertyKeyCollectType == 1} />
                                                    <label title="" type="checkbox" htmlFor="check1" className="custom-control-label">Please collect keys from the office to enter the property</label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input name="propertyKey" type="radio" id="check2" className="custom-control-input" value="2" defaultChecked={jobDetails.propertyKeyCollectType == 2} />
                                                    <label title="" type="checkbox" htmlFor="check2" className="custom-control-label">Collect the key from the office, inform the tenant on date & time</label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input name="propertyKey" type="radio" id="check3" className="custom-control-input" value="3" defaultChecked={jobDetails.propertyKeyCollectType == 3} />
                                                    <label title="" type="checkbox" htmlFor="check3" className="custom-control-label">Contact tenant to arrange access and time</label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="form-group">
                                            <Link className="form-control rich-control" to={`/external/add-attachments?code=${window.jobId}`}>
                                                <span>
                                                    <img src="/Images/upload-icon.png" alt="upload-icon" />
                                                </span>
                                                <label>Add images/ videos</label>
                                                <div className="plus-icon">
                                                    <i className="ba-icn ba-icn-close"></i>
                                                </div>
                                            </Link>
                                        </div> */}
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <ExternalAddAttachments 
                                                    jobDetails={ { id: jobDetails.id, attachments: this.state.attachments }} 
                                                    onSubmitAttachments={(event) => { this.setState( { attachments: event } ) }} >                                        
                                                </ExternalAddAttachments>
                                            </div>
                                            {jobDetails.isAttachmentMandatory ? <div className="required-icon">*</div> : ''}
                                        </div>
                                        {(jobDetails.attachments.length > 0) ? 
                                            <ImageViewer attachments={jobDetails.attachments} isExternal={true}></ImageViewer>: ''
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </Form>
                        {(jobDetails != null && (jobDetails.workflowStatusId == 1 || jobDetails.workflowStatusId == 5)) ? <div className="card-footer text-right edit-job-actions">
                            <button className="btn btn-primary" onClick={() => this.onSubmitJobDetails()} disabled={isJobPosting}>
                                <i className="ba-icn ba-icn-ok"></i>
                                {"  "}Save and post
                                {(isJobPosting) ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={60} /> : ''}
                            </button>
                        </div> : ''}
                    </>
            }

        </>
    }

}

const mapStateToProps = (state, ownProps) => {
    try {
        return {
            jobDetails: state.Jobs.jobDetails,
            isJobDetailsLoading: state.Jobs.isJobDetailsLoading,
            jobTypes: state.Jobs.jobTypes,
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setJobDetails: jobDetails => { dispatch({ type: 'SET_JOB_DETAILS', jobDetails: jobDetails }) },
        setJobDetailsLoadingStatus: status => { dispatch({ type: 'SET_JOB_DETAILS_LOADING_STATUS', status: status }) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalEditJob);
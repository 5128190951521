const initState = {
    isOpen: false,
    isRefreshGrid: false,
    conversations: [],
    jobTypes: [],
    jobDetails: null,
    isJobDetailsLoading: false
}


const JobsReducer = (state = initState, action) => {
    switch (action.type) {
        case "SHOW_JOB_DETAILS_DRAWER":
            return {
                ...state,
                isOpen: action.isOpen,
                refreshMyJobs: action.refreshMyJobs
            }
        case "Conversations":
            return {
                ...state,
                conversations: action.chatList
            }
        case 'REFRESH_GRID':
            return {
                ...state,
                isRefreshGrid: action.isRefresh
            }
        case 'SET_JOB_TYPES':
            return {
                ...state,
                jobTypes: action.jobTypes
            }
        case 'SET_JOB_DETAILS':
            return {
                ...state,
                jobDetails: action.jobDetails
            }
        case 'SET_JOB_DETAILS_LOADING_STATUS':
            return {
                ...state,
                isJobDetailsLoading: action.status
            }
        default:
            return state;
    }
}

export default JobsReducer;
import React, { useState } from "react";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import ImageViewer from "../../Common/ImageViewer/ImageViewer";

// Styles
//import "../OwnerApproval/JobApproveDrawer.scss";

class JobNotes extends React.Component {

  state = {
    jobDetails: {},
    jobNotes: [],
    userId: ""
  };
  constructor(props) {
    super(props);

    this.getJobNotesByJobId = this.getJobNotesByJobId.bind(this);
  }


  componentDidMount() {
    this.setState({ jobDetails: this.props.jobDetails, userId: this.props.userId });
    this.getJobNotesByJobId();
  }

  render() {

    return (
      <>
        {this.createJobNotesBody()}
      </>
    );
  }

  createJobNotesBody = () => {
    const jobNotes = this.state.jobNotes;
    return (
      <div className="panel-body">
        <div className="job-notes-container">
          {(jobNotes.length !== 0 &&
            jobNotes.map((note, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="job-note ng-star-inserted">
                    <div className="job-note-headerL">
                      <div className="d-flex bd-highlight mb-2">
                        <div className="mr-auto bd-highlight">
                          <p className="m-0 user-name">{note.createdUserName}</p>
                          <p className="posted-date">Posted on {note.createdOn}</p>
                        </div>
                        <div className="bd-highlight">
                          <p className="m-0 user-type mr-2">{note.createdUserType}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="note-type job-note p-0">
                        <label className="label label-default p-0">
                          {note.type}
                        </label>
                      </div>
                      <div className="job-note-email"
                        dangerouslySetInnerHTML={{
                          __html: note.note
                        }}>
                        </div>

                        { note && note.attachments && note.attachments.length > 0 ?   
              <div className="pt-2">
                        <div className="external-gallery w-100 d-inline-block">
                          <div className="float-left">
                            <ImageViewer
                              attachments={note.attachments}
                              isAfter={false}
                              isExternal={true}
                            ></ImageViewer>
                            </div>
                        </div>
                      </div>
                      :""}

                    
                    
                    </div>
                  </div>
                  <SeparateLine color="#dce5eb" hidden={(jobNotes.length > 1) ? '' : 'hidden'} />
                </React.Fragment>
              );
            })) ||
            (jobNotes.length === 0 && <p>Notes not available</p>)}
        </div>
      </div>
    );
  };

  getJobNotesByJobId = () => {

    const userId = (this.props.userId && this.props.userId != null) ? this.props.userId : localStorage.getItem("exernalUserId");
    const jobId = this.props.jobDetails.id;

    if (jobId) {
      try {
        AXIOS.GET(
          `tenant-api/job-notes?jobId=${jobId}&userId=${userId}`
        ).then(data => {
          this.setState({
            jobNotes: data
          });
        })
      } catch (error) {
        console.log(error);
      }
    }
  };
}
const SeparateLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color
    }}
  />
);

export default connect(
)(JobNotes);

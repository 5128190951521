export const WEEKDAYS = [
    { fullName: "Sunday", shortName: "sun", initial: "S" },
    { fullName: "Monday", shortName: "mon", initial: "M" },
    { fullName: "Tuesday", shortName: "tue", initial: "T" },
    { fullName: "Wednesday", shortName: "wed", initial: "W" },
    { fullName: "Thursday", shortName: "thu", initial: "T" },
    { fullName: "Friday", shortName: "fri", initial: "F" },
    { fullName: "Saturday", shortName: "sat", initial: "S" },
];

export const MONTHS = [
    {fullName: 'January', shortName: 'Jan', key: 0 },
    {fullName: 'February', shortName: 'Feb', key: 1 },
    {fullName: 'March', shortName: 'Mar', key: 2 },
    {fullName: 'April', shortName: 'Apr', key: 3 },
    {fullName: 'May', shortName: 'May', key: 4 },
    {fullName: 'June', shortName: 'Jun', key: 5 },
    {fullName: 'July', shortName: 'Jul', key: 6 },
    {fullName: 'August', shortName: 'Aug', key: 7 },
    {fullName: 'September', shortName: 'Sep', key: 8 },
    {fullName: 'October', shortName: 'Oct', key: 9 },
    {fullName: 'November', shortName: 'Nov', key: 10 },
    {fullName: 'December', shortName: 'Dec', key: 11 }
];

// export const TIME_SLOTS = [
//     { start: "0000", end: "0200" },
//     { start: "0200", end: "0400" },
//     { start: "0400", end: "0600" },
//     { start: "0600", end: "0800" },
//     { start: "0800", end: "1000" },
//     { start: "1000", end: "1200" },
//     { start: "1200", end: "1400" },
//     { start: "1400", end: "1600" },
//     { start: "1600", end: "1800" },
//     { start: "1800", end: "2000" },
//     { start: "2000", end: "2200" },
//     { start: "2200", end: "2400" }
// ];

const WORK_DAY = [
    { start: "0800", end: "1000" },
    { start: "1000", end: "1200" },
    { start: "1200", end: "1400" },
    { start: "1400", end: "1600" },
    { start: "1600", end: "1800" }
];

function getWorkDaySlots(day) {
    return WORK_DAY.map(slot => ({ ...slot, day }));
}

export const WORK_WEEK = ["mon", "tue", "wed", "thu", "fri"].map(getWorkDaySlots).flat();

export const TIME_SLOTS = [
    { displayText: '12 AM', time: 24 , hours: 0, minutes: 0},
    { displayText: '', time: 2430, hours: 0, minutes: 30 },
    { displayText: '1 AM', time: 10 , hours: 1, minutes: 0},
    { displayText: '', time: 130, hours: 1, minutes: 30 },
    { displayText: '2 AM', time: 20 , hours: 2, minutes: 0},
    { displayText: '', time: 230, hours: 2, minutes: 30 },
    { displayText: '3 AM', time: 30 , hours: 3, minutes: 0},
    { displayText: '', time: 330, hours: 3, minutes: 30 },
    { displayText: '4 AM', time: 40 , hours: 4, minutes: 0},
    { displayText: '', time: 430, hours: 4, minutes: 30 },
    { displayText: '5 AM', time: 50 , hours: 5, minutes: 0},
    { displayText: '', time: 530, hours: 5, minutes: 30 },
    { displayText: '6 AM', time: 60 , hours: 6, minutes: 0},
    { displayText: '', time: 630, hours: 6, minutes: 30 },
    { displayText: '7 AM', time: 70 , hours: 7, minutes: 0},
    { displayText: '', time: 730, hours: 7, minutes: 30 },
    { displayText: '8 AM', time: 80 , hours: 8, minutes: 0},
    { displayText: '', time: 830, hours: 8, minutes: 30 },
    { displayText: '9 AM', time: 90, hours: 9, minutes: 0 },
    { displayText: '', time: 930, hours: 9, minutes: 30 },
    { displayText: '10 AM', time: 100, hours: 10, minutes: 0 },
    { displayText: '', time: 1030, hours: 10, minutes: 30 },
    { displayText: '11 AM', time: 110, hours: 11, minutes: 0 },
    { displayText: '', time: 1130, hours: 11, minutes: 30 },
    { displayText: '12 AM', time: 120, hours: 12, minutes: 0 },
    { displayText: '', time: 1230, hours: 12, minutes: 30 },
    { displayText: '1 PM', time: 1300, hours: 13, minutes: 0 },
    { displayText: '', time: 1330, hours: 13, minutes: 30 },
    { displayText: '2 PM', time: 140, hours: 14, minutes: 0 },
    { displayText: '', time: 1430, hours: 14, minutes: 30 },
    { displayText: '3 PM', time: 150, hours: 15, minutes: 0 },
    { displayText: '', time: 1530, hours: 15, minutes: 30 },
    { displayText: '4 PM', time: 160, hours: 16, minutes: 0 },
    { displayText: '', time: 1630, hours: 16, minutes: 30 },
    { displayText: '5 PM', time: 170, hours: 17, minutes: 0 },
    { displayText: '', time: 1730, hours: 17, minutes: 30 },
    { displayText: '6 PM', time: 180, hours: 18, minutes: 0 },
    { displayText: '', time: 1830, hours: 18, minutes: 30 },
    { displayText: '7 PM', time: 190, hours: 19, minutes: 0 },
    { displayText: '', time: 1930, hours: 19, minutes: 30 },
    { displayText: '8 PM', time: 200, hours: 20, minutes: 0 },
    { displayText: '', time: 2030, hours: 20, minutes: 30 },
    { displayText: '9 PM', time: 210, hours: 21, minutes: 0 },
    { displayText: '', time: 2130, hours: 21, minutes: 30 },
    { displayText: '10 PM', time: 220, hours: 22, minutes: 0 },
    { displayText: '', time: 2230, hours: 22, minutes: 30 },
    { displayText: '11 PM', time: 2300, hours: 23, minutes: 0 },
    { displayText: '', time: 2330, hours: 23, minutes: 30 }
]
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from "react-moment";
import { Modal, Button, Tabs, Tab, Badge, Form } from "react-bootstrap";
import moment from 'moment';

import ImageViewer from "../../../Common/ImageViewer/ImageViewer";
import SignatureViewer from "../../../Common/ImageViewer/SignatureViewer";
import AttachmentsDrawer from "../../../Drawers/AttachmentsDrawer/AttachmentsDrawer";
import JobLocksDrawer from '../../../Drawers/JobLocksDrawer/JobLocksDrawer';
import jobService from "../../../../Services/JobService";

class JobDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        averageCostModel: {
            jobAverageCost: 0.00,
            jobCount: 0,
            confidenceLevel: 1
        },
        isAverageCostModelLoaded: false,
        isTenantManageJob:this.props.isTenantManageJob?true:false
    }

    urgencyFormatter(urgencyType) {
        switch (urgencyType) {
            case -1:
            case "Emergency":
                return <div className='common-tag small red'>Emergency</div>;
            case 1:
            case "Urgently":
                return <div className='common-tag small red'>Urgently</div>;
            case 2:
            case "Within a few days":
                return <div className='common-tag small badge-warning'>Within a few days</div>;
            case 3:
            case "Within a week":
                return <div className='common-tag small badge-primary'>Within a week</div>;
            case 999:
            case "By this date":
                return <div className='common-tag small success'>By this date</div>;
            default:
                return <div>{urgencyType === 0 ? "" : urgencyType}</div>;
        }
    }

    propertyTypeName(propertyType) {
        switch (propertyType) {
            case 0:
                return "Residential";
            case 1:
                return "Commercial";
            case 2:
                return "Unknown";
            case 3:
                return "Strata";
        }
    }

    budgetFormatter(budgetId, budgetAmount, budgetMax) {
       
        switch (budgetId) {
            case 0:
       
                return <div className="table-data "> Other</div>;
            case 1:
          
                return <div className="table-data "> Under $500</div>;
            case 2:
          
                return <div className="table-data "> Between $500 - $1000</div>;
            case 3:
           
                return <div className="table-data "> Between $1000 - $2000</div>;
            case 4:
               return <div className="table-data "> Between $2000 - $5000</div>
            case 5:
         
                return <div className="table-data "> Over $5000</div>;
            case 7:
                return <div className="table-data "> {'$ ' + budgetAmount} - {'$ ' + budgetMax}</div>;
            case -999:
                return <div className="table-data "> {budgetAmount == 0 ? 'Not supplied' : '$ ' + budgetAmount}</div>;
            case 6:
                return <div className="table-data "> Under ${budgetAmount}</div>;
        }
    }

    //for getting average job cost
    getPredictiveAverageCost = (jobId) => {
        try {
            jobService.GetPredictiveJobAverageCost(jobId)
                .then(data => {
                    this.setState({ averageCostModel: data });
                    this.setState({ isAverageCostModelLoaded: true });
                });
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        if (this.props.showAverageCostWhenOwnerApproval) {
            this.getPredictiveAverageCost(this.props.jobDetails.id);
        }
    }
    
    render() {
        let isTenant = localStorage.getItem("userType") == 4 ? true : false;
        const jobData = this.props.jobDetails;
        return (
            <div className="panel-body tabbed-panel job-detail-card">
                <div className="job-labels">
                    {this.urgencyFormatter(jobData.jobUrgencyDisplay)}
                    <label> Posted on:
                    <Moment format={this.props.dateFormat.toUpperCase()}>
                            {jobData.postedOn}
                        </Moment>
                   
                                   
                    </label>
                    <label>#{jobData.jobNumber}</label>
                </div>
                <h5>{jobData.title}</h5>
                <div className="job-description" dangerouslySetInnerHTML={{ __html:jobData.description }}></div>
                <div className="d-flex justify-content-start mb-4">
                    <div className='common-tag small light-gray mr-2'>
                        <span> {jobData.primaryJobTypeDisplay}: </span>
                        <span>{jobData.secondaryJobTypeDisplay}  </span>
                        {(jobData.jobTypeDisplay !== jobData.primaryJobTypeDisplay && jobData.jobTypeDisplay !== jobData.secondaryJobTypeDisplay)? <span>: {jobData.jobTypeDisplay}</span> : ''}
                    </div>

                </div>
                {(this.props.showAverageCostWhenOwnerApproval && this.state.isAverageCostModelLoaded) ?
                    <div className="mb-2">
                            <div className={this.getConfidenceLevelClass(this.state.averageCostModel.confidenceLevel)}>The suggested average cost range for the job is +/-${this.state.averageCostModel.jobAverageCost}</div>
                    </div>
                    :
                    ''
                }
                <div className="job-details-table ">
                    <div className="job-details-table-row">
                        <div className="table-label">Status</div>
                        <div className="table-data text-warning">
                            {(jobData.statusDisplay == 'Job Approval Pending' && this.props.isMyJobs) ? 'Job Completed' : jobData.statusDisplay}
                        </div>
                    </div>
                    <div className="job-details-table-row ">
                        <div className="table-label">Estimate</div>
                        {this.budgetFormatter(jobData.budgetId, jobData.budgetAmount, jobData.budgetMax)}
                    </div>
                    <div className={(jobData.property == null) ? 'd-none' : 'job-details-table-row'}>
                        <div className="table-label">Property</div>
                        <div className="table-data "> {jobData.propertyName} </div>
                    </div>
                    <div className="job-details-table-row ">
                        <div className="table-label">Property Type</div>
                        <div className="table-data "> {this.propertyTypeName(jobData.propertyTypeId)} </div>
                    </div>
                    <div className="job-details-table-row ">
                        <div className="table-label">Suburb</div>
                        <div className="table-data "> {jobData.suburbDisplay} </div>
                    </div>
                    <div className="job-details-table-row ">
                        <div className="table-label">Address</div>
                        <div className="table-data">{jobData.address}</div>
                    </div>
                    <div className={(jobData.property == null) ? 'job-details-table-row ' : 'd-none'}>
                        <div className="table-label">Quote Limit</div>
                        <div className="table-data">{jobData.quoteLimit}</div>
                    </div>
                    {(jobData.scheduleEventId != null)? 
                        <div className="job-details-table-row ">
                            <div className="table-label">Scheduled date</div>
                            <div className="table-data">{moment(jobData.scheduleFromDate).format(this.props.dateFormat.toUpperCase()+' HH:mm')} to {moment(jobData.scheduleToDate).format(this.props.dateFormat.toUpperCase()+' HH:mm')}</div>
                        </div>: ''
                    }
                    {this.getPMNotes(jobData.ownerApprovalComment)}
                    <div className="job-details-table-row ">
                        <div className="table-label">Note</div>
                        <div className="table-data">
                            {this.getPropertyKeyMessage(
                                jobData.propertyKeyCollectType,
                                jobData.propertyTypeId
                            )}
                        </div>
                    </div>
                    {(jobData.serviceConsumer && jobData.serviceConsumer != null && !jobData.isHideTenantDetailsToOwner)? 
                        <>
                            <div className="job-details-table-row ">
                                <div className="table-label">Tenant name</div>
                                <div className="table-data">{jobData.serviceConsumer.businessName}</div>
                            </div>
                            {isTenant ? <>
                                <div className="job-details-table-row ">
                                <div className="table-label">Tenant email address</div>
                                <div className="table-data">{jobData.serviceConsumer.email}</div>
                            </div>
                            {jobData.serviceConsumer.mobile == '' ? '' : <>
                                <div className="job-details-table-row ">
                                    <div className="table-label">Tenant mobile number</div>
                                    <div className="table-data">{jobData.serviceConsumer.mobile}</div>
                                </div>
                            </>}
                            </>: ''}
                            
                        </>: ''
                    }
                </div>
                {
                    (jobData.propertyKeyCollectType != null
                        && (jobData.propertyKeyCollectType == 1 || jobData.propertyKeyCollectType == 2)) ?
                        <JobLocksDrawer jobId={jobData.id} isSelectable={false}></JobLocksDrawer>
                        : ''
                }
                 {(jobData.statusDisplay == 'Job Completed' || (jobData.statusDisplay == 'Job Approval Pending' && this.props.isMyJobs) || this.state.isTenantManageJob) ? '' :
                    <AttachmentsDrawer
                        jobDetails={jobData}
                        onSubmitAttachments={() => {
                            this.props.getJobDetails(jobData.id);
                        }}
                    ></AttachmentsDrawer>
                }

                {(jobData.hasOwnProperty("attachments") && jobData.attachments) ?
                    <>
                        <ImageViewer attachments={jobData.attachments.filter(a => !a.isAfter && a.type == 27)} isAfter={false} isInvoice={true}></ImageViewer>
                        <ImageViewer attachments={jobData.attachments.filter(a => a.isAfter && a.type == 27)} isAfter={true} isInvoice={true}></ImageViewer>
                    </>: ''
                }

                {(jobData.hasOwnProperty("attachments") && jobData.attachments) ?
                    <>
                        <ImageViewer attachments={jobData.attachments.filter(a => !a.isAfter && a.type != 27)} isAfter={false}></ImageViewer>
                        <ImageViewer attachments={jobData.attachments.filter(a => a.isAfter && a.type != 27)} isAfter={true}></ImageViewer>
                    </>: ''
                }
                {this.getSignatureAttachments(jobData.signatureAttachments)}
            </div>
        )
    }

    getSignatureAttachments = (signatureAttachments) => {

        if (signatureAttachments != null) {
          
            return (
                <div className="signature-display">
                    <div className="job-completed">
                        <span class="complete-icon"><i className="ba-icn ba-icn-ok"></i></span> Job Completed
                    </div>
                    <div className="signature-details">
                        <SignatureViewer attachments={signatureAttachments} ></SignatureViewer>
                        <div className="signature-name">{this.props.jobDetails.jobCompleteSigneeName }</div>
                            <div className="signature-date">On <Moment format={this.props.dateFormat.toUpperCase()}>
                                    {signatureAttachments.lastUpdatedDateTime}
                                </Moment>
                            </div>
                    </div>

                
                </div>
            );
        }

    }


    getPropertyKeyMessage(keyCollectionType, propertyType) {
        if (propertyType === 1) {
            switch (keyCollectionType) {
                case 1:
                    return "Please collect keys from the office to enter the property";

                case 2:
                    return "Please collect the keys from the office but let the tenant know what time you will be on site";

                case 3:
                    return "Please book a time with the tenant to meet at the property";

                default:
                    break;
            }
        } else {
            switch (keyCollectionType) {
                case 1:
                    return "Please collect keys from the office to enter the property";

                case 2:
                    return "Collect the key from the office, inform the tenant on date & time";

                case 3:
                    return "Contact tenant to arrange access and time";

                default:
                    break;
            }
        }
    }

    getPMNotes = (ownerComment) => {

        if (ownerComment != null && ownerComment.trim() != "") {
            return (
                <div className="job-details-table-row ">
                    <div className="table-label">PM note</div>
                    <div className="table-data" dangerouslySetInnerHTML={{ __html: ownerComment }}></div>
                </div>
            );
        }
    }

    getConfidenceLevelClass(confidenceLevel) {
        switch (confidenceLevel) {
            case 1:
                return "confident-lbl confident-low";
            case 2:
                return "confident-lbl confident-medium";
            case 3:
                return "confident-lbl confident-high";
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    try {
      return {
        dateFormat: state.User.dateFormat
      };
    } catch (error) {
      console.log(error);
    }
  };

export default connect(mapStateToProps)(withRouter(JobDetail));
import React from "react";
import { Navbar } from "react-bootstrap";
import ImageViewer from "../../Common/ImageViewer/ImageViewer";
import externalService from "../../../Services/ExternalService";
import { connect } from "react-redux";
import * as _ from 'lodash';
import "./Assets.scss";

class AssetCommanView extends React.Component {

    state = {
        currentStep: this.props.currentStep,
        assetDetails: this.props.asset,
        assetList: this.props.assetList,
        invitationId: this.props.invitationId
    };

  componentDidMount() {
    const { currentStep } = this.props;
  }

    handleClick = (page) => {
        this.setState({ currentStep: page });
        this.props.sendDataToParent(page);
        if (page === 5) {
            this.updateAssetDetals();
        }
        if (page === 8) {
            this.saveAssetList();
        }
    };

  updateAssetDetals = () => {
    try {
        externalService.updateAssetDetails(this.state.assetDetails).then((res) => {
            if (res) this.props.sendDataToParent(6);
        });
    } catch (error) {
        this.setState({
            currentStep: 10
        });
    }
  };

  saveAssetList = () => {
    var updatedArray = _.filter(this.state.assetList, f => f.isUpdated == true);
    let model = {
        invitationId: this.state.invitationId,
        assetList: updatedArray
    }

    try {
        externalService.submitInvitationAssetList(model).then(res => {
            if (res) this.props.sendDataToParent(6);
        });
    } catch (error) {
        this.setState({
            currentStep: 10
        });
    }
  };

  render() {
    return (
      <div className="App">
        {this.state.currentStep === 1 && (
          <div>
            <div className="container w-100 d-flex align-items-center flex-column text-center align-items-center bg-light-blue py-5">
              <h5 className="mt-0 text-dark fs-16 fw-500">
                Hi {this.props.assetsReviewInfo.recipientTeantName}
              </h5>
              <h1 className="mt-0 text-purple fs-24 fw-500">Welcome to your</h1>
              <h3 className="mt-0 text-dark fs-16">Asset Upload Portal</h3>
            </div>
            <div className="shadow-lg bg-white w-100 py-5">
              <div className="container text-center">
                <h5 className="text-dark fs-16 fw-500">
                  Here, you can conveniently upload photos of assets <br />
                  for your current property
                </h5>
                <h1 className="fs-20 text-purple">
                  {this.props.assetsReviewInfo.propertyAddress}
                </h1>
                <h5 className="text-dark fs-16 fw-500">Let's get started!</h5>
                <h5 className="fs-12 text-dark fw-500">
                  Regards{" "}
                  {this.props.assetsReviewInfo.requestedPropertyMangerName}{" "}
                  {this.props.assetsReviewInfo.requestedPropertyMangerEmail}
                </h5>
              </div>
            </div>
            <div className="container w-100 text-center py-5 d-flex align-items-center justify-content-center flex-column">
              <h5 className="fs-16 text-light fw-300">Tenant detail</h5>
              <div className="white-box p-5 shadow-lg bg-white">
                <img src="/images/user-info.png" />
                <h3 className="text-dark">
                  {this.props.assetsReviewInfo.recipientTeantName}
                </h3>
                <h3 className="mt-0 text-dark">{this.props.assetsReviewInfo.recipientEmail}</h3>
              </div>
            </div>
            <div className="shadow-lg w-100 py-5">
              <div className="container d-flex align-items-center justify-content-center">
                <button
                  className="btn bg-purple text-white btn-size border-radius-22-5"
                  onClick={() => this.handleClick(2)}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.currentStep === 2 && (
          <div>
            <h1 className="container fs-20 text-center text-purple fw-500 py-5">
              Tips on uploading clear and quality photos of assets.
            </h1>
            <h3 className="container fs-15 d-flex align-items-center justify-content-start text-light-dark py-5 text-left">
              <span className="badge bg-purple text-white mr-3">1</span>Your
              first photo should be a standard front-facing photo of the asset
              you’re uploading. (Ex: Refrigerators, Television, Microwaves….)
            </h3>
            <div className="container d-flex w-100 align-items-center justify-content-center">
              <img src="/images/air-conditioner.png" />
            </div>
            {this.props.invitationId ?  <h3 className="container fs-15 d-flex align-items-center justify-content-start text-light-dark py-5 text-left">
              <span className="badge bg-purple text-white mr-3">2</span>Your
              second photo should be of the compliance plate of the asset you’re
              uploading.
            </h3>:<></>}
            {this.props.invitationId ? <div className="container d-flex w-100 align-items-center justify-content-center">
              <img src="/images/compliance-plate.png" />
            </div>:<></>}
            <div className="shadow-lg w-100 py-5 my-5">
              <div className="container d-flex align-items-center justify-content-center gap-2">
                <button
                  className="btn bg-dark text-white border-radius-22-5 mr-3 fs-12 btn-size"
                  onClick={() => this.handleClick(1)}
                >
                  GO BACK
                </button>{" "}
                <button
                  className="btn bg-purple text-white border-radius-22-5 fs-12 btn-size"
                  onClick={() => this.handleClick(3)}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.currentStep === 4 && (
          <div className="home-wrapper">
            <div className="container w-100 d-flex align-items-center flex-column text-center align-items-center full-height">
            <h2 className="mt-0 text-purple fs-24 fw-500">Your Asset Uploading process <br/>is almost complete</h2>
            <div className="d-flex align-items-center flex-column text-center align-items-center py-5">
              <img src="/images/complete.png" />
            </div>
            <h5 className="text-dark fs-16 fw-500">Please make sure you have included all the <br/>relevant assets of your property.</h5>
            </div>
            <div className="shadow-lg bg-white w-100 py-5 my-5">
              <div className="container d-flex align-items-center justify-content-center gap-2">
                <button
                  className="btn bg-dark text-white border-radius-22-5 mr-3 fs-12 btn-size"
                  onClick={() => this.handleClick(3)}
                >
                  GO BACK
                </button>{" "}
                <button
                  className="btn bg-purple text-white border-radius-22-5 fs-12 btn-size"
                  onClick={() => this.handleClick(5)}
                >
                  SEND
                </button>
              </div>
            </div>
          </div>  
        )}
        {this.state.currentStep === 6 && (
          <div className="home-wrapper ">
             <div className="container w-100 d-flex align-items-center flex-column text-center align-items-center py-5">
            <h2 className="mt-0 text-purple fs-24 fw-500">Your Asset Uploading process<br/>is completed.</h2>
            <div className="d-flex align-items-center flex-column text-center align-items-center py-5">
              <img src="/images/complete.png" />
            </div>
            <h5 className="text-dark fs-16 fw-500">The report has been sent to the Bricks And Agent<br/>successfully</h5>
            </div>
          </div>
        )}
        {this.state.currentStep === 7 && (
            <div className="home-wrapper">
                <div className="container w-100 d-flex align-items-center flex-column text-center align-items-center full-height">
                    <h2 className="mt-0 text-purple fs-24 fw-500">Your Asset Uploading process <br />is almost complete</h2>
                    <div className="d-flex align-items-center flex-column text-center align-items-center py-5">
                        <img src="/images/complete.png" />
                    </div>
                    <h5 className="text-dark fs-16 fw-500">Please make sure you have included all the <br />relevant assets of your property.</h5>
                </div>
                <div className="shadow-lg bg-white w-100 py-5 my-5">
                    <div className="container d-flex align-items-center justify-content-center gap-2">
                        <button
                            className="btn bg-dark text-white border-radius-22-5 mr-3 fs-12 btn-size"
                            onClick={() => this.handleClick(3)}
                        >
                            GO BACK
                        </button>{" "}
                        <button
                            className="btn bg-purple text-white border-radius-22-5 fs-12 btn-size"
                            onClick={() => this.handleClick(8)}
                        >
                            SEND
                        </button>
                    </div>
                </div>
            </div>
        )}
        {this.state.currentStep === 10 && (
            <div className="home-wrapper ">
                <div className="container w-100 d-flex align-items-center flex-column text-center align-items-center py-5">
                    <h2 className="mt-0 text-purple fs-24 fw-500">404</h2>
                    <div className="d-flex align-items-center flex-column text-center align-items-center py-5">
                        <img src="/images/complete.png" />
                    </div>
                    <h5 className="text-dark fs-16 fw-500">Something went wrong!</h5>
                </div>
            </div>
        )}
      </div>
    );
  }
}

export default connect()(AssetCommanView);

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import './AutoComplete.scss';

class AutoComplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: ""
        };
    }

    // Event fired when the input value is changed
    onChange = (e) => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        if(userInput.length > 2) {
            this.props.onChange(userInput);
            this.setState({
                activeSuggestion: 0,
                filteredSuggestions: this.props.dataList,
                showSuggestions: true,
                userInput: userInput
            });
            
        } else {
            this.setState({
                activeSuggestion: 0,
                filteredSuggestions: [],
                showSuggestions: false,
                userInput: userInput
            });
            this.props.onSelect(null);
        }

        

        // Filter our suggestions that don't contain the user's input
        // const filteredSuggestions = suggestions.filter(
        //     suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        // );

        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        // this.setState({
        //     activeSuggestion: 0,
        //     filteredSuggestions,
        //     showSuggestions: true,
        //     userInput: e.currentTarget.value
        // });
    };

    // Event fired when the user clicks on a suggestion
    onClick = (event, data) => {
        // Update the user input and reset the rest of the state
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: event.currentTarget.innerText
        });

        this.props.onSelect(data);
    };

    // Event fired when the user presses a key down
    onKeyDown = (e) => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: this.props.dataList,
        });
        switch (e.keyCode) {
           
            case 13: // User pressed the enter key, update the input and close the suggestions
                let suggest = activeSuggestion;
                this.setState({
                    activeSuggestion: 0,
                    showSuggestions: false,
                    userInput: this.props.dataList[suggest].label
                });
                this.props.onSelect(this.props.dataList[suggest]);
                break;
            case 38: // User pressed the up arrow, decrement the index
                if (activeSuggestion === 0) {
                    return;
                }
                this.setState({ activeSuggestion: activeSuggestion - 1 }); 
                break;
            case 40: // User pressed the down arrow, increment the index
                if (filteredSuggestions.length>0 &&(activeSuggestion - 1 === filteredSuggestions.length)) {
                    return;
                }
                this.setState({ activeSuggestion: activeSuggestion + 1 });
                break;
        };
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
              activeSuggestion,
              showSuggestions,
              userInput
            }
          } = this;

          const { placeholder } = this.props;

          let suggestionsListComponent;

          if(showSuggestions && userInput) {
              if(this.props.dataList && this.props.dataList.length > 0) {
                  suggestionsListComponent = (
                      <ul className="suggestions">
                        {
                            this.props.dataList.map((suggestion, index) => {
                                let className;

                                // Flag the active suggetion with a class
                                if(index == activeSuggestion) {
                                    className = 'suggestion-active';
                                }

                                return (
                                    <li className={className} key={index} onClick={(event) => onClick(event, suggestion)}>
                                        {suggestion[this.props.title]}
                                    </li>
                                );
                            })
                        }
                      </ul>
                  );
              } else {
                  if (this.props.errorMsg == null) {
                      suggestionsListComponent = (
                          <div className="no-suggetions text-danger">
                              No Suggestions !
                          </div>
                      );
                  }
                  else {
                      suggestionsListComponent = (
                          <div className="no-suggetions text-danger">
                              {this.props.errorMsg}
                          </div>
                      );
                  }
              }
          }

        return (
            <Fragment>
                <input
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={userInput}
                    className="form-control"
                    placeholder={placeholder}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default AutoComplete;
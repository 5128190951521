import { AXIOS } from '../Utilities/HttpRequest/Axios.js';
import * as _ from 'lodash';
import { AppConfiguration } from 'read-appsettings-json';
const pamApi = AppConfiguration.Setting().PamApiEndPoint;
const SendVerificationCode = (mobileNumber) => {
    return AXIOS.GET(`tenant-api/get-messenger-phone-number-verification-code?mobileNumber=%2B${mobileNumber}`);
}

const SendVerificationCodeViaEmail = (email) => {
    return AXIOS.GET(`tenant-api/get-email-verification-code?userEmail=${email}`);
}

const SendVerificationCodeForOwner = (mobileNumber) => {
    return AXIOS.GET(`tenant-api/get-messenger-phone-number-verification-code-owner?mobileNumber=%2B${mobileNumber}`);
}

const CheckUserExist = (mobileNumber)=>{
    return AXIOS.GET(`tenant-api/search-tenancy?userMobile=${mobileNumber}`);
}

const CheckUserExistByEmail = (email) => {
    return AXIOS.GET(`tenant-api/search-tenancy?userEmail=${email}`);
}

const CheckOwnerExist = (mobileNumber) => {
    return AXIOS.GET(`tenant-api/search-owners?userMobile=${mobileNumber}`);
}

const getChatbotSettings = (orgid)=>{
    return AXIOS.GET(`tenant-api/chatbot-settings?orgId=${orgid}`);
}

const getQandAForTenant = (organizationId)=>{
    return AXIOS.GET(`tenant-api/q-and-a-by-organization?organizationId=${organizationId}`);
}

const getPendingOwnerApprovalList = (userId) => {
    return AXIOS.GET(`tenant-api/chatbot/jobs?userId=${userId}`);
}

const ownerApproveRejectJob = (userId, jobId, note, isApprove) => {
    return AXIOS.GET(`tenant-api/chat-bot/job-action-owner?userId=${userId}&jobId=${jobId}&note=${note}&isApproved=${isApprove}`);
}

const ownerAcceptJobQuote = (bidId, userId, jobId, note) => {
    return AXIOS.GET(`tenant-api/bid/approve-bid?bidId=${bidId}&userId=${userId}&jobId=${jobId}&note=${note}`);
}

const ownerDeclineAllJobQuotes = (userId, jobId, note) => {
    return AXIOS.GET(`tenant-api/bid/decline-all-quotes-by-owner?userId=${userId}&jobId=${jobId}&note=${note}`);
}

const getAssignedPm = (tenantId)=>{
    return AXIOS.GET(`tenant-api/pam/assigned-pm?tenantId=${tenantId}`);
}

const postNewQandA = (tenantId,question)=>{
    return AXIOS.GET(`tenant-api/pam/tenant-post-new-qanda?tenantId=${tenantId}&question=${question}`);
}

const getRentalDetails = (tenantId)=>{
    return AXIOS.GET(`tenant-api/pam/tenant-get-rental-details?tenantId=${tenantId}`);
}
const getArrearsDetails = (tenantId)=>{
    return AXIOS.GET(`tenant-api/pam/tenant-get-arrears-details?tenantId=${tenantId}`);
}
const getScheduleJobs = (filters)=>{
    return AXIOS.GET(`tenant-api/tenant-jobs?filters=${filters}`);
}
const botService = {
    SendVerificationCode,
    SendVerificationCodeViaEmail,
    SendVerificationCodeForOwner,
    CheckUserExist,
    CheckUserExistByEmail,
    CheckOwnerExist,
    getChatbotSettings,
    getQandAForTenant,
    getPendingOwnerApprovalList,
    ownerApproveRejectJob,
    ownerAcceptJobQuote,
    ownerDeclineAllJobQuotes,
    getAssignedPm,
    postNewQandA,
    getRentalDetails,
    getArrearsDetails,
    getScheduleJobs
}

export default botService;
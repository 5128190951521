import React from 'react';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton, Form, Button } from 'react-bootstrap';
import './FrequentlyUsedServices.scss';


function FrequentlyUsedServices(props) {
    const parentId = props.selectedService;
    const t = props.translationInstance;

    return (
        <React.Fragment>
            <div className="freq-srvs">
              {t != undefined ?  <div className="wizard-header">
                    <h2>{t('hdr_choose_servicetype')}.<span className="required-icon">*</span></h2>
                    <p className="userTip">({t('lbl_chooseoption')})</p>
                </div>
                    : <div className="wizard-header">
                        <h2>Choose the service type you need.<span className="required-icon">*</span></h2>
                        <p className="userTip">(Choose an option)</p>
                    </div>
                }
                <ButtonToolbar className="radio-btn-group">
                    <ToggleButtonGroup
                        className={props.isFromBot?"btn-group-bot":""}
                        defaultValue={parentId}
                        type="radio"
                        name="options"
                        onChange={props.onSelect}>
                        {props.services.map((jbType, index) => {
                            return <ToggleButton key={index} value={jbType.value}><span className="ba-icn ba-icn-ok"></span> {jbType.label}</ToggleButton>;
                        })}
                        {t != undefined ? <ToggleButton key={-1} value={0} onClick={props.onClickMore}><span className="ba-icn ba-icn-ok"></span> {t('lbl_jobtype_other')}</ToggleButton>
                            : <ToggleButton key={-1} value={0} onClick={props.onClickMore}><span className="ba-icn ba-icn-ok"></span> Other</ToggleButton>
                        }
                    </ToggleButtonGroup>
                </ButtonToolbar>
            </div>
        </React.Fragment>
    )
}

export default FrequentlyUsedServices;
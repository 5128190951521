import React, { useState } from 'react';
import Drawer from "../../../Common/Drawer/Drawer"

class Disclaimer extends React.Component {
    state = {
        isFromBot:this.props.isFromBot?true:false,
    }

    constructor(props) {
        super(props);
    }

    render() {
        let drawer = this.createDrawer();
        return <div>{drawer}</div>;
    }

    createDrawer = () => {
        let header = this.createHeader();
         let body = this.createBody();
         let footer = this.createFooter();
        return (
            <>
                <Drawer
                    size={"lg"}
                    open={this.props.showDrawer}
                    header={header}
                    body={body}
                    footer={footer}
                ></Drawer>
            </>
        );
    };

    createHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Disclaimer
                </h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        type="button"
                        onClick={this.closeDrawer}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createBody = () => {
        if(this.props.showDrawer) {
            return (
                <>
                <div className={this.state.isFromBot?"panel-body-bot":"panel-body"} dangerouslySetInnerHTML={{ __html: this.props.disclaimer }}>               
                </div>
                </>
            )
        } else {
            return;
        }
    };

    createFooter = () => {
        return (
            <>
                <div className="card-actions">
                    <button onClick={this.closeDrawer}>Cancel</button>
                    <button onClick={this.onAgree} type="button">I agree</button>
                </div>
            </>
        )
    }

    onAgree = () => {
        this.props.onAgreedForDisclaimer(this.props.jobPostWorkflowStatus);
        this.closeDrawer();
    }

    closeDrawer = () => {
        this.props.onCloseDisclaimer();
    }
    
}

document.body.classList.add('disclaimer');
export default Disclaimer;
import ReactGA from 'react-ga';


/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const sendEventStatsToGoogle = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });


}

/**
 * Page view - custom tracking event.
 * @param {string} path 
 * @param {object} trackername 
 * @param {string} title 
 */
export const sendPageStatsViaStringToGoogle = (path, trackername, title) => {
    // ReactGA.pageview({
    //     path: "path",
    //     title: title
    // })
    ReactGA.ga('set', 'page', path)
    ReactGA.ga('send', 'pageview');

}


const googleAnalyticsService = {
    sendEventStatsToGoogle,
    sendPageStatsViaStringToGoogle
}

export default googleAnalyticsService;

//#region [IMPORT REACT COMPONENTS]
import React, { Component } from 'react';
import { connect } from "react-redux";
//#endregion

//#region [IMPORT FONT AWSOME COMPONENTS]
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTasks } from '@fortawesome/free-solid-svg-icons'
//#endregion

//#region [IMPORT NAV COMPONENTS]
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import "bootstrap/dist/css/bootstrap.min.css";
// Import the side navigation CSS
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import styled from 'styled-components';

import { UserType } from '../../Constants/Enums';

//#endregion

const NavHeader = styled.div`
    display: ${props => (props.expanded ? 'block' : 'none')};
    white-space: nowrap;
    background-color: #db3d44;
    color: #fff;
    > * {
        color: inherit;
        background-color: #fff;
    }
`;

const NavImage = styled.div`display: ${props => (props.expanded ? 'none' : 'block')};`;

// height: 20px + 10px + 10px = 40px
const NavTitle = styled.div`
    font-size: 2em;
    line-height: 20px;
    padding: 10px 0;
`;

class SideNave extends Component {
    state = {
        expanded: true
    }
    render() {

        const jobsForReviewMenu = this.getJobsForReviewMenu();
        const myJobMenu = this.getMenuForMyJobs();
        const propertyAccessControlsMenu = this.getMenuForPropertyAccessControls();     
        const userType = (localStorage.getItem('userType') == UserType.Owner) ? 'owner' : 'tenant';
        return (

            <React.Fragment>
                <SideNav
                    expanded={this.state.expanded}
                    onSelect={(selected) => sideNavOnSelect(selected, this.props)}
                    onToggle={(expanded) => {
                        this.setState({ expanded });
                        this.props.onSideNaveExpand(expanded);
                    }}
                    className="side-nav-tray">
                    {/* <Toggle className="toggle-icon"/> */}

                    <NavImage expanded={this.state.expanded}>
                        <a className="btn-small-logo" role="menuitem" onClick={() => { this.setState({ expanded: true }); this.props.onSideNaveExpand(true); }}>
                            <img src="https://test.bricksandagent.com/images/app-logo-sm.svg" alt="Bricks + Agent" />
                        </a>
                    </NavImage>
                    <NavHeader expanded={this.state.expanded}>
                        <NavTitle>
                            <a className="btn-minimize-menu" role="menuitem" onClick={() => { this.setState({ expanded: false }); this.props.onSideNaveExpand(false); }}>
                                <FontAwesomeIcon style={{ fontSize: '1em' }} icon={faBars} ></FontAwesomeIcon>
                            </a>
                            <img src="https://test.bricksandagent.com/images/app-logo.svg" alt="Bricks + Agent" className="logo" />
                        </NavTitle>
                    </NavHeader>

                    <SideNav.Nav>
                        {jobsForReviewMenu}
                        {myJobMenu}
                        {propertyAccessControlsMenu}
                        {(localStorage.getItem('userType') == UserType.Owner) ?
                            <NavItem className="sidenav-item" eventKey="owner/all-jobs" active={this.props.location.pathname.indexOf('all-jobs') > -1}>
                                <NavIcon>
                                    <i className="ba-icn ba-icn-briefcase"></i>
                                </NavIcon>
                                <NavText title="All Jobs">
                                    All Jobs
                                </NavText>
                            </NavItem> : ''
                        }
                      
                        <NavItem className="sidenav-item" eventKey={userType + "/document-list"} active={this.props.location.pathname.indexOf('document-list') > -1}>
                            <NavIcon>
                                <i className="ba-icn ba-icn-checklist"></i>
                            </NavIcon>
                            <NavText title="Document List">
                                Document List
                            </NavText>
                        </NavItem>
                        {this.props.properties.length > 0 ?
                            <NavItem className="sidenav-item " eventKey={userType + "/property-inspection"} active={this.props.location.pathname.indexOf('property-inspection') > -1}>
                                <NavIcon>
                                    <i className="ba-icn ba-icn-inspections"></i>
                                </NavIcon>
                                <NavText title="Property Inspection">
                                    Property Inspection
                            </NavText>
                            </NavItem> : ''
                        }
                        <NavItem className="sidenav-item" eventKey={userType + "/settings"} active={this.props.location.pathname.indexOf('settings') > -1}>
                            <NavIcon>
                                <i className="ba-icn ba-icn-gears"></i>
                            </NavIcon>
                            <NavText title="Settings">
                                Settings
                            </NavText>
                        </NavItem>
                    </SideNav.Nav>
                </SideNav>
            </React.Fragment>

        );
    }

    getMenuForMyJobs = () => {
        if (localStorage.getItem('userType') == UserType.Owner) {
            return (
                <NavItem className="sidenav-item" eventKey="owner/my-jobs" active={this.props.location.pathname.indexOf('my-jobs') > -1}>
                    <NavIcon>
                        <i className="ba-icn ba-icn-my-jobs"></i>
                    </NavIcon>
                    <NavText title="My Jobs">
                        My Jobs
                    </NavText>
                </NavItem>
            );
        } else {
            return (
                <NavItem className="sidenav-item" eventKey="tenant/my-jobs" active={this.props.location.pathname.indexOf('my-jobs') > -1}>
                    <NavIcon>
                        <i className="ba-icn ba-icn-my-jobs"></i>
                    </NavIcon>
                    <NavText title="My Jobs">
                        My Jobs
                    </NavText>
                </NavItem>
            );
        }
    }

    getMenuForPropertyAccessControls = () => {
        if (localStorage.getItem('userType') == UserType.Tenant) {
            return (
                <NavItem className="sidenav-item" eventKey="tenant/property-access-controls" 
                    active={this.props.location.pathname.indexOf('property-access-controls') > -1}>
                    <NavIcon>
                        <i className="ba-icn ba-icn-my-jobs"></i>
                    </NavIcon>
                    <NavText title="Property Access Controls">
                        Property Access Controls
                    </NavText>
                </NavItem>
            );
        }
    }

    getJobsForReviewMenu = () => {
        if (localStorage.getItem('userType') == UserType.Owner) {
            return (
                <NavItem className="sidenav-item" subnavClassName="sidenav-item-nav" 
                    navitemClassName="sidenav-item-nav" eventKey="owner/jobs-for-review"
                    active={this.props.location.pathname.indexOf('jobs-for-review') > -1}>
                    <NavIcon className="">
                        <i className="ba-icn ba-icn-job-review sidenav-item-nav"></i>
                    </NavIcon>
                    <NavText className="sidenav-item-nav" title="Jobs for Review">
                        Jobs for review
                        </NavText>
                </NavItem>
            );
        } else {
            return (
                <NavItem className="sidenav-item" subnavClassName="sidenav-item-nav" 
                navitemClassName="sidenav-item-nav" eventKey="tenant/jobs-for-review"
                active={this.props.location.pathname.indexOf('jobs-for-review') > -1}>
                    <NavIcon className="">
                        <i className="ba-icn ba-icn-job-review sidenav-item-nav"></i>
                    </NavIcon>
                    <NavText className="sidenav-item-nav" title="Jobs for Review">
                        Jobs for review
                        </NavText>
                </NavItem>
            );
        }
    }
}

function sideNavOnSelect(selected, props) {
    const to = '/' + selected;
    if (props.location.pathname !== to) {
        props.history.push(to);
    }
}

const mapStateToProps = (state, ownProps) => {
    try {

        return {
            properties: state.Tenant.tenantProperties
        };
    } catch (error) {
        console.log(error);
    }
};
export default connect(mapStateToProps)(SideNave);
import { AXIOS } from '../Utilities/HttpRequest/Axios';


const GetFrequentlyUsedJobTypes = () => {
    return AXIOS.GET(`tenant-api/get-grequently-used-job-types?userId=${localStorage.getItem('userId')}`);
}

const GetSubJobTypes = (jobRef) => {
    return AXIOS.GET(`tenant-api/job-sub-types?parentRef=${jobRef}`);
}

const GetSuburb = (suburbName, postCode) => {
    return AXIOS.GET(`tenant-api/get-suburb?suburbName=${suburbName}&postCode=${postCode}&userId=${localStorage.getItem('userIdDecripted')}`);
}

const CancelJob = (jobId) => {
    return AXIOS.GET(`tenant-api/cancel-job?jobId=${jobId}&userId=${localStorage.getItem('userIdDecripted')}`);
}

const GetMarketplace = (suburbId, secondaryJobTypeId) => {
    return AXIOS.GET(`tenant-api/marketplace?userId=${localStorage.getItem('userIdDecripted')}&suburbId=${suburbId}&secondaryJobType=${secondaryJobTypeId}`);
}

const GetJobTradeeCount = (jobTypeId, suburbId) => {
    return AXIOS.GET(`tenant-api/getJobTradeeCount?jobTypeId=${jobTypeId}&suburbId=${suburbId}`);
}

const GetJobDetailsForExternalUsers = (jobId,userId) => {
    return AXIOS.GET(`tenant-api/get-job-details-for-external-users?jobId=${jobId}&userId=${userId}`);
}

const GetJobRelatedTimelineForExternalUsers = (jobId) => {
    return AXIOS.GET(`tenant-api/get-timeline-for-external-users?jobId=${jobId}&filterType=1`);
}
const GetOwnerApprovalStatus = (jobId,userId) => {
    return AXIOS.GET(`tenant-api/owner-approval-status?jobId=${jobId}&userId=${userId}`);
}
const OwnerAttendingToTheJob = (jobId, userId, callbackUrl) => {
    return AXIOS.GET(`tenant-api/OwnerAttendingToTheJob?jobId=${jobId}&ownerId=${userId}&callbackUrl=${callbackUrl}`);
}
const GetTroubleShootingGuide = (propertyId,jobDescription) => {
    return AXIOS.GET(`tenant-api/troubleshooting-guide?propertyId=${propertyId}&jobDescription=${jobDescription}`);
}
export const GetPredictiveJobAverageCost = (jobId) => {
    return AXIOS.GET(`tenant-api/job/predictiveAverageCost?jobId=${jobId}&userId=${localStorage.getItem('userId')}`);
}
const saveTroubleShootingResult = (troubleShootingResultData) => {
    return AXIOS.POST(`tenant-api/resolved-by-tenant`, troubleShootingResultData);
}
const GetAIPoweredTroubleShootingGuide = (propertyId,jobDescription) => {
    return AXIOS.GET(`tenant-api/openAI-troubleshooting-guide?propertyId=${propertyId}&jobDescription=${jobDescription}`);
}
const GetJobTypeTags = (jobTypeId) => {
    return AXIOS.GET(`tenant-api/issue-tags-forjob-type?jobTypeId=${jobTypeId}`);
}
const GetPropertyComponent = (propertyId) => {
    return AXIOS.GET(`tenant-api/pam/property-component?propertyId=${propertyId}`);
}
const CreatePropertyComponent = (propertyId) => {
    return AXIOS.GET(`tenant-api/pam/create-property-component?propertyId=${propertyId}`);
}
const GetGoogleImages = (issue,count,url) => {
    if(url && url!==""){
        return AXIOS.GET(`tenant-api/get-related-images-by-url?issue=${issue}&url=${url}&count=${count}`);
    }else{
        return AXIOS.GET(`tenant-api/get-image-suggetions?issue=${issue}&count=${count}`);
    }
    
}
const GetAllJobTypes = (countryId, organizationId) => {
     return AXIOS.GET(`tenant-api/jobtypesByOrganization?countryId=${countryId}&organizationId=${organizationId}`);
}
const GetAllChildJobTypes = (parentJobTypeId, organizationId) => {
    return AXIOS.GET(`tenant-api/childJobTypesByOrganization?parentJobTypeId=${parentJobTypeId}&organizationId=${organizationId}`);
}
const GetAllTrades = (isIncludePendingInvitationTrades, isTenantManagedJob,jobId,jobTypeId,propertyId,removeSuburbFilter,suburbId,tradeSearchtext,pageSize,pageIndex) => {
    return AXIOS.GET(`tenant-api/jobTradees?userId=${localStorage.getItem('userId')}
    &isIncludePendingInvitationTrades=${isIncludePendingInvitationTrades}&isTenantManagedJob=${isTenantManagedJob}
    &jobId=${jobId}&jobTypeId=${jobTypeId}&propertyId=${propertyId}&removeSuburbFilter=${removeSuburbFilter}&suburbId=${suburbId}&tradeSearchtext=${tradeSearchtext}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
}
const GetPropertyDetailsWithHoldAmount = () => {
    return AXIOS.GET(`tenant-api/properties?propertyUserId=${localStorage.getItem('userId')}`);
}
const GetVariationHistory = (jobId) => {
    return AXIOS.GET(`tenant-api/job-variation-history?jobId=${jobId}`);
}
const GetRecurringJobList = (jobId) => {
    return AXIOS.GET(`tenant-api/get-recurring-joblist?parentJobId=${jobId}`);
}
const GetJobNotesByJobId = (jobId, userId) =>{
   return AXIOS.GET(`tenant-api/job-notes?jobId=${jobId}&userId=${userId}`);
}
const jobService = {
    OwnerAttendingToTheJob,
    GetJobRelatedTimelineForExternalUsers,
    GetJobTradeeCount,
    GetMarketplace,
    GetSuburb,
    GetFrequentlyUsedJobTypes,
    GetSubJobTypes,
    GetAllJobTypes,
    CancelJob,
    GetJobDetailsForExternalUsers,
    GetOwnerApprovalStatus,
    GetTroubleShootingGuide,
    saveTroubleShootingResult,
    GetPredictiveJobAverageCost,
    GetAIPoweredTroubleShootingGuide,
    GetJobTypeTags,
    GetPropertyComponent,
    CreatePropertyComponent,
    GetGoogleImages,
    GetAllChildJobTypes,
    GetAllTrades,
    GetPropertyDetailsWithHoldAmount,
    GetVariationHistory,
    GetRecurringJobList,
    GetJobNotesByJobId
}

export default jobService;
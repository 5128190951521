import { AXIOS } from  '../Utilities/HttpRequest/Axios';

export const getDescriptedUserId = () => {
    return AXIOS.GET(`tenant-api/decrypt?text=${localStorage.getItem('userId')}`);
}

export const saveAttachments = (model) => {
    return AXIOS.POST(`tenant-api/file-save`,model);
}

const utilityService = {
    getDescriptedUserId,
    saveAttachments
}

export default utilityService;
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import notificationService from '../../../../../Services/NotificationService';
import { Guid } from 'guid-typescript';

class Notification extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        isRead: this.props.isRead
    }

    render() {
       const isTenantManage = localStorage.getItem('isTenantManageJob');
       let result = this.props.data.body.replace(/the B\+A Team/ig,"").replace(/the B \+ A Team/ig,"");
        return (
            <div>
                <div className={(this.props.data.isActionClicked || this.state.isRead) ? 'notification-item' : 'notification-item notification-clicked'}>
                {/* <div className="notification-img">
                    <a href="#" onClick={(event) => this.openJobDetailsDrawer(event)}>
                        <img src={`/Images/Notification/${this.props.data.icon}`} alt={this.props.data.title} className="media-object" />
                    </a>
                </div> */}
                <div className="notification-detail w-100">
                    <h4>
                        {(this.props.data.isActionClicked  || this.state.isRead) ? '' :  <span className="blink notification-blink"></span>}
                        <a className="font-600" href="#" onClick={(event) => this.openJobDetailsDrawer(event)}>{this.props.data.title}</a>
                    </h4>
                    <a href="#" onClick={(event) => this.openJobDetailsDrawer(event)}>
                        <p>{isTenantManage ? result : this.props.data.body}</p>
                        <div>
                            <p className="notification-date mt-2">{this.props.data.strCreatedDate}</p>
                        </div>
                    </a>
                </div>
            </div>
                <hr></hr>
            </div>

        )
    }

    openJobDetailsDrawer(event) {
        event.preventDefault();
        this.setState({ isRead : true });
        this.props.isClickedOnNotification();
        this.updateNotificationAsRead();
        if (this.props.data.notificationCategory == 49
            || this.props.data.notificationCategory == 50
            || this.props.data.notificationCategory == 51
            || this.props.data.notificationCategory == 59
            || this.props.data.notificationCategory == 60
            || this.props.data.notificationCategory == 62
            || this.props.data.notificationCategory == 66
            || this.props.data.notificationCategory == 67
            || this.props.data.notificationCategory == 68
            || this.props.data.notificationCategory == 69
            || this.props.data.notificationCategory == 113
            || this.props.data.notificationCategory == 114
            || this.props.data.notificationCategory == 115
            || this.props.data.notificationCategory == 116
            || this.props.data.notificationCategory == 117) {
            this.props.openJobDetailsDrawer(Guid.EMPTY);
        } else if (this.props.data.notificationCategory == 127) {
            this.props.openPendingPropertyLocksDrawer(this.props.data.lockCodeRequestId);
        }   
        else if(this.props.data.notificationCategory == 343 ){
           if (this.props.data.primaryId == this.props.data.optionalValue)
           {
            this.props.openJobDetailsDrawer(this.props.data.primaryId)
           }
           else{
            this.props.openJobDetailsDrawer(Guid.EMPTY);
           }
        }
        else {         
            this.props.openJobDetailsDrawer(this.props.data.primaryId);
        }      
    }

    updateNotificationAsRead() {
        try {
            notificationService.UpdateNotificationAsRead(this.props.data.id)
                .then(response => {
                    console.log(response);
                })
        } catch (error) {
            console.log(error);
        }
    }
}

export default Notification;
import React from 'react';
import { BrowserRouter as Router, Route, Link, } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class MenuBar extends React.Component {

    render() {
        const { pathname } = this.props.location;
        const { path } = this.props.match;
        const { customStyle } = this.props;
        const { t } = this.props;
        let bidCount = this.props.jobDetails ? this.props.jobDetails.bidCount : 0 ;
        var userId = localStorage.getItem('userId');
        return (
            <ul className="nav" >
                <li className={`nav-item ${/*pathname.indexOf('overview') > -1 ? */'' /*: ''*/}`}>
                    <Link to={userId != null ? `${path}/overview?code=${window.jobId}&lng=${window.lng}&userId=${userId}` : `${path}/overview?code=${window.jobId}&lng=${window.lng}`} className={`nav-link ${pathname.indexOf('overview') > -1 ? 'active' : ''}`} style={{ color: (customStyle.brand) ? customStyle.brand : "", backgroundColor: (customStyle.brandBackground) ? customStyle.brandBackground : "" }}> {t('menu_overview')} </Link>
                </li>
                {bidCount != 0  && <li className={`nav-item ${localStorage.getItem("exernalUserId") != "" ? '' : 'd-none'}`}>
                    <Link to={`${path}/job-quotes?code=${window.jobId}`} className={`nav-link ${pathname.indexOf('job-quotes') > -1 ? 'active' : ''}`} style={{ color: (customStyle.brand) ? customStyle.brand : "", backgroundColor: (customStyle.brandBackground) ? customStyle.brandBackground : "" }}>Quotes</Link>
                </li>}
                <li className={`nav-item ${pathname.indexOf('edit') > -1 ? '' : 'd-none'}`}>
                    <Link to={`${path}/edit-job?code=${window.jobId}`} className={`nav-link ${pathname.indexOf('edit') > -1 ? 'active' : ''}`} style={{ color: (customStyle.brand) ? customStyle.brand : "", backgroundColor: (customStyle.brandBackground) ? customStyle.brandBackground : "" }}>{t('menu_editjob')}</Link>
                </li>
                <li className={`nav-item ${pathname.indexOf('attachments') > -1 ? '' : 'd-none'}`}>
                    <Link to={`${path}/add-attachments?code=${window.jobId}`} className={`nav-link ${pathname.indexOf('attachments') > -1 ? 'active' : ''}`} style={{ color: (customStyle.brand) ? customStyle.brand : "", backgroundColor: (customStyle.brandBackground) ? customStyle.brandBackground : "" }}>Add Attachments</Link>
                </li>
                <li className={`nav-item ${pathname.indexOf('ownerApprove') > -1 ? '' : 'd-none'}`}>
                    <Link to={`${path}/owner-approve?code=${window.jobId}`} className={`nav-link ${pathname.indexOf('ownerApprove') > -1 ? 'active' : ''}`} style={{ color: (customStyle.brand) ? customStyle.brand : "", backgroundColor: (customStyle.brandBackground) ? customStyle.brandBackground : "" }}>Approve</Link>
                </li>
                <li className={`nav-item ${pathname.indexOf('ownerReject') > -1 ? '' : 'd-none'}`}>
                    <Link to={`${path}/owner-reject?code=${window.jobId}`} className={`nav-link ${pathname.indexOf('ownerReject') > -1 ? 'active' : ''}`} style={{ color: (customStyle.brand) ? customStyle.brand : "", backgroundColor: (customStyle.brandBackground) ? customStyle.brandBackground : "" }}>Reject</Link>
                </li>
            </ul>
        )
    }
}

export default withTranslation()(MenuBar);
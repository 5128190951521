import React from 'react';
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ImageViewer from '../../../Common/ImageViewer/ImageViewer';
import { AXIOS } from "../../../../Utilities/HttpRequest/Axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import GoogleMapLocationsAutoComplete from '../../GoogleMapLocationsAutoComplete/GoogleMapLocationsAutoComplete';
import settingsService from '../../../../Services/settingsService';
import jobService from '../../../../Services/JobService';
import { Guid } from 'guid-typescript';
import DatePicker from "react-datepicker";
import { Search, Label } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import * as _ from 'lodash';
import 'semantic-ui-css/semantic.min.css'

import './OwnerJobPostWizard.scss';
import propertyService from '../../../../Services/PropertyService';
import FrequentlyUsedServices from '../../../Drawers/JobPostDrawer/FrequentlyUsedServices/FrequentlyUsedServices';
import JobLocksDrawer from '../../../Drawers/JobLocksDrawer/JobLocksDrawer';
import AttachmentsDrawer from '../../../Drawers/AttachmentsDrawer/AttachmentsDrawer';
import Disclaimer from '../../../Drawers/JobPostDrawer/Disclaimer/Disclaimer';
import { AppConfiguration } from 'read-appsettings-json';
import { trackPromise } from 'react-promise-tracker';
import InviteTradee from '../../../Drawers/JobPostDrawer/InviteTradee/InviteTradee';
import { Link } from 'react-router-dom';
import JobIssueTag from '../../JobIssueTag/JobIssueTag';
import GoogleJobImage from '../../GoogleJobImage/GoogleJobImage';
import PropertyComponent from '../../PropertyComponent/PropertyComponent';
import { withTranslation } from 'react-i18next';
import translateService from '../../../../Services/TranslateService';

class OwnerJobPostWizard extends React.Component {

    constructor(props) {
        super(props);
        this.title = React.createRef();
        this.jobDescription = React.createRef();
        this.budgetRef = React.createRef();
        this.issueAreaInProperty = React.createRef();
    }
    notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        if (this.props.isFromBot) {
            this.props.showChatbotError(msg);
        }
    }

    state = {
        userType: localStorage.getItem("userType"),
        userId: localStorage.getItem("userId"),
        //stepFlow: [[0, "selectProperty"], [1, "getMaintenaceIssue"], [2, "getMaintenaceTroubleShootGuide"], [3, "selectServiceType"], [4, "selectUgency"], [5, "selectKeyCollectionType"], [6, "selectBudget"], [7, "getAttachments"], [8, "getTrades"], [9, "selectSubmitOption"], [10, "setResponse"], [11, "setTroubleShooteResponse"]],
        stepFlow: [[0, "selectProperty"], [1, "getMaintenaceIssue"], [2, "getMaintenaceTroubleShootGuide"], [3, "selectServiceType"], [4, "selectUgency"], [5, "getAttachments"], [6, "selectJobRelatedGoogleImage"], [7, "getJobTypeTags"], [8, "selectKeyCollectionType"], [9, "selectBudget"], [10, "getTrades"], [11, "selectSubmitOption"], [12, "setResponse"], [13, "setTroubleShooteResponse"]], /*[8, "getPropertyComponent"]*/
        isJobEdit: (this.props.isJobEdit) ? true : false,
        jobEditId: (this.props.jobEditId) ? this.props.jobEditId : "",
        currentStep: 0,
        isConsumerJobPost: false,
        propertySearchData: [],
        formData: {
            address: '',
            location: '',
            propertyId: '',
            propertyName: '',
            suburbId: '',
            propertyType: '',
            jobBudget: 0,
            jobDescription: '',
            jobDescriptionEng: '',
            issueAreaInProperty: '',
            issueAreaInPropertyEng: '',
        },
        availableServices: [],
        availableServicesDetails: [],
        selectedService: { childJobTypes: [] },
        selectedServiceType: {
            parentJobType: { id: '' }
        },
        selectedSubServiceType: {},
        jobDetails: { attachments: [] },
        attachments: [],
        jobId: '',
        dueDate: '',
        formattedDueDate: '',
        jobUrgency: '',
        propertyList: [],
        value: '',
        isLoading: false,
        filteredResults: [],
        propertyId: '',
        isPropertiesLoading: false,
        parentJobTypes: [],
        primaryJobTypes: [],
        secondaryJobTypes: [],
        frequentlyUsedServices: [],
        isShowMoreServices: false,
        locationDetails: {
            latitude: '',
            longitude: '',
            placeId: '',
            suburbId: '',
            location: ''
        },
        isMandatoryToUploadFiles: false,
        isJobForMe: false,
        jobBudget: "",
        jobForMe: {},
        selectedTradees: [],
        isSelectedOnDemand: false,
        selectedQuoteLimit: 0,
        inviteToProviders: false,
        isJobPosting: false,
        isRestrictKeyCollectionForResidentialProperties: false,
        showDisclaimerDrawer: false,
        jobPostWorkflowStatus: 1,
        propertyLocks: [],
        isJobForMeText: '',
        isSubServiceTypeSelected: false,
        isShowAttchmentDrawer: false,
        parentId: '',
        childJobTypeId: '',
        subChildJobTypeId: '',
        jobNumber: '',
        isPropertyOwner: (localStorage.userType == 5) ? true : false,
        isShowInviteTradeDrawer: false,
        budgetAmount: 0,
        isPropertySelected: false,
        title: '',
        isLoadingTroubleshootingGuide: false,
        troubleShootingGuideDetails: {
            troubleshootGuideId: '',
            troubleshootGuide: '',
            primaryJobTypeId: '',
            secondaryJobTypeId: ''
        },
        primaryButtonStyle: (this.props.customStyle && this.props.customStyle.primaryButtonStyle) ? this.props.customStyle.primaryButtonStyle : {
            color: ''
        },
        secondaryButtonStyle: (this.props.customStyle && this.props.customStyle.secondaryButtonStyle) ? this.props.customStyle.secondaryButtonStyle : {
            color: ''
        },
        optionsStyle: (this.props.customStyle && this.props.customStyle.optionsStyle) ? this.props.customStyle.optionsStyle : {
            color: ''
        },
        isFromBot: (this.props.isFromBot) ? true : false,
        isMaintatanceIssueProvided: false,
        isJobPostOrTroubleshootGuideSelected: false,
        isDraftJob: false,
        tradeeCount: 0,
        jobTypeTags: [],
        isLoadingJobTypeTags: false,
        jobAssignTags: [],
        selectedComponent: '',
        selectedGoogleImage: [],
        selectedFeatureComponent: [],
        previousStep: 0,
        chatbotSetting: this.props.chatbotSetting ? this.props.chatbotSetting : {
            hideQandABot: false,
            hideCheckRentalStatus: false,
            hideKeyCollectionOptions: false,
            hideTroubleshootGuide: false,
            enableDraftJobFeature: false
        },
        maintenanceTroubleshootGuideId: null,
        propertyType: "Residential",
        actionValue: 0
    }

    componentDidMount() {
        var normalFlow = [[0, "selectProperty"], [1, "getMaintenaceIssue"], [2, "getMaintenaceTroubleShootGuide"], [3, "selectServiceType"], [4, "selectUgency"], [5, "selectKeyCollectionType"], [6, "selectBudget"], [7, "getAttachments"], [8, "getTrades"], [9, "selectSubmitOption"], [10, "setResponse"], [11, "setTroubleShooteResponse"]];
        setTimeout(() => {
            this.GetTenantPropertiesByUserId();
        }, 1000);
        this.getAvailableServices();
        this.GetFrequentlyUsedJobTypes();
        this.setState({
            jobId: Guid.create().toString(),
            jobDetails: {
                id: this.state.jobId
            }
        });
        this.getAllAvailableJobTypes();
        if (localStorage.userType == 5) {
            this.setState({
                isJobForMe: true
            })
        }
    }

    componentDidUpdate() {
        if (this.props.isCustomThemeEnable) {
            this.createToggleBtnDynamicStyle();
            this.createOkBtnDynamicStyle();
            this.createActiveBtnDynamicStyle();
            this.attchmentBarStyle();
        }
    }

    applyTranslation(texts, sourceLng, targetLng) {
        const requestModel = {
            text: texts,
            targetCode: (targetLng == "en-US" || targetLng =="en-GB")  ? "en" : targetLng,
            sourceCode: sourceLng
        };

        return translateService.translate(requestModel);
    }

    createToggleBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        const border = this.props.customStyle.optionsStyle.border;
        let labelInsideToggleGroup = document.querySelectorAll('label.btn');

        for (var i = 0; i < labelInsideToggleGroup.length; i++) {
            labelInsideToggleGroup[i].style.color = color;
            labelInsideToggleGroup[i].style.border = border;
            labelInsideToggleGroup[i].style.background = '';
        }
    }
    createOkBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        let okIcons = document.querySelectorAll('span.ba-icn-ok');

        for (var i = 0; i < okIcons.length; i++) {
            okIcons[i].style.background = color;
        }
    }
    createActiveBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.activeBackground;
        let active = document.querySelectorAll('label.btn.active');
        for (var i = 0; i < active.length; i++) {
            active[i].style.background = color;
        }
    }
    attchmentBarStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        const border = this.props.customStyle.optionsStyle.border;
        let label = document.querySelector('.rich-control');
        if (label) {
            label.querySelector('label').style.color = color;
        }
        let boader = document.querySelector('div.form-control.rich-control');
        if (boader) {
            boader.style.border = border;
        }
    }

    //get data

    getJobDetailsByJobId = jobId => {
        const userId = localStorage.getItem("userId");
        this.setState({ isJobDetailsLoading: true })
        if (jobId) {
            try {
                AXIOS.GET(`tenant-api/job?jobId=${jobId}&userId=${userId}`).then(
                    data => {
                        //need to set jobdetails to  state
                        this.setState({});
                    }
                )
            } catch (error) {
                console.log(error);
            }
        }
    };


    getOrganizationSettings(organizationId) {
        settingsService.getOrganizationSettingsByOrgId(organizationId)
            .then((res) => {
                this.setState({
                    isMandatoryToUploadFiles: res.organizationSettingsModel.allowTenantsToImageAttachmentFeatureForTheJobposting,
                    isRestrictKeyCollectionForResidentialProperties: res.organizationSettingsModel.isRestrictKeyCollectionForResidentialProperties,
                    allowTenantsToSeeMatchingTrades: res.organizationSettingsModel.allowTenantsToSeeMatchingTrades,
                    isEnableSuggestingImage: res.organizationSettingsModel.isEnableSuggestingImage,
                    isEnableIssueTag: res.organizationSettingsModel.isEnableIssueTag,
                })

            })

    }

    GetTenantPropertiesByUserId = () => {
        let none = [{ propertyId: 0, propertyName: '--None--', suburbId: '', location: '', address: '', propertyType: 'Residential' }];

        this.setState({ isPropertiesLoading: true });
        let properties;
        let propertyNames = [];
        const { i18n } = this.props;

        propertyService.GetTenantPropertiesByUserId()
            .then((res) => {
                properties = res.properties;
                if (res.properties.length > 0) {

                    properties.forEach(x => {
                        propertyNames.push(x.address ? x.address : " ");
                    });
                    if (this.state.isFromBot) {
                        this.applyTranslation(([propertyNames]), "en", i18n.language).then((trans) => {
                            if (trans.length > 0) {
                                trans.forEach((x, i) => {
                                    properties[i].address = trans[i];
                                });
                            }

                            this.setState({
                                propertyList: properties,
                                propertyId: properties[0].propertyId
                            });
                            this.getPropertyDetailsByPropertyId(properties[0].propertyId);
                            this.setState({ isPropertiesLoading: false });
                        });
                    }
                    else {
                        this.setState({
                            propertyList: properties,
                            propertyId: properties[0].propertyId,
                            isCurrentTenant:properties[0].isCurrentTenant
                        });
                        if (localStorage.userType != 5) {
                            this.getPropertyDetailsByPropertyId(properties[0].propertyId);
                        }
                        this.setState({ isPropertiesLoading: false });
                    }
                } else {
                    this.setState({
                        propertyList: [],
                        propertyId: 0
                    });
                    this.onChangeJobType(1);
                }
            });
    }

    onAttachTradeesToTheJob = ({ selectedTradees, selectedQuoteLimit, isSelectedOnDemand, inviteToProviders, tradeeCount }) => {
        this.setState({
            selectedTradees: selectedTradees,
            selectedQuoteLimit: selectedQuoteLimit,
            isSelectedOnDemand: isSelectedOnDemand,
            inviteToProviders: inviteToProviders,
            tradeeCount: tradeeCount
        })
    }

    getAvailableServices = () => {
        try {
            this.setState({ isLoading: true })
            AXIOS.GET(`tenant-api/available-job-types?userId=${localStorage.getItem('userId')}`)
                .then(dataJson => {
                    this.setState({ isLoading: false })
                    this.setState({ availableServicesDetails: dataJson });
                    this.setState({ availableServices: dataJson });
                    let formData = this.state.formData;
                    formData.childJobTypeId = '';
                    formData.childJobType = '';
                    this.setState({ formData: formData });
                })
        } catch (error) {
            console.log(error);
        }
    }

    GetFrequentlyUsedJobTypes = () => {
        try {
            let jobTypesLabels = [];
            let data;
            const { i18n } = this.props;
            if (this.state.isFromBot) {
                jobService.GetFrequentlyUsedJobTypes()
                    .then(result => {
                        data = result;
                        jobTypesLabels = data.map(x => x.label);
                        return this.applyTranslation((jobTypesLabels), "en", i18n.language);

                    }).then((trans) => {
                        if (trans.length > 0) {
                            trans.forEach((x, i) => {
                                data[i].label = trans[i];
                            });
                        }
                        this.setState({ frequentlyUsedServices: data });
                    })
            }
            else {
                jobService.GetFrequentlyUsedJobTypes()
                    .then(data => {
                        this.setState({ frequentlyUsedServices: data });
                    })
            }
        } catch (error) {
            console.log(error);
        }
    }

    getAllAvailableJobTypes() {
        let jobTypes = this.props.jobTypes;
        let parents = _.map(jobTypes, m => m.parentJobType);
        const parentJobTypes = _.filter(parents, f => f.parentRef === null);
        this.setState({ parentJobTypes: parentJobTypes });
    }

    getPropertyDetailsByPropertyId = (propertyId) => {
        const selectedProperty = this.state.propertyList.filter(a => a.propertyId == propertyId);
        if (selectedProperty && selectedProperty.length > 0 && selectedProperty[0].propertyId != null) {
            this.setState({
                propertyId: propertyId,
                //locationDetails: {},
                formData: {
                    address: selectedProperty[0].address,
                    location: selectedProperty[0].location,
                    propertyId: selectedProperty[0].propertyId,
                    propertyName: selectedProperty[0].propertyName,
                    suburbId: selectedProperty[0].suburbId,
                    propertyType: selectedProperty[0].propertyType
                },
                locationDetails: selectedProperty[0],
                jobForMe: {
                    propertyType: selectedProperty[0].propertyType,
                    address: selectedProperty[0].address,
                    latitude: selectedProperty[0].latitude,
                    location: selectedProperty[0].location,
                    longitude: selectedProperty[0].longitude,
                    placeId: selectedProperty[0].placeId,
                    suburbId: selectedProperty[0].suburbId,
                },
            });
            this.getOrganizationSettings(selectedProperty[0].orgnizationId);
        } else {
            this.setState({
                propertyId: null,
                locationDetails: {},
                formData: {
                    address: '',
                    location: '',
                    propertyId: null,
                    propertyName: '',
                    suburbId: null,
                    propertyType: ''
                }
            });
        }
        setTimeout(() => {
            if (this.state.isJobForMe == 1) {
                // document.getElementById('check1').checked = true;
                this.setState({ keyCollectType: 1 });
            }
        }, 500);
    }

    onChangeJobType = (value) => {
        if (value === 1 && localStorage.userType == 4) {
            this.setState({ isJobForMeText: <p>You (tenant) will be paying for this job and are responsible <br></br> for all costs involved.<br></br>This job is not being sent to your property manager.</p> });
        } else {
            this.setState({ isJobForMeText: '' });
        }

        this.setState({
            isJobForMe: (value === 1) ? true : false,
            jobForMe: {
                propertyType: '',
                address: '',
                latitude: '',
                location: '',
                longitude: '',
                placeId: '',
                suburbId: '',
                budget: ''
            },
            formData: {
                propertyId: ""
            }
        });
    }

    onChangePropertyTypeForMe = (event) => {
        let formdata = {};
        if (!event) {
            formdata.propertyType = "Residential";
            this.setState({ formdata: formdata, propertyType: "Residential" });
        }
        let jobforMe = this.state.jobForMe;
        jobforMe.propertyType = event;
        this.setState({ jobForMe: jobforMe, propertyType: event });

        formdata = this.state.formData;
        formdata.propertyType = event;
        this.setState({ formdata: formdata, propertyType: event });
    }

    onKeyCollectionChange = (event) => {
        this.setState({
            keyCollectType: event
        });
    }

    handlePropertyChange = (props) => {
        this.setState({ property: props });
        this.getPropertyDetailsByPropertyId(props);
    }
    setLocationDetails = (event) => {
        this.setState({
            locationDetails: event,
            jobForMe: {
                propertyType: '',
                address: event.address,
                latitude: event.latitude,
                location: event.location,
                longitude: event.longitude,
                placeId: event.placeId,
                suburbId: event.suburbId,
            },
            formData: {
                address: event.address
            }
        });
    }



    handleOnChangeJobDescription = (value) => {

        let jobforMe = this.state.jobForMe;
        jobforMe.jobDescription = this.jobDescription.current.value;
        this.setState({ jobForMe: jobforMe });


        let formdata = this.state.formData;
        const typedDescription = this.jobDescription.current.value;
        formdata.jobDescription = typedDescription;
        formdata.jobDescriptionEng = typedDescription;
        this.setState({
            formdata: formdata,
            isMaintatanceIssueProvided: true
        });

    }

    handleOnChangeJobIssueArea = (value) => {
        const { i18n } = this.props;
        let formdata = this.state.formData;
        const typedArea = this.issueAreaInProperty.current.value;
        formdata.issueAreaInProperty = typedArea;
        formdata.issueAreaInPropertyEng = typedArea;
        this.setState({ formdata: formdata });
    }

    //createTypeOfServicesButtons = (props) => {
    //    let isServiceTypeSelected = this.state.isServiceTypeSelected;
    //    const { t } = this.props;
    //    if (this.state.selectedService.childJobTypes) {
    //        return (
    //            <>
    //                <div className="form-group mt-5">
    //                    {(isServiceTypeSelected) ?
    //                        <div className="wizard-header">
    //                            <h2>{this.state.isFromBot ? t('pj_lbl_select_sub_type') : 'Please select the type of subservice you require.'}<span className="required-icon">*</span></h2>
    //                            <p>{this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'}</p>
    //                        </div>
    //                        : ''}

    //                    <ButtonToolbar className="radio-btn-group">
    //                        <ToggleButtonGroup
    //                            defaultValue={(this.state.childJobTypeId) ? this.state.childJobTypeId : ''}
    //                            type="radio"
    //                            name="options"
    //                            onChange={(event) => { this.getSubChildJobTypes(event); }}>
    //                            {this.state.selectedService.childJobTypes.map((jbType, index) => {
    //                                return <ToggleButton key={jbType.id} value={jbType.id}><span className="ba-icn ba-icn-ok"></span>  {jbType.label}</ToggleButton>;
    //                            })}
    //                        </ToggleButtonGroup>
    //                    </ButtonToolbar>
    //                </div>
    //            </>
    //        )
    //    } else {
    //        return
    //    }
    //}
    //createTypeOfServicesChildButtons = (props) => {
    //    if (this.state.secondaryJobTypes.length > 0) {
    //        return (
    //            <>
    //                <div className="form-group mt-5">
    //                    <label className="form-label mb-3">{this.state.selectedServiceType.parentJobType.description}<span className="required-icon">*</span></label>
    //                    <ButtonToolbar className="radio-btn-group">
    //                        <ToggleButtonGroup
    //                            defaultValue={(this.state.subChildJobTypeId) ? this.state.subChildJobTypeId : ''}
    //                            type="radio"
    //                            name="options"
    //                            onChange={this.onChangeChildTypeOfServices}>
    //                            {this.state.secondaryJobTypes.map((jbType, index) => {
    //                                return <ToggleButton key={jbType.id} value={jbType.id}><span className="ba-icn ba-icn-ok"></span>  {jbType.label}</ToggleButton>;
    //                            })}
    //                        </ToggleButtonGroup>
    //                    </ButtonToolbar>
    //                </div>
    //            </>
    //        )
    //    } else {
    //        return
    //    }

    //}

    //showHideMoreService = () => {
    //    this.setState({
    //        isShowMoreServices: !this.state.isShowMoreServices,
    //        selectedService: {},
    //        isServiceTypeSelected: true
    //    })
    //}

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.label })
        this.getChildJobTypes(result.id);
    }
    handleSearchChange = (e, { value }) => {
        this.setState({ filteredResults: [], value });
        if (value.length > 2) {
            this.setState({ isLoading: true })
            setTimeout(() => {
                document.querySelector('.jobpost-wizard-owner .auto-complete').classList.remove("hide-results");
                this.setState({
                    isLoading: false,
                    filteredResults: this.state.parentJobTypes.filter(suggestion => suggestion.label.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1)
                })
            }, 300)
        } else {
            document.querySelector('.jobpost-wizard-owner .auto-complete').classList.add("hide-results");
        }
    }

    resultRenderer = (event) => {
        return <Label key={event.label} content={event.label}></Label>
    }





    onSelectFrequentlyUsedJobTypes = (event) => {
        try {
            this.setState({ parentId: event });
            this.setState({ isServiceTypeSelected: true });
            this.getChildJobTypes(event);
        } catch (error) {
            console.log(error);
        }
    }

    showHideMoreService = () => {
        let formData2 = {
            ...this.state.formData, childJobType: '',
            childJobTypeId: '',
        }
        this.setState({
            isShowMoreServices: !this.state.isShowMoreServices,
            selectedService: {},
            secondaryJobTypes: [],
            subChildJobTypeId: '',
            formData: formData2,
            isServiceTypeSelected: true
        })
    }

    getChildJobTypes(parentId) {
        let jobTypes = this.props.jobTypes;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === parentId);

        if (this.state.selectedServiceType && this.state.selectedServiceType.parentJobType && _.find(selectedNode.childJobTypes, f => f.id === this.state.selectedServiceType.parentJobType.id)) {
            this.setState({
                primaryJobTypes: selectedNode.childJobTypes,
                selectedService: selectedNode
            });
            this.setState({ childJobTypeId: selectedNode });
        } else {
            let formData2 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: '',

            }
            this.setState({
                primaryJobTypes: selectedNode.childJobTypes,
                selectedService: selectedNode,
                secondaryJobTypes: [],
                selectedServiceType: {
                    parentJobType: { id: '' }
                },
                formData: formData2
            });
        }
    }

    getSubChildJobTypes(parentId) {

        this.setState({ childJobTypeId: parentId });
        this.setState({ isSubServiceTypeSelected: true });
        let jobTypes = this.props.jobTypes;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === parentId);

        if (selectedNode == undefined) {
            let selectedServiceType = {
                childJobTypes: [],
                parentJobType: _.find(this.state.primaryJobTypes, f => f.id === parentId)
            }
            let formData1 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: ''
            };
            this.setState({
                secondaryJobTypes: [],
                selectedServiceType: selectedServiceType,
                formData: formData1
            });

        } else {
            let formData2 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: ''
            };
            this.setState({
                secondaryJobTypes: selectedNode.childJobTypes,
                selectedServiceType: selectedNode,
                formData: formData2
            });
        }

    }

    createTypeOfServicesChildButtons = (props) => {
        if (this.state.secondaryJobTypes.length > 0) {
            return (
                <>
                    <div className="form-group mt-5 wzrd-post-a-job thrd-lvl-btns">
                        <h2 className="mb-3">{this.state.selectedServiceType.parentJobType.description}<span className="required-icon">*</span></h2>
                        <ButtonToolbar className={this.state.isFromBot?"radio-btn-group btn-group-bot":"radio-btn-group"}>
                            <ToggleButtonGroup
                                className={this.props.isFromBot?"btn-group-bot":""}
                                defaultValue={(this.state.subChildJobTypeId) ? this.state.subChildJobTypeId : ''}
                                type="radio"
                                name="options"
                                onChange={this.onChangeChildTypeOfServices}>
                                {this.state.secondaryJobTypes.map((jbType, index) => {
                                    return <ToggleButton value={jbType.id}><span className="ba-icn ba-icn-ok"></span>  {jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            return
        }

    }

    onChangeChildTypeOfServices = (input) => {
        this.setState({ subChildJobTypeId: input });
        const childJobType = this.state.selectedServiceType.childJobTypes.filter(a => a.id === input)[0];
        let formData = this.state.formData;
        formData.childJobTypeId = childJobType.id;
        formData.childJobType = childJobType.title;
        this.setState({ formData: formData });
    }

    createTypeOfServicesButtons = (props) => {
        const { t } = this.props;
        let isServiceTypeSelected = this.state.isServiceTypeSelected;
        if (this.state.selectedService.childJobTypes) {
            return (
                <>
                    <div className="mt-5 wzrd-post-a-job">
                        {(isServiceTypeSelected) ?
                            <div className="wizard-header">
                                <h2>{this.state.isFromBot ? t('pj_lbl_select_sub_type') : 'Please select the type of subservice you require.'}<span className="required-icon">*</span></h2>
                                <p>{this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'}</p>
                            </div>

                            : ''}

                        <ButtonToolbar className="radio-btn-group sub-srvs-typ-tgl">
                            <ToggleButtonGroup
                                className={this.props.isFromBot?"btn-group-bot":""}
                                defaultValue={(this.state.childJobTypeId) ? this.state.childJobTypeId : ''}
                                type="radio"
                                name="options"
                                onChange={(event) => { this.getSubChildJobTypes(event); }}>
                                {this.state.selectedService.childJobTypes.map((jbType, index) => {
                                    return <ToggleButton key={jbType.id} value={jbType.id}><span className="ba-icn ba-icn-ok"></span> {jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            return
        }
    }

    onChangeTypeOfServices = (event) => {

        try {
            this.setState({ isLoading: true })
            AXIOS.GET(`tenant-api/job-sub-types?parentRef=${event}`)
                .then(dataJson => {
                    this.setState({ isLoading: false })
                    this.setState({ selectedServiceType: dataJson });
                    let formData = this.state.formData;
                    formData.childJobTypeId = '';
                    formData.childJobType = '';
                    this.setState({ formData: formData });
                });
        } catch (error) {
            console.log(error);
        }
    }

    handleDueDateChange = (date) => {
        this.setState({
            dueDate: date,
        });
    }

    handleJobUrgencyOnChange = (value) => {
        this.setState({ jobUrgency: value });
    }

    getDueDate = () => {
        const { t } = this.props;
        if (this.state.jobUrgency == -999) {
            return (
                <div className="form-group">
                    <label className="form-label">{this.state.isFromBot ? t('pj_lbl_select_a_scheduled_date') : 'Due date for the maintenance request'}</label>
                    <div>
                        <DatePicker dateFormat={(this.props.dateFormat && this.props.dateFormat != "") ? this.props.dateFormat : "dd/MM/yyyy"} minDate={new Date()} className="form-control" selected={this.state.dueDate} onChange={this.handleDueDateChange} />
                    </div>
                </div>
            )
        }
    }

    closeDisclaimer = () => {
        this.setState({
            showDisclaimerDrawer: false,
        });
    }

    onChangeJobBudget = (value) => {
        this.setState({ jobBudget: value });
    }

    onChangeAmount = (event) => {
        this.setState({ budgetAmount: event.target.value });
    }

    getTroubleShoutingGuide(index) {
        const { i18n } = this.props;
        this.setState({
            isLoadingTroubleshootingGuide: true
        });
        jobService.GetTroubleShootingGuide(this.state.formData.propertyId, this.state.formData.jobDescriptionEng).then(res => {
            if (res.responseCode == 12 && res.troubleshootGuide != null) {
                if (this.state.isFromBot) {
                    if (i18n.language != "en" && !i18n.language.toString().includes("en")) {
                        this.applyTranslation(res.troubleshootGuide, "en", i18n.language).then((trans) => {
                            res.troubleshootGuide = trans[0];
                            this.setState({
                                troubleShootingGuideDetails: res,
                                maintenanceTroubleshootGuideId: res.troubleshootGuideId,
                                isLoadingTroubleshootingGuide: false
                            });
                        });
                    } else {
                        this.setState({
                            troubleShootingGuideDetails: res,
                            maintenanceTroubleshootGuideId: res.troubleshootGuideId,
                            isLoadingTroubleshootingGuide: false
                        });
                    }
                }
                else {
                    this.setState({
                        troubleShootingGuideDetails: res,
                        maintenanceTroubleshootGuideId: res.troubleshootGuideId,
                        isLoadingTroubleshootingGuide: false
                    });
                }

            } else {
                this.setState({
                    isLoadingTroubleshootingGuide: false,
                    currentStep: (index + 2)
                });
            }
        })
    }


    render() {
        const currentStepIndex = this.state.currentStep;
        let step = this.showCurrentStep(currentStepIndex);
        return (
            <Form>
                <div className="jobpost-wizard-owner">
                    {step}
                </div>
                {(this.state.showDisclaimerDrawer == true) ?
                    <Disclaimer
                        showDrawer={this.state.showDisclaimerDrawer}
                        disclaimer={this.state.disclamimerText}
                        jobPostWorkflowStatus={this.state.jobPostWorkflowStatus}
                        onAgreedForDisclaimer={(event) => this.postJob(event)}
                        onCloseDisclaimer={() => this.closeDisclaimer()}
                        isFromBot={this.state.isFromBot}
                    ></Disclaimer> : ''
                }
            </Form>);
    }

    showCurrentStep = (index) => {
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "selectProperty":
                return this.selectPropertyStep(index);
            case "getMaintenaceIssue":
                return this.getMaintenaceIssue(index);
            case "getMaintenaceTroubleShootGuide":
                if (this.state.chatbotSetting.hideTroubleshootGuide && this.state.isFromBot) {
                    this.setState({
                        currentStep: index + 1
                    }, () => {
                        this.nextStep(index);
                    })
                }
                else {
                    return this.getMaintenaceTroubleShootGuide(index);
                }
            case "selectServiceType":
                return this.selectServiceType(index);
            case "selectUgency":
                return this.selectUgency(index);
            case "selectJobRelatedGoogleImage":
                return this.selectJobRelatedGoogleImage(index);
            case "getJobTypeTags":
                return this.getJobTypeTags(index)
            case "getPropertyComponent":
                return this.getPropertyComponent(index)
            case "selectKeyCollectionType":
                if (this.state.chatbotSetting.hideKeyCollectionOptions && this.state.isFromBot) {
                    this.setState({
                        keyCollectType: this.state.chatbotSetting.defaultKeyCollectionOption,
                        currentStep: index + 1
                    }, () => {
                      return this.nextStep(index);
                    })
                }
                else {
                    return this.selectKeyCollectionType(index);
                }
            case "selectBudget":
                return this.selectBudget(index);
            case "getAttachments":
                return this.getAttachments(index);
            case "getTrades":
                return this.getTrades(index);
            case "selectSubmitOption":
                return this.selectSubmitOption(index);
            case "setResponse":
                return this.setResponse(index);
            case "setTroubleShooteResponse":
                return this.setTroubleShooteResponse();
            default:
                return this.nextStep(index);
        }
    }

    selectPropertyStep = (index) => {
        const { propertyList, propertyId, isJobForMe, jobForMe, formData, isPropertyOwner } = this.state;
        const forMeText = this.state.isJobForMeText;
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                    {!this.state.isFromBot ?
                        <div className={(localStorage.userType == 4 || localStorage.userType == 5) ? "d-block" : 'd-none'}>
                            <div className="wizard-header">
                                <h2>Job posting for</h2>
                                <p className="usertip">(Choose an option)</p>
                            </div>
                            <ButtonToolbar className="radio-btn-group for-tgl-btn">
                                <ToggleButtonGroup className={this.props.isFromBot?"btn-group-bot":""} type="radio" name="options" ref="jobType" defaultValue={isJobForMe ? 1 : 0} value={isJobForMe ? 1 : 0}
                                    onChange={(value) => { this.onChangeJobType(value) }}>
                                    {this.state.isCurrentTenant && localStorage.userType == 4 ?
                                        <ToggleButton value={0}><span className="ba-icn ba-icn-ok"></span> Property Manager</ToggleButton> : ''}
                                    <ToggleButton value={1}><span className="ba-icn ba-icn-ok"></span> Job I will Pay For</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                            <div className="my-2">
                                <div className="note-danger">{forMeText}</div>
                            </div>
                        </div>
                        : ''}
                    {(!isJobForMe) ?
                        <div>
                            <div className="wizard-header">
                                <h2 htmlFor="property">{this.state.isFromBot ? t('pj_lbl_prop_selection_q'): 'Which property do you wish to log a maintenance request for?'}</h2>
                                <p className="userTip">({this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'})</p>
                            </div>

                            <div>
                                {(this.state.isPropertiesLoading) ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : ''}
                                <ButtonToolbar className="radio-btn-group">
                                    <ToggleButtonGroup className={this.props.isFromBot?"btn-group-bot":""} type="radio" name="options" ref="jobType" defaultValue={(formData && formData.propertyId != "") ? formData.propertyId : ""}
                                        onChange={(value) => { this.handlePropertyChange(value) }}>
                                        {propertyList.map((a, index) => {
                                            return <ToggleButton key={index} value={a.propertyId}><span className="ba-icn ba-icn-ok"></span> {a.address}</ToggleButton>
                                        })
                                        }
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </div>
                            
                        </div> : ''}
                    <div className="form-group" hidden={(!isJobForMe && !isPropertyOwner) ? "hidden" : ""}>
                        <label className="form-label">What is your Address<span className="required-icon">*</span></label>
                        {(propertyId == 0 || isJobForMe || isPropertyOwner) ?
                            <GoogleMapLocationsAutoComplete setLocationDetails={(event) => this.setLocationDetails(event)} formData={this.state.formData}></GoogleMapLocationsAutoComplete> :
                            <input type="text" className="form-control" id="formGroupAddress" placeholder="Address" readOnly value={this.state.formData.address} ></input>}
                    </div>

                    <div className="form-group" hidden={(!isJobForMe && !isPropertyOwner) ? "hidden" : ""}>
                        <label className="form-label">What is your Location<span className="required-icon">*</span></label>
                        {propertyId == 0 || isJobForMe || isPropertyOwner ? <input type="text" className="form-control" id="formGroupLocation" placeholder="Location" readOnly value={jobForMe.location}></input>
                            : <input type="text" className="form-control" id="formGroupLocation" placeholder="Location" readOnly={true} value={this.state.formData.location}></input>}

                    </div>
                    {(isJobForMe || isPropertyOwner) ?
                        <div>
                            <div className="wizard-header">
                                <h2>Property Type</h2>
                                <p className="userTip">(Choose an option)<span className="required-icon">*</span></p>
                            </div>
                            <ButtonToolbar className="radio-btn-group">
                                <ToggleButtonGroup className={this.props.isFromBot?"btn-group-bot":""} type="radio" name="options" ref="jobPropertyTypes" defaultValue="Residential"
                                    onChange={(value) => this.onChangePropertyTypeForMe(value)}>
                                    <ToggleButton value="Residential"><span className="ba-icn ba-icn-ok"></span> Residential</ToggleButton>
                                    <ToggleButton value="Commercial"><span className="ba-icn ba-icn-ok"></span> Commercial</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div> : ''}
                </div>
                <div className="wizard-actions">
                    <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{this.state.isFromBot ? t('btn_next') : 'NEXT'}</button>
                </div>

            </div>);
    }
    getMaintenaceIssue = (index) => {
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className={(this.state.isFromBot) ? 'job-post-type' : 'wizard-body job-post-type'}>
                    <div className="wizard-header">
                        <h2>{this.state.isFromBot ? t('pj_lbl_describe_job') : 'Please describe your maintenance issue'}</h2>
                        {(this.state.formData.address) ?
                            <p className="userTip">({this.state.isFromBot ? t('pj_lbl_job_property') : 'You are logging this maintenance issue for'} <span className="font-500">{this.state.formData.address}</span>)</p> : ''}
                    </div>

                    <div className="form-group">
                        <textarea className="form-control form-textarea" placeholder={this.state.isFromBot ? t('pj_lbl_job_property') : 'Please type your maintenance issue with more details'} aria-label="test"
                            value={this.state.formData.jobDescription} ref={this.jobDescription} onChange={(value) => this.handleOnChangeJobDescription(value)}>
                        </textarea>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder={this.state.isFromBot ? t('pj_lbl_job_property_area') : 'Where the issue is located in the property?'} aria-label="issue area in property"
                            value={this.state.formData.issueAreaInProperty} ref={this.issueAreaInProperty} onChange={(value) => this.handleOnChangeJobIssueArea(value)} >
                        </input>
                    </div>
                </div>
                <div className="wizard-actions">
                    <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                    <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{this.state.isFromBot ? t('btn_next') : 'NEXT'}</button>
                </div>
            </div>);
    }

    getMaintenaceTroubleShootGuide = (index) => {
        const { troubleShootingGuideDetails, isLoadingTroubleshootingGuide } = this.state;
        const { t } = this.props;

        return (
            <div className="wizard-inner">
                <div className={(this.state.isFromBot) ? 'job-post-type' : 'wizard-body job-post-type'}>
                    {!isLoadingTroubleshootingGuide && <div className="wizard-header">
                        <h2>{this.state.isFromBot ? t('pj_lbl_issue_desc') : 'Issue Description'}</h2>
                        {(this.state.formData.address) ?
                            <p className="userTip">{this.state.isFromBot ? t('pj_lbl_job_property') : 'You are logging this maintenance issue for'} <span className="font-500">{this.state.formData.address}</span></p> : ''}
                        <div className="form-group">
                            <textarea className="form-control form-textarea" placeholder="Please type your maintenance issue with more details" aria-label="test"
                                value={this.state.formData.jobDescription} ref={this.jobDescription} onChange={(value) => this.handleOnChangeJobDescription(value)} disabled>
                            </textarea>
                        </div>
                    </div>}
                    {isLoadingTroubleshootingGuide ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />
                        : (<div>
                            <h2>{this.state.isFromBot ? t('pj_lbl_lets_troubleshoot') : 'Let’s troubleshoot your problem'}</h2>
                            <div className="mt-5 innerHtmlContainer text-left trbl-st-cont" dangerouslySetInnerHTML={{ __html: `${troubleShootingGuideDetails.troubleshootGuide}` }}></div>
                        </div>)}
                </div>
                {!isLoadingTroubleshootingGuide && (<div>
                    <p class="mt-3">{this.state.isFromBot ? t('pj_lbl_issue_resoved_verification') : 'Were you able to resolve your issue?'}</p>
                    <div className="wizard-actions">
                        <button type="button" className="btn btn-secondary" onClick={() => this.continueJobPosting(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('lbl_attachment_no') : 'NO'}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.saveTroubleShootResult(true)} style={this.state.primaryButtonStyle}>{this.state.isFromBot ? t('lbl_attachment_yes') : 'YES'}</button>
                    </div>
                </div>)}
            </div>);
    }

    selectJobRelatedGoogleImage = (index) => {
        if (!this.state.isEnableSuggestingImage) return this.state.previousStep > index ? this.previousStep(index) : '';
        const data = {
            secondaryButtonStyle: this.state.secondaryButtonStyle,
            primaryButtonStyle: this.state.primaryButtonStyle,
            index: index,
            jobDescription: this.state.formData.jobDescriptionEng,
            previousStep: this.previousStep,
            nextStep: this.handleGoogleImageCallback,
            selectedGoogleImage: this.state.selectedGoogleImage,
            attachmentUrl: this.state.attachments && (this.state.attachments.length > 0) && this.state.attachments[0] ? this.state.attachments[0].fileUrl : "",
            t: this.state.isFromBot ? this.props.t : undefined
        }
        return (<GoogleJobImage data={data} ></GoogleJobImage>)
    }

    handleGoogleImageCallback = (value) => {
        var attachments = this.state.attachments;
        if (value.attachments) {

            if (attachments) {
                value.attachments.forEach((attch) => {
                    attachments.push(attch);
                });

            } else {
                attachments = [];
                value.attachments.forEach((attch) => {
                    attachments.push(attch);
                });

            }
        }
        this.setState({ selectedGoogleImage: value.selectedGoogleImage, attachments: attachments });
        this.nextStep(value.index);
    }

    handleJobTypeTagCallback = (value) => {
        this.setState({ jobAssignTags: value.data });
        this.nextStep(value.index);
    }

    handlePropertyComponentCallback = (value) => {
        this.setState({ selectedComponent: value.selectedComponent, selectedFeatureComponent: value.selectedFeatureComponent });
        this.nextStep(value.index);
    }

    getJobTypeTags = (index) => {
        var childJobTypeId = this.state.childJobTypeId;
        if (this.state.formData.childJobTypeId && this.state.formData.childJobTypeId.trim() != "") {
            childJobTypeId = this.state.formData.childJobTypeId;
        }
        else {
            childJobTypeId = this.state.selectedServiceType.parentJobType.id;
        }

        if (!this.state.isEnableIssueTag) return this.state.previousStep > index ? this.previousStep(index) : '';
        const data = {
            secondaryButtonStyle: this.state.secondaryButtonStyle,
            primaryButtonStyle: this.state.primaryButtonStyle,
            index: index,
            childJobTypeId: childJobTypeId,
            previousStep: this.previousStep,
            nextStep: this.handleJobTypeTagCallback,
            jobAssignTags: this.state.jobAssignTags,
            t: this.state.isFromBot ? this.props.t : undefined,
            previousIndex: this.state.previousStep,
            isFromBot: this.state.isFromBot,
            i18n: this.props.i18n
        }
        return (<JobIssueTag data={data} ></JobIssueTag>)

    }

    getPropertyComponent = (index) => {
        const data = {
            secondaryButtonStyle: this.state.secondaryButtonStyle,
            primaryButtonStyle: this.state.primaryButtonStyle,
            index: index,
            propertyId: this.state.propertyId,
            previousStep: this.previousStep,
            nextStep: this.handlePropertyComponentCallback,
            selectedComponent: this.state.selectedComponent,
            selectedFeatureComponent: this.state.selectedFeatureComponent,
            t: this.state.isFromBot ? this.props.t : undefined
        }
        return (<PropertyComponent data={data} ></PropertyComponent>)
    }


    selectServiceType = (index) => {
        const { isLoading, value, propertyList, filteredResults, propertyId, isPropertiesLoading, isJobForMe, jobForMe } = this.state
        let serviceTypes = this.createTypeOfServicesButtons();
        let serviceTypeChild = this.createTypeOfServicesChildButtons();
        const { t } = this.props;
        if (this.state.parentId) {
            this.addParentJobTypeToFrequentlyUsedServices(this.state.parentId);
        }
        return (
            <>
                <div className="wizard-inner">
                    <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                        {(this.state.isShowMoreServices) ?
                            <div>
                                <div className="wizard-header">
                                    <h2>Type the service that you need.</h2>
                                    <p className="userTip">( eg. Electrician )</p>
                                </div>
                                <div className="form-group auto-complete">
                                    <label class="form-label">Service </label>
                                    <Search
                                        placeholder="please type the service you need"
                                        loading={isLoading}
                                        onResultSelect={this.handleResultSelect}
                                        onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                            leading: true,
                                        })}
                                        value={value}
                                        results={filteredResults}
                                        resultRenderer={this.resultRenderer}
                                        {...this.props}
                                    />
                                </div>
                            </div> :
                            <FrequentlyUsedServices
                                onSelect={this.onSelectFrequentlyUsedJobTypes}
                                services={this.state.frequentlyUsedServices}
                                onClickMore={this.showHideMoreService}
                                selectedService={(this.state.parentId) ? this.state.parentId : ''}
                                translationInstance={t}
                                isFromBot={this.state.isFromBot}>
                            </FrequentlyUsedServices>

                        }
                        {serviceTypes}
                        {serviceTypeChild}
                    </div>
                    <div className="wizard-actions">
                        <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{this.state.isFromBot ? t('btn_next') : 'NEXT'}</button>
                    </div>
                </div>
            </>);
    }
    selectUgency = (index) => {
        const dueDate = this.getDueDate();
        const { t } = this.props;
        return (
            <>
                <div className="wizard-inner wzrd-post-a-job">
                    <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                        <div className="wizard-header">
                            <h2>{this.state.isFromBot ? t('pj_lbl_select_urgency') : 'When do you want this issue to be fixed?'}</h2>
                            <p className="usertip">({this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'})</p>
                        </div>
                        <div className="form-group">
                            <ButtonToolbar className="radio-btn-group sub-srvs-typ-tgl" id="jobUrgencyGroup" key="jobUrgencyGroup">
                                <ToggleButtonGroup className={this.state.isFromBot?"btn-group-bot":""} type="radio" name="options" ref="jobUrgency" onChange={(value) => this.handleJobUrgencyOnChange(value)} defaultValue={this.state.jobUrgency}>
                                    <ToggleButton key="jobUrgency1" value="1"><span className="ba-icn ba-icn-ok"></span>{this.state.isFromBot ? t('pj_lbl_select_urgency_urgently') : ' Urgently'}</ToggleButton>
                                    <ToggleButton key="jobUrgency2" value="2"><span className="ba-icn ba-icn-ok"></span>{this.state.isFromBot ? t('pj_lbl_select_urgency_few_days') : ' Within a few days'}</ToggleButton>
                                    <ToggleButton key="jobUrgency3" value="3"><span className="ba-icn ba-icn-ok"></span>{this.state.isFromBot ? t('pj_lbl_select_urgency_within_week') : ' Within a week'}</ToggleButton>
                                    <ToggleButton key="jobUrgency4" value="-999"><span className="ba-icn ba-icn-ok"></span>{this.state.isFromBot ? t('pj_lbl_select_urgency_scheduled') : ' Schedule a date'}</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div>
                        {dueDate}
                    </div>
                    <div className="wizard-actions">
                        <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle} >{this.state.isFromBot ? t('btn_next') : 'NEXT'}</button>
                    </div>
                </div>
            </>);
    }
    selectKeyCollectionType = (index) => {
        const { isJobForMe, isPropertyOwner } = this.state;
        const { t } = this.props;
        return (
            <>
                <div className="wizard-inner">
                    <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                        {(isJobForMe) ? '' :
                            <div>
                                <div className="wizard-header">
                                    <h2>{this.state.isFromBot ? t('hdr_keycollection_option') : 'Choose your preferred key collection option.'} <span className="required-icon">*</span></h2>
                                    <p class="userTip">({this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'})</p>
                                </div>
                                <div className="form-group">

                                    {(this.state.isRestrictKeyCollectionForResidentialProperties && this.state.formData.propertyType == 'Residential') ?
                                        <ButtonToolbar className="radio-btn-group">
                                            <ToggleButtonGroup className={this.state.isFromBot?"btn-group-bot":""} type="radio" name="propertyKey" id="check3" onChange={(value) => this.onKeyCollectionChange(value)} defaultValue={3}>
                                                <ToggleButton value="3"><span className="text-wrap"><span className="ba-icn ba-icn-ok"></span> {this.state.isFromBot ? t('lbl_propkey_case6') : 'Don\'t take the key, I want to be home so let’s arrange a time'}</span></ToggleButton>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>
                                        :
                                        <div>
                                            <ButtonToolbar className="radio-btn-group">
                                                <ToggleButtonGroup className={this.state.isFromBot?"btn-group-bot":""} type="radio" name="propertyKey" id="check3" onChange={(value) => this.onKeyCollectionChange(value)} defaultValue={this.state.keyCollectType}>
                                                    <ToggleButton id="check1" key={1} value="1"><span className="text-wrap"><span className="ba-icn ba-icn-ok"></span> {this.state.isFromBot ? t('lbl_keycollection_option1') : 'Please collect keys from the office to enter the property'}</span></ToggleButton>
                                                    <ToggleButton id="check2" key={2} value="2"><span className="text-wrap"><span className="ba-icn ba-icn-ok"></span> {this.state.isFromBot ? t('lbl_keycollection_option2') : 'Collect the key from the office, inform the tenant on date & time'}</span></ToggleButton>
                                                    <ToggleButton id="check3" key={3} value="3"><span className="text-wrap"><span className="ba-icn ba-icn-ok"></span> {this.state.isFromBot ? t('lbl_keycollection_option3') : 'Contact tenant to arrange access and time'}</span></ToggleButton>
                                                </ToggleButtonGroup>
                                            </ButtonToolbar>

                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {
                            (!isJobForMe
                                && this.state.keyCollectType != null
                                && (this.state.keyCollectType == 1 || this.state.keyCollectType == 2)) ?
                                <JobLocksDrawer propertyId={this.state.propertyId} isSelectable={true} t={t} isFromBot={this.state.isFromBot} onPropertyKeySelected={(event) => this.onPropertyKeySelected(event)}></JobLocksDrawer>
                                : ''
                        }
                    </div>

                    <div className="wizard-actions">
                        <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle} >{this.state.isFromBot ? t('btn_next') : 'NEXT'}</button>
                    </div>
                </div>
            </>);
    }
    getAttachments = (index) => {
        const { isShowAttchmentDrawer, isJobForMe } = this.state;
        const { t } = this.props;
        const styleflex = {
            flexDirection: "column !important",
        }
        return (
            <>
                <div>
                    {(!isShowAttchmentDrawer) ?
                        <div className="wizard-inner wzrd-post-a-job">
                            <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                                <div className="wizard-header">
                                    <h2>{this.state.isFromBot ? t('pj_lbl_attachment') : 'Do you wish to attach images or videos of the issue?'}</h2>
                                    <p className="userTip">({this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'})</p>
                                </div>
                                <div className="radio-btn-group">
                                    <div className={!this.state.isFromBot?"btn-group y-n-btn":"btn-group btn-group-bot"}>
                                        <button type="button" className="btn" onClick={() => this.showAttachmentDrawer()} style={this.state.primaryButtonStyle}>{this.state.isFromBot ? t('lbl_attachment_yes') : 'YES'}</button>
                                        <button type="button" className="btn" onClick={() => this.checkIfFilesMandatory(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('lbl_attachment_no') : 'NO'}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="wizard-actions">
                                <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                            </div>
                        </div>
                        : <div className="wizard-inner">
                            <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                                <div className="wizard-header">
                                    <h2>{this.state.isFromBot ? t('pj_lbl_attachment_sub') : 'Attach images or videos that describe your issue properly.'}</h2>
                                </div>
                                <div className="form-group">
                                    <div className="img-upld-cont">
                                        <AttachmentsDrawer
                                            jobDetails={{ id: this.state.jobId, attachments: this.state.attachments }}
                                            onSubmitAttachments={(event) => { this.setState({ attachments: event }) }}
                                            isJobPost={true}
                                            isFromBot={this.state.isFromBot}
                                            t={t}>
                                        </AttachmentsDrawer>
                                        {(this.state.attachments) ?
                                            <ImageViewer attachments={this.state.attachments} isFromBot={this.state.isFromBot} t={t}></ImageViewer> : ''
                                        }
                                    </div>
                                </div>
                                <div className="wizard-actions flot-left w-100 pt-3">
                                    <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                                    <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{this.state.isFromBot ? t('btn_next') : 'NEXT'}</button>
                                </div>
                            </div>

                        </div>}
                </div>
            </>);
    }

    selectBudget = (index) => {
        const { isJobForMe } = this.state;
        return (
            <>
                {(isJobForMe) ?
                    <div className="wizard-inner wzrd-post-a-job">
                        <div className="wizard-body2">
                            <div className="wizard-header">
                                <h2>Do you have an estimate in mind?<span className="required-icon">*</span></h2>
                                <p className="usertip">(choose an option)</p>
                            </div>
                            <div className="sub-srvs-typ-tgl">
                                <ButtonToolbar className="radio-btn-group" id="budgetSelectGroup" key="budgetSelectGroup">
                                    <ToggleButtonGroup className={this.state.isFromBot?"btn-group-bot budgetSelect":"budgetSelect"} id="budgetSelect" type="radio" name="budget" onChange={(value) => this.onChangeJobBudget(value)} defaultValue={this.state.jobBudget}>
                                        <ToggleButton id="budget1" value="1"><span className="ba-icn ba-icn-ok"></span>Under $500</ToggleButton>
                                        <ToggleButton id="budget2" value="2"><span className="ba-icn ba-icn-ok"></span>$500 - $1000</ToggleButton>
                                        <ToggleButton id="budget3" value="3"><span className="ba-icn ba-icn-ok"></span>$1000 - $2000</ToggleButton>
                                        <ToggleButton id="budget4" value="4"><span className="ba-icn ba-icn-ok"></span>$2000 - $5000</ToggleButton>
                                        <ToggleButton id="budget5" value="5"><span className="ba-icn ba-icn-ok"></span>$5000+</ToggleButton>
                                        <ToggleButton id="budget0" value="0" ><span className="ba-icn ba-icn-ok"></span>Unsure</ToggleButton>
                                        <ToggleButton id="budget6" value="-999"><span className="ba-icn ba-icn-ok"></span>Custom</ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </div>
                            <div className={(this.state.jobBudget == -999) ? 'form-group mt-3' : 'form-group d-none'}>
                                <input type="number" className="form-control"
                                    placeholder="Enter your estimate." aria-label="test"
                                    value={this.state.budgetAmount} ref={this.budgetRef} onChange={e => this.onChangeAmount(e)}>
                                </input>
                            </div>
                        </div>
                        <div className="wizard-actions">
                            <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>BACK</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>NEXT</button>
                        </div>
                    </div>
                    : ""}
            </>
        )
    }

    getTrades = (index) => {

        const { inviteToProviders, selectedTradees, isSelectedOnDemand } = this.state;
        return (
            <>
                <div>
                    <div className="wizard-inner">
                        <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                            <div className="wizard-header">
                                <h2>Invite trade/trades for maintenance request.<span className="required-icon">*</span></h2>
                            </div>
                            <div className="img-upld-cont">
                                <InviteTradee
                                    location={this.state.jobForMe}
                                    jobTypeId={this.state.selectedServiceType.parentJobType.id}
                                    validateBeforeInviteTradee={this.validateBeforeInviteTradee}
                                    onAttachTradeesToTheJob={(event) => this.onAttachTradeesToTheJob(event)}
                                    selectedTradees={this.state.selectedTradees}
                                    isFromBot = {this.state.isFromBot}>
                                </InviteTradee>
                            </div>
                            {(inviteToProviders && !isSelectedOnDemand) ? <label>Invite trades from B+A</label> : ""}
                            {(isSelectedOnDemand) ?
                                <div>
                                    {selectedTradees.map((item, index) => {
                                        return <p className='mb-2' key={index}>{item.name}</p>
                                    })}
                                </div> : ""}

                        </div>
                        <div className="wizard-actions">
                            <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle} >BACK</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle} >NEXT</button>

                        </div>
                    </div>
                </div>
            </>);
    }

    selectSubmitOption = (index) => {
        const { t } = this.props;
        return (
            <div className="wizard-inner">
                <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                    <div className="wizard-header">
                        <h2>{this.state.isFromBot ? t('pj_lbl_select_submit_options') : 'Please select submit option.'}</h2>
                        <p className="usertip">({this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'})</p>
                    </div>
                    <ButtonToolbar className="radio-btn-group">
                        <ToggleButtonGroup type="radio" name="options" className={this.props.isFromBot?"btn-group-bot":""}
                            onChange={(value) => { this.onSubmitJob(value) }} value={this.state.actionValue}>
                            <ToggleButton value={2}><span className="ba-icn ba-icn-ok"></span> {this.state.isFromBot ? t('pj_button_job_post') : 'Job Post'}</ToggleButton>
                            {this.state.isFromBot ? !this.state.chatbotSetting.enableDraftJobFeature &&
                                <ToggleButton value={1}><span className="ba-icn ba-icn-ok"></span> {t('pj_button_job_draft')}</ToggleButton>
                                :
                                <ToggleButton value={1}><span className="ba-icn ba-icn-ok"></span> {this.state.isFromBot ? t('pj_button_job_draft') : 'Job Draft'}</ToggleButton>
                            }
                        </ToggleButtonGroup>
                    </ButtonToolbar>
                    <div className="wizard-actions">
                        <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                    </div>
                </div>
            </div>)
    }

    setResponse = (index) => {
        const { t } = this.props;
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <div className="job-posted-icon">
                            <span className="ba-icn ba-icn-briefcase" style={{ color: (this.state.isFromBot && this.props.customStyle.brand) ? this.props.customStyle.brand : '' }}>
                                <span className="ba-icn ba-icn-ok"></span>
                            </span>
                        </div>
                        <div className="job-posted-msg">
                            {(this.state.isFromBot && this.state.isDraftJob) ? 
                            <h3 className="text-center"> {t('pj_lbl_job_draft_response')} </h3> 
                            : <h3 className="text-center">{this.state.isFromBot ?
                             t('lbl_jobpost_success')  
                             : (this.state.isDraftJob) ? 
                             <h3 className="text-center"> {t('pj_lbl_job_draft_response')} </h3> 
                             : t('lbl_jobpost_success')}</h3>}
                        </div>
                        <div className="wizard-actions">
                            {(this.state.isFromBot) ? <button onClick={() => this.props.resetChat()} className="btn btn-primary" style={this.state.primaryButtonStyle} ><span>{t('btn_done')}</span></button> : <Link to={`./home`} className="btn btn-primary"><span>Back to home</span></Link>}
                        </div>
                    </div>
                </div>
            </>);
    }
    nextStep = (index) => {
        const { i18n } = this.props;
        var currentStep = index;
        if (this.validate(index)) {
            if (index == 1) {
                if (this.state.isJobForMe) {
                    currentStep = (index + 2);
                }
                else {

                    let formdata = this.state.formData;
                    const typedDescription = this.jobDescription.current.value;
                    if (typedDescription.length > 0) {
                        if (i18n.language != 'en' && !i18n.language.toString().includes("en")) {
                            this.applyTranslation(typedDescription, i18n.language, 'en').then((trans) => {
                                formdata.jobDescription = typedDescription;
                                formdata.jobDescriptionEng = trans[0];
                                this.setState({
                                    formdata: formdata,
                                    isMaintatanceIssueProvided: true
                                });
                                this.getTroubleShoutingGuide(index);
                            });
                        } else {
                            this.getTroubleShoutingGuide(index);
                        }
                    }
                    const typedArea = this.issueAreaInProperty.current.value;

                    if (typedArea.length > 0) {
                        if (i18n.language != "en" && !i18n.language.toString().includes("en")) {
                            this.applyTranslation(typedArea, i18n.language, 'en').then((trans) => {
                                formdata.issueAreaInProperty = typedArea;
                                formdata.issueAreaInPropertyEng = trans[0];
                                this.setState({ formdata: formdata });
                            });
                        } else {
                            formdata.issueAreaInProperty = typedArea;
                            formdata.issueAreaInPropertyEng = typedArea;
                            this.setState({ formdata: formdata });
                        }
                    }


                    currentStep = (index + 1);
                }
            }            else if (this.state.isJobForMe && index == 4) {
                currentStep = (index + 5);
            }

            else if (this.state.isJobForMe && index == 9) {
                currentStep = (index - 4);
            }
            else if (this.state.isJobForMe && index == 5) {
                currentStep = (index + 5);
            }
            else if (!this.state.isJobForMe && !this.state.allowTenantsToSeeMatchingTrades && index == 8) { // 7
                currentStep = (index + 3);
            }
            else if (!this.state.isJobForMe && this.state.allowTenantsToSeeMatchingTrades && index == 8) { 
                currentStep = (index + 2);
            }
            else if (!this.state.isJobForMe && !this.state.allowTenantsToSeeMatchingTrades && index == 10) { //7
                currentStep = (index + 2);
            }
            else {
                currentStep = (index + 1);

            }


        } else {
            return false;
        }
        this.handleChatbotScroll();
        currentStep = this.permissionCheckNextStep(currentStep);
        this.setState({ currentStep: currentStep, previousStep: index });
    }

    previousStep = (index) => {
        var currentStep = index;
        if (this.state.isJobForMe && index == 9) { // 7
            currentStep = (index - 6);
        }
        else if (this.state.isJobForMe && index == 8) { //6
            currentStep = (index - 5);
        }
        else if (this.state.isJobForMe && index == 10) { //6
            currentStep = (index - 6);
        }
        else if (this.state.isJobForMe && index == 5) { //6
            currentStep = (index + 5);
        }
        else if (!this.state.isJobForMe && index == 9) { //7
            currentStep = (index - 2);
        }
        else if (!this.state.isJobForMe && this.state.allowTenantsToSeeMatchingTrades && index == 11) {
            currentStep = (index - 1);
        }
        else if (!this.state.isJobForMe && index == 11) { //7
            currentStep = (index - 3);
        }
        else if (!this.state.isJobForMe && this.state.allowTenantsToSeeMatchingTrades && index == 10) { 
            currentStep = (index - 2);
        }
        else if (index == 3) {
            currentStep = (index - 2);
        }
        else {
            currentStep = (index - 1);
        }
        this.handleChatbotScroll();
        currentStep = this.permissionCheckBackStep(currentStep);
        this.setState({ currentStep: currentStep, previousStep: index });
    }

    permissionCheckNextStep = (nextIndex) => {
        if (!this.state.isJobForMe && nextIndex == 6 && !this.state.isEnableSuggestingImage) {
            nextIndex = (nextIndex + 1);
        }
        if (!this.state.isJobForMe && nextIndex == 7 && !this.state.isEnableIssueTag) {
            nextIndex = (nextIndex + 1);
        }
        return nextIndex;
    }

    permissionCheckBackStep = (nextIndex) => {
        if (!this.state.isJobForMe && nextIndex == 6 && !this.state.isEnableSuggestingImage) {
            nextIndex = (nextIndex - 1);
        }
        if (!this.state.isJobForMe && nextIndex == 7 && !this.state.isEnableIssueTag) {
            nextIndex = (nextIndex - 1);
        }
        return nextIndex;
    }

    showAttachmentDrawer = () => {
        this.setState({ isShowAttchmentDrawer: true });
    }

    checkIfFilesMandatory = (index) => {
        const { t } = this.props;
        if (!this.state.isJobForMe) {
            if (this.state.isMandatoryToUploadFiles) {
                this.notifyError(this.state.isFromBot ? t('pj_error_attachments_empty') : 'Attachments are required.');
                return false;
            } else {
                this.nextStep(index);
            }
        } {
            this.nextStep(index);
        }
    }

    onSubmitJob = (workflow) => {
         const { t } = this.props;  
        if (this.state.isJobForMe || workflow == 1) {
            if (workflow == 1) {
                this.setState({ isDraftJob: true });
            }
            this.postJob(workflow);
        } else {
            if (!this.state.isJobForMe && this.state.isMandatoryToUploadFiles && (this.state.attachments == [] || this.state.attachments.length == 0)) {
                this.notifyError(this.state.isFromBot ? t('pj_error_attachments_empty') : 'Attachments are required.');
                return false;
            } else {
                this.loadDisclaimer(workflow);
            }
        }
    }

    loadDisclaimer = (workflow) => {
        this.setState({
            isLoading: true
        });
        propertyService.GetDisclaimerByPropertyId(this.state.formData.propertyId).then(res => {
            this.setState({
                isLoading: false
            });
            if (res !== null && res !== '') {
                this.setState({
                    showDisclaimerDrawer: true,
                    disclamimerText: res,
                    jobPostWorkflowStatus: workflow,
                    actionValue :0
                });
            } else {
                this.postJob(workflow);
            }
        });
    }

    validate = (index) => {
        const { tradeeCount, jobBudget, budgetAmount, suburbId, isSelectedOnDemand,
            inviteToProviders, isJobForMe, selectedTradees, selectedService,
            selectedServiceType, formData } = this.state
        let currentStep = this.state.stepFlow[index][1];
        const { t } = this.props;
        switch (currentStep) {
            case "selectProperty":
                if (isJobForMe && (this.state.jobForMe.suburbId == '' || this.state.jobForMe.suburbId == null)) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_invalid_address') : 'Please enter a valid address.');
                    return false;
                }
                if (this.state.jobForMe.address == "" && isJobForMe) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_no_address') : 'Please enter the address.');
                    return false;
                } else if (!isJobForMe && !this.state.property) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_no_property_selected') : 'Please select a property.');
                    return false;
                }
                else {
                    return true;
                }
            case "getMaintenaceIssue":
                if ((this.state.formData.jobDescription == '' || this.state.formData.jobDescription == undefined) && (!this.jobDescription.current.value || this.jobDescription.current.value == '')) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_no_jd') : 'Please enter job description.');
                    return false;
                } else {
                    return true;
                }
            case "selectServiceType":
                if (selectedService && selectedService.parentJobType) {
                    if (selectedServiceType && selectedServiceType.parentJobType && selectedServiceType.parentJobType.id != '') {
                        if (selectedServiceType.childJobTypes.length > 0 && (!formData.childJobType || formData.childJobType === '')) {
                            var errorMessage = this.state.isFromBot ? t('pj_error_child_job_type_not_selected') : 'Please select -parentTypeDesc- that you need.';
                            //errorMessage.replace('-parentType-', selectedServiceType.parentJobType.description.replace(' ? ', ''));
                            this.notifyError(errorMessage);
                            return false;
                        }
                    } else {
                        this.notifyError(this.state.isFromBot ? t('pj_error_parent_job_type_not_selected') : 'Please select a type of service.');
                        return false;
                    }
                } else if (!selectedService.parentJobType) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_service_not_selected') : 'Please select a service.');
                    return false;
                }
                return true;
            case "selectUgency":
                if (this.state.jobUrgency == undefined || this.state.jobUrgency == '') {
                    this.notifyError(this.state.isFromBot ? t('pj_error_urgency_not_selected') : 'Please select job urgency.');
                    return false;
                }
                if (this.state.jobUrgency == -999 && this.state.dueDate == '') {
                    this.notifyError(this.state.isFromBot ? t('pj_error_due_date_not_selected') : 'Please select a due date.');
                    return false;
                }
                return true;
            case "selectKeyCollectionType":
                if (this.state.keyCollectType == undefined || this.state.keyCollectType == '') {
                    this.notifyError(this.state.isFromBot ? t('pj_error_key_coll_method_not_selected') : 'Please select property key collection method.');
                    return false;
                }
                return true;
            case "selectBudget":
                if (jobBudget == '') {
                    this.notifyError(this.state.isFromBot ? t('pj_error_budget_not_selected') : 'Please select your estimate.');
                    return false;
                }
                if (jobBudget == '-999' && (budgetAmount == '' || budgetAmount == 0)) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_budget_empty') : 'Please enter your estimate.');
                    return false;
                };
                return true;
            case "getAttachments":
                if (!this.state.isJobForMe) {
                    if (this.state.isMandatoryToUploadFiles && this.state.attachments.length == 0) {
                        this.notifyError(this.state.isFromBot ? t('pj_error_attachments_empty') : 'Attachments are required.');
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            case "getTrades":
                if (isSelectedOnDemand) {
                    if (selectedTradees.length == 0) {
                        this.notifyError(this.state.isFromBot ? t('pj_error_trades_not_selected') : 'Please select one or more trades to progress.');
                        return false;
                    } else {
                        return true;
                    }
                } else if (!inviteToProviders) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_trades_not_selected') : 'Please select one or more trades to progress.');
                    return false;
                } else if ((inviteToProviders && !isSelectedOnDemand && tradeeCount == 0)) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_trades_not_selected') : 'Please select one or more trades to progress.');
                    return false;
                } else {
                    return true;
                }
            case "setResponse":
                return this.setResponse(index);

            case "selectJobRelatedGoogleImage":
                return true;

            case "getJobTypeTags":
                return true;

            case "getPropertyComponent":
                return true;

        }
    }

    postJob = (workflow) => {
        const workflowId = workflow;
        let jobDetails = {};
        const { address, budget, latitude, location, longitude, placeId, propertyType, suburbId } = this.state.jobForMe;
        const { selectedService, selectedServiceType, formData, jobBudget, selectedTradees, isSelectedOnDemand, selectedQuoteLimit, budgetAmount, jobAssignTags, selectedComponent, selectedFeatureComponent } = this.state;
        if (this.state.isJobForMe == true) {

            jobDetails.propertyId = null;
            jobDetails.suburbId = suburbId;
            jobDetails.address = address;
            jobDetails.location = location;
            jobDetails.jobPropertyTypes = propertyType;
            if (this.state.jobForMe && this.state.jobForMe.propertyType && this.state.jobForMe.propertyType === "Residential") {
                jobDetails.propertyTypeId = 0;
            }
            else if (this.state.jobForMe && this.state.jobForMe.propertyType && this.state.jobForMe.propertyType === "Commercial") {
                jobDetails.propertyTypeId = 1;
            } else {
                if (this.state.propertyType && this.state.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                } else {
                    jobDetails.propertyTypeId = 1;
                }
            }
            jobDetails.budgetId = parseInt(jobBudget);
            if (jobBudget == '-999') {
                jobDetails.budgetAmount = parseFloat(budgetAmount);
            }
            if (isSelectedOnDemand) {
                jobDetails.targetedTradeeOption = 4;
                jobDetails.sendInvitationtoAll = false;
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                let tradees = [];
                selectedTradees.forEach(element => {
                    tradees.push({
                        isAssigned: true,
                        userId: element.userId,
                        name: element.name,
                        email: element.email,
                        firstName: element.name,
                        lastName: element.name,
                    });
                });
                jobDetails.tradees = tradees;
            } else {
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                jobDetails.targetedTradeeOption = 0;
                jobDetails.sendInvitationtoAll = true;
                jobDetails.tradees = [];
            }
        } else {
            if (this.state.formData.propertyId == 0 || this.state.formData.propertyId == undefined) {
                jobDetails.propertyId = null;
                jobDetails.suburbId = this.state.locationDetails.suburbId;
                jobDetails.address = this.state.locationDetails.address;
                jobDetails.jobPropertyTypes = this.refs.jobPropertyTypes.props.value;
                if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                } else if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Commercial") {
                    jobDetails.propertyTypeId = 1;
                }
                else {
                    jobDetails.propertyTypeId = 0;
                }
            } else {
                jobDetails.propertyId = this.state.formData.propertyId;
                jobDetails.address = this.state.formData.address;
                jobDetails.location = this.state.formData.location;
                jobDetails.propertyName = this.state.formData.propertyName;
                jobDetails.suburbId = this.state.formData.suburbId;
                if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                }
                else if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Commercial") {
                    jobDetails.propertyTypeId = 1;
                } else {
                    jobDetails.propertyTypeId = 0;
                }
                jobDetails.jobPropertyTypes = this.state.formData.propertyType;
            }
            jobDetails.propertyKeyCollectType = parseInt(this.state.keyCollectType);

            if (isSelectedOnDemand) {
                jobDetails.targetedTradeeOption = 4;
                jobDetails.sendInvitationtoAll = false;
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                let tradees = [];
                selectedTradees.forEach(element => {
                    tradees.push({
                        isAssigned: true,
                        userId: element.userId,
                        name: element.name,
                        email: element.email,
                        firstName: element.name,
                        lastName: element.name,
                    });
                });
                jobDetails.tradees = tradees;
            } else {
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                jobDetails.targetedTradeeOption = 0;
                jobDetails.sendInvitationtoAll = true;
                jobDetails.tradees = [];
            }
        }
        jobDetails.id = this.state.jobId;
        jobDetails.secondaryJobType = this.state.selectedServiceType.parentJobType.id;
        jobDetails.secondaryJobTypeDisplay = this.state.selectedServiceType.parentJobType.title;
        jobDetails.primaryJobType = this.state.selectedService.parentJobType.id;
        jobDetails.primaryJobTypeDisplay = this.state.selectedService.parentJobType.title;
        jobDetails.jobPropertyLocks = this.state.propertyLocks.map((item, index) => {
            const jobLock = {};
            jobLock.jobId = null;
            jobLock.propertyLock = {};
            jobLock.propertyLock.id = item.id;
            jobLock.propertyLock.lockId = item.lockId;
            jobLock.propertyLock.propertyId = jobDetails.propertyId;
            return jobLock;
        });

        if (this.state.formData.childJobTypeId && this.state.formData.childJobTypeId.trim() != "") {
            jobDetails.jobType = this.state.formData.childJobTypeId;
        }
        else {
            jobDetails.jobType = this.state.selectedServiceType.parentJobType.id;
        }

        jobDetails.jobTypeDisplay = this.state.formData.childJobType;
        jobDetails.title = this.state.formData.jobDescriptionEng;
        jobDetails.description = this.state.formData.jobDescriptionEng;
        jobDetails.jobUrgencyId = parseInt(this.state.jobUrgency);

        jobDetails.dueDate = (!this.state.dueDate) ? null : this.state.dueDate;
        // jobDetails.JobUrgencyDisplay =  this.refs.jobUrgency.props.value;
        jobDetails.jobValidityPeriod = 1;
        jobDetails.serviceConsumerId = localStorage.getItem('userId');

        jobDetails.workflowStatusId = workflow === 1 ? 1 : 2;
        jobDetails.attachments = this.state.attachments;
        if (workflowId == 2) {
            jobDetails.isActive = true;
        }
        if (selectedComponent != '') {
            jobDetails.JobPropertyComponentId = selectedComponent;
        }

        if (jobAssignTags != undefined && jobAssignTags.length > 0) {
            jobDetails.JobAssignTagIds = jobAssignTags;
        }

        if (selectedFeatureComponent != undefined && selectedFeatureComponent.length > 0) {
            jobDetails.JobPropertyFeatureComponentIds = selectedFeatureComponent;
        }

        jobDetails.issueAreaInProperty = (this.state.formData.issueAreaInPropertyEng) ? this.state.formData.issueAreaInPropertyEng : this.state.formData.issueAreaInProperty;

        if (this.state.maintenanceTroubleshootGuideId && this.state.maintenanceTroubleshootGuideId != '') {
            jobDetails.maintenanceTroubleshootGuideId = this.state.maintenanceTroubleshootGuideId;
        }
        if(this.state.isFromBot){
            jobDetails.channelId = 12;
        }else{
            jobDetails.channelId = 1;
        }
        this.setState({ isJobPosting: true })
        try {
            trackPromise(
                AXIOS.POST(`tenant-api/job-post`, jobDetails, {
                    'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret ,'ChannelId':jobDetails.channelId}
                })
                    .then(dataJson => {
                        this.setState({ isJobPosting: false })
                        const message = workflowId === 1 ? 'drafted' : 'posted'
                        if (dataJson == 'Request Unauthorized' || dataJson == 'Job creation failed') {
                            toast.error(`Job is not ${message} successfully. Please try again.`, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        } else {
                            toast.success(`Job is ${message} successfully.`, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            this.setState({ jobNumber: dataJson.jobNumber });
                            this.setState({ currentStep: 12 });
                            this.setState({
                                attachments: [],
                                value: '',
                                isShowMoreServices: false,
                                showDisclaimerDrawer: false,
                                jobPostWorkflowStatus: 1
                            });
                            if (this.state.troubleShootingGuideDetails.troubleshootGuideId != '') {
                                this.saveTroubleShootResult(false, dataJson.id);
                            }
                        }
                    }, error => {
                        console.log(error);
                        this.setState({ isJobPosting: false });
                        toast.error(`Fail to post the job. Please try again.`, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
            )

        } catch (error) {
            console.log(error);
        }
    }
    validateBeforeInviteTradee = () => {
        var v = true;
        if (this.state.jobForMe.address == '') { this.notifyError('Please enter your address.'); v = v && false; }
        if (this.state.selectedService && this.state.selectedService.parentJobType) {
            if (this.state.selectedServiceType && this.state.selectedServiceType.parentJobType && this.state.selectedServiceType.parentJobType.id != '') {
                if (this.state.selectedServiceType.childJobTypes.length > 0 && (!this.state.formData.childJobType || this.state.formData.childJobType === '')) {
                    this.notifyError(`Please select ${this.state.selectedServiceType.parentJobType.description.replace('?', '')} that you need.`); v = v && false;
                }
            } else {
                this.notifyError('Please select a type of service.'); v = v && false;
            }
        } else {
            this.notifyError('Please select a service.'); v = v && false;
        }

        return v;
    }

    saveTroubleShootResult = (isResolved = false, jobId = "") => {
        let troubleShootResult = {};
        troubleShootResult.isResolved = isResolved;
        troubleShootResult.jobId = jobId;
        troubleShootResult.userId = this.state.userId;
        troubleShootResult.maintenanceTroubleshootGuideId = this.state.troubleShootingGuideDetails.troubleshootGuideId;
        troubleShootResult.description = this.state.formData.jobDescriptionEng;
        troubleShootResult.propertyId = this.state.propertyId;
        jobService.saveTroubleShootingResult(troubleShootResult).then(response => {
            if (response) {
                if (isResolved) {
                    this.setState({ currentStep: (13) });
                }
            }
        });
    }

    continueJobPosting = (index) => {
        let jobTypes = this.props.jobTypes;
        this.state.selectedService = jobTypes.find(x => x.parentJobType.id == this.state.troubleShootingGuideDetails.primaryJobTypeId);
        this.state.primaryJobTypes = this.state.selectedService.childJobTypes;
        this.state.parentId = this.state.troubleShootingGuideDetails.primaryJobTypeId;
        this.state.isServiceTypeSelected = true;
        this.state.childJobTypeId = this.state.troubleShootingGuideDetails.secondaryJobTypeId;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === this.state.troubleShootingGuideDetails.secondaryJobTypeId);
        if (selectedNode !== undefined) {
            this.setSecondaryJobType(selectedNode);
        }
        else {
            this.state.selectedServiceType.parentJobType = this.state.selectedService.childJobTypes.find(x => x.id == this.state.troubleShootingGuideDetails.secondaryJobTypeId);
            this.state.selectedServiceType.childJobTypes = [];
        }
        this.setState({
            currentStep: (index + 1),
            parentId: this.state.troubleShootingGuideDetails.primaryJobTypeId
        });
    }

    setSecondaryJobType = (selectedNode) => {
        let formData2 = {
            ...this.state.formData, childJobType: '',
            childJobTypeId: ''
        };
        this.setState({
            secondaryJobTypes: selectedNode.childJobTypes,
            selectedServiceType: selectedNode,
            formData: formData2
        });
    }

    setTroubleShooteResponse = () => {
        const { t } = this.props;
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <div className="job-posted-icon">
                            <span className="ba-icn ba-icn-briefcase" style={{ color: (this.state.isFromBot && this.props.customStyle.brand) ? this.props.customStyle.brand : '' }}>
                                <span className="ba-icn ba-icn-ok"></span>
                            </span>
                        </div>
                        <div className="job-posted-msg">
                            <h3 className="text-center">{this.state.isFromBot ? t('pj_lbl_resolved_issue_response') : 'You have successfully resolved the issue'}</h3>
                        </div>
                        <div className="wizard-actions">
                            {(this.state.isFromBot) ? <button onClick={() => this.props.resetChat()} className="btn btn-primary" style={this.state.primaryButtonStyle} ><span>{t('btn_done')}</span></button> : <Link to={`./home`} className="btn btn-primary"><span>Back to home</span></Link>}
                        </div>
                    </div>
                </div>
            </>);
    }

    handleChatbotScroll = () => {
        if (this.props.isFromBot) {
            this.props.scorolToTop();
        }
    }

    t() {
        return false;
    }

    addParentJobTypeToFrequentlyUsedServices = (parentId) => {
        let { frequentlyUsedServices, availableServices } = this.state;
        let parentJob = _.find(frequentlyUsedServices, f => f.value === parentId);
        let selectedNode = _.find(availableServices, f => f.value === parentId);
        const { i18n } = this.props;
        if (!parentJob) {
            let services = frequentlyUsedServices;
            let parent = {
                label: selectedNode.label,
                value: selectedNode.value
            }
            if (this.state.isFromBot && i18n.language !== "en" && !i18n.language.toString().includes("en")) {

                this.applyTranslation(([selectedNode.label]), "en", i18n.language).then((trans) => {
                    if (trans.length > 0) {
                        parent.label = trans[0];

                        services.unshift(parent);
                        this.setState({
                            frequentlyUsedServices: services
                        })
                    }
                });
            } else {
                services.unshift(parent);
                this.setState({
                    frequentlyUsedServices: services
                })
            }

        }
    }

}



const mapStateToProps = (state, ownProps) => {
    try {
        return {
            jobTypes: state.Jobs.jobTypes,
            dateFormat: state.User.dateFormat,
            userRoles: state.User.userRoles,
            properties: state.Tenant.tenantProperties,
            isCurrentTenant: state.Tenant.isCurrentTenant,
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateProperties: properties => {
            dispatch({ type: "SET_PROPERTIES", properties: properties });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OwnerJobPostWizard));
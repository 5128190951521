import React from "react";
import ImageViewer from "../../../Common/ImageViewer/ImageViewer";

class JobNotes extends React.Component {
  render() {
    const jobNotes = this.props.jobNotes;
    return (
      <div className="panel-body tabbed-panel">
        <div className="job-notes-container">
         {(jobNotes.length === 0 || jobNotes == null) ? "Notes not available" : 
            (jobNotes.map((note, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="job-note ng-star-inserted">
                    <div className="job-note-header">
                      <div className="job-note-headerL">
                        <div className="profile-img">
                          <img alt="" src={note.createdUserProfileImageUrl} />
                        </div>
                        <div>
                          <h4>{note.createdUserName}</h4>
                          <p>{note.createdUserType}</p>
                        </div>
                      </div>
                      <div className="job-note-headerR">
                        <label>Posted on {note.createdOn} </label>
                      </div>
                    </div>
                    <div className="job-note-body">
                      <div className="note-type">
                        <label className="label label-default">
                          {note.type}
                        </label>
                      </div>
                      <div className="job-note-email"
                      dangerouslySetInnerHTML={{
                        __html: note.note
                      }}></div>
                      {(note.attachments && note.attachments.length > 0) ? (
                        <div>
                          <br/>
                          <h5>Attachment(s)</h5>
                          <ImageViewer
                            attachments={note.attachments}
                          ></ImageViewer>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            }))}
        </div>
      </div>
    );
  }
}

export default JobNotes;

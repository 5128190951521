import * as firebase from 'firebase';
import * as _ from 'lodash';
import moment from 'moment';
import { AXIOS } from '../Utilities/HttpRequest/Axios';
import { AppConfiguration } from "read-appsettings-json";

export const getConversationIdByUserWithOrderByIsRead = (senderId) => {
    const databseRef = firebase.database();
    return databseRef.ref(`users/${senderId}/conversations`).orderByChild('isRead');
}

export const getConversationIdByUser = (senderId) => {
    const databseRef = firebase.database();
    return databseRef.ref(`users/${senderId}/conversations`);
}

export const getConversationById = (conversationId) => {
    const databseRef = firebase.database();
    return databseRef.ref(`conversations/${conversationId}/conversationMessages`);
}

export const createPrivateConversation = (tread) => {
    const databseRef = firebase.database();
    const updates = {};
    const newKey = databseRef.ref().push().key;

    let _obj = {};
    _obj.userId = tread.sender;
    _obj.isRead = true;
    _obj.unreadMessageCount = 0;
    _obj.profileImageUrl = tread.senderImage;
    _obj.firstName = tread.senderFirstName;
    _obj.lastName = tread.senderLastName;

    updates["/conversations/" + newKey + "/conversationUsers/" + tread.sender] = _obj;

    let senderConversationObj = {};
    senderConversationObj.conversationId = newKey;
    senderConversationObj.conversationName = tread.receiverFirstName + " " + tread.receiverLastName;
    senderConversationObj.isRead = true;
    senderConversationObj.unReadMessageCount = 0;
    senderConversationObj.receiver = tread.receiver;
    senderConversationObj.receiverName = tread.receiverFirstName + ' ' + tread.receiverLastName;
    senderConversationObj.senderName = tread.senderFirstName + ' ' + tread.senderLastName;
    senderConversationObj.sender = tread.sender;
    senderConversationObj.receiverImageUrl = tread.receiverImage || '';
    senderConversationObj.senderImageUrl = tread.senderImage || '';
    senderConversationObj.isPrivate = true;
    senderConversationObj.senderFirstName = tread.senderFirstName;
    senderConversationObj.senderLastName = tread.senderLastName;
    senderConversationObj.receiverFirstName = tread.receiverFirstName;
    senderConversationObj.receiverLastName = tread.receiverLastName;

    updates["/users/" + tread.sender + "/conversations/" + newKey] = senderConversationObj;

    let _objReceiver = {};
    _objReceiver.userId = tread.receiver;
    _objReceiver.isRead = false;
    _objReceiver.unreadMessageCount = 0;
    _objReceiver.profileImageUrl = tread.receiverImage || '';
    _objReceiver.firstName = tread.receiverFirstName;
    _objReceiver.lastName = tread.receiverLastName;

    updates["/conversations/" + newKey + "/conversationUsers/" + tread.receiver] = _objReceiver;

    let receiverConversationObj = {};
    receiverConversationObj.conversationId = newKey;
    receiverConversationObj.conversationName = tread.senderFirstName + " " + tread.senderLastName;
    receiverConversationObj.isRead = true;
    receiverConversationObj.unReadMessageCount = 0;
    receiverConversationObj.receiver = tread.sender;
    receiverConversationObj.receiverName = tread.senderFirstName + ' ' + tread.senderLastName;
    receiverConversationObj.senderName = tread.receiverFirstName + ' ' + tread.receiverLastName;
    receiverConversationObj.sender = tread.receiver;
    receiverConversationObj.receiverImageUrl = tread.senderImage || '';
    receiverConversationObj.senderImageUrl = tread.receiverImage || '';
    receiverConversationObj.isPrivate = true;
    receiverConversationObj.senderFirstName = tread.receiverFirstName;
    receiverConversationObj.senderLastName = tread.receiverLastName;
    receiverConversationObj.receiverFirstName = tread.senderFirstName;
    receiverConversationObj.receiverLastName = tread.senderLastName;

    updates["/users/" + tread.receiver + "/conversations/" + newKey] = receiverConversationObj;

    updates["/conversations/" + newKey + "/createdBy"] = tread.sender;

    databseRef.ref().update(updates);
    return newKey;

}

export const resetConversationData = (conversationId) => {
    const databseRef = firebase.database();
    return databseRef.ref('/conversations/' + conversationId).off();
}

export const resetConversationMessages = (conversation) => {
    const databseRef = firebase.database();
    databseRef.ref(`conversations/${conversation.conversationId}/conversationMessages`).off();
    databseRef.ref(`users/${conversation.sender}/conversations/${conversation.conversationId}`).off();
}

export const getConversationByIdChunk = (conversationId, chunkSize) => {
    const databseRef = firebase.database();
    return databseRef.ref('conversations/' + conversationId + '/conversationMessages').limitToLast(chunkSize);
}

export const getConversationUsersByConversationId = (conversationId) => {
    const databseRef = firebase.database();
    return databseRef.ref('conversations/' + conversationId + '/conversationUsers');
}

export const getConversationChanges = (model) => {
    const databseRef = firebase.database();
    return databseRef.ref("users/" + model.sender + "/conversations/" + model.conversationId)
}

export const getUnreadMessageCountChanges = (userId, conversationId) => {
    const databseRef = firebase.database();
    return databseRef.ref(`users/${userId}/conversations/${conversationId}/unReadMessageCount`);
}

export const getUnreadCommonMessageCountChanges = (userId) => {
    const databseRef = firebase.database();
    return databseRef.ref(`users/${userId}/unReadMessageCount`);
}

export const updateUserActiveStatus = (userId, status) => {
    const databseRef = firebase.database();
    let update = {};
    update[`users/${userId}/isActive`] = status;
    return databseRef.ref().update(update);
}

export const geActivationStatus = (userId) => {
    const databseRef = firebase.database();
    return databseRef.ref(`users/${userId}/isActive`);
}

export const sendChatMessage = (senderId, messageObj, conversationThread, message) => {
    let prevMessage = -1;
    const databseRef = firebase.database();
    let convemessage = getConversationUsersByConversationId(conversationThread.conversationId)
        .on('value', snapshot => {
            if (prevMessage === -1) {
                prevMessage = 1;
                const Updates = {};
                let receiverList = [];
                let conversationNotifications = {};
                conversationNotifications.message = message;
                conversationNotifications.type = 1;
                conversationNotifications.conversationId = conversationThread.conversationId;
                conversationNotifications.senderId = senderId;
                let dataList = [];
                snapshot.forEach(element => {
                    const childKey = element.key;
                    let childData = element.val();
                    childData.key = childKey;
                    dataList.push(childData);

                    let mobileChatUsersViewModel = {}
                    mobileChatUsersViewModel.userId = childData['userId'];

                    if (mobileChatUsersViewModel.userId !== senderId) {
                        receiverList.push(mobileChatUsersViewModel.userId);
                        let unreadMessageCount = -1;
                        let onMsgCountChanges = getUnreadMessageCountChanges(mobileChatUsersViewModel.userId, conversationThread.conversationId)
                            .on('value', msgCountChanges => {
                                const UpdatesV2 = {};
                                if (unreadMessageCount === -1) {
                                    unreadMessageCount = msgCountChanges.val() + 1;
                                    UpdatesV2['/users/' + mobileChatUsersViewModel.userId + '/conversations/' + conversationThread.conversationId + '/unReadMessageCount'] = msgCountChanges.val() + 1;
                                    databseRef.ref().update(UpdatesV2);
                                }
                                databseRef.ref('users/' + mobileChatUsersViewModel.userId + '/conversations/' + conversationThread.conversationId + '/unReadMessageCount').off();
                                // databseRef.ref().off('value',onMsgCountChanges);
                                // onMsgCountChanges = undefined;
                            });
                        let unreadMessageCountAll = -1;
                        let onCommonChanges = getUnreadCommonMessageCountChanges(mobileChatUsersViewModel.userId)
                            .on('value', (commonChanges) => {
                                const UpdatesV3 = {};
                                if (unreadMessageCountAll === -1) {
                                    unreadMessageCountAll = commonChanges.val() + 1;
                                    UpdatesV3['users/' + mobileChatUsersViewModel.userId + '/unReadMessageCount'] = commonChanges.val() + 1;
                                    databseRef.ref().update(UpdatesV3);
                                }
                                databseRef.ref('users/' + mobileChatUsersViewModel.userId + '/unReadMessageCount').off();
                                // databseRef.ref().off('value',onCommonChanges);
                                // onCommonChanges = undefined;
                            });
                        Updates["users/" + mobileChatUsersViewModel.userId + "/conversations/" + conversationThread.conversationId + "/isRead"] = false;
                        Updates["users/" + mobileChatUsersViewModel.userId + "/conversations/" + conversationThread.conversationId + "/lastMessage"] = message;
                        Updates["users/" + mobileChatUsersViewModel.userId + "/conversations/" + conversationThread.conversationId + "/lastMessageSentAt"] = messageObj.sentDate;
                        let dateNow = new Date();
                        Updates["users/" + mobileChatUsersViewModel.userId + "/lastChatReceivedTime"] = dateNow.getTime() * 1000;
                        Updates["users/" + mobileChatUsersViewModel.userId + "/hasSentNotification"] = false;
                    }
                });

                conversationNotifications.userList = receiverList;

                databseRef.ref().update(Updates);
                const newKey = databseRef.ref().push().key;
                let senderUpdates = {};
                messageObj.channelId = 1;
                messageObj.receiverId = senderId;
                senderUpdates["conversations/" + conversationThread.conversationId + "/conversationMessages/" + newKey] = messageObj;
                databseRef.ref().update(senderUpdates);
                databseRef.ref('/conversations/' + conversationThread.conversationId + '/conversationUsers').off();
                databseRef.ref('/notifications').push(conversationNotifications);
            }
            // convemessage.unsubscribe();
            // convemessage = undefined;
        })
}

export const queueChatMessage = (senderId, receiverId, message, messageObj) => {
    if (!messageObj.isEmoteText) {
        let auditMessage = {};
        auditMessage.UserId = senderId;
        auditMessage.SecondaryUserId = receiverId;
        auditMessage.OptionalValue = message;
        if (messageObj.subType === 1) {
            auditMessage.MessageStatusType = 402;
            auditMessage.JobId = messageObj.subTypeId;
        }
        else {
            auditMessage.MessageStatusType = 401;
        }
        AXIOS.POST(`tenant-api/audit-chat`, auditMessage, {
            'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret }
        });
      
    }
}

export const getConversationMessageList = (conversationId) => {
    const databseRef = firebase.database();
    return databseRef.ref('/conversations/' + conversationId + '/conversationMessages');
}

export const getUpdatedChats = (userId) => {
    const databseRef = firebase.database();
    return databseRef.ref('/users/' + userId + '/conversations')
}

export const getConversationReadStatus = (loggedInUserId, conversationId) => {
    const databseRef = firebase.database();
    return databseRef.ref(`/users/${loggedInUserId}/conversations/${conversationId}/isRead`);
}

export const getConversationSubTypeStatus = (subtypeId) => {
    return AXIOS.GET(`chat/sub-type-status?jobId=${subtypeId}`);
}

export const resetConversationNotification = (model) => {
    const databseRef = firebase.database();
    let seenByuserList = {};
    databseRef.ref('conversations/' + model.conversationId + '/conversationUsers')
        .once('value', snapshot => {
            let data = Object.values(snapshot.toJSON());
            let findx = _.findIndex(data, _data => {
                return _data['userId'] == model.loggedInUserId
            });

            if (findx >= 0) {
                const Updates = {};
                //Updates["/users/"+model.LoggedInUserId+"/unreadChats"] = false;
                Updates["/users/" + model.loggedInUserId + "/unReadMessageCount"] = 0;
                Updates["/users/" + model.loggedInUserId + "/conversations/" + model.conversationId + "/unReadMessageCount"] = 0;
                Updates["/users/" + model.loggedInUserId + "/conversations/" + model.conversationId + "/isRead"] = true;

                databseRef.ref().update(Updates);

                getConversationMessageList(model.conversationId)
                    .once('value', conversationMessages => {
                        const seenbyUpdates = {};

                        seenByuserList = {
                            firstName: model.loggedUserFirstName,
                            userImage: model.senderImageUrl === undefined?"":model.senderImageUrl,
                            userId: model.loggedInUserId,
                            seenTime: moment(new Date().toUTCString().split(' GMT')[0]).format('DD/MM/YYYY HH:mm:ss'),
                            doNotShowSeenByForSP: false
                        };

                        //let conversationMessagesList = Object.values(conversationMessages.toJSON());

                        conversationMessages.forEach(res => {
                            let element = res.val();
                            const childKey = res.key;

                            if (element['senderId'] !== model.loggedInUserId) {

                                let seenByTimeAdded = false;

                                if (element['seenBy'] !== undefined) {
                                    let seenByList = Object.keys(element['seenBy']).map(key => ({ type: key, value: element['seenBy'][key] }));
                                    for (var j = 0; j < seenByList.length; j++) {
                                        if (seenByList[j].value.userId == model.loggedInUserId) {
                                            seenByTimeAdded = true;
                                        }
                                    }
                                } else {
                                    element['seenBy'] = [];
                                }

                                if (!seenByTimeAdded && element['seenBy'] !== undefined) {
                                    const newKey = databseRef.ref().push().key;
                                    seenbyUpdates["/conversations/" + model.conversationId + "/conversationMessages/" + childKey + "/seenBy/" + newKey] = seenByuserList;

                                }

                            }
                        });

                        let seenbyUpdatesList = Object.keys(seenbyUpdates).map(key => ({ type: key, value: seenbyUpdates[key] }));
                        if (seenbyUpdatesList.length > 0) {
                            databseRef.ref().update(seenbyUpdates);
                        }
                    });
            }
        })
}

const chatService = {
    updateUserActiveStatus,
    getUpdatedChats,
    getConversationById,
    getConversationIdByUserWithOrderByIsRead,
    getConversationIdByUser,
    resetConversationData,
    getConversationByIdChunk,
    getConversationChanges,
    resetConversationMessages,
    getConversationUsersByConversationId,
    getUnreadMessageCountChanges,
    getUnreadCommonMessageCountChanges,
    sendChatMessage,
    resetConversationNotification,
    createPrivateConversation,
    geActivationStatus,
    getConversationReadStatus,
    getConversationSubTypeStatus
}

export default chatService;


import React from 'react';

class ExternalTimeline extends React.Component {
    render() {
        return <div className="external-timeline">
            <div className="row">
                <div className="col-md-12">
                    <div className="form-check form-check-inline">
                        <div className="tl-item-block">
                            <div className="tl-details w-100 tl-detailsbg">
                                <div className="recent-activity-icons">
                                    <img _ngcontent-c0="" alt="" src="/images/recent-activity/job-icon.svg" />
                                </div>
                                <div className="w-100">
                                    <h3>Job Completed</h3>
                                    <div className="pb-3">
                                        <div className="tl-chats">
                                            <div className="tl-chat-details">
                                                <p>By: pm Muzain</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="tl-date-time">24/07/2019 12:10:54</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-check form-check-inline">
                        <div className="tl-item-block">
                            <div className="tl-details w-100 tl-detailsbg">
                                <div className="recent-activity-icons">
                                    <img _ngcontent-c0="" alt="" src="/images/recent-activity/job-icon.svg" />
                                </div>
                                <div className="w-100">
                                    <h3>Job Completed</h3>
                                    <div className="pb-3">
                                        <div className="tl-chats">
                                            <div className="tl-chat-details">
                                                <p>By: pm Muzain</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="tl-date-time">24/07/2019 12:10:54</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-check form-check-inline">
                        <div className="tl-item-block">
                            <div className="tl-details w-100 tl-detailsbg">
                                <div className="recent-activity-icons">
                                    <img _ngcontent-c0="" alt="" src="/images/recent-activity/job-icon.svg" />
                                </div>
                                <div className="w-100">
                                    <h3>Job Completed</h3>
                                    <div className="pb-3">
                                        <div className="tl-chats">
                                            <div className="tl-chat-details">
                                                <p>By: pm Muzain</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="tl-date-time">24/07/2019 12:10:54</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ExternalTimeline;
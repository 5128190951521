import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { connect } from "react-redux";
import ExternalJobDetails from '../External/JobDetails/ExternalJobDetails';
import ExternalTimeline from '../External/Timeline/ExternalTimeline';
import MenuBar from '../External/MenuBar/MenuBar';
import './ExternalUserLayout.scss'
import jobService from '../../Services/JobService';
import ExternalEditJob from '../External/EditJob/ExternalEditJob';
import { AppConfiguration } from "read-appsettings-json";
import ExternalJobApprove from '../External/OwnerApproval/ExternalJobApprove';
import ExternalJobReject from '../External/OwnerReject/ExternalJobReject';
import ExternalOwnerAttend from '../External/OwnerAttend/ExternalOwnerAttend';
import JobNotes from '../External/JobNote/JobNotes';
import QuotesDetails from '../External/QuotesDetail/QuotesDetails';
import JobPostWizard from '../Common/JobPostWizard/JobPostWizard';
import JobEditWizard from '../Common/JobEditWizard/JobEditWizard';
import settingsService from '../../Services/settingsService';
import { AXIOS } from "../../Utilities/HttpRequest/Axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trackPromise } from 'react-promise-tracker';
import Loader from "react-loader-spinner";
import { withTranslation } from 'react-i18next';
import ScheduleJobDrawer from "../Drawers/ScheduleJobDrawer/ScheduleJobDrawer";
import OwnerApproveRejectDrawer from '../External/OwnerApproveRejectDrawer/OwnerApproveRejectDrawer';
import OwnerApproveDrawer from '../External/OwnerApproveRejectDrawer/OwnerApproveDrawer';
import OwnerRejectDrawer from '../External/OwnerApproveRejectDrawer/OwnerRejectDrawer';
import { bool } from 'prop-types';
import ToolTipLayout from './ToolTipLayout';

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;


class ExternalUserLayout extends React.Component {

    state = {
        isLoadingTimeline: false,
        timelineData: [],
        inValidStatus: [8, 103, 110, 104, 105, 106, 109, 206],
        completedStatus: [7, 12, 107],
        userId: "",
        isJobPosting: false,
        isJobEditing: false,
        isJobApprovedOrRejected: false,
        showScheduleJob: false,
        showApproveRejectDrawer: false,
        showApproveDrawer: false,
        showRejectDrawer: false
    }

    componentDidMount() {
        // this.getJobTimelineByJobId();
        if (this.props && this.props.location.search.indexOf('userId') > -1) {
            //Owner variation approval
            const params = new URLSearchParams(this.props.location.search);
            let id = params.get('userId');
            console.log(id);
            localStorage.setItem("exernalUserId", id);
            this.setState({ userId: id });
            this.getOwnerApprovalStatus(window.jobId, id);
        } else {
            localStorage.setItem("exernalUserId", "");
        }
    }

    getJobTimelineByJobId = () => {
        const jobId = window.jobId;
        this.setState({ isLoadingTimeline: true });
        if (jobId) {
            jobService.GetJobRelatedTimelineForExternalUsers(jobId).then(result => {
                this.setState({
                    isLoadingTimeline: false,
                    timelineData: result
                });
            }, error => {
                console.log(error);
            })
        }
    }

    getOwnerApprovalStatus = (jobId, userId) => {
        this.setState({ isLoadingTimeline: true });
        if (jobId && userId) {
            jobService.GetOwnerApprovalStatus(jobId, userId).then(result => {

                this.setState({
                    isLoadingTimeline: false,
                    ownerApprovalStatus: result.status,
                    urlExpired: result.isExpired
                });
                if (result.isExpired) {
                    toast.error(`Url has been expired !`, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }, error => {
                console.log(error);
            });
        }
    }

    dayDifference = (date1, date2) => {
        date1 = new Date(date1.toDateString());
        date2 = new Date(date2.toDateString());
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var diffDays = Math.ceil(timeDiff / MILLISECONDS_PER_DAY);
        return diffDays;
    }

    onSubmitJobDetails = () => {
        const { jobDetails } = this.props;

        if (jobDetails.attachments.length == 0 && jobDetails.isAttachmentMandatory) {

            toast.error(`You are required to attach an image  related to the maintenance request.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return;
        }

        jobDetails.jobValidityPeriod = 1;
        jobDetails.serviceConsumerId = localStorage.getItem('userId');

        jobDetails.workflowStatusId = 2;
        jobDetails.isActive = true;

        this.setState({ isJobPosting: true })
        try {
            trackPromise(
                AXIOS.POST(`tenant-api/external-job-post`, jobDetails, {
                    'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret }
                })
                    .then(dataJson => {
                        this.setState({ isJobPosting: false })
                        const message = 'posted'
                        if (dataJson == 'Request Unauthorized' || dataJson == 'Job post failed') {
                            toast.error(`Job is not ${message} successfully. Please refresh link and try again.`, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        } else {
                            toast.success(`Job is ${message} successfully.`, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        }
                    }, error => {
                        console.log(error);
                        this.setState({ isJobPosting: false });
                        toast.error(`Failed to post the job. Please try again.`, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
            )

        } catch (error) {
            console.log(error);
        }

    }
    reloadPage = ()=>
    {
        this.props.reloadPage();
    }

    toggleApproveRejectDrawer() {
        this.setState({ showApproveRejectDrawer: !this.state.showApproveRejectDrawer })
    }
    toggleApproveDrawer() {
        this.setState({ showApproveDrawer: !this.state.showApproveDrawer })
    }
    toggleRejectDrawer() {
        this.setState({ showRejectDrawer: !this.state.showRejectDrawer })
    }

    render() {
        const { match, jobDetails, isCurrentTenant, theme, isCustomThemeEnable } = this.props;
        const { t } = this.props;
        const style = {
            isCustomThemeEnable: isCustomThemeEnable,
            brand: theme.brandColor,
            brandBackground: theme.brandColor + "33",
            textColor: theme.textColor,
            primaryButtonStyle: {
                color: theme.primaryButtonTextColor,
                backgroundColor: theme.primaryButtonBackground,
                border: "1px solid " + theme.primaryButtonBackground
            },
            secondaryButtonStyle: {
                color: theme.primaryButtonBackground,
                backgroundColor: theme.primaryButtonBackground + "33",
                border: "1px solid " + theme.primaryButtonBackground
            },
            optionsStyle: {
                border: "1px solid " + theme.brandColor,
                color: theme.brandColor,
                activeBackground: theme.brandColor + "33"
            }
        }
        return (

            <div id="wrapper" className="container">
                <div className="notify-bell">
                    <Link to={`/download?code=${window.jobId}`}>
                        <i className="ba-icn ba-icn-bell"></i>
                    </Link>
                </div>
                {(jobDetails != null && this.state.inValidStatus.indexOf(jobDetails.workflowStatusId) > -1) ?
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <div id="notfound">
                                    <div className="notfound">
                                        <div className="notfound-404">
                                            <h2 className="sub-title">Url has been expired or invalid!</h2>
                                        </div>
                                        <a className="btn btn-primary" href={`${AppConfiguration.Setting().BAndAApiEndPoint}`}>Go TO Login</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> :
                    (jobDetails != null && this.state.completedStatus.indexOf(jobDetails.workflowStatusId) > -1 && this.dayDifference(new Date(jobDetails.lastUpdatedDateTime), new Date()) > 5 || this.state.urlExpired) ?
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <div id="notfound">
                                        <div className="notfound">
                                            <div className="notfound-404">
                                                <h2 className="sub-title">Url has been expired!</h2>
                                            </div>
                                            <a className="btn btn-primary" href={`${AppConfiguration.Setting().BAndAApiEndPoint}`}>Go TO Login</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> :

                        (jobDetails != null && !isCurrentTenant && jobDetails.isUserTenant && jobDetails.propertyId != null && jobDetails.workflowStatusId != 6) ?
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div id="notfound">
                                            <div className="notfound">
                                                <div className="notfound-404">
                                                    <h2 className="sub-title">Only current tenants are allow to view or edit job details!</h2>
                                                </div>
                                                <a className="btn btn-primary" href={`${AppConfiguration.Setting().BAndAApiEndPoint}`}>Go TO Login</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :
                            (jobDetails != null && !isCurrentTenant && jobDetails.isUserTenant && jobDetails.propertyId != null && jobDetails.workflowStatusId != 6) ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div id="notfound">
                                                <div className="notfound">
                                                    <div className="notfound-404">
                                                        <h2 className="sub-title">Only current tenants are allow to view or edit job details!</h2>
                                                    </div>
                                                    <a className="btn btn-primary" href={`${AppConfiguration.Setting().BAndAApiEndPoint}`}>Go TO Login</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <nav className="navbar navbar-light px-0 py-4">
                                        <div className='d-flex flex-column flex-lg-row align-items-center w-100'>
                                            <div className='col-12 col-lg-4 text-center text-lg-left'>
                                                <a className="navbar-brand mr-0">
                                                    {(isCustomThemeEnable) ? <img src={theme.logoUrl} alt="Bricks + Agent" className="logo" /> : <img src="/images/app-logo.svg" alt="Bricks + Agent" className="logo" />}
                                                </a>
                                            </div>
                                            <div className='col-12 col-lg-4 text-center'>
                                                <h5 className="mt-2 mt-lg-0 font-500">{t('title_maintanance_request')}</h5>
                                            </div>
                                        </div>
                                        {/* {(jobDetails != null && (jobDetails.workflowStatusId == 1)) ? <Link className="edit-btn my-sm-0" to={`${match.path}/edit-job?code=${window.jobId}`}>
                                    <i className="ba-icn ba-icn-edit"></i>
                            Edit
                        </Link> : (jobDetails != null  && (jobDetails.workflowStatusId == 6 && this.state.ownerApprovalStatus==0))?<div className="float-right"><Link className="approve-btn my-sm-0 mr-2 color-success" to={`${match.path}/ownerApprove?code=${window.jobId}&approve=true`}>           
                            Approve
                        </Link> <Link className="reject-btn my-sm-0 mr-2" to={`${match.path}/ownerReject?code=${window.jobId}&approve=false`}>
                            Reject
                        </Link></div>:''} */}
                                    </nav>
                                    <div className="card external-card">
                                        <div className="card-header menu-bar">
                                            <MenuBar {...this.props} customStyle={style}></MenuBar>
                                        </div>
                                        <div className="card-body">
                                            <Route
                                                path={`${match.path}/overview`}
                                                render={routerProps => <ExternalJobDetails {...routerProps} customStyle={style} />}
                                            />
                                            <Route
                                                path={`${match.path}/timeline`}
                                                render={routerProps => <ExternalTimeline {...routerProps} {...this.state} />}
                                            />
                                            {/* <Route
                                        path={`${match.path}/edit-job`}
                                        render={routerProps => <ExternalEditJob {...routerProps} />}
                                    /> */}
                                            <Route
                                                path={`${match.path}/edit-job`}
                                                render={routerProps => <JobEditWizard
                                                    customStyle={style}
                                                    backtoList={(event) => this.backToList(event)}
                                                    isJobEdit={true} jobDetail={jobDetails}
                                                    {...routerProps}
                                                    reloadPage={()=>this.reloadPage()} />}
                                            />
                                            <Route
                                                path={`${match.path}/ownerApprove`}
                                                render={routerProps => <ExternalJobApprove {...routerProps}
                                                    jobDetails={this.props.jobDetails}
                                                    userId={this.state.userId}
                                                    onJobApproveOrReject={() => this.onJobApproveOrReject()}
                                                />}
                                            />
                                            <Route
                                                path={`${match.path}/ownerReject`}
                                                render={routerProps => <ExternalJobReject {...routerProps}
                                                    jobDetails={this.props.jobDetails}
                                                    userId={this.state.userId}
                                                    onJobApproveOrReject={() => this.onJobApproveOrReject()}
                                                />}
                                            />
                                            <Route
                                                path={`${match.path}/ownerAttend`}
                                                render={routerProps => <ExternalOwnerAttend {...routerProps}
                                                    jobDetails={this.props.jobDetails}
                                                    userId={this.state.userId}
                                                    onJobApproveOrReject={() => this.onJobApproveOrReject()}
                                                />}
                                            />
                                            <Route
                                                path={`${match.path}/job-notes`}
                                                render={routerProps => <JobNotes {...routerProps}
                                                    jobDetails={this.props.jobDetails}
                                                    userId={this.state.userId}
                                                />}
                                            />
                                            <Route
                                                path={`${match.path}/job-quotes`}
                                                render={routerProps => <QuotesDetails {...routerProps}
                                                    jobDetails={this.props.jobDetails}
                                                    userId={this.state.userId}
                                                    reload={() => this.reload()}
                                                />}
                                            />
                                            <nav className="navbar navbar-light justify-content-end px-0 pt-0 pb-4 ext-card-actions mt-3">

                                                {((jobDetails != null && jobDetails.workflowStatusId == 1) || (jobDetails != null && localStorage.getItem('sendemailfortenattogetmoreinfo') == "true")) ? <div>
                                                    {(this.props.location && this.props.location.pathname === '/external/overview') ?
                                                        <Link className="edit-button my-sm-0"
                                                            to={`${match.path}/edit-job?code=${window.jobId}&lng=${window.lng}`}
                                                            style={{
                                                                color: (style.primaryButtonStyle.color) ? style.primaryButtonStyle.color : "", backgroundColor: (style.primaryButtonStyle.backgroundColor) ? style.primaryButtonStyle.backgroundColor : ""
                                                            }}
                                                        >
                                                            <i className="ba-icn ba-icn-edit"></i>
                                                            {t('btn_edit')}
                                                        </Link>
                                                        : <></>}
                                                    {(this.props.location && this.props.location.pathname !== '/external/edit-job') ?
                                                        <button className="post-button with-left-margin my-sm-0" onClick={() => this.onSubmitJobDetails()} style={{ color: (style.primaryButtonStyle.color) ? style.primaryButtonStyle.color : "", backgroundColor: (style.primaryButtonStyle.backgroundColor) ? style.primaryButtonStyle.backgroundColor : "" }}>
                                                            {t('btn_post')}
                                                            {(this.state.isJobPosting) ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={60} /> : ''}
                                                        </button>
                                                        : <></>}
                                                </div> :
                                                    (jobDetails != null
                                                        && (jobDetails.workflowStatusId == 6 && (this.state.ownerApprovalStatus == 0 || this.state.ownerApprovalStatus == 6))
                                                        && !this.state.isJobApprovedOrRejected
                                                    ) ?
                                                        < div className={(this.props.location.pathname === '/external/ownerApprove' || this.props.location.pathname === '/external/ownerReject' || this.props.location.pathname === '/external/ownerAttend' || this.props.location.pathname === '/external/job-notes' || this.props.location.pathname === '/external/job-quotes') ? "d-none" : "d-flex col-12 w-100 flex-wrap align-items-center px-0"} >
                                                            {(jobDetails.workflowStatusId === 6 && (jobDetails.bidCount === 0 || (jobDetails.isOwnerApprovalPendingForVariation && jobDetails.currentVariation))) ?
                                                                <div className='col-12 mb-3 pl-0 pr-0'>
                                                                    <p className="confident-lbl confident-low w-100 mb-0"> If you want more information or have any questions, please reply to the email that you received which will go directly to your property manager.</p>
                                                                </div> : ''
                                                            }
                                                            <div className="col-12 mt-4 mb-3 pl-0 pr-0 text-right">
                                                                {(jobDetails.isPercentageReached) ? '' :
                                                                    //<ToolTipLayout ToolTipMessage="You can assign this job to yourself." >
                                                                    //    <Link className="edit-btn trkng-will-atnd my-sm-0 mr-2" to={`${match.path}/ownerAttend?code=${window.jobId}&userId=${this.state.userId}&approve=true`}> I will Attend</Link>
                                                                    //</ToolTipLayout>
                                                                    <>
                                                                        <button className="edit-btn trkng-will-atnd my-sm-0 mr-2" onClick={this.toggleApproveRejectDrawer.bind(this)}> I will Attend</button>
                                                                        {(this.state.showApproveRejectDrawer) ?
                                                                        <OwnerApproveRejectDrawer
                                                                            showOwnerApproveRejectDrawer={this.state.showApproveRejectDrawer}
                                                                            jobDetails={this.props.jobDetails}
                                                                            userId={this.state.userId}
                                                                            onJobApproveOrReject={() => this.onJobApproveOrReject()}
                                                                            toggleOwnerApproveRejectDrawer={this.toggleApproveRejectDrawer.bind(this)} >
                                                                        </OwnerApproveRejectDrawer>
                                                                        : ''
                                                                        }
                                                                    </>
                                                                }
                                                                {(jobDetails.workflowStatusId === 6 && (jobDetails.bidCount === 0 || (jobDetails.isOwnerApprovalPendingForVariation && jobDetails.currentVariation))) ?
                                                                    //<ToolTipLayout ToolTipMessage="You can approve this job." >
                                                                    //    <Link className="approve-btn my-sm-0 mr-2 color-success trkng-apprv-btn" to={`${match.path}/ownerApprove?code=${window.jobId}&userId=${this.state.userId}&approve=true`}> Approve </Link>
                                                                    //</ToolTipLayout>

                                                                    //<ToolTipLayout ToolTipMessage="You can reject this job." >
                                                                    //    <Link className="reject-btn my-sm-0 mr-0 trkng-rjct-btn" to={`${match.path}/ownerReject?code=${window.jobId}&userId=${this.state.userId}&approve=false`}> Reject </Link>
                                                                    //</ToolTipLayout>
                                                                    <>
                                                                        <button className="approve-btn my-sm-0 mr-2 color-success trkng-apprv-btn" onClick={this.toggleApproveDrawer.bind(this)}> Approve </button>
                                                                        {(this.state.showApproveDrawer) ?
                                                                            <OwnerApproveDrawer
                                                                                showOwnerApproveDrawer={this.state.showApproveDrawer}
                                                                                jobDetails={this.props.jobDetails}
                                                                                userId={this.state.userId}
                                                                                onJobApproveOrReject={() => this.onJobApproveOrReject()}
                                                                                toggleOwnerApproveDrawer={this.toggleApproveDrawer.bind(this)} >
                                                                            </OwnerApproveDrawer>
                                                                            : ''
                                                                        }
                                                                        <button className="reject-btn my-sm-0 mr-0 trkng-rjct-btn" onClick={this.toggleRejectDrawer.bind(this)}> Reject </button>
                                                                        {(this.state.showRejectDrawer) ?
                                                                            <OwnerRejectDrawer
                                                                                showOwnerRejectDrawer={this.state.showRejectDrawer}
                                                                                jobDetails={this.props.jobDetails}
                                                                                userId={this.state.userId}
                                                                                onJobApproveOrReject={() => this.onJobApproveOrReject()}
                                                                                toggleOwnerRejectDrawer={this.toggleRejectDrawer.bind(this)} >
                                                                            </OwnerRejectDrawer>
                                                                            : ''
                                                                        }
                                                                    </>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div> : ''}
                                                {(jobDetails != null && (jobDetails.workflowStatusId == 4) && jobDetails.scheduleEventId != null) ? <div>
                                                    {(jobDetails.scheduleEventStatus !== 2) ? <>
                                                        <button className="approve-btn my-sm-0 mr-2 color-success" onClick={() => this.onAcceptSchedule()}> Approve </button>
                                                        <button className="reject-btn my-sm-0 mr-2" onClick={() => this.onRejectSchedule()}> Reject </button>
                                                    </> : ''}
                                                    <button className="approve-btn my-sm-0 mr-2 color-success" onClick={() => this.toggleSheduleJobDrawer()}> Re-schedule Job</button>
                                                    {(this.state.showScheduleJob) ?
                                                        <ScheduleJobDrawer
                                                            isExternal={true}
                                                            showScheduleJobDrawer={this.state.showScheduleJob}
                                                            jobDetails={jobDetails}
                                                            toggleSheduleJobDrawer={() => this.toggleSheduleJobDrawer()}
                                                            getJobDetails={(event) => { window.location.reload() }}
                                                            CloseJobDetailsDrawer={(event) => window.location.reload()}>
                                                        </ScheduleJobDrawer> : ''
                                                    }
                                                </div> : ''}
                                            </nav>
                                        </div>
                                    </div>
                                </>}
            </div>
        )
    }

    onAcceptSchedule = () => {
        let obj = {};

        obj.JobId = this.props.jobDetails.id;

        obj.id = this.props.jobDetails.scheduleEventId;
        obj.bidId = this.props.jobDetails.acceptedBidId;
        obj.type = 0;
        obj.status = 2;
        obj.userId = this.props.jobDetails.serviceConsumerId;
        try {
            AXIOS.POST(`tenant-api/save-external-schedule-job`, obj).then(response => {
                if (response) {
                    toast.success('Job Schedule Accepted', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    window.location.reload();
                }
                else {
                    toast.error('Job Schedule Acceptance Failed', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }

    }

    onRejectSchedule = () => {

        let obj = {};

        obj.JobId = this.props.jobDetails.id;

        obj.id = this.props.jobDetails.scheduleEventId;
        obj.bidId = this.props.jobDetails.acceptedBidId;
        obj.type = 0;
        obj.status = 3;
        obj.userId = this.props.jobDetails.serviceConsumerId;

        try {
            AXIOS.POST(`tenant-api/save-external-schedule-job`, obj).then(response => {
                if (response) {
                    toast.success('Job Schedule Rejected', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    window.location.reload();
                }
                else {
                    toast.error('Job Schedule Rejection Failed', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    toggleSheduleJobDrawer = () => {
        this.setState({
            showScheduleJob: !this.state.showScheduleJob
        });
    }

    onJobApproveOrReject = () => {
        this.setState({
            isJobApprovedOrRejected: true
        })
    }
    backToList = (status) => {
        this.setState({
            showJobPostDrawer: false,
        });
    }

    onOwnerAttend = () => {

        var callbackUrl = AppConfiguration.Setting().BAndAApiEndPoint;
        try {
            AXIOS.GET(`tenant-api/owner-attending-job?ownerId=${this.state.userId}&jobId=${window.jobId}&callbackUrl=${callbackUrl}&note=${""}`).then(response => {
                if (response) {
                    toast.success('Saved as Owner will attend to the job', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.setState({
                        isJobApprovedOrRejected: true
                    });
                }
                else {
                    toast.error('Failed to save', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    reload = () => {

    }
}


const mapStateToProps = (state, ownProps) => {
    try {
        let userDetails = state.User;

        return {
            userDetails: userDetails,
            profileThumbnailsCdnEndpoint: state.AppSettings.imageCdnEndpoint,
            jobDetails: state.Jobs.jobDetails,
            isCurrentTenant: state.Tenant.isCurrentTenant
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateUserDetails: details => { dispatch({ type: "SET_USER_DETAILS", userDetails: details }) },
        updateIsTenantField: isCurrentTenant => { dispatch({ type: "CHECK_CURRENT_TENANT", isCurrentTenant: isCurrentTenant }) },
        setJobDetails: jobDetails => { dispatch({ type: 'SET_JOB_DETAILS', jobDetails: jobDetails }) },
        setJobDetailsLoadingStatus: status => { dispatch({ type: 'SET_JOB_DETAILS_LOADING_STATUS', status: status }) },
        addJobTypes: jobTypes => { dispatch({ type: 'SET_JOB_TYPES', jobTypes: jobTypes }) }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(withTranslation()(ExternalUserLayout));
import axios from "axios";
import { AppConfiguration } from "read-appsettings-json";

const apiendpoint = AppConfiguration.Setting().Translate_API;
const allowDynamicTranslate = AppConfiguration.Setting().AllowDynamicTranslate;

const translate = (requestModel) => {
  delete axios.defaults.headers.get["ClientSecret"];
  delete axios.defaults.headers.post["ClientSecret"];

  const promise = new Promise((resolve, reject) => {
    if (requestModel.targetCode != requestModel.sourceCode && allowDynamicTranslate) {
      return axios.post(apiendpoint, requestModel, null).then((res) => {
        resolve(res.data);
      });
    } else resolve([]);
  });
  return promise;
};

const translateService = {
  translate,
};

export default translateService;

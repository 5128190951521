import React from 'react';
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ImageViewer from '../../../Common/ImageViewer/ImageViewer';
import { AXIOS } from "../../../../Utilities/HttpRequest/Axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import settingsService from '../../../../Services/settingsService';
import jobService from '../../../../Services/JobService';
import { Guid } from 'guid-typescript';
import DatePicker from "react-datepicker";
import { Search, Label, Button, Modal, Dropdown } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import * as _ from 'lodash';
import 'semantic-ui-css/semantic.min.css'
import './BotJobPost_Wizard.scss';
import propertyService from '../../../../Services/PropertyService';
import FrequentlyUsedServices from '../../../Drawers/JobPostDrawer/FrequentlyUsedServices/FrequentlyUsedServices';
import AttachmentsDrawer from '../../../Drawers/AttachmentsDrawer/AttachmentsDrawer';
import Disclaimer from '../../../Drawers/JobPostDrawer/Disclaimer/Disclaimer';
import { AppConfiguration } from 'read-appsettings-json';
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import translateService from '../../../../Services/TranslateService';
import { string } from 'prop-types';
import { any } from 'prop-types';

class BotJobPostWizard extends React.Component {

    constructor(props) {
        super(props);
        this.title = React.createRef();
        this.jobDescription = React.createRef();
        this.budgetRef = React.createRef();
        this.issueAreaInProperty = React.createRef();
    }
    notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        if (this.props.isFromBot) {
            this.props.showChatbotError(msg);
        }
    }

    state = {
        userType: localStorage.getItem("userType"),
        userId: localStorage.getItem("userId"),
        stepFlow: [[0, "selectProperty"], [1, "getMaintenaceIssue"], [2, "getMaintenaceTroubleShootGuide"],[3, "getAttachments"], [4, "selectPrePostSummary"], [5, "setResponse"], [6, "setTroubleShooteResponse"]],
        isJobEdit: (this.props.isJobEdit) ? true : false,
        jobEditId: (this.props.jobEditId) ? this.props.jobEditId : "",
        currentStep: 0,
        isConsumerJobPost: false,
        propertySearchData: [],
        formData: {
            address: '',
            location: '',
            propertyId: '',
            propertyName: '',
            suburbId: '',
            propertyType: '',
            jobBudget: 0,
            jobDescription: '',
            jobDescriptionEng: '',
            issueAreaInProperty: '',
            issueAreaInPropertyEng: '',
        },
        availableServices: [],
        availableServicesDetails: [],
        selectedService: { childJobTypes: [] },
        selectedServiceType: {
            parentJobType: { id: '' }
        },
        selectedSubServiceType: {},
        jobDetails: { attachments: [] },
        attachments: [],
        jobId: '',
        dueDate: '',
        formattedDueDate: '',
        jobUrgencyList: [
            {
                value: this.props.t('editjob_lbl13'),
                jobUrgency: -1
            },
            {
                value: this.props.t('editjob_lbl11'),
                jobUrgency: 1
            },
            {
                value: this.props.t('editjob_lbl12'),
                jobUrgency: 2
            }
        ],
        jobUrgency: 2,
        jobUrgencyCheckbox: false,
        propertyList: [],
        value: '',
        isLoading: false,
        filteredResults: [],
        propertyId: '',
        isPropertiesLoading: false,
        parentJobTypes: [],
        primaryJobTypes: [],
        secondaryJobTypes: [],
        frequentlyUsedServices: [],
        isShowMoreServices: false,
        locationDetails: {
            latitude: '',
            longitude: '',
            placeId: '',
            suburbId: '',
            location: ''
        },
        isMandatoryToUploadFiles: false,
        isJobForMe: false,
        jobBudget: "",
        jobForMe: {},
        selectedTradees: [],
        isSelectedOnDemand: false,
        selectedQuoteLimit: 0,
        inviteToProviders: false,
        isJobPosting: false,
        isRestrictKeyCollectionForResidentialProperties: false,
        showDisclaimerDrawer: false,
        showBackToHomeConfirmation: false,
        jobPostWorkflowStatus: 1,
        propertyLocks: [],
        isJobForMeText: '',
        isSubServiceTypeSelected: false,
        isShowAttchmentDrawer: false,
        parentId: '',
        childJobTypeId: '',
        subChildJobTypeId: '',
        jobNumber: '',
        jobOverViewUrl: '',
        isPropertyOwner: (localStorage.userType == 5) ? true : false,
        isShowInviteTradeDrawer: false,
        budgetAmount: 0,
        isPropertySelected: false,
        title: '',
        isLoadingTroubleshootingGuide: false,
        troubleShootingGuideDetails: {
            troubleshootGuideId: '',
            troubleshootGuide: '',
            aiPoweredTroubleshootGuide: [],
            primaryJobTypeId: '',
            secondaryJobTypeId: ''
        },
        isAIPoweredTroubleshootRequested: false,
        isAlternativeAITroubleshootGuideRequested: false,
        aiPoweredTroubleshootResultsEmpty: false,
        isSkipTroubleshootGuideLoading: false,
        primaryButtonStyle: (this.props.customStyle && this.props.customStyle.primaryButtonStyle) ? this.props.customStyle.primaryButtonStyle : {
            color: ''
        },
        secondaryButtonStyle: (this.props.customStyle && this.props.customStyle.secondaryButtonStyle) ? this.props.customStyle.secondaryButtonStyle : {
            color: ''
        },
        optionsStyle: (this.props.customStyle && this.props.customStyle.optionsStyle) ? this.props.customStyle.optionsStyle : {
            color: ''
        },
        urgencyLabelStyle: {
            "display": "inline-block"
        },
        urgencyCheckboxStyle: {
            "height": "20px",
            "width": "60px"
        },
        isFromBot: (this.props.isFromBot) ? true : false,
        isTrackIssue: (this.props.isTrackIssue) ? true : false,
        isMaintatanceIssueProvided: false,
        isJobPostOrTroubleshootGuideSelected: false,
        isDraftJob: false,
        tradeeCount: 0,
        jobTypeTags: [],
        isLoadingJobTypeTags: false,
        jobAssignTags: [],
        selectedComponent: '',
        selectedGoogleImage: [],
        selectedFeatureComponent: [],
        previousStep: 0,
        chatbotSetting: this.props.chatbotSetting ? this.props.chatbotSetting : {
            hideQandABot: false,
            hideCheckRentalStatus: false,
            hideKeyCollectionOptions: false,
            hideTroubleshootGuide: false,
            enableAIPoweredTroubleshootGuide: false,
            enableDraftJobFeature: false
        },
        maintenanceTroubleshootGuideId: null,
        propertyType: "Residential",
        actionValue: 0
    }

    componentDidMount() {
        var normalFlow = [[0, "selectProperty"], [1, "getMaintenaceIssue"], [2, "getMaintenaceTroubleShootGuide"], [3, "selectServiceType"], [4, "selectUgency"], [5, "selectKeyCollectionType"], [6, "selectBudget"], [7, "getAttachments"], [8, "getTrades"], [9, "selectSubmitOption"], [10, "setResponse"], [11, "setTroubleShooteResponse"]];
        setTimeout(() => {
            this.GetTenantPropertiesByUserId();
        }, 1000);
        this.getAvailableServices();
        this.GetFrequentlyUsedJobTypes();
        this.setState({
            jobId: Guid.create().toString(),
            jobDetails: {
                id: this.state.jobId
            }
        });
        this.getAllAvailableJobTypes();
        if (localStorage.userType == 5) {
            this.setState({
                isJobForMe: true
            })
        }
        if (this.state.chatbotSetting.hideKeyCollectionOptions) {
            this.setState({
                keyCollectType: this.state.chatbotSetting.defaultKeyCollectionOption
            });
        }
    }

    componentDidUpdate() {
        if (this.props.isCustomThemeEnable) {
            this.createToggleBtnDynamicStyle();
            this.createOkBtnDynamicStyle();
            this.createActiveBtnDynamicStyle();
            this.attchmentBarStyle();
        }
    }

    applyTranslation(texts, sourceLng, targetLng) {
        const requestModel = {
            text: texts,
            targetCode: (targetLng == "en-US" || targetLng =="en-GB")  ? "en" : targetLng,
            sourceCode: sourceLng
        };

        return translateService.translate(requestModel);
    }

    createToggleBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        const border = this.props.customStyle.optionsStyle.border;
        let labelInsideToggleGroup = document.querySelectorAll('label.btn');

        for (var i = 0; i < labelInsideToggleGroup.length; i++) {
            labelInsideToggleGroup[i].style.color = color;
            labelInsideToggleGroup[i].style.border = border;
            labelInsideToggleGroup[i].style.background = '';
        }
    }
    createOkBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        let okIcons = document.querySelectorAll('span.ba-icn-ok');

        for (var i = 0; i < okIcons.length; i++) {
            okIcons[i].style.background = color;
        }
    }
    createActiveBtnDynamicStyle() {
        const color = this.props.customStyle.optionsStyle.activeBackground;
        let active = document.querySelectorAll('label.btn.active');
        for (var i = 0; i < active.length; i++) {
            active[i].style.background = color;
        }
    }
    attchmentBarStyle() {
        const color = this.props.customStyle.optionsStyle.color;
        const border = this.props.customStyle.optionsStyle.border;
        let label = document.querySelector('.rich-control');
        if (label) {
            label.querySelector('label').style.color = color;
        }
        let boader = document.querySelector('div.form-control.rich-control');
        if (boader) {
            boader.style.border = border;
        }
    }

    //get data

    getJobDetailsByJobId = jobId => {
        const userId = localStorage.getItem("userId");
        this.setState({ isJobDetailsLoading: true })
        if (jobId) {
            try {
                AXIOS.GET(`tenant-api/job?jobId=${jobId}&userId=${userId}`).then(
                    data => {
                        //need to set jobdetails to  state
                        this.setState({});
                    }
                )
            } catch (error) {
                console.log(error);
            }
        }
    };


    getOrganizationSettings(organizationId) {
        settingsService.getOrganizationSettingsByOrgId(organizationId)
            .then((res) => {
                this.setState({
                    isMandatoryToUploadFiles: res.organizationSettingsModel.allowTenantsToImageAttachmentFeatureForTheJobposting,
                    isRestrictKeyCollectionForResidentialProperties: res.organizationSettingsModel.isRestrictKeyCollectionForResidentialProperties,
                    allowTenantsToSeeMatchingTrades: res.organizationSettingsModel.allowTenantsToSeeMatchingTrades,
                    isEnableSuggestingImage: res.organizationSettingsModel.isEnableSuggestingImage,
                    isEnableIssueTag: res.organizationSettingsModel.isEnableIssueTag,
                })

            })

    }

    GetTenantPropertiesByUserId = () => {
        let none = [{ propertyId: 0, propertyName: '--None--', suburbId: '', location: '', address: '', propertyType: 'Residential' }];

        this.setState({ isPropertiesLoading: true });
        let properties;
        let propertyNames = [];
        const { i18n } = this.props;

        propertyService.GetTenantPropertiesByUserId()
            .then((res) => {
                properties = res.properties;
                if (res.properties.length > 0) {

                    properties.forEach(x => {
                        propertyNames.push(x.address ? x.address : " ");
                    });
                    if (this.state.isFromBot) {
                        this.applyTranslation(([propertyNames]), "en", i18n.language).then((trans) => {
                            if (trans.length > 0) {
                                trans.forEach((x, i) => {
                                    properties[i].address = trans[i];
                                });
                            }

                            this.setState({
                                propertyList: properties,
                                propertyId: properties[0].propertyId
                            });
                            this.getPropertyDetailsByPropertyId(properties[0].propertyId);
                            this.setState({ isPropertiesLoading: false });
                        });
                    }
                    else {
                        this.setState({
                            propertyList: properties,
                            propertyId: properties[0].propertyId,
                            isCurrentTenant:properties[0].isCurrentTenant
                        });
                        if (localStorage.userType != 5) {
                            this.getPropertyDetailsByPropertyId(properties[0].propertyId);
                        }
                        this.setState({ isPropertiesLoading: false });
                    }
                    this.handlePropertyChange(properties[0].propertyId);
                } else {
                    this.setState({
                        propertyList: [],
                        propertyId: 0
                    });
                    this.onChangeJobType(1);
                }
            });
    }

    getAvailableServices = () => {
        try {
            this.setState({ isLoading: true })
            AXIOS.GET(`tenant-api/available-job-types?userId=${localStorage.getItem('userId')}`)
                .then(dataJson => {
                    this.setState({ isLoading: false })
                    this.setState({ availableServicesDetails: dataJson });
                    this.setState({ availableServices: dataJson });
                    let formData = this.state.formData;
                    formData.childJobTypeId = '';
                    formData.childJobType = '';
                    this.setState({ formData: formData });
                })
        } catch (error) {
            console.log(error);
        }
    }

    GetFrequentlyUsedJobTypes = () => {
        try {
            let jobTypesLabels = [];
            let data;
            const { i18n } = this.props;
            if (this.state.isFromBot) {
                jobService.GetFrequentlyUsedJobTypes()
                    .then(result => {
                        data = result;
                        jobTypesLabels = data.map(x => x.label);
                        return this.applyTranslation((jobTypesLabels), "en", i18n.language);

                    }).then((trans) => {
                        if (trans.length > 0) {
                            trans.forEach((x, i) => {
                                data[i].label = trans[i];
                            });
                        }
                        this.setState({ frequentlyUsedServices: data });
                    })
            }
            else {
                jobService.GetFrequentlyUsedJobTypes()
                    .then(data => {
                        this.setState({ frequentlyUsedServices: data });
                    })
            }
        } catch (error) {
            console.log(error);
        }
    }

    getAllAvailableJobTypes() {
        let jobTypes = this.props.jobTypes;
        let parents = _.map(jobTypes, m => m.parentJobType);
        const parentJobTypes = _.filter(parents, f => f.parentRef === null);
        this.setState({ parentJobTypes: parentJobTypes });
    }

    getPropertyDetailsByPropertyId = (propertyId) => {
        const selectedProperty = this.state.propertyList.filter(a => a.propertyId == propertyId);
        if (selectedProperty && selectedProperty.length > 0 && selectedProperty[0].propertyId != null) {
            this.setState({
                propertyId: propertyId,
                //locationDetails: {},
                formData: {
                    address: selectedProperty[0].address,
                    location: selectedProperty[0].location,
                    propertyId: selectedProperty[0].propertyId,
                    propertyName: selectedProperty[0].propertyName,
                    suburbId: selectedProperty[0].suburbId,
                    propertyType: selectedProperty[0].propertyType
                },
                locationDetails: selectedProperty[0],
                jobForMe: {
                    propertyType: selectedProperty[0].propertyType,
                    address: selectedProperty[0].address,
                    latitude: selectedProperty[0].latitude,
                    location: selectedProperty[0].location,
                    longitude: selectedProperty[0].longitude,
                    placeId: selectedProperty[0].placeId,
                    suburbId: selectedProperty[0].suburbId,
                },
            });
            this.getOrganizationSettings(selectedProperty[0].orgnizationId);
        } else {
            this.setState({
                propertyId: null,
                locationDetails: {},
                formData: {
                    address: '',
                    location: '',
                    propertyId: null,
                    propertyName: '',
                    suburbId: null,
                    propertyType: ''
                }
            });
        }
        setTimeout(() => {
            if (this.state.isJobForMe == 1) {
                this.setState({ keyCollectType: 1 });
            }
        }, 500);
    }

    onChangeJobType = (value) => {
        if (value === 1 && localStorage.userType == 4) {
            this.setState({ isJobForMeText: <p>You (tenant) will be paying for this job and are responsible <br></br> for all costs involved.<br></br>This job is not being sent to your property manager.</p> });
        } else {
            this.setState({ isJobForMeText: '' });
        }

        this.setState({
            isJobForMe: (value === 1) ? true : false,
            jobForMe: {
                propertyType: '',
                address: '',
                latitude: '',
                location: '',
                longitude: '',
                placeId: '',
                suburbId: '',
                budget: ''
            },
            formData: {
                propertyId: ""
            }
        });
    }

    onKeyCollectionChange = (event) => {
        this.setState({
            keyCollectType: event
        });
    }

    handlePropertyChange = (props) => {
        this.setState({ property: props });
        this.getPropertyDetailsByPropertyId(props);
    }



    handleOnChangeJobDescription = (value) => {

        let jobforMe = this.state.jobForMe;
        jobforMe.jobDescription = this.jobDescription.current.value;
        this.setState({ jobForMe: jobforMe });


        let formdata = this.state.formData;
        const typedDescription = this.jobDescription.current.value;
        formdata.jobDescription = typedDescription;
        formdata.jobDescriptionEng = typedDescription;
        this.setState({
            formdata: formdata,
            isMaintatanceIssueProvided: true
        });

    }

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.label })
        this.getChildJobTypes(result.id);
    }
    handleSearchChange = (e, { value }) => {
        this.setState({ filteredResults: [], value });
        if (value.length > 2) {
            this.setState({ isLoading: true })
            setTimeout(() => {
                document.querySelector('.jobpost-wizard .auto-complete').classList.remove("hide-results");
                this.setState({
                    isLoading: false,
                    filteredResults: this.state.parentJobTypes.filter(suggestion => suggestion.label.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1)
                })
            }, 300)
        } else {
            document.querySelector('.jobpost-wizard .auto-complete').classList.add("hide-results");
        }
    }

    resultRenderer = (event) => {
        return <Label key={event.label} content={event.label}></Label>
    }





    onSelectFrequentlyUsedJobTypes = (event) => {
        try {
            this.setState({ parentId: event });
            this.setState({ isServiceTypeSelected: true });
            this.getChildJobTypes(event);
        } catch (error) {
            console.log(error);
        }
    }

    showHideMoreService = () => {
        let formData2 = {
            ...this.state.formData, childJobType: '',
            childJobTypeId: '',
        }
        this.setState({
            isShowMoreServices: !this.state.isShowMoreServices,
            selectedService: {},
            secondaryJobTypes: [],
            subChildJobTypeId: '',
            formData: formData2,
            isServiceTypeSelected: true
        })
    }

    getChildJobTypes(parentId) {
        let jobTypes = this.props.jobTypes;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === parentId);

        if (this.state.selectedServiceType && this.state.selectedServiceType.parentJobType && _.find(selectedNode.childJobTypes, f => f.id === this.state.selectedServiceType.parentJobType.id)) {
            this.setState({
                primaryJobTypes: selectedNode.childJobTypes,
                selectedService: selectedNode
            });
            this.setState({ childJobTypeId: selectedNode });
        } else {
            let formData2 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: '',

            }
            this.setState({
                primaryJobTypes: selectedNode.childJobTypes,
                selectedService: selectedNode,
                secondaryJobTypes: [],
                selectedServiceType: {
                    parentJobType: { id: '' }
                },
                formData: formData2
            });
        }
    }

    getSubChildJobTypes(parentId) {

        this.setState({ childJobTypeId: parentId });
        this.setState({ isSubServiceTypeSelected: true });
        let jobTypes = this.props.jobTypes;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === parentId);

        if (selectedNode == undefined) {
            let selectedServiceType = {
                childJobTypes: [],
                parentJobType: _.find(this.state.primaryJobTypes, f => f.id === parentId)
            }
            let formData1 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: ''
            };
            this.setState({
                secondaryJobTypes: [],
                selectedServiceType: selectedServiceType,
                formData: formData1
            });

        } else {
            let formData2 = {
                ...this.state.formData, childJobType: '',
                childJobTypeId: ''
            };
            this.setState({
                secondaryJobTypes: selectedNode.childJobTypes,
                selectedServiceType: selectedNode,
                formData: formData2
            });
        }

    }

    createTypeOfServicesChildButtons = (props) => {
        if (this.state.secondaryJobTypes.length > 0) {
            return (
                <>
                    <div className="form-group mt-5 wzrd-post-a-job thrd-lvl-btns">
                        <h2 className="mb-3">{this.state.selectedServiceType.parentJobType.description}<span className="required-icon">*</span></h2>
                        <ButtonToolbar className={this.state.isFromBot?"radio-btn-group btn-group-bot":"radio-btn-group"}>
                            <ToggleButtonGroup
                                className={this.props.isFromBot?"btn-group-bot":""}
                                defaultValue={(this.state.subChildJobTypeId) ? this.state.subChildJobTypeId : ''}
                                type="radio"
                                name="options"
                                onChange={this.onChangeChildTypeOfServices}>
                                {this.state.secondaryJobTypes.map((jbType, index) => {
                                    return <ToggleButton value={jbType.id}><span className="ba-icn ba-icn-ok"></span>  {jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            return
        }

    }

    onChangeChildTypeOfServices = (input) => {
        this.setState({ subChildJobTypeId: input });
        const childJobType = this.state.selectedServiceType.childJobTypes.filter(a => a.id === input)[0];
        let formData = this.state.formData;
        formData.childJobTypeId = childJobType.id;
        formData.childJobType = childJobType.title;
        this.setState({ formData: formData });
    }

    createTypeOfServicesButtons = (props) => {
        const { t } = this.props;
        let isServiceTypeSelected = this.state.isServiceTypeSelected;
        if (this.state.selectedService.childJobTypes) {
            return (
                <>
                    <div className="mt-5 wzrd-post-a-job">
                        {(isServiceTypeSelected) ?
                            <div className="wizard-header">
                                <h2>{this.state.isFromBot ? t('pj_lbl_select_sub_type') : 'Please select the type of subservice you require.'}<span className="required-icon">*</span></h2>
                                <p>{this.state.isFromBot ? t('pj_lbl_choose_an_option') : 'choose an option'}</p>
                            </div>

                            : ''}

                        <ButtonToolbar className="radio-btn-group sub-srvs-typ-tgl">
                            <ToggleButtonGroup
                                className={this.props.isFromBot?"btn-group-bot":""}
                                defaultValue={(this.state.childJobTypeId) ? this.state.childJobTypeId : ''}
                                type="radio"
                                name="options"
                                onChange={(event) => { this.getSubChildJobTypes(event); }}>
                                {this.state.selectedService.childJobTypes.map((jbType, index) => {
                                    return <ToggleButton key={jbType.id} value={jbType.id}><span className="ba-icn ba-icn-ok"></span> {jbType.label}</ToggleButton>;
                                })}
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                </>
            )
        } else {
            return
        }
    }

    onChangeTypeOfServices = (event) => {

        try {
            this.setState({ isLoading: true })
            AXIOS.GET(`tenant-api/job-sub-types?parentRef=${event}`)
                .then(dataJson => {
                    this.setState({ isLoading: false })
                    this.setState({ selectedServiceType: dataJson });
                    let formData = this.state.formData;
                    formData.childJobTypeId = '';
                    formData.childJobType = '';
                    this.setState({ formData: formData });
                });
        } catch (error) {
            console.log(error);
        }
    }

    handleJobUrgencyOnChange = (event, props) => {
        let isUrgent = (props.value == 1) ? true : false;
        this.setState({ jobUrgency: props.value, jobUrgencyCheckbox: isUrgent });
    }

    GetJobUrgency = () => {
        const { t } = this.props;
        return this.state.jobUrgency == -1 ? <span class="common-tag small badge-danger">{t('editjob_lbl13')}</span> : this.state.jobUrgency == 1 ? <span class="common-tag small badge-danger">{t('editjob_lbl11')}</span> : <span>{t('editjob_lbl12')}</span>;
    }

    closeDisclaimer = () => {
        this.setState({
            showDisclaimerDrawer: false,
        });
    }

    getTroubleShoutingGuide(index) {
        if (this.state.chatbotSetting.enableAIPoweredTroubleshootGuide) {
            this.getAIPoweredTroubleShoutingGuide(index);
        } else {
            this.getDefaultTroubleShoutingGuide(index);
        }
    }

    getDefaultTroubleShoutingGuide(index) {
        const { i18n } = this.props;
        this.setState({
            isLoadingTroubleshootingGuide: true
        });
        jobService.GetTroubleShootingGuide(this.state.formData.propertyId, this.state.formData.jobDescriptionEng).then(res => {
            if (res.responseCode == 12 && res.troubleshootGuide != null) {
                if (i18n.language != "en" && !i18n.language.toString().includes("en")) {
                    this.applyTranslation(res.troubleshootGuide, "en", i18n.language).then((trans) => {
                        res.troubleshootGuide = trans[0];
                        this.setState({
                            troubleShootingGuideDetails: res,
                            maintenanceTroubleshootGuideId: res.troubleshootGuideId,
                            isLoadingTroubleshootingGuide: false
                        });
                    });
                } else {
                    this.setState({
                        troubleShootingGuideDetails: res,
                        maintenanceTroubleshootGuideId: res.troubleshootGuideId,
                        isLoadingTroubleshootingGuide: false
                    });
                }

                if (this.state.chatbotSetting.hideTroubleshootGuide) {
                    this.continueJobPosting(index + 1);
                }

            } else {
                this.setState({
                    troubleShootingGuideDetails: res,
                    isLoadingTroubleshootingGuide: false
                });
                this.continueJobPosting(index + 1);
            }
        });
    }

    getAIPoweredTroubleShoutingGuide(index) {
        const { i18n } = this.props;
        this.setState({
            isLoadingTroubleshootingGuide: true
        });
        jobService.GetAIPoweredTroubleShootingGuide(this.state.formData.propertyId, this.state.formData.jobDescriptionEng).then(res => {
            if (res.success == true && res.contents != null) {
                if (i18n.language != "en" && !i18n.language.toString().includes("en")) {
                    this.applyTranslation(res.contents[0], "en", i18n.language).then((trans) => {
                        res.contents[0] = trans[0];

                        let troubleShootingGuideDetails = this.state.troubleShootingGuideDetails;
                        troubleShootingGuideDetails.aiPoweredTroubleshootGuide = res.contents;
                        troubleShootingGuideDetails.primaryJobTypeId = res.primaryJobTypeId;
                        troubleShootingGuideDetails.secondaryJobTypeId = res.secondaryJobTypeId;

                        this.setState({
                            troubleShootingGuideDetails: troubleShootingGuideDetails,
                            aiPoweredTroubleshootResultsEmpty: false,
                            isLoadingTroubleshootingGuide: false
                        });
                    });
                } else {
                    let troubleShootingGuideDetails = this.state.troubleShootingGuideDetails;
                    troubleShootingGuideDetails.aiPoweredTroubleshootGuide = res.contents;
                    troubleShootingGuideDetails.primaryJobTypeId = res.primaryJobTypeId;
                    troubleShootingGuideDetails.secondaryJobTypeId = res.secondaryJobTypeId;

                    this.setState({
                        troubleShootingGuideDetails: troubleShootingGuideDetails,
                        aiPoweredTroubleshootResultsEmpty: false,
                        isLoadingTroubleshootingGuide: false
                    });
                }
            } else {
                let troubleShootingGuideDetails = this.state.troubleShootingGuideDetails;
                troubleShootingGuideDetails.primaryJobTypeId = res.primaryJobTypeId;
                troubleShootingGuideDetails.secondaryJobTypeId = res.secondaryJobTypeId;

                this.setState({
                    troubleShootingGuideDetails: troubleShootingGuideDetails,
                    aiPoweredTroubleshootResultsEmpty: true,
                    isLoadingTroubleshootingGuide: false
                });
            }
        });
    }

    directToAIPoweredTroubleShootGuide = () => {
        this.setState({
            isAIPoweredTroubleshootRequested: true
        });
    }

    showAlternativeTroubleshootResults = (index) => {
        this.setState({ isAlternativeAITroubleshootGuideRequested: true });
        this.getAIPoweredTroubleShoutingGuide(index);
    }


    render() {
        const currentStepIndex = this.state.currentStep;
        let step = this.showCurrentStep(currentStepIndex);
        let backToHomeModal = this.backToHomeModal();
        return (
            <Form>
                <div className="">
                    {step}
                </div>
                {(this.state.showBackToHomeConfirmation == true) ?
                    <div>{backToHomeModal}</div> : ''
                }
                {(this.state.showDisclaimerDrawer == true) ?
                    <Disclaimer
                        showDrawer={this.state.showDisclaimerDrawer}
                        disclaimer={this.state.disclamimerText}
                        jobPostWorkflowStatus={this.state.jobPostWorkflowStatus}
                        onAgreedForDisclaimer={(event) => this.postJob(event)}
                        onCloseDisclaimer={() => this.closeDisclaimer()}
                        isFromBot={this.state.isFromBot}
                    ></Disclaimer> : ''
                }
            </Form>);
    }

    showCurrentStep = (index) => {       
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "selectProperty":
                return this.selectPropertyStep(index);
            case "getMaintenaceIssue":               
                return this.getMaintenaceIssue(index);
            case "getMaintenaceTroubleShootGuide":
                if (this.state.chatbotSetting.enableAIPoweredTroubleshootGuide) {
                    return this.getAIPoweredTroubleShootGuide(index);
                } else if (!this.state.chatbotSetting.hideTroubleshootGuide) {
                    return this.getMaintenaceTroubleShootGuide(index);
                }
            case "getAttachments":
                return this.getAttachments(index);
            case "selectPrePostSummary":
                return this.selectPrePostSummary(index);
            case "setResponse":
                return this.setResponse(index);
            case "setTroubleShooteResponse":
                return this.setTroubleShooteResponse();
            case "selectServiceType":
                return this.selectServiceType(index);
            default:
                return this.nextStep(index);
        }
    }

    selectPropertyStep = (index) => {
        const { propertyList, propertyId, isJobForMe, jobForMe, formData, isPropertyOwner } = this.state;
        const forMeText = this.state.isJobForMeText;
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className='custom-scroller ongoing-jobs'>
                    <div className="mb-4">
                        <div className="wizard-header mb-5">
                            <h2 htmlFor="property">{t('new_ui_pj_lbl_prop_select')}</h2>
                        </div>

                        <div className='mb-4'>
                            {(this.state.isPropertiesLoading) ?<div className='d-flex justify-content-center'> <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> </div>: ''}
                            
                            {(!this.state.isPropertiesLoading) ? <ButtonToolbar className="radio-btn-group">
                                <ToggleButtonGroup className={"btn-group-bot selective-tiles w-100"} type="radio" name="options" ref="jobType"  defaultValue={(propertyList && propertyList.length > 0 ) ? propertyList[0].propertyId : ""}
                                    onChange={(value) => { this.handlePropertyChange(value) }}>
                                    {propertyList.map((a, index) => {
                                        return <ToggleButton key={index} value={a.propertyId} className="d-flex align-items-center tile"><span className="icon icon-ico-location"></span><span className='address'>{a.address}</span></ToggleButton>
                                    })
                                    }
                                </ToggleButtonGroup>
                            </ButtonToolbar>:<></>}
                        </div>

                    </div>
                </div>
                
                <div className="wizard-actions pt-5">
                    <button type="button" className="btn btn-primary mx-0" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{t('new_ui_pj_btn_continue')}</button>
                </div>

            </div>);
    }
    getMaintenaceIssue = (index) => {
        const { jobUrgency, jobUrgencyList } = this.state;
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className={'job-post-type'}>
                    <div className='d-flex justify-content-between mb-4 mt-1'>
                        <button type="button" className="btn btn-primary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{t('btn_back')}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.backToHomeConfirmationModal()} style={this.state.secondaryButtonStyle}>{t('new_ui_pj_btn_home')}</button>
                    </div>
                    <div className='custom-scroller describe-issue'>
                        <div className="wizard-header mb-4">
                            <h2>{t('new_ui_pj_hdr_job_describe')}</h2>
                        </div>

                        <div className="form-group mb-4">
                            <textarea className="form-control form-textarea" placeholder={t('new_ui_pj_lbl_job_descrptn')} aria-label="test"
                                value={this.state.formData.jobDescription} ref={this.jobDescription} onChange={(value) => this.handleOnChangeJobDescription(value)}>
                            </textarea>
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-label" style={this.state.urgencyLabelStyle}>{t('editjob_priority')}</label>
                            <Dropdown className='form-control'
                                selectOnNavigation={false}
                                className='test test'
                                selection
                                loading={false}
                                value={jobUrgency}
                                name='jobUrgency'
                                options={jobUrgencyList.map((a, index) => { return { key: index, text: a.value, value: a.jobUrgency }; })}
                                placeholder='Choose job Priority'
                                onChange={this.handleJobUrgencyOnChange}
                            />
                            {(this.state.jobUrgency==1) ? <div className='warning-card mt-3'><p className="text-left font-14 mb-0">{t('new_ui_pj_lbl_urgent_notice')}</p></div> : ''}
                        </div>
                    </div>
                </div>
                <div className="wizard-actions">
                    <button type="button" className="btn btn-primary mx-0" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{t('new_ui_pj_btn_continue')}</button>
                </div>
            </div>);
    }

    getMaintenaceTroubleShootGuide = (index) => {
        const { troubleShootingGuideDetails, isLoadingTroubleshootingGuide } = this.state;
        const { t } = this.props;

        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className={'job-post-type'}>

                    <div className='d-flex justify-content-between mb-4 mt-1'>
                        <button type="button" className="btn btn-primary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{t('btn_back')}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.backToHomeConfirmationModal()} style={this.state.secondaryButtonStyle}>{t('new_ui_pj_btn_home')}</button>
                    </div>
                    <div className='custom-scroller issue-fix-suggestions'>
                        <h2>{t('new_ui_pj_hdr_troubleshoot_steps')}</h2>
                        <div className='p-4 light-brown-bg'>
                            {isLoadingTroubleshootingGuide ? <div className='d-flex justify-content-center'><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>
                                : (<div>
                                    
                                    <p className="text-danger w-100 text-left">{t('new_ui_pj_lbl_troubleshoot_proceed')}</p>

                                    <div className="innerHtmlContainer text-left" dangerouslySetInnerHTML={{ __html: `${troubleShootingGuideDetails.troubleshootGuide}` }}></div>
                                </div>)}
                        </div>
                    </div>
                </div>
                {!isLoadingTroubleshootingGuide && (<div>
                    <div className="wizard-actions d-flex align-items-center">
                        <button type="button" className="btn btn-secondary" onClick={() => this.continueJobPosting(index)} style={this.state.secondaryButtonStyle}>{t('new_ui_pj_btn_troubleshoot_not_fixed')}</button>
                        <button type="button" className="btn btn-primary mt-2" onClick={() => this.saveTroubleShootResult(true)} style={this.state.primaryButtonStyle}>{t('new_ui_pj_btn_troubleshoot_fixed')}</button>
                    </div>
                </div>)}
            </div>);
    }

    getAIPoweredTroubleShootGuide = (index) => {
        const { troubleShootingGuideDetails, isLoadingTroubleshootingGuide, isAIPoweredTroubleshootRequested, isAlternativeAITroubleshootGuideRequested, aiPoweredTroubleshootResultsEmpty } = this.state;
        const { t } = this.props;

        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className={'job-post-type'}>

                    <div className='d-flex justify-content-between mb-4 mt-1'>
                        <button type="button" className="btn btn-primary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{t('btn_back')}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.backToHomeConfirmationModal()} style={this.state.secondaryButtonStyle}>{t('new_ui_pj_btn_home')}</button>
                    </div>
                    {!isAIPoweredTroubleshootRequested ?
                        isLoadingTroubleshootingGuide ? <div className='d-flex justify-content-center'>
                            <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />
                        </div> : <div className='custom-scroller issue-fix-suggestions'>
                            <div className='custom-scroller issue-fix-suggestions align-items-center'>
                                <div class="text-center font-16 pt-5">{t('new_ui_pj_hdr_ai_troubleshoot_confirmation')}</div>
                            </div>
                            <div className="wizard-actions d-flex align-items-center">
                                <button type="button" className="btn btn-secondary" onClick={() => this.continueJobPosting(index)} style={this.state.secondaryButtonStyle}>{t('lbl_attachment_no')}</button>
                                <button type="button" className="btn btn-primary mt-3" onClick={() => this.directToAIPoweredTroubleShootGuide()} style={this.state.primaryButtonStyle}>{t('lbl_attachment_yes')}</button>
                            </div>
                        </div> : <div className='custom-scroller issue-fix-suggestions'>
                            <h2>{t('new_ui_pj_hdr_troubleshoot_steps')}</h2>
                            <div className='p-4 light-brown-bg'>
                                {isLoadingTroubleshootingGuide ? <div className='d-flex justify-content-center'><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div>
                                    : (aiPoweredTroubleshootResultsEmpty ? <div>
                                        <div className="col-md-12 popup-contents d-flex flex-column align-items-center mt-3">
                                            <span className='icon icon-ico-exclamation popup-icon error mb-4'></span>
                                            <div class="text-center">{t('new_ui_pj_lbl_ai_troubleshoot_no_response')}</div>
                                        </div>
                                    </div> : <div>

                                        <p className="text-danger w-100 text-left">{t('new_ui_pj_lbl_troubleshoot_proceed')}</p>

                                        {troubleShootingGuideDetails.aiPoweredTroubleshootGuide.map((a, index) => {
                                            return <div>
                                                <hr></hr>
                                                <p className="text-danger w-100 text-left">{a.split('\n').map(str => <p>{str}</p>)}</p>
                                            </div>
                                        })
                                        }
                                    </div>)}
                            </div>
                        </div>}
                </div>
                {!isLoadingTroubleshootingGuide && isAIPoweredTroubleshootRequested && (<div>
                    <div className="wizard-actions d-flex align-items-center">
                        {!isAlternativeAITroubleshootGuideRequested &&
                            (<div className="w-30 mx-2 p-0 m-0">
                            <a type="button" onClick={() => this.showAlternativeTroubleshootResults(index)}><p className="text-blue text-underline p-2 m-2">{t('new_ui_pj_btn_troubleshoot_alternatives')}</p></a>
                            </div>)
                        }
                        <button type="button" className="btn btn-secondary mx-2 ml-0 w-30 p-0" onClick={() => this.continueJobPosting(index)} style={this.state.secondaryButtonStyle}><p className="p-2 m-2">{t('new_ui_pj_btn_troubleshoot_not_fixed')}</p></button>
                        <button type="button" className="btn btn-primary mx-2 ml-0 w-30 p-0 mt-3" onClick={() => this.showTroubleshootSuccessResult()} style={this.state.primaryButtonStyle}><p className="p-2 m-2">{t('new_ui_pj_btn_troubleshoot_fixed')}</p></button>
                    </div>
                </div>)}
            </div>);
    }

    getAttachments = (index) => {
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className={'job-post-type'}>
                    <div className='d-flex justify-content-between mb-4 mt-1'>
                        <button type="button" className="btn btn-primary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{t('btn_back')}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.backToHomeConfirmationModal()} style={this.state.secondaryButtonStyle}>{t('new_ui_pj_btn_home')}</button>
                    </div>
                    <div className='custom-scroller describe-issue'>
                        {(this.state.attachments.length == 0) ?
                            <div className="form-group mb-4">
                                <p className='text-left font-12 text-dark'>{t('new_ui_pj_lbl_attchmnts_notice')}</p>
                            </div> : ''
                        }
                        <div className="form-group">
                            <div className="img-upld-cont">
                                <AttachmentsDrawer
                                    jobDetails={{ id: this.state.jobId, attachments: this.state.attachments }}
                                    onSubmitAttachments={(event) => { this.setState({ attachments: event }) }}
                                    isJobPost={true}
                                    isFromBot={true}
                                    t={t}>
                                </AttachmentsDrawer>
                                {(this.state.attachments) ?
                                    <ImageViewer attachments={this.state.attachments} isFromBot={true} t={t}></ImageViewer> : ''
                                }
                            </div>
                        </div>
                        {(!this.state.chatbotSetting.hideKeyCollectionOptions) ?
                            <div className="form-group text-left mb-0">
                                <label className='text-left font-14 text-dark mb-2'>{t('new_ui_pj_lbl_summary_key_collection')}</label>
                                {(this.state.isRestrictKeyCollectionForResidentialProperties && this.state.formData.propertyType == 'Residential') ?
                                    <ButtonToolbar className="radio-btn-group">
                                        <ToggleButtonGroup className={"btn-group-bot mx-0 w-100"} type="radio" name="propertyKey" id="check3" onChange={(value) => this.onKeyCollectionChange(value)} defaultValue={3}>
                                            <ToggleButton value="3" className="tile-button"><span className="text-wrap">{t('lbl_propkey_case6')}</span></ToggleButton>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                    :
                                    <div>
                                        <ButtonToolbar className="radio-btn-group">
                                            <ToggleButtonGroup className={"btn-group-bot mx-0 w-100"} type="radio" name="propertyKey" id="check3" onChange={(value) => this.onKeyCollectionChange(value)} defaultValue={this.state.keyCollectType}>
                                                <ToggleButton id="check1" key={1} value="1" className="tile-button"><span className="text-wrap">{t('lbl_keycollection_option1')}</span></ToggleButton>
                                                <ToggleButton id="check2" key={2} value="2" className="tile-button"><span className="text-wrap">{t('lbl_keycollection_option2')}</span></ToggleButton>
                                                <ToggleButton id="check3" key={3} value="3" className="tile-button"><span className="text-wrap">{t('lbl_keycollection_option3')}</span></ToggleButton>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>

                                    </div>
                                }
                            </div> : ''
                        }
                    </div>
                </div>
                <div className="wizard-actions">
                    <button type="button" className="btn btn-primary mx-0" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{t('new_ui_pj_btn_continue')}</button>
                </div>
            </div>);
    }

    selectPrePostSummary = (index) => {
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className={''}>
                   
                    <div className='d-flex justify-content-between mb-4 mt-1'>
                        <button type="button" className="btn btn-primary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{t('btn_back')}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.backToHomeConfirmationModal()} style={this.state.secondaryButtonStyle}>{t('new_ui_pj_btn_home')}</button>
                    </div>
                    <div className='custom-scroller check-issues'>
                        <div className="wizard-header">
                            <h2>{t('new_ui_pj_hdr_job_summary')}</h2>
                        </div>

                        <div className='p-4 light-brown-bg flex-column-section'>
                            <div className="form-group">
                                <div className="font-12 text-light-brown">{t('new_ui_pj_lbl_summary_address')}</div>
                                <div className="font-14 text-dark">{this.state.formData.address}</div>
                            </div>
                            <div className="form-group">
                                <div className="font-12 text-light-brown">{t('new_ui_pj_lbl_summary_description')}</div>
                                <div className="font-14 text-dark">{this.state.formData.jobDescription}</div>
                            </div>
                            {(this.state.attachments) ?
                                <ImageViewer attachments={this.state.attachments} isFromBot={this.state.isFromBot} t={t}></ImageViewer> : ''
                            }
                            <div className="form-group">
                                <div className="font-12 text-light-brown">{t('editjob_priority')}</div>
                                <div className="font-14 text-dark">{this.GetJobUrgency()}</div>
                            </div>
                            {(!this.state.chatbotSetting.hideKeyCollectionOptions) ?
                                <div className="form-group">
                                    <div className="font-12 text-light-brown">{t('new_ui_pj_lbl_summary_key_collection')}</div>
                                    {(this.state.isRestrictKeyCollectionForResidentialProperties && this.state.formData.propertyType == 'Residential' && this.state.keyCollectType == 3) ?
                                    
                                        <div className="font-14 text-dark">{t('lbl_propkey_case6')}</div>
                                        
                                        :
                                        <div>
                                            {(this.state.keyCollectType == 1) ? <div className="font-14 text-dark">{t('lbl_keycollection_option1')}</div>
                                                : (this.state.keyCollectType == 2) ? <div className="font-14 text-dark">{t('lbl_keycollection_option2')}</div>
                                                    : <div className="font-14 text-dark">{t('lbl_keycollection_option3')}</div>
                                            }
                                        </div>
                                    }
                                </div> : ''
                            }
                        </div>

                    </div>
                    <div className="wizard-actions">
                        <button type="button" className="btn btn-primary mx-0" onClick={() => this.onSubmitJob(1)} style={this.state.primaryButtonStyle}>{t('new_ui_pj_btn_summary_job_post')}</button>
                    </div>
                </div>
            </div>)
    }


    selectServiceType = (index) => {
        const { isLoading, value, propertyList, filteredResults, propertyId, isPropertiesLoading, isJobForMe, jobForMe } = this.state
        let serviceTypes = this.createTypeOfServicesButtons();
        let serviceTypeChild = this.createTypeOfServicesChildButtons();
        const { t } = this.props;
        if (this.state.parentId) {
            this.addParentJobTypeToFrequentlyUsedServices(this.state.parentId);
        }
        return (
            <>
                <div className="wizard-inner">
                    <div className={(this.state.isFromBot) ? '' : 'wizard-body'}>
                        {(this.state.isShowMoreServices) ?
                            <div>
                                <div className="wizard-header">
                                    <h2>Type the service that you need.</h2>
                                    <p className="userTip">( eg. Electrician )</p>
                                </div>
                                <div className="form-group auto-complete">
                                    <label class="form-label">Service </label>
                                    <Search
                                        placeholder="please type the service you need"
                                        loading={isLoading}
                                        onResultSelect={this.handleResultSelect}
                                        onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                            leading: true,
                                        })}
                                        value={value}
                                        results={filteredResults}
                                        resultRenderer={this.resultRenderer}
                                        {...this.props}
                                    />
                                </div>
                            </div> :
                            <FrequentlyUsedServices
                                onSelect={this.onSelectFrequentlyUsedJobTypes}
                                services={this.state.frequentlyUsedServices}
                                onClickMore={this.showHideMoreService}
                                selectedService={(this.state.parentId) ? this.state.parentId : ''}
                                translationInstance={t}
                                isFromBot={this.state.isFromBot}>
                            </FrequentlyUsedServices>

                        }
                        {serviceTypes}
                        {serviceTypeChild}
                    </div>
                    <div className="wizard-actions">
                        <button type="button" className="btn btn-secondary" onClick={() => this.previousStep(index)} style={this.state.secondaryButtonStyle}>{this.state.isFromBot ? t('btn_back') : 'BACK'}</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.nextStep(index)} style={this.state.primaryButtonStyle}>{this.state.isFromBot ? t('btn_next') : 'NEXT'}</button>
                    </div>
                </div>
            </>);
    }

    setResponse = (index) => {
        const { t } = this.props;
        return (
            <div className='wizard-inner wzrd-post-a-job tenant-chat-bot'>
                <div className="row justify-content-center">
                        <div className='custom-scroller ongoing-jobs'>
                            <div className="job-posted-icon">
                                {/* style={{ color: (this.props.customStyle.brand) ? this.props.customStyle.brand : '' }} */}
                                <span className="ba-icn ba-icn-ok"></span>
                            </div>
                            <div className="job-posted-msg mb-0">
                                <h2 className="text-center mb-0"> {t('new_ui_pj_hdr_job_response')} </h2>
                            </div>
                            <div className="job-posted-msg mb-5">
                                <p className="text-center font-14 text-success"><i>{t('new_ui_pj_lbl_job_response_ref')} {this.state.jobNumber}</i></p>
                            </div>
                            <div className="job-posted-msg">
                                <p className="text-center userTip font-14">{t('new_ui_pj_lbl_job_response_notice')}</p>
                            </div>
                            <div className="job-posted-msg">
                                <p className="text-center userTip font-14">{t('new_ui_pj_lbl_job_response_nxt_step')}</p>
                            </div>
                            <div className="job-posted-msg">
                                <a style={{ display: 'block' }} href={this.state.jobOverViewUrl} target="_blank" className='text-blue font-14 text-underline' >{t('new_ui_pj_lbl_job_response_link')}</a>
                            </div>
                        </div>
                        <div className="wizard-actions">
                            <button onClick={() => this.backToMainMenu()} className="btn btn-primary" style={this.state.primaryButtonStyle} ><span>{t('new_ui_pj_btn_back_to_menu')}</span></button>
                        </div>
                  
                </div>
            </div>);
    }
    nextStep = (index) => {
        const { i18n } = this.props;
        var currentStep = index;
        if (this.validate(index)) {
            if (index == 1) {
                let formdata = this.state.formData;
                const typedDescription = this.jobDescription.current.value;
                if (typedDescription.length > 0) {
                    if (i18n.language != 'en' && !i18n.language.toString().includes("en")) {
                        this.applyTranslation(typedDescription, i18n.language, 'en').then((trans) => {
                            formdata.jobDescription = typedDescription;
                            formdata.jobDescriptionEng = trans[0];
                            this.setState({
                                formdata: formdata,
                                isMaintatanceIssueProvided: true
                            });
                            this.getTroubleShoutingGuide(index);
                        });
                    } else {
                        this.getTroubleShoutingGuide(index);
                    }
                }

                formdata.issueAreaInProperty = "";
                formdata.issueAreaInPropertyEng = "";
                this.setState({ formdata: formdata });

                if (!this.state.chatbotSetting.hideTroubleshootGuide || this.state.chatbotSetting.enableAIPoweredTroubleshootGuide) {
                    currentStep = (index + 1);
                }
            }
            else {
                currentStep = (index + 1);

            }


        } else {
            return false;
        }
        
        this.setState({ currentStep: currentStep, previousStep: index });
    }

    previousStep = (index) => {
        var currentStep = index;
        if (index == 3) {
            currentStep = (index - 2);
        }
        else {
            currentStep = (index - 1);
        }

        if (index == 2 || index == 3) {
            this.setState({
                isAIPoweredTroubleshootRequested: false,
                isAlternativeAITroubleshootGuideRequested: false
            });
        }
        
        this.setState({ currentStep: currentStep, previousStep: index });
    }

    onSubmitJob = (workflow) => {
         const { t } = this.props;  
        if (workflow == 1) {
            if (workflow == 1) {
                this.setState({ isDraftJob: true });
            }
            this.loadDisclaimer(workflow);
        } else {
            if (!this.state.isJobForMe && this.state.isMandatoryToUploadFiles && (this.state.attachments == [] || this.state.attachments.length == 0)) {
                this.notifyError(this.state.isFromBot ? t('pj_error_attachments_empty') : 'Attachments are required.');
                return false;
            } else {
                this.loadDisclaimer(workflow);
            }
        }
    }

    loadDisclaimer = (workflow) => {
        this.setState({
            isLoading: true
        });
        propertyService.GetDisclaimerByPropertyId(this.state.formData.propertyId).then(res => {
            this.setState({
                isLoading: false
            });
            if (res !== null && res !== '') {
                this.setState({
                    showDisclaimerDrawer: true,
                    disclamimerText: res,
                    jobPostWorkflowStatus: workflow,
                    actionValue :0
                });
            } else {
                this.postJob(workflow);
            }
        });
    }

    validate = (index) => {
        const { tradeeCount, jobBudget, budgetAmount, suburbId, isSelectedOnDemand,
            inviteToProviders, isJobForMe, selectedTradees, selectedService,
            selectedServiceType, formData } = this.state
        let currentStep = this.state.stepFlow[index][1];
        const { t } = this.props;
        switch (currentStep) {
            case "selectProperty":
                if (!this.state.property) {
                    this.notifyError(t('pj_error_no_property_selected'));
                    return false;
                }
                else {
                    return true;
                }
            case "getMaintenaceIssue":
                if ((this.state.formData.jobDescription == '' || this.state.formData.jobDescription == undefined) && (!this.jobDescription.current.value || this.jobDescription.current.value == '')) {
                    this.notifyError(t('pj_error_no_jd'));
                    return false;
                } else {
                    return true;
                }
            case "getAttachments":
                if (this.state.isMandatoryToUploadFiles && this.state.attachments.length == 0) {
                    this.notifyError(t('pj_error_attachments_empty'));
                    return false;
                } else if (this.state.keyCollectType == undefined || this.state.keyCollectType == '') {
                    this.notifyError(t('pj_error_key_coll_method_not_selected'));
                    return false;
                }else{
                    return true;
                }
            case "selectServiceType":
                if (selectedService && selectedService.parentJobType) {
                    if (selectedServiceType && selectedServiceType.parentJobType && selectedServiceType.parentJobType.id != '') {
                        if (selectedServiceType.childJobTypes.length > 0 && (!formData.childJobType || formData.childJobType === '')) {
                            var errorMessage = this.state.isFromBot ? t('pj_error_child_job_type_not_selected') : 'Please select -parentTypeDesc- that you need.';
                            //errorMessage.replace('-parentType-', selectedServiceType.parentJobType.description.replace(' ? ', ''));
                            this.notifyError(errorMessage);
                            return false;
                        }
                    } else {
                        this.notifyError(this.state.isFromBot ? t('pj_error_parent_job_type_not_selected') : 'Please select a type of service.');
                        return false;
                    }
                } else if (!selectedService.parentJobType) {
                    this.notifyError(this.state.isFromBot ? t('pj_error_service_not_selected') : 'Please select a service.');
                    return false;
                }
                return true;
            case "setResponse":
                return this.setResponse(index);

        }
    }

    postJob = (workflow) => {
        const workflowId = workflow;
        let jobDetails = {};
        const { address, budget, latitude, location, longitude, placeId, propertyType, suburbId } = this.state.jobForMe;
        const { selectedService, selectedServiceType, formData, jobBudget, selectedTradees, isSelectedOnDemand, selectedQuoteLimit, budgetAmount, jobAssignTags, selectedComponent, selectedFeatureComponent } = this.state;
        if (this.state.isJobForMe == true) {

            jobDetails.propertyId = null;
            jobDetails.suburbId = suburbId;
            jobDetails.address = address;
            jobDetails.location = location;
            jobDetails.jobPropertyTypes = propertyType;
            if (this.state.jobForMe && this.state.jobForMe.propertyType && this.state.jobForMe.propertyType === "Residential") {
                jobDetails.propertyTypeId = 0;
            }
            else if (this.state.jobForMe && this.state.jobForMe.propertyType && this.state.jobForMe.propertyType === "Commercial") {
                jobDetails.propertyTypeId = 1;
            } else {
                if (this.state.propertyType && this.state.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                } else {
                    jobDetails.propertyTypeId = 1;
                }
            }
            jobDetails.budgetId = parseInt(jobBudget);
            if (jobBudget == '-999') {
                jobDetails.budgetAmount = parseFloat(budgetAmount);
            }
            if (isSelectedOnDemand) {
                jobDetails.targetedTradeeOption = 4;
                jobDetails.sendInvitationtoAll = false;
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                let tradees = [];
                selectedTradees.forEach(element => {
                    tradees.push({
                        isAssigned: true,
                        userId: element.userId,
                        name: element.name,
                        email: element.email,
                        firstName: element.name,
                        lastName: element.name,
                    });
                });
                jobDetails.tradees = tradees;
            } else {
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                jobDetails.targetedTradeeOption = 0;
                jobDetails.sendInvitationtoAll = true;
                jobDetails.tradees = [];
            }
        } else {
            if (this.state.formData.propertyId == 0 || this.state.formData.propertyId == undefined) {
                jobDetails.propertyId = null;
                jobDetails.suburbId = this.state.locationDetails.suburbId;
                jobDetails.address = this.state.locationDetails.address;
                jobDetails.jobPropertyTypes = this.refs.jobPropertyTypes.props.value;
                if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                } else if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Commercial") {
                    jobDetails.propertyTypeId = 1;
                }
                else {
                    jobDetails.propertyTypeId = 0;
                }
            } else {
                jobDetails.propertyId = this.state.formData.propertyId;
                jobDetails.address = this.state.formData.address;
                jobDetails.location = this.state.formData.location;
                jobDetails.propertyName = this.state.formData.propertyName;
                jobDetails.suburbId = this.state.formData.suburbId;
                if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Residential") {
                    jobDetails.propertyTypeId = 0;
                }
                else if (this.state.formData && this.state.formData.propertyType && this.state.formData.propertyType === "Commercial") {
                    jobDetails.propertyTypeId = 1;
                } else {
                    jobDetails.propertyTypeId = 0;
                }
                jobDetails.jobPropertyTypes = this.state.formData.propertyType;
            }
            jobDetails.propertyKeyCollectType = parseInt(this.state.keyCollectType);

            if (isSelectedOnDemand) {
                jobDetails.targetedTradeeOption = 4;
                jobDetails.sendInvitationtoAll = false;
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                let tradees = [];
                selectedTradees.forEach(element => {
                    tradees.push({
                        isAssigned: true,
                        userId: element.userId,
                        name: element.name,
                        email: element.email,
                        firstName: element.name,
                        lastName: element.name,
                    });
                });
                jobDetails.tradees = tradees;
            } else {
                jobDetails.quoteLimit = parseInt(selectedQuoteLimit);
                jobDetails.targetedTradeeOption = 0;
                jobDetails.sendInvitationtoAll = true;
                jobDetails.tradees = [];
            }
        }
        jobDetails.id = this.state.jobId;
        jobDetails.secondaryJobType = this.state.selectedServiceType.parentJobType.id;
        jobDetails.secondaryJobTypeDisplay = this.state.selectedServiceType.parentJobType.title;
        jobDetails.primaryJobType = this.state.selectedService.parentJobType.id;
        jobDetails.primaryJobTypeDisplay = this.state.selectedService.parentJobType.title;
        jobDetails.jobPropertyLocks = this.state.propertyLocks.map((item, index) => {
            const jobLock = {};
            jobLock.jobId = null;
            jobLock.propertyLock = {};
            jobLock.propertyLock.id = item.id;
            jobLock.propertyLock.lockId = item.lockId;
            jobLock.propertyLock.propertyId = jobDetails.propertyId;
            return jobLock;
        });

        if (this.state.formData.childJobTypeId && this.state.formData.childJobTypeId.trim() != "") {
            jobDetails.jobType = this.state.formData.childJobTypeId;
        }
        else {
            jobDetails.jobType = this.state.selectedServiceType.parentJobType.id;
        }

        jobDetails.jobTypeDisplay = this.state.formData.childJobType;
        jobDetails.title = this.state.formData.jobDescriptionEng;
        jobDetails.description = this.state.formData.jobDescriptionEng;
        jobDetails.jobUrgencyId = this.state.jobUrgency;

        jobDetails.dueDate = (!this.state.dueDate) ? null : this.state.dueDate;
        // jobDetails.JobUrgencyDisplay =  this.refs.jobUrgency.props.value;
        jobDetails.jobValidityPeriod = 1;
        jobDetails.serviceConsumerId = localStorage.getItem('userId');

        jobDetails.workflowStatusId = workflow === 1 ? 1 : 2;
        jobDetails.attachments = this.state.attachments;
        if (workflowId == 2) {
            jobDetails.isActive = true;
        }
        if (selectedComponent != '') {
            jobDetails.JobPropertyComponentId = selectedComponent;
        }

        if (jobAssignTags != undefined && jobAssignTags.length > 0) {
            jobDetails.JobAssignTagIds = jobAssignTags;
        }

        if (selectedFeatureComponent != undefined && selectedFeatureComponent.length > 0) {
            jobDetails.JobPropertyFeatureComponentIds = selectedFeatureComponent;
        }

        jobDetails.issueAreaInProperty = (this.state.formData.issueAreaInPropertyEng) ? this.state.formData.issueAreaInPropertyEng : this.state.formData.issueAreaInProperty;

        if (this.state.maintenanceTroubleshootGuideId && this.state.maintenanceTroubleshootGuideId != '') {
            jobDetails.maintenanceTroubleshootGuideId = this.state.maintenanceTroubleshootGuideId;
        }
        if(this.state.isFromBot){
            jobDetails.channelId = 12;
            jobDetails.includeJobOverViewUrl = true;
        }else{
            jobDetails.channelId = 1;
        }
        this.setState({ isJobPosting: true })
        try {
            trackPromise(
                AXIOS.POST(`tenant-api/job-post`, jobDetails, {
                    'headers': { 'ClientSecret': AppConfiguration.Setting().ClientSecret ,'ChannelId':jobDetails.channelId}
                })
                    .then(dataJson => {
                        this.setState({ isJobPosting: false })
                        const message = workflowId === 1 ? 'drafted' : 'posted'
                        if (dataJson == 'Request Unauthorized' || dataJson == 'Job creation failed') {
                            toast.error(`Job is not ${message} successfully. Please try again.`, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                        } else {
                            toast.success(`Job is ${message} successfully.`, {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            this.setState({ jobNumber: dataJson.jobNumber });
                            this.setState({ jobOverViewUrl: dataJson.jobOverViewUrl });
                            this.setState({ currentStep: 5 });
                            this.setState({
                                attachments: [],
                                value: '',
                                isShowMoreServices: false,
                                showDisclaimerDrawer: false,
                                jobPostWorkflowStatus: 1
                            });
                            if (this.state.troubleShootingGuideDetails.troubleshootGuideId != '') {
                                this.saveTroubleShootResult(false, dataJson.id);
                            }
                        }
                    }, error => {
                        console.log(error);
                        this.setState({ isJobPosting: false });
                        toast.error(`Fail to post the job. Please try again.`, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
            )

        } catch (error) {
            console.log(error);
        }
    }

    saveTroubleShootResult = (isResolved = false, jobId = "") => {
        let troubleShootResult = {};
        troubleShootResult.isResolved = isResolved;
        troubleShootResult.jobId = jobId;
        troubleShootResult.userId = this.state.userId;
        troubleShootResult.maintenanceTroubleshootGuideId = this.state.troubleShootingGuideDetails.troubleshootGuideId;
        troubleShootResult.description = this.state.formData.jobDescriptionEng;
        troubleShootResult.propertyId = this.state.propertyId;
        jobService.saveTroubleShootingResult(troubleShootResult).then(response => {
            if (response) {
                if (isResolved) {
                    this.setState({ currentStep: (6) });
                }
            }
        });
    }

    showTroubleshootSuccessResult = () => {        
        this.setState({ currentStep: (6) });
    }

    skipAIPoweredTroubleshootGuide = (index) => {
        if (this.state.isLoadingTroubleshootingGuide) {
            this.setState({ isSkipTroubleshootGuideLoading: true });
            setInterval(this.skipAIPoweredTroubleshootGuide(index), 1000);
        } else {
            this.setState({ isSkipTroubleshootGuideLoading: false });
            this.continueJobPosting(index);
        }
    }

    continueJobPosting = (index) => {
        let jobTypes = this.props.jobTypes;
        this.state.selectedService = jobTypes.find(x => x.parentJobType.id == this.state.troubleShootingGuideDetails.primaryJobTypeId);
        this.state.primaryJobTypes = this.state.selectedService.childJobTypes;
        this.state.parentId = this.state.troubleShootingGuideDetails.primaryJobTypeId;
        this.state.isServiceTypeSelected = true;
        this.state.childJobTypeId = this.state.troubleShootingGuideDetails.secondaryJobTypeId;
        let selectedNode = _.find(jobTypes, f => f.parentJobType.id === this.state.troubleShootingGuideDetails.secondaryJobTypeId);
        if (selectedNode !== undefined) {
            this.setSecondaryJobType(selectedNode);
        }
        else {
            this.state.selectedServiceType.parentJobType = this.state.selectedService.childJobTypes.find(x => x.id == this.state.troubleShootingGuideDetails.secondaryJobTypeId);
            this.state.selectedServiceType.childJobTypes = [];
        }
        this.setState({
            currentStep: (index + 1),
            parentId: this.state.troubleShootingGuideDetails.primaryJobTypeId
        });
    }

    setSecondaryJobType = (selectedNode) => {
        let formData2 = {
            ...this.state.formData, childJobType: '',
            childJobTypeId: ''
        };
        this.setState({
            secondaryJobTypes: selectedNode.childJobTypes,
            selectedServiceType: selectedNode,
            formData: formData2
        });
    }

    setTroubleShooteResponse = () => {
        const { t } = this.props;
        return (
            <div className='wizard-inner wzrd-post-a-job tenant-chat-bot'>
                <div className="text-center">
                    
                        <div className="job-posted-icon">
                            {/* style={{ color: (this.props.customStyle.brand) ? this.props.customStyle.brand : '' }} */}
                            <span className="ba-icn ba-icn-ok"></span>
                        </div>
                        <div className="job-posted-msg mb-0">
                            <h2 className="text-center mb-0">{t('new_ui_pj_hdr_troubleshoot_response')}</h2>
                        </div>
                        <div className="job-posted-msg">
                            <h3 className="text-center font-16">{t('new_ui_pj_lbl_troubleshoot_response')}</h3>
                        </div>
                        <div className="wizard-actions">
                            <button onClick={() => this.backToMainMenu()} className="btn btn-primary mx-0" style={this.state.primaryButtonStyle} ><span>{t('new_ui_pj_btn_back_to_menu')}</span></button>
                        </div>
                  
                </div>
            </div>
            );





            // <>
            //     <div className="row justify-content-center">
            //         <div className="col-md-12 col-sm-12">
            //             <div className="job-posted-icon">
            //                 <span className="ba-icn ba-icn-briefcase" style={{ color: (this.props.customStyle.brand) ? this.props.customStyle.brand : '' }}>
            //                     <span className="ba-icn ba-icn-ok"></span>
            //                 </span>
            //             </div>
            //             <div className="job-posted-msg">
            //                 <h2 className="text-center">{t('new_ui_pj_hdr_troubleshoot_response')}</h2>
            //             </div>
            //             <div className="job-posted-msg">
            //                 <h3 className="text-center">{t('new_ui_pj_lbl_troubleshoot_response')}</h3>
            //             </div>
            //             <div className="wizard-actions">
            //                 <button onClick={() => this.backToMainMenu()} className="btn btn-primary" style={this.state.primaryButtonStyle} ><span>{t('new_ui_pj_btn_back_to_menu')}</span></button>
            //             </div>
            //         </div>
            //     </div>
            // </>
            
    }    

    backToHomeConfirmationModal = () => {
        this.setState({
            showBackToHomeConfirmation: true
        });
    }

    backToHomeModal = () => {
        const { t } = this.props;

        return (
            <>
                <Modal size={'tiny'} onClose={this.closeReturnHomeConfirmation} open={this.state.showBackToHomeConfirmation} className="height-auto">
                    <Modal.Content>
                        <div className="px-1">
                            <div className="row">
                                <div className="col-md-12 popup-contents d-flex flex-column align-items-center mt-3">
                                    <span className='icon icon-ico-exclamation popup-icon error mb-4'></span>
                                    <div class="text-center">{t('new_ui_pj_lbl_return_home')}</div>
                                    <div class="col-md-12 text-center mb-3">
                                        <p>{t('new_ui_pj_lbl_return_home_confirmation')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions className='d-flex justify-content-center align-items-center bg-white border-0 popup-actions mb-2'>
                        <Button onClick={() => this.closeReturnHomeConfirmation()}>{t('lbl_attachment_no')}</Button>
                        <Button onClick={() => this.backToMainMenu()} primary>{t('lbl_attachment_yes')}</Button>
                    </Modal.Actions>
                </Modal>
            </>
        );
    }

    closeReturnHomeConfirmation = () => {
        this.setState({
            showBackToHomeConfirmation: false
        });
    }

    backToMainMenu = () => {
        this.setState({
            showBackToHomeConfirmation: false
        });
        this.props.backToMainMenu();
    }

    handleChatbotScroll = () => {
        this.props.scorolToTop();
    }

    t() {
        return false;
    }

    addParentJobTypeToFrequentlyUsedServices = (parentId) => {
        let { frequentlyUsedServices, availableServices } = this.state;
        let parentJob = _.find(frequentlyUsedServices, f => f.value === parentId);
        let selectedNode = _.find(availableServices, f => f.value === parentId);
        const { i18n } = this.props;
        if (!parentJob) {
            let services = frequentlyUsedServices;
            let parent = {
                label: selectedNode.label,
                value: selectedNode.value
            }
            if (this.state.isFromBot && i18n.language !== "en" && !i18n.language.toString().includes("en")) {

                this.applyTranslation(([selectedNode.label]), "en", i18n.language).then((trans) => {
                    if (trans.length > 0) {
                        parent.label = trans[0];

                        services.unshift(parent);
                        this.setState({
                            frequentlyUsedServices: services
                        })
                    }
                });
            } else {
                services.unshift(parent);
                this.setState({
                    frequentlyUsedServices: services
                })
            }

        }
    }

}



const mapStateToProps = (state, ownProps) => {
    try {
        return {
            jobTypes: state.Jobs.jobTypes,
            dateFormat: state.User.dateFormat,
            userRoles: state.User.userRoles,
            properties: state.Tenant.tenantProperties,
            isCurrentTenant: state.Tenant.isCurrentTenant,
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateProperties: properties => {
            dispatch({ type: "SET_PROPERTIES", properties: properties });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BotJobPostWizard));
import { Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import Loader from "react-loader-spinner";
import jobService from '../../../Services/JobService';
import React from 'react';
import { toast } from 'react-toastify';
import translateService from '../../../Services/TranslateService';
import './PropertyComponent.scss';

class PropertyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingPropertyComponent: false,
            propertyComponents: [],
            selectedComponent: '',
            isPropertyComponentNotFound: false,
            selectedComponentName: '',
            isComponentSelected: false,
            componentFeatures: [],
            selectedFeatureComponent: []

        };
    };



    componentWillMount() {
        this.getPropertyComponentData();
    }

    render() {
        const {
            propertyComponents,
            isComponentSelected,
            isLoadingPropertyComponent,
            isPropertyComponentNotFound,
            selectedComponentName,
            componentFeatures,
            selectedFeatureComponent
        } = this.state;
        const { t } = this.props.data;
        return (
            <>
                <div className="wizard-inner wzrd-post-a-job">
                    <div className="wizard-body">
                        {!isLoadingPropertyComponent && <div className="wizard-header">
                            <h2>{t != undefined ? t('lbl_property_componet') : "Choose components for your issue."}</h2>
                            <p className="usertip">({t != undefined ? t('lbl_chooseoption') : "choose an option"})</p>
                        </div>}
                        {!isLoadingPropertyComponent && <div className="form-group for-tgl-btn">
                            <ButtonToolbar className="radio-btn-group" id="propertyComponentGroup" key="propertyComponentGroup">

                                <ToggleButtonGroup type="radio" name="options" ref="propertyComponent" defaultValue={this.props.data.selectedComponent} onChange={(value) => this.handlePropertyComponentOnChange(value)}
                                >
                                    {propertyComponents.map((a, index) => {
                                        return <ToggleButton key={index} value={a.id}><span className="ba-icn ba-icn-ok"></span> {a.componentName}</ToggleButton>
                                    })
                                    }
                                </ToggleButtonGroup>

                            </ButtonToolbar>
                        </div>}
                        {isComponentSelected && <div>
                            <h3>Features for the {selectedComponentName}</h3>

                            <ButtonToolbar className="radio-btn-group" id="selectedComponentName" key="selectedComponentName">
                                <ToggleButtonGroup type="checkbox" name={selectedComponentName} ref={selectedComponentName} value={selectedFeatureComponent} defaultValue={selectedFeatureComponent} onChange={(value) => this.handlePropertyFeatureComponentOnChange(value)} >
                                    {componentFeatures.map((a, index) => {
                                        return <ToggleButton key={index} value={a.id}><span className="ba-icn ba-icn-ok"></span> {a.name}</ToggleButton>
                                    })
                                    }
                                </ToggleButtonGroup>
                            </ButtonToolbar>

                        </div>}
                        {isLoadingPropertyComponent && <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} />}
                        {isPropertyComponentNotFound && <div> <button type="button" className="btn btn-primary" onClick={this.generateComponent}>{t != undefined ? t('btn_generate_component') : "Genarate components"}</button></div>}
                    </div>
                    {!isLoadingPropertyComponent && <div className="wizard-actions">
                        <button type="button" className="btn btn-primary" onClick={() => this.next(t)} style={this.props.data.primaryButtonStyle}>{t != undefined ? t('btn_next') : "NEXT"}</button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.props.data.previousStep(this.props.data.index)} style={this.props.data.secondaryButtonStyle}>{t != undefined ? t('btn_back') : "BACK"}</button>
                    </div>
                    }
                </div>
            </>);
    }

    getPropertyComponentData() {
        const trgLng = window.lng == undefined ? 'en': window.lng;
        this.setState({
            isLoadingPropertyComponent: true
        });
        let result;
        jobService.GetPropertyComponent(this.props.data.propertyId).then((res) => {
                var list =[] ;
                result = res;
                if (res.length > 0) {
                    list = res.map(function(value) { return value['componentName']; }); 
                }
                 return this.applyTranslation(list, "en", trgLng);
            }).then(trans => {
                if(trans.length > 0){
                 result.map(function(value,index) { return value.componentName = trans[index]});
                }
                if(result.length > 0){
                    this.setState({
                        propertyComponents: result,
                        isLoadingPropertyComponent: false,
                        isPropertyComponentNotFound: false
                    });
                }
                else {
                    this.setState({
                        isLoadingPropertyComponent: false,
                        isPropertyComponentNotFound: true
                    });
                }
                this.updateSelected();

            });
        }              

    


    handlePropertyComponentOnChange = (value) => {
        const { propertyComponents } = this.state;
        const trgLng = window.lng == undefined ? 'en': window.lng;
        var name = propertyComponents.find(x => x.id == value).componentName;
        const features = propertyComponents.find(x => x.id == value).componentFeatures;
        var featureList =[];
            
        if (features.length > 0) {
            featureList = features.map(function(value) { return value['name']; }); 
            featureList.push(name);
        }
        this.applyTranslation(featureList, "en", trgLng).then(trans => {
            if(trans.length > 0){
                features.map(function(value,index) { return value.name = trans[index]});
                name = trans[trans.length-1];
            }
            if(features.length >0){
                this.setState({
                    selectedComponent: value,
                    selectedComponentName: name,
                    isComponentSelected: true,
                    componentFeatures: features,
                    selectedFeatureComponent: []
                });
            }
        })

    }

    handlePropertyFeatureComponentOnChange = (value) => {
        const { componentFeatures, selectedFeatureComponent } = this.state;
        //const features = propertyComponents.find(x=>x.id == value).componentFeatures;
        this.setState({
            selectedFeatureComponent: value
        });

    }

    updateSelected() {
        if (this.props.data.selectedComponent != '' && this.props.data.selectedComponent != null) {
            const trgLng = window.lng == undefined ? 'en': window.lng;
            const { propertyComponents } = this.state;
            var name = propertyComponents.find(x => x.id == this.props.data.selectedComponent).componentName;
            const features = propertyComponents.find(x => x.id == this.props.data.selectedComponent).componentFeatures;
            
            var featureList =[];
            
            if (features.length > 0) {
                featureList = features.map(function(value) { return value['name']; }); 
                featureList.push(name);
            }
            this.applyTranslation(featureList, "en", trgLng).then(trans => {
                if(trans.length > 0){
                    features.map(function(value,index) { return value.name = trans[index]});
                    name = trans[trans.length-1];
                }
                if(features.length >0){
                    this.setState({
                        selectedComponentName: name,
                        isComponentSelected: true,
                        componentFeatures: features,
                        selectedFeatureComponent: this.props.data.selectedFeatureComponent,
                        selectedComponent : this.props.data.selectedComponent
                    }); 
                }
            })

        }
    }

    generateComponent = () => {
        const { propertyComponents } = this.state;
        if (propertyComponents.length == 0) {
            var result;
            const trgLng = window.lng == undefined ? 'en': window.lng;
            jobService.CreatePropertyComponent(this.props.data.propertyId).then(res => {                
                    var list =[] ;
                    result = res;
                    if (res.length > 0) {
                        list = res.map(function(value) { return value['componentName']; }); 
                    }
                     return this.applyTranslation(list, "en", trgLng);
                }).then(trans => {
                    if(trans.length > 0){
                        result.map(function(value,index) { return value.componentName = trans[index]});
                       }
                       if(result.length > 0){
                        this.setState({
                            propertyComponents: result,
                            isLoadingPropertyComponent: false,
                            isPropertyComponentNotFound: false
                        });
                       }
                })


                }
           
        }
    

    next = (t) => {
        if (this.state.selectedComponent != '' && this.state.selectedFeatureComponent.length == 0) {
            toast.error((t != undefined ? t('lbl_feature_component_validation') : "Please select feature component"), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return false;
        }
        this.props.data.nextStep({ selectedComponent: this.state.selectedComponent, index: this.props.data.index, selectedFeatureComponent: this.state.selectedFeatureComponent,t:t });
    }

    applyTranslation(texts, sourceLng, targetLng){
        const requestModel = {
            text: texts,
            targetCode: targetLng,
            sourceCode : sourceLng
        };
        return translateService.translate(requestModel);
    }
}

export default PropertyComponent;
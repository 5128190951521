import React, { Component } from 'react';
import botService from '../../Services/BotService';
import LogMaintenanceBot from '../External/LogMaintenanceBot/LogMaintenanceBot';
import { Dropdown } from 'semantic-ui-react'
import './LogMaintenanceBotLayout.css';
import { withTranslation } from 'react-i18next';
import { any } from 'prop-types';
import "./Layout.scss";
import "../../Styles/style.scss";
class LogMaintenanceBotLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCustomThemeEnable:'',
      theme:{
        header:'',
        primaryColor:''
      },
      isMenuOptionSelected: false,
      style:{
        brand:'',
        brandBackground:'',
        headerBackground:{backgroundColor:''},
        primaryButtonStyle:{
            backgroundColor:'',
            border: ''
        },
        secondaryButtonStyle:{
            backgroundColor:'',
            border: ''
        },
        optionsStyle:{
            border: '',
            color: '',
            activeBackground:''
        }
      },
      countrySetting:{
        callingCode:'',
        countryCode:''
      },
      languageList:[{
        index:1,
        text:"English",
        value:"en"
      },
      {
        index:2,
        text:"Hindi हिन्दी",
        value:"hi"
      },
      {
        index:3,
        text:"Chinese (Cantonese) 粤语",
        value:"zh"
      },
      {
        index:4,
        text:"Arabic عربى",
        value:"ar"
      },
      {
        index:5,
        text:"Greek ελληνικά",
        value:"el"
      },
      {
        index:6,
        text:"Spanish español",
        value:"es"
      },
      {
        index:7,
        text:"Italian italiano",
        value:"it"
      },
      {
        index:8,
        text:"Punjabi ਪੰਜਾਬੀ",
        value:"pa"
      },
      {
        index:9,
        text:"Tagalog",
        value:"tl"
      },
      {
        index:10,
        text:"Vietnamese Tiếng Việt",
        value:"vi"
      },
      {
        index:11,
        text:"Chinese (Mandarin) 官话",
        value:"zh-tw"
      }
    ],
      lang:"en",
      chatbotSetting:{
        hideQandABot : false,
        hideCheckRentalStatus : false,
        hideKeyCollectionOptions : false,
        hideTroubleshootGuide : false,
        enableAIPoweredTroubleshootGuide : false,
        enableDraftJobFeature :false,
        linkedAgencies : []
      }
    }
    
  }

  componentDidMount(){
    this.getChatbotSettings();
    this.addBodyStyle();
  }
  render() {
      const { theme, isCustomThemeEnable,isMenuOptionSelected,style,countrySetting,languageList,chatbotSetting} = this.state;
    let { orgId } = this.props;
    let { isLanguageInitialized } = this.props;
    return <div className="chatbot-container">
            {/* <div className="chatbot-header " style={style.headerBackground}> */}
            <div className="chatbot-header ">
            <div className='app-logo text-center d-flex align-items-center justify-content-center'>
                    <img src="/images/app-logo.svg" height="25" alt="Client Logo"/>
            </div>
            {/* <h2 className="chatbot-header-title">{theme.header}</h2> */}
            </div>
            {(isCustomThemeEnable!='')?
            <LogMaintenanceBot
            isCustomThemeEnable={isCustomThemeEnable}
            organizationId={orgId}
            theme={theme}
            style={style}
            country={countrySetting}
            lang={this.props.lang}
            chatbotSetting={chatbotSetting}
            menuOptionSelected={(option) => this.setLanguageSelection(option)}
          ></LogMaintenanceBot>:""}
          <div className="chatbot-footer py-3">
            {(!isLanguageInitialized && !isMenuOptionSelected) ? <div className='d-flex justify-content-center'>
                <Dropdown
                    selectOnNavigation={false}
                    className='test test'
                    selection={(this.props.i18n.language == "en-US" || this.props.i18n.language == "en-GB" || this.props.i18n.language == undefined || this.props.i18n.language == null) ? "en" : this.props.i18n.language}
                    loading={false}
                    value={this.props.lang}
                    name='languageId'
                    options={languageList}
                    placeholder='Choose Language'
                    onChange={this.handleLanguageChange}
                />
            </div>
                : ''
            }
          </div>
          </div>
  }

  getChatbotSettings=()=>{
    let {orgId}=this.props;
    if(orgId){
    botService.getChatbotSettings(orgId).then(result => {
      if(result.chatbotSettingsModel){
       let newTheme ={
           header:(result.chatbotSettingsModel.chatbotHeader!=null)?result.chatbotSettingsModel.chatbotHeader:'',
           primaryColor : (result.chatbotSettingsModel.chatbotPrimaryColor!=null)?result.chatbotSettingsModel.chatbotPrimaryColor:'',          
       }
       let style={}
       if(result.chatbotSettingsModel.chatbotPrimaryColor){
         style ={
          brand:newTheme.primaryColor,
          brandBackground:newTheme.primaryColor+"33",
          headerBackground:{backgroundColor:newTheme.primaryColor},
          primaryButtonStyle:{
              backgroundColor:newTheme.primaryColor,
              border: "1px solid "+newTheme.primaryColor
          },
          secondaryButtonStyle:{
              color:newTheme.primaryColor,
              backgroundColor:newTheme.primaryColor+"33",
              border: "1px solid "+newTheme.primaryColor
          },
          optionsStyle:{
              border: "1px solid "+newTheme.primaryColor,
              color: newTheme.primaryColor,
              activeBackground:newTheme.primaryColor+"33"
          },
          isCustomThemeEnable: (result.chatbotSettingsModel.chatbotPrimaryColor!=null || result.chatbotSettingsModel.chatbotPrimaryColor!='')?true:false
      } 
       }
       
       var setting = {
         countryCode:result.chatbotSettingsModel.countryCode,
         callingCode:result.chatbotSettingsModel.callingCode,
       };

       var chatbotSetting = {
        hideQandABot:result.chatbotSettingsModel.hideQandABot,
        hideCheckRentalStatus:result.chatbotSettingsModel.hideCheckRentalStatus,
        hideKeyCollectionOptions:result.chatbotSettingsModel.hideKeyCollectionOptions,
        hideTroubleshootGuide:result.chatbotSettingsModel.hideTroubleshootGuide,
        enableAIPoweredTroubleshootGuide:result.chatbotSettingsModel.enableAIPoweredTroubleshootGuide,
        enableDraftJobFeature:result.chatbotSettingsModel.enableDraftJobFeature,
        defaultKeyCollectionOption:result.chatbotSettingsModel.defaultKeyCollectionOption,
        linkedAgencies: (result.chatbotSettingsModel.linkedAgencies != undefined && result.chatbotSettingsModel.linkedAgencies != null) ? result.chatbotSettingsModel.linkedAgencies:[],
      };

       this.setState({
           isCustomThemeEnable: (result.chatbotSettingsModel.chatbotPrimaryColor!=null || result.chatbotSettingsModel.chatbotPrimaryColor!='')?true:false,
           theme : newTheme,
           style:style,
           countrySetting:setting,
           chatbotSetting:chatbotSetting
       });

      }else{
       this.setState({
           isCustomThemeEnable: false,
       });
      }
   }, error => {
       console.log(error);
   })
  }
  }

  setLanguageSelection = (option) => {
     this.setState({ isMenuOptionSelected: option });
  }


  addBodyStyle=()=>{
    var element = document.body;
    element.style.cssText = 'overflow:hidden !important;';
    element.style.cssText = 'padding-top:0 !important; overflow:hidden !important; background: transparent !important;';
    document.documentElement.style.cssText = "background: transparent !important;";
  }

  handleLanguageChange= (event, props)=>{
    const {t,i18n,i18next}=this.props;
    i18n.changeLanguage(props.value);
    this.setState({lang:props.value});
    this.props.passLanguageChange(props.value);
  }

}

export default withTranslation()(LogMaintenanceBotLayout);
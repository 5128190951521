import React, { Component } from 'react';
import botService from '../../../Services/BotService';
import { AppConfiguration } from "read-appsettings-json";
import { AXIOS } from '../../../Utilities/HttpRequest/Axios.js';
import Loader from "react-loader-spinner";
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import '../../Layout/LogMaintenanceBotLayout.css';
import JobPostWizard from '../../Common/JobPostWizard/JobPostWizard';
import { connect } from 'react-redux';
import userService from '../../../Services/UserService';
import { ChatbotOptionType, MasterDataType, ChatbotLanguageOptionType } from '../../../Constants/Enums';
import PouchDbService from '../../../Services/PouchDbService';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import BotJobPostWizard from './JobPost/BotJobPost_Wizard';
import QandA_Wizard from './QandA/QandA_Wizard';
import RentalDetailsWizard from './RentalDetails/RentalDetailsWizard';
import OwnerApprovals_Wizard from './OwnerApprovals/OwnerApprovals_Wizard';
import { withTranslation } from 'react-i18next';
import propertyService from '../../../Services/PropertyService';
import translateService from '../../../Services/TranslateService';
import TrackIssueWizard from './TrackIssue/TrackIssueWizard'
class LogMaintenanceBot extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            hasMultipleProperty: false,
            properties: [],
            userName: '',
            stepFlow: [[0, "mainMenu"], [1, "mobileVerification"], [2, "confirmOTP"], [3, "propertySelect"], [4, "selectLogMaintenance"], [5, "selectTrackIssue"], [6, "selectRentalStatus"], [7, "selectQnA"], [8, "selectOwnerLogin"]],
            currentStep: 0,
            previousStep: 0,
            mobileNumberInput: "",
            emailAddressInput: "",
            otpDigit1: "",
            otpDigit2: "",
            otpDigit3: "",
            otpDigit4: "",
            showInvalidMobileError: false,
            showInvalidEmailError: false,
            showNoAssociatedPropertyError: false,
            showCannotProcessError: false,
            showInvalidOTPError: false,
            isMobileAuthentication: false,
            showSwitchAuthentication: false,
            showTimer: true,
            time: {},
            seconds: 120,
            enableResendOTP: false,
            remainingOTPAttempts: 3,
            loading: false,
            isUserExist: true,
            chatbotKey: "1234",
            tenantDetail: [],
            isBotTyping: false,
            isBotStep: true,
            isUserStep: false,
            mobileNumber: "",
            emailAddress: "",
            chatBody: [],
            botSteps: this.getBotSteps(),
            currentBotStep: 0,
            userSteps: this.getUserSteps(),
            currentUserStep: 0,
            selectedProperty: {},
            footerValue: "",
            verificationCode: "",
            isCustomThemeEnable: this.props.isCustomThemeEnable,
            theme: this.props.theme,
            style: this.props.style,
            userDetails: {
                applicationUserCountryId: '',
                applicationUserId: '',
                encryptedUserId: ''
            },
            jobTypes: [],
            botErrorMsg: '',
            showError: false,
            isLogMaintenance: false,
            isCheckRental: false,
            isQandA: false,
            isOwnerLogin: false,
            isTrackIssue: false,
            optionSelected: false,
            country: this.props.country,
            currentLang: 'en',
            chatbotSetting: this.props.chatbotSetting ? this.props.chatbotSetting : {
                hideQandABot: false,
                hideCheckRentalStatus: false,
                hideKeyCollectionOptions: false,
                hideTroubleshootGuide: false,
                enableDraftJobFeature: false,
                linkedAgencies: []
            },
            propertyList: [],
            fromQandAMessege: false
        }

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    chatContainer = React.createRef();
    mainViewContainer = React.createRef();
    jobPostContainer = React.createRef();
    qAndAContainer = React.createRef();
    ownerLoginContainer = React.createRef();
    trackIssueContainer = React.createRef();
    mobileNumberField = React.createRef();
    otpDigit1Ref = React.createRef();
    otpDigit2Ref = React.createRef();
    otpDigit3Ref = React.createRef();
    otpDigit4Ref = React.createRef();

    notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }
    componentDidMount() {
        localStorage.setItem("is_bna_bot_session", "FALSE");
        this.setTheme();
        //this.cretateMsg();
        this.addBodyStyle();
        // setTimeout(() => {
        //     this.GetTenantPropertiesByUserId();
        // }, 1000);
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    componentDidUpdate() {
        if (this.state.isLogMaintenance) {
            this.scrollToJobPost();
        } else if (this.state.isQandA) {
            this.scrollToQandA();
        } else if (this.state.isOwnerLogin) {
            this.scrollToOwnerLogin();
        } else if (this.state.isTrackIssue) {
            this.scrollToTrackIssue();
        }
    }

    addBodyStyle = () => {
        var element = document.body;
        element.style.cssText = 'overflow:hidden !important;';
        element.style.cssText = 'padding-top:0 !important; overflow:hidden !important; background: transparent !important; padding-bottom:0 !important;';
        document.documentElement.style.cssText = "background: transparent !important;";

    }
    setTheme = () => {
        const { style, theme, isCustomThemeEnable } = this.props;
        this.setState({
            style: style,
            theme: theme,
            isCustomThemeEnable: isCustomThemeEnable
        });
    }

    scrollToMyRef = () => {
        let jobPostHeight = (this.jobPostContainer.current && this.jobPostContainer.current.clientHeight) ? this.jobPostContainer.current.clientHeight : 0;
        if (jobPostHeight == 0) {
            const scroll =
                this.mainViewContainer.current.scrollHeight -
                this.mainViewContainer.current.clientHeight;
            this.mainViewContainer.current.scrollTo(0, scroll);
        }
    };


    applyTranslation(texts, sourceLng, targetLng) {
        const requestModel = {
            text: texts,
            targetCode: (targetLng == "en-US" || targetLng == "en-GB") ? "en" : targetLng,
            sourceCode: sourceLng
        };

        return translateService.translate(requestModel);
    }
    GetTenantPropertiesByUserId = () => {
        let none = [{ propertyId: 0, propertyName: '--None--', suburbId: '', location: '', address: '', propertyType: 'Residential' }];

        this.setState({ isPropertiesLoading: true });
        let properties;
        let propertyNames = [];
        const { i18n } = this.props;

        propertyService.GetTenantPropertiesByUserId()
            .then((res) => {
                properties = res.properties;
                if (res.properties.length > 0) {

                    properties.forEach(x => {
                        propertyNames.push(x.address ? x.address : " ");
                    });

                    this.applyTranslation(([propertyNames]), "en", i18n.language).then((trans) => {
                        if (trans.length > 0) {
                            trans.forEach((x, i) => {
                                properties[i].address = trans[i];
                            });
                        }

                        this.setState({
                            propertyList: properties,
                            propertyId: properties[0].propertyId
                        });
                        this.getPropertyDetailsByPropertyId(properties[0].propertyId);
                        this.setState({ isPropertiesLoading: false });
                    });


                } else {
                    this.setState({
                        propertyList: [],
                        propertyId: 0
                    });
                    //this.onChangeJobType(1);
                }
            });
    }

    getPropertyDetailsByPropertyId = (propertyId) => {
        const selectedProperty = this.state.propertyList.filter(a => a.propertyId == propertyId);
        if (selectedProperty && selectedProperty.length > 0 && selectedProperty[0].propertyId != null) {
            this.setState({
                propertyId: propertyId,
                //locationDetails: {},
                formData: {
                    address: selectedProperty[0].address,
                    location: selectedProperty[0].location,
                    propertyId: selectedProperty[0].propertyId,
                    propertyName: selectedProperty[0].propertyName,
                    suburbId: selectedProperty[0].suburbId,
                    propertyType: selectedProperty[0].propertyType
                },
                locationDetails: selectedProperty[0],
                jobForMe: {
                    propertyType: selectedProperty[0].propertyType,
                    address: selectedProperty[0].address,
                    latitude: selectedProperty[0].latitude,
                    location: selectedProperty[0].location,
                    longitude: selectedProperty[0].longitude,
                    placeId: selectedProperty[0].placeId,
                    suburbId: selectedProperty[0].suburbId,
                },
            });
            //this.getOrganizationSettings(selectedProperty[0].orgnizationId);
        } else {
            this.setState({
                propertyId: null,
                locationDetails: {},
                formData: {
                    address: '',
                    location: '',
                    propertyId: null,
                    propertyName: '',
                    suburbId: null,
                    propertyType: ''
                }
            });
        }
        setTimeout(() => {
            if (this.state.isJobForMe == 1) {
                this.setState({ keyCollectType: 1 });
            }
        }, 500);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds == 0) {
            clearInterval(this.timer);
            this.timeLimitReachAction();
        }
    }

    startTimer() {
        this.setState({
            seconds: 120
        });

        this.timer = 0;
        this.timer = setInterval(this.countDown, 1000);

    }

    stopTimer() {
        clearInterval(this.timer);
    }

    timeLimitReachAction() {
        let currentStep = this.state.currentStep;
        switch (currentStep) {
            case 1:
                this.setState({
                    showSwitchAuthentication: true
                });
                break;
            case 2:
                this.setState({
                    enableResendOTP: true
                });

                break;
        }
    }

    scrollToJobPost = () => {
        let jobPostHeight = (this.jobPostContainer.current && this.jobPostContainer.current.clientHeight) ? this.jobPostContainer.current.clientHeight : 0;
        if (jobPostHeight > 0) {
            let fullHeight = this.mainViewContainer.current.scrollHeight;
            let viewHeight = fullHeight - jobPostHeight;
            this.mainViewContainer.current.scrollTo(0, viewHeight);
        }
    }

    scrollToQandA = () => {
        let qAndA = (this.qAndAContainer.current && this.qAndAContainer.current.clientHeight) ? this.qAndAContainer.current.clientHeight : 0;
        if (qAndA > 0) {
            let fullHeight = this.mainViewContainer.current.scrollHeight;
            let viewHeight = fullHeight - qAndA;
            this.mainViewContainer.current.scrollTo(0, viewHeight);
        }
    }

    scrollToOwnerLogin = () => {
        let ownerLogin = (this.ownerLoginContainer.current && this.ownerLoginContainer.current.clientHeight) ? this.ownerLoginContainer.current.clientHeight : 0;
        if (ownerLogin > 0) {
            let fullHeight = this.mainViewContainer.current.scrollHeight;
            let viewHeight = fullHeight - ownerLogin;
            this.mainViewContainer.current.scrollTo(0, viewHeight);
        }
    }

    scrollToTrackIssue = () => {
        let trackIssue = (this.trackIssueContainer.current && this.trackIssueContainer.current.clientHeight) ? this.trackIssueContainer.current.clientHeight : 0;
        if (trackIssue > 0) {
            let fullHeight = this.mainViewContainer.current.scrollHeight;
            let viewHeight = fullHeight - trackIssue;
            this.mainViewContainer.current.scrollTo(0, viewHeight);
        }
    }


    render() {
        const { t, i18n, theme } = this.props;
        const currentStepIndex = this.state.currentStep;
        let step = this.showCurrentStep(currentStepIndex);
        return (
            <Form ref={this.mainViewContainer}>
                <div className='d-flex justify-content-center'><Loader className={'mb-3 mt-3'} visible={this.state.loading} type="ThreeDots" color={"#DB3D44"} height={10} width={100} /></div>
                <div className={`jobpost-wizard chatbot-body ${this.state.stepFlow[currentStepIndex][1] === 'confirmOTP' ? 'confirm-otp-custom' : ''}`}>
                    {step}
                </div>
                <div>
                    {(this.state.showError) ? <div className="botError">{this.state.botErrorMsg}</div> : ''}
                </div>
            </Form>);
        //let body = this.createBody();
        //let footer = this.createFotter();
        //return <>
        //    {body}
        //    {footer}
        //</>
    }

    showCurrentStep = (index) => {
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "mainMenu":
                return this.getMainMenu(index);
            case "mobileVerification":
                return this.getMobileAuthentication(index);
            case "confirmOTP":
                return this.getMobileOTPVerification(index);
            case "propertySelect":
                return this.selectPropertyStep(index);
            case "selectLogMaintenance":
                return this.addJobPostWizard();
            case "selectTrackIssue":
                return this.addTrackIssueWizard();
            case "selectRentalStatus":
                return this.addCheckRentalWizard();
            case "selectQnA":
                return this.addQandAWizard();
            case "selectOwnerLogin":
                return this.addOwnerApprovalWizard();
        }
    }

    getMainMenu = (index) => {
        const { chatBody } = this.state;
        const { style } = this.props;
        const { t } = this.props;

        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className={'job-post-type'}>
                    <div className="wizard-header">
                        <h1 className='mb-0 text-center'>{t('new_ui_hdr_welcome')}</h1>
                        <h1 className='mt-0 mb-4 text-center'>{t('new_ui_hdr_bna')}</h1>
                        <p className="userTip mt-3 mb-0">{t('new_ui_lbl_how_can_i_help')}</p>
                    </div>
                </div>
                <div id="chatbot-option-btn" className='mt-3'>
                    <div className="chat-options-div">
                        <ul className="chat-options-ul m-0 p-0 d-flex flex-column">
                            <li className="chat-options-li m-0">
                                <a className="chat-option w-100 d-flex align-items-center justify-content-between" style={style.optionsStyle} onClick={() => this.onMenuOptionSelect(ChatbotOptionType.LogMaintenance)}><div className='d-flex align-items-center'><span class="icon icon-ico-maintenance mr-3"></span> {t('cb_button_m_request')}</div> <span class="ba-icn ba-icn-right-arrow mr-2"></span></a>
                            </li>
                            <li className="chat-options-li m-0">
                                <a className="chat-option w-100 d-flex align-items-center justify-content-between" style={style.optionsStyle} onClick={() => this.onMenuOptionSelect(ChatbotOptionType.TrackIssue)}><div className='d-flex align-items-center'><span class="icon icon-ico-tracking mr-3"></span>{t('new_ui_cb_button_track_issue')}</div> <span class="ba-icn ba-icn-right-arrow mr-2"></span></a>
                            </li>
                            {!this.state.chatbotSetting.hideCheckRentalStatus &&
                                <li className="chat-options-li m-0">
                                    <a className="chat-option w-100 d-flex align-items-center justify-content-between" style={style.optionsStyle} onClick={() => this.onMenuOptionSelect(ChatbotOptionType.CheckRental)}><div className='d-flex align-items-center'><span class="icon icon-ico-cheque mr-3"></span>{t('cb_button_check_rental')}</div> <span class="ba-icn ba-icn-right-arrow mr-2"></span></a>
                                </li>}
                            {!this.state.chatbotSetting.hideQandABot &&
                                <li className="chat-options-li m-0">
                                    <a className="chat-option w-100 d-flex align-items-center justify-content-between" style={style.optionsStyle} onClick={() => this.onMenuOptionSelect(ChatbotOptionType.QandA)}><div className='d-flex align-items-center'><span class="icon icon-ico-question mr-3"></span>{t('cb_button_qanda')}</div> <span class="ba-icn ba-icn-right-arrow mr-2"></span></a>
                                </li>}
                            <li className="chat-options-li m-0">
                                <a className="chat-option w-100 d-flex align-items-center justify-content-between" style={style.optionsStyle} onClick={() => this.onMenuOptionSelect(ChatbotOptionType.OwnerLogin)}><div className='d-flex align-items-center'><span class="icon icon-ico-user mr-3"></span>{t('cb_button_owner_login')}</div> <span class="ba-icn ba-icn-right-arrow mr-2"></span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>);
    }

    onMenuOptionSelect = (option) => {
        const { chatBody } = this.state;
        const { t } = this.props;
        let fromQandAMessege =false;
        var currentStep = 0;
        var previousStep = 0;
        if (option == ChatbotOptionType.LogMaintenance) {
            this.setState({
                loading: false,
                isUserStep: true,
                isBotStep: false,
                isLogMaintenance: true,
                isMobileAuthentication: false,
                optionSelected: true
            });
        } else if (option == ChatbotOptionType.CheckRental) {
            this.setState({
                loading: false,
                isCheckRental: true,
                optionSelected: true
            });
        } else if (option == ChatbotOptionType.QandA) {
            this.setState({
                loading: false,
                isQandA: true,
                optionSelected: true
            });
        } else if (option == ChatbotOptionType.TrackIssue) {
            this.setState({
                loading: false,
                isTrackIssue: true,
                optionSelected: true
            });
        } else if (option == ChatbotOptionType.OwnerLogin) {
            this.setState({
                loading: false,
                isOwnerLogin: true,
                isMobileAuthentication: true,
                optionSelected: true
            });
        }

        if (localStorage.getItem("is_bna_bot_session") == "TRUE" && localStorage.getItem("userType") == 4) {
            if (option == ChatbotOptionType.LogMaintenance) {
                currentStep = 4;
            } else if (option == ChatbotOptionType.QandA) {
                currentStep = 7;                
            } else if (option == ChatbotOptionType.CheckRental) {
                currentStep = 6;
            } else if (option == ChatbotOptionType.TrackIssue) {
                currentStep = 3;
            }
            else if (option == ChatbotOptionType.OwnerLogin) {
                currentStep = 1;
            }
        } else if (localStorage.getItem("is_bna_bot_session") == "TRUE" && localStorage.getItem("userType") == 5) {
            if (option == ChatbotOptionType.OwnerLogin) {
                currentStep = 8;
            }else if(option == ChatbotOptionType.QandA && localStorage.getItem("userType") == 5){
                currentStep = 7;
            }else{
                currentStep = 1;
            }
        }
        else {
            if (option == ChatbotOptionType.QandA) {
                currentStep = 7;               
            } else {
                currentStep = 1;
                if (this.state.isLogMaintenance || option == ChatbotOptionType.LogMaintenance) {
                    this.startTimer();
                }
            }
        }


        this.props.menuOptionSelected(true);
        this.setState({ currentStep: currentStep, previousStep: previousStep,fromQandAMessege:fromQandAMessege });
    }

    getMobileAuthentication = (index) => {
        const { t } = this.props;
        var placeholder = t('new_ui_ph_enter_mobile_number').replace('-callingCode-', this.props.country.callingCode);
            
        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
            {(this.state.isMobileAuthentication) ?
            <div>
                <div className={'job-post-type'}>
                    <div className='custom-scroller ongoing-jobs'>
                        <div className="wizard-header">
                            <h2 className='font-22 font-medium mb-4'>{t('new_ui_hdr_mobile_verification')}</h2>
                            <h6 className="userTip">{t('new_ui_lbl_mobile_verification')}</h6>
                        </div>

                        <div className="form-group">
                            <input type="text" className="form-control" placeholder={placeholder} aria-label="enter mobile number"
                                value={this.state.mobileNumberInput} onChange={(evnt) => this.updateMobileNumber(evnt)} onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        this.validateMobileNumberAndOTP();
                                        ev.preventDefault();
                                    }
                                }} >
                            </input>
                        </div>
                        {(this.state.showInvalidMobileError) ? 
                            <div className="popup-contents d-flex flex-column align-items-center mt-5">
                                <span className='icon icon-ico-exclamation popup-icon error mb-4'></span>
                                <div class="text-center">{t('cb_step4_message')}</div>
                            </div>    
                        : ''}
                        {(this.state.showNoAssociatedPropertyError) ? 
                            <div className="popup-contents d-flex flex-column align-items-center mt-5">
                                <span className='icon icon-ico-exclamation popup-icon warning mb-4'></span>
                                <div class="text-center">{t('new_ui_lbl_no_property_message')} {this.state.mobileNumberInput}</div>
                            </div>                        
                        : ''}
                        {(this.state.showCannotProcessError) ? 
                            <div className="popup-contents d-flex flex-column align-items-center mt-5">
                                <span className='icon icon-ico-exclamation popup-icon warning mb-4'></span>
                                <div class="text-center">{t('cb_step5_message')}</div>
                            </div>  
                        : ''}
                    </div>
                </div>
                <div className="wizard-actions">
                    {(this.state.showSwitchAuthentication && this.state.isLogMaintenance) ? 
                        <p className="userTip font-14 text-dark">{t('new_ui_lbl_trouble_mobile_auth')} <a className="ml-2 common-link" onClick={() => this.enableEmailAuthentication()}>{t('new_ui_btn_switch_to_email_auth')}</a></p> 
                     : ''}
                    <button type="button" className="btn btn-primary mx-0" onClick={() => this.validateMobileNumberAndOTP()} style={this.state.primaryButtonStyle}>{t('new_ui_btn_get_otp')}</button>
                </div>
            </div>
            : <div>
                <div className={'job-post-type'}>
                    <div className='custom-scroller ongoing-jobs'>
                        <div className="wizard-header">
                            <h2 className='font-22 font-medium mb-4'>{t('new_ui_hdr_mobile_verification')}</h2>
                            <h6 className="userTip mb-4">{t('new_ui_lbl_email_verification')}</h6>
                        </div>

                        <div className="form-group mb-3">
                            <input type="text" className="form-control tenant-emailwrap" placeholder={t('new_ui_ph_enter_email')} aria-label="enter email address"
                                value={this.state.emailAddressInput} onChange={(evnt) => this.updateEmailAddress(evnt)} onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        this.validateMobileNumberAndOTP();
                                        ev.preventDefault();
                                        
                                    }
                                }} >
                            </input>
                        </div>
                        {(this.state.showInvalidEmailError) ? 
                            <div className="popup-contents d-flex flex-column align-items-center mt-3">
                                <span className='icon icon-ico-exclamation popup-icon error mb-2'></span>
                                <div class="text-center font-14">{t('new_ui_error_invalid_email')}</div>
                            </div>    
                        : ''}
                        {(this.state.showNoAssociatedPropertyError) ? 
                            <div className="popup-contents d-flex flex-column align-items-center mt-3">
                                <span className='icon icon-ico-exclamation popup-icon warning mb-2'></span>
                                <div class="text-center font-14">{t('new_ui_lbl_no_property_message')} {this.state.emailAddressInput}.</div>
                                <div class="text-center font-14">{t('new_ui_error_no_property')}</div>
                            </div>                        
                        : ''}
                        {(this.state.showCannotProcessError) ? 
                            <div className="popup-contents d-flex flex-column align-items-center mt-5">
                                <span className='icon icon-ico-exclamation popup-icon warning mb-4'></span>
                                <div class="text-center">{t('cb_step5_message')}</div>
                            </div>  
                        : ''}
                    </div>
                </div>
                <div className="wizard-actions">
                    {(this.state.showSwitchAuthentication) ? 
                        <p className="userTip font-14 text-dark">{t('new_ui_lbl_trouble_email_auth')} <a className="ml-2 common-link" onClick={() => this.enableMobileAuthentication()}>{t('new_ui_btn_switch_to_mobile_auth')}</a></p> 
                     : ''}
                    <button type="button" className="btn btn-primary mx-0" onClick={() => this.validateMobileNumberAndOTP()} style={this.state.primaryButtonStyle}>{t('new_ui_btn_get_otp_via_email')}</button>
                </div>
            </div>}
            </div>);
    }

    updateMobileNumber = (evt) => {
        this.setState({
            mobileNumberInput: evt.target.value
        });
    }

    updateEmailAddress = (evt) => {
        this.setState({
            emailAddressInput: evt.target.value
        });
    }

    enableMobileAuthentication = () => {

        this.setState({
            currentStep: 1,
            isMobileAuthentication: true,
            showSwitchAuthentication: false,
            enableResendOTP: false,
            remainingOTPAttempts: 3,
            showInvalidMobileError: false,
            showInvalidEmailError: false,
            showInvalidOTPError: false,
            showNoAssociatedPropertyError: false,
            showCannotProcessError:false,
            mobileNumberInput: "",
            emailAddressInput: ""
        });
        this.stopTimer();
        this.startTimer();
    }

    enableEmailAuthentication = () => {
        this.setState({
            currentStep: 1,
            isMobileAuthentication: false,
            showSwitchAuthentication: false,
            enableResendOTP: false,
            remainingOTPAttempts: 3,
            showInvalidMobileError: false,
            showInvalidEmailError: false,
            showInvalidOTPError: false,
            showNoAssociatedPropertyError: false,
            showCannotProcessError:false,
            mobileNumberInput: "",
            emailAddressInput: ""
        });
        this.stopTimer();
        this.startTimer();
    }

    getMobileOTPVerification = (index) => {
        const { t } = this.props;
        let { isMobileAuthentication, mobileNumber, emailAddress } = this.state;
        var resendPinHeader = t('new_ui_lbl_resend_pin_header').replace('-remainingAttempts-', this.state.remainingOTPAttempts);

        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className={'job-post-type'}>
                    <div className='text-left mb-3'>
                        <button type="button" className="btn btn-primary" onClick={() => this.goBackStep(1)}>{t('btn_back')}</button>
                    </div>
                    <div className="wizard-header">
                        <h2>{t('new_ui_hdr_mobile_verification')}</h2>
                        <p className="userTip">{t('new_ui_lbl_enter_otp')} {(isMobileAuthentication) ? mobileNumber : emailAddress}</p>
                    </div>

                    <div className="form-group d-flex justify-content-center align-items-center otp-fields mb-3">
                        <input type="number" maxLength="1" className="form-control" aria-label="enter otp"
                            value={this.state.otpDigit1} ref={this.otpDigit1Ref} onChange={(evnt) => this.updateOTPCode(evnt, 1)} onKeyUp={(ev) => {
                                this.otpInputKeypress(ev, 1);
                            }} >
                        </input>
                        <input type="number" maxLength="1" className="form-control" aria-label="enter otp"
                            value={this.state.otpDigit2} ref={this.otpDigit2Ref} onChange={(evnt) => this.updateOTPCode(evnt, 2)} onKeyUp={(ev) => {
                                this.otpInputKeypress(ev, 2);
                            }} >
                        </input>
                        <input type="number" maxLength="1" className="form-control" aria-label="enter otp"
                            value={this.state.otpDigit3} ref={this.otpDigit3Ref} onChange={(evnt) => this.updateOTPCode(evnt, 3)} onKeyUp={(ev) => {
                                this.otpInputKeypress(ev, 3);
                            }} >
                        </input>
                        <input type="number" maxLength="1" className="form-control" aria-label="enter otp"
                            value={this.state.otpDigit4} ref={this.otpDigit4Ref} onChange={(evnt) => this.updateOTPCode(evnt, 4)} onKeyUp={(ev) => {
                                this.otpInputKeypress(ev, 4);
                            }} >
                        </input>
                    </div>
                    {(this.state.showInvalidOTPError) ? <div className='text-danger text-center'>{resendPinHeader}</div> : ''}
                    {(this.state.showTimer) ? <div className='text-center'>{this.state.time.m}:{this.state.time.s}</div> : ''}
                </div>
                <div className="wizard-actions">
                    {(isMobileAuthentication) ?
                        <p className="userTip font-14 text-dark">{t('new_ui_lbl_resend_otp')} <a className={"ml-2 " + (!this.state.enableResendOTP ? 'disabled-link' : 'common-link')} onClick={() => this.resendOTP()}>{t('new_ui_btn_resend_otp')}</a></p>
                        : <p className="userTip font-14 text-dark">{t('new_ui_lbl_resend_otp')} <a className={"ml-2 " + (!this.state.enableResendOTP ? 'disabled-link' : 'common-link')} onClick={() => this.resendOTPViaEmail()}>{t('new_ui_btn_resend_otp_via_email')}</a></p>}
                    
                    {(isMobileAuthentication) ?
                        (this.state.isLogMaintenance) ? <p className="userTip font-14 text-dark">{t('new_ui_lbl_trouble_mobile_auth')} <a className="ml-2 common-link" onClick={() => this.enableEmailAuthentication()}>{t('new_ui_btn_switch_to_email_auth')}</a></p> : ''
                        : <p className="userTip font-14 text-dark">{t('new_ui_lbl_trouble_email_auth')} <a className="ml-2 common-link" onClick={() => this.enableMobileAuthentication()}>{t('new_ui_btn_switch_to_mobile_auth')}</a></p>}

                    <button type="button" className="btn btn-primary mx-0" disabled={this.state.enableResendOTP || this.state.remainingOTPAttempts <= 0} onClick={() => this.validateMobileNumberAndOTP()} style={this.state.primaryButtonStyle}>{t('new_ui_btn_verify_otp')}</button>
                </div>
            </div>);
    }

    updateOTPCode = (evt, key) => {
        let { otpDigit1, otpDigit2, otpDigit3, otpDigit4 } = this.state;
        let value = evt.target.value.slice(0, 1);
        let secondValue = evt.target.value.length > 1 ? evt.target.value.slice(1, 2) : "";
        let thirdValue = evt.target.value.length > 2 ? evt.target.value.slice(2, 3) : "";
        let forthValue = evt.target.value.length > 3 ? evt.target.value.slice(3, 4) : "";

        switch (key) {
            case 1:
                this.setState({
                    otpDigit1: value,
                    otpDigit2: secondValue != "" ? secondValue : otpDigit2,
                    otpDigit3: thirdValue != "" ? thirdValue : otpDigit3,
                    otpDigit4: forthValue != "" ? forthValue : otpDigit4
                });
                break;
            case 2:
                this.setState({
                    otpDigit2: value,
                    otpDigit3: secondValue != "" ? secondValue : otpDigit3,
                    otpDigit4: thirdValue != "" ? thirdValue : otpDigit4
                });
                break;
            case 3:
                this.setState({
                    otpDigit3: value,
                    otpDigit4: secondValue != "" ? secondValue : otpDigit4
                });
                break;
            case 4:
                this.setState({
                    otpDigit4: value
                });
                break;
        }
    }

    otpInputKeypress = (event, key) => {
        let { otpDigit1, otpDigit2, otpDigit3, otpDigit4 } = this.state;
        switch (key) {
            case 1:
                if (event.key === 'Enter') {
                    this.validateMobileNumberAndOTP();
                    event.preventDefault();
                } else if (otpDigit1.length === 1) {
                    this.otpDigit2Ref.current.focus();
                }
                break;
            case 2:
                if (event.key === 'Enter') {
                    this.validateMobileNumberAndOTP();
                    event.preventDefault();
                } else if (event.key === 'Backspace') {
                    this.otpDigit1Ref.current.focus();
                } else if (otpDigit2.length === 1) {
                    this.otpDigit3Ref.current.focus();
                }
                break;
            case 3:
                if (event.key === 'Enter') {
                    this.validateMobileNumberAndOTP();
                    event.preventDefault();
                } else if (event.key === 'Backspace') {
                    this.otpDigit2Ref.current.focus();
                } else if (otpDigit3.length === 1) {
                    this.otpDigit4Ref.current.focus();
                }
                break;
            case 4:
                if (event.key === 'Enter') {
                    this.validateMobileNumberAndOTP();
                    event.preventDefault();
                } else if (event.key === 'Backspace') {
                    this.otpDigit3Ref.current.focus();
                }
                break;
        }
    }

    validateMobileNumberAndOTP = () => {
        let { currentStep, mobileNumberInput, emailAddressInput, isMobileAuthentication, verificationCode, otpDigit1, otpDigit2, otpDigit3, otpDigit4, isLogMaintenance, isQandA, isCheckRental, isOwnerLogin, isTrackIssue } = this.state;
        let stepChange = false;
        var nextStep = 0;
        this.setState({ showInvalidMobileError: false, showInvalidEmailError: false, showInvalidOTPError: false });
        switch (currentStep) {
            case 1:
                var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
                this.stopTimer();
                if (isMobileAuthentication) {
                    if (regex.test(mobileNumberInput) && (mobileNumberInput.includes(this.props.country.callingCode) || mobileNumberInput.includes("+94") || mobileNumberInput.startsWith("0"))) {
                        this.setState({ mobileNumber: mobileNumberInput });
                        this.SendMobileOTP(mobileNumberInput);
                    } else {
                        stepChange = true;
                        this.setState({ showInvalidMobileError: true, showSwitchAuthentication: true });
                    }
                } else {
                    if (regexEmail.test(emailAddressInput)) {
                        this.setState({ emailAddress: emailAddressInput });
                        this.SendOTPViaEmail(emailAddressInput);
                    } else {
                        stepChange = true;
                        this.setState({ showInvalidEmailError: true, showSwitchAuthentication: true });
                    }
                }
                
                break;
            case 2:
                let inputCode = otpDigit1 + otpDigit2 + otpDigit3 + otpDigit4;
                let fromQandAMessege = false;
                this.stopTimer();
                if (verificationCode == inputCode) {
                    this.GetTenantPropertiesByUserId();
                    localStorage.setItem("is_bna_bot_session", "TRUE");
                    if (isLogMaintenance) {
                        nextStep = 4;
                    } else if (isQandA) {
                        nextStep = 7;
                        fromQandAMessege =true;
                    } else if (isCheckRental) {
                        nextStep = 3;
                    } else if (isTrackIssue) {
                        nextStep = 3;
                    } else if (isOwnerLogin) {
                        nextStep = 8;
                    }

                    this.setState({ currentStep: nextStep,fromQandAMessege:fromQandAMessege });

                } else {
                    let remainOTPAttempts = this.state.remainingOTPAttempts - 1;
                    this.setState({
                        showInvalidOTPError: true,
                        remainingOTPAttempts: remainOTPAttempts
                    });

                    if (remainOTPAttempts > 0) {
                        this.setState({
                            enableResendOTP: true
                        });
                    }
                }
                break;
        }

    }

    SendMobileOTP = (mobileNumber) => {

        this.setState({ loading: true, showNoAssociatedPropertyError: false, showCannotProcessError: false });

        if (this.state.isOwnerLogin) {
            botService.CheckOwnerExist(mobileNumber).then(res => {
                if (res.propertyOwners==null ||res.propertyOwners.length == 0) {

                    this.setState({
                        showNoAssociatedPropertyError: true,
                        loading: false
                    });
                }
                else {

                    var user = {};
                    if (this.props.organizationId) {
                        var orgwiseUser = res.propertyOwners.filter(m => m.organizationId == this.props.organizationId)[0];
                        if (orgwiseUser) {
                            user = {
                                applicationUserCountryId: orgwiseUser.applicationUserCountryId,
                                applicationUserId: orgwiseUser.applicationUserId,
                                encryptedUserId: orgwiseUser.encryptedUserId
                            }
                        } else {
                            user = {
                                applicationUserCountryId: res.propertyOwners[0].applicationUserCountryId,
                                applicationUserId: res.propertyOwners[0].applicationUserId,
                                encryptedUserId: res.propertyOwners[0].encryptedUserId
                            }
                        }
                    } else {
                        user = {
                            applicationUserCountryId: res.propertyOwners[0].applicationUserCountryId,
                            applicationUserId: res.propertyOwners[0].applicationUserId,
                            encryptedUserId: res.propertyOwners[0].encryptedUserId
                        }
                    }

                    this.setState({ userDetails: user });
                    localStorage.setItem("userType", 5);
                    localStorage.setItem("userId", user.encryptedUserId);
                    this.getActiveMasterDataFileVersion();
                    botService.SendVerificationCodeForOwner(mobileNumber).then(res => {
                        if (res.code) {
                            this.stopTimer();
                            this.startTimer();
                            this.setState({
                                verificationCode: res.code,
                                currentStep: 2,
                                remainingOTPAttempts: 3,
                                loading: false
                            });
                            this.otpDigit1Ref.current.focus();
                        } else {
                            this.setState({
                                showCannotProcessError: true,
                                loading: false
                            });
                        }
                    });
                }
            }, error => {

            });
        }
        else {
            botService.CheckUserExist(mobileNumber).then(res => {
                if (res.propertyTenants==null ||res.propertyTenants.length == 0) {

                    this.setState({
                        showNoAssociatedPropertyError: true,
                        showSwitchAuthentication: true,
                        loading: false
                    });
                }
                else {

                    var user = {};
                    if (this.props.organizationId) {
                        var orgwiseUser = res.propertyTenants.filter(m => m.organizationId == this.props.organizationId)[0];
                        if (orgwiseUser) {
                            user = {
                                applicationUserCountryId: orgwiseUser.applicationUserCountryId,
                                applicationUserId: orgwiseUser.applicationUserId,
                                encryptedUserId: orgwiseUser.encryptedUserId
                            }
                        } else {
                            user = {
                                applicationUserCountryId: res.propertyTenants[0].applicationUserCountryId,
                                applicationUserId: res.propertyTenants[0].applicationUserId,
                                encryptedUserId: res.propertyTenants[0].encryptedUserId
                            }
                        }
                    } else {
                        user = {
                            applicationUserCountryId: res.propertyTenants[0].applicationUserCountryId,
                            applicationUserId: res.propertyTenants[0].applicationUserId,
                            encryptedUserId: res.propertyTenants[0].encryptedUserId
                        }
                    }

                    this.setState({ userDetails: user });
                    localStorage.setItem("userType", 4);
                    localStorage.setItem("userId", user.encryptedUserId);
                    this.getActiveMasterDataFileVersion();
                    botService.SendVerificationCode(mobileNumber).then(res => {
                        if (res.code) {
                            this.stopTimer();
                            this.startTimer();
                            this.setState({
                                verificationCode: res.code,
                                currentStep: 2,
                                remainingOTPAttempts: 3,
                                loading: false
                            });
                            this.otpDigit1Ref.current.focus();
                        } else {
                            this.setState({
                                showCannotProcessError: true,
                                showSwitchAuthentication: true,
                                loading: false
                            });
                        }
                    });
                }
            }, error => {

            });
        }

    }

    resendOTP = () => {
        let { mobileNumber } = this.state;
        this.setState({
            showInvalidOTPError: false,
            showCannotProcessError: false
        });

        if (this.state.isOwnerLogin) {
            botService.SendVerificationCodeForOwner(mobileNumber).then(res => {
                if (res.code) {
                    this.stopTimer();
                    this.startTimer();
                    this.setState({
                        verificationCode: res.code,
                        enableResendOTP: false,
                        loading: false
                    });
                } else {
                    this.setState({
                        showCannotProcessError: true,
                        loading: false
                    });
                    this.addBotMsg(4);
                }
            });
        } else {
            botService.SendVerificationCode(mobileNumber).then(res => {
                if (res.code) {
                    this.stopTimer();
                    this.startTimer();
                    this.setState({
                        verificationCode: res.code,
                        enableResendOTP: false,
                        loading: false
                    });
                } else {
                    this.setState({
                        showCannotProcessError: true,
                        loading: false
                    });
                }
            });
        }
    }

    SendOTPViaEmail = (emailAddress) => {
        this.setState({ loading: true, showNoAssociatedPropertyError: false, showCannotProcessError: false });

        botService.CheckUserExistByEmail(emailAddress).then(res => {
            if (res.propertyTenants==null || res.propertyTenants.length == 0) {

                this.setState({
                    showNoAssociatedPropertyError: true,
                    showSwitchAuthentication: true,
                    loading: false
                });
            }
            else {

                var user = {};
                if (this.props.organizationId) {
                    var orgwiseUser = res.propertyTenants.filter(m => m.organizationId == this.props.organizationId)[0];
                    if (orgwiseUser) {
                        user = {
                            applicationUserCountryId: orgwiseUser.applicationUserCountryId,
                            applicationUserId: orgwiseUser.applicationUserId,
                            encryptedUserId: orgwiseUser.encryptedUserId
                        }
                    } else {
                        user = {
                            applicationUserCountryId: res.propertyTenants[0].applicationUserCountryId,
                            applicationUserId: res.propertyTenants[0].applicationUserId,
                            encryptedUserId: res.propertyTenants[0].encryptedUserId
                        }
                    }
                } else {
                    user = {
                        applicationUserCountryId: res.propertyTenants[0].applicationUserCountryId,
                        applicationUserId: res.propertyTenants[0].applicationUserId,
                        encryptedUserId: res.propertyTenants[0].encryptedUserId
                    }
                }

                this.setState({ userDetails: user });
                localStorage.setItem("userType", 4);
                localStorage.setItem("userId", user.encryptedUserId);
                this.getActiveMasterDataFileVersion();
                botService.SendVerificationCodeViaEmail(emailAddress).then(res => {
                    if (res.code) {
                        this.stopTimer();
                        this.startTimer();
                        this.setState({
                            verificationCode: res.code,
                            currentStep: 2,
                            remainingOTPAttempts: 3,
                            loading: false
                        });
                        this.otpDigit1Ref.current.focus();
                    } else {
                        this.setState({
                            showCannotProcessError: true,
                            showSwitchAuthentication: true,
                            loading: false
                        });
                    }
                });
            }
        }, error => {

        });

    }

    resendOTPViaEmail = () => {
        let { emailAddress } = this.state;
        this.setState({
            showInvalidOTPError: false,
            showCannotProcessError: false
        });

        botService.SendVerificationCodeViaEmail(emailAddress).then(res => {
            if (res.code) {
                this.stopTimer();
                this.startTimer();
                this.setState({
                    verificationCode: res.code,
                    enableResendOTP: false,
                    loading: false
                });
            } else {
                this.setState({
                    showCannotProcessError: true,
                    loading: false
                });
            }
        });
    }

    goBackStep = (step) => {
        this.resetValidations();
        this.setState({
            currentStep: step
        });
    }

    resetValidations = () => {
        this.setState({
            mobileNumberInput: "",
            emailAddressInput: "",
            verificationCode: "",
            otpDigit1: "",
            otpDigit2: "",
            otpDigit3: "",
            otpDigit4: "",
            loading: false,
            showInvalidMobileError: false,
            showInvalidEmailError: false,
            showNoAssociatedPropertyError: false,
            showCannotProcessError: false,
            showInvalidOTPError: false,
            showSwitchAuthentication: false,
            enableResendOTP: false,
            remainingOTPAttempts: 3
        });
    }

    directToMainMenu = () => {
        this.resetValidations();
        this.setState({
            currentStep: 0,
            isLogMaintenance: false,
            isCheckRental: false,
            isQandA: false,
            isTrackIssue: false,
            isOwnerLogin: false,
        });
        this.props.menuOptionSelected(false);
    }

    createBody = () => {
        const { chatBody } = this.state;
        const { theme } = this.props;
        return <div className="chatbot-body" ref={this.chatContainer}>
            <div id="chatbot-body">
                {chatBody}
            </div>
            <Loader visible={this.state.loading} type="ThreeDots" color={(theme.primaryColor != "") ? theme.primaryColor : "#DB3D44"} height={10} width={100} />
            <div>
                {(this.state.showError) ? <div className="botError">{this.state.botErrorMsg}</div> : ''}
            </div>
        </div>
    }

    createFotter = () => {
        return <div className="chatbot-footer"><input type="textarea" disabled={!this.state.isUserStep} className="chatbot-input" placeholder={this.state.isUserStep ? "Type the message ..." : ""} value={this.state.footerValue} onChange={(evnt) => this.updateUserInput(evnt)}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    this.submitUserInput();
                    ev.preventDefault();
                }
            }} />
            {this.state.isUserStep &&
                <button className="chatbot-button" disabled={!this.state.isUserStep} onClick={() => this.submitUserInput()} ><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 500 500"><g><g><polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75"></polygon></g></g></svg></button>
            }
        </div>
    }

    cretateMsg = () => {
        const { isBotStep, currentBotStep } = this.state;
        if (isBotStep) {
            this.addBotMsg(currentBotStep);
        }
    }

    addBotMsg = (currentBotStep) => {
        const { chatBody } = this.state;
        const { theme, style } = this.props;
        let botmsg = this.state.botSteps[currentBotStep];
        let message = botmsg.message;
        if (currentBotStep == 2) {
            message = botmsg.message.replace('-mobileNumber-', this.state.mobileNumber);
        }
        if (currentBotStep == 0) {
            message = botmsg.message.replace('-agency-', this.props.theme.header);
        }
        if (currentBotStep == 1 || currentBotStep == 3) {
            message = botmsg.message.replace('-callingCode-', this.props.country.callingCode);
        }

        let msg = <div className="chatbot-bot-icn"><div className="chatbot-bot-icn-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 254 254">
                <g id="Group_1" data-name="Group 1" transform="translate(-394 -292)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="89" cy="89" r="89" transform="translate(432 330)" fill={(theme.primaryColor) ? theme.primaryColor : "#eb4c4b"} />
                    <path id="Path_1" data-name="Path 1" d="M122.673,44.527A10.552,10.552,0,0,0,112.121,34H48.812A10.552,10.552,0,0,0,38.26,44.552v63.285a10.552,10.552,0,0,0,10.552,10.552H95.936l26.736,10.527V44.527ZM110.432,98c-.431.37-10.86,9.122-29.966,9.122S50.882,98.37,50.5,98a2.827,2.827,0,1,1,3.7-4.277c.16.136,9.467,7.766,26.317,7.766,17.048,0,26.219-7.692,26.305-7.766a2.827,2.827,0,1,1,3.7,4.277Z" transform="translate(440.902 337.911)" fill="#fff" />
                    <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(394 292)" fill="none" stroke="#00324b" stroke-width="3" opacity="0.29">
                        <circle cx="127" cy="127" r="127" stroke="none" />
                        <circle cx="127" cy="127" r="125.5" fill="none" />
                    </g>
                </g>
            </svg>
        </div>
            <div className="chatbot-bot-msg" style={style.headerBackground}>{message}</div>
        </div>
        chatBody.push(msg);
        this.setState({ chatBody: chatBody, isBotStep: false, isUserStep: true, loading: false }, () => this.scrollToMyRef());

        if (currentBotStep == 0) {
            this.addChatbotOptionButtons();
        }
        if (currentBotStep === 2) {
            this.addResendVerificationButton();
        }

    }
    addUserMsg = (input) => {
        const { chatBody, footerValue } = this.state;
        let msg = <div className="chatbot-user-icn">
            <div className="chatbot-user-icn-container">
                <img className="" src="/images/user.svg" alt="avatar" />
            </div>
            <div className="chatbot-user-msg">{input}</div>
        </div>
        chatBody.push(msg);
        this.setState({ chatBody: chatBody, isBotStep: true }, () => this.scrollToMyRef());
        this.validateUserInput();
    }

    updateUserInput = (evt) => {
        this.setState({
            footerValue: evt.target.value
        });

    }
    submitUserInput = () => {
        this.addUserMsg(this.state.footerValue);
        this.setState({
            isBotStep: true
        });
    }

    validateUserInput = () => {
        let { currentUserStep, footerValue, verificationCode, isLogMaintenance, isQandA, isCheckRental, isOwnerLogin, isTrackIssue } = this.state;
        let nextUserStep = 0, nextBotStep = 0;
        let stepChange = false;
        switch (currentUserStep) {
            case 0:
                var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
                if (regex.test(footerValue) && (footerValue.includes(this.props.country.callingCode) || footerValue.includes("+94") || footerValue.startsWith("0"))) {
                    this.setState({ mobileNumber: footerValue });
                    this.SendMobileVerificationCode(footerValue);
                } else {
                    stepChange = true;
                    nextBotStep = 3;
                    nextUserStep = currentUserStep;
                }
                break;
            case 1:
                if (verificationCode == footerValue) {
                    localStorage.setItem("is_bna_bot_session", "TRUE");
                    if (isLogMaintenance) {
                        this.addJobPostWizard();
                    } else if (isQandA) {
                        this.addQandAWizard();
                    } else if (isCheckRental) {
                        this.addCheckRentalWizard();
                    } else if (isTrackIssue) {
                        this.addTrackIssueWizard();
                    } else if (isOwnerLogin) {
                        this.addOwnerApprovalWizard();
                    }
                } else {
                    this.addResendButtonForInvalidCode();
                }
                break;
        }

        if (stepChange) {
            this.setState({
                footerValue: "",
                currentBotStep: nextBotStep,
                currentUserStep: nextUserStep
            });
            this.addBotMsg(nextBotStep);
        }

    }

    SendMobileVerificationCode = (mobileNumber) => {
        this.setState({ loading: true });

        if (this.state.isOwnerLogin) {
            botService.CheckOwnerExist(mobileNumber).then(res => {
                if (res.propertyOwners.length == 0) {

                    this.setState({
                        footerValue: "",
                        currentBotStep: 5,
                        currentUserStep: 0,
                        isBotStep: true,
                        loading: false
                    });
                    this.addBotMsg(5);
                }
                else {

                    var user = {};
                    if (this.props.organizationId) {
                        var orgwiseUser = res.propertyOwners.filter(m => m.organizationId == this.props.organizationId)[0];
                        if (orgwiseUser) {
                            user = {
                                applicationUserCountryId: orgwiseUser.applicationUserCountryId,
                                applicationUserId: orgwiseUser.applicationUserId,
                                encryptedUserId: orgwiseUser.encryptedUserId
                            }
                        } else {
                            user = {
                                applicationUserCountryId: res.propertyOwners[0].applicationUserCountryId,
                                applicationUserId: res.propertyOwners[0].applicationUserId,
                                encryptedUserId: res.propertyOwners[0].encryptedUserId
                            }
                        }
                    } else {
                        user = {
                            applicationUserCountryId: res.propertyOwners[0].applicationUserCountryId,
                            applicationUserId: res.propertyOwners[0].applicationUserId,
                            encryptedUserId: res.propertyOwners[0].encryptedUserId
                        }
                    }

                    this.setState({ userDetails: user });
                    localStorage.setItem("userType", 5);
                    localStorage.setItem("userId", user.encryptedUserId);
                    this.getActiveMasterDataFileVersion();
                    botService.SendVerificationCodeForOwner(mobileNumber).then(res => {
                        if (res.code) {
                            this.setState({
                                footerValue: "",
                                verificationCode: res.code,
                                currentBotStep: 2,
                                currentUserStep: 1,
                                isBotStep: true,
                                loading: false
                            });
                            this.addBotMsg(2);
                        } else {
                            this.setState({
                                footerValue: "",
                                currentBotStep: 4,
                                currentUserStep: 0,
                                isBotStep: true,
                                loading: false
                            });
                            this.addBotMsg(4);
                        }
                    });
                }
            }, error => {

            });
        }
        else {
            botService.CheckUserExist(mobileNumber).then(res => {
                if (res.propertyTenants.length == 0) {

                    this.setState({
                        footerValue: "",
                        currentBotStep: 5,
                        currentUserStep: 0,
                        isBotStep: true,
                        loading: false
                    });
                    this.addBotMsg(5);
                }
                else {

                    var user = {};
                    if (this.props.organizationId) {
                        var orgwiseUser = res.propertyTenants.filter(m => m.organizationId == this.props.organizationId)[0];
                        if (orgwiseUser) {
                            user = {
                                applicationUserCountryId: orgwiseUser.applicationUserCountryId,
                                applicationUserId: orgwiseUser.applicationUserId,
                                encryptedUserId: orgwiseUser.encryptedUserId
                            }
                        } else {
                            user = {
                                applicationUserCountryId: res.propertyTenants[0].applicationUserCountryId,
                                applicationUserId: res.propertyTenants[0].applicationUserId,
                                encryptedUserId: res.propertyTenants[0].encryptedUserId
                            }
                        }
                    } else {
                        user = {
                            applicationUserCountryId: res.propertyTenants[0].applicationUserCountryId,
                            applicationUserId: res.propertyTenants[0].applicationUserId,
                            encryptedUserId: res.propertyTenants[0].encryptedUserId
                        }
                    }

                    this.setState({ userDetails: user });
                    localStorage.setItem("userType", 4);
                    localStorage.setItem("userId", user.encryptedUserId);
                    this.getActiveMasterDataFileVersion();
                    botService.SendVerificationCode(mobileNumber).then(res => {
                        if (res.code) {
                            this.setState({
                                footerValue: "",
                                verificationCode: res.code,
                                currentBotStep: 2,
                                currentUserStep: 1,
                                isBotStep: true,
                                loading: false
                            });
                            this.addBotMsg(2);
                        } else {
                            this.setState({
                                footerValue: "",
                                currentBotStep: 4,
                                currentUserStep: 0,
                                isBotStep: true,
                                loading: false
                            });
                            this.addBotMsg(4);
                        }
                    });
                }
            }, error => {

            });
        }

    }
    getBotSteps() {

        const { t } = this.props;

        var chatSteps = [
            {
                id: '0',
                message: t('cb_step1_message')
            },
            {
                id: '1',
                message: t('cb_step2_message')
            },
            {
                id: '2',
                message: t('cb_step3_message')
            },
            {
                id: '3',
                message: t('cb_step4_message')
            },
            {
                id: '4',
                message: t('cb_step5_message')
            },
            {
                id: '5',
                message: t('cb_step6_message')
            }];
        return chatSteps;
    }

    getUserSteps() {
        var chatSteps = [
            {
                id: '0',
                step: 'getMobileNumber'
            },
            {
                id: '1',
                message: 'getVerificationCode'
            }];
        return chatSteps;
    }

    addJobPostWizard = () => {
        const { chatBody, style, jobTypes, theme } = this.state;
        return (
            <div className="job-post-div" ref={this.jobPostContainer}>
                {(jobTypes.length > 1) ? <BotJobPostWizard backtoList={(event) => this.backToList(event)}
                    isJobEdit={false}
                    jobEditid=""
                    customStyle={style}
                    isFromBot={true}
                    isTrackIssue={false}
                    isCurrentTenant={true}
                    isCustomThemeEnable={this.state.isCustomThemeEnable}
                    resetChat={() => this.resetChat()}
                    scorolToTop={() => this.scrollToJobPost()}
                    backToMainMenu={() => this.directToMainMenu()}
                    showChatbotError={(msg) => this.showError(msg)}
                    chatbotSetting={this.state.chatbotSetting}>
                </BotJobPostWizard>
                    :
                    <div className='d-flex justify-content-center mb-5'><Loader type="ThreeDots" height={10} width={100} /></div>}

            </div>);
    }

    backToList = (event) => {

    }

    showError = (msg) => {
        this.setState({ showError: true, botErrorMsg: msg });

        setTimeout(
            () => this.setState({ showError: false, botErrorMsg: "" }),
            3000
        );
    }


    addResendButtonForInvalidCode = () => {
        const { chatBody } = this.state;
        const { theme, style, t } = this.props;

        var resendBtn = <div>
            <div className="chatbot-bot-icn"><div className="chatbot-bot-icn-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 254 254">
                    <g id="Group_1" data-name="Group 1" transform="translate(-394 -292)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="89" cy="89" r="89" transform="translate(432 330)" fill={(theme.primaryColor) ? theme.primaryColor : "#eb4c4b"} />
                        <path id="Path_1" data-name="Path 1" d="M122.673,44.527A10.552,10.552,0,0,0,112.121,34H48.812A10.552,10.552,0,0,0,38.26,44.552v63.285a10.552,10.552,0,0,0,10.552,10.552H95.936l26.736,10.527V44.527ZM110.432,98c-.431.37-10.86,9.122-29.966,9.122S50.882,98.37,50.5,98a2.827,2.827,0,1,1,3.7-4.277c.16.136,9.467,7.766,26.317,7.766,17.048,0,26.219-7.692,26.305-7.766a2.827,2.827,0,1,1,3.7,4.277Z" transform="translate(440.902 337.911)" fill="#fff" />
                        <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(394 292)" fill="none" stroke="#00324b" stroke-width="3" opacity="0.29">
                            <circle cx="127" cy="127" r="127" stroke="none" />
                            <circle cx="127" cy="127" r="125.5" fill="none" />
                        </g>
                    </g>
                </svg>
            </div>
                <div className="chatbot-bot-msg" style={style.headerBackground} >{t('cb_lbl_resend_pin_header')}</div>
            </div>
            <div className="chat-options-div">
                <ul className="chat-options-ul">
                    <li className="chat-options-li">
                        <a className="chat-option" style={style.optionsStyle} onClick={() => this.resendPin()}>{t('cb_button_resend_pin')}</a>
                    </li>
                    <li className="chat-options-li">
                        <a className="chat-option" style={style.optionsStyle} onClick={() => this.resetChat()}>{t('cb_button_reset_chat')}</a>
                    </li>
                </ul>
            </div>
        </div>

        chatBody.push(resendBtn);
        this.setState({ isUserStep: false, footerValue: "" }, () => this.scrollToMyRef());
    }

    resendPin = () => {

        let { mobileNumber } = this.state;
        if (this.state.isOwnerLogin) {
            botService.SendVerificationCodeForOwner(mobileNumber).then(res => {
                if (res.code) {
                    this.setState({
                        footerValue: "",
                        verificationCode: res.code,
                        currentBotStep: 2,
                        currentUserStep: 1,
                        isBotStep: true,
                        loading: false
                    });
                    this.addBotMsg(2);
                } else {
                    this.setState({
                        footerValue: "",
                        currentBotStep: 4,
                        currentUserStep: 0,
                        isBotStep: true,
                        loading: false
                    });
                    this.addBotMsg(4);
                }
            });
        } else {
            botService.SendVerificationCode(mobileNumber).then(res => {
                if (res.code) {
                    this.setState({
                        footerValue: "",
                        verificationCode: res.code,
                        currentBotStep: 2,
                        currentUserStep: 1,
                        isBotStep: true,
                        loading: false
                    });
                    this.addBotMsg(2);
                } else {
                    this.setState({
                        footerValue: "",
                        currentBotStep: 4,
                        currentUserStep: 0,
                        isBotStep: true,
                        loading: false
                    });
                    this.addBotMsg(4);
                }
            });
        }
    }
    resetChat = () => {
        let chathistory = document.getElementById('chatbot-body');
        chathistory.innerHTML = "";
        this.setState({
            isBotStep: true,
            isUserStep: false,
            mobileNumber: "",
            chatBody: [],
            currentBotStep: 0,
            currentUserStep: 0,
            footerValue: "",
            isLogMaintenance: false,
            isCheckRental: false,
            isQandA: false,
            isOwnerLogin: false,
            isTrackIssue: false,
            optionSelected: false
        });

        this.addBotMsg(0);
    }

    backToMainMenu = () => {
        this.resetChat();
    }

    getActiveMasterDataFileVersion = () => {
        userService.GetActiveMasterDataFileVersion(MasterDataType.JobTypes)
            .then(response => {
                this.readJsonFile(response);
            });
    }

    readJsonFile(fileName) {
        let filePath = `${AppConfiguration.Setting().azureStorage.BlobStorageCdnEndPoint}${AppConfiguration.Setting().azureStorage.JobTypesContainerName}/${fileName}`;
        fetch(filePath)
            .then(response => response.json())
            .then(result => {
                this.addJobTypesToTheState(result);
            })
    }

    addChatbotOptionButtons = () => {
        const { chatBody } = this.state;
        const { style } = this.props;
        const { t } = this.props;
        var optionBtn = <div id="chatbot-option-btn">
            <div className="chat-options-div">
                <ul className="chat-options-ul">
                    {!this.state.chatbotSetting.hideQandABot &&
                        <li className="chat-options-li">
                            <a className="chat-option" style={style.optionsStyle} onClick={() => this.onOptionSelect(ChatbotOptionType.QandA)}>{t('cb_button_qanda')}</a>
                        </li>}

                    <li className="chat-options-li">
                        <a className="chat-option" style={style.optionsStyle} onClick={() => this.onOptionSelect(ChatbotOptionType.LogMaintenance)}>{t('cb_button_m_request')}</a>
                    </li>
                    {!this.state.chatbotSetting.hideCheckRentalStatus &&
                        <li className="chat-options-li">
                            <a className="chat-option" style={style.optionsStyle} onClick={() => this.onOptionSelect(ChatbotOptionType.CheckRental)}>{t('cb_button_check_rental')}</a>
                        </li>}
                    <li className="chat-options-li">
                        <a className="chat-option" style={style.optionsStyle} onClick={() => this.onOptionSelect(ChatbotOptionType.TrackIssue)}>{t('new_ui_cb_button_track_issue')}</a>
                    </li>
                    <li className="chat-options-li">
                        <a className="chat-option" style={style.optionsStyle} onClick={() => this.onOptionSelect(ChatbotOptionType.OwnerLogin)}>{t('cb_button_owner_login')}</a>
                    </li>
                </ul>
            </div>
        </div>

        chatBody.push(optionBtn);
        this.setState({ loading: false, isUserStep: false, footerValue: "" }, () => this.scrollToMyRef());
    }

    onOptionSelect = (option) => {
        const { chatBody } = this.state;
        const { t } = this.props;
        let userSelect = "";
        if (option == ChatbotOptionType.LogMaintenance) {
            userSelect = t('cb_button_m_request');
            this.setState({
                footerValue: "",
                currentBotStep: 1,
                currentUserStep: 0,
                isBotStep: false,
                loading: false,
                isUserStep: true,
                isLogMaintenance: true,
                optionSelected: true
            });
        } else if (option == ChatbotOptionType.CheckRental) {
            userSelect = t('cb_button_check_rental');
            this.setState({
                footerValue: "",
                currentBotStep: 1,
                currentUserStep: 0,
                isBotStep: false,
                loading: false,
                isUserStep: true,
                isCheckRental: true,
                optionSelected: true
            });
        } else if (option == ChatbotOptionType.QandA) {
            userSelect = t('cb_button_qanda');
            this.setState({
                footerValue: "",
                isBotStep: false,
                isUserStep: true,
                loading: false,
                isQandA: true,
                optionSelected: true
            });
        } else if (option == ChatbotOptionType.TrackIssue) {
            userSelect = t('new_ui_cb_button_track_issue');
            this.setState({
                footerValue: "",
                isBotStep: false,
                isUserStep: true,
                loading: false,
                isTrackIssue: true,
                optionSelected: true
            });
        } else if (option == ChatbotOptionType.OwnerLogin) {
            userSelect = t('cb_button_owner_login');
            this.setState({
                footerValue: "",
                isBotStep: false,
                isUserStep: true,
                loading: false,
                isOwnerLogin: true,
                optionSelected: true
            });
        }
        let chatoptions = document.getElementById('chatbot-option-btn');

        let msg = <div className="chatbot-user-icn">
            <div className="chatbot-user-icn-container">
                <img className="" src="/images/user.svg" alt="avatar" />
            </div>
            <div className="chatbot-user-msg">{userSelect}</div>
        </div>
        chatoptions.innerHTML = "";
        chatBody.push(msg);

        if (localStorage.getItem("is_bna_bot_session") == "TRUE") {
            if (option == ChatbotOptionType.LogMaintenance) {
                this.addJobPostWizard();
            } else if (option == ChatbotOptionType.QandA) {
                this.addQandAWizard();
            } else if (option == ChatbotOptionType.CheckRental) {
                this.addCheckRentalWizard();
            } else if (option == ChatbotOptionType.TrackIssue) {
                this.addTrackIssueWizard();
            } else if (option == ChatbotOptionType.OwnerLogin) {
                this.addOwnerApprovalWizard();
            }
        } else {
            if (option == ChatbotOptionType.QandA) {
                this.addQandAWizard();
            } else {
                this.addBotMsg(1);
            }
        }
    }

    addQandAWizard = () => {
        const { chatBody, style, theme, isCustomThemeEnable, userDetails,fromQandAMessege } = this.state;
        const { organizationId } = this.props;
        if(fromQandAMessege){
            this.setState({fromQandAMessege: false})
        }
        return (
            <div className="job-post-div" ref={this.qAndAContainer}>
                <QandA_Wizard
                    tenantId={userDetails.applicationUserId}
                    organizationId={organizationId}
                    theme={theme}
                    style={style}
                    isCustomThemeEnable={isCustomThemeEnable}
                    backToMainMenu={() => this.directToMainMenu()}
                    t={this.props.t}
                    i18n={this.props.i18n}
                    authenticate = {() => this.rederectToAuthenticate()}
                    fromQandAMessege ={fromQandAMessege}>
                </QandA_Wizard>
            </div>);

    }

    rederectToAuthenticate(){
        this.setState({
            currentStep: 1,
        });
    }

    addCheckRentalWizard = () => {
        const { chatBody, style, theme, isCustomThemeEnable, userDetails } = this.state;
        return (
            <div className="job-post-div" ref={this.qAndAContainer}>
                <RentalDetailsWizard
                    tenantId={userDetails.applicationUserId}
                    theme={theme}
                    style={style}
                    isCustomThemeEnable={isCustomThemeEnable}
                    backToMainMenu={() => this.directToMainMenu()}
                    t={this.props.t}
                    i18n={this.props.i18n}
                    propertyName={this.state.formData.propertyName}
                    propertyId={this.state.propertyId}>
                </RentalDetailsWizard>
            </div>);

    }

    addTrackIssueWizard = () => {
        const { chatBody, style, theme, isCustomThemeEnable, userDetails,propertyId } = this.state;
        return (
            <div className="job-post-div" ref={this.trackIssueContainer}>
                <TrackIssueWizard backtoList={(event) => this.backToList(event)}
                    tenantId={userDetails.applicationUserId}
                    theme={theme}
                    style={style}
                    isCustomThemeEnable={isCustomThemeEnable}
                    backToMainMenu={() => this.directToMainMenu()}
                    t={this.props.t}
                    i18n={this.props.i18n}
                    propertyId={propertyId}
                    showChatbotError={(msg) => this.showError(msg)}>
                </TrackIssueWizard>

                {/* <Loader type="ThreeDots" color={(theme.primaryColor != "") ? theme.primaryColor : "#DB3D44"} height={10} width={100} />} */}

            </div>);

    }

    addOwnerApprovalWizard = () => {
        const { chatBody, style, theme, isCustomThemeEnable, userDetails } = this.state;
        const { organizationId } = this.props;
        return (
            <div className="job-post-div" ref={this.ownerLoginContainer}>
                <OwnerApprovals_Wizard
                    ownerId={userDetails.applicationUserId}
                    organizationId={organizationId}
                    theme={theme}
                    style={style}
                    isCustomThemeEnable={isCustomThemeEnable}
                    backToMainMenu={() => this.directToMainMenu()}
                    scrollToWizardHeight={() => this.scrollToOwnerLogin()}
                    t={this.props.t}
                    i18n={this.props.i18n}>
                </OwnerApprovals_Wizard>
            </div>);

    }



    selectPropertyStep = (index) => {
        const { propertyList, propertyId, isJobForMe, jobForMe, formData, isPropertyOwner } = this.state;
        const forMeText = this.state.isJobForMeText;
        const { t } = this.props;
        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className="custom-scroller ongoing-jobs">
                    <div>
                        <div className="wizard-header mb-5">
                            <h2 htmlFor="property">{t('new_ui_pj_lbl_prop_select')}</h2>
                        </div>

                        <div className='mb-4'>
                            {(this.state.isPropertiesLoading) ?<div className='d-flex justify-content-center mb-3'> <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> </div> : ''}
                            {(!this.state.isPropertiesLoading) ?<ButtonToolbar className="radio-btn-group">
                                <ToggleButtonGroup className={"btn-group-bot selective-tiles w-100"} type="radio" name="options" ref="jobType" defaultValue={(propertyList && propertyList.length > 0 ) ? propertyList[0].propertyId : ""}
                                    onChange={(value) => { this.handlePropertyChange(value) }}>
                                    {propertyList.map((a, index) => {
                                        return <ToggleButton key={index} value={a.propertyId} className="d-flex align-items-center tile"><span className="icon icon-ico-location"></span><span className='address'> {a.address}</span></ToggleButton>
                                    })
                                    }
                                </ToggleButtonGroup>
                            </ButtonToolbar>:<></>}
                        </div>

                    </div>
                </div>
                <div className="wizard-actions">
                    <button type="button" className="btn btn-primary" onClick={() => this.nextStep()} style={this.state.primaryButtonStyle}>{t('new_ui_pj_btn_continue')}</button>
                </div>

            </div>);
    }

    nextStep = () => {
        let { isLogMaintenance, isQandA, isCheckRental, isOwnerLogin, isTrackIssue } = this.state;
        let nextStep = 0;
        if (this.ValidateCurrentStep()) {
            if (isLogMaintenance) {
                nextStep = 4;
            } else if (isQandA) {
                nextStep = 7;
            } else if (isCheckRental) {
                nextStep = 6;
            } else if (isTrackIssue) {
                nextStep = 5;
            } else if (isOwnerLogin) {
                nextStep = 8;
            }

            this.setState({
                currentStep: nextStep,
            });
        }
    }

    ValidateCurrentStep = () => {
        let {isCheckRental, isTrackIssue} = this.state;
        const { t } = this.props;

        if (isCheckRental || isTrackIssue) {
            if (this.state.propertyId == null || this.state.propertyId == undefined) {
                this.showError(t('new_ui_lbl_selectProperty_error_msg'));
                return false;
            } 
            return true;
        }
        return true;
    }

    handlePropertyChange = (props) => {
        this.setState({ property: props });
        this.getPropertyDetailsByPropertyId(props);
    }

    getJobTypesByCountryId = () => {
        PouchDbService.GetAllDocument(MasterDataType[MasterDataType.JobTypes]).then(response => {
            if (response.rows.length > 0) {
                this.addJobTypesToTheState(response);
            }
        })
    }

    addJobTypesToTheState = (jobTypesList) => {
        const { i18n } = this.props;
        const trgLng = i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1);
        let services = [];
        let allJobTypes = [];
        let jobTypes = [];
        if (jobTypesList.length) {
            jobTypes = jobTypesList.filter(element => element.CountryId == this.state.userDetails.applicationUserCountryId);
        } else {
            jobTypes = jobTypesList.rows.filter(element => element.doc.CountryId == this.state.userDetails.applicationUserCountryId);
        }

        jobTypes.forEach(element => {
            let subTypes = [];
            if (jobTypesList.length) {
                subTypes = jobTypes.filter(p => p.ParentJobTypeId == element.Id);
                allJobTypes.push({
                    id: element.Id,
                    title: (trgLng === 'En') ? element.Name : element[`Name${trgLng}`],
                    label: (trgLng === 'En') ? element.Name : element[`Name${trgLng}`],
                    description: element.Description,
                    tooltip: element.Tooltip,
                    jobCategory: element.JobGroupId,
                    parentRef: element.ParentJobTypeId,
                    countryId: element.CountryId,
                    hasChildRef: subTypes.length > 0
                });
            } else {
                subTypes = jobTypes.filter(p => p.doc.ParentJobTypeId == element.doc.Id);
                allJobTypes.push({
                    id: element.doc.Id,
                    title: (trgLng === 'En') ? element.Name : element[`Name${trgLng}`],
                    label: (trgLng === 'En') ? element.Name : element[`Name${trgLng}`],
                    description: element.doc.Description,
                    tooltip: element.doc.Tooltip,
                    jobCategory: element.doc.JobGroupId,
                    parentRef: element.doc.ParentJobTypeId,
                    countryId: element.doc.CountryId,
                    hasChildRef: subTypes.length > 0
                });
            }
        });

        allJobTypes.forEach(element => {

            let subTypes = allJobTypes.filter(a => a.parentRef == element.id);

            if (element.hasChildRef) {
                services.push({
                    parentJobType: element,
                    childJobTypes: subTypes
                });
            }
        });
        this.setState({ jobTypes: services });
        this.props.addJobTypes(services);
    }

    addResendVerificationButton = () => {
        const { chatBody } = this.state;
        const { theme, style, t } = this.props;

        var resendBtn = <div>
            <div className="chat-options-div">
                <ul className="chat-options-ul">
                    <li className="chat-options-li">
                        <a className="chat-option" style={style.optionsStyle} onClick={() => this.resendPin()}>{t('cb_button_resend_pin')}</a>
                    </li>
                </ul>
            </div>
        </div>

        chatBody.push(resendBtn);
        this.setState({ isUserStep: true, footerValue: "" }, () => this.scrollToMyRef());
    }
}

const mapStateToProps = (state) => {
    try {
        return {
            jobTypes: state.Jobs.jobTypes,
        };
    } catch (error) {
        console.log(error);
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addJobTypes: jobTypes => { dispatch({ type: 'SET_JOB_TYPES', jobTypes: jobTypes }) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LogMaintenanceBot));
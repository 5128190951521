import React from "react";
import { connect } from "react-redux";
import { WEEKDAYS, TIME_SLOTS, MONTHS } from "./Time";
import moment from 'moment';
import { DatetimePickerTrigger, DatetimePicker, DatetimeRangePickerTrigger } from 'rc-datetime-picker';
import TimePicker from 'rc-time-picker';
import { toast } from 'react-toastify';
import 'rc-time-picker/assets/index.css';
import './EventScheduler.scss'

class EventScheduler extends React.Component {

    state = {
        workDays: [],
        suggestedTimeSlots: [],
        bidDetails: {},
        totalJobTimeHoursPortion: 0,
        totalJobTimeMinutesPortion: 0,
        estimatedJobTimeHoursPortion: 0,
        estimatedJobTimeMinutesPortion: 0,
        travelTimeHoursPortion: 0,
        travelTimeMinutesPortion: 0
    };

    componentDidMount() {
        this.fillTimeSlots();
        this.convertJobTimesToHoursAndMinutes();
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }


    fillTimeSlots = () => {
        this.onPrepareGrid(this.props.fromDate, this.props.toDate);
        setTimeout(() => {
            this.fillAvailableTimeSlots();
            this.fillWorkingTimeSlots();
            this.fillSuggestedTimeSlots();
            this.fillNotAvailableTimeSlots();
        }, 500);
    }

    onPrepareGrid(fromDate, toDate) {
        let days = [];
        let endDate = new Date(toDate);
        let newToDate = new Date(endDate.setDate(endDate.getDate() + 1))
        let count = this.daysCountBetween(fromDate, newToDate)
        for (let index = 0; index < count; index++) {
            let fromDateObj = fromDate;
            let tomorrow = new Date(fromDateObj);
            tomorrow.setDate(tomorrow.getDate() + index);
            let dateObj = new Date(tomorrow);
            days.push({
                localDateString: dateObj.toLocaleDateString(),
                date: dateObj,
                day: WEEKDAYS[dateObj.getDay()].fullName,
                availableSlots: [],
                sugesstedSlots: [],
                notAvilableSlots: [],
                workingSlots: []
            })
        }
        this.setState({
            workDays: days
        });
    }

    fillAvailableTimeSlots = (workDays) => {
        this.props.availableTimeSlots.forEach(element => {
            let daysCount = this.daysCountBetween(element.from, element.to)
            if (daysCount == 0) {
                let dateObj = this.state.workDays.find(a => a.localDateString == element.from.toLocaleDateString())
                let fromHoursCount = element.from.getHours();
                let fromMinsCount = element.from.getMinutes();
                let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                let toMinsCount = element.to.getMinutes();
                let hours = [];
                for (let index = fromHoursCount; index <= toHoursCount; index++) {
                    if (index == fromHoursCount) {
                        let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                        if (fromMinsCount > 30) {
                            if (mins == 30) {
                                hours.push((index).toString() + 30);
                            }
                        } else {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push((index).toString() + 30);
                            } else {
                                hours.push((index).toString() + 30);
                            }
                        }
                    } else if (index == toHoursCount) {
                        let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                        if (toMinsCount > 30) {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push(index.toString() + '30');
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        } else {
                            if (mins == 30) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        }
                    } else {
                        if (index == 23 || index == 13) {
                            hours.push((index).toString() + '00');
                        } else {
                            hours.push((index).toString() + 0);
                        }
                        hours.push(index.toString() + '30')
                    }
                }
                dateObj.availableSlots = dateObj.availableSlots.concat(hours);
            } else {
                for (let i = 0; i <= daysCount; i++) {
                    let from = new Date(element.from);
                    let tomorrow = from;
                    tomorrow.setDate(tomorrow.getDate() + i);
                    let fromObj = new Date(tomorrow);
                    let fromHoursCount = element.from.getHours();
                    let fromMinsCount = element.from.getMinutes();
                    let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                    let toMinsCount = element.to.getMinutes();
                    let dateObj = this.state.workDays.find(a => a.localDateString == fromObj.toLocaleDateString());
                    let hours = []
                    if (daysCount == i) {
                        fromHoursCount = 8;
                    } else if (i > 0 && i != daysCount) {
                        fromHoursCount = 8;
                        toHoursCount = 17;
                    } else {
                        toHoursCount = 17;
                    }
                    for (let index = fromHoursCount; index <= toHoursCount; index++) {
                        if (index == fromHoursCount) {
                            let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                            if (fromMinsCount > 30) {
                                if (mins == 30) {
                                    hours.push((index).toString() + 30);
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push((index).toString() + 30);
                                }
                            } else {
                                //if(mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push((index).toString() + 30);
                                //} else {
                                //    if(index == 23 || index == 13) {
                                //        hours.push((index).toString() + '00');
                                //    } else {
                                //        hours.push((index).toString() + 0);
                                //    }
                                //    hours.push((index).toString() + 30);
                                //}
                            }
                        } else if (index == toHoursCount) {
                            let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                            if (toMinsCount > 30) {
                                if (mins == 0) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push(index.toString() + '30');
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            } else {
                                if (mins == 30 || toHoursCount == 17) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            }
                        } else {
                            if (index == 23 || index == 13) {
                                hours.push((index).toString() + '00');
                            } else {
                                hours.push((index).toString() + 0);
                            }
                            hours.push(index.toString() + '30')
                        }
                    }
                    dateObj.availableSlots = dateObj.availableSlots.concat(hours);
                }
            }
        })
    }

    updateAvailableTimeSlots = () => {
        let workDays = this.state.workDays;
        if (this.props.availableTimeSlots.length > 0) {
            this.props.availableTimeSlots.forEach(element => {
                let startTime = element.from;
                let endTime = element.to;
                let daysCount = this.daysCountBetween(startTime, endTime)
                var arrayIndex = workDays.findIndex(a => a.localDateString == startTime.toLocaleDateString())
                if (daysCount == 0 && arrayIndex >= 0) {
                    let dateObj = workDays[arrayIndex];
                    let fromHoursCount = element.from.getHours();
                    let fromMinsCount = element.from.getMinutes();
                    let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                    let toMinsCount = element.to.getMinutes();
                    let hours = []
                    for (let index = fromHoursCount; index <= toHoursCount; index++) {
                        let hoursIndex = index == 0 ? 24 : index;
                        if (index == fromHoursCount) {
                            let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                            if (fromMinsCount > 30) {
                                if (mins == 30) {
                                    hours.push((hoursIndex).toString() + 30);
                                }
                            } else {
                                if (mins == 0) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push((hoursIndex).toString() + 30);
                                } else {
                                    hours.push((hoursIndex).toString() + 30);
                                }
                            }
                        } else if (index == toHoursCount) {
                            let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                            if (toMinsCount > 30) {
                                if (mins == 0) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push(hoursIndex.toString() + '30');
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            } else {
                                if (mins == 30) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            }
                        } else {
                            if (index == 23 || index == 13) {
                                hours.push((index).toString() + '00');
                            } else {
                                hours.push((index).toString() + 0);
                            }
                            hours.push(hoursIndex.toString() + '30')
                        }
                    }
                    dateObj.availableSlots = dateObj.availableSlots.concat(hours);
                    workDays[arrayIndex] = dateObj;
                    this.setState({ workDays: workDays });
                } else if(arrayIndex >= 0) {
                    for (let i = 0; i <= daysCount; i++) {
                        let from = new Date(element.from);
                        let tomorrow = from;
                        tomorrow.setDate(tomorrow.getDate() + i);
                        let fromObj = new Date(tomorrow);
                        let fromHoursCount = element.from.getHours();
                        let fromMinsCount = element.from.getMinutes();
                        let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                        let toMinsCount = element.to.getMinutes();
                        let arrayIndex = this.state.workDays.findIndex(a => a.localDateString == fromObj.toLocaleDateString())
                        let dateObj = this.state.workDays[arrayIndex];
                        let hours = []
                        if (daysCount == i) {
                            fromHoursCount = 8;
                        } else if (i > 0 && i != daysCount) {
                            fromHoursCount = 8;
                            toHoursCount = 17;
                        } else {
                            toHoursCount = 17;
                        }
                        for (let index = fromHoursCount; index <= toHoursCount; index++) {
                            if (index == fromHoursCount) {
                                let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                                if (fromMinsCount > 30) {
                                    if (mins == 30) {
                                        hours.push((index).toString() + 30);
                                    } else {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                        hours.push((index).toString() + 30);
                                    }
                                } else {
                                    //if (mins == 0) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push((index).toString() + 30);
                                    //} else {
                                    //    if (index == 23 || index == 13) {
                                    //        hours.push((index).toString() + '00');
                                    //    } else {
                                    //        hours.push((index).toString() + 0);
                                    //    }
                                    //    hours.push((index).toString() + 30);
                                    //}
                                }
                            } else if (index == toHoursCount) {
                                let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                                if (toMinsCount > 30) {
                                    if (mins == 0) {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                        hours.push(index.toString() + '30');
                                    } else {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                    }
                                } else {
                                    if (mins == 30 || toHoursCount == 17) {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                    }
                                }
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push(index.toString() + '30')
                            }
                        }
                        dateObj.availableSlots = dateObj.availableSlots.concat(hours);
                        workDays[arrayIndex] = dateObj;
                        this.setState({ workDays: workDays });
                    }
                }
            })
        }
    }

    fillWorkingTimeSlots = (workDays) => {
        this.props.workingSlots.forEach(element => {
            if (!element || !element.fromDateTime) {
                return;
            }
            let daysCount = 0;
            if (daysCount == 0) {
                let startTime = element.from;
                let endTime = element.to;
                let dateObj = this.state.workDays.find(a => a.localDateString == startTime.toLocaleDateString())
                let fromHoursCount = startTime.getHours();
                let fromMinsCount = startTime.getMinutes();
                let toHoursCount = (endTime.getHours() == 0) ? 24 : endTime.getHours();
                let toMinsCount = endTime.getMinutes();
                let hours = []
                for (let index = fromHoursCount; index <= toHoursCount; index++) {
                    if (index == fromHoursCount) {
                        let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                        if (fromMinsCount > 30) {
                            if (mins == 30) {
                                hours.push((index).toString() + 30);
                            }
                        } else {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push((index).toString() + 30);
                            } else {
                                hours.push((index).toString() + 30);
                            }
                        }
                    } else if (index == toHoursCount) {
                        let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                        if (toMinsCount > 30) {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push(index.toString() + '30');
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        } else {
                            if (mins == 30) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        }
                    } else {
                        if (index == 23 || index == 13) {
                            hours.push((index).toString() + '00');
                        } else {
                            hours.push((index).toString() + 0);
                        }
                        hours.push(index.toString() + '30')
                    }
                }
                dateObj.workingSlots = dateObj.workingSlots.concat(hours);

            }
        });


    }

    updateWorkingTimeSlots = () => {
        let workDays = this.state.workDays;
        this.props.workingSlots.forEach(element => {

            if (!element || !element.fromDateTime) {
                return;
            }
            let startTime = element.from;
            let endTime = element.to;
            let daysCount = 0;
            let arrayIndex = workDays.findIndex(a => a.localDateString == startTime.toLocaleDateString());
            if (daysCount == 0 && arrayIndex >= 0) {             

                let dateObj = workDays[arrayIndex];
                let fromHoursCount = startTime.getHours();
                let fromMinsCount = startTime.getMinutes();
                let toHoursCount = (endTime.getHours() == 0) ? 24 : endTime.getHours();
                let toMinsCount = endTime.getMinutes();
                let hours = [];
                for (let index = fromHoursCount; index <= toHoursCount; index++) {
                    let hoursIndex = index == 0 ? 24 : index;
                    if (index == fromHoursCount) {
                        let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                        if (fromMinsCount > 30) {
                            if (mins == 30) {
                                hours.push((hoursIndex).toString() + 30);
                            }
                        } else {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push((hoursIndex).toString() + 30);
                            } else {
                                hours.push((hoursIndex).toString() + 30);
                            }
                        }
                    } else if (index == toHoursCount) {
                        let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                        if (toMinsCount > 30) {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push(hoursIndex.toString() + '30');
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        } else {
                            if (mins == 30) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        }
                    } else {
                        if (index == 23 || index == 13) {
                            hours.push((index).toString() + '00');
                        } else {
                            hours.push((index).toString() + 0);
                        }
                        hours.push(hoursIndex.toString() + '30')
                    }
                }

                dateObj.workingSlots = (dateObj && dateObj.workingSlots) ? dateObj.workingSlots.concat(hours): [];

                workDays[arrayIndex] = dateObj;
                this.setState({ workDays: workDays });
            }

        });

    }

    fillSuggestedTimeSlots = (workDays) => {
        let slots = this.props.suggestedTimeSlots;
        slots.forEach(element => {
            let daysCount = this.daysCountBetween(element.from, element.to)
            if (daysCount == 0) {
                let dateObj = this.state.workDays.find(a => a.localDateString == element.from.toLocaleDateString())
                let fromHoursCount = element.from.getHours();
                let fromMinsCount = element.from.getMinutes();
                let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                let toMinsCount = element.to.getMinutes();
                let hours = []
                for (let index = fromHoursCount; index <= toHoursCount; index++) {
                    if (index == fromHoursCount) {
                        let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                        if (fromMinsCount > 30) {
                            if (mins == 30) {
                                hours.push((index).toString() + 30);
                            }
                        } else {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push((index).toString() + 30);
                            } else {
                                hours.push((index).toString() + 30);
                            }
                        }
                    } else if (index == toHoursCount) {
                        let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                        if (toMinsCount > 30) {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push(index.toString() + '30');
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        } else {
                            if (mins == 30) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        }
                    } else {
                        if (index == 23 || index == 13) {
                            hours.push((index).toString() + '00');
                        } else {
                            hours.push((index).toString() + 0);
                        }
                        hours.push(index.toString() + '30')
                    }
                }
                dateObj.sugesstedSlots = dateObj.sugesstedSlots.concat(hours);
            } else {
                for (let i = 0; i <= daysCount; i++) {
                    let from = new Date(element.from);
                    let tomorrow = from;
                    tomorrow.setDate(tomorrow.getDate() + i);
                    let fromObj = new Date(tomorrow);
                    let fromHoursCount = element.from.getHours();
                    let fromMinsCount = element.from.getMinutes();
                    let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                    let toMinsCount = element.to.getMinutes();
                    let dateObj = this.state.workDays.find(a => a.localDateString == fromObj.toLocaleDateString());
                    let hours = []
                    if (daysCount == i) {
                        fromHoursCount = 8;
                    } else {
                        toHoursCount = 17;
                    }
                    for (let index = fromHoursCount; index <= toHoursCount; index++) {
                        if (index == fromHoursCount) {
                            let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                            if (fromMinsCount > 30) {
                                if (mins == 30) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push((index).toString() + 30);
                                }
                            } else {
                                if (fromHoursCount == 8) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                                if (!(toMinsCount < 30) || toHoursCount == 17) {
                                    hours.push((index).toString() + 30);
                                }
                                if (fromHoursCount + 1 == toHoursCount) {
                                    hours.push((index).toString() + 30);
                                }
                            }
                        } else if (index == toHoursCount) {
                            let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                            if (toMinsCount > 30) {
                                if (mins == 0) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push(index.toString() + '30');
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        } else {
                            if (index == 23 || index == 13) {
                                hours.push((index).toString() + '00');
                            } else {
                                hours.push((index).toString() + 0);
                            }
                            hours.push(index.toString() + '30')
                        }
                    }
                    dateObj.sugesstedSlots = dateObj.sugesstedSlots.concat(hours);
                }
            }
        })
    }

    updateSuggestedTimeSlots = () => {
        let workDays = this.state.workDays;
        if (this.props.suggestedTimeSlots.length > 0) {
            let suggesSlots = this.props.suggestedTimeSlots;
            suggesSlots.forEach(element => {
                if (!element.isDeletedEvent) {
                    let daysCount = this.daysCountBetween(element.from, element.to)
                    if (daysCount == 0) {
                        let arrayIndex = workDays.findIndex(a => a.localDateString == element.from.toLocaleDateString())
                        let dateObj = workDays[arrayIndex];
                        let fromHoursCount = element.from.getHours();
                        let fromMinsCount = element.from.getMinutes();
                        let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                        let toMinsCount = element.to.getMinutes();
                        let hours = []
                        for (let index = fromHoursCount; index <= toHoursCount; index++) {
                            if (index == fromHoursCount) {
                                let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                                if (fromMinsCount > 30) {
                                    if (mins == 30) {
                                        hours.push((index).toString() + 30);
                                    }
                                } else {
                                    if (mins == 0) {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                        hours.push((index).toString() + 30);
                                    } else {
                                        hours.push((index).toString() + 30);
                                    }
                                }
                            } else if (index == toHoursCount) {
                                let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                                if (toMinsCount > 30) {
                                    if (mins == 0) {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                        hours.push(index.toString() + '30');
                                    } else {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                    }
                                } else {
                                    if (mins == 30) {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                    }
                                }
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push(index.toString() + '30')
                            }
                        }
                        dateObj.sugesstedSlots = dateObj.sugesstedSlots.concat(hours);
                        workDays[arrayIndex] = dateObj;
                        this.setState({ workDays: workDays });
                    } else {
                        for (let i = 0; i <= daysCount; i++) {
                            let from = new Date(element.from);
                            let tomorrow = from;
                            tomorrow.setDate(tomorrow.getDate() + i);
                            let fromObj = new Date(tomorrow);
                            let fromHoursCount = element.from.getHours();
                            let fromMinsCount = element.from.getMinutes();
                            let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                            let toMinsCount = element.to.getMinutes();
                            let arrayIndex = this.state.workDays.findIndex(a => a.localDateString == fromObj.toLocaleDateString())
                            let dateObj = this.state.workDays[arrayIndex];
                            let hours = []
                            if (daysCount == i) {
                                fromHoursCount = 8;
                            } else {
                                toHoursCount = 17;
                            }
                            for (let index = fromHoursCount; index <= toHoursCount; index++) {
                                if (index == fromHoursCount) {
                                    let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                                    if (fromMinsCount > 30) {
                                        if (mins == 30) {
                                            if (index == 23 || index == 13) {
                                                hours.push((index).toString() + '00');
                                            } else {
                                                hours.push((index).toString() + 0);
                                            }
                                            hours.push((index).toString() + 30);
                                        }
                                    } else {
                                        if (mins == 0) {
                                            if (index == 23 || index == 13) {
                                                hours.push((index).toString() + '00');
                                            } else {
                                                hours.push((index).toString() + 0);
                                            }
                                            hours.push((index).toString() + 30);
                                        } else {
                                            if (fromHoursCount == 8) {
                                                if (index == 23 || index == 13) {
                                                    hours.push((index).toString() + '00');
                                                } else {
                                                    hours.push((index).toString() + 0);
                                                }
                                            }
                                            if (!(toMinsCount < 30) || toHoursCount == 17) {
                                                hours.push((index).toString() + 30);
                                            }
                                            if (fromHoursCount + 1 == toHoursCount) {
                                                hours.push((index).toString() + 30);
                                            }
                                        }
                                    }
                                } else if (index == toHoursCount) {
                                    let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                                    if (toMinsCount > 30) {
                                        if (mins == 0) {
                                            if (index == 23 || index == 13) {
                                                hours.push((index).toString() + '00');
                                            } else {
                                                hours.push((index).toString() + 0);
                                            }
                                            hours.push(index.toString() + '30');
                                        } else {
                                            if (index == 23 || index == 13) {
                                                hours.push((index).toString() + '00');
                                            } else {
                                                hours.push((index).toString() + 0);
                                            }
                                        }
                                    } else {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                    }
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push(index.toString() + '30')
                                }
                            }
                            dateObj.sugesstedSlots = dateObj.sugesstedSlots.concat(hours);
                            workDays[arrayIndex] = dateObj;
                            this.setState({ workDays: workDays });
                        }
                    }
                }
            })
        }
    }

    fillNotAvailableTimeSlots = (workDays) => {
        this.props.notAvailableTimeSlots.forEach(element => {
            let daysCount = this.daysCountBetween(element.from, element.to)
            if (daysCount == 0) {
                let dateObj = this.state.workDays.find(a => a.localDateString == element.from.toLocaleDateString())
                let fromHoursCount = element.from.getHours();
                let fromMinsCount = element.from.getMinutes();
                let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                let toMinsCount = element.to.getMinutes();
                let hours = []
                for (let index = fromHoursCount; index <= toHoursCount; index++) {
                    if (index == fromHoursCount) {
                        let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                        if (fromMinsCount > 30) {
                            if (mins == 30) {
                                hours.push((index).toString() + 30);
                            }
                        } else {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push((index).toString() + 30);
                            } else {
                                hours.push((index).toString() + 30);
                            }
                        }
                    } else if (index == toHoursCount) {
                        let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                        if (toMinsCount > 30) {
                            if (mins == 0) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push(index.toString() + '30');
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        } else {
                            if (mins == 30) {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                            }
                        }
                    } else {
                        if (index == 23 || index == 13) {
                            hours.push((index).toString() + '00');
                        } else {
                            hours.push((index).toString() + 0);
                        }
                        hours.push(index.toString() + '30')
                    }
                }
                dateObj.notAvilableSlots = dateObj.notAvilableSlots.concat(hours);
            } else {
                for (let i = 0; i <= daysCount; i++) {
                    let from = new Date(element.from);
                    let tomorrow = from;
                    tomorrow.setDate(tomorrow.getDate() + i);
                    let fromObj = new Date(tomorrow);
                    let fromHoursCount = element.from.getHours();
                    let fromMinsCount = element.from.getMinutes();
                    let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                    let toMinsCount = element.to.getMinutes();
                    let dateObj = this.state.workDays.find(a => a.localDateString == fromObj.toLocaleDateString());
                    let hours = []
                    if (daysCount == i) {
                        fromHoursCount = 8;
                    } else if (i > 0 && i != daysCount) {
                        fromHoursCount = 8;
                        toHoursCount = 17;
                    } else {
                        toHoursCount = 17;
                    }
                    for (let index = fromHoursCount; index <= toHoursCount; index++) {
                        if (index == fromHoursCount) {
                            let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                            if (fromMinsCount > 30) {
                                if (mins == 30) {
                                    hours.push((index).toString() + 30);
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push((index).toString() + 30);
                                }
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push((index).toString() + 30);
                            }
                        } else if (index == toHoursCount) {
                            let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                            if (toMinsCount > 30) {
                                if (mins == 0) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push(index.toString() + '30');
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            } else {
                                if (mins == 30 || toHoursCount == 17) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            }
                        } else {
                            if (index == 23 || index == 13) {
                                hours.push((index).toString() + '00');
                            } else {
                                hours.push((index).toString() + 0);
                            }
                            hours.push(index.toString() + '30')
                        }
                    }
                    dateObj.notAvilableSlots = dateObj.notAvilableSlots.concat(hours);
                }
            }
        })
    }

    updateNotAvailableTimeSlots = () => {
        let workDays = this.state.workDays;
        if (this.props.notAvailableTimeSlots.length > 0) {
            this.props.notAvailableTimeSlots.forEach(element => {
                let daysCount = this.daysCountBetween(element.from, element.to)
                if (daysCount == 0) {
                    let arrayIndex = workDays.findIndex(a => a.localDateString == element.from.toLocaleDateString())
                    let dateObj = workDays[arrayIndex];
                    let fromHoursCount = element.from.getHours();
                    let fromMinsCount = element.from.getMinutes();
                    let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                    let toMinsCount = element.to.getMinutes();
                    let hours = []
                    for (let index = fromHoursCount; index <= toHoursCount; index++) {
                        if (index == fromHoursCount) {
                            let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                            if (fromMinsCount > 30) {
                                if (mins == 30) {
                                    hours.push((index).toString() + 30);
                                }
                            } else {
                                if (mins == 0) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push((index).toString() + 30);
                                } else {
                                    hours.push((index).toString() + 30);
                                }
                            }
                        } else if (index == toHoursCount) {
                            let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                            if (toMinsCount > 30) {
                                if (mins == 0) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push(index.toString() + '30');
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            } else {
                                if (mins == 30) {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                }
                            }
                        } else {
                            if (index == 23 || index == 13) {
                                hours.push((index).toString() + '00');
                            } else {
                                hours.push((index).toString() + 0);
                            }
                            hours.push(index.toString() + '30')
                        }
                    }
                    if (dateObj !== undefined) {
                        dateObj.notAvilableSlots = dateObj.notAvilableSlots.concat(hours);
                        workDays[arrayIndex] = dateObj;
                        this.setState({ workDays: workDays });
                    }
                } else {
                    for (let i = 0; i <= daysCount; i++) {
                        let from = new Date(element.from);
                        let tomorrow = from;
                        tomorrow.setDate(tomorrow.getDate() + i);
                        let fromObj = new Date(tomorrow);
                        let fromHoursCount = element.from.getHours();
                        let fromMinsCount = element.from.getMinutes();
                        let toHoursCount = (element.to.getHours() == 0) ? 24 : element.to.getHours();
                        let toMinsCount = element.to.getMinutes();
                        let arrayIndex = this.state.workDays.findIndex(a => a.localDateString == fromObj.toLocaleDateString())
                        let dateObj = this.state.workDays[arrayIndex];
                        let hours = []
                        if (daysCount == i) {
                            fromHoursCount = 8;
                        } else if (i > 0 && i != daysCount) {
                            fromHoursCount = 8;
                            toHoursCount = 17;
                        } else {
                            toHoursCount = 17;
                        }
                        for (let index = fromHoursCount; index <= toHoursCount; index++) {
                            if (index == fromHoursCount) {
                                let mins = (Math.round(fromMinsCount / 30) * 30) % 60;
                                if (fromMinsCount > 30) {
                                    if (mins == 30) {
                                        hours.push((index).toString() + 30);
                                    } else {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                        hours.push((index).toString() + 30);
                                    }
                                } else {
                                    if (index == 23 || index == 13) {
                                        hours.push((index).toString() + '00');
                                    } else {
                                        hours.push((index).toString() + 0);
                                    }
                                    hours.push((index).toString() + 30);
                                }
                            } else if (index == toHoursCount) {
                                let mins = (Math.round(toMinsCount / 30) * 30) % 60;
                                if (toMinsCount > 30) {
                                    if (mins == 0) {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                        hours.push(index.toString() + '30');
                                    } else {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                    }
                                } else {
                                    if (mins == 30 || toHoursCount == 17) {
                                        if (index == 23 || index == 13) {
                                            hours.push((index).toString() + '00');
                                        } else {
                                            hours.push((index).toString() + 0);
                                        }
                                    }
                                }
                            } else {
                                if (index == 23 || index == 13) {
                                    hours.push((index).toString() + '00');
                                } else {
                                    hours.push((index).toString() + 0);
                                }
                                hours.push(index.toString() + '30')
                            }
                        }
                        if (dateObj !== undefined) {
                            dateObj.notAvilableSlots = dateObj.notAvilableSlots.concat(hours);
                            workDays[arrayIndex] = dateObj;
                            this.setState({ workDays: workDays });
                        }

                    }
                }
            })
        }
    }

    onCLickAddNewSuggestedTimeSlots = () => {
        let slots = this.props.suggestedTimeSlots;
        slots.push({ isUpdated: false, isSingle: false, from: new Date(), to: new Date() });
        this.props.updateState('suggestedTimeSlots', slots);
    }

    daysCountBetween = (date1, date2) => {
        let a = date1;
        let b = date2;
        const ONE_DAY = 1000 * 60 * 60 * 24;
        const differenceMs = Math.abs(a - b);
        return Math.round(differenceMs / ONE_DAY);
    }

    roundToThrityMins = (date) => {
        var now = date;
        var mins = now.getMinutes();
        var quarterHours = Math.round(mins / 30);
        if (quarterHours == 2) {
            now.setHours(now.getHours() + 1);
        }
        var rounded = (quarterHours * 30) % 60;
        now.setMinutes(rounded);
        return now;
    }

    onClickUpdateTimeSlot = (index) => {
        let slots = this.props.suggestedTimeSlots;
        let fromDate = (this.props.fromDate < slots[index].from) ? this.props.fromDate : slots[index].from
        let toDate = (this.props.toDate > slots[index].to) ? this.props.toDate : slots[index].to
        slots[index].isUpdated = true;
        //this.props.updateState('fromDate', fromDate);
       // this.props.updateState('toDate', toDate);

       let isValid = true;

        if (slots[index].from >= slots[index].to) {
            isValid  = false;
            toast.error('End date should be greater than Start date.', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return;
        }

        let rounded = {
            start: this.roundToThrityMins(new Date(slots[index].from)),
            end: this.roundToThrityMins(new Date(slots[index].to)),
        }
        let minsBetween = this.getMinutesBetweenDates(rounded.start, rounded.end);
        if (minsBetween < this.props.scheduledDuration) {
            isValid  = false;
            toast.error('The suggested time slot(s) is not enough to complete the task', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return;
        }

        let timeSlotKey = '';
        let time = new Date(rounded.start);
        let minuteSlots = minsBetween / 30;
        let newDate = new Date(rounded.start);
        
        let minutesToAdd = 0
        for (let index = 0; index < minuteSlots; index++) {
            minutesToAdd = index == 0 ? 0 : 30;
            time = new Date(new Date(time).setMinutes(new Date(time).getMinutes() + minutesToAdd));
            timeSlotKey = time.getHours().toString() + time.getMinutes().toString();
            if (timeSlotKey == '1730') {
                var d = newDate;
                d = new Date(d.setDate(d.getDate() + 1));
                time = new Date(new Date(d.setHours(8)).setMinutes(0));
                timeSlotKey = time.getHours().toString() + time.getMinutes().toString();
            }
            let element = document.querySelector(`[time-slot='${time.toLocaleDateString() + '-' + timeSlotKey}']`);

            if (element == null || element == undefined) {
                isValid = isValid && false;
            } else {
                isValid = isValid && true;
            }
        }

        if (isValid) {
            this.props.updateState('suggestedTimeSlots', slots);

            this.onPrepareGrid(fromDate, toDate);

            setTimeout(() => {
                this.updateNotAvailableTimeSlots();
                this.updateAvailableTimeSlots();
                this.updateSuggestedTimeSlots();
                this.updateWorkingTimeSlots();
            }, 500);
        } else {
            toast.error('Please select a valid time slot.', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }

    onClickDeleteTimeSlot = (index) => {
        this.props.suggestedTimeSlots.forEach((element, i) => {
            if (i === index) {
                element.isDeletedEvent = true;
            }
        });
        let suggestedTimeSlots = this.props.suggestedTimeSlots.filter((element, i) => i != index);   // AN-24965

        this.onPrepareGrid(this.props.fromDate, this.props.toDate);
        setTimeout(() => {
            this.updateNotAvailableTimeSlots();
            this.updateAvailableTimeSlots();
            this.updateSuggestedTimeSlots();
            this.updateWorkingTimeSlots();
        }, 500);
    }

    disableFromDates = (currentDate, selectedDate, index) => {
        var d = new Date();
        d.setDate(d.getDate() - 1);
        return true; //currentDate > new Date(d);
    }

    disableToDates = (currentDate, selectedDate, index) => {
        let slot = this.props.suggestedTimeSlots[index];
        var d = slot.from
        d.setDate(d.getDate() - 1);
        return currentDate > new Date(d);
    }

    onClickAvailableSlot = (workDay, slot) => {
        let startDate = new Date(workDay.localDateString);
        startDate = new Date(startDate.setHours(slot.hours));
        startDate = new Date(startDate.setMinutes(slot.minutes));
        let startTime = new Date(startDate);
        let endTime = new Date(new Date(startDate).setMinutes(new Date(startDate).getMinutes() + this.props.scheduledDuration));
        if (endTime > new Date(new Date(new Date(startDate).setHours(17)).setMinutes(0))) {
            let minutesDiff = this.getMinutesBetweenDates(new Date(new Date(new Date(startDate).setHours(17)).setMinutes(0)), endTime) - 31;
            var d = new Date(workDay.localDateString);
            d = new Date(d.setDate(d.getDate() + 1));
            endTime = new Date(new Date(d.setHours(8)).setMinutes(minutesDiff));
        }
        let suggestedTimeSlots = [];
        let timeSlotKey = slot.hours.toString() + slot.minutes.toString();
        let time = workDay.date;
        time = new Date(new Date(workDay.date.setHours(slot.hours)).setMinutes(slot.minutes))
        let minuteSlots = this.props.scheduledDuration / 30;
        let newDate = workDay;
        let isValid = true;
        let minutesToAdd = 0
        for (let index = 0; index < minuteSlots; index++) {
            minutesToAdd = index == 0 ? 0 : 30;
            time = new Date(new Date(time).setMinutes(new Date(time).getMinutes() + minutesToAdd));
            timeSlotKey = time.getHours().toString() + time.getMinutes().toString();
            if (timeSlotKey == '1730') {
                var d = newDate.date;
                d = new Date(d.setDate(d.getDate() + 1));
                time = new Date(new Date(d.setHours(8)).setMinutes(0));
                timeSlotKey = time.getHours().toString() + time.getMinutes().toString();
            }
            let element = document.querySelector(`[time-slot='${time.toLocaleDateString() + '-' + timeSlotKey}']`);

            if (element == null || element == undefined) {
                isValid = isValid && false;
            } else {
                isValid = isValid && true;
            }
        }

        if (isValid) {
            suggestedTimeSlots.push({
                from: startTime,
                to: endTime,
                isUpdated: true,
                isSingle: true
            });

            this.props.updateState('suggestedTimeSlots', suggestedTimeSlots);

            this.onPrepareGrid(this.props.fromDate, this.props.toDate);
            setTimeout(() => {
                this.updateNotAvailableTimeSlots();
                this.updateAvailableTimeSlots();
                this.updateSuggestedTimeSlots();
                this.updateWorkingTimeSlots();
            }, 500);
        } else {
            toast.error('Please select a valid time slot.', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }

    getMinutesBetweenDates = (startDate, endDate) => {
        var diff = endDate.getTime() - startDate.getTime();
        return (diff / 60000);
    }

    renderTimeSlots = (workDay) => {
        {
            TIME_SLOTS.map((slot, index) => {
                return (
                    <React.Fragment key={index}>
                        {(workDay.sugesstedSlots.indexOf(slot.time.toString()) > -1) ?
                            <div className={'suggested ds-timeslot'} style={{ width: '10%' }}
                                onClick={() => this.onClickAvailableSlot(workDay, slot)} time-slot={workDay.localDateString + '-' + slot.time}>
                                <div className="droppable"></div>
                                {(workDay.workingSlots.indexOf(slot.time.toString()) > -1) ?
                                    <div className="working-slot-pararl" ></div>
                                    : <div></div>}
                            </div> :
                            (workDay.availableSlots.indexOf(slot.time.toString()) > -1) ?
                                <div className={'available ds-timeslot'} style={{ width: '10%' }}
                                    onClick={() => this.onClickAvailableSlot(workDay, slot)} time-slot={workDay.localDateString + '-' + slot.time}>
                                    <div className="droppable">  </div>
                                    {(workDay.workingSlots.indexOf(slot.time.toString()) > -1) ?
                                        <div className="working-slot-pararl" ></div>
                                        : <div></div>}
                                </div> :
                                (workDay.notAvilableSlots.indexOf(slot.time.toString()) > -1) ?
                                    <div className={'not-available ds-timeslot'} style={{ width: '10%' }}>
                                        <div className="droppable">  </div>
                                        {(workDay.workingSlots.indexOf(slot.time.toString()) > -1) ?
                                            <div className="working-slot-pararl" ></div>
                                            : <div></div>}
                                    </div> :
                                    (workDay.workingSlots.indexOf(slot.time.toString()) > -1) ?
                                        <div className={'working-slot ds-timeslot'} style={{ width: '10%' }}>
                                            <div className="droppable">  </div>
                                        </div> :
                                        (slot.time == 80) ?
                                            <div className={'ds-timeslot'} style={{ width: '10%' }}>
                                                <div className="droppable">  </div>
                                            </div> :
                                            <div className={'ds-timeslot'} style={{ width: '10%' }} time-slot={workDay.localDateString + '-' + slot.time}>
                                                <div className="droppable">  </div>
                                            </div>
                        }
                    </React.Fragment>
                )
            })
        }
    }

    onChangeStartTime = (date, index) => {
        let slots = this.props.suggestedTimeSlots;
        let element = slots[index];
        let startDate = new Date(element.from);
        let startTime = new Date(date);
        element.from = new Date(new Date(startDate.setHours(startTime.getHours())).setMinutes(startTime.getMinutes()));
        element.isUpdated = false;
        element.isSingle = false;
        slots[index] = element;
        this.props.updateState('suggestedTimeSlots', slots);
    }

    onChangeStartDate = (date, index) => {
        let slots = this.props.suggestedTimeSlots;
        let element = slots[index];
        element.from = new Date(date);
        element.isUpdated = false;
        element.isSingle = false;
        slots[index] = element;
        this.props.updateState('suggestedTimeSlots', slots);
    };

    onChangeEndTime = (date, index) => {
        let slots = this.props.suggestedTimeSlots;
        let element = slots[index];
        let endDate = new Date(element.to);
        let endTime = new Date(date);
        element.to = new Date(new Date(endDate.setHours(endTime.getHours())).setMinutes(endTime.getMinutes()));
        element.isUpdated = false;
        element.isSingle = false;
        slots[index] = element;
        this.props.updateState('suggestedTimeSlots', slots);
    }

    onChangeEndDate = (date, index) => {
        let slots = this.props.suggestedTimeSlots;
        let element = slots[index];
        element.to = new Date(date);
        element.isUpdated = false;
        element.isSingle = false;
        slots[index] = element;
        this.props.updateState('suggestedTimeSlots', slots);
    }

    convertJobTimesToHoursAndMinutes() {
        var totalJobTimeInMinutes = this.props.bidDetails.estimatedHours;
        if (this.props.travelTimeDuration > 0) {
            totalJobTimeInMinutes = parseInt(this.props.bidDetails.estimatedHours + this.props.travelTimeDuration);
        }
        const totalJobTimeHoursPortion = Math.floor(totalJobTimeInMinutes / 60);
        const totalJobTimeMinutesPortion = totalJobTimeInMinutes % 60;

        const estimatedJobTimeHoursPortion = Math.floor(this.props.bidDetails.estimatedHours / 60);
        const estimatedJobTimeMinutesPortion = this.props.bidDetails.estimatedHours % 60;

        this.setState({
            totalJobTimeHoursPortion: totalJobTimeHoursPortion,
            totalJobTimeMinutesPortion: totalJobTimeMinutesPortion,
            estimatedJobTimeHoursPortion: estimatedJobTimeHoursPortion,
            estimatedJobTimeMinutesPortion: estimatedJobTimeMinutesPortion
        });

        if (this.props.travelTimeDuration > 0) {
            const travelTimeHoursPortion = Math.floor(this.props.travelTimeDuration / 60);
            const travelTimeMinutesPortion = this.props.travelTimeDuration % 60;
            this.setState({
                travelTimeHoursPortion: travelTimeHoursPortion,
                travelTimeMinutesPortion: travelTimeMinutesPortion
            });
        }
    }

    render() {
        const { workDays } = this.state;
        const { fromDate, toDate } = this.props;
        const shortcuts = {
            'Today': moment()
        };
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                        <label className="timeslot-label font-14 text-dark float-left mb-2 pl-0">Suggested optional day(s) and time</label>
                            <div className="form-control rich-control add-new-events pl-0" style={{ background: 'none' }} >
                                <div>
                                    <p>*Avg job time (<span>{this.state.totalJobTimeHoursPortion} hrs</span><span> {this.state.totalJobTimeMinutesPortion} min</span>) =
                                        job estimation (<span>{this.state.estimatedJobTimeHoursPortion} hrs</span><span> {this.state.estimatedJobTimeMinutesPortion} min</span>) +
                                        travel time (<span>{this.state.travelTimeHoursPortion} hrs</span><span> {this.state.travelTimeMinutesPortion} min</span>)</p>
                                </div>
                                    <div className="plus-icon ml-4" onClick={this.onCLickAddNewSuggestedTimeSlots}>
                                        Add  {"  "}
                                        <i className="ml-2 ba-icn ba-icn-close"></i>
                                    </div> 
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group d-inline-flex align-item-center">
                                    <h4 className="working-timeslot"></h4>
                                    <label class="col-form-label ml-2 timeslot-label pt-0 text-left">Working Slot</label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group d-inline-flex align-item-center">
                                    <h4 className="available-timeslot"></h4>
                                    <label class="col-form-label ml-2 timeslot-label pt-0 text-left">Available Slot</label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group d-inline-flex align-item-center">
                                    <h4 className="not-available-timeslot"></h4>
                                    <label class="col-form-label ml-2 timeslot-label pt-0 text-left">Blocked Slot</label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group d-inline-flex align-item-center">
                                    <h4 className="suggested-timeslot"></h4>
                                    <label class="col-form-label ml-2 timeslot-label pt-0 text-left">Suggested Slot</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.suggestedTimeSlots.map((element, index) => {
                    if (!element.isDeletedEvent) {
                        return (
                            <div className="row" key={index}>
                                <div className="col-md-3 custom-datetime-picker">
                                    <div className="form-group d-flex">
                                        <label className="col-form-label mr-2 mt-1">Start</label>
                                        {(element.isSingle) ?
                                            <input type="text" placeholder="Start Time" readOnly className="form-control"
                                                value={moment(element.from).format('YYYY-MM-DD HH:mm')} /> :
                                            <>
                                                <DatetimePickerTrigger
                                                    minDate={moment(new Date())}
                                                    showTimePicker={false}
                                                    shortcuts={shortcuts}
                                                    moment={moment(element.from)}
                                                    onChange={(event) => this.onChangeStartDate(event, index)}>
                                                    <input type="text" value={moment(element.from).format('YYYY-MM-DD')}
                                                        readOnly={true} />
                                                </DatetimePickerTrigger>
                                                <TimePicker
                                                    showSecond={false}
                                                    value={moment(element.from)}
                                                    onChange={(event) => this.onChangeStartTime(event, index)}
                                                    inputReadOnly
                                                    use12Hours
                                                    popupClassName={'time-picker-popup'}>
                                                </TimePicker>
                                                <span className="calender-icon">
                                                    <i className="ba-icn ba-icn-calendar-gray"></i>
                                                </span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4 custom-datetime-picker">
                                    <div className="form-group d-inline-flex">
                                        <lonClickUpdateTimeSlotabel className="col-form-label mr-2 mt-1">End</lonClickUpdateTimeSlotabel>
                                        {(element.isSingle) ?
                                            <input type="text" placeholder="End Time" readOnly className="form-control" value={moment(element.to).format('YYYY-MM-DD HH:mm')} /> :
                                            <>
                                                <DatetimePickerTrigger
                                                    minDate={moment(new Date(element.from))}
                                                    showTimePicker={false}
                                                    shortcuts={shortcuts}
                                                    moment={moment(element.to)}
                                                    onChange={(event) => this.onChangeEndDate(event, index)}>
                                                    <input type="text" value={moment(element.to).format('YYYY-MM-DD')} readOnly={true} />
                                                </DatetimePickerTrigger>
                                                <TimePicker
                                                    showSecond={false}
                                                    value={moment(element.to)}
                                                    onChange={(event) => this.onChangeEndTime(event, index)}
                                                    inputReadOnly
                                                    use12Hours
                                                    popupClassName={'time-picker-popup'}>
                                                </TimePicker>
                                                <span className="calender-icon">
                                                    <i className="ba-icn ba-icn-calendar-gray"></i>
                                                </span>
                                            </>
                                        }
                                    </div>
                                    {(element.isSingle && !element.scheduledByPm) ? '' : <button className="btn" onClick={event => this.onClickUpdateTimeSlot(index)}>
                                        <i className="ba-icn ba-icn-ok"></i>
                                    </button>}
                                    {(element.scheduledByPm) ? '' : <button className="btn" onClick={event => this.onClickDeleteTimeSlot(index)} >
                                        <i className="ba-icn ba-icn-bin"></i>
                                    </button>}
                                </div>
                            </div>
                        )
                    }
                })}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="event-scheduler">
                            <div className="ds-row">
                                <div className="ds-titlespace">
                                    <div className="ds-title-details space-holder">
                                        {MONTHS[fromDate.getMonth()].shortName + ' ' + fromDate.getDate() + ' - ' + MONTHS[toDate.getMonth()].shortName + ' ' + toDate.getDate() + ', ' + toDate.getFullYear()}
                                    </div>
                                    {workDays.map((day, index) => {
                                        return (
                                            <div className="ds-title-details" key={index}>
                                                <div className="ds-title">
                                                    <span>{day.date.toDateString()}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="ds-dataspace">
                                    <div className="ds-data title-holder">
                                        <div className="ds-timeslots" style={{ width: '400%' }}>
                                            {TIME_SLOTS.map((slot, index) => {
                                                return (
                                                    <div className="ds-timeslot" style={{ width: '10%' }} key={index}>
                                                        <div className="w-100 text-left"> {slot.displayText} </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    {workDays.map((workDay, index) => {
                                        return (
                                            <div className="ds-data" key={index}>
                                                <div className="ds-timeslots" style={{ width: '400%' }}>
                                                    {/*{this.renderTimeSlots(workDay)}*/}
                                                    {TIME_SLOTS.map((slot, index2) => {
                                                        return (
                                                            <React.Fragment key={index2}>
                                                                {(workDay.sugesstedSlots.indexOf(slot.time.toString()) > -1) ?
                                                                    <div className={'suggested ds-timeslot'} style={{ width: '10%' }}
                                                                        onClick={() => this.onClickAvailableSlot(workDay, slot)} time-slot={workDay.localDateString + '-' + slot.time}>
                                                                        <div className="droppable"></div>
                                                                        {(workDay.workingSlots.indexOf(slot.time.toString()) > -1) ?

                                                                            <div className="working-slot-pararl" ></div>
                                                                            : <div></div>}
                                                                    </div> :
                                                                    (workDay.availableSlots.indexOf(slot.time.toString()) > -1) ?
                                                                        <div className={'available ds-timeslot'} style={{ width: '10%' }}
                                                                            onClick={() => this.onClickAvailableSlot(workDay, slot)} time-slot={workDay.localDateString + '-' + slot.time}>
                                                                            <div className="droppable">  </div>
                                                                            {(workDay.workingSlots.indexOf(slot.time.toString()) > -1) ?

                                                                                <div className="working-slot-pararl" ></div>
                                                                                : <div></div>}
                                                                        </div> :
                                                                        (workDay.notAvilableSlots.indexOf(slot.time.toString()) > -1) ?
                                                                            <div className={'not-available ds-timeslot'} style={{ width: '10%' }}>
                                                                                <div className="droppable">  </div>
                                                                                {(workDay.workingSlots.indexOf(slot.time.toString()) > -1) ?

                                                                                    <div className="working-slot-pararl" ></div>
                                                                                    : <div></div>}
                                                                            </div> :
                                                                            (workDay.workingSlots.indexOf(slot.time.toString()) > -1) ?
                                                                                <div className={'working-slot ds-timeslot'} style={{ width: '10%' }}>
                                                                                    <div className="droppable">  </div>
                                                                                </div> :
                                                                                (slot.time == 80) ?
                                                                                    <div className={'ds-timeslot'} style={{ width: '10%' }}>
                                                                                        <div className="droppable">  </div>
                                                                                    </div> :
                                                                                    <div className={'ds-timeslot'} style={{ width: '10%' }} time-slot={workDay.localDateString + '-' + slot.time}>
                                                                                        <div className="droppable">  </div>
                                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        dateFormat: state.User.dateFormat
    };
};
export default connect(mapStateToProps)(EventScheduler);

import React, { Component } from "react";
import { Button,  Modal } from 'semantic-ui-react'

class TroubleshootGuidModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <Modal className="custom-modal" size={'tiny'} open={this.props.drawerOpen}>
                <span className="ba-icn ba-icn-close close-icon" onClick={() => this.props.closeModal(this.props.index)}></span>
                <Modal.Content>
                    <div className="wizard-inner">
                        <div className='wizard-body job-post-type'>
                            <div className="wizard-header pr-4">
                                <h2>Please check the steps below to see if they help you to solve your issue</h2>
                                <p><i>You can proceed to submit your job after tying the below steps</i></p>
                            </div>
                            <div>
                                    <div className="mt-4 innerHtmlContainer text-left trbl-st-cont" dangerouslySetInnerHTML={{ __html: `${this.props.troubleShootingGuideDetails.troubleshootGuide}` }}></div>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>                    
                    {/*<Button primary onClick={() => this.props.closeModal(this.props.index)}>close </Button>*/}
                    <div className=" d-flex flex-column flex-md-row justify-content-end mt-0">
                        <button type="button" className="btn light-blue-button px-4 mb-3 mx-0 mx-md-2" onClick={() => this.props.continueJobPosting(this.props.index)}>Issue Not Fixed</button>
                        <button type="button" className="btn next-btn px-4 mb-3 mx-0 mx-md-2 d-flex align-items-center justify-content-center" onClick={() => this.props.saveTroubleShootResult(true)}>Issue is Fixed</button>
                    </div>
                </Modal.Actions>

            </Modal>
            </div>;
    }

}

export default TroubleshootGuidModal;
const initState = {
    tenantCount:0,
    isCurrentTenant: false,
    tenantProperties: []
}

const TenantReducer = (state = initState, action) =>{

    switch (action.type) {
        case 'ADD_Tenant':
            return {
                ...state,
                tenantCount: action.tenantCount
            }
        case 'CHECK_CURRENT_TENANT':
            return{
                ...state,
                isCurrentTenant: action.isCurrentTenant
            }
        case 'SET_PROPERTIES':
            return{
                ...state,
                tenantProperties: action.properties
            }
    
        default:
            return state;
    }
}

export default TenantReducer;
import React from 'react';
import { connect } from 'react-redux';

class Conversation extends React.Component {
    state = {};
    constructor(props) {
        super(props)
    }

    render() {
        const msges = this.getUnReadMsgs();
        return (
            <div className="chat-content">
                {msges}
            </div>
        )
    }

    getUnReadMsgs = () => {
        const chatUser = this.props.conversation;
        const userImg = this.getUserImg();
        const unreadMsgCount = this.getUnreadMsgCount()
        if (chatUser.isRead) {
            return (
                <div className="chat-user" onClick={this.openChatBox}>
                    <div className="chat-user-img">
                        {userImg}
                    </div>
                    <div className="chat-username">
                        <span>{chatUser.conversationName}</span>
                        <span className="chat-recent">{chatUser.lastMessage}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="chat-user unread" onClick={this.openChatBox}>
                    <div className="chat-user-img">
                        {userImg}
                    </div>
                    <div className="chat-username">
                        <span>{chatUser.conversationName}</span>
                        <span className="chat-recent">{chatUser.lastMessage}</span>
                    </div>
                    {unreadMsgCount}
                </div>
            );
        }
    }

    getUnreadMsgCount = () => {
        const msg = this.props.conversation;
        if (msg.unReadMessageCount !== 0 && msg.unReadMessageCount >= 100) {
            return <div className="unread-chats">99+</div>
        } else {
            if (msg.unReadMessageCount !== 0) {
                return <div className="unread-chats">{msg.unReadMessageCount}</div>
            }
        }

    }

    getUserImg = () => {
        if (this.props.conversation.isPrivate) {
            return <img src={this.props.profileThumbnailsCdnEndpoint + this.props.conversation.receiver + '.jpg'}
            onError={(e)=>{e.target.onerror = null; e.target.src="/Images/default-profile.png"}}></img>
        } else {
            return <img src="/images/default-group-profile.png"></img>
        }
    }

    openChatBox = () => {
        let chatList = (this.props.chatList == '') ? [] : JSON.parse(this.props.chatList);
        chatList.push(this.props.conversation)
        this.props.addToChatBoxList(JSON.stringify(chatList));
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        chatList : state.Conversation.conversationList,
        imageThumbnailsCdnEndpoint: state.AppSettings.imageThumbnailsCdnEndpoint,
        imageCdnEndpoint: state.AppSettings.imageCdnEndpoint,
        profileThumbnailsCdnEndpoint: state.AppSettings.profileThumbnailsCdnEndpoint,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {

    return {
        addToChatBoxList: (chatList) => { dispatch({type:'SHOW_CONVERSATION', chatList: chatList})}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
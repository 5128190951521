import { AXIOS } from '../Utilities/HttpRequest/Axios';
import { AppConfiguration } from "read-appsettings-json";

const getQoutesByJobId = (jobId, userId) => {
    return AXIOS.GET(`tenant-api/bids?jobId=${jobId}&userId=${userId}`);
}

const approveBidByOwner = (jobId, bidId, note) => {
    return AXIOS.GET(`tenant-api/bid/approve-bid?jobId=${jobId}&userId=${localStorage.getItem('userIdDecripted')}&bidId=${bidId}&userIdEncrypted=${localStorage.getItem('exernalUserId')}&note=${note}`);
}

const declineAllBidsByOwner = (jobId, note) => {
    return AXIOS.GET(`tenant-api/bid/decline-all-quotes-by-owner?jobId=${jobId}&userId=${localStorage.getItem('userIdDecripted')}&userIdEncrypted=${localStorage.getItem('exernalUserId')}&note=${note}`);
}

const acceptTenantMangeQuote = (model) => {
    return AXIOS.POST(`tenant-api/consumer-type/accept-quote`,model);
}

const rejectTenantMangeQuote = (model) => {
    return AXIOS.POST(`tenant-api/consumer-type/reject-quote`,model);
}

const bidService = {
    getQoutesByJobId,
    approveBidByOwner,
    declineAllBidsByOwner,
    acceptTenantMangeQuote,
    rejectTenantMangeQuote
}

export default bidService;
import React, { Component } from 'react';
import '../../../Layout/LogMaintenanceBotLayout.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import botService from '../../../../Services/BotService.js';
import * as _ from 'lodash';
import { Accordion, ButtonToolbar, Card, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import Axios from 'axios';
import moment from 'moment';
import translateService from '../../../../Services/TranslateService';
class TrackIssueWizard extends React.Component {

    constructor(props) {
        super(props);
        this.refProblem = React.createRef();
        this.state = {
            isCustomThemeEnable: this.props.isCustomThemeEnable,
            theme: this.props.theme,
            style: this.props.style,
            tenantId: this.props.tenantId,
            rentalDetails: {},
            arrearsDetails: {},
            scheduleJobs: [],
            isJobsLoading: false,
            isDetailsView: false,
            currentJob: {},
            jobsNotFount: false,
            currentStepIndex: 0,
            stepFlow: [[0, "scheduledJobs"], [1, "detailView"], [2, "NoJobFound"]],
        }
    }

    componentDidMount() {
        this.LoadScheduleJobs();
        this.setTheme();
    }

    componentDidUpdate() {
        if (this.props.isCustomThemeEnable) {
            this.createToggleBtnDynamicStyle();
            this.createOkBtnDynamicStyle();
            this.createActiveBtnDynamicStyle();
        }
    }

    createToggleBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.color;
        const border = this.props.style.optionsStyle.border;
        let labelInsideToggleGroup = document.querySelectorAll('label.btn');

        for (var i = 0; i < labelInsideToggleGroup.length; i++) {
            labelInsideToggleGroup[i].style.color = color;
            labelInsideToggleGroup[i].style.border = border;
            labelInsideToggleGroup[i].style.background = '';
        }
    }
    createOkBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.color;
        let okIcons = document.querySelectorAll('span.ba-icn-ok');

        for (var i = 0; i < okIcons.length; i++) {
            okIcons[i].style.background = color;
        }
    }
    createActiveBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.activeBackground;
        let active = document.querySelectorAll('label.btn.active');
        for (var i = 0; i < active.length; i++) {
            active[i].style.background = color;
        }
    }

    LoadScheduleJobs = () => {
        var propertyId = this.props.propertyId;
        var langCode = this.props.i18n.language;
        let userId = localStorage.getItem('userId');
        let filters = {
            PropertyId: propertyId,
            UserId: userId,
            pageNumber: 1,
            PageSize: 50,
            OnlyScheduledJobs: true,
            LangCode: langCode
        };
        this.setState({ isJobsLoading: true });
        const base64param = Buffer.from(JSON.stringify(filters)).toString('base64');
        botService.getScheduleJobs(base64param).then(res => {

            if (res && res.jobsList.length > 0) {

                if (langCode !== 'en') {
                    let titleList = res.jobsList.map(x => x.title ? x.title : " ");
                    this.applyTranslation((titleList), "en", langCode).then((trans) => {
                        if (trans.length > 0) {
                            trans.forEach((element, index) => {
                                res.jobsList[index].title = element;
                            });
                            this.setState({ scheduleJobs: res.jobsList, isJobsLoading: false });
                        }
                    });
                } else {
                    this.setState({ scheduleJobs: res.jobsList, isJobsLoading: false });
                }




            }
            else {
                this.setState({ scheduleJobs: [], isJobsLoading: false, jobsNotFount: true });
            }
        }, error => {
        });
    }

    applyTranslation(texts, sourceLng, targetLng) {
        const requestModel = {
            text: texts,
            targetCode: targetLng,
            sourceCode: sourceLng
        };

        return translateService.translate(requestModel);
    }

    addBodyStyle = () => {
        var element = document.body;
        element.style.cssText = 'overflow:hidden !important;';
        element.style.cssText = 'padding-top:0 !important; overflow:hidden !important; background: transparent !important;';
        document.documentElement.style.cssText = "background: transparent !important;";

    }
    setTheme = () => {
        const { style, theme, isCustomThemeEnable } = this.props;
        this.setState({
            style: style,
            theme: theme,
            isCustomThemeEnable: isCustomThemeEnable
        });
    }
    previousStep = (index) => {
        this.setState({ isDetailsView: false, currentJob: {} })
    }
    scheduleJobs = () => {
        let { scheduleJobs, currentJob } = this.state;
        let nextStepIndex = (scheduleJobs.length > 0) ? 1 : 2;
        const { t } = this.props;

        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className='custom-scroller ongoing-jobs'>
                    <div>

                        <div className="wizard-header">
                            <h2 htmlFor="property">{t('new_ui_pj_lbl_job_select')}</h2>
                        </div>

                        <div>
                            {(this.state.isJobsLoading) ? <div className='d-flex justify-content-center'><Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /></div> :
                                <ButtonToolbar className="radio-btn-group">
                                    <ToggleButtonGroup className={"btn-group-bot w-100"} type="radio" name="options" ref="jobType"
                                        onChange={(value) => { this.handleJobChange(value) }}>
                                        {scheduleJobs.map((a, index) => {
                                            return <ToggleButton className="common-job" key={index} value={a}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="job-number">#{a.jobNumber} | {this.timeAgo(a.postedOn)}</div>
                                                    <div className="ml-0 ml-md-auto mt-2 mt-md-0 d-flex flex-wrap justify-content-start justify-content-md-end">
                                                        <label className="ml-2 mb-2 mb-lg-0 common-tag small red">{a.jobUrgencyId === 1 ? t('new_ui_pj_lbl_urgent') : ""}</label>
                                                    </div>
                                                </div>                                             
                                                <div className="job-title mb-4">{a.title}</div>

                                                <div className='d-flex justify-content-between'> 
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <span className='icon icon-ico-tools mr-3'></span>
                                                        <div className='d-flex flex-column'>
                                                            <p className='mb-0 font-12 status'>{a.statusDisplay}</p>
                                                            <p className='mb-0 font-12 updated-text'>Updated: {this.timeAgo(a.lastUpdatedDateTime)}</p>
                                                        </div>
                                                    </div>
                                                    <span className={`job-type-icon icon-Default icon-${a.primaryJobTypeDisplay.replace(' ', '')}`}></span>
                                                </div>
                                            </ToggleButton>
                                        })
                                        }
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            }
                        </div>

                    </div>
                </div>

                {(!this.state.isJobsLoading) ?
                    <div className="wizard-actions">
                        <button type="button" className="btn btn-primary mx-0" onClick={() => this.nextStep(nextStepIndex)} style={this.state.primaryButtonStyle}>{t('new_ui_pj_btn_continue')}</button>
                    </div>
                    : ''}

            </div>);
    }

    handleJobChange = (job) => {
        var langCode = this.props.i18n.language;
        let description = job.description != null ? job.description : "";
        this.applyTranslation(([description]), "en", langCode).then((trans) => {
            if (trans.length > 0) {
                job.description = trans[0];
            }
        });
        this.setState({ isDetailsView: true, currentJob: job })
    }

    timeAgo(value) {
        const { t } = this.props;
        if (!value) return;
        let d = new Date(value);
        let now = new Date();
        let seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));

        let minutes = Math.round(Math.abs(seconds / 60));
        let hours = Math.round(Math.abs(minutes / 60));
        let days = Math.round(Math.abs(hours / 24));
        let months = Math.round(Math.abs(days / 30.416));
        let years = Math.round(Math.abs(days / 365));
        if (Number.isNaN(seconds)) {
            return '';
        } else if (seconds <= 45) {
            return t('new_ui_lbl_fewSeconds');
        } else if (seconds <= 90) {
            return t('new_ui_lbl_minute');
        } else if (minutes <= 45) {
            return minutes + t('new_ui_lbl_minutes');
        } else if (minutes <= 90) {
            return t('new_ui_lbl_hour');
        } else if (hours <= 22) {
            return hours + t('new_ui_lbl_hours');
        } else if (hours <= 36) {
            return t('new_ui_lbl_day');
        } else if (days <= 25) {
            return days + t('new_ui_lbl_days');
        } else if (days <= 45) {
            return t('new_ui_lbl_month');
        } else if (days <= 345) {
            return months + t('new_ui_lbl_months');
        } else if (days <= 545) {
            return t('new_ui_lbl_year');
        } else {
            // (days > 545)
            return years + t('new_ui_lbl_years');
        }
    }

    render() {
        let step = this.showCurrentStep(this.state.currentStepIndex);
        return <>
            <div className="">
                {step}
            </div>
        </>
    }

    nextStep = (index) => {
        if (this.validateCurrentStep(index - 1)) {
            this.setState({ currentStepIndex: index });
        }
    }

    validateCurrentStep = (index) => {
        const { t } = this.props;
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "scheduledJobs":
                if (Object.keys(this.state.currentJob).length === 0) {
                    this.props.showChatbotError(t('new_ui_lbl_selectScheduledJob_error_msg'));
                    return false;
                }
                else
                    return true;
            case "detailView":
                return true;
            case "NoJobFound":
                return true;
        }
    }

    showCurrentStep = (index) => {
        let currentStep = this.state.stepFlow[index][1];
        switch (currentStep) {
            case "scheduledJobs":
                return this.scheduleJobs();
            case "detailView":
                return this.jobDetails();
            case "NoJobFound":
                return this.JobsNotFound();
        }
    }

    jobDetails = () => {
        const { t } = this.props;
        const { currentJob } = this.state;
        return (
            <div className="wizard-inner wzrd-post-a-job tenant-chat-bot">
                <div className='custom-scroller issue-wizard'>
                    <div>
                        <div className="wizard-header">
                            <h2 className='font-semibold' htmlFor="property">{t('new_ui_pj_lbl_job_details_header')} <span className="text-success">#{currentJob.jobNumber}</span></h2>
                        </div>
                        <div className='tracking-timeline'>

                            <div className='p-4 light-brown-bg description mb-4 pb-3'>{currentJob.description}</div>
                            
                            <div className='mt-2'>
                                <div className='d-flex flex-row align-items-center pt-0 step'>
                                    <div className='mr-3'><span className='icon icon-ico-tools status-icon'></span></div>
                                    <div className='d-flex flex-column'>
                                        <p className='mb-0 status-title d-flex align-items-center'>{t('new_ui_pj_lbl_job_issue_posted')} <span className='tick-icon ba-icn ba-icn-ok'></span></p>
                                        <p className='mb-0 status-text text-light-brown'>{currentJob.postedOnUTCDisplay}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center pt-4 step'>
                                    <div className='mr-3'><span className='icon icon-ico-worker status-icon'></span></div>
                                    <div className='d-flex flex-column'>
                                        <p className='mb-0 status-title d-flex align-items-center'>{t('new_ui_pj_lbl_job_trade_assigned')} <span className='tick-icon ba-icn ba-icn-ok'></span></p>
                                        <p className='mb-0 status-text text-light-brown'>{currentJob.acceptedServiceProviderName}({currentJob.primaryJobTypeDisplay})</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center pt-4 step inactive'>
                                    <div className='mr-3'><span className='icon icon-icon-complete status-icon'></span></div>
                                    <div className='d-flex flex-column'>
                                        <p className='mb-0 status-title'>{t('new_ui_pj_lbl_job_issue_completed')}</p>
                                        <p className='mb-0 status-text text-light-brown'>{currentJob.dueDateUTCDisplay}</p>
                                    </div>
                                </div>
                            
                            </div>
                   
                        </div>

                    </div>
                </div>
                <div className="wizard-actions">
                    <button type="button" className="btn btn-primary mx-0" onClick={() => this.props.backToMainMenu()} style={this.state.primaryButtonStyle}>{t('new_ui_qna_lbl_back_to_main_chat')}</button>
                </div>

            </div>);
    }

    JobsNotFound = () => {
        const { t } = this.props;
        const { currentJob } = this.state;
        return (
            <div className="wizard-inner wzrd-post-a-job">
                <div className={''}>
                    <div>

                        <div>
                            <div>
                                <span>{t('new_ui_pj_lbl_job_not_found')}</span>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="wizard-actions">
                    <button type="button" className="btn btn-primary" onClick={() => this.props.backToMainMenu()} style={this.state.primaryButtonStyle}>{t('new_ui_qna_lbl_back_to_main_chat')}</button>
                </div>

            </div>);
    }

}


export default connect()(TrackIssueWizard);
import React from 'react';
import notificationService from '../../../../Services/NotificationService';
import Notification from './Notification/Notification';
import JobDetailsDrawer from '../../JobDetailsDrawer/JobDetailsDrawer';
import { trackPromise } from 'react-promise-tracker';
import { Guid } from 'guid-typescript';
import LockRequestsDrawer from '../../LockRequestsDrawer/LockRequestsDrawer';

class NotificationList extends React.Component {

    state = {
        notificationCount: 0,
        notifications: [],
        showJobDetails: false,
        selectedJobId: '',
        showMore: true,
        unreadCount: this.props.unreadCount
    };

    componentDidMount() {
        this.GetNotifications();
    }

    componentWillUpdate(){
        if (this.state.unreadCount != this.props.unreadCount && this.props.unreadCount > 0 ) {
            this.setState({unreadCount: this.props.unreadCount});
        }
    }

    render() {
        return (
            <div className="notification-center">
                <div className="notification-header p-0">
                    <div className="text-right">You have ({this.state.unreadCount}) unread notifications</div>
                </div>
                <div>
                    <ul>
                        <li id="messages">
                            {this.state.notifications.slice(0,this.state.unreadCount).map((data, index) => {
                                return <Notification 
                                    jobId={this.state.selectedJobId} 
                                    key={index} 
                                    drawerOpen={this.state.showJobDetails}
                                    data={data}
                                    openJobDetailsDrawer={(event) => this.openJobDetailsDrawer(event)}
                                    openPendingPropertyLocksDrawer={(event) => this.openPendingPropertyLocksDrawer(event)}
                                    isClickedOnNotification = {() => this.updateUnreadCount()}
                                    isRead={false}>
                                </Notification>
                            })}
                            {this.state.notifications.slice(this.state.unreadCount, this.state.notifications.length).map((data, index) => {
                                return <Notification 
                                    jobId={this.state.selectedJobId} 
                                    key={index} 
                                    drawerOpen={this.state.showJobDetails}
                                    data={data}
                                    openJobDetailsDrawer={(event) => this.openJobDetailsDrawer(event)}
                                    openPendingPropertyLocksDrawer={(event) => this.openPendingPropertyLocksDrawer(event)}
                                    isClickedOnNotification = {() => this.updateUnreadCount()}
                                    isRead={true}>
                                </Notification>
                            })}
                        </li>
                        <li className={(this.state.showMore) ? '' : 'd-none'}>
                            <div className="row">
                                <div className="col-md-12 text-center notifi-all">
                                    <a href="#" onClick={(event)=> this.getAllNotifications(event)}>See all</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                {(this.state.showJobDetails) ?
                    <JobDetailsDrawer 
                        jobId={this.state.selectedJobId} 
                        drawerOpen={this.state.showJobDetails}
                        toggleDrawer={() => this.toggleDrawer()}>
                    </JobDetailsDrawer> : ''
                }

                {(this.state.showPendingLockRequests) ?
                    <LockRequestsDrawer 
                        selectedLockCodeRequestId={this.state.selectedJobId} 
                        drawerOpen={this.state.showPendingLockRequests}
                        toggleDrawer={() => this.toggleLocksDrawer()}>
                    </LockRequestsDrawer> : ''
                }
            </div>
        )
    }

    toggleDrawer() {
        this.setState({
            showJobDetails: !this.state.showJobDetails,
        })
    }
    toggleLocksDrawer() {
        this.setState({
            showPendingLockRequests: !this.state.showPendingLockRequests,
        });
    }

    openJobDetailsDrawer(jobId) {
        if (jobId != Guid.EMPTY) {
            this.setState({
                selectedJobId: jobId,
                showJobDetails: true,
            })}
       
    }

    updateUnreadCount() {
        if(this.state.unreadCount != 0){
            this.setState({unreadCount: this.state.unreadCount-1});
        }
        this.props.userReadNotificationEvent();
    }

    getAllNotifications = (event) => {
        event.preventDefault();
        trackPromise(
            notificationService.GetNotifications(true)
            .then(response => {
                this.setState({ notifications: response, showMore: false });
            })
        )
        
    }

    GetNotifications = () => {
        trackPromise(
            notificationService.GetNotifications(false)
            .then(response => {
                this.setState({ notifications: response });
            })
        )
        
    }

    openPendingPropertyLocksDrawer = (lockCodeRequestId) => {
        this.setState({
            selectedLockCodeRequestId: lockCodeRequestId,
            showPendingLockRequests: !this.state.showPendingLockRequests,
        })
    }

}

export default NotificationList;
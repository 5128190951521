import React from "react";
import Drawer from "../../../Components/Common/Drawer/Drawer";
import Loader from "react-loader-spinner";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bidService from "../../../Services/BidService";
import ConfirmationDrawer from "../../Drawers/ConfirmationDrawer/ConfirmationDrawer";

var model = {
    "bidAcceptedOrRejectedUserId": '',
    "userId": '',
    "note": '',
    "bidId": '',
}

export default class QuoteAcceptRejectDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.note = React.createRef();
    }
    state = {
        showDrawer: false,
        quoteNote: '',
        isLoading:false,
        showConfirmationDrawer:false,
        message:''
    };

    componentDidMount() {
        this.setState({ showDrawer: this.props.showDrawer });
        //isAcceptQuote, bid
    }

    render() {

        let drawer = this.createDrawer();
        return <div>{drawer}</div>;

    }

    toggleDrawer = isClose => {
        this.setState(state => ({
            showDrawer: !this.state.showDrawer
        }));

        if (isClose) {
            this.props.onCloseDrawer(false);
        }

    };

    createDrawer = () => {
        const header = this.createProfileHeader();
        const body = this.createProfileBody();
        const footer = this.createFooter();
        return (
            <>
            <Drawer
                size={"m"}
                open={this.state.showDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
            {this.state.showConfirmationDrawer?
            <ConfirmationDrawer
            message={this.state.message} showDrawer={this.state.showConfirmationDrawer} onConfirm={(status)=>this.onConfirmation(status)}
                onClose={()=>this.onCloseConfirmationDrawer()}></ConfirmationDrawer>:""}
            </>
        );
    };

    showConfirmationDrawer=()=>{
        var amount = this.props.amount?this.props.amount.toFixed(2):0.00;
        var msg = "A temporary fund of $"+amount+" will be held from your nominated credit card and it will be released once the job is marked as completed.";
        this.setState({showConfirmationDrawer:true,
                        message:msg});
    }

    onConfirmation=(status)=>{
        if(status){
            this.setState({showConfirmationDrawer:false});
            this.onAccept();
        }
    }

    onCloseConfirmationDrawer =()=>{
        this.setState({showConfirmationDrawer:false});
    }
    createProfileHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Quote
                    {(this.state.isLoading) ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : ''}
                </h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={() => this.toggleDrawer(true)}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createProfileBody = () => {
        return (
            <>
                <div className="panel-body dashboardN pt-0">
                    <div className="provider-profile row">
                        <div className="col-md-12 mt-4">
                            <div>
                                <span> <lable style={{ display: 'block' }}> Quote amount : <strong>${this.props.amount?this.props.amount.toFixed(2):0.00}</strong></lable></span>
                            </div>
                            <div className="mt-3">
                                <span> <lable style={{ display: 'block' }}> Note </lable></span>
                                <div className="form-group">
                                    <textarea className="form-control form-textarea"
                                        value={this.state.quoteNote} ref={this.note} onChange={(value) => this.handleOnChangeNote()}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };


    createFooter = () => {
        return (
            <>
                <div className="card-actions">
                    <button onClick={this.toggleDrawer}>Cancel</button>
                    {this.props.isAcceptQuote ? <button onClick={this.showConfirmationDrawer} className="action-accept" type="button"> <i className="ba-icn ba-icn-ok"></i>Accept</button> :
                        <button onClick={this.onReject} className="action-reject" type="button">Reject</button>}

                </div>
            </>
        )
    }

    handleOnChangeNote = () => {
        const note = this.note.current.value;
        this.setState({
            quoteNote: note
        });
    }

    onAccept = () => {



        this.setState({
            isLoading:true
        })

        model.bidId = this.props.bidId;
        model.bidAcceptedOrRejectedUserId = localStorage.getItem("userId");
        model.userId = localStorage.getItem("userId");
        model.note = this.state.quoteNote;

        bidService.acceptTenantMangeQuote(model).then(
            (res) => {
                if(res.status==13){
                    toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.setState(state => ({
                        isLoading:false
                    }));

                }else{
                    toast.success("Quote accepted successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    
                    this.setState(state => ({
                        isLoading:false,
                        showDrawer: false
                    }));
                    this.props.onCloseDrawer(true);
                }

            }, error => {
                this.setState(state => ({
                    isLoading:false
                }));
                toast.error("Quote accept unsuccessful", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        )
    }

    onReject = () => {
     
        model.bidId = this.props.bidId;
        model.bidAcceptedOrRejectedUserId = localStorage.getItem("userId");
        model.userId = localStorage.getItem("userId");
        model.note = this.state.quoteNote;
        if (!model.note || model.note == "") {
            toast.error("Reject note required", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            this.setState({
                isLoading:true
            })
            bidService.rejectTenantMangeQuote(model).then(
                (res) => {
                    toast.success("Quote rejected successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.setState(state => ({
                        isLoading:false,
                        showDrawer: false
                    }));
                    this.props.onCloseDrawer(true);
                }, error => {
                    this.setState(state => ({
                        isLoading:false
                    }));
                    toast.error("Quote reject unsuccessful", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            )
        }

    }

}

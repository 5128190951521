export var UserType;
(function(UserType) {
  UserType[(UserType["None"] = 0)] = "None";
  UserType[(UserType["Provider"] = 1)] = "Provider";
  UserType[(UserType["Consumer"] = 2)] = "Consumer";
  UserType[(UserType["PropertyManager"] = 3)] = "PropertyManager";
  UserType[(UserType["Tenant"] = 4)] = "Tenant";
  UserType[(UserType["Owner"] = 5)] = "Owner";
})(UserType || (UserType = {}));

export var JobsListTypes;
(function(JobsListTypes) {
  JobsListTypes[(JobsListTypes["None"] = 0)] = "None";
  JobsListTypes[(JobsListTypes["MatchingJobs"] = 1)] = "MatchingJobs";
  JobsListTypes[(JobsListTypes["AvailableJobs"] = 2)] = "AvailableJobs";
  JobsListTypes[(JobsListTypes["AwardedJobs"] = 3)] = "AwardedJobs";
  JobsListTypes[(JobsListTypes["UnscheduledJobs"] = 4)] = "UnscheduledJobs";
  JobsListTypes[(JobsListTypes["InProgressJobs"] = 5)] = "InProgressJobs";
  JobsListTypes[(JobsListTypes["AssignedJobs"] = 6)] = "AssignedJobs";
  JobsListTypes[(JobsListTypes["PendingReviewJobs"] = 7)] = "PendingReviewJobs";
  JobsListTypes[(JobsListTypes["CompletedJobs"] = 8)] = "CompletedJobs";
  JobsListTypes[(JobsListTypes["ArchivedJobs"] = 9)] = "ArchivedJobs";
  JobsListTypes[(JobsListTypes["QuotedJobs"] = 10)] = "QuotedJobs";
  JobsListTypes[(JobsListTypes["PendingApprovalJobs"] = 11)] =
    "PendingApprovalJobs";
  JobsListTypes[(JobsListTypes["PendingOwnerApprovalJobs"] = 12)] =
    "PendingOwnerApprovalJobs";
  JobsListTypes[(JobsListTypes["OwnerApprovedJobs"] = 13)] =
    "OwnerApprovedJobs";
  JobsListTypes[(JobsListTypes["PostedJobs"] = 14)] = "PostedJobs";
  JobsListTypes[(JobsListTypes["OverdueJobs"] = 16)] = "OverdueJobs";
  JobsListTypes[(JobsListTypes["QuoteRejectedJobs"] = 17)] =
    "QuoteRejectedJobs";
  JobsListTypes[(JobsListTypes["RecurringJobs"] = 18)] = "RecurringJobs";
  JobsListTypes[(JobsListTypes["ScheduledJobs"] = 19)] = "ScheduledJobs";
  JobsListTypes[(JobsListTypes["DraftedJobs"] = 20)] = "DraftedJobs";
  JobsListTypes[(JobsListTypes["InactiveJobs"] = 21)] = "InactiveJobs";
  JobsListTypes[(JobsListTypes["RejectedJobs"] = 22)] = "RejectedJobs";
  JobsListTypes[(JobsListTypes["UpcomingJobs"] = 23)] = "UpcomingJobs";
  JobsListTypes[(JobsListTypes["RevisionRequestedJobs"] = 24)] =
    "RevisionRequestedJobs";
  JobsListTypes[(JobsListTypes["PendingScheduleApproval"] = 26)] =
    "PendingScheduleApproval";
  JobsListTypes[(JobsListTypes["TodaysJobs"] = 27)] = "TodaysJobs";
  JobsListTypes[(JobsListTypes["QuoteLimitMetJobs"] = 28)] =
    "QuoteLimitMetJobs";
  JobsListTypes[(JobsListTypes["OwnerRejectedJobs"] = 30)] =
    "OwnerRejectedJobs";
  JobsListTypes[(JobsListTypes["PropertyInspectionJobs"] = 31)] =
    "PropertyInspectionJobs";
  JobsListTypes[(JobsListTypes["PostedJobForThirdParty"] = 32)] =
    "PostedJobForThirdParty";
  JobsListTypes[(JobsListTypes["CeasedJobs"] = 33)] = "CeasedJobs";
  JobsListTypes[(JobsListTypes["FinanceAcceptedQuotes"] = 34)] =
    "FinanceAcceptedQuotes";
  JobsListTypes[(JobsListTypes["FinanceUnpaidInvoices"] = 35)] =
    "FinanceUnpaidInvoices";
  JobsListTypes[(JobsListTypes["FinancePaidInvoices"] = 36)] =
    "FinancePaidInvoices";
  JobsListTypes[(JobsListTypes["VariationAvailableJobs"] = 37)] =
    "VariationAvailableJobs";
  JobsListTypes[(JobsListTypes["PendingJobs"] = 38)] = "PendingJobs";
  JobsListTypes[(JobsListTypes["PendingInvoiceJobs"] = 39)] =
    "PendingInvoiceJobs";
  JobsListTypes[(JobsListTypes["PendingPaymentJobs"] = 40)] =
    "PendingPaymentJobs";
  JobsListTypes[(JobsListTypes["InternalPendingReviewJobs"] = 41)] =
    "InternalPendingReviewJobs";
})(JobsListTypes || (JobsListTypes = {}));

export var WorkflowStates;
(function(WorkflowStates) {
  WorkflowStates[(WorkflowStates["JobDrafted"] = 1)] = "JobDrafted";
  WorkflowStates[(WorkflowStates["JobPosted"] = 2)] = "JobPosted";
  WorkflowStates[(WorkflowStates["JobQuotesAvailable"] = 3)] =
    "JobQuotesAvailable";
  WorkflowStates[(WorkflowStates["JobQuoteAccepted"] = 4)] = "JobQuoteAccepted";
  WorkflowStates[(WorkflowStates["JobAwaitingForApproval"] = 5)] =
    "JobAwaitingForApproval";
  WorkflowStates[(WorkflowStates["JobAwaitingOwnerApproval"] = 6)] =
    "JobAwaitingOwnerApproval";
  WorkflowStates[(WorkflowStates["JobCompleted"] = 7)] = "JobCompleted";
  WorkflowStates[(WorkflowStates["JobArchived"] = 8)] = "JobArchived";
  WorkflowStates[(WorkflowStates["JobApprovedByOwner"] = 9)] =
    "JobApprovedByOwner";
  WorkflowStates[(WorkflowStates["JobApprovedByPM"] = 10)] = "JobApprovedByPM";
  WorkflowStates[(WorkflowStates["JobRejectedByOwner"] = 11)] =
    "JobRejectedByOwner";
  WorkflowStates[(WorkflowStates["BidCreated"] = 101)] = "BidCreated";
  WorkflowStates[(WorkflowStates["BidAccepted"] = 102)] = "BidAccepted";
  WorkflowStates[(WorkflowStates["BidRejected"] = 103)] = "BidRejected";
  WorkflowStates[(WorkflowStates["JobCancelled"] = 104)] = "JobCancelled";
  WorkflowStates[(WorkflowStates["BidJobCancelled"] = 105)] = "BidJobCancelled";
  WorkflowStates[(WorkflowStates["JobRejected"] = 106)] = "JobRejected";
  WorkflowStates[(WorkflowStates["JobPendingReview"] = 107)] =
    "JobPendingReview";
  WorkflowStates[(WorkflowStates["JobPendingInvoice"] = 111)] =
    "JobPendingInvoice";
  WorkflowStates[(WorkflowStates["VariationCreated"] = 201)] =
    "VariationCreated";
  WorkflowStates[(WorkflowStates["VariationApproved"] = 202)] =
    "VariationApproved";
  WorkflowStates[(WorkflowStates["VariationRejected"] = 203)] =
    "VariationRejected";
  WorkflowStates[(WorkflowStates["JobAwardPendingApproval"] = 204)] =
    "JobAwardPendingApproval";
  WorkflowStates[(WorkflowStates["JobPostedForThirdParty"] = 205)] =
    "JobPostedForThirdParty";
  WorkflowStates[(WorkflowStates["JobCeased"] = 109)] = "JobCeased";
  WorkflowStates[(WorkflowStates["BidDeclined"] = 110)] = "BidDeclined";
})(WorkflowStates || (WorkflowStates = {}));

export var PropertyInspectionStatus;
(function (PropertyInspectionStatus) {
    PropertyInspectionStatus[(PropertyInspectionStatus["None"] = 0)] = "None";
    PropertyInspectionStatus[(PropertyInspectionStatus["PendingApproval"] = 1)] = "PendingApproval";
    PropertyInspectionStatus[(PropertyInspectionStatus["Approved"] = 2)] = "Approved";
    PropertyInspectionStatus[(PropertyInspectionStatus["Rejected"] = 3)] = "Rejected";
    PropertyInspectionStatus[(PropertyInspectionStatus["Completed"] = 4)] = "Completed";
    PropertyInspectionStatus[(PropertyInspectionStatus["Drafted"] = 5)] = "Drafted";
    PropertyInspectionStatus[(PropertyInspectionStatus["NewDateProposed"] = 6)] = "NewDateProposed";
})(PropertyInspectionStatus || (PropertyInspectionStatus = {}));

export var MasterDataType;
(function (MasterDataType) {
    MasterDataType[MasterDataType["JobTypes"] = 0] = "JobTypes";
})(MasterDataType || (MasterDataType = {}));

export var ChatbotOptionType;
(function(ChatbotOptionType) {
  ChatbotOptionType[(ChatbotOptionType["LogMaintenance"] = 0)] = "LogMaintenance";
  ChatbotOptionType[(ChatbotOptionType["CheckRental"] = 1)] = "CheckRental";
  ChatbotOptionType[(ChatbotOptionType["QandA"] = 2)] = "QandA";
  ChatbotOptionType[(ChatbotOptionType["OwnerLogin"] = 3)] = "OwnerLogin";
  ChatbotOptionType[(ChatbotOptionType["TrackIssue"] = 4)] = "TrackIssue";
})(ChatbotOptionType || (ChatbotOptionType = {}));

export var ChatbotLanguageOptionType;
(function (ChatbotOptionType) {
  ChatbotLanguageOptionType[(ChatbotLanguageOptionType["English"] = 0)] = "English";
  ChatbotLanguageOptionType[(ChatbotLanguageOptionType["Hindi"] = 1)] = "English";
  ChatbotLanguageOptionType[(ChatbotLanguageOptionType["Chinese"] = 2)] = "English";
})(ChatbotLanguageOptionType || (ChatbotLanguageOptionType = {}));
import { AXIOS } from '../Utilities/HttpRequest/Axios';
import { AppConfiguration } from "read-appsettings-json";

export const getUserSettings = () => {
    return AXIOS.GET(`tenant-api/user-settings?userId=${localStorage.getItem('userIdDecripted')}`);
}
export const revokeDevice = (channelType) => {
    return AXIOS.GET(`tenant-api/settings/revoke-device?userId=${localStorage.getItem('userIdDecripted')}&channelType=${channelType}`);
}
export const activateDevice = (channelType) => {
    return AXIOS.GET(`tenant-api/settings/activate-device?userId=${localStorage.getItem('userIdDecripted')}&channelType=${channelType}`);
}
export const updateTwoFactorAuthentication = (model) => {
    return AXIOS.POST(`tenant-api/settings/update-two-factor-authentication`, model);
}
export const updateCommunicationPreferences = (model) => {
    return AXIOS.POST(`tenant-api/settings/update-communication-preferences`, model);
}
export const updateQuoteSettings = (model) => {
    return AXIOS.POST(`tenant-api/settings/update-quote-settings`, model);
}
export const generateMobileValidationToken = (model) => {
    return AXIOS.POST(`tenant-api/settings/generate-phone-number-validation-token`, model);
}
export const confirmMobileValidationToken = (model) => {
    return AXIOS.POST(`tenant-api/settings/confirm-phone-number-validation-token`, model);
}
export const getOrganizationSettings = () => {
    return AXIOS.GET(`tenant-api/Organization-settings?userId=${localStorage.getItem('userIdDecripted')}`);
}

export const getGeneralSettings = (userId) => {
    return AXIOS.GET(`tenant-api/General-settings?userId=${userId}`);
}

export const getOrganizationSettingsByOrgId = (organizationId) => {
    return AXIOS.GET(`tenant-api/Organization-settings-for-property?organizationId=${organizationId}`);
}

export const getOmniOrgThemeSettings = (userId) => {
    return AXIOS.GET(`tenant-api/Organization-omni-theme-settings?userId=${userId}`);
}

export const getCardDetailsByUserId = (userId) => {
    return AXIOS.GET(`tenant-api/tenant-card-details?userId=${userId}`);
}

export const addCardDetails = (model) => {
    return AXIOS.POST(`tenant-api/add-card-details`, model);
}
export const deleteCardDetails = (model) => {
    return AXIOS.DELETE(`tenant-api/delete-card-details`, model);
}
export const setDefaultCardDetails = (model) => {
    return AXIOS.PUT(`tenant-api/default-card-details`, model);
}
const settingsService = {
    getUserSettings,
    revokeDevice,
    activateDevice,
    updateTwoFactorAuthentication,
    updateCommunicationPreferences,
    updateQuoteSettings,
    generateMobileValidationToken,
    confirmMobileValidationToken,
    getOrganizationSettings,
    getGeneralSettings,
    getOrganizationSettingsByOrgId,
    getOmniOrgThemeSettings,
    getCardDetailsByUserId,
    addCardDetails,
    deleteCardDetails,
    setDefaultCardDetails,
    
}

export default settingsService;
import PouchDB from 'pouchdb';

const InsertDocumentList = (tableName, documents) => {
    var promise = Promise.resolve();
    function addDoc(i) {
        return doAddDoc;
        function doAddDoc() {
            var doc = documents[i];
            doc._id = 'doc_' + i;
            return new window['PouchDB'](tableName).put(doc);
        }
    }
    for (var i = 0; i < documents.length; i++) {
        promise = promise.then(addDoc(i));
    }
    return promise;
}

const GetAllDocument = (tableName) => {
    return new PouchDB(tableName).allDocs({ include_docs: true, attachments: false });
}

const RemoveTable = (tableName) => {
    return new PouchDB(tableName).destroy();
}

const InsertDocument = (tableName, document) => {
    const table = new PouchDB(tableName);

    return table.post(document).then((res) => {
        console.log(`${tableName} inserted SUCCESSFULLY`);
    }).catch((err) => {
        console.error(err);
    }); 
}

const PouchDbService = {
    InsertDocumentList,
    InsertDocument,
    RemoveTable,
    GetAllDocument
}

export default PouchDbService;




import React, { Suspense } from 'react';
import { connect } from "react-redux";
//import "./Styles/style.scss";
import LogMaintenanceBotLayout from "./Components/Layout/LogMaintenanceBotLayout";
import botService from './Services/BotService';
import { withTranslation } from 'react-i18next';


class LogMaintenanceBotApp extends React.Component {

    state = {
        themeColor: this.props.themeColor,
        theme: {
            header: '',
            primaryColor: this.props.themeColor
        },
        headerBackground: { backgroundColor: this.props.themeColor },
        orgId: this.props.orgId,
        hasMultipleProperty:false,
        properties:[],
        userName:'' ,
        lang: (this.props.i18n.language == "en-US" || this.props.i18n.language == "en-GB" || this.props.i18n.language == undefined || this.props.i18n.language == null) ? "en" : this.props.i18n.language,
        isLanguageInitialized: false,
        linkedAgencies: [],
        isMultipleAgenciesExist: false,
        isPrefferedAgencySelected: false
    }

    constructor(props) {
        super(props);
        this.getChatbotSettings();
    }
    componentDidMount() {
        
    }
    shouldComponentUpdate (nextProps, nextState){
     return true;
    }
    render() {
        const { t } = this.props;
        var y = this.state;
        return (
            <div className="App">
                <Suspense fallback={<div>Loading...</div>}>
                    {this.state.isMultipleAgenciesExist && !this.state.isPrefferedAgencySelected ? 
                    <div className="chatbot-wrapper" style={{marginTop:'20px'}}>
                            <div className="chatbot-container">
                                <div className="chatbot-header" style={this.state.headerBackground}>
                                    <h2 className="chatbot-header-title">{this.state.theme.header}</h2>
                                </div>
                                <h3 style={{ marginLeft: '20px' }}>Please Select your Agency</h3>
                                <div id="chatbot-option-btn">
                                    <div className="chat-options-div">
                                        <ul className="chat-options-ul px-4">
                                            {
                                                this.state.linkedAgencies.map((item, i) => {
                                                    return <li className="chat-options-li" style={{ display: 'block' }} key={i}>
                                                        <a className="chat-option w-100 text-center" onClick={() => this.prefferedAgencySelect(item.organizationId, item.chatbotPrimaryColor)} >{item.agencyName}</a>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    </div>
                        : <LogMaintenanceBotLayout
                            key={this.state.lang}
                            orgId={this.state.orgId}
                            themeColor={this.state.themeColor}
                            passLanguageChange={(lang) => this.passLanguageChange(lang)}
                            lang={this.state.lang}
                            isLanguageInitialized={this.state.isLanguageInitialized} />
                    }
                </Suspense>
            </div>
        )
    }

    passLanguageChange=(lang)=>{
        this.setState({
            lang: lang,
            isLanguageInitialized: true
        });

    }

    getChatbotSettings = () => {
        let { orgId } = this.state;
        if (orgId) {
            botService.getChatbotSettings(orgId).then(result => {
                if (result.chatbotSettingsModel) {
                    let newTheme = {
                        header: (result.chatbotSettingsModel.chatbotHeader != null) ? result.chatbotSettingsModel.chatbotHeader : '',
                        primaryColor: (result.chatbotSettingsModel.chatbotPrimaryColor != null) ? result.chatbotSettingsModel.chatbotPrimaryColor : '',
                    }

                    var prefferedAgencies = (result.chatbotSettingsModel.linkedAgencies != undefined && result.chatbotSettingsModel.linkedAgencies != null) ? result.chatbotSettingsModel.linkedAgencies : [];
                    if (prefferedAgencies != null && prefferedAgencies.length > 0) {
                        this.setState({
                            isMultipleAgenciesExist: true,
                            isPrefferedAgencySelected: false,
                            linkedAgencies: prefferedAgencies,
                            theme: newTheme,
                            headerBackground: { backgroundColor: newTheme.primaryColor }
                        });
                    }

                }
            }, error => {
                console.log(error);
            })
        }
    }

    prefferedAgencySelect = (organizationId,chatbotPrimaryColor) => {
        this.setState({
            orgId: organizationId,
            isPrefferedAgencySelected: true,
            themeColor:chatbotPrimaryColor
        });
    }

}

export default connect()(withTranslation()(LogMaintenanceBotApp));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextInput from '../../../Common/TextInput/TextInput';
import UploadProfilePicture from '../UploadProfilePicture/UploadProfilePicture';
import userService from '../../../../Services/UserService';
import ProfilePicUploadDrawer from '../../../Drawers/ProfilePicUploadDrawer/ProfilePicUploadDrawer';
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import GoogleMapLocationsAutoComplete from '../../../Common/GoogleMapLocationsAutoComplete/GoogleMapLocationsAutoComplete';

import "./ProfileDetails.scss";


class ProfileDetails extends Component {

  constructor(props){
    super(props);
    this.handleAddressKeyUp = this.handleAddressKeyUp.bind(this);
  }

  state = {
    firstName: '',
    lastName: '',
    email: '',
    location: '',
    mobileNumber: '',
    homePhoneNumber: '',
    officePhoneNumber: '',
    profileImageUrl: '',
    profileImageId: '',
    errors: {

    },
    redirect: false,
    isEditing: false
  }

  createProfilePicUploader = () => {
    return (
      <ProfilePicUploadDrawer
        profileImageUrl={this.state.profileImageUrl}
        onSubmitAttachment={(event, url) => this.handleSubmitAttachment(event, url)}
      ></ProfilePicUploadDrawer>);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      firstName: nextProps.userData.firstName,
      lastName: nextProps.userData.lastName,
      email: nextProps.userData.email,
      location: nextProps.userData.location,
      mobileNumber: nextProps.userData.mobileNumber,
      homePhoneNumber: nextProps.userData.homePhoneNumber,
      officePhoneNumber: nextProps.userData.officePhoneNumber,
      profileImageUrl: nextProps.userData.profileImageUrl,
      profileImageId: nextProps.userData.profileImageId,
    });

  }

  handleSubmitAttachment = (urlId, url) => {
    this.setState({ profileImageId: urlId, profileImageUrl: url });
  }

  formIsValid() {
    const _errors = {};
    if (!this.state.firstName) _errors.firstName = "First Name is required";
    if (!this.state.lastName) _errors.lastName = "Last Name is required";
    if (!this.state.email) _errors.email = "Email is required";
    if (!this.state.location) 
    {
      _errors.location = "Address is required";
      toast.error(_errors.location,{
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
    if (!this.state.mobileNumber) _errors.mobileNumber = "Mobile Number is required";

    this.setState({ errors: _errors });
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  onClickSaveProfile = (event) => {
    event.preventDefault();
    let profileData = { ...this.state };
    profileData.profileImageUrl = this.state.profileImageId
    profileData.userId = localStorage.getItem('userId');
    if (!this.formIsValid()) return;
    try {
      userService.saveTenantProfile(profileData).then(res => {
        let userData = this.props.userDetails;
        userData.profileImageUrl = this.state.profileImageUrl;
        this.props.updateUserDetails(userData);
        this.setState({ redirect: true });
        toast.success("User data edited.",{
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
    } catch (error) {

    }

  }

  onInputChange = (event) => {
    switch (event.target.name) {
      case 'firstName': this.setState({ firstName: event.target.value }); break;
      case 'lastName': this.setState({ lastName: event.target.value }); break;
      case 'email': this.setState({ email: event.target.value }); break;
      case 'mobileNumber': this.setState({ mobileNumber: event.target.value.replace(/[^+ | 0-9]/g, '').replace(/(\..*)\./g, '$1') }); break;
      case 'homePhoneNumber': this.setState({ homePhoneNumber: event.target.value.replace(/[^+ | 0-9]/g, '').replace(/(\..*)\./g, '$1') }); break;
      case 'officePhoneNumber': this.setState({ officePhoneNumber:  event.target.value.replace(/[^+ | 0-9]/g, '').replace(/(\..*)\./g, '$1')  }); break;
      default: break;
    }
  }

  handleAddressKeyUp(){
    this.setState({isEditing: true})

  }

  setLocationDetails = (event) => {
    this.setState({ location: event.address });
  }

  render() {
    var style = {
      background: 'white'
    };
    const { redirect } = this.state;
    let updateProfilePicBody = this.createProfilePicUploader();
    if (redirect) {
      if (localStorage.getItem("userType") === "4") {
        return <Redirect to='/tenant/my-jobs' />;
      }
      return <Redirect to='/owner/my-jobs' />;
    }

    return (
      <React.Fragment>
        <form onSubmit={this.onClickSaveProfile} style={style} id="tenant-register-form" className="form-horizontal form-with-styles" >
          <div className="p-4">
            <div className="row">
              <div className="col-md-6">
                <TextInput
                    id="firstName"
                    label="First Name"
                    onChange={this.onInputChange}
                    name="firstName"
                    value={this.state.firstName}
                    error={this.state.errors.firstName}
                  />
                  <TextInput
                  id="lastName"
                  label="Last Name"
                  onChange={this.onInputChange}
                  name="lastName"
                  value={this.state.lastName}
                  error={this.state.errors.lastName}
                />
                <div className="form-group">
                  <label className="col-md-10 control-label p-0 m-0" htmlFor="email">Email</label>
                  <div className="col-md-10 p-0">
                      <input
                          id="email"
                          type="text"
                          name="email"
                          className="form-control"
                          value={this.state.email}
                          disabled
                      /><span className="highlight"></span>
                      <span className="bar"></span>
                  </div>
                </div>
                <div className="form-group col-md-10 p-0">
                  <label className=" control-label m-0" htmlFor="location">What is your Address</label>
                  {(!this.state.isEditing) ? 
                  <input type="text" className="form-control" id="location"
                   onKeyUp={this.handleAddressKeyUp}  
                   value={this.state.location} 
                   ></input> :
                  <GoogleMapLocationsAutoComplete  setLocationDetails={(event) => this.setLocationDetails(event)} address={this.state.location}></GoogleMapLocationsAutoComplete>}
                  </div>
                <TextInput
                  id="mobileNumber"
                  label="Mobile Phone Number"
                  onChange={this.onInputChange}
                  name="mobileNumber"
                  value={this.state.mobileNumber}
                  error={this.state.errors.mobileNumber}
                />
              </div>
              <div className="col-md-6">
              
                <TextInput
                  id="homePhoneNumber"
                  label="Home Phone Number"
                  onChange={this.onInputChange}
                  name="homePhoneNumber"
                  value={this.state.homePhoneNumber}
                  error={this.state.errors.homePhoneNumber}
                />
                <TextInput
                  id="officePhoneNumber"
                  label="Office Phone Number"
                  onChange={this.onInputChange}
                  name="officePhoneNumber"
                  value={this.state.officePhoneNumber}
                  error={this.state.errors.officePhoneNumber}
                />
                <div className="col-md-10 p-0 mt-4">
                    {updateProfilePicBody}
                </div>
              <div>
                <img className="current-profile-pic" src={this.state.profileImageUrl} onError={(e) => { e.target.onerror = null; e.target.src = "/Images/default-profile.png" }} />
              </div>
              {this.state.profileImageId == null ? '' : <button type="button" id="" class="btn btn-primary mt-2" onClick={e => this.onRemoveProfilePicture(e)}>Remove Profile Picture</button> }
              </div>
            </div>
          </div>
          <div className="p-4">
            <button type="submit" id="tenant-save" className="btn btn-primary">
              Save Profile</button>
          </div>
        </form>
      </React.Fragment>);
  }

  onRemoveProfilePicture = (event) => {
    event.preventDefault();
    this.setState({
      profileImageId: null,
      profileImageUrl: '/Images/default-profile.png'
    })
  }
}


const mapStateToProps = (state, ownProps) => {
  try {
    let userDetails = state.User;

    return {
      userDetails: userDetails,
      profileThumbnailsCdnEndpoint: state.AppSettings.imageCdnEndpoint,
      jobTypes: state.Jobs.jobTypes
    };
  } catch (error) {
    console.log(error);
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: details => {
      dispatch({ type: "SET_USER_DETAILS", userDetails: details });
    },
    updateIsTenantField: isCurrentTenant => {
      dispatch({ type: "CHECK_CURRENT_TENANT", isCurrentTenant: isCurrentTenant });
    },
    addJobTypes: jobTypes => { dispatch({ type: 'SET_JOB_TYPES', jobTypes: jobTypes })}
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);

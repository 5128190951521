import React, { useState } from 'react';
import { connect } from 'react-redux';
import chatService from '../../../../../Services/ChatService';
import moment from 'moment';

const MessagePost = (props) => {
    const [message, setMessage] = useState('');

    const onSendMessage = () => {
        console.log(message);
        if (message === null || message.trim() === "") return
        let chatAllowed = true;
        let newMessage = {}

        if (chatAllowed) {
            let loggedUserImg = props.loggedUserProfileImage;
            if (loggedUserImg === undefined || loggedUserImg === "" ||
                loggedUserImg === "null" || loggedUserImg === null ||
                loggedUserImg.startsWith("/images/default-profile.png")) {
                loggedUserImg = "/images/default-profile.png";
            } else {
                loggedUserImg = props.imageThumbnailsCdnEndpoint + loggedUserImg;
            }
            let senderFirstNameCompany = props.loggedUserFirstName;
            //if (false) {//this.userRole.indexOf('Providers') > -1
            //    // if (this.baseService.companyName) {
            //    //     senderFirstNameCompany = `${this.baseService.loggedUserFirstName}(${this.baseService.companyName})`;
            //    // }

            //    senderFirstNameCompany = `${props.senderFirstNameCompany}(company name)`;
            //    newMessage = {
            //        message: message,
            //        senderId: props.loggedUserId,
            //        senderImage: loggedUserImg,
            //        senderName: props.loggedUserFirstName,
            //        senderFirstName: senderFirstNameCompany,
            //        sentDate: new Date().toLocaleString(),
            //        timestamp: new Date().toLocaleString(),
            //        seenBy: [],
            //        isEmoteText: false,
            //        subTypeId: '',
            //        subType: 3,
            //        senderBusinessName: props.loggedUserFirstName
            //    }
            //    chatService.sendChatMessage(props.loggedUserId, newMessage, props.conversationTread, message);
            //} else {
                newMessage = {
                    message: message,
                    senderId: props.loggedUserId,
                    senderImage: loggedUserImg,
                    senderName: props.loggedUserFirstName,
                    senderFirstName: senderFirstNameCompany,
                    sentDate: moment(new Date().toUTCString().split(' GMT')[0]).format('DD/MM/YYYY HH:mm:ss'),
                    timestamp: new Date().toLocaleString(),
                    seenBy: [],
                    isEmoteText: false,
                    subTypeId: props.conversationTread.jobId ? props.conversationTread.jobId : '',
                    subType: props.conversationTread.jobId ? 1 : 3,
                    senderBusinessName: props.loggedUserFirstName
                }
                chatService.sendChatMessage(props.loggedUserId, newMessage, props.conversationTread, message);
                setMessage('');
            //}
        }
    }

    const onHandleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSendMessage();
          }
    }

    const resetCurrentNotifications = () => {
        props.conversationTread.loggedInUserId = props.loggedUserId;
        props.conversationTread.loggedUserFirstName = props.loggedUserFirstName
        chatService.resetConversationNotification(props.conversationTread);
      }

    return (
        <div className="ch-post">
            <div className="input-group">
                <input
                    disabled={props.options.isJobSpecificChat && props.options.isJobCompleted}
                    type="text"
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                    onKeyDown={onHandleKeyDown}
                    onClick={resetCurrentNotifications}
                    className="form-control"
                    placeholder="Type a message.."
                    aria-label="Recipient's username" />
                <button type="button" onClick={onSendMessage} disabled={props.options.isJobSpecificChat && props.options.isJobCompleted}>
                    <i className="ba-icn ba-icn-send"></i>
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {

    try {
        let chats = state.Conversation.conversationList

        return {
            chatBoxes: chats,
            imageThumbnailsCdnEndpoint: state.AppSettings.imageThumbnailsCdnEndpoint,
            imageCdnEndpoint: state.AppSettings.imageCdnEndpoint,
            profileThumbnailsCdnEndpoint: state.AppSettings.profileThumbnailsCdnEndpoint,
            loggedUserProfileImage: state.User.profileImageUrl,
            loggedUserFirstName: state.User.firstName,
            loggedUserId: state.User.userId,
        };
    } catch (error) {
        console.log(error)
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        updateChatboxes: (chatList) => { dispatch({ type: 'SHOW_CONVERSATION', chatList: chatList }) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagePost);
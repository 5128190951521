import React from 'react';
import { connect } from "react-redux";
import Datetime from "react-datetime";
import Drawer from '../../Common/Drawer/Drawer';
import scheduleService from '../../../Services/ScheduleService';
import { toast } from 'react-toastify';

class ScheduleJobDrawer extends React.Component {

    state = {
        showScheduleJob: false,
        events: [],
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
        title: '',
        description: ''
    }

    componentDidMount() {
        this.setState({
            title: this.props.jobDetails.title,
            description: this.props.jobDetails.description
        })
    };

    render() {
        const header = this.createHeader();
        const body = this.createBody();
        const footer = this.createFooter();

        return (
            <Drawer
                size={"md"}
                open={this.props.showScheduleJobDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    }

    handleChange = (event, name) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: event });
        }
    }

    createBody = () => {
        var yesterday = Datetime.moment().subtract(1, 'day');
        var validDate = function (current) {
            return current.isAfter(yesterday);
        };
        return (
            <div className="panel-body reschedule-drawer">
                <div className="row">
                    <div className="col-md-12">
                        {(this.props.jobDetails.scheduleEventId === null) ?
                            <div className="form-group">
                                <label htmlFor="title">Title</label>
                                <input type="text" value={this.state.title} onChange={(event) => this.setState({ title: event.target.value })} id="title" placeholder="Title" class="form-control" />
                            </div>
                            : ''
                        }
                        {(this.props.jobDetails.scheduleEventId === null) ?
                            <div className="form-group">
                                <label htmlFor="Description">Description</label>
                                <input type="text" value={this.state.description} onChange={(event) => this.setState({ description: event.target.value })} id="Description" placeholder="Description" class="form-control" />
                            </div>
                            : ''
                        }
                        <div className="form-group">
                            <div class="d-flex justify-content-start"><label>Start</label></div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Datetime
                                        isValidDate={validDate}
                                        closeOnSelect={true}
                                        dateFormat={this.props.dateFormat.toUpperCase()}
                                        timeFormat={false}
                                        value={this.state.fromDate}
                                        inputProps={{ placeholder: 'Start Date' }}
                                        onChange={(event) => { this.handleChange(event, 'fromDate') }} />
                                </div>
                                <div className="col-md-6">
                                    <Datetime
                                        closeOnSelect={true}
                                        dateFormat={false}
                                        inputProps={{ placeholder: 'Start Time' }}
                                        value={this.state.fromTime}
                                        onChange={(event) => { this.handleChange(event, 'fromTime') }} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div class="d-flex justify-content-start"><label>End</label></div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Datetime
                                        isValidDate={validDate}
                                        dateFormat={this.props.dateFormat.toUpperCase()}
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        inputProps={{ placeholder: 'End Date' }}
                                        onChange={(event) => { this.handleChange(event, 'toDate') }} />
                                </div>
                                <div className="col-md-6">
                                    <Datetime
                                        dateFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'End Time' }}
                                        onChange={(event) => { this.handleChange(event, 'toTime') }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    validateUI = () => {
        let v = true;
        if (this.props.jobDetails.scheduleEventId === null && document.getElementById('title').value == '') { v = v && false; toast.error('Please enter title.', { position: toast.POSITION.BOTTOM_RIGHT }) };
        if (this.state.fromDate == '') { v = v && false; toast.error('Please select start date.', { position: toast.POSITION.BOTTOM_RIGHT }) };
        if (this.state.fromTime == '') { v = v && false; toast.error('Please select start time.', { position: toast.POSITION.BOTTOM_RIGHT }) };
        if (this.state.toDate == '') { v = v && false; toast.error('Please select end date.', { position: toast.POSITION.BOTTOM_RIGHT }) };
        if (this.state.toTime == '') { v = v && false; toast.error('Please select end time.', { position: toast.POSITION.BOTTOM_RIGHT }) };
        if (v && new Date(new Date(this.state.fromDate).toDateString() + ' ' + this.state.fromTime.format('hh:mm A')) < new Date()) {
            v = v && false; toast.error('start Date & Time should greater than current date & time', { position: toast.POSITION.BOTTOM_RIGHT });
        }
        if (v && new Date(new Date(this.state.fromDate).toDateString() + ' ' + this.state.fromTime.format('hh:mm A')) > new Date(new Date(this.state.toDate).toDateString() + ' ' + this.state.toTime.format('hh:mm A'))) {
            v = v && false; toast.error('End Date & Time should greater than start date & time', { position: toast.POSITION.BOTTOM_RIGHT });
        }

        return v;
    }

    onScheduleAJob = () => {
        if (this.validateUI()) {
            let obj = {};

            obj.JobId = this.props.jobDetails.id;
            if (this.props.jobDetails.scheduleEventId === null) {
                obj.description = document.getElementById('Description').value;
                obj.title = document.getElementById('title').value;
                obj.status = 1;
            } else {
                obj.id = this.props.jobDetails.scheduleEventId;
                obj.status = 4;
            }
            obj.type = 0;
            obj.bidId = this.props.isExternal ? this.props.jobDetails.acceptedBidId : this.props.jobDetails.acceptedBid.bidId;
            obj.userId = localStorage.getItem('userIdDecripted');
            obj.fromDate = new Date(this.state.fromDate).toDateString();
            obj.fromTime = this.state.fromTime.format('hh:mm A');
            obj.toDate = new Date(this.state.toDate).toDateString();
            obj.toTime = this.state.toTime.format('hh:mm A');

            //if (this.props.isExternal) {
            scheduleService.saveExternalScheduleJob(obj).then(response => {
                if (response) {
                    toast.success('Job Scheduled', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.props.toggleSheduleJobDrawer();
                    this.props.getJobDetails(this.props.jobDetails.id);
                }
            });
            //} else {
            //    scheduleService.saveExternalScheduleJob(obj).then(response => {
            //        if (response) {
            //            toast.success('Job Scheduled', {
            //                position: toast.POSITION.BOTTOM_RIGHT
            //            });
            //            this.props.toggleSheduleJobDrawer();
            //            this.props.getJobDetails(this.props.jobDetails.id);
            //        }
            //    });
            //}

        }

    }

    //onSelectDate = (infor) => {
    //    if (window.confirm('do you want to add an event?')) {
    //        this.setState({
    //            events: this.state.events.concat({
    //                start: infor.start.getTime(),
    //                end: infor.end.getTime(),
    //                title: infor.startStr,
    //                allDay: infor.allDay
    //            })
    //        });
    //    }
    //}

    createHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">{(this.props.jobDetails.scheduleEventId == null) ? 'Schedule Job' : 'Re-Schedule Job'}</h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={() => this.props.toggleSheduleJobDrawer()}>
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createFooter = () => {
        return (
            <>
                <div className="card-actions">
                    <button onClick={() => this.onScheduleAJob()}>
                        <i className="ba-icn ba-icn-ok"></i> OK{" "}
                    </button>
                    <button className="more-actions" onClick={() => this.props.toggleSheduleJobDrawer()}>
                        {" "}
                        Cancel{" "}
                    </button>
                </div>
            </>
        );
    };

}


const mapStateToProps = (state, ownProps) => {
    return {
        isRefresh: state.MyJobs.isRefreshGrid,
        userType: state.User.userType,
        dateFormat: state.User.dateFormat
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refreshGrid: isRefresh => {
            dispatch({ type: "REFRESH_GRID", isRefresh: isRefresh });
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleJobDrawer);
import React, { Component } from 'react';
import '../../../Layout/LogMaintenanceBotLayout.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import botService from '../../../../Services/BotService.js';
import ImageViewer from "../../../Common/ImageViewer/ImageViewer";
import Moment from "react-moment";
import * as _ from 'lodash';
import { Accordion, ButtonToolbar, Card, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import translateService from '../../../../Services/TranslateService';
class OwnerApprovals_Wizard extends React.Component {

    constructor(props) {
        super(props);
        this.refActionReason = React.createRef();
        this.state = {
            isCustomThemeEnable: this.props.isCustomThemeEnable,
            theme: this.props.theme,
            style: this.props.style,
            ownerId: this.props.ownerId,
            pendingApprovalListVisible: true,
            jobListLoading: false,
            dateFormat: "dd/MM/yyyy",
            pendingApprovalJobList: [],
            totalJobCount: 0,
            pageSize: 100,
            pageNumber: 0,
            isJobApproval: false,
            isJobAcceptClick: false,
            isQuoteAcceptClick: false,
            isDeclineAllQuotesClick: false,
            selectedJobId: '',
            selectedJobNumber: '',
            selectedJobTitle: '',
            selectedBidId: '',
            actionReason: '',
            jobActionProcessing: false,
            isSuccessActionResult: false,
            isActionResultView: false
        }
    }

    componentDidMount() {
        this.LoadPendingOwnerApprovalJobList();
        this.setTheme();
    }

    componentDidUpdate() {
        if (this.props.isCustomThemeEnable) {
            this.createToggleBtnDynamicStyle();
            this.createOkBtnDynamicStyle();
            this.createActiveBtnDynamicStyle();
        }
    }

    applyTranslation(texts, sourceLng, targetLng) {
        const requestModel = {
            text: texts,
            targetCode: targetLng,
            sourceCode: sourceLng
        };
        return translateService.translate(requestModel);
    }

    createToggleBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.color;
        const border = this.props.style.optionsStyle.border;
        let labelInsideToggleGroup = document.querySelectorAll('label.btn');

        for (var i = 0; i < labelInsideToggleGroup.length; i++) {
            labelInsideToggleGroup[i].style.color = color;
            labelInsideToggleGroup[i].style.border = border;
            labelInsideToggleGroup[i].style.background = '';
        }
    }
    createOkBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.color;
        let okIcons = document.querySelectorAll('span.ba-icn-ok');

        for (var i = 0; i < okIcons.length; i++) {
            okIcons[i].style.background = color;
        }
    }
    createActiveBtnDynamicStyle() {
        const color = this.props.style.optionsStyle.activeBackground;
        let active = document.querySelectorAll('label.btn.active');
        for (var i = 0; i < active.length; i++) {
            active[i].style.background = color;
        }
    }

    LoadPendingOwnerApprovalJobList = () => {
        this.setState({ jobListLoading: true });
        var ownerId = this.props.ownerId;
        const { i18n } = this.props;
        botService.getPendingOwnerApprovalList(ownerId.trim()).then(result => {
            if (result && result.jobsList.length > 0) {

                this.setState({
                    pendingApprovalJobList: result.jobsList,
                    totalJobCount: result.total,
                    jobListLoading: false
                });

            }
            else {
                this.setState({
                    pendingApprovalListVisible: false,
                    jobListLoading: false
                });
            }
            this.props.scrollToWizardHeight();

        }, error => {
        });
    }

    addBodyStyle = () => {
        var element = document.body;
        element.style.cssText = 'overflow:hidden !important;';
        element.style.cssText = 'padding-top:0 !important; overflow:hidden !important; background: transparent !important;';
        document.documentElement.style.cssText = "background: transparent !important;";
    }
    setTheme = () => {
        const { style, theme, isCustomThemeEnable } = this.props;
        this.setState({
            style: style,
            theme: theme,
            isCustomThemeEnable: isCustomThemeEnable
        });
    }

    render() {
        let body = this.createBody();
        return <>
            <div className="jobpost-wizard chat">
                {body}
            </div>
        </>
    }
    createBody = () => {
        let { pendingApprovalListVisible, isJobApproval, isActionResultView, totalJobCount } = this.state;
        let jobList = this.createOwnerApprovalList();
        let jobActionView = this.createPendingApprovalActionView();
        let jobActionResultView = this.createActionResultView();
        const { t } = this.props;
        return <div className="wizard-inner" style={{ minHeight: '360px' }}>
            <div className="wizard-body" style={{ minHeight: '360px' }}>
                <div className="wizard-header">
                    <h2 className="wizard-main-title">{t('ownerapproval_lbl_header')} </h2>
                    {(pendingApprovalListVisible) ?
                        <div>
                            <div className="sub-title-section w-100 d-flex">
                                <div className="sub-title d-flex flex-column text-left justify-content-center">
                                    <h6>{t('ownerapproval_lbl_jobs')}</h6>
                                    <h3>{t('ownerapproval_lbl_toapprove')}</h3>
                                </div>
                                <div className="count d-flex flex-column text-right justify-content-center">
                                    <h5>{totalJobCount}</h5>
                                </div>
                            </div>
                            {jobList}
                        </div> :
                        (isJobApproval) ?
                            <div>
                                <p className="userTip"><span className="font-500"> {t('ownerapproval_lbl_accept_reject_job')} </span></p>
                                {jobActionView}
                            </div> :
                            (isActionResultView) ?
                                <div>
                                    {jobActionResultView}
                                </div> :
                                <div>
                                    <p className="userTip"><span className="font-500"> {t('ownerapproval_lbl_no_jobs')} </span></p>
                                </div>
                    }

                </div>
            </div>
        </div>
    }

    createOwnerApprovalList = () => {
        let { pendingApprovalJobList, pageNumber, pageSize, theme } = this.state;
        let count = 0;
        let list = pendingApprovalJobList;
        const { t } = this.props;
        return <>
            <div className="wizard-actions">
                {(this.state.jobListLoading) ? <div className="mb-5 text-center wizard-loader"><Loader type="ThreeDots" color={"#DB3D44"} height={10} width={100} /></div>
                    : <div className='custom-scroller pending-approvals'>
                        <div className="wizard-contents">
                            {list.map((a, index) => {
                                return <Card className="chat-card-wrapper">
                                    <Card.Body className="chat-card">
                                        <div className="title-section">
                                            <h2 className="chat-title">{a.title} [#{a.jobNumber}]</h2>
                                            <div className="tags">
                                                <div className="tag black">
                                                    {a.jobVariations.length > 0 ? t('ownerapproval_lbl_variation_approval') : (a.bids.bidsList.length > 0 ? t('ownerapproval_lbl_quote_approval') : t('ownerapproval_lbl_job_approval'))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="date">{t('ownerapproval_lbl_posted_on')} {" "}
                                            <Moment format={this.state.dateFormat.toUpperCase()}>
                                                {a.postedOn}
                                            </Moment>
                                        </div>
                                        <div className="description">
                                            {a.description}
                                        </div>
                                        <div className="icon-line">
                                            <div className="icon"><img src="/images/location_on_black.svg" /></div>
                                            <div className="text address">{a.address}</div>
                                        </div>
                                        <div className="icon-line">
                                            <div className="icon"><img src="/images/construction_black.svg" /></div>
                                            <div className="text"><strong>{a.primaryJobTypeDisplay} : {a.secondaryJobTypeDisplay} </strong> | <span class="red">{a.jobUrgencyDisplay}</span></div>
                                        </div>
                                        <div className="icon-line">
                                            <div className="icon"><img src="/images/account_balance_wallet_black.svg" /></div>
                                            <div className="text">{this.budgetFormatter(a.budgetId, a.budgetAmount, a.budgetMax)}</div>
                                        </div>
                                        {a.attachments.length > 0 ? (<div className="icon-line">
                                            <div className="icon"><img src="/images/attach_file_black.svg" /></div>
                                            <div className="text">
                                                <div className="external-gallery">
                                                    <ImageViewer
                                                        attachments={a.attachments}
                                                        isAfter={false}
                                                        isExternal={true}
                                                    ></ImageViewer>
                                                </div>
                                            </div>
                                        </div>) : ''
                                        }

                                        {(a.bids.bidsList.length > 0 && a.jobVariations.length == 0) ? (<div>
                                            {a.bids.bidsList.map((b, index) => {
                                                return <div className="quote-wrapper mb-2">
                                                    <div className="quote">
                                                        <div className="text">
                                                            <h4 className="quote-title">{t('ownerapproval_lbl_quoted_by')} {" "}{b.name}</h4>
                                                            <h3 className="quote-amount">${b.amount.toFixed(2)}</h3>
                                                        </div>
                                                        <div className="button flex-column">
                                                            <button className="bt bt-blue" disabled={this.state.jobActionProcessing} onClick={() => this.clickJobQuoteAccept(a, b)}>{t('ownerapproval_lbl_accept')}</button>
                                                            {(this.state.jobActionProcessing && this.state.selectedBidId == b.bidId) ? <div className="text-center"><Loader type="ThreeDots" color={"#DB3D44"} height={10} width={100} /></div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </div>) : ''
                                        }

                                        {a.jobVariations.length > 0 ? (<div>
                                            {a.jobVariations.map((v, index) => {
                                                return <div className="variation mb-2">
                                                    <div className="title-section">
                                                        <h2 className="chat-title">{t('ownerapproval_lbl_variation_by')} {" "}{v.postedBy}</h2>
                                                        <div className="tags text-right">
                                                            <strong>${v.amount.toFixed(2)}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="date">{t('ownerapproval_lbl_on')} {" "}
                                                        <Moment format={this.state.dateFormat.toUpperCase()}>
                                                            {v.createdDate}
                                                        </Moment>
                                                    </div>
                                                    <div className="description">
                                                        {v.description}
                                                    </div>
                                                    {v.jobVariationAttachments.length > 0 ? (<div className="external-gallery">
                                                        <ImageViewer
                                                            attachments={v.jobVariationAttachments}
                                                            isAfter={false}
                                                            isExternal={true}
                                                        ></ImageViewer>
                                                    </div>) : ''
                                                    }

                                                </div>
                                            })
                                            }
                                        </div>) : ''
                                        }

                                        {((a.bids.bidsList.length == 0 && a.jobVariations.length == 0) || a.jobVariations.length > 0) ? (
                                            <div className="inner-buttons mt-3">
                                                <button className="bt bt-red" onClick={() => this.onJobApproveRejectClick(false, a)}>
                                                    {t('ownerapproval_lbl_reject')}{" "}
                                                </button>
                                                <button className="bt bt-blue" onClick={() => this.onJobApproveRejectClick(true, a)}>
                                                    {t('ownerapproval_lbl_approve')}{" "}
                                                </button>
                                            </div>
                                        ) : (a.bids.bidsList.length > 0 && a.jobVariations.length == 0) ? (
                                            <div className="inner-buttons flex-column mt-3 align-items-end justify-content-end">
                                                <div>
                                                        <button className="bt bt-red ml-0" disabled={this.state.jobActionProcessing} onClick={() => this.clickJobDeclineAllQuotes(a)}>{t('ownerapproval_lbl_decline_quotes')}</button>
                                                    {(this.state.jobActionProcessing && this.state.selectedJobId == a.id) ? <div className="d-flex justify-content-center mt-2"><Loader type="ThreeDots" color={(theme.primaryColor != "") ? theme.primaryColor : "#DB3D44"} height={10} width={100} /></div> : ''}
                                                </div>

                                            </div>
                                        ) : ''
                                        }

                                    </Card.Body>
                                </Card>
                            })
                            }
                        </div>
                    </div>
                }
                <button type="button" className="btn btn-primary mx-0 mb-0 back-to-main-chat" onClick={() => this.props.backToMainMenu()} style={this.state.style.secondaryButtonStyle}>{t('ownerapproval_lbl_back_to_main_chat')}</button>
            </div>
        </>
    }

    createPendingApprovalActionView = () => {
        let { theme, isJobAcceptClick, selectedJobNumber } = this.state;
        const { t } = this.props;
        return <>
            <div className="wizard-actions">
                <div className="wizard-contents">
                    <Card className="chat-card-wrapper">
                        <Card.Body className="chat-card">
                            <div className="approve-reject-section">
                                <h2 className="approve-reject-title">{isJobAcceptClick ? t('ownerapproval_lbl_accept') : t('ownerapproval_lbl_reject')} {t('ownerapproval_lbl_job')} {t('ownerapproval_lbl_approval')} : <span>#{selectedJobNumber}</span> </h2>
                            </div>
                            <div className="approve-reject-reason">
                                <label>{t('ownerapproval_lbl_reason_for')} {isJobAcceptClick ? t('ownerapproval_lbl_approval') : t('ownerapproval_lbl_rejection')}</label>
                                <textarea className="form-control form-textarea" placeholder="please provide the reason for this action" aria-label="test"
                                    ref={this.refActionReason} onChange={(value) => this.handleOnChangeActionReason(value)}>
                                </textarea>
                            </div>
                            {isJobAcceptClick ? (<div className="inner-buttons flex-column mt-3 align-items-end justify-content-end">
                                <button className="bt bt-blue ml-0" disabled={this.state.actionReason == '' || this.state.jobActionProcessing} onClick={() => this.clickJobApproveRejectAction(true)}>
                                    {t('ownerapproval_lbl_approve')}{" "}
                                </button>
                                {(this.state.jobActionProcessing) ? <div className="d-flex justify-content-center mt-2"><Loader type="ThreeDots" color={(theme.primaryColor != "") ? theme.primaryColor : "#DB3D44"} height={10} width={100} /></div> : ''}

                            </div>) : (<div className="inner-buttons flex-column mt-3 align-items-end justify-content-end">
                                    <button className="bt bt-red ml-0" disabled={this.state.actionReason == '' || this.state.jobActionProcessing} onClick={() => this.clickJobApproveRejectAction(false)}>
                                    {t('ownerapproval_lbl_reject')}{" "}
                                </button>
                                {(this.state.jobActionProcessing) ? <div className="d-flex justify-content-center mt-2"><Loader type="ThreeDots" color={(theme.primaryColor != "") ? theme.primaryColor : "#DB3D44"} height={10} width={100} /></div> : ''}

                            </div>)}

                        </Card.Body>
                    </Card>
                </div>
                <button type="button" className="btn btn-primary mb-2 mb-md-0 back-to-main-chat" onClick={() => this.backToPendingApprovalList()} style={this.state.style.secondaryButtonStyle}>{t('ownerapproval_lbl_back_to_job_list')}</button>
                <button type="button" className="btn btn-primary back-to-main-chat" onClick={() => this.props.backToMainMenu()} style={this.state.style.secondaryButtonStyle}>{t('ownerapproval_lbl_back_to_main_chat')}</button>
            </div>
        </>
    }

    createActionResultView = () => {
        let { theme, isJobAcceptClick, isQuoteAcceptClick, isDeclineAllQuotesClick, isSuccessActionResult, selectedJobNumber, selectedJobTitle } = this.state;
        const { t } = this.props;
        return <>
            <div className="wizard-actions">
                <div className="mb-5 wizard-contents">
                    <div className="success-section-wrapper">
                        <div className="success-msg-image text-center mb-4">
                            <img src="/images/success-msg.PNG" width="160" />
                        </div>
                        <div className="success-section">
                            {isSuccessActionResult ? <>
                                {isQuoteAcceptClick ? (<div className="mb-3">
                                    <h2 className="success-title">{t('ownerapproval_lbl_success_accept_quote')}</h2>
                                </div>) : isDeclineAllQuotesClick ? (<div className="mb-3">
                                    <h2 className="success-title">{t('ownerapproval_lbl_success_decline_quotes')}</h2>
                                </div>) : (<div className="mb-3">
                                    <h2 className="success-title">{t('ownerapproval_lbl_you_have_successfully')} {isJobAcceptClick ? t('ownerapproval_lbl_approved') : t('ownerapproval_lbl_rejected')} {t('ownerapproval_lbl_the_job')}</h2>
                                </div>)
                                }
                            </> : <>
                                {isQuoteAcceptClick ? (<div className="mb-3">
                                    <h2 className="success-title">{t('ownerapproval_lbl_quote_accept_failed')}</h2>
                                </div>) : isDeclineAllQuotesClick ? (<div className="mb-3">
                                    <h2 className="success-title">{t('ownerapproval_lbl_quotes_decline_failed')}</h2>
                                </div>) : (<div className="mb-3">
                                    <h2 className="success-title">{t('ownerapproval_lbl_job')} {isJobAcceptClick ? t('ownerapproval_lbl_approval') : t('ownerapproval_lbl_rejection')} {t('ownerapproval_lbl_failed')}</h2>
                                </div>)
                                }
                            </>
                            }
                            <div className="success-description">
                                <strong>{selectedJobTitle} [#{selectedJobNumber}]</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary mb-2 mb-md-0 back-to-main-chat" onClick={() => this.backToPendingApprovalList()} style={this.state.style.secondaryButtonStyle}>{t('ownerapproval_lbl_back_to_job_list')}</button>
                <button type="button" className="btn btn-primary back-to-main-chat" onClick={() => this.props.backToMainMenu()} style={this.state.style.secondaryButtonStyle}>{t('ownerapproval_lbl_back_to_main_chat')}</button>
            </div>
        </>
    }

    backToPendingApprovalList = () => {
        if (this.state.isSuccessActionResult) {
            this.LoadPendingOwnerApprovalJobList();
        }
        this.setState({
            pendingApprovalListVisible: true,
            isJobApproval: false,
            isActionResultView: false,
            isJobAcceptClick: false,
            isDeclineAllQuotesClick: false,
            isQuoteAcceptClick: false,
            isSuccessActionResult: false,
            selectedJobId: '',
            selectedJobNumber: '',
            selectedJobTitle: '',
            selectedBidId: '',
            actionReason: '',
            pageNumber: 0
        });
    }

    more = () => {
        let number = this.state.pageNumber + 1;
        this.setState({ pageNumber: number });
    }

    pageWiseList = (filterdList, pageNumber, pageSize) => {
        let total = filterdList.length;
        if (total <= pageSize) {
            return filterdList;
        } else {
            let rest = total - (pageNumber * pageSize);
            if (rest > 0 && rest >= pageSize) {
                return _.take(_.drop(filterdList, (pageNumber * pageSize)), pageSize);
            } else if (rest > 0 && rest < pageSize) {
                return _.take(_.drop(filterdList, (pageNumber * pageSize)), rest);
            }
        }
    }

    budgetFormatter(budgetId, budgetAmount, budgetMax) {
        switch (budgetId) {
            case 0:
                return <div className="table-data "> Other</div>;
            case 1:
                return <div className="table-data "> Under $500</div>;
            case 2:
                return <div className="table-data "> Between $500 - $1000</div>;
            case 3:
                return <div className="table-data "> Between $1000 - $2000</div>;
            case 4:
                return <div className="table-data "> Between $2000 - $5000</div>;
            case 5:
                return <div className="table-data "> Over $5000</div>;
            case 7:
                return (
                    <div className="table-data ">
                        {" "}
                        {"$ " + budgetAmount} - {"$ " + budgetMax}
                    </div>
                );
            case -999:
                return (
                    <div className="table-data ">
                        {" "}
                        {budgetAmount == 0
                            ? "Not supplied"
                            : "$ " + budgetAmount.toFixed(2)}
                    </div>
                );
        }
    }

    onJobApproveRejectClick = (isApprove, job) => {
        this.setState({
            isJobApproval: true,
            pendingApprovalListVisible: false,
            isJobAcceptClick: isApprove,
            selectedJobId: job.id,
            selectedJobNumber: job.jobNumber,
            selectedJobTitle: job.title
        });
    }

    handleOnChangeActionReason = (event) => {
        this.setState({ actionReason: event.currentTarget.value });
    }

    clickJobApproveRejectAction = (isApprove) => {
        this.setState({ jobActionProcessing: true });
        var userId = this.props.ownerId;
        var jobId = this.state.selectedJobId;
        var note = this.state.actionReason;
        botService.ownerApproveRejectJob(userId, jobId, note, isApprove).then(res => {
            if (res) {
                this.setState({
                    jobActionProcessing: false,
                    isJobApproval: false,
                    isSuccessActionResult: true,
                    isActionResultView: true
                });
            }
            else {
                this.setState({
                    jobActionProcessing: false,
                    isJobApproval: false,
                    isSuccessActionResult: false,
                    isActionResultView: true
                });
            }
        }, error => {
        });
    }

    clickJobQuoteAccept = (job, bid) => {
        this.setState({ jobActionProcessing: true, isQuoteAcceptClick: true, selectedBidId: bid.bidId, selectedJobNumber: job.jobNumber, selectedJobTitle: job.title });
        var userId = this.props.ownerId;
        var jobId = job.id;
        var quoteId = bid.bidId;
        var note = '';
        botService.ownerAcceptJobQuote(quoteId, userId, jobId, note).then(res => {
            if (res) {
                this.setState({
                    jobActionProcessing: false,
                    pendingApprovalListVisible: false,
                    isSuccessActionResult: true,
                    isActionResultView: true
                });
            }
            else {
                this.setState({
                    jobActionProcessing: false,
                    pendingApprovalListVisible: false,
                    isSuccessActionResult: false,
                    isActionResultView: true
                });
            }
        }, error => {
        });
    }

    clickJobDeclineAllQuotes = (job) => {
        this.setState({ jobActionProcessing: true, isDeclineAllQuotesClick: true, selectedJobId: job.id, selectedJobNumber: job.jobNumber, selectedJobTitle: job.title });
        var userId = this.props.ownerId;
        var jobId = job.id;
        var note = '';
        botService.ownerDeclineAllJobQuotes(userId, jobId, note).then(res => {
            if (res) {
                this.setState({
                    jobActionProcessing: false,
                    pendingApprovalListVisible: false,
                    isSuccessActionResult: true,
                    isActionResultView: true
                });
            }
            else {
                this.setState({
                    jobActionProcessing: false,
                    pendingApprovalListVisible: false,
                    isSuccessActionResult: false,
                    isActionResultView: true
                });
            }
        }, error => {
        });
    }

}


export default connect()(OwnerApprovals_Wizard);
import React, { Component } from "react";


class UploadProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    return (
      <>
        <div className="col-md-8">

          <div className="form-group">
            <label className="col-md-12 control-label p-0">
              Upload your <strong> profile photo</strong>.
  </label>
            <div className="file-upload">
              <div id="example" className="k-content">

                <div className="demo-section k-content wide">
                  <div className="wrapper">
                    <div className="profileImageDropZone dropZoneElement">
                      <div className="textWrapper">
                        <p><span></span>Add Image</p>
                        <p className="dropImageHereText mb-0">Drop image here to upload</p>
                        <p className="text-center font-12 font-600">(.jpg, .jpeg, .png, .bmp, .gif)</p>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className="form-group">
            <div id="providerPhoto">
            </div>
            <div id="deletePhotoHolder" className="ml-4 mt-2"></div>
          </div>
          <span asp-validation-summary="All" className="text-danger"></span>
        </div>

      </>
    );
  }
}

export default UploadProfilePicture;

import React, { useState } from "react";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";
import { Dropdown } from 'semantic-ui-react';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import { BrowserRouter as Router, Route, Link, } from 'react-router-dom'
import Loader from "react-loader-spinner";
import settingsService from "../../../Services/settingsService";
import jobService from "../../../Services/JobService";
// Styles
//import "../OwnerApproval/JobApproveDrawer.scss";

class ExternalJobApprove extends React.Component {
  notifyError = (msg) => toast.error(msg, {
    position: toast.POSITION.BOTTOM_RIGHT
  });
  state = {
    showJobApproveDrawer: false,
    jobApproveOption: '0',
    showQuoteLimitSelection: false,
    showSuggestedTrade: false,
    selectedQuoteLimit: 0,
    selectedTrade: '',
    quoteLimit: [
      { key: 1, text: '1', value: 1 },
      { key: 2, text: '2', value: 2 },
      { key: 3, text: '3', value: 3 },
      { key: 4, text: '4', value: 4 },
      { key: 5, text: '5', value: 5 },
      { key: 6, text: '6', value: 6 },
      { key: 7, text: '7', value: 7 },
      { key: 8, text: '8', value: 8 },
      { key: 9, text: '9', value: 9 },
      { key: 10, text: '10', value: 10 },
      { key: 11, text: 'Unlimited', value: 11 }],
    suggestedTradeList: [],
    jobDetails: {},
    history: {},
    isLoading: false,
    showAverageCostWhenOwnerApproval: false,
    isOrganizationSettingsLoaded: false,
    averageCostModel: {
      jobAverageCost: 0.00,
      jobCount: 0,
      confidenceLevel: 1
    },
    isAverageCostModelLoaded: false,
    disableApproveButton:false
  };
  constructor(props) {
    super(props);
    this.validateApproval = this.validateApproval.bind(this);
    this.JobApprove = this.JobApprove.bind(this);
  }

  componentDidMount() {
    this.setState({ jobDetails: this.props.jobDetails, userId: this.props.userId });
    this.description = React.createRef();
    this.approvedAmount = React.createRef();
    this.isEmptySuggestedTradeList = false
    this.getOrganizationSettings(localStorage.getItem('orgId'));
  }

  getOrganizationSettings(organizationId) {
    const jobId = window.jobId;
    settingsService.getOrganizationSettingsByOrgId(organizationId)
      .then((res) => {
        this.setState({
          showAverageCostWhenOwnerApproval: res.organizationSettingsModel.showAverageCostWhenOwnerApproval,
          isOrganizationSettingsLoaded: true,
        });
        if (res.organizationSettingsModel.showAverageCostWhenOwnerApproval) {
          this.getPredictiveAverageCost(jobId);
        }
      })
  }

  //for getting average job cost
  getPredictiveAverageCost = (jobId) => {
    try {
      jobService.GetPredictiveJobAverageCost(jobId)
        .then(data => {
          this.setState({ averageCostModel: data });
          this.setState({ isAverageCostModelLoaded: true });
        });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <>
        {this.createJobApproveBody()}
      </>
    );
  }
  createJobApproveBody = () => {
    let quoteLimitSelection = this.createQuoteLimitSelection();
    let suggestedTradeSelection = this.createSuggestedTradeSelection();

    return (
      <div className="panel-body">
        {/* {(this.props.jobDetails && this.props.jobDetails.isEnableOwnerApprovalPercentage == false) ?
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <ButtonToolbar className="radio-btn-group">
                                <ToggleButtonGroup type="radio" name="options" ref="jobApproveOption" onChange={this.handleJobApproveOptionOnChange}>
                                    <ToggleButton value="1">Send out direct work order</ToggleButton>
                                    <ToggleButton value="2">Please get some quotes</ToggleButton>
                                    <ToggleButton value="3">Delegate to Property Manager</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div>
                    </div>
                </div>
            </> : ''
        } */}
        {quoteLimitSelection}
        {suggestedTradeSelection}
        {(this.state.showAverageCostWhenOwnerApproval && this.state.isAverageCostModelLoaded) ?
          <div className="row">
            <div className="col-sm-12">
              <div className="info-header-bar text-center">
                <div className={this.getConfidenceLevelClass(this.state.averageCostModel.confidenceLevel)}>The suggested average cost range for the job is +/-${this.state.averageCostModel.jobAverageCost}</div>
              </div>
            </div>
          </div>
          :
          ''
        }
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="w-100 text-left">Note</label>
              <textarea
                className="form-control"
                id="txtFeedback"
                rows="3"
                ref={this.description}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row d-none">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  name="markAsCompleted"
                  type="checkbox"
                  id="1"
                  className="custom-control-input"
                />
                <label
                  title=""
                  type="checkbox"
                  htmlFor="1"
                  className="custom-control-label"
                >
                  {" "}
                  Mark as Completed
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-right edit-job-actions">
          <button class="btn btn-approve" onClick={this.JobApprove} disabled={this.state.disableApproveButton}>
            {this.state.isLoading ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : <><i class="ba-icn ba-icn-ok"></i> Approve</>}
          </button>
          <button class="btn btn-primary" onClick={() => this.props.history.goBack()}> Cancel</button></div>
      </div>
    );
  };

  createQuoteLimitSelection = () => {
    if (this.state.showQuoteLimitSelection) {
      return (
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Quote Limit</label>
                <Dropdown
                  selectOnNavigation={false}
                  selection
                  options={this.state.quoteLimit}
                  placeholder='How many quotes'
                  onChange={this.handleQuoteLimitChange}
                />
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return
    }
  }

  createSuggestedTradeSelection = () => {
    let messageForEmptySuggestedTradeSelection = this.createMessageForEmptySuggestedTradeSelection();
    if (this.state.showSuggestedTrade) {
      return (
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Select a trade</label>
                <Dropdown
                  selectOnNavigation={false}
                  selection
                  options={this.state.suggestedTradeList}
                  placeholder='Select a trade'
                  onChange={this.handleTradeChange}
                />
              </div>
            </div>
          </div>
          {messageForEmptySuggestedTradeSelection}
        </>
      )
    } else {
      return
    }
  }
  createMessageForEmptySuggestedTradeSelection = () => {
    if (this.isEmptySuggestedTradeList) {
      return (
        <>
          <div className="row">
            <div className="col-sm-12">
              <label className="empty-list-label px-0">There are no suggested trades, you may delegate the job to your property manager</label>
            </div>
          </div>
        </>
      )
    }
    else {
      return
    }
  }

  handleJobApproveOptionOnChange = (value) => {
    this.setState({ jobApproveOption: value });

    if (value === "1") {
      try {
        AXIOS.GET(
          `tenant-api/suggested-trades?jobId=${this.props.jobDetails.id}`
        ).then(response => {
          if (response) {
            if (response.length <= 0) {
              this.isEmptySuggestedTradeList = true;
            }
            this.setState({ suggestedTradeList: response.map((a, index) => { return { key: index, text: a.businessName, value: a.userId }; }) });
            this.setState({ showQuoteLimitSelection: false, showSuggestedTrade: true });
          } else {
            toast.error("Error occured. Please try again.", {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    else if (value === "2") {
      this.setState({ showQuoteLimitSelection: true, showSuggestedTrade: false });
    }
    else if (value === "3") {
      this.setState({ showQuoteLimitSelection: false, showSuggestedTrade: false });
    }
  }

  onQuoteLimitSelection = (value) => {

  }

  validateApproval = (value) => {
    let v = true;
    // if (this.state.jobApproveOption === '0' && this.props.jobDetails.isEnableOwnerApprovalPercentage == false) {
    //   toast.error('Please select approval option.',{
    //     position: toast.POSITION.BOTTOM_RIGHT
    //   }); v = v && false;
    // }

    // if (this.state.jobApproveOption === '1' && this.state.selectedTrade === '') {
    //   toast.error('Please select a trade.',{
    //     position: toast.POSITION.BOTTOM_RIGHT
    //   }); v = v && false;
    // }

    // if (this.state.jobApproveOption === '2' && this.state.selectedQuoteLimit === 0) {
    //   toast.error('Please select a Quote Limit.',{
    //     position: toast.POSITION.BOTTOM_RIGHT
    //   }); v = v && false;
    // }

    if (this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
      toast.error("Please enter job approving note.", {
        position: toast.POSITION.BOTTOM_RIGHT
      }); v = v && false;
    }

    return v;
  }

  JobApprove = (value) => {
    try {

      if (this.validateApproval()) {
        this.setState({
          isLoading: true,
          disableApproveButton:true
        })
        let jobApprovalRequest = {};
          jobApprovalRequest.userId = localStorage.getItem("exernalUserId");
        jobApprovalRequest.jobId = this.props.jobDetails.id;
        jobApprovalRequest.note = this.description.current.value;
        jobApprovalRequest.jobApproveOption = parseFloat(this.state.jobApproveOption);
        jobApprovalRequest.quoteLimit = this.state.selectedQuoteLimit;
        jobApprovalRequest.tradeId = this.state.selectedTrade;
        jobApprovalRequest.IsOwnerApproved = true;

        let variationText = "";
        if (this.props.jobDetails.isOwnerApprovalPendingForVariation &&
          this.props.jobDetails.currentVariation &&
          this.props.jobDetails.currentVariation.status == 201) {
            variationText = "Variation ";
        }

        trackPromise(
          AXIOS.POST(`tenant-api/job-approve-owner`, jobApprovalRequest)
            .then(response => {
              if (response && response.success) {
                toast.success(`Job ${variationText}Approved.`, {
                  position: toast.POSITION.BOTTOM_RIGHT
                });
                this.props.onJobApproveOrReject();
                this.props.history.push(`/external/overview?code=${this.props.jobDetails.id}&userId=${jobApprovalRequest.userId}`);
                this.setState({
                  isLoading: true
                })
                // window.location.reload();
              } else {
                this.props.history.push(`/external/overview?code=${this.props.jobDetails.id}&userId=${this.state.userId}`);
                toast.error('Job’s status has been changed. Please contact the property manager for more details', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
              }
            }));
      }
    } catch (error) {
      console.log(error);
    }

  };

  handleQuoteLimitChange = (event, props) => {
    this.state.selectedQuoteLimit = props.value;
    this.state.selectedTrade = '';
  }

  handleTradeChange = (event, props) => {
    this.state.selectedTrade = props.value;
    this.state.selectedQuoteLimit = 0;
  }


  createJobApproveHeader = () => {
    return (
      <>
        <h2 className="d-flex align-items-center">
          Approve Job
        </h2>

      </>
    );
  };

  getConfidenceLevelClass(confidenceLevel) {
    switch (confidenceLevel) {
      case 1:
        return "confident-lbl confident-low";
      case 2:
        return "confident-lbl confident-medium";
      case 3:
        return "confident-lbl confident-high";
    }
  }

}


export default connect(
)(ExternalJobApprove);

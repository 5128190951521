import * as React from 'react';
import FileViewer from "react-file-viewer";
import PDFViewer from 'pdf-viewer-reactjs'
import { Modal } from "react-bootstrap";
import { AppConfiguration } from "read-appsettings-json";
const FilesViewer = (props) => {
    const fileType = props.fileType;
    const filePath = props.filePath;
    const isInspectionAttachmentWithComments = props.isCommentsAvailable;
    const attachmentComment = props.attachmentComment;
    const origin = AppConfiguration.Setting().apiendpoint;
    if (fileType === 'pdf') {

        return (
            <>
                <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="video-pdf-viewer">
                        <PDFViewer
                            document={{
                                url: 'https://cors-anywhere.herokuapp.com/' + filePath,
                                httpHeaders: {
                                    'X-CustomHeader': '40359820958024350238508234',
                                    'Access-Control-Allow-Origin': origin
                                },
                                withCredentials: true
                            }}
                            hideZoom
                            hideRotation
                            navigation={{
                                css: {
                                    navbarWrapper: 'container',
                                    previousPageBtn: 'btn text-hide border border-dark',
                                    pageIndicator: 'customPages',
                                    nextPageBtn: 'btn text-hide border border-dark'
                                }
                            }}
                        />
                    </Modal.Body>
                </Modal>
            </>
        )
    } else if (isInspectionAttachmentWithComments) {
        return (
            <>
                <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-7 col-sm-12">
                                <img
                                    src={filePath}
                                    alt={""}
                                    className="w-100 h-auto"
                                    downloadurl={filePath}
                                />
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <h3 className="font-16 font-600 mb-1">Comments :</h3>
                                <hr class="mt-2" />
                                <div dangerouslySetInnerHTML={{ __html: attachmentComment }}></div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    } else {
        return (
            <>
                <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FileViewer fileType={fileType} filePath={filePath} />
                    </Modal.Body>
                </Modal>
            </>
        )

    }

}


export default FilesViewer;
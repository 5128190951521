import React from "react";
import Drawer from "../../../Common/Drawer/Drawer";
import StarRatingComponent from "react-star-rating-component";
import userService from "../../../../Services/UserService";
import Loader from "react-loader-spinner";
import moment from 'moment';
import { Tab, Tabs } from "react-bootstrap";

export default class ProviderMiniProfileDrawer extends React.Component {
    state = {
        showProfileDrawer: false,
        profileDetails: {},
        isPmView: false,
        isSpView: false,
        isUserView: false,
        isDataLoaded: false,
        profileImageUrl: "/images/default-profile.png",
        isSelectedTradee: false,
        page: 1,
        total: 0,
        feedbackList: [],
        filteredFeedback: [],
        filterUserType: 0,
        isShowAreas: false,
        isLoadingFeedback: false,
        consumerTypePropertyId: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.userId != null) {
            this.setState({ showProfileDrawer: true, isSelectedTradee: this.props.isSelected })
            this.getProfile(this.props.userId);
        }
    }

    render() {

        let drawer = this.createDrawer();
        return <div>{drawer}</div>;

    }

    toggleProfileDrawer = isClose => {
        this.setState(state => ({
            showProfileDrawer: !this.state.showProfileDrawer
        }));

        if (isClose) {
            this.props.onCloseProviderDrawer();
        }

    };

    filterByUserType = (event) => {
        if (document.getElementById('filterUserType') != null) {
            let val = document.getElementById('filterUserType').value;
            let result = (val == 0) ? this.state.feedbackList : this.state.feedbackList.filter(a => a.feedBackUserType == val);
            let pageCount = document.getElementById('filterPageSize').value;
            result = (pageCount == -999) ? result : result.slice(0, pageCount);
            this.setState({
                filteredFeedback: result
            })
        }
    }

    userTypeFormatter(cell) {
        switch (cell) {
            case 3:
                return <span className="badge badge-waiting-confirmation"><span className="txt-wrap">Property Manager</span></span>;
            case 4:
                return <span className="badge badge-schedule-confirmed"><span className="txt-wrap">Tenant</span></span>;
            case 2:
                return <span className="badge badge-schedule-rejected"><span className="txt-wrap">Consumer</span></span>;
        }
    }

    dateFormatter(cell) {
        return <span>{new Date(cell).toLocaleDateString()}</span>;
    }

    getFeedbackTable() {

        return (
            <React.Fragment>
                <table className="received-feedBack-main table">
                    <tbody>
                        {this.state.filteredFeedback.map((element, index) => {
                            return (
                                <React.Fragment>
                                    <tr id={'feedback-item-' + index}>
                                        <td>
                                            <div className="text-light">
                                                {this.userTypeFormatter(element.feedBackUserType)}
                                            </div>
                                        </td>
                                        <td><div class="font-600">{element.user}</div></td>
                                        <td className="feedback-stars">
                                            <div>
                                                <StarRatingComponent
                                                    name="Avarage Feedback"
                                                    editing={false}
                                                    starCount={5}
                                                    value={element.avarageRating}
                                                    size={3}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mr-4">{element.avarageRating}</div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    createDrawer = () => {
        const header = this.createProfileHeader();
        const body = this.createProfileBody();

        return (
            <Drawer
                size={"m"}
                open={this.state.showProfileDrawer}
                header={header}
                body={body}
            ></Drawer>
        );
    };


    createProfileHeader = () => {
        return (
            <>
                <h2 className="d-flex align-items-center">
                    Profile View
                    <Loader visible={this.state.isLoadingData} type="ThreeDots" color="#DB3D44" height={10} width={100} />
                </h2>
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={() => this.toggleProfileDrawer(true)}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createProfileBody = () => {

        const { providerProfile } = this.state.profileDetails;
        if (this.state.isDataLoaded) {
            return (
                <>
                    <div className="panel-body dashboardN pt-0">
                        <div className="provider-profile row">
                            <div className="col-md-12">
                                <Tabs
                                    defaultActiveKey="details"
                                    id="uncontrolled-tab-example">
                                    <Tab eventKey="details" title="Details" style={{ overflowX: 'hidden' }}>
                                        <div className="row profile-view-bg pt-4">
                                            <div className="col-md-12">

                                                <div className="row">
                                                    <div className="col-md-4">

                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="profile-img-wrapper">
                                                            <div className="profile-img">
                                                                <img
                                                                    src={this.state.profileImageUrl}
                                                                    alt=""
                                                                    className="rounded-circle img-150"
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="col-md-4">

                                                    </div>
                                                </div>

                                                <div className="col-md-12 text-center mt-3">
                                                    <div className="user-contact">
                                                        <div>
                                                            <strong className="mt-0 mb-1">{providerProfile.displayName}</strong>
                                                        </div>
                                                        <div>
                                                            {providerProfile.email}
                                                        </div>
                                                        <div>
                                                            {(providerProfile.mobileNumber) ? providerProfile.mobileNumber : ""}
                                                            {(providerProfile.officePhone) ? " / " + providerProfile.officePhone : ""}
                                                        </div>

                                                        <div className="user-contact">
                                                            <div>
                                                                <StarRatingComponent
                                                                    name="Overoll Feedback"
                                                                    editing={false}
                                                                    starCount={5}
                                                                    value={providerProfile.overallRating}
                                                                />
                                                            </div>
                                                        </div>
                                                        <h3 className="align-items-start">Services</h3>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <div>
                                                                {this.state.profileDetails.providerProfile.primaryServices.map((service, index) => {
                                                                    return <label key={index} className="label label-md label-brand-blue">{service.title}</label>
                                                                })}
                                                            </div>
                                                        </div>
                                                        <h3 className="align-items-start">Areas Covered</h3>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                        <div>
                                                            {this.state.profileDetails.providerProfile.preferredStates.map(element => {
                                                                return <label className="label label-md label-brand-blue"> {element.label} </label>
                                                            })}
                                                        </div> 
                                                        </div>

                                                        <div className="profile-stats">
                                                            <div className="stat" style={{ borderLeft: 'none' }}>
                                                                <div className="stat-details">
                                                                    <strong className="mt-0 mb-2">Hourly rate</strong>
                                                                    <div className="stat-count-mini"> ${providerProfile.hourlyRate}</div>
                                                                </div>
                                                            </div>
                                                            <div className="stat" style={{ borderLeft: 'none' }}>
                                                                <div className="stat-details">
                                                                    <strong className="mt-0 mb-2">Callout fee</strong>
                                                                    <div className="stat-count-mini"> ${providerProfile.calloutFee}</div>
                                                                </div>
                                                            </div>
                                                            <div className="stat" style={{ borderLeft: 'none' }}>
                                                                <div className="stat-details">
                                                                    <strong className="mt-0 mb-2">Jobs completed</strong>
                                                                    <div className="stat-count-mini"> {providerProfile.completedJobsCount}</div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="profile-badges">
                                                            {(providerProfile.hasABNBadge) ?
                                                                <div className="profile-badge">
                                                                    <div className="abn-badge"></div>
                                                                    <div className="badge-name">
                                                                        ABN
                                                                        <br />
                                                                        Registered
                                                                    </div>
                                                                </div> : ''}
                                                            {(providerProfile.hasCm3CertifiedBadge) ?
                                                                <div className="profile-badge">
                                                                    <div className="cm3-badge"></div>
                                                                    <div className="badge-name">
                                                                        CM3
                                                                        <br />
                                                                        Certified
                                                                    </div>
                                                                </div> : ''}
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6 mb-2">
                                                                <strong style={{ display: "block" }}>ABN</strong>
                                                                <span> {(providerProfile.abn) ? providerProfile.abn : "N/A"} </span>
                                                            </div>
                                                            <div className="col-md-6 mb-2">
                                                                <strong style={{ display: "block" }}>Phone Number</strong>
                                                                <span>  {(providerProfile.mobileNumber) ? providerProfile.mobileNumber : ""}
                                                                    {(providerProfile.officePhone) ? " / " + providerProfile.officePhone : ""} </span>
                                                            </div>
                                                            <div className="col-md-6 mb-2">
                                                                <strong style={{ display: "block" }}>Business Name</strong>
                                                                <span> {(providerProfile.businessName) ? providerProfile.businessName : "N/A"} </span>
                                                            </div>
                                                        </div>

                                        <div className="col-md-12 text-center mt-3">
                                            <h3 className="profile-view-collapse mb-0"> Licensed Trades <i className="ba-icn ba-icn-angle-right"></i></h3>
                                            {this.getLicensedTrades()}

                                            <h3 className="profile-view-collapse mb-0"> Insurance <i className="ba-icn ba-icn-angle-right"></i></h3>
                                            {this.getInsurance()}
                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="feedback" title="Feedback" style={{ overflowX: 'hidden' }}>
                                        <div className="mt-3">
                                            <h5>Feedback Received <span className="font-300">({this.state.feedbackList.length})</span></h5>
                                            <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                                                <div className="form-inline">
                                                    <div className="form-group">
                                                        <label>Filter By User Type</label>
                                                        <select className="ml-2 form-control" onChange={event => this.filterByUserType(event)} id="filterUserType">
                                                            <option value="0">All</option>
                                                            <option value="3">Property Managers</option>
                                                            <option value="4">Property Tenants</option>
                                                            <option value="2">Consumers</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group">
                                                        <label>Per page</label>
                                                        <select className="ml-2 form-control" onChange={event => this.filterByUserType(event)} id="filterPageSize">
                                                            <option selected="" value="5">5</option>
                                                            <option value="10">10</option>
                                                            <option value="15">15</option>
                                                            <option value="20">20</option>
                                                            <option value="-999">All</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-grid received-feedBack-scroller provider-profile">
                                                {(this.state.isLoadingFeedback) ?
                                                    <div className="text-center"><Loader type="ThreeDots" color="#DB3D44" height={20} width={100} /></div>
                                                    : this.getFeedbackTable()}
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>

                            </div>
                        </div>
                    </div>
                </>
            );
        }
    };

    getSPDetails = () => {
        const { providerProfile } = this.state.profileDetails;

        if (this.state.isSpView) {
            return (
                <>
                    <div className="col-md-9">
                        <div>
                            {(providerProfile.tradeeLeaveCalendars && providerProfile.tradeeLeaveCalendars.length > 0) ?
                                <div class="confident-lbl confident-medium">
                                    {providerProfile.tradeeLeaveCalendars.map((service, index) => {
                                        return <div> <label key={index}> On Leave from {moment(new Date(service.startDateTime)).format('DD/MM/YYYY').toString()} until  {moment(new Date(service.startDateTime)).format('DD/MM/YYYY').toString()} ({service.numberOfDays} Days) </label><br /> </div>
                                    })}
                                </div>
                                : ''}
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <div className="col-md-6">
                                <div className="user-contact">
                                    <div>
                                        <strong className="mt-0 mb-1">SP Name : </strong>
                                        {providerProfile.displayName}
                                    </div>
                                    <div>
                                        <strong className="mt-0 mb-1">ABN : </strong>
                                        {providerProfile.abn}
                                    </div>
                                    <div>
                                        <strong className="mt-0 mb-1">Business Name : </strong>
                                        {providerProfile.businessName}
                                    </div>
                                    <div>
                                        <strong className="mt-0 mb-1">Email : </strong>
                                        {providerProfile.email}
                                    </div>
                                    <div>
                                        <strong className="mt-0 mb-1">Mobile No : </strong>
                                        {providerProfile.mobileNumber}
                                    </div>
                                    {(providerProfile.officePhone != null) ?
                                        <div>
                                            <strong className="mt-0 mb-1">Office No : </strong>
                                            {providerProfile.officePhone}
                                        </div> : ''}
                                    <div>
                                        <label className="ba-switch">
                                            <input
                                                name="twoFactor"
                                                disabled={true}
                                                type="checkbox"
                                                id="isReadyToWork"
                                                className="ba-switch-input"
                                                defaultChecked={providerProfile.readyToWork} />
                                            <span className="ba-switch-label">Ready to Work</span>
                                            <span className="ba-switch-text"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="user-contact">
                                    <div>
                                        <StarRatingComponent
                                            name="Overoll Feedback"
                                            editing={false}
                                            starCount={5}
                                            value={providerProfile.overallRating}
                                        />
                                    </div>
                                    <div>
                                        <strong className="mt-0 mb-1">Rating : </strong>
                                        {providerProfile.overallRating}
                                    </div>
                                    <div>
                                        <strong className="mt-0 mb-1">Country in Operation : </strong>
                                        {providerProfile.workingCountry}
                                    </div>
                                </div>
                                <h4 className="mb-1">Skills :</h4>
                                <div className="d-flex justify-content-between align-items-start">
                                    <div>
                                        {this.state.profileDetails.providerProfile.primaryServices.map((service, index) => {
                                            return <label key={index} className="label label-md label-brand-blue">{service.title}</label>
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="profile-stats">
                            <div className="stat">
                                <div className="stat-count"> ${providerProfile.hourlyRate}</div>
                                <div className="stat-details">
                                    <span>Per hour</span>
                                    <span>rate</span>
                                </div>
                            </div>
                            <div className="stat">
                                <div className="stat-count"> {providerProfile.completedJobsCount}</div>
                                <div className="stat-details">
                                    <span>Completed</span>
                                    <span>Jobs</span>
                                </div>
                            </div>
                            <div className="stat">
                                <div className="stat-count"> ${providerProfile.calloutFee}</div>
                                <div className="stat-details">
                                    <span>Callout</span>
                                    <span>Fee</span>
                                </div>
                            </div>
                        </div>
                        <div className="profile-badges">
                            {(providerProfile.hasABNBadge) ?
                                <div className="profile-badge">
                                    <div className="abn-badge"></div>
                                    <div className="badge-name">
                                        ABN
                                        <br />
                                        Registered
                                    </div>
                                </div> : ''}
                            {(providerProfile.hasCm3CertifiedBadge) ?
                                <div className="profile-badge">
                                    <div className="cm3-badge"></div>
                                    <div className="badge-name">
                                        CM3
                                        <br />
                                        Certified
                                    </div>
                                </div> : ''}
                        </div>
                        <hr />
                        <div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h3>Feedback Received <span className="font-300">({this.state.feedbackList.length})</span></h3>
                                <div className="form-inline">
                                    <div className="form-group">
                                        <label>Filter By User Type</label>
                                        <select className="ml-2 form-control" onChange={event => this.filterByUserType(event)} id="filterUserType">
                                            <option value="0">All</option>
                                            <option value="3">Property Managers</option>
                                            <option value="4">Property Tenants</option>
                                            <option value="2">Consumers</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-inline">
                                    <div className="form-group">
                                        <label>Per page</label>
                                        <select className="ml-2 form-control" onChange={event => this.filterByUserType(event)} id="filterPageSize">
                                            <option selected="" value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="-999">All</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-grid received-feedBack-scroller provider-profile">
                                {(this.state.isLoadingFeedback) ?
                                    <div className="text-center"><Loader type="ThreeDots" color="#DB3D44" height={20} width={100} /></div>
                                    : this.getFeedbackTable()}
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    };

    getLicensedTrades = () => {
        const { providerProfile } = this.state.profileDetails;
        return (
            <div className="mb-4">
                <table className="w-100 table table-sm">
                    <tbody>
                        {providerProfile.providerLicenses.map((element, index) => {
                            return (
                                <tr key={index}>
                                    <td className="font-600 text-left">{element.mainJobTypeName}</td>
                                    <td className="text-left">{element.licenceNumber}</td>
                                </tr>
                            )
                        })}
                        
                    </tbody>
                </table>
            </div>
        )
    }

    getInsurance = () => {
        const { providerProfile } = this.state.profileDetails;
        return (
            <div className="mt-2">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="font-600 font-14 m-1">Applicable to All job Types</h3>
                    </div>
                </div>
                <table class="w-100 table table-sm">
                    <tbody>
                        <tr>
                            <td class="font-600 text-left">Public Liability</td>
                            <td class="text-left">$ {providerProfile.publicLiabilityDisplay}</td>
                        </tr>
                        <tr>
                            <td class="font-600 text-left">Professional indemnity</td>
                            <td class="text-left">$ {providerProfile.professionalIndemnityDisplay}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    getSPAllSkillsSummary = () => {
        const skills = this.state.profileDetails.providerProfile.primaryServices.map(
            (service, index) => {
                return (
                    <div>
                        <label className="label label-md label-brand-blue">
                            {service.title}
                        </label>
                    </div>
                );
            }
        );
        return skills;
    };

    getProfile = userId => {
        try {
            let filters = {
                userId: userId,
                includeAttachments: false,
                includeBidRatings: false,
                includeNearbySuburbs: true,
                includeTradeeAttachments: false,
                isDetailedView: false,
                includeBadges: true,
                isTimeline: false,
                inCludePrefferedJobTypes: true,
                numberOfSuburbsNeeded: 10,
                ischat: false,
                consumerTypePropertyId: this.props.consumerTypePropertyId
            };
            let isLoadedSpProfile = false;
            let isLoadedSpFeedback = false;
            this.setState({
                isLoadingFeedback: true,
                isLoadingData: true
            });
            userService.getProviderProfile(btoa(JSON.stringify(filters))).then(dataJson => {
                isLoadedSpProfile = true;
                this.setState({
                    profileDetails: dataJson,
                    isDataLoaded: true,
                    isLoadingData: false
                });

                if (dataJson.providerProfile.userId !== null) {
                    this.setState({
                        isPmView: false,
                        isSpView: true,
                        isUserView: false
                    });
                    if (
                        dataJson.providerProfile.profileImageUrl != null &&
                        dataJson.providerProfile.profileImageUrl.trim() != ""
                    ) {
                        this.setState({
                            profileImageUrl: dataJson.providerProfile.profileImageUrl
                        });
                    }
                } else if (dataJson.propertyManagerProfile.userId !== null) {
                    this.setState({
                        isPmView: true,
                        isSpView: false,
                        isUserView: false
                    });

                    if (
                        dataJson.propertyManagerProfile.profileImageUrl !== null ||
                        dataJson.propertyManagerProfile.profileImageUrl.trim() != ""
                    ) {
                        this.setState({
                            profileImageUrl: dataJson.propertyManagerProfile.profileImageUrl
                        });
                    }
                } else if (dataJson.userProfile.userId !== null) {
                    this.setState({
                        isPmView: false,
                        isSpView: false,
                        isUserView: true
                    });
                    if (
                        dataJson.userProfile.profileImageUrl !== null ||
                        dataJson.userProfile.profileImageUrl.trim() != ""
                    ) {
                        this.setState({
                            profileImageUrl: dataJson.userProfile.profileImageUrl
                        });
                    }
                } else {
                    this.setState({
                        isDataLoaded: false
                    });
                }

                userService.GetProviderBidFeedbacks(this.props.userId).then(res => {
                    isLoadedSpFeedback = true
                    this.setState({
                        feedbackList: res,
                        isLoadingFeedback: false,
                        total: res.length
                    });
                    if (res.length > 0) {
                        this.filterByUserType('1')
                    }

                })
            })


        } catch (error) {
            console.log(error);
        }
    };

}

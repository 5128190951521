import React, { Component } from 'react'
import Drawer from "../../Common/Drawer/Drawer";
import './EventScheduleDrawer.scss';
import EventScheduler from '../../Common/EventScheduler/EventScheduler';
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import moment from 'moment';
import Loader from "react-loader-spinner";
import { toast } from 'react-toastify';

export default class EventScheduleDrawer extends Component {
    state = {
        showDrawer: false,
        availableTimeSlots: [],
        notAvailableTimeSlots: [],
        suggestedTimeSlots: [],
        tempAvailableArray: [],
        tempblockedArray: [],
        tempsuggestArray: [],
        fromDate: new Date(),
        toDate: new Date(new Date().getTime() + (5 * 24 * 60 * 60 * 1000)),
        scheduledDuration: 0,
        jobDetails: {},
        isPostingData: false,
        isDetailsLoading: false,
        isUpdating: false,
        travelTimeDuration: 0,
        workingSlots: [],
        tempWorkingSlots: []
    };

    constructor(props) {
        super(props);
    }


    onUpdateSchedule = (event) => {
        let schedules = {}
        const suggestedTaskScheduleListViewModel = [];
        if (this.state.suggestedTimeSlots.length > 0) {
            this.setState({ isUpdating: true });
            this.state.suggestedTimeSlots.forEach(event => {
                var mobileTaskScheduleModel = this.convertToScheduleEvent(event);
                suggestedTaskScheduleListViewModel.push(mobileTaskScheduleModel);

            });
        }
        this.setState({ isPostingData: true });

        if (suggestedTaskScheduleListViewModel.length > 0) {

            schedules.taskSchedules = suggestedTaskScheduleListViewModel;

            AXIOS.POST(`schedule/tenantSuggestSchedules`, schedules).then(response => {
                if (response === 12) {
                    toast.success("Schedule updated sucess.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
                else {
                    toast.error('Schedule updated failed.', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
                this.setState({ showDrawer: false, isUpdating: false });
            });

        }

    }

    convertToScheduleEvent(event) {
        var userId = localStorage.getItem('userIdDecripted');
        let mobileTaskScheduleModel = {};
        const from = new Date(event.from);
        from.setTime(from.getTime() + from.getTimezoneOffset() * 60 * 1000);
        const to = new Date(event.to);
        to.setTime(to.getTime() + to.getTimezoneOffset() * 60 * 1000);
        mobileTaskScheduleModel.status = 1;
        if (event.eventId !== undefined) {
            mobileTaskScheduleModel.eventId = event.eventId;
            mobileTaskScheduleModel.status = 2;
        }
        mobileTaskScheduleModel.fromDateTime = moment(new Date(from).toUTCString()).format('DD/MM/YYYY HH:mm:ss');
        mobileTaskScheduleModel.toDateTime = moment(new Date(to).toUTCString()).format('DD/MM/YYYY HH:mm:ss');
        mobileTaskScheduleModel.bidId = this.props.jobDetails.acceptedBid.bidId;
        mobileTaskScheduleModel.jobId = this.props.jobDetails.id;
        mobileTaskScheduleModel.userId = userId;
        mobileTaskScheduleModel.jobScheduledPMId = event.userId === undefined ? userId : event.userId;
        mobileTaskScheduleModel.lastUpdatedUserId = userId;
        mobileTaskScheduleModel.isJobScheduleFromPM = false;
        mobileTaskScheduleModel.isDeletedEvent = event.isDeletedEvent === undefined ? false : event.isDeletedEvent;
        mobileTaskScheduleModel.assigneeId = this.props.jobDetails.acceptedBid.providerId;
        mobileTaskScheduleModel.travelTime = this.props.jobDetails.acceptedBid.travelTime === undefined ? 0 : this.props.jobDetails.acceptedBid.travelTime;

        return mobileTaskScheduleModel;
    }



    updateState = (name, event) => {
        this.setState({
            [name]: event
        })
    }

    toggleJobCancelDrawer = () => {
        document.body.style.cssText = 'overflow : auto !important';
        const isShowDrawer = !this.state.showDrawer;
        this.setState(state => ({
            showDrawer: isShowDrawer
        }));
        if (isShowDrawer) {
            document.body.style.cssText = 'overflow : hidden !important';
            if (this.props.jobDetails != null && this.props.jobDetails.acceptedBid != null) {
                var totalJobTime = this.props.jobDetails.acceptedBid.estimatedHours;
                const availableStart = new Date(this.props.jobDetails.acceptedBid.availabilityStart);
                availableStart.setTime(availableStart.getTime() - availableStart.getTimezoneOffset() * 60 * 1000);
                const availableEnd = new Date(this.props.jobDetails.acceptedBid.availabilityEnd);
                availableEnd.setTime(availableEnd.getTime() - availableEnd.getTimezoneOffset() * 60 * 1000);
                this.setState({ isDetailsLoading: true, scheduledDuration: totalJobTime, fromDate: availableStart, toDate: availableEnd });
                this.setState({ availableTimeSlots: [], notAvailableTimeSlots: [], suggestedTimeSlots: [], tempAvailableArray: [], tempblockedArray: [], tempsuggestArray: [], tempWorkingSlots: [], workingSlots: [] });
                this.setSchedulerByDates(this.props.jobDetails.acceptedBid.availabilityStart, this.props.jobDetails.acceptedBid.availabilityEnd);
            }

        }
    };


    setSchedulerByDates(startDate, endDate) {
        let start = new Date(startDate);
        start.setTime(start.getTime() - start.getTimezoneOffset() * 60 * 1000);
        let end = new Date(endDate);
        end.setDate(end.getDate() + 1);
        const endTime = end.setTime(end.getTime() - end.getTimezoneOffset() * 60 * 1000);
        let startTime = Number(start);
        while (endTime >= startTime) {
            var startDates = moment(start.toLocaleString()).format('YYYY-MM-DD HH:mm:ss');

            this.getJobSchedulerDetail(startDates);
            startTime = start.setDate(start.getDate() + 1);
        }
    }

    getJobSchedulerDetail = async (startDate) => {
        if (!startDate) return false;

        try {
            await AXIOS.GET(`schedule/tenantShedulesByDate?providerId=${this.props.jobDetails.acceptedBid.providerId}&selectedDate=${startDate}&bidId=${this.props.jobDetails.acceptedBid.bidId}`).then(
                data => {
                    if (data !== "") {
                        this.setAvailableTimeSlot(data);
                    }
                    return true;
                }
            )
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    setAvailableTimeSlot(data) {
        var userId = localStorage.getItem('userIdDecripted');
        if (data.availableEvents.length > 0) {

            data.availableEvents.forEach((element) => {
                let availableScheduleArray = {};
                availableScheduleArray.from = this.getConvertedDateTime(element.fromDateTime);
                availableScheduleArray.to = this.getConvertedDateTime(element.toDateTime);
                this.state.tempAvailableArray.push(availableScheduleArray);
            });
            this.setState({ availableTimeSlots: this.state.tempAvailableArray });
        }

        if (data.events.length > 0) {

            data.events.forEach((element) => {
                let eventScheduleArray = {};
                eventScheduleArray.from = this.getConvertedDateTime(element.fromDateTime);
                eventScheduleArray.to = this.getConvertedDateTime(element.toDateTime);
                this.state.tempblockedArray.push(eventScheduleArray);
            });
            this.setState({ notAvailableTimeSlots: this.state.tempblockedArray });

        }
        if (data.suggestedEventsForDate.length > 0) {

            data.suggestedEventsForDate.forEach((element) => {
                if (!element.isDeletedEvent) {
                    let suggestScheduleArray = {};
                    if (userId !== element.suggestedUserId) {
                        suggestScheduleArray.scheduledByPm = true;
                    }
                    suggestScheduleArray.eventId = element.eventId;
                    suggestScheduleArray.userId = element.suggestedUserId;
                    suggestScheduleArray.isSingle = '';
                    suggestScheduleArray.isDeletedEvent = false;
                    suggestScheduleArray.from = this.getConvertedDateTime(element.fromDateTime);
                    suggestScheduleArray.to = this.getConvertedDateTime(element.toDateTime);
                    this.state.tempsuggestArray.push(suggestScheduleArray);
                }
            });
            this.setState({ suggestedTimeSlots: this.state.tempsuggestArray });
        }
        if (data.travelTime > 0) {
            var totalJobTime = parseInt(this.props.jobDetails.acceptedBid.estimatedHours + data.travelTime);
            this.setState({ scheduledDuration: totalJobTime, travelTimeDuration: data.travelTime });
        }
        this.scheduler.onPrepareGrid(this.state.fromDate, this.state.toDate);

        if (data.workingHours) {
            data.workingHours.from = this.getConvertedDateTime(data.workingHours.fromDateTime);
            data.workingHours.to = this.getConvertedDateTime(data.workingHours.toDateTime);
            this.state.tempWorkingSlots.push(data.workingHours);
            this.setState({ workingSlots: this.state.tempWorkingSlots });
        }

        setTimeout(() => {
            this.scheduler.updateNotAvailableTimeSlots();
            this.scheduler.updateAvailableTimeSlots();
            this.scheduler.updateSuggestedTimeSlots();
            this.scheduler.convertJobTimesToHoursAndMinutes();
            this.scheduler.updateWorkingTimeSlots();
            this.setState({ isDetailsLoading: false });
        }, 500);

    }

    getConvertedDateTime(value) {
        if (!value) {
            return;
        }
        let allData = value.split(' ');
        let date = allData[0].split('/');
        let newDate = date[1] + '/' + date[0] + '/' + date[2] + ' ' + allData[1];
        return new Date(newDate);
    }

    render() {
        let drawer = this.createDrawer();

        return (
            <>
                <button className="action-btn" onClick={this.toggleJobCancelDrawer}>
                    <span className="button-label">Calendar</span>
                </button>
                {drawer}
            </>
        );
    }

    createDrawer = () => {
        const header = this.createHeader();
        const body = this.createBody();
        const footer = this.createFooter();

        return (
            <Drawer
                size={"xxl"}
                open={this.state.showDrawer}
                header={header}
                body={body}
                footer={footer}
            ></Drawer>
        );
    };

    createHeader = () => {
        const { isDetailsLoading } = this.state;
        return (
            <>
                <h2 className="d-flex align-items-center">Event Scheduler</h2>
                {(isDetailsLoading) ? <Loader type="ThreeDots" color="#DB3D44" height={10} width={100} /> : ''}
                <div className="panel-header-actions">
                    <button
                        className="action-btn panel-close"
                        onClick={this.toggleJobCancelDrawer}
                    >
                        <i className="ba-icn ba-icn-close"></i>
                    </button>
                </div>
            </>
        );
    };

    createBody = () => {
        return (
            <div className="panel-body">
                <EventScheduler
                    onRef={ref => (this.scheduler = ref)}
                    availableTimeSlots={this.state.availableTimeSlots}
                    notAvailableTimeSlots={this.state.notAvailableTimeSlots}
                    suggestedTimeSlots={this.state.suggestedTimeSlots}
                    workingSlots={this.state.workingSlots}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    scheduledDuration={this.state.scheduledDuration}
                    bidDetails={this.props.jobDetails.acceptedBid}
                    travelTimeDuration={this.state.travelTimeDuration}
                    updateState={(name, event) => this.updateState(name, event)} />
            </div>
        );
    };

    createFooter = () => {
        const { isUpdating } = this.state;
        return (
            <>
                <div className="card-actions my-3">
                    <button onClick={(event) => this.onUpdateSchedule(event)}>
                        {(isUpdating) ? <Loader type="ThreeDots" color="#DB3D44" height={7} width={70} /> : ''}  <i className="ba-icn ba-icn-ok"></i> Update Schedule{" "}
                    </button>
                </div>
            </>
        );
    };


}

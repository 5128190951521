import React, { useState } from "react";
import Drawer from "../../Common/Drawer/Drawer";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";

class RejectDrawer extends React.Component {
  state = {
    showJobRejectDrawer: false,
    disableJobRejectButton:false
  };
  constructor(props) {
    super(props);
    this.description = React.createRef();
  }

  render() {
    let drawer = this.createDrawer();

    return (
      <>
        <button className="action-btn reject-action" onClick={this.toggleJobRejectDrawer}>
          {/* <i class="ba-icn ba-icn-close mr-2"></i>  */}
          <span className="button-label font-600">Reject</span>
        </button>
        {drawer}
      </>
    );
  }

  createDrawer = () => {
    const header = this.createJobRejectHeader();
    const body = this.createJobRejectBody();
    const footer = this.createJobRejectFooter();

    return (
      <Drawer
        size={"md"}
        open={this.state.showJobRejectDrawer}
        header={header}
        body={body}
        footer={footer}
      ></Drawer>
    );
  };

  createJobRejectHeader = () => {
    return (
      <>
        <h2 className="d-flex align-items-center">Reject Job</h2>
        <div className="panel-header-actions">
          <button
            className="action-btn panel-close"
            onClick={this.toggleJobRejectDrawer}
          >
            <i className="ba-icn ba-icn-close"></i>
          </button>
        </div>
      </>
    );
  };

  createJobRejectBody = () => {
    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-sm-12 text-left">
            <div className="form-group">
              <label>Note<span className="required-icon">*</span></label>
              <textarea
                className="form-control"
                id="txtFeedback"
                rows="3"
                ref={this.description}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row d-none">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  name="markAsCompleted"
                  type="checkbox"
                  id="1"
                  className="custom-control-input"
                />
                <label
                  title=""
                  type="checkbox"
                  htmlFor="1"
                  className="custom-control-label"
                >
                  {" "}
                  Mark as Completed
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  createJobRejectFooter = () => {
    return (
      <>
        <div className="card-actions">
        <button className="more-actions" onClick={this.toggleJobRejectDrawer}>
            {" "}
            CANCEL{" "}
          </button>
          <button disabled={this.state.disableJobRejectButton} onClick={this.onClickJobReject}>
            <i className="ba-icn ba-icn-ok"></i> REJECT JOB{" "}
          </button>
        </div>
      </>
    );
  };

  onClickJobReject = () => {
    if(this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
      toast.error("Please enter job rejecting reason.",{
        position: toast.POSITION.BOTTOM_RIGHT
      });
      return;
    }
  this.toggleJobRejectButton();
    if (localStorage.getItem('userType') != '5' && this.props.jobDetails.workflowStatusId == 107) {
        AXIOS.GET(
            `tenant-api/job-review-reject?userId=${localStorage.getItem(
                "userId"
            )}&jobId=${this.props.jobDetails.id}&note=${
            this.description.current.value
            }`
        ).then(response => {
            if (response) {
                toast.success("Job Review Rejected.",{
                  position: toast.POSITION.BOTTOM_RIGHT
                });
                this.toggleJobRejectDrawer();
                this.props.toggleDrawer(true);
            } else {
                toast.error("Error occured. Please try again.",{
                  position: toast.POSITION.BOTTOM_RIGHT
                });
            }
          this.toggleJobRejectButton();
        });
    }
    else {
        AXIOS.GET(
            `tenant-api/job-reject-owner?userId=${localStorage.getItem(
                "userId"
            )}&jobId=${this.props.jobDetails.id}&note=${
            this.description.current.value
            }`
        ).then(response => {
            if (response) {
                toast.success("Job Rejected.",{
                  position: toast.POSITION.BOTTOM_RIGHT
                });
                this.toggleJobRejectDrawer();
                // this.props.getJobDetails(this.props.jobDetails.id);
                this.props.toggleDrawer(true);
                //this.props.refreshGrid(true);
            } else {
                toast.error("Error occured. Please try again.",{
                  position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            this.toggleJobRejectButton();
        });
    }
    
  };

  toggleJobRejectDrawer = () => {
    this.setState(state => ({
      showJobRejectDrawer: !this.state.showJobRejectDrawer
    }));
  };

  toggleJobRejectButton = () => {
    this.setState(state => ({
      disableJobRejectButton: !this.state.disableJobRejectButton
    }));
  };

}

const mapStateToProps = (state, ownProps) => {
  return {
    isRefresh: state.MyJobs.isRefreshGrid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshGrid: isRefresh => {
      dispatch({ type: "REFRESH_GRID", isRefresh: isRefresh });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RejectDrawer);

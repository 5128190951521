import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
const apiendpoint = AppConfiguration.Setting().apiendpoint;
axios.defaults.headers.post['ClientSecret'] = AppConfiguration.Setting().ClientSecret;
axios.defaults.headers.get['ClientSecret'] = AppConfiguration.Setting().ClientSecret;

var config = {
    crossdomain: true,
    headers: {
        ClientSecret: AppConfiguration.Setting().ClientSecret
    }
};
const Get = (url) => {
    return axios.get(apiendpoint + url, config)
            .then(res => {
                return res.data;
            });
} 

const Post = (url, body, header) => {
    return axios.post(apiendpoint + url, body, header)
            .then(res => {
                return res.data;
            });
 }

 const PostOther = (url, body, header) => {
    return axios.post(url, body, header)
            .then(res => {
                return res.data;
            });
 }

 
 const GetOther = (url, body, header) => {
    return axios.get(url, body, header)
            .then(res => {
                return res.data;
            });
 }

 const Put = (url, body, header) => {
    return axios.put(apiendpoint + url, body, header)
            .then(res => {
                return res.data;
            });
 }

 const Delete = (url, body, header) => {
    return axios.delete(apiendpoint + url, { data: body }, header)
            .then(res => {
                return res.data;
            });
 }

 export const AXIOS = {
     GET: Get,
     POST: Post,
     CROSS_ORIGIN: {
         POST: PostOther,
         GET: GetOther
     },
     PUT: Put,
     DELETE:Delete
 };
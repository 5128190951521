import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
//import "./Styles/JLL/style.scss";
import TenantProfile from "./Components/Tenant/TenantProfile/TenantProfile";
import userService from "./Services/UserService";
import { UserType } from './Constants/Enums';
import { ToastContainer, toast } from 'react-toastify';
import jobService from "./Services/JobService";
import { trackPromise } from "react-promise-tracker";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import propertyService from "./Services/PropertyService";
import { AppConfiguration } from "read-appsettings-json";
import PouchDbService from "./Services/PouchDbService";
import { MasterDataType } from '../src/Constants/Enums';
import { AXIOS } from "./Utilities/HttpRequest/Axios";
import Intercom from 'react-intercom';
import Home from './Components/Tenant/Home/Home'
import Loader from "react-loader-spinner";

const MyJobs = lazy(() => import("./Components/Tenant/MyJobs/MyJobs"));
const AllJobs = lazy(() => import("./Components/Tenant/AllJobs/AllJobs"));
const PropertyAccessControls = lazy(() => import("./Components/Tenant/PropertyAccessControls/PropertyAccessControls"));
const Settings = lazy(() => import("./Components/Tenant/Settings/Settings"));
const JobsForReview = lazy(() => import("./Components/Tenant/JobsForReview/JobsForReview"));
const DocumentList = lazy(() => import("./Components/Tenant/DocumentList/DocumentList"));
const PropertyInspection = lazy(() =>import("./Components/Tenant/PropertyInspection/PropertyInspection"));


var history = createBrowserHistory();
ReactGA.initialize('UA-103017323-2');

class App extends Component {
  static displayName = App.name;  
  constructor(props) {
    super(props);
   
    this.state = {
      minimizeDrawers: [],
      loading : true
    };    
  }

  componentDidMount() {    
    this.getUserDetails();
    this.getActiveMasterDataFileVersion();
    this.getOrgDetails();    
  }

  componentWillMount() {
    if(localStorage.getItem('styleCode') === "JLL" || localStorage.getItem('styleCode') === "jll") {
      import('./Styles/JLL/style.scss');
    } else {
      import('./Styles/style.scss');
    }
  }

  getActiveMasterDataFileVersion = () => {
    userService.GetActiveMasterDataFileVersion(MasterDataType.JobTypes)
    .then(response => {
      if(localStorage.getItem('jobTypesBlobFileName') == undefined || localStorage.getItem('jobTypesBlobFileName') == null) {
        this.readJsonFile(response);
      } else {
        if(localStorage.getItem('jobTypesBlobFileName') != response) {
          this.readJsonFile(response);
        }
      }
    });
  }

    readJsonFile(fileName) {
    let filePath = `${AppConfiguration.Setting().azureStorage.BlobStorageCdnEndPoint}${AppConfiguration.Setting().azureStorage.JobTypesContainerName}/${fileName}`;
    fetch(filePath)
      .then(response => response.json())
      .then(result => {
        this.addJobTypesToTheState(result);
        PouchDbService.RemoveTable(MasterDataType[MasterDataType.JobTypes]).then(() => {
          console.log('JobTypes table destroyed...');
          PouchDbService.InsertDocumentList(MasterDataType[MasterDataType.JobTypes], result).then(response => {
            console.log(`JobTypes inserted SUCCESSFULLY`);
            console.log(response);
            localStorage.setItem('jobTypesBlobFileName', fileName);
          });
        });
        
        
      })
  }

  readZipFile(fileName) {
    let filePath = `${AppConfiguration.Setting().azureStorage.BlobStorageCdnEndPoint}${AppConfiguration.Setting().azureStorage.JobTypesContainerName}/${fileName}`;
    var promise = new window.JSZip.external.Promise(function (resolve, reject) {
        window.JSZipUtils.getBinaryContent(filePath, function(err, data) {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });

    promise.then(window.JSZip.loadAsync)                 
    .then(function(zip) {     
        return zip.file(Object.keys(zip.files)[0]).async("base64");
    })
    .then((success) => {       
        let obj = atob(success).replace('ï»¿', '');
        let jsonObj = JSON.parse(obj);
        this.addJobTypesToTheState(jsonObj);
        PouchDbService.InsertDocumentList(MasterDataType[MasterDataType.JobTypes], jsonObj);
    }, (error) => {       
        console.log(error);
    });
}

  getPropertyByUserId(userId) {
  propertyService.GetPropertyByUserId(userId).then(res => {
    if (!res.isCurrentTenant) {
      this.props.updateIsTenantField(false);
    } else {
      this.props.updateIsTenantField(true);
    }
  });
}

  render() {
    const userType =
      localStorage.getItem("userType") == UserType.Owner ? "owner" : "tenant";
      const intercomApiKey = this.props.intercomApiKey;
    return (
      <div className="App">   
      {this.state.loading?
      <div className="align-items-center justify-content-center">
         <Loader type="ThreeDots" color="#DB3D44" height={20} width={200} />
      </div>:
      <>
       <Intercom appID={intercomApiKey} { ...this.state.intercomSettings } />
       <ToastContainer autoClose={3000} hideProgressBar />
       <Router history={history}>
         <Route
           onEnter={(nextState, replace) => replace("/" + userType + "/home")}
           render={({ location, history }) => (
             <Layout location={location} history={history}>
               <Suspense fallback={<div className="container mt-4">Loading...</div>}>
                <Switch>
                <Route
                     path={"/" + userType + "/home"}
                     component={props => <Home userData={this.state.intercomSettings}/>}
                   />
                 <Route
                     path={"/" + userType + "/my-jobs"}
                     component={props => <MyJobs />}
                   />
                   <Route
                     path={"/" + userType + "/all-jobs"}
                     component={props => <AllJobs />}
                   />
                   <Route
                     path={"/" + userType + "/property-access-controls"}
                     component={props => <PropertyAccessControls />}
                   />
                   <Route
                     path={"/" + userType + "/jobs-for-review"}
                     component={props => <JobsForReview />}
                   />
                   {/* <Route path='/tenant/drafted-jobs' component={props => <DraftedJobIndex />} /> */}
                   <Route
                     path={"/" + userType + "/document-list"}
                     component={props => <DocumentList />}
                   />
                   <Route
                     path={"/" + userType + "/property-inspection"}
                     component={props => <PropertyInspection />}
                   />
                   <Route
                     path={"/" + userType + "/tenant-profile"}
                     component={TenantProfile}
                   />
                   <Route
                     path={"/" + userType + "/settings"}
                     component={Settings}
                   />
                   {/* <IndexRoute component={AllJobs}/> */}
                   {/* <Redirect from="/" to="/tenant/my-jobs" /> */}
                   {/* <Route path="*" component={MyJobs} /> */}
                   <Route
                     exact
                     path="/"
                     render={() =>
                       true ? (
                         <Redirect to={"/" + userType + "/home"} />
                       ) : (
                           <Redirect to="/login" />
                         )
                     }
                   />
                </Switch>
               </Suspense>
             </Layout>
           )}
         />
       </Router>
       </>
      }
       
      </div>
    );
  }

  minimizeJobDetailsDraw = data => {
    this.setState({
      minimizeDrawers: [...this.state.minimizeDrawers, data]
    });
  };

  maximizeJobDetailsDraw = jobNumber => {
    let filteredArray = this.state.minimizeDrawers.filter(
      item => item.jobNumber !== jobNumber
    );
    this.setState({ minimizeDrawers: filteredArray });
  };

  addJobTypesToTheState = (jobTypesList) => {
    let services = [];
    let allJobTypes = [];
    let jobTypes = [];
    if(jobTypesList.length) {
      jobTypes = jobTypesList.filter(element => element.CountryId == this.props.userDetails.countryId);
    } else {
      jobTypes = jobTypesList.rows.filter(element => element.doc.CountryId == this.props.userDetails.countryId);
    }

    jobTypes.forEach(element => {
      let subTypes = [];
      if(jobTypesList.length) {
        subTypes = jobTypes.filter(p => p.ParentJobTypeId == element.Id);
        allJobTypes.push({
          id: element.Id,
          title: element.Name,
          label: element.Name,
          description: element.Description,
          tooltip: element.Tooltip,
          jobCategory: element.JobGroupId,
          parentRef: element.ParentJobTypeId,
          countryId: element.CountryId,
          hasChildRef: subTypes.length > 0
        });
      } else {
        subTypes = jobTypes.filter(p => p.doc.ParentJobTypeId == element.doc.Id);
        allJobTypes.push({
          id: element.doc.Id,
          title: element.doc.Name,
          label: element.doc.Name,
          description: element.doc.Description,
          tooltip: element.doc.Tooltip,
          jobCategory: element.doc.JobGroupId,
          parentRef: element.doc.ParentJobTypeId,
          countryId: element.doc.CountryId,
          hasChildRef: subTypes.length > 0
        });
      }
    }); 

    allJobTypes.forEach(element => {
      
      let subTypes = allJobTypes.filter(a => a.parentRef == element.id);

      if(element.hasChildRef) {
        services.push({
          parentJobType: element,
          childJobTypes: subTypes
        });
      }
    });
    this.props.addJobTypes(services);
  }

  getJobTypesByCountryId = () => {
    PouchDbService.GetAllDocument(MasterDataType[MasterDataType.JobTypes]).then(response => {
      console.log('JobTypes loaded...');
      if(response.rows.length > 0) {
        this.addJobTypesToTheState(response);
      }
    })
  }

  getUserDetails = () => {
    try {
      userService.getUserDetails().then(res => {
        let details = {};
        details.firstName = res.firstName;
        details.lastName = res.lastName;
        details.email = res.email;
        details.location = res.location;
        details.mobileNumber = res.mobileNumber;
        details.homePhoneNumber = res.homePhoneNumber;
        details.officePhoneNumber = res.officePhoneNumber;
        details.countryId = res.countryId;
        details.profileImageUrl = this.props.profileThumbnailsCdnEndpoint + res.profileImageUrl;
        details.profileImageId = res.profileImageUrl;
        details.userId = res.userId;
        details.userType = localStorage.getItem("userType");
        details.userIdEncripted = localStorage.getItem("userId");
        details.dateFormat = res.dateFormat;
        details.dateTimeFormat = res.dateTimeFormat + ":ss";
        details.dateTimeFormatMin = res.dateTimeFormat;
        details.countryCode = res.countryCode;
        this.props.updateUserDetails(details);
        this.getPropertyByUserId(localStorage.getItem("userId"));
        this.getJobTypesByCountryId();
        localStorage.setItem("DateFormat", details.dateFormat);
        localStorage.setItem("DateTimeFormat", details.dateTimeFormat);
        localStorage.setItem("dateTimeFormatMin", details.dateTimeFormatMin);
        localStorage.setItem("countryCode", details.countryCode);
        localStorage.setItem("firstName",details.firstName);
          var d = new Date(res.createdDateTime).getTime() / 1000;
          let intercomSettings = {
              name: res.firstName + " " + res.lastName, // Full name
              email: res.email, // Email address
              user_id: res.userId,
              phone: res.mobileNumber,
              created_at: d, // Signup date as a Unix timestamp
              profileImage: res.profileImageUrl != null? this.props.profileThumbnailsCdnEndpoint + res.profileImageUrl: "",
              usertype: res.userRole,
              userType: res.userRole,
              countryID: res.countryId,
              alignment: 'left',
              horizontal_padding: 35,
              vertical_padding: 10
          };

          this.setState({ intercomSettings: intercomSettings });
      });
    } catch (error) {
      console.log(error);
    }
  };

  getCommonOrganizationSettings = (orgId) => {
    this.setState({loading:true});
    userService.getCommonOrganizationSettings(orgId).then(response => {
            
      if(response && response.styleCode === 'JLL' || response.styleCode === 'jll') {
        let isTenantManageJob = (response.styleCode === 'JLL' || response.styleCode === 'jll') ? true : false;
        localStorage.setItem('styleCode','JLL');
        localStorage.setItem('isTenantManageJob',isTenantManageJob);
        import('./Styles/JLL/style.scss');
        setTimeout( () => {
          this.setState({loading:false});
        }, 2000);

        
      }else{
        localStorage.setItem('styleCode','');
        import('./Styles/style.scss');
        setTimeout( () => {
          this.setState({loading:false});
        }, 2000);
      }
      localStorage.setItem('spk',response.stripePublicKey);
    })
  }

  getOrgDetails = () => {
    userService.getUserRoles().then(response => {
        
        let selectedRole = (response.length == 1) ? response[0] : response.find(a => a.isSelected);
        if (selectedRole == undefined) {
            selectedRole = response[0]
        }
        localStorage.setItem('orgId', selectedRole.organizationId);
        this.getCommonOrganizationSettings(selectedRole.organizationId);
    });
   
}

}



const mapStateToProps = (state, ownProps) => {
  try {
    let userDetails = state.User;

    return {
      userDetails: userDetails,
      profileThumbnailsCdnEndpoint: state.AppSettings.imageCdnEndpoint,
      jobTypes: state.Jobs.jobTypes,
      intercomApiKey: state.AppSettings.intercomApiKey
    };
  } catch (error) {
    console.log(error);
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: details => {
      dispatch({ type: "SET_USER_DETAILS", userDetails: details });
    },
    updateIsTenantField: isCurrentTenant => {
      dispatch({ type: "CHECK_CURRENT_TENANT", isCurrentTenant: isCurrentTenant });
    },
    addJobTypes: jobTypes => { dispatch({ type: 'SET_JOB_TYPES', jobTypes: jobTypes })}
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

import React, { useState } from "react";
import Drawer from "../../Common/Drawer/Drawer";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";
import { Dropdown } from 'semantic-ui-react';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import jobService from "../../../Services/JobService";

// Styles
import "./JobApproveDrawer.scss";

class JobApproveDrawer extends React.Component {
  notifyError = (msg) => toast.error(msg, {
    position: toast.POSITION.BOTTOM_RIGHT
  });
  state = {
    showJobApproveDrawer: false,
    jobApproveOption: '0',
    showQuoteLimitSelection: false,
    showSuggestedTrade: false,
    selectedQuoteLimit: 0,
    selectedTrade: '',
    quoteLimit: [
      { key: 1, text: '1', value: 1 },
      { key: 2, text: '2', value: 2 },
      { key: 3, text: '3', value: 3 },
      { key: 4, text: '4', value: 4 },
      { key: 5, text: '5', value: 5 },
      { key: 6, text: '6', value: 6 },
      { key: 7, text: '7', value: 7 },
      { key: 8, text: '8', value: 8 },
      { key: 9, text: '9', value: 9 },
      { key: 10, text: '10', value: 10 },
      { key: 11, text: 'Unlimited', value: 11 }],
    suggestedTradeList: [],
    averageCostModel: {
      jobAverageCost: 0.00,
      jobCount: 0,
      confidenceLevel: 1
    },
    isAverageCostModelLoaded: false
  };
  constructor(props) {
    super(props);
    this.description = React.createRef();
  }
  //for getting average job cost
  getPredictiveAverageCost = (jobId) => {
    try {
      jobService.GetPredictiveJobAverageCost(jobId)
        .then(data => {
          this.setState({ averageCostModel: data });
          this.setState({ isAverageCostModelLoaded: true });
        });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.description = React.createRef();
    this.approvedAmount = React.createRef();
    this.isEmptySuggestedTradeList = false;
    if (this.props.showAverageCostWhenOwnerApproval) {
      this.getPredictiveAverageCost(this.props.jobDetails.id);
    }
  }

  render() {
    let drawer = this.createDrawer();

    return (
      <>
        <button className="action-btn" onClick={this.toggleJobApproveDrawer}>
          <span className="button-label">Approve</span>
        </button>
        {drawer}
      </>
    );
  }

  createDrawer = () => {
    const header = this.createJobApproveHeader();
    const body = this.createJobApproveBody();
    const footer = this.createJobApproveFooter();

    return (
      <Drawer
        size={"md"}
        open={this.state.showJobApproveDrawer}
        header={header}
        body={body}
        footer={footer}
      ></Drawer>
    );
  };

  createJobApproveHeader = () => {
    return (
      <>
        <h2 className="d-flex align-items-center">
          Approve Job
        </h2>
        <div className="panel-header-actions">
          <button
            className="action-btn panel-close"
            onClick={this.toggleJobApproveDrawer}
          >
            <i className="ba-icn ba-icn-close"></i>
          </button>
        </div>
      </>
    );
  };

  createJobApproveBody = () => {
    let quoteLimitSelection = this.createQuoteLimitSelection();
    let suggestedTradeSelection = this.createSuggestedTradeSelection();

    return (
      <div className="panel-body">
        {(this.props.showAverageCostWhenOwnerApproval && this.state.isAverageCostModelLoaded) ?
          <div className="row mb-2">
            <div className="col-sm-12 mb-3">
                <div className={this.getConfidenceLevelClass(this.state.averageCostModel.confidenceLevel)}>The suggested average cost range for the job is +/-${this.state.averageCostModel.jobAverageCost}</div>
            </div>
          </div>
          :
          ''
        }
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <ButtonToolbar className="radio-btn-group aprv-jb-btns d-flex justify-content-center">
                  <ToggleButtonGroup type="radio" name="options" ref="jobApproveOption" className="d-flex justify-content-center" onChange={this.handleJobApproveOptionOnChange}>
                    <ToggleButton value="2" className="mx-1">Please get some quotes</ToggleButton>
                    <ToggleButton value="3" className="mx-1">Delegate to Property Manager</ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
          </div>
        </>
        {quoteLimitSelection}
        {suggestedTradeSelection}
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group text-left">
              <label>Note
                <span className="required-icon">*</span>
              </label>
              <textarea
                className="form-control"
                id="txtFeedback"
                rows="3"
                ref={this.description}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row d-none">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  name="markAsCompleted"
                  type="checkbox"
                  id="1"
                  className="custom-control-input"
                />
                <label
                  title=""
                  type="checkbox"
                  htmlFor="1"
                  className="custom-control-label"
                >
                  {" "}
                  Mark as Completed
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  createQuoteLimitSelection = () => {
    if (this.state.showQuoteLimitSelection) {
      return (
        <>
          <div className="row">
            <div className="col-sm-12 text-left">
              <div className="form-group">
                <label>Quote Limit</label>
                <Dropdown
                  selectOnNavigation={false}
                  selection
                  options={this.state.quoteLimit}
                  placeholder='How many quotes'
                  onChange={this.handleQuoteLimitChange}
                />
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return
    }
  }

  createMessageForEmptySuggestedTradeSelection = () => {
    if (this.isEmptySuggestedTradeList) {
      return (
        <>
          <div className="row">
            <div className="col-sm-12">
              <label className="empty-list-label px-0">There are no suggested trades, you may delegate the job to your property manager</label>
            </div>
          </div>
        </>
      )
    }
    else {
      return
    }
  }

  createSuggestedTradeSelection = () => {
    let messageForEmptySuggestedTradeSelection = this.createMessageForEmptySuggestedTradeSelection();
    if (this.state.showSuggestedTrade) {
      return (
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Select a trade</label>
                <Dropdown
                  selectOnNavigation={false}
                  selection
                  options={this.state.suggestedTradeList}
                  placeholder='Select a trade'
                  onChange={this.handleTradeChange}
                />
              </div>
            </div>
          </div>
          {messageForEmptySuggestedTradeSelection}
        </>
      )
    } else {
      return
    }
  }

  handleQuoteLimitChange = (event, props) => {
    this.state.selectedQuoteLimit = props.value;
    this.state.selectedTrade = '';
  }

  handleTradeChange = (event, props) => {
    this.state.selectedTrade = props.value;
    this.state.selectedQuoteLimit = 0;
  }

  createJobApproveFooter = () => {
    return (
      <>
        <div className="card-actions">
          <button onClick={this.onClickJobApprove}>
            <i className="ba-icn ba-icn-ok"></i> Approve{" "}
          </button>
          <button
            className="more-actions"
            onClick={this.toggleJobApproveDrawer}
          >
            {" "}
            Cancel{" "}
          </button>
        </div>
      </>
    );
  };

  validateApproval = () => {
    let v = true;
    if (this.state.jobApproveOption === '0' && this.props.jobDetails.isEnableOwnerApprovalPercentage == false) {
      toast.error('Please select approval option.', {
        position: toast.POSITION.BOTTOM_RIGHT
      }); v = v && false;
    }

    if (this.state.jobApproveOption === '1' && this.state.selectedTrade === '') {
      toast.error('Please select a trade.', {
        position: toast.POSITION.BOTTOM_RIGHT
      }); v = v && false;
    }

    if (this.state.jobApproveOption === '2' && this.state.selectedQuoteLimit === 0) {
      toast.error('Please select a Quote Limit.', {
        position: toast.POSITION.BOTTOM_RIGHT
      }); v = v && false;
    }

    if (this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
      toast.error("Please enter job approving note.", {
        position: toast.POSITION.BOTTOM_RIGHT
      }); v = v && false;
    }

    return v;
  }

  onClickJobApprove = () => {
    try {

      if (this.validateApproval()) {
        let jobApprovalRequest = {};
        jobApprovalRequest.userId = localStorage.getItem("userId");
        jobApprovalRequest.jobId = this.props.jobDetails.id;
        jobApprovalRequest.note = this.description.current.value;
        jobApprovalRequest.jobApproveOption = parseFloat(this.state.jobApproveOption);
        jobApprovalRequest.quoteLimit = this.state.selectedQuoteLimit;
        jobApprovalRequest.tradeId = this.state.selectedTrade;
        jobApprovalRequest.IsOwnerApproved = true;


        trackPromise(
          AXIOS.POST(`tenant-api/job-approve-owner`, jobApprovalRequest)
            .then(response => {
              if (response) {
                toast.success("Job Approved.", {
                  position: toast.POSITION.BOTTOM_RIGHT
                });
                this.toggleJobApproveDrawer();
                this.props.toggleDrawer(true);
              } else {
                toast.error("Error occured. Please try again.", {
                  position: toast.POSITION.BOTTOM_RIGHT
                });
              }
            }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  toggleJobApproveDrawer = () => {
    this.setState(state => ({
      showJobApproveDrawer: !this.state.showJobApproveDrawer
    }));
  };

  handleJobApproveOptionOnChange = (value) => {
    this.setState({ jobApproveOption: value });

    if (value === "1") {
      try {
        AXIOS.GET(
          `tenant-api/suggested-trades?jobId=${this.props.jobDetails.id}`
        ).then(response => {
          if (response) {
            if (response.length <= 0) {
              this.isEmptySuggestedTradeList = true;
            }
            this.setState({ suggestedTradeList: response.map((a, index) => { return { key: index, text: a.businessName, value: a.userId }; }) });
            this.setState({ showQuoteLimitSelection: false, showSuggestedTrade: true });
          } else {
            toast.error("Error occured. Please try again.", {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    else if (value === "2") {
      this.setState({ showQuoteLimitSelection: true, showSuggestedTrade: false });
    }
    else if (value === "3") {
      this.setState({ showQuoteLimitSelection: false, showSuggestedTrade: false });
    }
  }

  onQuoteLimitSelection = (value) => {

  }

  getConfidenceLevelClass(confidenceLevel) {
    switch (confidenceLevel) {
      case 1:
        return "confident-lbl confident-low";
      case 2:
        return "confident-lbl confident-medium";
      case 3:
        return "confident-lbl confident-high";
    }
  }
}



const mapStateToProps = (state, ownProps) => {
  return {
    isRefresh: state.MyJobs.isRefreshGrid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshGrid: isRefresh => {
      dispatch({ type: "REFRESH_GRID", isRefresh: isRefresh });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobApproveDrawer);

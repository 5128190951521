import React, { useState } from "react";
import Drawer from "../../Common/Drawer/Drawer";
import bidService from "../../../Services/BidService";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";
import { AppConfiguration } from "read-appsettings-json";

class QuoteAcceptDrawer extends React.Component {
  state = {
    showAcceptQuoteDrawer: false,
    disableAcceptButton:false
  };
  constructor(props) {
    super(props);
    this.description = React.createRef();
  }

  render() {
    let drawer = this.createDrawer();

    return (
      <>
        <button className="btn btn-outline-primary" title="Accept" onClick={this.toggleAcceptQuoteDrawer}>Accept</button>
        {drawer}
      </>
    );
  }

  createDrawer = () => {
    const header = this.createAcceptQuoteHeader();
    const body = this.createAcceptQuoteBody();
    const footer = this.createAcceptQuoteFooter();

    return (
      <Drawer
        size={"md"}
        open={this.state.showAcceptQuoteDrawer}
        header={header}
        body={body}
        footer={footer}
      ></Drawer>
    );
  };

  createAcceptQuoteHeader = () => {
    return (
      <>
        <h2 className="d-flex align-items-center">Accept Quote</h2>
        <div className="panel-header-actions">
          <button
            className="action-btn panel-close"
            onClick={this.toggleAcceptQuoteDrawer}
          >
            <i className="ba-icn ba-icn-close"></i>
          </button>
        </div>
      </>
    );
  };

  createAcceptQuoteBody = () => {
    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-sm-12 text-left">
            <div className="form-group">
              <label>Note<span className="required-icon">*</span></label>
              <textarea
                className="form-control text-area-height"
                id="txtFeedback"
                rows="3"
                ref={this.description}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    );
  };

  createAcceptQuoteFooter = () => {
    return (
      <>
        <div className="card-actions">
        <button className="more-actions" onClick={this.toggleAcceptQuoteDrawer}>
            {" "}
            CANCEL{" "}
          </button>
          <button onClick={this.onClickApproveBid} disabled={this.state.disableAcceptButton}>
            <i className="ba-icn ba-icn-ok"></i> ACCEPT{" "}
          </button>
        </div>
      </>
    );
  };

  onClickApproveBid = () => {
      if (this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
          toast.error("Please enter quote accept reason.", {
              position: toast.POSITION.BOTTOM_RIGHT
          });
          return;
      }
      this.setState({disableAcceptButton:true})

      bidService.approveBidByOwner(this.props.jobDetails.id, this.props.bidDetails.bidId, this.description.current.value).then(res => {
          toast.success('Bid accepted by owner', {
              position: toast.POSITION.BOTTOM_RIGHT
          });
          this.toggleAcceptQuoteDrawer();
          this.props.toggleDrawer(true);
      })
  };

  toggleAcceptQuoteDrawer = () => {
    this.setState(state => ({
      showAcceptQuoteDrawer: !this.state.showAcceptQuoteDrawer
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    isRefresh: state.MyJobs.isRefreshGrid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshGrid: isRefresh => {
      dispatch({ type: "REFRESH_GRID", isRefresh: isRefresh });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteAcceptDrawer);
